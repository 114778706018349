import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Trash, Copy, Save } from "react-feather";
import { Field, ErrorMessage } from "formik";
import LowSpace from "app/shared/components/Popups/LowSpace";
import Url from "app/shared/constants/Url";
import VideoThumbnail from "react-video-thumbnail";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";

export default class AddImage extends Component {
  constructor(props) {
    super(props);

    if (this.props.sectionArr !== undefined) {
      var selectedIndex =
        this.props.sectionArr.length > 0
          ? this.props.sectionArr.length - 1
          : "";
    }

    this.state = {
      queIndex: this.props.index,
      image: [],
      imagePreviewUrl: "",
      submitted: false,
      fileFieldError: false,
      order: this.props.order,
     // selectedSectionIndex: selectedIndex,
     selectedSectionIndex: selectedIndex > 0 ?  selectedIndex : 'question',
      isCheckLowSpace: false,
      lowStorageMessage: "",
      lowStorageStatus: 200,
      showLowStoragePopup: false,
      type:0,
    };
  }

  _handleImageChange = async(e) => {
    // e.preventDefault();

    let reader = new FileReader();
    let file = e;
    this.setState({
      type : file.type.includes("image") ? 1 : 2,
    })

    const lowStorage = this.checkLowStorage();
    lowStorage
      .then(async (response) => {
        const { status, msg } = response.data;

        if (status == 200) {
          reader.onloadend = () => {
            this.setState({
              image: file,
              imagePreviewUrl: reader.result,
              fileFieldError: false,
            });
          };
          reader.readAsDataURL(file);
          this.props.form.setFieldValue(`field.file_path`, file);
        }
        await this.setState({
          lowStorageStatus: status,
          lowStorageMessage: msg,
          showLowStoragePopup: status == 200 ? false : true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _handleLowSpace = async (statusFlag) => {
    await this.setState({ showLowStoragePopup : false});
  };

  _onHideBlock = () => {
    this.props.hideBlock();
  };

  _getSelectedSection = async (e) => {
    let value = e.target.value;
    await this.setState({ selectedSectionIndex: value });
    console.log(this.state.selectedSectionIndex);
  };

  _addtoPreview = async () => {

    console.log("I am in addtoPreview Method");
    debugger
    this.setState({ submitted: true });

    if (this.state.imagePreviewUrl === "") {
      await this.setState({ fileFieldError: true });
      return;
    }

    var sectionTitle;
    if (this.props.section !== undefined) {
      // Setting dynamic section title based on selected section option from dropdown
      if (this.props.sectionArr !== undefined) {
        var index = this.state.selectedSectionIndex;
        sectionTitle =
          // index === "question" ? "" : this.props.sectionArr[index].title;
          index === "question" ? "" : this.props.sectionArr[index].title || this.props.sectionArr[index].section_name  ;
      } else {
        sectionTitle = this.props.section.title;
      }
    } else {
      sectionTitle = "";
    }

    this.props.addNew({
      type: "notsection",
      question: this.state.question,
      question_type: 2,
      description: "",
      mandatory: 0,
      notes: 0,
      evidence: 0,
      logic: 0,
      response_type: 0,
      section: sectionTitle,
      order: this.state.order,
      file_path: this.state.imagePreviewUrl,
      file_type: this.state.type,
      field_response: [],
      selectedSectionIndex: this.state.selectedSectionIndex,
      isEditing: false,
    });
    this.props.hideBlock();
  };

  render() {
    const { name, value: formikValue } = this.props.field;
    const { setFieldValue } = this.props.form;

    return (
      <div className="section-dynamic">
        <div className="box-dynamic">
          <div className="form-dynamic">
            <div className="form-group">
              <Field
                type="text"
                name={`field.question`}
                placeholder="Add Title"
                className="form-control"
                onChange={(e) => this.setState({ question: e.target.value })}
              />
            </div>
            <div className="file-upload">
              <MyDropzone type="5" uploadFile={(e) => {this._handleImageChange(e)}} />
            </div>
            {this.state.fileFieldError && this.state.submitted && (
              <div className="inputErrorMsg">Image/Video file is required</div>
            )}
            {/* <div className="browse-image">
              {this.state.imagePreviewUrl !== "" && (
               <>
                {this.state.type == 1 &&
                  <img
                      className="inner-logo"
                      src={this.state.imagePreviewUrl}
                      alt="preview"
                  />
                }
                {this.state.type == 2 &&
                  <VideoThumbnail videoUrl={this.state.imagePreviewUrl} />
                }
                </>
              )}
            </div> */}
            {this.props.sectionArr !== undefined &&
              this.props.sectionArr.length > 0 && (
                <Row>
                  <Col sm={4}>
                    <div>
                      <select
                        onChange={this._getSelectedSection}
                        className="form-control"
                        value={this.state.selectedSectionIndex}
                      >
                        <option value="question" key="sectionOption-question">
                          Question
                        </option>
                        {this.props.sectionArr.map((item, index) => (
                          <option value={index} key={`sectionOption-${index}`}>
                            {item.section_name === undefined
                              ? item.title
                              : item.section_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              )}
            <div className="section-btn">
              <Button className="icon-btn" onClick={() => this._onHideBlock()}>
                <Trash className="icon-style btn-color" />
              </Button>
              <Button className="icon-btn" onClick={() => this._addtoPreview()}>
                <Save className="icon-style btn-color" />
              </Button>
            </div>
          </div>
        </div>
        {this.state.showLowStoragePopup ? (
          <LowSpace
            message={this.state.lowStorageMessage}
            handleLowSpace={this._handleLowSpace}
          />
        ) : null}
      </div>
    );
  }
}
