/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal, Nav, Tab } from "react-bootstrap";
import TourIReport from "./TourIReport";
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import ExternalGpReport from "./ExternalGpReport";
import ExternalNmReport from "./ExternalNmReport";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import Url from "app/shared/constants/Url";
import { checkPermittedRoute } from "app/shared/utility/UtilFunction";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "axios";

//assetsV2
import { IReportCroxIcon, IReportTourIcon } from "assets/Iconsv2/Iconsv2";
import PageNotFound from "../PageNotFound";
const mapStateToProps = (state) => {
  const data = [...state.permitModule.permittedModuleList];
  return {
    permittedModuleList: data,
  };
};
const qs = require("qs");

class ExternalReport extends Component {
  constructor(props) {
    super(props);
    this.traningRef = React.createRef();
    this.gpRef = React.createRef();
    this.otherRef = React.createRef();
    this.state = {
      encyptedKey: decodeURIComponent(this.props?.match.params.encyptedKey),
      show: false,
      dataLoading: true,
      reportFeeds: [],
      reportFeedsLoading: true,
      isV2Enabled: this.props?.isVerisonV2 ?? false,
      activeModule: this.props?.activeModule ?? {},
      currentTab: "soi",
      error: false,
      hasMore: true,
      isLoading: false,
      flag: "open",
      graphIndex: null,
      otherDashboardDetails: [],
      sourceList: [],
      otherDetailsLoaded: false,
      rightSideBarType: "performers",
      safetyImages: [],
      safetyThought: "",
      maxFilesAttachLimit: 5,
      safetyThoughtSubmited: false,
      showDeletePopup: false,
      selectedId: null,
      days_since_last_incidence: "",
      reportingSOIPopup: false,
      startDate: new Date(),
      formParameter: [],
      serviceList: [],
      soiFormHidingField: {},
      nmFormHidingField: {},
      iraFormHidingField: {},
      userInfo: JSON.parse(localStorage.getItem("userData")),
      serviceId: null,
      projectId: null,
      isVerified: false,
    };
  }

  closeModal = () => {
    this.props.handleClose();
  };

  componentDidMount = async () => {
    await this._validateQrUrl();

    //await this._fetchServiceList();
    if (this.props?.isVerisonV2) {
      this.setState({ currentTab: this.props.activeModule?.key });
    }
  };

  _validateQrUrl = async () => {
    this._setDataLoading(true);
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      url: this.state.encyptedKey,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .post(Url.adminServices.projects.validateQr, qs.stringify(requestData), {
        headers: ContentType,
      })
      .then(async (response) => {
        if (response.status === 200) {
          if(response.data.status != 200) {
            this.props.history.push('/');
            toast.error("No record exists for the given QR code",{
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false
            });
          }
          await this.setState({
            projectId: response.data.projectId,
            serviceId: response.data.serviceId,
            isVerified: true,
          });
          // await this.setState({ sourceList: response.data.data });

          await this._fetchFormParamList();
          await this._fetchSourceList();
        }
      })
      .catch((error) => {
        this._setDataLoading(false);
      });
  };

  _fetchFormParamList = async () => {
    this._setDataLoading(true);
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.externalFormParameter
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let {
                formParameter,
                soiFormHidingField,
                nmFormHidingField,
                iraFormHidingField,
              } = prevState;
              formParameter = response.data.data;
              formParameter.soiSetting.forEach((element, index) => {
                soiFormHidingField[index] = element.parameter;
              });

              formParameter.nmSetting.forEach((element, index) => {
                nmFormHidingField[index] = element.parameter;
              });

              formParameter.iraSetting.forEach((element, index) => {
                iraFormHidingField[index] = element.parameter;
              });

              return {
                formParameter,
                soiFormHidingField,
                nmFormHidingField,
                iraFormHidingField,
              };
            });
            this._setDataLoading(false);
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };
  _fetchSourceList = async () => {
    this._setDataLoading(true);
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.externalSourceList);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ sourceList: response.data.data });
            this._setDataLoading(false);
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _handleTabClick = async (selectedKey = "soi") => {
    console.log(selectedKey);
  };

  _handlePermittedRoute = (checkRoute) =>
    checkPermittedRoute(this.props.permittedModuleList, checkRoute);
  __buildTabs = () => {
    return [
      {
        tabName: "UA/UC",
        key: "soi",
        skip: false,
        keyClass: "ir1",
      },
      {
        tabName: "NM",
        key: "nm",
        skip: false,
        keyClass: "ir2",
      },
      {
        tabName: "IRA",
        key: "ira",
        skip: false,
        keyClass: "ir3",
      },
      {
        tabName: "Good Act",
        key: "gp",
        skip: false,
        keyClass: "ir4",
      },
    ];
  };
  __gpEmitter = {};
  __trainingEmitter = {};
  __nmEmitter = {};
  __evenetEmitter = (tab) => {
    if (tab === "gp") {
      this.__gpEmitter();
    } else if (tab === "trn") {
      this.__trainingEmitter();
    } else {
      this.__nmEmitter();
    }
  };

  render() {
    return (
      <div>
        <Scrollbars
          // className="externalReport-bg"
          style={{ height: "100vh", background: "white" }}
          autoHide={false}
        >
        <div className={`w-100 ${this.state.isV2Enabled ? "bg-none" : ""}`}>
          <Modal.Title className={`w-100`}>
            <div style={{ background: "#ff6347", padding: 10 }}>
              {!this.state.isV2Enabled ? (
                <>
                  <HorizontalNavTab
                    tabs={this.__buildTabs()}
                    activeItem={this.state.currentTab}
                    handleChange={(key) => this.setState({ currentTab: key })}
                  />
                  <div className="modal-controls">
                    {/* <button onClick={this.closeModal}>Cancel</button> */}
                    {/*  */}
                  </div>
                </>
              ) : (
                <>
                  <div className="clide-v2-oreange-1">
                    {this.state.activeModule.tabName}
                  </div>
                  <div className="align-items-center d-flex justify-content-between">
                    <TourIReport
                      isVersionV2={this.state.isV2Enabled}
                      content={<IReportTourIcon />}
                    />{" "}
                    <div onClick={this.closeModal} className="cursor-pointer">
                      <IReportCroxIcon />
                    </div>
                  </div>
                </>
              )}
            </div>
          </Modal.Title>
        </div>
        {/* {this.state.isVerified ? */}
          <Modal.Body className="px-4 pt-4 bg-white">
            {/* Data Loading  */}
            <div
              className="loader"
              style={{ display: this.state.dataLoading ? "block" : "none" }}
            >
              <LoadingImage />
            </div>
                {!this.state.isV2Enabled ? <TourIReport /> : null}

                {this.state.currentTab === "soi" && (
                  <ExternalNmReport
                    hideFieldList={this.state.soiFormHidingField}
                    reportType="soi"
                    close={this.closeModal}
                    setClick={(click) => (this.__nmEmitter = click)}
                    sourceList={this.state.sourceList}
                    serviceId={this.state.serviceId}
                    projectId={this.state.projectId}
                  />
                )}

                {this.state.currentTab === "nm" && (
                  <ExternalNmReport
                    hideFieldList={this.state.nmFormHidingField}
                    reportType="nm"
                    close={this.closeModal}
                    setClick={(click) => (this.__nmEmitter = click)}
                    serviceId={this.state.serviceId}
                    projectId={this.state.projectId}
                  />
                )}

                {this.state.currentTab === "ira" && (
                  <>
                    <ExternalNmReport
                      hideFieldList={this.state.iraFormHidingField}
                      reportType="ira"
                      close={this.closeModal}
                      setClick={(click) => (this.__nmEmitter = click)}
                      serviceId={this.state.serviceId}
                      projectId={this.state.projectId}
                    />
                  </>
                )}
                {this.state.currentTab === "gp" && (
                  <ExternalGpReport
                    hideFieldList={this.state.iraFormHidingField}
                    reportType="gp"
                    close={this.closeModal}
                    setClick={(click) => (this.__gpEmitter = click)}
                    serviceId={this.state.serviceId}
                    projectId={this.state.projectId}
                  />
                )}
    
          </Modal.Body>
              <Modal.Footer
                className="d-flex justify-content-center"
                style={{ background: "white" }}
              >
                <button
                  onClick={() => this.__evenetEmitter(this.state.currentTab)}
                  className="report-btn"
                >
                  Report
                </button>
              </Modal.Footer>
            </Scrollbars>
        {/* {this.state.isV2Enabled ? (
          <Modal.Footer className="d-flex justify-content-center">
            <div>
              <button onClick={this.closeModal}>Cancel</button>
              <button
                onClick={() => this.__evenetEmitter(this.state.currentTab)}
                className="border clide-v2-common-orange-bg-1 clide-v2-common-white-1 clide-v2-fs-5 font-weight-500 px-2 py-1 rounded"
              >
                Report
              </button>
            </div>
          </Modal.Footer>
        ) : null} */}
      </div>

    );
  }
}

export default connect(mapStateToProps, null)(withRouter(ExternalReport));

export const HorizontalNavTab = ({
  tabs = [],
  activeItem,
  handleChange,
  classes = "",
}) => {
  const items = tabs.filter(({ skip = false, keyClass = "", ...rest }) => {
    if (skip === false) {
      return rest;
    }
  });
  return (
    <ul className={`horizontal-nav-tab-wrapper ${classes}`}>
      {items.map((el) => (
        <li
          key={el.key}
          onClick={() => handleChange(el.key)}
          className={`tab-item d-flex align-items-center ${el?.keyClass} ${
            activeItem === el?.key ? "tab-active" : ""
          }`}
        >
          <a id={el.key}>{el?.tabName} </a>
          <div className="mx-1">
            {el?.activeIcon !== undefined && activeItem === el?.key
              ? el?.activeIcon
              : el?.icon}
          </div>
        </li>
      ))}
    </ul>
  );
};
