import React, { Component } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { Mic } from "react-feather";
import Url from "app/shared/constants/Url";
import axios from "axios";
import Backbutton from "app/shared/components/Backbutton";
import LoadingView from "app/shared/components/Loader/LoadingView";
import TourWF from "./TourWF";

import ConfirmModal from "app/shared/utility/ConfirmModal";
import {
  SuccessToast,
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";

const qs = require("qs");

const RequiredText = () => <span className="text-danger mr-1">*</span>;

export default class NmWorkFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      checked: false,
      type_of_act: 0,
      activity: 1,
      hazard: 0,
      zone: 0,
      person_id: 0,
      location: 0,
      department: 0,
      contractor: 0,
      Incident_date: 1,
      Incident_time: 1,
      person: 0,
      employee_search: 0,
      severity: 1,
      evidence_num: "",
      observation: 1,
      workFlowPopup: false,
      showConfirmPopup: false,
    };
    this._NMWorkFlowView();
    this._settingCheck2 = this._settingCheck2.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    this._NMWorkFlowView();
  };

  _NMWorkFlowView = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.nmModule.nmsavedformsetting,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          response.data.data.setting.map((data) => {
            if (data.parameter === "Type Of Act") {
              this.setState({ type_of_act: data.parameter_value });
            }
            if (data.parameter === "Hazard") {
              this.setState({ hazard: data.parameter_value });
            }
            if (data.parameter === "Observation") {
              this.setState({ observation: data.parameter_value });
            }
            if (data.parameter === "Incident Date") {
              this.setState({ Incident_date: data.parameter_value });
            }
            if (data.parameter === "Incident Time") {
              this.setState({ Incident_time: data.parameter_value });
            }
            if (data.parameter === "Person") {
              this.setState({ person: data.parameter_value });
            }
            if (data.parameter === "Employee Search") {
              this.setState({ employee_search: data.parameter_value });
            }
            if (data.parameter === "Zone") {
              this.setState({ zone: data.parameter_value });
            }
            if (data.parameter === "Location") {
              this.setState({ location: data.parameter_value });
            }
            if (data.parameter === "Person Id") {
              this.setState({ person_id: data.parameter_value });
            }
            if (data.parameter === "Department") {
              this.setState({ department: data.parameter_value });
            }
            if (data.parameter === "Contractor") {
              this.setState({ contractor: data.parameter_value });
            }
            if (data.parameter === "Evidence_Limit") {
              this.setState({ evidence_num: data.parameter_value });
            }
            if (data.parameter === "Severity") {
              // this.setState({ severity : data.parameter_value})
            }
            if (data.parameter === "Activity") {
              this.setState({ activity: data.parameter_value });
            }
          });
        }
      })
      .catch((error) => { });
  };

  _NMWorkFlow = async () => {
    await this.setState({ dataLoading: true, showConfirmPopup: false });

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.nmModule.nmworkflowformsetting,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          // type_of_act : this.state.type_of_act,
          // activity : 1,
          hazard: this.state.hazard,
          zone: this.state.zone,
          location: this.state.location,
          department: this.state.department,
          contractor: this.state.contractor,
          incident_date: this.state.Incident_date,
          incident_time: this.state.Incident_time,
          person: this.state.person,
          employee_search: this.state.employee_search,
          person_id: this.state.person_id,
          severity: 1,
          evidence_limit: this.state.evidence_num,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(async (response) => {
        if (!response.data.status) {
          return (
            // <div className="loader" style={{ display: "block" }}>
            //   <img
            //     className="loader-img"
            //     src={require("assets/images/loader1.gif")}
            //   />
            // </div>
            <LoadingView showLoading={this.state.dataLoading} />
          );
        }


        await this.setState({ dataLoading: false });
        if (response.data.status == 200) {
          SuccessToast("Seting Updated Successfully");
        }
      })
      .catch(async (error) => {
        ErrorToast("Something went error");
        await this.setState({ dataLoading: false });
      });
  };

  _handleSubmitConfirmation = () => {
    this._showConfirmPopup();
  };

  WfModal = (visible) => {
    this.setState({ workFlowPopup: visible });
  };

  handleChange(checked) {
    this.setState({ checked });
  }

  _settingCheck2(param, value) {
    if (value == 1) {
      if (param == 0) {
        this.setState({ type_of_act: 1 });
      } else {
        this.setState({ type_of_act: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ hazard: 1 });
      } else {
        this.setState({ hazard: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ observation: 1 });
      } else {
        this.setState({ observation: 0 });
      }
    }
    if (value == 4) {
      if (param == 0) {
        this.setState({ Incident_date: 1 });
      } else {
        this.setState({ Incident_date: 0 });
      }
    }
    if (value == 5) {
      if (param == 0) {
        this.setState({ Incident_time: 1 });
      } else {
        this.setState({ Incident_time: 0 });
      }
    }
    if (value == 6) {
      if (param == 0) {
        this.setState({ person: 1 });
      } else {
        this.setState({ person: 0 });
      }
    }
    if (value == 7) {
      if (param == 0) {
        this.setState({ zone: 1 });
      } else {
        this.setState({ zone: 0 });
      }
    }
    if (value == 8) {
      if (param == 0) {
        this.setState({ location: 1 });
        this.setState({ zone: 1 });
      } else {
        this.setState({ location: 0 });
      }
    }
    if (value == 9) {
      if (param == 0) {
        this.setState({ department: 1 });
      } else {
        this.setState({ department: 0 });
      }
    }
    if (value == 10) {
      if (param == 0) {
        this.setState({ contractor: 1 });
      } else {
        this.setState({ contractor: 0 });
      }
    }
    if (value == 11) {
      if (param == 0) {
        this.setState({ person_id: 1 });
      } else {
        this.setState({ person_id: 0 });
      }
    }
    if (value == 12) {
      if (param == 0) {
        this.setState({ employee_search: 1 });
      } else {
        this.setState({ employee_search: 0 });
      }
    }
  }

  _showConfirmPopup = async () => {
    await this.setState({ showConfirmPopup: true });
  };

  _hideConfirmPopup = async () => {
    await this.setState({ showConfirmPopup: false });
  };

  render() {
    return (
      <div className="profile">
        
        <LoadingView showLoading={this.state.dataLoading} />
        <div className="page-full tour1">
          <div className="profile-top nm-top-head">
            <div className="d-flex-center">
            <h2>
              <Backbutton />
              NM Reporting Form Setting
            </h2>
            <TourWF />
            </div>
            <div className="nm-right-btn">
              <Button
                variant="outline-primary"
                className="nm-back-btn"
                onClick={this._handleSubmitConfirmation}
              >
                SAVE
              </Button>
              {/* <Button variant="outline-primary" className="nm-back-btn">
                BACK TO HOME
              </Button> */}
            </div>
          </div>
          <div className="nm-content">
            <div className="nm-left-content">
              <div className="rd-box nm1">
                <div className="rd-box-head">
                  <h3>Reporting Parameter</h3>
                </div>
                <div className="rd-box-bottom">
                  <div className="label">
                    Select checkbox for mandatory options
                  </div>
                </div>
                <div className="rd-box-bottom">
                  <Row className="row-gap">
                    {/* <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">Type of Act
                          <input name="field.mandatory" type="checkbox"  checked={this.state.type_of_act} onClick={() => this._settingCheck2(this.state.type_of_act, 1)}/>
                          <span className="checkmark1"></span>
                        </label>
                      </div>
                    </Col> */}
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Hazard
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.hazard}
                            onClick={() =>
                              this._settingCheck2(this.state.hazard, 2)
                            }
                          />
                          <span className="checkmark1"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Incident Date
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.Incident_date}
                            // onClick={() =>
                            //   this._settingCheck2(this.state.Incident_date, 4)
                            // }
                            disabled
                          />
                          <span className="checkmark1 disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Incident Time
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.Incident_time}
                            // onClick={() =>
                            //   this._settingCheck2(this.state.Incident_time, 5)
                            // }

                            disabled
                          />
                          <span className="checkmark1 disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Person
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.person}
                            onClick={() =>
                              this._settingCheck2(this.state.person, 6)
                            }
                          />
                          <span className="checkmark1"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Employee Search
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.employee_search}
                            onClick={() =>
                              this._settingCheck2(this.state.employee_search, 12)
                            }
                          />
                          <span className="checkmark1"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          ID
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.person_id}
                            onClick={() =>
                              this._settingCheck2(this.state.person_id, 11)
                            }
                          />
                          <span className="checkmark1"></span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="rd-box nm2">
                <div className="rd-box-head">
                  <h3>Location Based Parameter </h3>
                </div>
                <div className="rd-box-bottom">
                  <div className="label">
                    Select checkbox for mandatory options
                  </div>
                </div>
                <div className="rd-box-bottom">
                  <Row className="row-gap">
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Zone
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.zone}
                            disabled
                          // onClick={() =>
                          //   this._settingCheck2(this.state.zone, 7)
                          // }
                          />
                          <span className="checkmark1  disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Location
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.location}
                            disabled

                          // onClick={() =>
                          //   this._settingCheck2(this.state.location, 8)
                          // }
                          />
                          <span className="checkmark1  disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Department
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.department}
                            // onClick={() =>
                            //   this._settingCheck2(this.state.department, 9)
                            // }
                            disabled
                          />
                          <span className="checkmark1  disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="feild-group">
                        <label className="check-box">
                          Contractor
                          <input
                            name="field.mandatory"
                            type="checkbox"
                            checked={this.state.contractor}
                            disabled
                          // onClick={() =>
                          //   this._settingCheck2(this.state.contractor, 10)
                          // }
                          />
                          <span className="checkmark1  disabled-step"></span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Row>
                <Col sm={6}>
                  <div className="rd-box">
                    <div className="rd-box-head">
                      <h3>System Default Parameter </h3>
                    </div>
                    <div className="rd-box-bottom">
                      <div className="toggle-label">
                        <div className="output-text margin-right">Activity</div>
                      </div>
                    </div>
                    <div className="rd-box-bottom">
                      <div className="toggle-label">
                        <div className="output-text margin-right">Severity</div>
                      </div>
                    </div>
                    <div className="rd-box-bottom">
                      <div className="toggle-label">
                        <div className="output-text margin-right">
                          Observation
                        </div>
                      </div>

                    </div>
                    <div className="container nm4">
                      <div className="rd-box-bottom nm4">
                        <div className="toggle-label">
                          <div className="output-text margin-right my-3">
                            Type Of Act
                          </div>
                        </div>
                      </div>
                      <div className="rd-box-head">
                      </div>
                      <div className="rd-box-bottom">
                        <div className="toggle-label">
                          <div className="output-text margin-right">
                            <p> Incident Analysis Question </p>
                            <div className="feild-group">
                              <label className="check-box">
                                Step 1
                                <input
                                  name="field.mandatory"
                                  type="checkbox"
                                  checked={true}
                                  // onClick={() =>
                                  //   this._settingCheck2(step_1_detail?.parameter_value, 11)
                                  // }
                                  disabled
                                />
                                <span className="checkmark1 disabled-step" />
                              </label>
                            </div>
                          </div>

                        </div>
                        <div className="rd-box-bottom">
                          <div className="toggle-label">
                            <div className="output-text margin-right">
                              <p> Root Cause Analysis </p>
                              <div className="feild-group">
                                <label className="check-box">
                                  Step 2
                                  <input
                                    name="field.mandatory"
                                    type="checkbox"
                                    checked={true}
                                    // onClick={() =>
                                    //   this._settingCheck2(step_1_detail?.parameter_value, 11)
                                    // }
                                    disabled
                                  />
                                  <span className="checkmark1 disabled-step" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rd-box-bottom">
                          <div className="toggle-label">
                            <div className="output-text margin-right">
                              <p> CAPA </p>
                              <div className="feild-group">
                                <label className="check-box">
                                  Step 3
                                  <input
                                    name="field.mandatory"
                                    type="checkbox"
                                    checked={true}
                                    // onClick={() =>
                                    //   this._settingCheck2(step_1_detail?.parameter_value, 11)
                                    // }
                                    disabled
                                  />
                                  <span className="checkmark1 disabled-step" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="rd-box nm3">
                    <div className="rd-box-head">
                      <h3>Evidence </h3>
                    </div>
                    <div className="rd-box-bottom">
                      <div className="label-input1">
                        <div className="output-text margin-top">
                          Number of evidence per report
                        </div>
                        <input
                          type="text"
                          placeholder="Evidence No."
                          className="form-control"
                          value={this.state.evidence_num}
                          onChange={(e) => {
                            this.setState({ evidence_num: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="nm-right-content">
              <div className="rd-box nm0">
                <div className="rd-box-head rd-box-head1">
                  <h3>Sample Preview</h3>
                </div>
                <div className="workflow-form">
                  <div className="form-group">
                    <h6>
                      <RequiredText />
                      Observation
                    </h6>
                    <Mic className="nm-mic" />
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Write your observation here"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <h6>Service & Project</h6>
                    <Row>
                      <Col sm={6}>
                        <div className="label">
                          <RequiredText /> Service
                        </div>
                        <select className="form-control">
                          <option>Select Service</option>
                        </select>
                      </Col>
                      <Col sm={6}>
                        <div className="label">
                          <RequiredText /> Project
                        </div>
                        <select className="form-control">
                          <option>Select Project</option>
                        </select>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <h6>About Incidence</h6>
                    <Row>
                      {this.state.type_of_act == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Type of Act</div>
                            <select className="form-control">
                              <option>Select Act</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      <Col sm={6}>
                        <div className="form-group">
                          <div className="label">
                            <RequiredText /> Activity
                          </div>
                          <select className="form-control">
                            <option>Select Activity</option>
                          </select>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="form-group">
                          <div className="label">
                            <RequiredText /> Severity
                          </div>
                          <select className="form-control">
                            <option>Select Severity</option>
                          </select>
                        </div>
                      </Col>
                      {this.state.hazard == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Hazard</div>
                            <select className="form-control">
                              <option>Select Hazard</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.Incident_date == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Incident Date</div>
                            <select className="form-control">
                              <option>Select Date</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.Incident_time == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Incident Time</div>
                            <select className="form-control">
                              <option>Select Time</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.person == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Name of Person</div>
                            <select className="form-control">
                              <option>Select Person</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.employee_search == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Employee Search</div>
                            <select className="form-control">
                              <option>Select Employee</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.person_id == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label  ">ID</div>
                            <input type="text" className="form-control font-weight-500" placeholder="Enter Id " />
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div>
                    <h6>Department/Contractor/Location</h6>
                    <Row>
                      {this.state.department == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Department</div>
                            <select className="form-control">
                              <option>Select Department</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.contractor == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Contractor</div>
                            <select className="form-control">
                              <option>Select Contractor</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.zone == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Zone</div>
                            <select className="form-control">
                              <option>Select Zone</option>
                            </select>
                          </div>
                        </Col>
                      )}
                      {this.state.location == 1 && (
                        <Col sm={6}>
                          <div className="form-group">
                            <div className="label">Location</div>
                            <select className="form-control">
                              <option>Select Location</option>
                            </select>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div>
                    <h6>Steps</h6>
                    <Row>
                      <Col sm={6}>
                        <div className="form-group">
                          <div className="label">Step 1</div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="form-group">
                          <div className="label">Step 2</div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="form-group">
                          <div className="label">Step 3</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          centered
          show={this.state.workFlowPopup}
          className="submit-modal"
        >
          <Modal.Header
            closeButton
            onClick={() => this.WfModal(false)}
          ></Modal.Header>

          <Modal.Body>
            {/* <img className="submit-img" src={require('assets/images/delete-popup.svg')} /> */}
            <p className="submit-text">UPDATED SUSSESSFULLY</p>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={() => {
                  this.WfModal(false);
                  this._NMWorkFlowView();
                }}
              >
                OK
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <ConfirmModal
          showConfirmPopup={this.state.showConfirmPopup}
          _handleCancel={this._hideConfirmPopup}
          _handleConfirm={this._NMWorkFlow}
        />
      </div>
    );
  }
}
