import {
  Button as SLDButton,
  Checkbox as SLDCheckbox,
  Modal as SLDModal,
  Textarea as SLDTextarea,
} from "@salesforce/design-system-react";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";
import {
  ErrorToast,
  SuccessToast,
} from "app/shared/components/Popups/ToastMessage";
import { DocumentPreviewer } from "app/shared/components/ReusableComponents/DocumentPreviewer/container";
import Url from "app/shared/constants/Url";
import { nmContent } from "../constants/nmConstant";
import ClideLoader from "app/shared/ui-kit/loader";
import { SLDSChat } from "app/views/Ira/Tracker/CapaList";
import axios from "axios";
import FsLightbox from "fslightbox-react";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import AudioPlayer from "react-h5-audio-player";
const qs = require("qs");

class CapaListNMV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...nmContent.capaPageState,
    };
    this._getnmHeaderCount();
    this._getService();
    this._getProject();
    this.selectSeverity = this.selectSeverity.bind(this);
  }

  componentDidMount = () => {
    this._getnmHeaderCount();
    this._getService();
    this._getProject();
    // this.props.setClick(this.nmTrackerList);
  };

  trackerModal = (visible, statusCheck) => {
    this.setState({ trackerFilterPopup: visible, statusCheck: statusCheck });
  };

  _getnmHeaderCount = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.nmModule.nmHeaderCount,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            nmHeaderCount: response.data.data,
            dataLoading: false,
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  _getService = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.serviceList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ service: response.data.data, dataLoading: false });

          var serviceobs = this.state.service.map((item) => item.service_id);
          this.setState({ serviceSelect: serviceobs[0] });
          this._getProject();
        }
        // console.log(this.state.service ? this.state.service : 0)
      })
      .catch((error) => {
        throw error;
      });
  };

  _getProject = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.projectList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: this.state.serviceSelect,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ project: response.data.data, dataLoading: false });

          var projectobs = this.state.project.map((item) => item.project_id);
          this.setState({ projectSelect: projectobs[0] });
        }
        // console.log('result: ',this.state.project ? this.state.project : 0)
      })
      .catch((error) => {
        throw error;
      });
  };

  _handleServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ serviceSelect: selectedServiceID });
      this._getProject();
    }
  };

  resetStates() {
    this.setState({
      ...nmContent.capaResetState,
    });
  }

  selectSeverity(e) {
    const severity = this.state.severity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ severity: severity });
  }

  FilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ extremeSelect: 4, all: 0 });
      } else {
        this.setState({ extremeSelect: 0, all: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ highSelect: 3, all: 0 });
      } else {
        this.setState({ highSelect: 0, all: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ mediumSelect: 2, all: 0 });
      } else {
        this.setState({ mediumSelect: 0, all: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ lowSelect: 1, all: 0 });
      } else {
        this.setState({ lowSelect: 0, all: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, all: 0 });
      } else {
        this.setState({ overdue: 0, all: 0 });
      }
    }
    if (value == "all") {
      if (param == 0) {
        this.setState({
          all: 1,
          extremeSelect: 4,
          highSelect: 3,
          mediumSelect: 2,
          lowSelect: 1,
          severity: [1, 2, 3, 4],
        });
      } else {
        this.setState({
          all: 0,
          extremeSelect: 0,
          highSelect: 0,
          mediumSelect: 0,
          lowSelect: 0,
          severity: [],
        });
      }
    }
  }
  comapareTargetDate = (date = "") => {
    if (date) {
      const targetDate = moment(date, "DD/MM/YYYY");
      const today = moment();
      if (targetDate.isSame(today, "day")) {
        return true;
      }
      return false;
    }
  };

  _handleLightBox = async (list) => {
    console.log(list.map((item) => item.format));
    await this.setState({
      lightboxactive: true,
      fsToggler: !this.state.fsToggler,
      evidencelightbox: list.map((item) => item.evidence),
      evidencelightboxType: list.map(() => "image"),
    });
  };

  modalHideSearch = () => {
    this.setState({
      showPopupSearch: false,
    });
  };
  modalShowSearch = (rec) => {
    this.setState({
      showPopupSearch: true,
      obs_rec: rec,
    });
  };

  __toggleGridView = (tabName) => {
    if (this.state.listMode !== tabName) {
      this.setState({ listMode: tabName });
    }
  };
  __generateTableBody = (capaItem, idx, arr = []) => {
    return (
      <tr key={idx}>
        <td>{capaItem.recommendation?.recommendation || ""}</td>
        <td>{capaItem.recommendation?.status || ""}</td>
        <td>{capaItem.category}</td>
        <td>
          <div className="d-flex ">
            <img src={capaItem?.recommendedby_pic} className="user-icon mr-2" />
            <p className="font-16 common-grey-1">
              {capaItem?.recommendedby || ""}
            </p>
          </div>
        </td>
        <td>
          {capaItem?.tracking_responsible_person?.map((person, index) => (
            <div className="d-flex " key={index}>
              <img src={person?.person_pic} className="user-icon mr-2" />
              <p className="font-16 common-grey-1">{person.person_name}</p>
            </div>
          ))}
        </td>
        <td>{capaItem.overdue_deviation || ""}</td>
        <td>
          <div
            style={{
              backgroundColor: capaItem.priority_color,
            }}
            className="badge font-16 font-weight-500 py-2"
          >
            <div
              className="output-text"
              style={{ color: "#ffffff", textAlign: "center" }}
            >
              {capaItem.priority}
            </div>
          </div>
        </td>
        <td>
          <span
            style={{ color: "rgba(0, 0, 0, 0.7)" }}
            className={`${
              this.comapareTargetDate(capaItem.target_date)
                ? "bg-danger"
                : "bg-warning"
            } text-white  px-1 badge font-16 font-weight-500 py-2`}
          >
            {capaItem.target_date || "N/A"}
          </span>
        </td>
        {arr.length ? (
          <>
            <td>{capaItem.recommendation?.closing_date || "N/A"}</td>
            <td>
              {capaItem.recommendation?.recommendation_evidence?.length && (
                <div
                  style={{ display: "inline-block" }}
                  onClick={() =>
                    this._handleLightBox(
                      capaItem.recommendation?.recommendation_evidence
                    )
                  }
                >
                  <img
                    className="tracker-right cursor-pointer"
                    style={{ height: "1.8vw" }}
                    src={require("assets/images/icons/fimage.svg")}
                    alt="light"
                  />
                </div>
              )}
              {capaItem.recommendation?.observation_rec?.length > 0 ? (
                <div style={{ display: "inline-block" }}>
                  <h6
                    onClick={() =>
                      this.modalShowSearch(
                        capaItem.recommendation.observation_rec
                      )
                    }
                  >
                    <img
                      style={{
                        height: "1.8rem",
                        paddingLeft: "1rem",
                        verticalAlign: "initial",
                      }}
                      src={require("assets/images/icons/play.svg")}
                      className="cursor-pointer"
                      alt="play"
                    />
                  </h6>
                </div>
              ) : null}
            </td>
          </>
        ) : null}
      </tr>
    );
  };
  getFieldProps = (data, columns = []) => {
    return {
      data,
      columns: [
        "CAPA",
        "Status",
        "Category",
        "Proposed By ",
        "Assigned To ",
        `${columns?.length ? "Deviation" : "Overdue"}`,
        "Priority",
        "Due Date",
        ...columns,
      ],
      bodyRendrer: (el, idx) => this.__generateTableBody(el, idx, columns),
    };
  };
  renderFooterAction = () => {
    const dataItems = { ...this.state.recommendList };
    const temp = [
      <SLDButton
        key="promptBtn"
        label="Close"
        onClick={() => {
          this.setState({
            modalParams: {},
          });
        }}
      />,
    ];

    if (dataItems.update_button_status == 1) {
      temp.push(
        <SLDButton
          variant="brand"
          label="Save"
          onClick={() => {
            this.SendUpdateApiStepByStep("save");
          }}
        />,
        <SLDButton
          variant="brand"
          label="Sent For Approval"
          onClick={() => {
            this.SendUpdateApiStepByStep("saveSubmit");
          }}
        />
      );
    }
    if (
      dataItems.send_for_approval_button_status == 1 &&
      dataItems.status !== "Approval Pending"
    ) {
      temp.push(
        <SLDButton
          variant="brand"
          label="Sent For Approval"
          onClick={() => {
            this.SendUpdateApiStepByStep("saveSubmit");
          }}
        />
      );
    }
    if (dataItems.approve_button_status == 1) {
      temp.push(
        <SLDButton
          variant="brand"
          label="Approve"
          onClick={() => {
            this.approveStatusUpdate();
          }}
        />
      );
    }
    if (dataItems.decline_button_status == 1) {
      temp.push(
        <SLDButton
          variant="destructive"
          label="Decline"
          onClick={() => {
            this.setState({
              isDeclineViewEnabled: true,
            });
          }}
        />
      );
    }

    return temp;
  };
  _checkApproveDeclinedButton = (item) => {
    if (item.approve_button_status || item.decline_button_status) {
      return true;
    } else {
      return false;
    }
  };
  checkApprovePersmission = (
    currentObj,
    arr = [],
    listItem = {},
    recommendationNumber,
    nearmiss_id
  ) => {
    const { user_id, designation_flag } = this.state.dflag;
    const el = arr.find((x) => x.person_id === user_id);
    const { send_for_approval_button_status } = listItem.recommendation;
    if (
      !_.isEmpty(el) ||
      ((designation_flag == 1 ||
        designation_flag == 3 ||
        listItem.recommendation?.teamLead_id == user_id) &&
        listItem.recommendation?.status !== "Pending") ||
      (this._checkApproveDeclinedButton(listItem.recommendation) &&
        listItem.recommendation?.status != "Approved")
    ) {
      this.setState({
        modalParams: listItem,
        recommendationNumber,
        isApprovedPersonEnabled:
          designation_flag == 1 ||
          designation_flag == 3 ||
          listItem.recommendation?.teamLead_id == user_id,
        correctiveItem: listItem,
        approval: send_for_approval_button_status,
        recommendList: listItem.recommendation,
        observationText: listItem.recommendation.observation,
        recommendation_id: listItem.recommendation_id,
        nearmiss_id,
        activeService: currentObj.service_id,
        activeProject: currentObj.project_id,
      });
    }
  };
  _checkIsResponsiblePerson = (correctiveItem) => {
    const userID = localStorage.getItem("userId");
    try {
      const assignedPerson =
        correctiveItem.tracking_responsible_person[0].person_id;
      if (userID == assignedPerson) {
        return true;
      }
      return false;
    } catch (error) {}
  };
  correctiveActionList = async () => {
    await this.setState(() => {
      let { recommendList } = this.state;
      if (recommendList.isChecked) {
        recommendList.isChecked = !recommendList.isChecked;
      } else {
        recommendList.isChecked = true;
      }
      return { recommendList };
    });
  };

  renderRenderOnlyView = (dataItems, modalParams) => {
    return (
      <>
        {dataItems.approve_button_status == 1 ||
        dataItems.decline_button_status == 1 ||
        dataItems.status === "Approved" ||
        (this.state?.isApprovedPersonEnabled &&
          dataItems.status === "Declined") ? (
          <div className="m-4">
            <div>
              <div class="slds-form-element">
                <span
                  class="slds-form-element__label"
                  id="file-selector-primary-label-105"
                >
                  Corrective Action
                </span>
                <div>
                  <span className="slds-text-heading_small font-16 font-weight-500">
                    {modalParams?.recommendation?.recommendation}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div class="slds-form-element">
                <span
                  class="slds-form-element__label"
                  id="file-selector-primary-label-105"
                >
                  Action Taken
                </span>
                <div>
                  <span className="slds-text-heading_small font-16 font-weight-500">
                    {this.state.observationText}
                  </span>
                </div>
              </div>
            </div>
            <div class="slds-form-element">
              <span
                class="slds-form-element__label"
                id="file-selector-primary-label-105"
              >
                Evidence
              </span>
              <div className="my-1 ml-2 d-flex common-flex-gap flex-wrap">
                <DocumentPreviewer
                  documentArray={dataItems?.recommendation_evidence}
                  mappingKeys={{
                    id: "id",
                    url: "evidence",
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };
  renderCommentView = (dataItems) => {
    return (
      <>
        {dataItems.recommendation_comments &&
        dataItems.recommendation_comments.length > 0 ? (
          <>
            <div className="mx-4">
              <div class="slds-form-element">
                <span class="slds-form-element__label font-20 font-weight-500">
                  Comments
                </span>
              </div>
              <div>
                <SLDSChat
                  chatArray={dataItems.recommendation_comments}
                  chatMappers={{
                    comment: "comment",
                    id: "comment_id",
                    personId: "commented_by_id",
                    avatar: "commented_by_pic",
                    name: "commented_by_name",
                    post_creationTimeStamp: "post_ago",
                  }}
                  inputPlaceHolder="Reply"
                  callback={(reply) => this.declineCommentUpdate(reply)}
                  isInputEnabled={
                    dataItems.status === "Approved" ? false : true
                  }
                />
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };
  renderDeclineCommentView = () => {
    return this.state.isDeclineViewEnabled ? (
      <div className="mx-4 my-2 ">
        <SLDTextarea
          id="text-area-error"
          name="required-textarea-error"
          label="Decline Message"
          placeholder="Reason of decline"
          value={this.state.declineMessage}
          onChange={(e) =>
            this.setState({
              declineMessage: e.target.value,
            })
          }
        />
        <div className="my-2 ">
          <SLDButton
            variant="outline-brand"
            label="Cancle"
            onClick={() => {
              this.setState({
                isDeclineViewEnabled: false,
                declineMessage: "",
              });
            }}
          />
          <SLDButton
            variant="brand"
            label="Send"
            disabled={this.state.declineMessage === ""}
            onClick={this._declineStatusUpdate}
          />
        </div>
      </div>
    ) : null;
  };
  renderRecomendationUpdate = (modalParams) => {
    const dataItems = { ...this.state.recommendList };
    return (
      <>
        {(dataItems.update_button_status == 1 ||
          dataItems.send_for_approval_button_status == 1 ||
          dataItems.status == "Declined") &&
        this._checkIsResponsiblePerson(modalParams) ? (
          <>
            <div className="m-4">
              <SLDCheckbox
                id="checkbox-example"
                labels={{
                  label: modalParams?.recommendation?.recommendation,
                }}
                checked={dataItems.isChecked || dataItems.observation}
                onChange={() => {
                  this.correctiveActionList(dataItems.id);
                }}
              />
              <div>
                <SLDTextarea
                  // aria-describedby="error-1"
                  id="text-area-error"
                  name="required-textarea-error"
                  label="Action Taken"
                  required
                  // errorText={this.state.actionTakeError ? null : "This field is required"}
                  placeholder="Placeholder Text"
                  value={this.state.observationText}
                  onChange={(e) =>
                    this.setState({
                      observationText: e.target.value,
                      correctionId: dataItems.id,
                    })
                  }
                  disabled={dataItems.status == "Approval Pending"}
                />
                {this.state.actionTakenError ? (
                  <span className="common-red-3 font-weight-500 font-17">
                    This field is required
                  </span>
                ) : null}
              </div>
              <div class="slds-form-element">
                {dataItems.status !== "Approval Pending" ? (
                  <>
                    <span
                      class="slds-form-element__label"
                      id="file-selector-primary-label-105"
                    >
                      Add Evidence
                    </span>
                    <div>
                      <MyDropzone
                        uploadFile={(file) =>
                          this.photoCallbackFunction(file, dataItems.id)
                        }
                        type={"1"}
                      />
                      {this.state.evidenceError ? (
                        <span className="common-red-3 font-weight-500 font-17">
                          This field is required
                        </span>
                      ) : null}
                    </div>
                  </>
                ) : null}

                <div className="my-4 ml-2 d-flex common-flex-gap flex-wrap">
                  <DocumentPreviewer
                    documentArray={dataItems?.recommendation_evidence}
                    mappingKeys={{
                      id: "id",
                      url: "evidence",
                    }}
                    isDeleteCallBackEnable={
                      (dataItems.update_button_status == 1 &&
                        !dataItems.send_for_approval_button_status) ||
                      dataItems.update_button_status == 1 ||
                      (dataItems.send_for_approval_button_status == 1 &&
                        dataItems.status !== "Approval Pending")
                    }
                    deleteItems={(ele) => {
                      this.setState({
                        deleteIds: [...this.state.deleteIds, ele],
                      });
                    }}
                  />
                  {/* {this.state.evidence.length > 0 && <DocumentPreviewer documentArray={[this.state.evidence]} mappingKeys={{
                    id: "id",
                    url: "evidence"
                  }} />
                  } */}
                </div>
              </div>
            </div>
          </>
        ) : null}
        {this.renderRenderOnlyView(dataItems, modalParams)}
        {this.renderDeclineCommentView(dataItems, modalParams)}
        {this.renderCommentView(dataItems, modalParams)}
      </>
    );
  };
  photoCallbackFunction = (file) => {
    this.setState({
      evidence: [file],
    });
  };
  UpdateRecommendFunction = (correctiveActionUpdateType) => {
    let userId = localStorage.getItem("userId");
    let userToken = localStorage.getItem("token");
    let data = new FormData();
    const isFormHavError = this.handleFormValidation();
    if (isFormHavError) {
      return false;
    }
    data.append("auth_key", userToken);
    data.append("user_id", userId);
    data.append("service_id", this.state.activeService);
    data.append("project_id", this.state.activeProject);
    data.append("nearmiss_id", this.state.nearmiss_id);
    data.append("rec_status", 0);
    data.append("recommendation_id", this.state.recommendList.id);
    data.append("corrective_action_id", this.state?.recommendation_id);
    data.append("observation", this.state.observationText);
    if (this.state.deleteIds.length) {
      this.state.deleteIds.forEach((el) => {
        data.append("delete_evidence_ids", el);
      });
    } else {
      data.append("delete_evidence_ids", "");
    }

    if (this.state.evidence?.length > 0) {
      this.state.evidence.map((item) => {
        data.append("file", item);
      });
    }
    if (correctiveActionUpdateType == "save") {
      this._updateCorrectiveAction(data);
    } else if (correctiveActionUpdateType == "saveSubmit") {
      this._saveSubmitCorrectiveAction(data);
    }
  };
  _updateCorrectiveAction = async (data) => {
    const url = Url.clientURL + "/listnm/addrecommendationdata";
    await axios
      .post(url, data, {
        // headers: {
        //   'Content-Type': "multipart/form-data",
        // },
      })
      // .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.props.refetch();
          this.setState({
            modalParams: {},
          });
          SuccessToast("Done");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  _saveSubmitCorrectiveAction = async (data) => {
    const url = Url.clientURL + "/listnm/saveandsendforapproval";
    await axios
      .post(url, data, {
        // headers: {
        //   'Content-Type': "multipart/form-data",
        // },
      })
      // .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.props.refetch();
          this.setState({
            modalParams: {},
          });
          SuccessToast("Send for approval");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleFormValidation = () => {
    const actionTakenError = !this.state.observationText;
    // const evidenceError = _.isEmpty(this.state.evidence);
    this.setState({
      actionTakenError,
    });
    return actionTakenError;
  };
  SendUpdateApiStepByStep = (type) => {
    this.UpdateRecommendFunction(type);
  };
  approveStatusUpdate = async () => {
    const url = Url.clientURL + "/listnm/approvedstatusupdate";
    let userId = localStorage.getItem("userId");
    let userToken = localStorage.getItem("token");

    var data = {
      user_id: userId,
      auth_key: userToken,
      service_id: this.state.activeService,
      project_id: this.state.activeProject,
      nearmiss_id: this.state.nearmiss_id,
      multi_rec_id: this.state.recommendList.id,
      recommendation_id: this.state.recommendation_id,
    };

    console.warn(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    await fetch(url, {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      // .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.props.refetch();
          this.setState({
            modalParams: {},
          });
          SuccessToast("Approved");
          return;
        }
        ErrorToast("Something went wrong");
      })
      .catch(() => {
        ErrorToast("Something went wrong");
      });
  };
  _handleDeclineCommentUpdate = () => {};
  renderFooterAction = () => {
    const dataItems = { ...this.state.recommendList };
    const temp = [
      <SLDButton
        key="promptBtn"
        className="common-black-1"
        label="Close"
        onClick={() => {
          this.setState({
            modalParams: {},
          });
        }}
      />,
    ];

    if (dataItems.update_button_status == 1) {
      temp.push(
        <SLDButton
          variant="brand"
          className="common-orange-btn-layout mx-2 px-3 line-none-1"
          label="Save"
          onClick={() => {
            this.SendUpdateApiStepByStep("save");
          }}
        />
      );

      if (
        dataItems.update_button_status == 1 &&
        !dataItems.send_for_approval_button_status
      ) {
        temp.push(
          <SLDButton
            variant="brand"
            label="Send For Approval"
            className="common-orange-btn-layout mx-2 px-3 line-none-1"
            onClick={() => {
              this.SendUpdateApiStepByStep("saveSubmit");
            }}
          />
        );
      }
    }
    if (
      dataItems.send_for_approval_button_status == 1 &&
      dataItems.status !== "Approval Pending"
    ) {
      temp.push(
        <SLDButton
          variant="brand"
          label="Send For Approval"
          onClick={() => {
            this.SendUpdateApiStepByStep("saveSubmit");
          }}
        />
      );
    }
    if (dataItems.approve_button_status == 1) {
      temp.push(
        <SLDButton
          variant="brand"
          label="Approve"
          onClick={() => {
            this.approveStatusUpdate();
          }}
        />
      );
    }
    if (dataItems.decline_button_status == 1) {
      temp.push(
        <SLDButton
          variant="destructive"
          label="Decline"
          onClick={() => {
            this.setState({
              isDeclineViewEnabled: true,
            });
          }}
        />
      );
    }

    return temp;
  };
  _declineStatusUpdate = async () => {
    let userId = localStorage.getItem("userId");
    let userToken = localStorage.getItem("token");

    var data = {
      user_id: userId,
      auth_key: userToken,
      service_id: this.state.activeService,
      project_id: this.state.activeProject,
      nearmiss_id: this.state.nearmiss_id,
      multi_rec_id: this.state.recommendList.id,
      recommendation_id: this.state.recommendation_id,
      declined_reason: this.state.declineMessage,
    };

    console.warn(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    await fetch(Url.clientURL + "/listnm/declinedstatusupdate", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      // .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            modalParams: {},
          });

          SuccessToast("Comment successfully");
          this.props.refetch();
        }
      })
      .catch(() => {});
  };
  declineCommentUpdate = async (comment) => {
    let userId = localStorage.getItem("userId");
    let userToken = localStorage.getItem("token");
    var data = {
      user_id: userId,
      auth_key: userToken,
      service_id: this.state.activeService,
      project_id: this.state.activeProject,
      near_miss_id: this.state.nearmiss_id,
      corrective_action_id: this.state?.recommendation_id,
      recommendation_id: this.state.recommendList.id,
      comment,
    };

    console.warn(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    await fetch(Url.clientURL + "/listnm/nmreccommentadd", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      // .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            modalParams: {},
          });
          SuccessToast("Replied successfully");
          this.props.refetch();
        }
      })
      .catch(() => {});
  };
  render() {
    const { modalParams, dataLoading, } = this.state;
    const {progressListCount,completedListCount } = this.props ?? {}
    if (dataLoading) {
      return <ClideLoader />;
    }
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 mb-2">
            <div className="clide-v2-card-box">
              <div className=" pe-3 clide-v2-p-0">
                <div className="d-flex border-bottom flex-direction-m">
                  <div className="clide-v2-side-color-box clide-v2-sky">
                    <p className="clide-v2-side-text"> IN PROGRESS | {progressListCount || 0}</p>
                  </div>
                  <div className="clide-v2-scroll-bar p-3 w-100">
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-primary-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-secondary-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-blue-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-green-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-ghostwhite-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-Neonpink-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="clide-v2-card-box">
              <div className="clide-v2-scroll-bar pe-3 clide-v2-p-0">
                <div className="d-flex flex-direction-m">
                  <div className="clide-v2-side-color-box clide-v2-green">
                    <p className="clide-v2-side-text"> COMPLETED | {completedListCount | 0 }</p>
                  </div>
                  <div className="clide-v2-scroll-bar p-3 w-100">
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-primary-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-secondary-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-blue-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-green-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-ghostwhite-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-Neonpink-color mb-3">
                      <div className="d-flex w-100 border-bottom mb-2">
                        <div className="w-75 p-3">UA | Chemical Handling</div>
                        <div className="p-3">
                          <button className="clide-v2-side-button">CAPA</button>
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="row p-1">
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Service</div>
                            <div className=" fw-semibold  clide-v2-fs-5 ">
                              PHARMA SERVICES
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-4">
                            <div>Plant/Project</div>
                            <div className=" fw-semibold  clide-v2-fs-5">
                              Pharma Plant
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-lg-4 text-center">
                            <img className="w-75" src="img/halfcircle.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!!this.state.lightboxactive && (
          <FsLightbox
            toggler={this.state.fsToggler}
            types={this.state.evidencelightboxType}
            disableLocalStorage={true}
            sources={this.state.evidencelightbox}
          />
        )}
        <Modal
          className="reportPopup searchPopup"
          centered
          show={this.state.showPopupSearch}
          onHide={this.modalHideSearch}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <AudioPlayer
                src={this.state.obs_rec}
                showJumpControls={true}
                layout="horizontal-reverse"
                customVolumeControls={[]}
                customAdditionalControls={[]}
                onPlay={(e) => {
                  console.log(e);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
        <SLDModal
          disableClose
          footer={this.renderFooterAction()}
          isOpen={Object.keys(modalParams).length}
          size="small"
          title={
            <span className=" font-18 font-weight-500  w-100">
              {modalParams?.category}
            </span>
          }
        >
          {this.renderRecomendationUpdate(modalParams)}
        </SLDModal>
      </>
    );
  }
}

CapaListNMV2.defaultProps = {
  progress_list: [],
  completed_list: [],
  CategoryFilter: [],
  completedListCount: 0,
  progressListCount: 0,
};

export default CapaListNMV2;

export const AccordionWrapper = ({
  items = [],
  customHeader = false,
  customHeaderClass = "w-100",
  isDefaultOpen = false,
}) => {
  const [openIdx, setOpenIdx] = React.useState([]);
  const [isFirtTimeLoad, setIsFirtTimeLoad] = React.useState(false);

  React.useEffect(() => {
    if (isDefaultOpen && !isFirtTimeLoad) {
      setOpenIdx(Array.from(items.keys()));
      setIsFirtTimeLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultOpen, isFirtTimeLoad]);

  const isPanelOpen = (idx) => {
    return openIdx.includes(idx);
  };
  const manageState = (idx) => {
    if (isPanelOpen(idx)) {
      setOpenIdx(openIdx.filter((el) => el !== idx));
      return;
    }
    setOpenIdx([...openIdx, idx]);
  };
  const renderItem = (el, idx) => {
    const isAriaExpand = isPanelOpen(idx);
    return (
      <li className="slds-accordion__list-item" key={idx}>
        <section
          className={`slds-accordion__section ${
            isAriaExpand ? "slds-is-open" : ""
          }`}
        >
          <div className="slds-accordion__summary">
            <h2 className="slds-accordion__summary-heading d-flex space-between">
              {customHeader ? (
                <div className={customHeaderClass}> {el.title}</div>
              ) : (
                <button
                  className="slds-button slds-button_reset slds-accordion__summary-action"
                  onClick={() => manageState(idx)}
                  aria-controls="referenceId-45"
                  aria-expanded={isAriaExpand}
                  title="Accordion summary"
                >
                  <span className="font-16 font-weight-500 slds-accordion__summary-content">
                    {el.title}
                  </span>
                </button>
              )}

              {isAriaExpand ? (
                <img
                  src={require("assets/sls-icons/Toggle.svg")}
                  alt="Toggle"
                  style={{
                    height: "1.35vw",
                    width: "1.35vw",
                  }}
                  onClick={() => manageState(idx)}
                  className="completed-arrow cursor-pointer"
                />
              ) : (
                <img
                  src={require("assets/sls-icons/arr-right.svg")}
                  alt="Toggle"
                  style={{
                    height: "1.35vw",
                    width: "1.35vw",
                  }}
                  onClick={() => manageState(idx)}
                  className="completed-arrow cursor-pointer"
                />
              )}
            </h2>
          </div>
          <div className="slds-accordion__content" id="referenceId-45">
            {el.content}
          </div>
        </section>
      </li>
    );
  };
  return (
    <ul className="slds-accordion">
      {items?.map((el, idx) => renderItem(el, idx))}
    </ul>
  );
};
