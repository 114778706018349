import React, { Component } from "react";
import { Button, Row, Col } from 'react-bootstrap';
import { Trash, Copy, Edit } from 'react-feather';
import MetaCheckboxes from "../Shared/MetaCheckboxes";
const rfdc = require('rfdc')();

export default class SingleChoicePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
  }

  componentDidMount = async () => {
    /*
    *@Description : in single choice preview passed props data is not proper so updated the props data
    */
    let sectionData = rfdc(this.state.data);

    /* Modifying response data as per requirement */
    if (sectionData.response !== undefined) {

      sectionData.field_response = rfdc(sectionData.response);
      delete sectionData.response;
      sectionData.field_response.map(data => {
        data.logic_status = rfdc(data.status)
        data.field_response = rfdc(data.logic_questions);
        delete data.status;
        delete data.logic_questions;
      });
    }

    /* Modifying section_questions_response data as per requirement */
    if (sectionData.section_questions_response !== undefined) {

      sectionData.field_response = rfdc(sectionData.section_questions_response);
      delete sectionData.section_questions_response;
      sectionData.field_response.map(data => {
        data.logic_status = rfdc(data.status)
        data.field_response = rfdc(data.section_logic_questions);
        delete data.status;
        delete data.section_logic_questions;

        data.field_response.map(subItem => {
          subItem.logic_response = rfdc(subItem.section_logic_response);
          delete subItem.section_logic_response;
        });

      });
    }
    await this.setState(prevState => {
      let { data } = prevState;
      data = sectionData;
      return { data };
    });
  }

  _onDelete = () => {
    this.props.onDelete();
  }

  render() {
    const data = this.state.data;

    return (
      <div className="box-dynamic first-box-dynamic">
        <div className="que-display">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5>{data.question}</h5>
            {data.scoring == 1 &&
              <label>Score :
                <span>{data.total_score}</span>
              </label>
            }
          </div>
          <p>{data.question_description || data.description || ''}</p>
          <div className="que-option">

            {data.field_response &&
              data.field_response.map((response, responseIndex) => (
                <Row>
                  <Col sm={4}>
                    <label className="radio-box" key={`response-${responseIndex}`}>{response.response}
                      <input type="radio" name="radio" disabled />
                      {/* <span className="checkmark" style={{ backgroundColor: response.markAsFailed == 1 ? "red" : "green" }}></span> */}
                      <span className="checkmark" style={{ backgroundColor: data.scoring == 1 ? (response.markAsFailed == 1 ? "red" :   response.markAsNA == 1 ? "gray" : "green" ) : "green" }}></span>
                    </label>
                  </Col>
                  {data.scoring == 1 &&
                    <>
                      <Col sm={2}>
                        <input type="text" name="single_option" placeholder={`Score ${responseIndex + 1}`} className="form-control radio-input" ref={el => this.inputTitle = el} value={response?.markAsNA ? "NA" : response?.score} />
                      </Col>
                      <Col sm={3}>
                        <input type="checkbox" name="radio" checked={response.markAsFailed == 1} disabled />
                        <label name='Marked as failed' style={{ marginLeft: '0.3vw' }}>Marked as failed</label>
                      </Col>
                      <Col sm={2}>
                        <input type="checkbox" name="radio" checked={response.markAsNA == 1} disabled />
                        <label name='Marked as NA' style={{ marginLeft: '0.3vw' }}>Marked as NA</label>
                      </Col>
                    </>
                  }
                </Row>
              ))
            }
            {data.response &&
              data.response.map((response, responseIndex) => (
                <label className="radio-box" key={`response-${responseIndex}`}>{response.response}
                  <input type="radio" name="radio" disabled />
                  <span className="checkmark"></span>
                </label>
              ))
            }
            {data.section_questions_response &&
              data.section_questions_response.map((response, responseIndex) => (
                <label className="radio-box" key={`response-${responseIndex}`}>{response.response}
                  <input type="radio" name="radio" disabled />
                  <span className="checkmark"></span>
                </label>
              ))
            }
          </div>
        </div>

        <MetaCheckboxes data={data} type={`singlechoice`} formType={this.props.formType} />

        <div className="form-dynamic">
          <div className="section-btn">
            {
              this.props.onEdit &&
              <Button className="icon-btn" title="Edit Question" onClick={this.props.onEdit}>
                <Edit className="icon-style btn-color" />
              </Button>
            }
            {
              this.props.onCopy &&
              <Button className="icon-btn" title="Copy Question" onClick={this.props.onCopy}>
                <Copy className="icon-style btn-color" />
              </Button>
            }
            {
              this.props.onDelete &&
              <Button className="icon-btn" title="Delete Question" onClick={() => this._onDelete()}>
                <Trash className="icon-style btn-color" />
              </Button>
            }
          </div>
        </div>
        {
          data.logic === 1 &&
          <div className="logic-view">
            <h6>Logic</h6>
            <div className="que-display">
              <div className="que-option">
                <label className="radio-box">
                  {
                    data.field_response && data.field_response.map((field_response, field_response_index) => {
                      if (field_response.logic_status === 1) {
                        return (
                          field_response.response
                        )
                      }
                    })
                  }
                  <input type="radio" name={`radio-${Math.random()}`} checked="checked" disabled />
                  <span className="checkmark" ></span>
                </label>
              </div>
              {
                data.field_response && data.field_response.map((response, ind) => {
                  if (response.logic_status === 1) {
                    if (response.field_response[0] !== undefined && (response.field_response[0].response_type === 1 || response.field_response[0].response_type === 4)) {
                      return (
                        <div className="que-display" key={`logic-${ind}`}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h5>{`${response.field_response[0].question}`}</h5>
                            {response.field_response[0].scoring == 1 &&
                              <label>Score :
                                <span>{response.field_response[0].logic_total_score}</span>
                              </label>
                            }
                          </div>
                          <p className="gray-para">{response.field_response[0].description}</p>
                          <div className="que-option">
                            {
                              response.field_response[0].logic_response &&
                              response.field_response[0].logic_response.map((resp, respIndex) => (
                                <Row>
                                  <Col sm={4}>
                                    <label className="radio-box" key={`response-${respIndex}`}>{resp.response}
                                      <input type="radio" name="radio" disabled />
                                      {/* <span className="checkmark" style={{ backgroundColor: resp.markAsFailed == 1 ? "red" : "green" }}></span> */}
                                      <span className="checkmark" style={{ backgroundColor: resp.markAsFailed == 1 ? "red" : resp.markAsFailed == 1 ? "gray" : "green" }}></span>
                                    </label>
                                  </Col>
                                  <Col>
                                    {response.field_response[0].scoring == 1 &&
                                      <div style={{ display: 'flex' }}>
                                        <Col sm={2}>
                                          <input type="text" name="single_option" placeholder={`Score ${respIndex + 1}`} className="form-control radio-input" ref={el => this.inputTitle = el} value={resp?.markAsNA ? "NA" : resp?.score} />
                                        </Col>
                                        <Col sm={4}>
                                          <input type="checkbox" name="radio" checked={resp.markAsFailed == 1} disabled />
                                          <label name='Marked as failed' style={{ marginLeft: '0.3vw' }}>Marked as failed</label>
                                        </Col>
                                        <Col sm={4}>
                                          <input type="checkbox" name="radio" checked={resp.markAsNA == 1} disabled />
                                          <label name='Marked as NA' style={{ marginLeft: '0.3vw' }}>Marked as NA</label>
                                        </Col>
                                      </div>
                                    }
                                  </Col>

                                </Row>

                              ))
                            }
                          </div>
                        </div>
                      )
                    } else if (response.field_response[0] && response.field_response[0].response_type === 2) {
                      return (
                        <div className="que-display">
                          <h5>{response.field_response[0].question}</h5>
                          <p className="gray-para">{response.field_response[0].description}</p>
                          <div className="que-option">
                            <div className="form-group">
                              <textarea type="text" name="hours" placeholder="Write your Answer" className="form-control" disabled style={{ backgroundColor: '#FFFFFF' }} />
                            </div>
                          </div>
                        </div>
                      )
                    } else if (response.field_response[0] && response.field_response[0].response_type === 3) {
                      return (
                        <div className="que-display">
                          <h5>{response.field_response[0].question}</h5>
                          <p className="gray-para">{response.field_response[0].description}</p>
                          <div className="que-option">
                            <div className="form-group">
                              <input type="text" name="value" placeholder="Enter Number" className="form-control" disabled style={{ backgroundColor: '#FFFFFF' }} />
                            </div>
                          </div>
                        </div>
                      )
                    } else if (response.field_response[0] && response.field_response[0].response_type === 4) {
                      return (
                        <div className="que-display">
                          <h5>{response.field_response[0].question}</h5>
                          <p className="gray-para">{response.field_response[0].description}</p>
                          <div className="que-option">
                            <div className="form-group">
                              <input type="text" name="value" placeholder="Enter Number" className="form-control" disabled style={{ backgroundColor: '#FFFFFF' }} />
                            </div>
                          </div>
                        </div>
                      )
                    } else if (response.field_response[0] && response.field_response[0].response_type === 5) {
                      return (
                        <div className="que-display">
                          <h5>{response.field_response[0].question}</h5>
                          {/* <p className="gray-para">{data.description}</p> */}
                          <p className="gray-para">{response.field_response[0].description}</p>
                          <div className="que-option">
                            <div className="file-upload">
                              <label for="upload" className="file-upload__label">Browse File</label>
                              <input id="upload" className="file-upload__input" type="file" name="file-upload" disabled />
                            </div>
                          </div>
                        </div>
                      )
                    } else if (response.field_response[0] && response.field_response[0].response_type === 6) {
                      return (
                        <div className="que-display">
                          <h5>{response.field_response[0].question}</h5>
                          <p className="gray-para">{response.field_response[0].description}</p>
                          <div className="que-option">
                            <div className="form-group">
                              <input type="text" name="hours" placeholder="Select Date" className="form-control" disabled style={{ backgroundColor: '#FFFFFF' }} />
                            </div>
                          </div>
                        </div>
                      )
                    } else if (response.field_response[0] && response.field_response[0].response_type === 7) {
                      return (
                        <div className="que-display">
                          <h5>{response.field_response[0].question}</h5>
                          <p className="gray-para">{response.field_response[0].description}</p>
                          <div className="que-option">
                            <div className="form-group">
                              <input type="text" name="hours" placeholder="Add Hours" className="form-control" disabled style={{ backgroundColor: '#FFFFFF' }} />
                            </div>
                          </div>
                        </div>
                      )
                    } else if (response.field_response[0] && response.field_response[0].response_type === 8) {
                      return (
                        <div className="que-display">
                          <h5>{response.field_response[0].question}</h5>
                          <p className="gray-para">{response.field_response[0].description}</p>
                          <div className="que-option">
                            <div className="form-group">
                              <textarea type="text" name="hours" placeholder="Write your Recommendation" className="form-control" disabled style={{ backgroundColor: '#FFFFFF' }} />
                            </div>
                          </div>
                        </div>
                      )
                    }
                  }
                })
              }
            </div>
          </div>
        }
        {
          data.field_response && data.field_response.map((item, index) => (
            item?.logic_status == 1 ? (<MetaCheckboxes data={item.field_response[0]} key={`meta-${index}`} logicRecommendation={true} logicScoring={(item.field_response[0].response_type === 1 || item.field_response[0].response_type === 4) && data.scoring ? true : false} formType={this.props.formType} />) : null
          ))
        }
      </div>

    )
  }
}