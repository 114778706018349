import React, { useState, useEffect } from "react";
import { Tabs, Tab, Nav, Col, Row, Container } from "react-bootstrap";
import axios from "axios";
import Url from "app/shared/constants/Url";
import Highcharts, { charts } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Scrollbars } from "react-custom-scrollbars";
import TourGT from "./TourGT";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Line } from 'react-chartjs-2';
import { config } from "config.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import UaUcGraph from "app/shared/components/PredictiveAnalysis/UaUcGraph";
import CountGraph from "app/views/Graphs/Graphs/CountGraph";

const qs = require("qs");
const mapStateToProps = (state) => {
  const data = { ...state.goal.goal };

  return {
    service_id: data.service_id,
    project_id: data.project_id,
    service_name: data.service_name,
    project_name: data.project_name,
    start_date: data.start_date,
    end_date: data.end_date,
  };
};
const GoalDashboard = (props, project_id) => {
  const { type } = props;
  const [dataLoading, setDataloading] = useState(true);

  const [goalGraph, setGoalGraph] = useState({});
  const [allgoalCount, setAllGoalCount] = useState({});

  useEffect(() => {
    _goalGraph();
  }, [props.project_id, props.start_date, props.end_date]);

  const _goalGraph = async (type = "PROJECT") => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    const data = new FormData();
    if (props.project_id && props.project_id.length > 0) {
      props.project_id.forEach((item) => {
        data.append("project_id", item);
      });
    }
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("start_date", props.start_date);
    data.append("end_date", props.end_date);
    let URL = Url.goal.goalGraph;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
         
          setGoalGraph(response.data.data);
          setAllGoalCount(response.data.data);
        }
      })
      .catch((error) => {
        throw error;
      });
      
  };

  const _getOpenCountGraph = (count) => {
    return {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          count +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: ["#00FF00"],
      series: [
        {
          data: [count || 0],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };
  };

  const CountDoughnut = ({ data, total, height = 150, fontSize = '2.5vw' }) => {
    return (
      data?.length > 0 ? (
        <div style={{ justifyContent: 'center' }}>
          <UaUcGraph
            title=""
            graphData={data}
            total={total}
            height={height}
            customTitles={{ useHTML: true }}
            list={false}
            cutout={'82%'}
            radius={'80%'}
            fontSize={fontSize}
            fontWeight={500}
          />
        </div>
      ) : null
    )
  }
  const CountChart = ({ count, color }) => {
    return <CountGraph
      count={count}
      radius={'77%'}
      cutout={"82%"}
      height={165}
      fontWeight={500}
      fontSize={'2.1vw'}
      color={color}
    />
  }

  //Close Count
  const _getCloseCountGraph = (count) => {
    return {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          count +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: ["#FF0000"],
      series: [
        {
          data: [count || 0],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };
  };

  //total task count
  const _getTaskCountGraph = (count) => {
    return {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          count +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: ["#A4E9FF"],
      series: [
        {
          data: [count || 0],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };
  };

  //Priority chart
  const priorityChart = {
    chart: {
      type: "pie",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {

        return '<b>' + this.point.name + '</b>: ' + this.y;

      }
    },
    title: {
      useHTML: true,
      text:
        '<span style="color:' +
        "#000" +
        "; font-weight:" +
        "bold" +
        "; font-size: " +
        "2vw" +
        "; text-align:" +
        "center" +
        '; height: 36">' +
        goalGraph.totalPriorityCount +
        "</span>",
      verticalAlign: "middle",
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    colors: allgoalCount?.priorityWiseCount?.map((data) => data.color),
    category: allgoalCount?.priorityWiseCount?.map((data) => data.priority),
    series: [
      {
        data: allgoalCount?.priorityWiseCount?.map((data) => ({ y: data.count, name: data.priority })),
        size: "100%",
        innerSize: "84%",
      },
    ],
  };

  //Schedule
  const mod = allgoalCount?.moduletypewise?.map((data) => ({ color: data.color, name: data.name, y: data.count }))

  const moduleChart = {
    chart: {
      type: "pie",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {

        return '<b>' + this.point.name + '</b>: ' + this.y;

      }
    },
    title: {
      useHTML: true,
      text:
        '<span style="color:' +
        "#000" +
        "; font-weight:" +
        "bold" +
        "; font-size: " +
        "2vw" +
        "; text-align:" +
        "center" +
        '; height: 36">' +
        goalGraph?.total_moduletypewise_count +
        "</span>",
      verticalAlign: "middle",
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    colors: allgoalCount?.moduletypewise?.map((data) => data.color),
    category: allgoalCount?.moduletypewise?.map((data) => data.name),
    series: [
      {
        data: mod,
        size: "100%",
        innerSize: "84%",
      },
    ],
  };

  //completed
  const completedChart = {
    chart: {
      type: "pie",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    title: {
      useHTML: true,
      text:
        '<span style="color:' +
        "#000" +
        "; font-weight:" +
        "bold" +
        "; font-size: " +
        "2vw" +
        "; text-align:" +
        "center" +
        '; height: 36">' +
        goalGraph?.total_completed_count +
        "</span>",
      verticalAlign: "middle",
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    colors: allgoalCount?.completed_graph?.map((data) => data.color),
    category: allgoalCount?.completed_graph?.map((data) => data.category),
    series: [
      {
        data: allgoalCount?.completed_graph?.map((data) => data.count),
        size: "100%",
        innerSize: "84%",
      },
    ],
  };

  const ownerChart = {
    chart: {
      type: "pie",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {

        return '<b>' + this.point.name + '</b>: ' + this.y;

      }
    },
    title: {
      useHTML: true,
      text:
        '<span style="color:' +
        "#000" +
        "; font-weight:" +
        "bold" +
        "; font-size: " +
        "2vw" +
        "; text-align:" +
        "center" +
        '; height: 36">' +
        goalGraph?.total_ownertypewise_count +
        "</span>",
      verticalAlign: "middle",
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    colors: allgoalCount?.ownertypewise?.map((data) => data.color),
    category: allgoalCount?.ownertypewise?.map((data) => data.category),
    series: [
      {
        data: allgoalCount?.ownertypewise?.map((data) => ({ y: data.count, name: data.name })),
        size: "100%",
        innerSize: "84%",
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Number',
        position: 'left',
        align: 'center',
        font: {
          weight: 300,
          lineHeight: 1.9,
          family: `"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif`,
          size: 12
        }
      },
      tooltip: {
        backgroundColor: "white",
        bodyColor: "#666666",
        bodyFont: {
          weight: 500
        },
        borderColor: "rgba(0, 0, 0, 0.08)",
        borderWidth: 2,
        cornerRadius: 4,
        bodySpacing: 4,
        padding: {
          top: -10,
          bottom: 8,
          left: 0,
          right: 0
        },
        bodyAlign: 'center',
        displayColors: false,
        titleColor: "transparent"
      },
      datalabels: {
        display: true,
        align: 'top',
        anchor: 'end',
        color: 'black',
      },
    },
    scales: {
      x: {
        display: true,
        offset: true,
        grid: {
          display: false
        },
        ticks: {
          color: "#666666",
          font: {
            size: 11,
            weight: "500"
          },
          padding: 0
        }
      },
      y: {
        display: true,
        beginAtZero: true,
        grid: {
          color: "#ececec"
        },
        ticks: {
          color: "#666666",
          font: {
            size: 11,
            weight: "500"
          },
          padding: 6
        }
      }
    }
  };

  const _DatewiseData = {
    labels: allgoalCount?.datewise_completed?.map((item) => item.date),
    datasets: [
      {
        label: "",
        data: allgoalCount?.datewise_completed?.map((data) => data.count),
        fill: true,
        backgroundColor: "rgba(124, 181, 236,0.2)",
        borderColor: "rgba(124, 181, 236,1)"
      }
    ]
  };

  //closedDate
  const _Datewise = {
    chart: {
      type: "line",
      height: "30%",
    },
    legend: {
      enabled: false,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: allgoalCount?.datewise_completed?.map((item) => item.date),
      crosshair: true,
    },
    yAxis: {
      title: {
        text: "Number",
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
    },
    series: [
      {
        data: allgoalCount?.datewise_completed?.map((data) => ({ y: data.count, name: data.name })),
      },
    ],
  };

  const _monthWiseData = {
    labels: allgoalCount?.monthwise_completed?.map((item) => item.month),
    datasets: [
      {
        label: "",
        data: allgoalCount?.monthwise_completed?.map((data) => data.count),
        fill: true,
        backgroundColor: "rgba(124, 181, 236,0.2)",
        borderColor: "rgba(124, 181, 236,1)"
      }
    ]
  };

  //monthcount
  //closedDate
  const _monthWise = {
    chart: {
      type: "line",
      height: "30%",
    },
    legend: {
      enabled: false,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: allgoalCount?.monthwise_completed?.map((item) => item.month),
      crosshair: true,
    },
    yAxis: {
      title: {
        text: "Number",
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
    },
    series: [
      {
        data: allgoalCount?.monthwise_completed?.map((data) => data.count),
      },
    ],
  };

  return (
    <Container className="open-section">

      <Row>
        <Col md={3}>
          <div className="container">
            <TourGT />
          </div>
          &nbsp; &nbsp;&nbsp;
          <div className="nm-dashboard-box my-3">
            <div className="header-block">
              <h5 className="header-title">Modulewise Goal Count</h5>
            </div>
            <div className="graph-block">
              {config.isChartJsEnabled ?
                (<CountDoughnut data={mod} total={goalGraph?.total_moduletypewise_count} />)
                : (<HighchartsReact highcharts={Highcharts} options={moduleChart} />)}
            </div>
            <Scrollbars style={{ height: `6vw` }}>
              <div className="outer-legend-block">
                {allgoalCount?.moduletypewise?.map((data, index) => (
                  <div className="soi-kanban-inner-legend-block" key={index}>
                    <span
                      className="soi-kanban-modal-legend-box"
                      style={{
                        backgroundColor:
                          data.color != "" ? data.color : "#FAB95C",
                      }}
                    ></span>
                    <span className="sm-graph-modal-legend-text">
                      {data.name}
                    </span>
                    <span className="legend-number">{data.count}</span>
                  </div>
                ))}
              </div>
            </Scrollbars>

          </div>


          <div className="nm-dashboard-box my-3">
            <div className="header-block">
              <h5 className="header-title">Ownerwise Goal Count</h5>
            </div>
            <div className="graph-block">
              {config.isChartJsEnabled ?
                (<CountDoughnut
                  data={allgoalCount?.ownertypewise?.map((data) => ({ y: data.count, name: data.name, color: data.color }))}
                  total={goalGraph?.total_ownertypewise_count} />)
                : (<HighchartsReact highcharts={Highcharts} options={ownerChart} />)}
            </div>
            <Scrollbars style={{ height: `6vw` }}>
              <div className="outer-legend-block">
                {allgoalCount?.ownertypewise?.map((data, index) => (
                  <div className="soi-kanban-inner-legend-block" key={index}>
                    <span
                      className="soi-kanban-modal-legend-box"
                      style={{
                        backgroundColor:
                          data.color != "" ? data.color : "#FAB95C",
                      }}
                    ></span>
                    <span className="sm-graph-modal-legend-text">
                      {data.name}
                    </span>
                    <span className="legend-number">{data.count}</span>
                  </div>
                ))}
              </div>
            </Scrollbars>
          </div>
        </Col>
        <Col md={9}>
          <Row>
            <Col sm={12}>
              <div className="nm-dashboard-box my-3">
                <div className="header-block">
                  <h5 className="header-title">Datewise Closed Count</h5>
                </div>
                <div className="trends-graph">
                  {config.isChartJsEnabled ? (
                    <Line
                      data={_DatewiseData}
                      plugins={[ChartDataLabels]}
                      options={lineOptions}
                      height={90}
                    />
                  ) :
                    (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={_Datewise}
                      />)}
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title">Monthwise Closed Count</h5>
                </div>
                <div className="trends-graph">
                  {config.isChartJsEnabled ? (
                    <Line
                      data={_monthWiseData}
                      plugins={[ChartDataLabels]}
                      options={lineOptions}
                      height={90}
                    />
                  ) :
                    (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={_monthWise}
                      />)}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={12}>
          <Row>
            <Col sm={3}>
              <div className="nm-dashboard-box" style={{ marginLeft: "-1vw" }}>
                <div className="header-block">
                  <h5 className="header-title">Prioritywise Open Count</h5>
                </div>
                <Row noGutters={true}>
                  <Col sm={6}>
                    <div className="graph-block">
                      {config.isChartJsEnabled ?
                        (<CountDoughnut
                          data={allgoalCount?.priorityWiseCount?.map((data) => ({ y: data.count, name: data.priority, color: data.color }))}
                          total={goalGraph.totalPriorityCount}
                        />)
                        : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={priorityChart}
                          />)}
                    </div>
                  </Col>
                  <Col sm={6} className="right-legend">
                    <Scrollbars style={{ height: `8vw` }}>
                      <div className="outer-legend-block">
                        {allgoalCount?.priorityWiseCount?.map((data, index) => (
                          <div
                            className="soi-kanban-inner-legend-block"
                            key={index}
                          >
                            <span
                              className="soi-kanban-modal-legend-box"
                              style={{
                                backgroundColor:
                                  data.color != "" ? data.color : "#FAB95C",
                              }}
                            ></span>
                            <span className="sm-graph-modal-legend-text">
                              {data.priority}
                            </span>
                            <span className="legend-number">{data.count}</span>
                          </div>
                        ))}
                      </div>
                    </Scrollbars>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={3}>
              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title">Open Task Count</h5>
                </div>
                <div className="graph-block">
                  {config.isChartJsEnabled ? 
                  <CountChart
                    count={goalGraph?.total_open_count}
                    color={'#00FF00'}
                  />
                    :
                    (<HighchartsReact
                      highcharts={Highcharts}
                      options={_getOpenCountGraph(goalGraph?.total_open_count)}
                    />)}
                </div>
              </div>
            </Col>

            {/* Close Count */}
            <Col sm={3}>
              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title">Close Task Count</h5>
                </div>
                <div className="graph-block">
                {config.isChartJsEnabled ? 
                  <CountChart
                    count={goalGraph?.total_close_count}
                    color={'#FF0000'}
                  />
                    :
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={_getCloseCountGraph(goalGraph?.total_close_count)}
                  />}
                </div>
              </div>
            </Col>

            {/* total task count */}
            <Col sm={3}>
              <div className="nm-dashboard-box">
                <div className="header-block">
                  <h5 className="header-title">Total Task Count</h5>
                </div>
                <div className="graph-block">
                  {config.isChartJsEnabled ? 
                  <CountChart
                    count={goalGraph?.total_task_count}
                    color={'#A4E9FF'}
                  />
                    :
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={_getTaskCountGraph(goalGraph?.total_task_count)}
                  />}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

//export default GoalDashboard;
export default withRouter(
  connect(mapStateToProps)(GoalDashboard)
);

const styles = {
  today_div: {
    position: "relative",
    width: "70vw",
  },

  right_pane: {
    position: "fixed",
    width: "18vw",
    height: "100vw",
    minHeight: "calc(100vh - 4.5vw)",
    right: "2vw",
    top: "5vw",
    // "background": "gray",
  },
  row_flex: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
};
