import React from 'react';

const MetaCheckboxes = ({ data, type, logicRecommendation, hideEvidence, logicScoring, formType }) => {

	

	return (
		<div>
			{
				<label className="check-box">Mandatory
					<input type="checkbox" defaultChecked={data.mandatory} disabled /><span className="checkmark1"></span>
				</label>
			}
			{/* {(!(formType == 8 || formType == 11 || formType == 13 || ((formType == 1 || formType == 2 || formType == 3 || formType == 4 || formType == 6 || formType == 7 || formType == 9) && type === 'field' ) || ((formType == 8 || formType == 11 || formType == 13) && type === 'singlechoice'))) &&
				<label className="check-box">Notes
					<input type="checkbox" defaultChecked={data.notes} disabled /><span className="checkmark1"></span>
				</label>
			} */}

{(!(formType == 8 || formType == 11 || formType == 13 || ((formType == 1 ) && type === 'field' ) || ((formType == 8 || formType == 11 || formType == 13) && type === 'singlechoice'))) &&
				<label className="check-box">Notes
					<input type="checkbox" defaultChecked={data.notes} disabled /><span className="checkmark1"></span>
				</label>
			}


			{/* {!hideEvidence &&  */}
			{/* {(!(formType == 8 || formType == 11 || formType == 13 || formType == 12 || (formType == 12 && type === 'field') || ((formType == 2 || formType == 3 || formType == 4 || formType == 6 || formType == 7 || formType == 9) && type == 'upload-field'))) &&
				<label className="check-box">Evidence
					<input type="checkbox" defaultChecked={data.evidence} disabled /><span className="checkmark1"></span>
				</label>
			} */}

			{(!(formType == 8 || formType == 11 || formType == 13 || formType == 12  || ((formType == 2 || formType == 3 || formType == 4 || formType == 6 || formType == 7 || formType == 9 || formType == 5 ) && type == 'upload-field'))) &&
				<label className="check-box">Evidence
					<input type="checkbox" defaultChecked={data.evidence} disabled /><span className="checkmark1"></span>
				</label>
			}

			{
				type === 'singlechoice' &&
				<label className="check-box">Logic
					<input type="checkbox" defaultChecked={data.logic} disabled /><span className="checkmark1"></span>
				</label>
			}
			{
				(!(formType == 1 || formType == 5 || formType == 6 || formType == 7 || formType == 9 || formType == 12 || formType == 8 || formType == 11 || formType == 13 || formType == 3 || formType == 4 || formType == 10) && (type === 'singlechoice' || logicRecommendation)) &&
				<label className="check-box">Recommendation
					<input type="checkbox" defaultChecked={data.recommendation} disabled /><span className="checkmark1"></span>
				</label>
			}
			{
				(!(formType == 1 || formType == 5 || formType == 8 || formType == 11) && (type === 'singlechoice' || logicScoring)) &&
				<label className="check-box">Scoring
					<input type="checkbox" defaultChecked={data.scoring} disabled /><span className="checkmark1"></span>
				</label>
			}
		</div>
	)
}

MetaCheckboxes.defaultProps = {
	data: {},
	type: 'field'
}

export default MetaCheckboxes;