import React from "react";
import _ from "lodash";

const TabsV2 = ({ tabs = [], currentItem = {}, handleChange = () => {} }) => {
  const [activeItem, setActiveItem] = React.useState(tabs[0] ?? {});

  const manageUpdate = (el) => {
    setActiveItem(el);
    handleChange(el);
  };

  React.useEffect(() => {
    if (!_.isEmpty(currentItem)) {
      setActiveItem(currentItem);
    }
  }, [currentItem]);

  return (
    <div className="clide-v2-live-feed-tabs mb-2 border-bottom">
      {tabs?.map((el) => (
        <div
          className={`  pb-2  ${
            activeItem?.key === el.key
              ? " clide-v2-common-border-b-orange-1 "
              : ""
          }`}
        >
          <button
            className={`  border-0 bg-transparent text-left w-100 px-0  ${
              activeItem?.key === el.key ? "clide-v2-oreange-1 " : ""
            }`}
            onClick={() => manageUpdate(el)}
          >
            {el.tabName}
          </button>
        </div>
      ))}
    </div>
  );
};

export default TabsV2;
