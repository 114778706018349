import React, { Component } from "react";

export default class AddDate extends Component{
  state = {
    date: new Date(),
  }

  onChange = date => this.setState({ date })

  render() {
    return (
			<div className="form-dynamic">
        <div className="form-group">
          <input type="text" name="" placeholder="Select Date" className="form-control" disabled/>
        </div>
			</div>
    )
  }
}