/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { AssignUser } from "app/shared/components/Popups";
import { Button } from "react-bootstrap";
import {
  Info,
  Trello,
  Menu,
  Plus,
  User,
  ShoppingCart,
  Download,
} from "react-feather";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import InfoComponent from "app/shared/utility/InfoComponent";
import FormModal from "app/shared/components/FormModal/FormModal";
import FormTypeDropDown from "../../../shared/components/ReportModal/FormTypeDropDown";
import FormImportModal from "../FormImportModal/FormImportModal";

const formPath = {
  2: "/downloads/templateFormSamples/audit_sample_template.xlsx",
};

const MenuList = ({ menuArr, formType = "" }) => {
  const [modelStatus, setModalStatus] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  // const [selectedFormType, setSelectedFormType] = useState("");
  // const [typeList, setTypeList] = useState([
  //   { type_id: 1, form_type: "Data Collection" },
  //   { type_id: 2, form_type: "Audit" },
  //   { type_id: 3, form_type: "Inspection" },
  //   { type_id: 4, form_type: "Checklist" },
  //   { type_id: 5, form_type: "Work Permit" },
  //   { type_id: 8, form_type: "Survey" },
  //   { type_id: 9, form_type: "Mock Drill" },
  //   { type_id: 10, form_type: "Contractor Assessment" },
  //   { type_id: 11, form_type: "Feedback" },
  //   { type_id: 12, form_type: "E-Learning" },
  //   { type_id: 13, form_type: "Assessment" },
  //   { type_id: 14, form_type: "Safety Meeting" },
  //   { type_id: 15, form_type: "Poll" },
  //   { type_id: 16, form_type: "Good Practice" },
  //   { type_id: 17, form_type: "Task Management" },
  // ]);

  const LOCATION = useLocation();

  const handleAssignUserModal = async () => {
    setModalStatus(!modelStatus);
  };

  const _handleFormModal = async () => {
    await setFormModal(!formModal);
  };

  const _handleDocumentModal = async () => {
    setShowDocumentModal(!showDocumentModal);
  };

  return (
    <div>
      <div className="top-right published-right-btn">
        {menuArr.dashboard && (
          <Link to="/dashboard">
            <Button
              className={`icon-btn ${
                LOCATION.pathname == "/dashboard" ? `menu-list-active` : ``
              }`}
              title="Dashboard"
            >
              <Trello className="icon-style" />
            </Button>
          </Link>
        )}
        {menuArr.published_form && (
          <Link to="/published-from">
            <Button
              className={`icon-btn ${
                LOCATION.pathname == "/published-from" ? `menu-list-active` : ``
              }`}
              title="Published form"
            >
              <Menu
                className="icon-style "
                style={{ height: "1.8vw", position: "relative", top: "-7px" }}
              />
            </Button>
          </Link>
        )}

        {menuArr.sampleDownload && <DownloadSampleComponent value={formType} />}
        {userInfo.designation_flag === 1 && menuArr?.isTemplateUploadEnabed && (
          <Button
            onClick={_handleDocumentModal}
            className="icon-btn "
            title="Upload Template"
          >
            <img
              src={require("assets/sls-icons/V2. New Development/File Upload.svg")}
              className="mt-4 mr-1"
              style={{ height: "1.8vw", position: "relative", top: "-19px" }}
            />
          </Button>
        )}
        {/* {menuArr.form_type && (
          <FormTypeDropDown
              // title="Do's & Dont's / Training / Policy"
              title="Filter"
              isRequired={false}
              optionList={typeList}
               className="filter"
            />
        )} */}
        {menuArr.created_form && (
          // <Link to="/create-form">
          <Button
            className={`icon-btn p5 ${
              LOCATION.pathname == "/create-form" ? `menu-list-active` : ``
            }`}
            title="Create new form"
            onClick={_handleFormModal}
            style={{ padding: "0" }}
          >
            <img
              src={require("assets/sls-icons/V2. New Development/Add +.svg")}
              className="mt-4 mr-1"
              style={{ height: "1.8vw", position: "relative", top: "-19px" }}
            />
          </Button>
          // </Link>
        )}
        {menuArr.assign_user && (
          <Button
            className="icon-btn p6"
            onClick={handleAssignUserModal}
            title="Assign User"
            style={{ padding: "0" }}
          >
            <img
              src={require("assets/sls-icons/V2. New Development/Add.svg")}
              className="mt-4 mr-1 svg-icons"
              style={{ height: "1.8vw", position: "relative", top: "-19px" }}
            />
          </Button>
        )}

        {menuArr.market_place && (
          <Link to="/market-place">
            <Button
              className={`icon-btn ${
                LOCATION.pathname == "/market-place" ? `menu-list-active` : ``
              }`}
              title="Market place "
            >
              <ShoppingCart className="icon-style" />
            </Button>
          </Link>
        )}
        {menuArr.info && <InfoComponent tooltipText={menuArr.infoText} />}
      </div>
      {modelStatus ? (
        <AssignUser
          showModal={modelStatus}
          closeModal={handleAssignUserModal}
        />
      ) : null}

      {!!formModal && <FormModal handleClose={_handleFormModal} />}
      {!!showDocumentModal && (
        <FormImportModal handleClose={_handleDocumentModal} />
      )}
    </div>
  );
};

MenuList.defaultProps = {
  menuArr: {},
};

export default MenuList;

const DownloadSampleComponent = ({ value }) => {
  return (
    <a
      href={formPath[value]}
      className="text-black-50 mb-3 cursor-pointer"
      target="_blank"
      rel="noopener noreferrer"
      title="Download Template Sample"
      download
    >
      <Download size={20} />
    </a>
  );
};
