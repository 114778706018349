import React, { useState } from "react";
import { Button, Table, Modal, Row, Col } from "react-bootstrap";
import { usePreventPaste } from "app/hook";

const ErrorBlock = ({ message }) => (
  <div className="invalid-feedback">{message || ""}</div>
);

const ConfirmModal = ({ showConfirmPopup, _handleCancel, _handleConfirm }) => {
  const [confirmWord, setConfirmWord] = useState("");
  const [confirmError, setConfirmError] = useState(null);

  const _preventPaste = usePreventPaste();

  const _handleChange = (event) => {
    const value = event.target.value;
    if (value === "CONFIRM") {
      setConfirmError(false);
    } else {
      setConfirmError(true);
    }
    setConfirmWord(value);
  };

  const _hideConfirmPopup = () => {
    setConfirmWord("");
    setConfirmError(false);
    _handleCancel();
  };

  const _handleConfirmSubmit = () => {
    if (confirmWord == "CONFIRM") {
      _handleConfirm();
      _hideConfirmPopup();
    } else {
      setConfirmError(true);
      return false;
    }
  };

  return (
    <Modal centered show={showConfirmPopup} className="submit-modal">
      <Modal.Body>
        <img
          className="submit-img img-fluid"
          src={require("assets/images/confirm-popup.svg")}
        />
        <p className="submit-text">
          The setting are important and any changes to form setting may affect
          the reporting workflow. If you are sure the changes are necessary for
          reporting please enter "CONFIRM" in capital letter and press Save.
        </p>
        <div className="form-group">
          <input
            type="text"
            className={`form-control ${confirmError ? "is-invalid" : "border"}`}
            onChange={_handleChange}
            onPaste={(event) => _preventPaste(event)}
            maxLength={30}
            value={confirmWord}
          />
          <ErrorBlock message={confirmError ? `Enter Valid Word` : ``} />
        </div>

        <div className="modal-footer-btn">
          <button
            type="submit"
            className="gray-btn"
            onClick={_hideConfirmPopup}
          >
            CANCEL
          </button>
          <button
            type="submit"
            className="red-btn"
            onClick={_handleConfirmSubmit}
          >
            SAVE
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  showConfirmPopup: false,
  _handleCancel: () => {},
  cancelSuccess: () => {},
};

export default ConfirmModal;
