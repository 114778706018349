const ProbableRootCause = (data) => {
  let CFHtml = "";
  let RCAHtml = "";
  let ImmediateHtml = "";

  if (data.contrubiting_factor_list) {
    data.contrubiting_factor_list.forEach((item, index) => {
      CFHtml += `<tr><td style="color: black;">${item.factor}</td></tr>`;
    });
  }

  if (data.immediate_factor_list) {
    data.immediate_factor_list.forEach((item, index) => {
      ImmediateHtml += `<tr><td style="color: black;">${item.factor}</td></tr>`;
    });
  }

  if (data.RCA_factor_list) {
    data.RCA_factor_list.forEach((item, index) => {
      RCAHtml += `<tr><td style="color: black;">${item.factor}</td></tr>`;
    });
  }

  return `
    <style>
      body {
        margin: 0;
        padding: 0;
        font-family: Arial, sans-serif;
      }

      .container {
        width: 100%;
        max-width: 800px;
        margin: 10px auto;
        border: 5px double #797878;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .content {
        padding: 16px;
        text-align: center;
      }

      h1 {
        font-size: 24px;
        margin: 10px 0;
      }

      h2, h4 {
        font-size: 16px;
        margin: 10px 0;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        margin-bottom: 20px;
        border: 1px solid #ccc;
      }

      td {
        padding: 8px;
        border: 1px solid #ccc;
      }

      .page-number {
        display: block;
        text-align: center;
        margin-top: 20px;
      }
    </style>
    <div class="container">
      <div class="content">
        <h1>NM Investigation Report</h1>
        <h2>${data.heading || 'N/A'}</h2>
        
        <h4 style="font-weight: bold;">Activity</h4>
        <table style="margin: 0 auto;">${CFHtml}</table>
        
        <h4 style="font-weight: bold;">Immediate Factor</h4>
        <table>${ImmediateHtml}</table>
        
        <h4 style="font-weight: bold;">Root Causes</h4>
        <table>${RCAHtml}</table>
        
        <span class="page-number">Page 7</span>
      </div>
    </div>
  `;
};

export default ProbableRootCause;
