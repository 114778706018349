import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Url from "app/shared/constants/Url";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import { connect } from "react-redux";
import CommonDropdown from "../ReportModal/CommonDropdown";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { requiredMessage } from "app/shared/constants/Messages";
const qs = require("qs");

const mapStateToProps = (state) => {
  const data = [...state.permitModule.permittedModuleList];
  return {
    permittedModuleList: data,
  };
};

class CloseModal extends Component {
  constructor(props) {
    super(props);
    let time = new Date();
    let addedTime = new Date();
    addedTime = addedTime.setHours(time.getHours() + 1);
    this.state = {
      closingReasonList: [],
      closingActionId: null,
      closingActionError: false,
      closingRemarkText: null,
      closingRemarkTextError: false,
      reasonList: [],
      dropDownTitle: null,
      selectedEndDate: addedTime,
      maxDatepickerDate: new Date(),
      url: "",
      optionsError: false,
      optionList: [],
    };
  }

  componentDidMount = async () => {
    await this._fetchCategoryList();

    var title = "Closing";
    // var list = this.state.closingReasonList;
    var tempUrl = Url.goal.changeStatus;
    switch (this.props.data.activity) {
      case "close":
        title = "Closing";
        // list = this.state.closingReasonList;
        tempUrl = Url.goal.changeStatus;
        break;
      default:
        title = "Closing";
        // list = this.state.closingReasonList;
        tempUrl = Url.goal.changeStatus;
    }
    await this.setState((prevState) => {
      let { dropDownTitle, reasonList, url } = prevState;
      dropDownTitle = title;
      // reasonList = list;
      url = tempUrl;
      return { dropDownTitle, reasonList, url };
    });
  };

  _fetchCategoryList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      form_type: this.props.data.type == 4 ? 22 : 21,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .post(Url.formCategoryList, qs.stringify(requestData), {
        headers: ContentType,
      })
      .then((response) => {
        if (response.data.status === 200) {
          var list = response.data.data.map((data) => {
            delete data.description;
            return data;
          });
          this.setState({ reasonList: list });
        }
      })
      .catch((error) => {});
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _handleChange = async (item, type) => {
    await this.setState((prevState) => {
      switch (type) {
        case "closing_remark":
          let { closingRemarkText, closingRemarkError } = prevState;
          closingRemarkText = item;
          closingRemarkError = !item ? true : false;
          return { closingRemarkText, closingRemarkError };
        default:
          return prevState;
      }
    });
  };

  _closingActionChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { closingActionId, closingActionError } = prevState;
      closingActionId = id;
      closingActionError = id ? false : true;
      return { closingActionId, closingActionError };
    });
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.closingRemarkError = tempObj.closingRemarkText ? false : true;
      prevState.closingActionError = tempObj.closingActionId ? false : true;

      //  if (this.state.optionList.length < 2) {
      //   prevState.closingActionError = true;
      // } else {
      //  prevState.closingActionError = false;
      // }

      return prevState;
    });
  };

  _changeGoalStatus = async () => {
    debugger
    await this._formValidation();

    // Checking form have any Error
    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          formErrorStatus = true;
          return;
        } else {
          formErrorStatus = false;
        }
      }
    }

    if (!formErrorStatus) {
      this.setState({ dataLoading: true });
      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");
      let URL =
        this.props.data.type == 4
          ? Url.taskManagement.changeStatus
          : Url.goal.changeStatus;

      await axios
        .post(
          URL,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            status: this.props.data.status,
            goal_id: this.props.data.goalId,
            task_id: this.props.data.taskId,
            type: this.props.data.type,
            closing_reason: this.state.closingActionId,
            closing_remark: this.state.closingRemarkText,
            module: this.props.data.module,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
           

            if (this.props.data.module === "goal") {
              this.props.history.push(
                `/goal-completed/${this.props.data.goalId}`
              );
              window.location.reload()
              this.props.handleClose();
            } else {
              // if(this.props.refetch) {
              //   this.props.refetch();
              // }
            }
            this.props.handleClose();
            // window.location.reload()

            // window.location.reload();
          }
          this.setState({ dataLoading: false });
          //this.props.handleClose();
        })
        .catch((error) => {
          this.setState({ dataLoading: false });
        });
    }
  };

  render() {
    return (
      <div>
        <Modal
          show={true}
          onHide={this.props.handleClose}
          className="closePopup"
          backdrop="static"
          keyboard={false}
          // style={{ width: "20vw" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{`${this.state.dropDownTitle} Remark & Reasons`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Data Loading  */}
            <div
              className="loader"
              style={{ display: this.state.dataLoading ? "block" : "none" }}
            >
              <LoadingImage />
            </div>
            <div className="rd-content">
              <div className="rd-box">
                <div className="form-group">
                  <div className="rd-box-head">
                    <h3>
                      {`${this.state.dropDownTitle} Remark`}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h3>
                  </div>
                  <input
                    className="form-control"
                    onChange={(event) =>
                      this._handleChange(event.target.value, "closing_remark")
                    }
                    value={this.state.closingRemarkText}
                    // placeholder={this._dynamicObservationPlaceHolder()}
                  ></input>
                  {!!this.state.closingRemarkError && (
                    <p className="inputErrorMsg">{requiredMessage}</p>
                  )}
                </div>
                <CommonDropdown
                  title={`${this.state.dropDownTitle} Reason`}
                  optionList={this.state.reasonList}
                  onChangeHandle={this._closingActionChangeHandle}
                  isRequired={true}
                  value={this.state.closingActionId}
                />
                {!!this.state.closingActionError && (
                  <p className="inputErrorMsg">{requiredMessage}</p>
                )}
              </div>
            </div>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={this._changeGoalStatus}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(CloseModal));
