import { AiOutlineDelete, AiOutlineUserAdd } from "react-icons/ai";
import { Button, Col, Row, Tab, Table, Tabs, Badge } from "react-bootstrap";
import {
  Edit2,
  Plus,
  Save,
  Trash,
  List,
  Grid,
  Users,
  Edit,
  Trash2,
  XCircle,
  CheckCircle,
} from "react-feather";
import Highcharts, { charts } from "highcharts";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import React, { Component, Suspense } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import {
  requiredMessage,
  userFormSubmitMessage,
  validHoursMessage,
  validNumberMessage,
} from "app/shared/constants/Messages";
import { Chrono } from "react-chrono";

import AlertMessage from "../Popups/AlertMessage";
import Backbutton from "app/shared/components/Backbutton";
import { Calendar, Search } from "react-feather";
import CloseModal from "./CloseModal";
import CommonDropdown from "app/views/TaskManagement/CommonDropdown";
import CompletePopup from "./CompletePopup";
import CreateTaskModal from "../CreateTask/CreateTaskModal";
import DatePicker from "react-datepicker";
import { DeletePopup } from "app/shared/components/Popups";
import { FcCopyright } from "react-icons/fc";
import { FcList } from "react-icons/fc";
import { FiSave } from "react-icons/fi";
import GoalDashboard from "app/shared/components/Goal/GoalManagement/Tabs/GoalDashboard";
import GoalTableList from "app/shared/components/Goal/GoalManagement/Tabs/GoalTableList";
import HighchartsReact from "highcharts-react-official";
import { ImCross } from "react-icons/im";
import InactivePopup from "./InactivePopup";
import InprogressPopup from "./InprogressPopup";
import InprogressValPopup from "./InprogressValPopup";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import ProjectList from "../ReportModal/ProjectList";
import { RiDeleteBin2Line } from "react-icons/ri";
import { Scrollbars } from "react-custom-scrollbars";
import ServiceList from "../ReportModal/ServiceList";
import Url from "app/shared/constants/Url";
import axios from "axios";
import { connect } from "react-redux";
import { convertDateToUTC } from "app/shared/utility/tzUtilFunction";
import { DateTableComponent as DataTable } from "../ReusableComponents/DataTable";
import { isValid } from "app/shared/utility/UtilFunction";
import moment from "moment";
// import { Table as TableAntd } from 'antd';
const qs = require("qs");
const mapStateToProps = (state) => {
  const data = { ...state.goal.goal };

  return {
    service_id: data.service_id,
    project_id: data.project_id,
    service_name: data.service_name,
    project_name: data.project_name,
  };
};
const taskColumns = [
  {
    title: "Task",
    dataIndex: "task",
    key: "task",
    render: (_, data) => {
      return (
        <div>
          {" "}
          <Link to={`/task-management/task/${data.task_id}/goal`}>
            <p
              className="form-view-text"
              style={{
                marginTop: "2.5vw",
              }}
            >
              {data.task}
            </p>
          </Link>
        </div>
      );
    },
  },
];

class Goal extends Component {
  constructor(props) {
    super(props);
    this.outerDiv = React.createRef();
    var date = new Date();
    this.state = {
      goalId: this.props.match.params.goalId,
      taskId: this.props.match.params.task_id,
      listMode: "table",
      goalDetails: {},
      dataLoading: true,
      formSubmitted: false,
      redirectToReferrer: false,
      goalOwnerTypeList: [
        { type_id: 1, owner_type: "Corporate" },
        { type_id: 2, owner_type: "Individual" },
        { type_id: 3, owner_type: "Service/project" },
        { type_id: 4, owner_type: "All" },
        { type_id: 5, owner_type: "Self" },
      ],
      moduleTypeList: [],
      addTask: false,
      showCreateTaskModal: false,
      closeModal: false,
      closeData: {},
      showGoalDeletePopup: false,
      deleteId: null,
      isFirstFetching: false,
      showInactivePopup: false,
      showCompletePopup: false,
      showInprogressPopup: false,
      showInprogressValPopup: false,
      goal: null,
      goalError: false,
      goalDescription: null,
      goalDescriptionError: false,
      selectedProgressiveMetric: null,
      selectedTargetDate: date,
      selectedStartDate: date,
      selectedMaxDate: null,
      minDate: date,
      //task: null,
      //description: null,
      taskError: false,
      taskDescription: null,
      responsiblePersonName: null,
      target_date: null,
      // statusList: [
      //   { type_id: 1, type: "To do" },
      //   { type_id: 2, type: "Inprogress" },
      //   { type_id: 3, type: "Complete" },
      // ],
      statusList: [
        { type_id: 0, type: "Schedule" },
        { type_id: 1, type: "To do" },
        { type_id: 2, type: "Inprogress" },
        { type_id: 3, type: "Complete" },
      ],
      isEdit: false,
      addTask: false,
      taskError: false,
      inprogressError: false,
      maxDatepickerDate: new Date(),
      isChecked: false,
      userInfo: JSON.parse(localStorage.getItem("userData")) || null,
      priorityList: [
        { id: 1, priority: "Normal", priority_color: "#E7C76E" },
        { id: 2, priority: "Important", priority_color: "#93B7F2" },
        { id: 3, priority: "Urgent", priority_color: "#E6EE42" },
        { id: 4, priority: "Critical", priority_color: "#E68895" },
      ],
      userList: [],
      responsiblePerson: null,
      responsiblePersonError: false,
      selectedCategory: this.props.moduleType ? this.props.moduleType : null,
      selectedCategoryError: false,
      selectedServiceId: null,
      selectedProjectId: null,
      taskList: [],
      task: "",
      task_description: "",
      taskAddType: null,
      taskIndex: null,
      showTaskAdd: false,
      editTask: false,
      creationDate: new Date(),
      taskCount: {},
      //serviceId: this.props.match.params.service_id,
      dueDate: new Date(),
      serviceName: null,
      projectName: null,
      priorityCountList: [],
    };
  }

  componentDidMount() {
    if (
      !(
        this.state.userInfo.designation_flag == 1 ||
        this.state.userInfo.designation_flag == 3
      )
    ) {
      this._fetchUserServiceProject();
    } else {
      this._fetchServiceList();
    }
    this.goaldetails();
    this._fetchModuleTypeList();

    this._fetchCategoryList();
    this._checkSafetyMeetingRight();
    this._taskCount();
  }
  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.service_id !== prevProps.service_id ||
      this.props.project_id !== prevProps.project_id
    ) {
      if (this.state.isFirstFetching) {
        this.setState({
          dataLoading: true,
        }, async ()  => {
          await this._taskCount();
          
        });
        
      } else {
        await this.setState({ isFirstFetching: true });
      }
    }
  }
  // _setDataLoading = async (status) => {
  //   await this.setState((prevState) => {
  //     let { dataLoading } = prevState;
  //     dataLoading = status;
  //     return { dataLoading };
  //   });
  // };

  _taskCount = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data;
    data = new FormData();
    if (this.props.project_id && this.props.project_id.length > 0) {
      this.props.project_id.forEach((item) => {
        data.append("project_id", item);
      });
    }
    data.append("auth_key", token);
    data.append("user_id", userId);
    let URL = Url.goal.tabsCount;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ taskCount: response.data.data });
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  _fetchCategoryList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      form_type: 17,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .post(Url.formCategoryList, qs.stringify(requestData), {
        headers: ContentType,
      })
      .then((response) => {
        if (response.data.status === 200) {
          var list = response.data.data.map((data) => {
            delete data.description;
            return data;
          });
          this.setState({ categoryList: list });
        }
      })
      .catch((error) => {});
  };

  _fetchUserList = async (serviceId, projectId, type) => {
    await this.setState({
      dataLoading: true,
      selectedServiceId: serviceId,
      selectedProjectId: projectId,
    });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var requestData = {
      auth_key: token,
      user_id: userId,
      service_id: serviceId,
      project_id: projectId,
      type: type,
      // form_id: this.state.formId,
    };

    const contentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(Url.UserList, qs.stringify(requestData), contentType)
      .then(async (response) => {
        if (response.data.status == 200) {
          await this.setState({
            dataLoading: false,
            userList: response.data.data,
          });
        }
      })
      .catch(async (error) => {
        await this.setState({
          dataLoading: false,
        });
      });
  };

  _getSelectedUsers = async () => {
    var tempUserList = [...this.state.userList];
    var tempInvitedUser = [...this.state.invitedUser];
    tempInvitedUser.map((user) => {
      tempUserList = tempUserList.filter((item) => item.id != user.user_id);
    });

    this.setState({
      userList: tempUserList,
    });
  };

  _checkSafetyMeetingRight = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.safetyMeeting.checkSafetyMeetingRights,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            safetyMeetingRight: response.data.safetyManHoursRights,
            serviceId: response.data.serviceId,
            projectId: response.data.projectId,
          });
          this._fetchUserList(this.state.serviceId, this.state.projectId);
        }
      })
      .catch((error) => {});
  };

  _fetchServiceList = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.serviceList);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { serviceList } = prevState;
              serviceList = response.data.data;
              return { serviceList };
            });
            // this._setDataLoading(false);
          }
        })
        .catch((error) => {
          //  this._setDataLoading(false);
        });
    });
  };

  _serviceChangeHandle = async (serviceId) => {
    await this.setState((prevState) => {
      let { selectedServiceId, serviceError } = prevState;
      selectedServiceId = serviceId;
      serviceError = serviceId ? false : true;
      return { selectedServiceId, serviceError };
    });

    await this._resetDropdwon();
    this._fetchProjectList(serviceId);
    this._fetchUserList(
      this.state.selectedServiceId,
      this.state.selectedProjectId
    );
  };

  _fetchProjectList = async (serviceId) => {
    let requestData = {
      service_id: serviceId,
      module_name: "goal",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { projectList } = prevState;
              projectList = response.data.data;
              return { projectList };
            });
          }
        })
        .catch((error) => {
          // this._setDataLoading(false);
        });
    });
  };

  _projectChangeHandle = async (projectId) => {
    await this._resetDropdwon(false);

    await this.setState((prevState) => {
      let { selectedProjectId, projectError, projectList } = prevState;

      selectedProjectId = projectId;
      projectError = projectId ? false : true;

      return { selectedProjectId, projectError };
    });

    await this._fetchUserList(
      this.state.selectedServiceId,
      this.state.selectedProjectId
    );
  };

  _resetDropdwon = async (projectReset = true) => {
    await this.setState((prevState) => {
      let { selectedProjectId, projectList } = prevState;

      if (projectReset) {
        selectedProjectId = null;
        projectList = [];
      }

      return {
        selectedProjectId,
        projectList,
      };
    });
  };

  _fetchUserServiceProject = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.userServiceProject);
      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { selectedServiceId, selectedProjectId } = prevState;
              selectedServiceId = response.data.data[0].service_id;
              selectedProjectId = response.data.data[0].project_id;
              return { selectedServiceId, selectedProjectId };
            });
            // this._setDataLoading(false);
            this._fetchUserList(
              this.state.selectedServiceId,
              this.state.selectedProjectId
            );
          }
        })
        .catch((error) => {
          // this._setDataLoading(false);
        });
    });
  };

  goaldetails = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.goal.goal,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          goal_id: this.state.goalId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(async (response) => {
        if (response.data.status === 200) {
          //this.setState({ createformdetails: response.data.data});

          let {
            goal,
            goal_description,
            due_date,
            creation_time,
            progressive_metric,
            service_name,
            project_name,
            status,
          } = response.data.data.goal;
          let { service_id, project_id, responsible_person } =
            response.data.data.goal.task_list;
          let goal1 = goal;

          await this.setState((prevState) => {
            let {
              goalDetails,
              goal,
              goalDescription,
              selectedTargetDate,
              selectedMaxDate,
              selectedProgressiveMetric,
              targetDate,
              taskList,
              selectedServiceId,
              selectedProjectId,
              responsiblePerson,
              creationDate,
              dueDate,
              serviceName,
              projectName,
              priorityCountList,
              status,
            } = prevState;
            goalDetails = response.data.data.goal;
            goal = goal1;
            goalDescription = goal_description;
            selectedTargetDate = moment(due_date, "YYYY-MM-DD").toDate();
            selectedMaxDate = new Date(selectedTargetDate);
            targetDate = due_date;
            selectedProgressiveMetric = progressive_metric;
            taskList = response.data.data.goal.task_list;
            selectedServiceId = service_id;
            selectedProjectId = project_id;
            responsiblePerson = responsible_person;
            creationDate = new Date(creation_time);
            dueDate = new Date(moment(due_date, "YYYY-MM-DD"));
            serviceName = service_name;
            projectName = project_name;
            priorityCountList = response.data.data.goal.priorityWiseCount;
            status = status;

            return {
              status,
              goalDetails,
              goal,
              goalDescription,
              selectedTargetDate,
              selectedMaxDate,
              selectedProgressiveMetric,
              targetDate,
              taskList,
              selectedServiceId,
              selectedProjectId,
              responsiblePerson,
              creationDate,
              dueDate,
              serviceName,
              projectName,
              priorityCountList,
            };
          });
          this._fetchProjectList(service_id);
          this._fetchUserList(service_id, project_id);
        }
        await this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _fetchModuleTypeList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      form_type: 17,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .post(Url.formCategoryList, qs.stringify(requestData), {
        headers: ContentType,
      })
      .then((response) => {
        if (response.data.status === 200) {
          var list = response.data.data.map((data) => {
            delete data.description;
            return data;
          });
          this.setState({ moduleTypeList: list });
        }
      })
      .catch((error) => {});
  };

  _handleCreateTaskModal = async () => {
    await this.setState((prevState) => {
      let { showCreateTaskModal } = prevState;
      showCreateTaskModal = !showCreateTaskModal;
      return { showCreateTaskModal };
    });
  };

  // _changeStatus = async (id, status) => {
  //   this.setState({ dataLoading: true });
  //   let userId = localStorage.getItem("userId");
  //   let token = localStorage.getItem("token");

  //   await axios
  //     .post(
  //       Url.taskManagement.changeStatus,
  //       qs.stringify({
  //         auth_key: token,
  //         user_id: userId,
  //         status: status,
  //         task_id: id,
  //       }),
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       if (response.data.status == 200) {
  //         window.location.reload();
  //       }
  //       this.setState({ dataLoading: false });
  //     })
  //     .catch((error) => {
  //       this.setState({ dataLoading: false });
  //     });
  // };

  _goalClose = async (id, status, type = 1) => {
    debugger
    await this.setState({
      closeData: {
        goalId: id,
        status: status,
        type: type,
        taskId: id,
        module:"goal"
      },
      closeModal: true,
    });
  };

  _changeGoalStatus = async (id, status, type = 1) => {
    this.setState({ dataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.goal.changeStatus,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          status: status,
          goal_id: id,
          type: type,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          
          this.goaldetails();
          this._fetchModuleTypeList()
          if(status===1){
              this.props.history.push(`/goal-inprogress/${id}`)
              
              window.location.reload()
            }
          ;
        }
        this.setState({
          dataLoading: false,
          showInactivePopup: false,
          showInprogressPopup: false,
        });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _taskDelete = async (id) => {
    this.setState({ dataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.taskManagement.taskDelete,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          task_id: this.state.deleteId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          // window.location.reload();
          this.goaldetails();
          this._fetchModuleTypeList();
        }
        this.setState({ dataLoading: false, showTaskDeletePopup: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _goalDelete = async () => {
    this.setState({ dataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.goal.goalDelete,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          goal_id: this.state.deleteId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          //window.location.reload();
          this.props.history.push("/goal-create");
          window.location.href("/goal-create");
          this.goaldetails();
          this._fetchModuleTypeList();
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  cancelGoalDeletion = async () => {
    await this.setState({
      showGoalDeletePopup: false,
      deleteId: null,
    });
  };

  cancelTaskDeletion = async () => {
    await this.setState({
      showTaskDeletePopup: false,
      deleteId: null,
    });
  };
  _handleChange = async (item = null, type = "start_date") => {
    await this.setState((prevState) => {
      switch (type) {
        case "target_date":
          let { selectedTargetDate } = prevState;
          selectedTargetDate = item;
          return { selectedTargetDate };

        case "goal":
          let { goal, goalError } = prevState;
          goal = item;
          goalError = item ? false : true;
          return { goal, goalError };

        case "goal_description":
          let { goalDescription, goalDescriptionError } = prevState;
          goalDescription = item;
          goalDescriptionError = item ? false : true;
          return { goalDescription, goalDescriptionError };

        case "progressive_metric":
          let { selectedProgressiveMetric, selectedProgressiveMetricError } =
            prevState;
          selectedProgressiveMetric = item;
          selectedProgressiveMetricError = item ? false : true;
          return { selectedProgressiveMetric, selectedProgressiveMetricError };

        default:
          return prevState;
      }
    });
  };

  _handleTask = async (item = null, type = "target_date") => {
    await this.setState((prevState) => {
      switch (type) {
        case "target_date":
          let { selectedTargetDate } = prevState;
          selectedTargetDate = item;
          return { selectedTargetDate };

        case "task":
          let { task, taskError } = prevState;
          task = item;
          taskError = item ? false : true;
          return { task, taskError };

        case "task_description":
          let { taskDescription, taskDescriptionError } = prevState;
          taskDescription = item;
          taskDescriptionError = item ? false : true;
          return { taskDescription, taskDescriptionError };

        case "priority":
          let { selectedPriority, priorityError } = prevState;
          selectedPriority = item;
          priorityError = item ? false : true;
          return { selectedPriority, priorityError };

        case "responsible_person":
          let { responsiblePerson, responsiblePersonError } = prevState;
          responsiblePerson = item;
          responsiblePersonError = item ? false : true;
          return { responsiblePerson, responsiblePersonError };

        default:
          return prevState;
      }
    });
  };

  //New Implemention

  _handleCheck = async () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  _taskReport = async (type) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.taskManagement.taskReport;
    let headerType = "multipart/form-data";

    let data = new FormData();
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("task", this.state.task);
    data.append("task_description", this.state.taskDescription);
    data.append(
      "category",
      this.state.selectedCategory || this.state.goalDetails.module_type
    );
    data.append("priority", this.state.selectedPriority);

    var startDate = moment(this.state.selectedStartDate).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    var targetDate = moment(this.state.selectedTargetDate).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    //var startDate = convertDateToUTC(this.state.selectedStartDate);
    //var targetDate = convertDateToUTC(this.state.selectedTargetDate);

    data.append("start_date", startDate);
    data.append("target_date", targetDate);
    data.append("responsible_person", this.state.responsiblePerson || 0);
    data.append("goal_id", this.state.goalDetails.goal_id || 0);
    data.append("service_id", this.state.selectedServiceId || 0);
    data.append("project_id", this.state.selectedProjectId || 0);
    data.append("self", this.state.isChecked ? 1 : 0);

    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          this.goaldetails();
        }
        this.setState((prevState) => {
          let { dataLoading } = prevState;
          dataLoading = false;
          return { dataLoading };
        });

        //this.setState({ dataLoading: false });
        this.props.handleClose();
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  // _formValidation = async () => {
  //   const tempObj = this.state;

  //   await this.setState((prevState) => {
  //     prevState.taskError = tempObj.task ? false : true;

  //     prevState.priorityError = tempObj.selectedPriority ? false : true;

  //     prevState.targetDateError = tempObj.selectedTargetDate ? false : true;

  //     prevState.responsiblePersonError = tempObj.responsiblePerson
  //       ? false
  //       : true;

  //     //prevState.categoryError = tempObj.selectedCategory ? false : true;

  //     return prevState;
  //   });
  // };

  _inproValidation = async () => {
    let currentDate = new Date();
    var dateString = moment(currentDate).format("YYYY-MM-DD");

    //console.log("date :" +this.state.goalDetails.due_date);
    //console.log("current_date :" +dateString);

    if (this.state.goalDetails.due_date < dateString) {
      this.setState({
        //showInprogressPopup: true,
        showInprogressValPopup: true,
      });
    } else {
      this.setState({
        //showInprogressValPopup: true,
        showInprogressPopup: true,
      });
    }
  };

  _addTask = async (obj) => {
    //await this._formValidation();

    // this.setState((prevState) => {
    //   let { task_list, addTask } = prevState;
    //   let recData = task_list || [];
    //   console.log(recData);
    //   task_list = recData ? [...recData, obj] : [obj];
    //   console.log(task_list);
    //   addTask = false;
    //   return { task_list, addTask };
    // });

    // If form not have error then submit form
    let formErrorStatus;
    // Checking form have any Error

    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          formErrorStatus = true;
          return;
        } else {
          formErrorStatus = false;
        }
      }
    }

    if (!formErrorStatus) {
      let obj = {
        task: this.state.task,
        task_description: this.state.taskDescription,
        responsible_person_name: this.state.responsiblePerson,
        category: this.state.selectedCategory || 0,
        target_date: moment(this.state.selectedTargetDate).format("YYYY-MM-DD"),
        priority: this.state.selectedPriority,
        task_id: this.state.taskId || 0,
        goal_id: this.state.goalId,
      };
      await this._addFormTask(obj);

      await this.setState({
        editTask: false,
        task: null,
        taskDescription: null,
        responsiblePerson: null,
        selectedCategory: null,
        selectedTargetDate: new Date(),
        selectedPriority: null,
        recommendationId: 0,
      });
    }

    //await this._taskReport();
  };

  _addFormTask = async (obj) => {
    this.setState((prevState) => {
      let { task_list, editTask, addTask } = prevState;
      let recData = task_list || [];
      console.log(recData);
      task_list = recData ? [...recData, obj] : [obj];
      console.log(task_list);
      //editTask = false;
      addTask = false;
      return { task_list, editTask, addTask };
    });

    if (this.state.editTask) {
      await this._taskEdit();
    } else {
      await this._taskReport();
    }
  };

  _taskChangeHandle = async (text) => {
    await this.setState((prevState) => {
      let { task, taskError } = prevState;
      task = text;
      taskError = text ? false : true;
      return { task, taskError };
    });
  };

  _getCountGraph = (count) => {
    return {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          count +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: ["#A4E9FF"],
      series: [
        {
          data: [count || 0],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };
  };

  _taskEdit = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.taskManagement.taskEdit;
    let headerType = "multipart/form-data";

    let data = new FormData();
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("task", this.state.task);
    data.append("task_description", this.state.taskDescription);
    data.append("category", this.state.selectedCategory);
    data.append("priority", this.state.selectedPriority);

    // var startDate = moment(this.state.selectedStartDate).format(
    //   "YYYY-MM-DD HH:mm:ss"
    // );
    // var targetDate = moment(this.state.selectedTargetDate).format(
    //   "YYYY-MM-DD HH:mm:ss"
    // );

    var startDate = convertDateToUTC(this.state.selectedStartDate);
    var targetDate = convertDateToUTC(this.state.selectedTargetDate);

    data.append("start_date", startDate);
    data.append("target_date", targetDate);
    data.append("responsible_person", this.state.responsiblePerson);
    data.append("task_id", this.state.taskId || 0);

    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          AlertMessage({ message: "Task edited succesfully" });
          window.location.reload();
          this.goaldetails();
        }
        this.setState({ dataLoading: false, editTask: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
        AlertMessage({
          message: "There is a error while editing task",
          type: "error",
        });
      });
  };

  _goalEdit = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.goal.goalEdit;
    let headerType = "multipart/form-data";

    let data = new FormData();
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("goal", this.state.goal);
    data.append("goal_description", this.state.goalDescription);
    data.append("progressive_metric", this.state.selectedProgressiveMetric);

    var targetDate = moment(this.state.selectedTargetDate).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    data.append("due_date", targetDate);
    data.append("goal_id", this.state.goalId || 0);

    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          //this.goaldetails();
          //window.location.reload();
          this.goaldetails();
          this._taskReport();
          AlertMessage({ message: "Goal edited succesfully" });
        }
        this.setState({ dataLoading: false, isEdit: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
        AlertMessage({
          message: "There is a error while editing goal",
          type: "error",
        });
      });
  };

  _changeStatus = async (id, status) => {
    this.setState({ dataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.taskManagement.changeStatus,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          status: status,
          task_id: id,
          module: "goal",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.goaldetails();
          this._fetchModuleTypeList();
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _typeChangeHandle = async (id, task_id) => {
    await this.setState((prevState) => {
      let { showCloseModal, taskId, closeData } = prevState;
      if (id == 1) {
        this._changeStatus(task_id, 1);
        window.location.reload();
      } else if (id == 2) {
        this._changeStatus(task_id, 2, "goal");
      } else {
        showCloseModal = true;
        closeData = {
          taskId: task_id,
          status: id,
          type: 4,
          module: "goal",
        };
      }
      return { showCloseModal, taskId, closeData };
    });
  };

  _handleCloseModal = async () => {
    this.setState({
      showCloseModal: false,
      closeData: {},
    });
    // window.location.reload();
    this.goaldetails();
    this._fetchModuleTypeList();
  };

  _handleCloseTaskModal = async () => {
    this.setState({
      closeModal: false,
      closeData: {},
    });
  };

  _handleFormTaskEdit = async (index) => {
    this.setState((prevState) => {
      let {
        task_list,
        task,
        taskDescription,
        selectedCategory,
        selectedPriority,
        selectedTargetDate,
        responsiblePerson,
        selectedServiceId,
        selectedProjectId,
        taskAddType,
        taskIndex,
        taskId,
        editTask,
      } = prevState;

      let recObj = this.state.goalDetails.task_list.filter(
        (data, idx) => idx === index
      )[0];
      console.log(recObj);
      task = recObj.task;
      taskDescription = recObj.task_description;
      selectedCategory = recObj.category;
      selectedPriority = recObj.priority;
      responsiblePerson = recObj.responsible_person;
      selectedTargetDate = new Date(recObj.target_date);
      selectedServiceId = recObj.service_id;
      selectedProjectId = recObj.project_id;
      editTask = true;
      taskAddType = "form";
      taskId = recObj.task_id;
      task_list = this.state.goalDetails.task_list.filter(
        (data, idx) => idx !== index
      );

      this._fetchProjectList(selectedServiceId);
      this._fetchUserList(selectedServiceId, selectedProjectId);

      return {
        task_list,
        task,
        taskDescription,
        selectedCategory,
        selectedPriority,
        selectedTargetDate,
        responsiblePerson,
        selectedServiceId,
        selectedProjectId,
        editTask,
        taskAddType,
        taskIndex,
        taskId,
      };
    });
  };

  __parseUserAction = (data = {}) => {
    if (data.status == 0 && data.self != 1 && data.status_change_right != 1) {
      return "Scheduled";
    } else if (
      data.status == 1 &&
      data.self != 1 &&
      data.status_change_right != 1
    ) {
      return "To do";
    } else if (
      data.status == 2 &&
      data.self != 1 &&
      data.status_change_right != 1
    ) {
      return "Inprogress";
    } else if (data.status == 3 && data.self != 1) {
      return <p className="common-green-1 font-weight-500">Completed</p>;
    } else if (
      data.responsible_person &&
      data.status != 3 &&
      this.state.goalDetails.status != 0
    ) {
      return (
        <div
          className="common-dropdown-layout"
          style={{
            minWidth: "8vw",
          }}
        >
          <CommonDropdown
            isRequired={false}
            optionList={this.state.statusList}
            onChangeHandle={(val) => this._typeChangeHandle(val, data.task_id)}
            value={data.status}
          />
        </div>
      );
    }
  };
  __renderTimeLine = (data) => {
    const isMultiUsersAssigned =
      data.goalDetails.responsible_person_list?.length > 0;
    const items = [
      {
        title: <span className="font-16">Creation Details</span>,
        cardTitle: (
          <div className="pl-3">
            <span className="common-black-1 font-weight-500">Created By</span>
            <div className="d-flex  text-center align-items-center my-2">
              <img src={data.goalDetails.user_pic} className="user-icon" />
              <div>
                <div className="common-black-1 font-weight-500 font-14">
                  {" "}
                  {data.goalDetails.user_name}
                </div>
              </div>
            </div>
            <div className="common-grey-1 font-weight-500 font-14">
              {" "}
              Creation Date : {data.goalDetails.creation_time}
            </div>
          </div>
        ),
      },
      {
        title: <span className="font-16">Assigned Details </span>,
        cardTitle: (
          <div className="pl-3">
            <span className="common-black-1 font-weight-500">Assigned To</span>
            <div className="d-flex  text-center align-items-center my-2">
              {isMultiUsersAssigned ? (
                <Users className="user-icon" />
              ) : (
                <img src={data.goalDetails.person_pic} className="user-icon" />
              )}
              <div>
                <div className="common-black-1 font-weight-500 font-14">
                  {isMultiUsersAssigned
                    ? "All Assigned"
                    : data.goalDetails.responsible_person_name}
                </div>
              </div>
            </div>
            <div className="common-grey-1 font-weight-500 font-14">
              {" "}
              Due Date : {data.goalDetails.due_date}
            </div>
          </div>
        ),
        skip:
          data.goalDetails?.responsible_person === 0 && !isMultiUsersAssigned,
      },
      {
        title: <span className="font-16">Closing Details </span>,
        cardTitle: (
          <div className="pl-3">
            <span className="common-black-1 font-weight-500">Closed By</span>
            <div className="d-flex  text-center align-items-center my-2">
              {isMultiUsersAssigned ? (
                <Users className="user-icon" />
              ) : (
                <img src={data.goalDetails.person_pic} className="user-icon" />
              )}
              <div>
                <div className="common-black-1 font-weight-500 font-14">
                  {isMultiUsersAssigned
                    ? "All Assigned"
                    : data.goalDetails.responsible_person_name}{" "}
                </div>
              </div>
            </div>
            <div className="common-grey-1 font-weight-500 font-14">
              {" "}
              Closing Date : {data.goalDetails.closing_date}
            </div>
          </div>
        ),
        skip:
          data.goalDetails.closing_date === undefined ||
          data.goalDetails?.responsible_person === 0,
      },
    ];

    return items;
  };
  __updateState = (param) => {
    this.setState(param);
  };
  __renderGridView = (data = [], priortyList = []) => {
    const taskParameter = [];
    data.forEach((el, idx) => {
      const element = priortyList.filter(
        (priority) => priority.id == el.priority
      )[0];
      taskParameter.push({
        date: el.target_date,
        dateLeft: "DD:",
        author: el.self == 1 ? el.user_name : el.responsible_person_name,
        avatar: el.self == 1 ? el.user_pic : el.person_pic,
        component: this.__parseUserAction(el),
        isControlEnabled:
          this.state.goalDetails.is_active == 1 &&
          this.state.goalDetails.status == 0,
        callbacks: {
          edit: this._handleFormTaskEdit,
          delete: this.__updateState,
        },
        callbacksParamerter: [
          idx,
          {
            showTaskDeletePopup: true,
            deleteId: el.task_id,
          },
        ],
        content: {
          Task: (
            <div>
              <Link to={`/task-management/task/${el.task_id}/goal`}>
                <p className="mb-0 common-black-1">{el.task}</p>
              </Link>
            </div>
          ),
          // Description: el.task_description,
          Priority: (
            <Badge
              bg="secondary"
              style={{ background: element.priority_color }}
            >
              {element.priority}
            </Badge>
          ),
          ...(this.state.goalDetails.status == 2 &&
            this.state.goalDetails.is_active == 1 && {
              "Closing Remark":
                this.state.goalDetails.status == 2 &&
                this.state.goalDetails.is_active == 1
                  ? el.closing_remark
                  : "",
              "Closing Date":
                this.state.goalDetails.status == 2 &&
                this.state.goalDetails.is_active == 1 ? (
                  <div style={{ color: "#FA0505" }}>
                    {el.closing_date?.substring(0, 10)}
                  </div>
                ) : (
                  ""
                ),
            }),
        },
      });
    });

    return taskParameter;
  };
  __toggleGridView = (tabName) => {
    if (this.state.listMode !== tabName) {
      this.setState({ listMode: tabName });
    }
  };

  __generateTableBody = (data, idx) => {
    return (
      <tr key={idx}>
        <td>
          {" "}
          <Link
            to={`/task-management/task/${data.task_id}/goal`}
            style={{ width: "20vw" }}
          >
            {data.task}
          </Link>
        </td>
        <td style={{ width: "20vw" }}>{data.task_description}</td>
        <td>{data.target_date}</td>
        <td>
          {/* {data.responsible_person_name} */}
          {data.self == 1 ? (
            <img className="user-icon" src={data.user_pic} />
          ) : (
            <img className="user-icon" src={data.person_pic} />
          )}
          {data.self == 1 ? data.user_name : data.responsible_person_name}
        </td>
        <td>
          <span
            style={{
              backgroundColor: this.state.priorityList.filter(
                (priority) => priority.id == data.priority
              )[0]?.priority_color,
            }}
            className="rounded px-3 py-1 font-16"
          >
            {
              this.state.priorityList.filter(
                (priority) => priority.id == data.priority
              )[0]?.priority
            }
          </span>
        </td>

        {this.state.goalDetails.status == 2 &&
        this.state.goalDetails.is_active == 1 ? (
          <>
            <td>{data.closing_reason}</td>
            <td>{data.closing_remark}</td>
            <td>{data.closing_date?.substring(0, 10)}</td>
          </>
        ) : (
          <>
            {this.state.goalDetails.status == 2 &&
            this.state.goalDetails.is_active == 1 ? (
              <>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
              </>
            ) : null}
          </>
        )}

        <td className="last-td">
          {/* 
                                            {this.state.goalDetails.status !=
                                              0 &&
                                            (data.self == 0 || data.self == 1) &&
                                            data.status_change_right != 0 &&
                                            data.status != 3 ? (
                                              <div
                                                style={{
                                                  width: "8vw",
                                                  marginTop: "0vw",
                                                  marginLeft: "5vw",
                                                }}
                                              >
                                                <CommonDropdown
                                                  isRequired={false}
                                                  optionList={
                                                    this.state.statusList
                                                  }
                                                  onChangeHandle={(val) =>
                                                    this._typeChangeHandle(
                                                      val,
                                                      data.task_id
                                                    )
                                                  }
                                                  value={data.status}
                                                />
                                              </div>
                                            ) : null} */}

          {this.state.userInfo.user_id == data.responsible_person &&
          data.status != 3 &&
          this.state.goalDetails.status != 0 ? (
            <div
              style={{
                width: "8vw",
              }}
              className="common-dropdown-layout"
            >
              <CommonDropdown
                isRequired={false}
                optionList={this.state.statusList}
                onChangeHandle={(val) =>
                  this._typeChangeHandle(val, data.task_id)
                }
                value={data.status}
              />
            </div>
          ) : null}

          {data.delete_right == 1 &&
            this.state.goalDetails.is_active == 1 &&
            this.state.goalDetails.status == 0 && (
              <>
                <Button
                  // style={{
                  //   marginLeft: "7vw",
                  //   marginTop: "-2vw",
                  // }}
                  className="icon-btn"
                  title="Edit Task"
                  onClick={(e) => this._handleFormTaskEdit(idx)}
                  style={{ padding: "0px" }}
                >
                  <img
                    src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                    className=" svg-icons mr-1"
                  />
                </Button>
                <Button
                  // style={{
                  //   marginTop: "-2vw",
                  // }}
                  className="icon-btn"
                  title="Delete Task"
                  onClick={(e) =>
                    this.setState({
                      showTaskDeletePopup: true,
                      deleteId: data.task_id,
                    })
                  }
                  style={{ padding: "0px" }}
                >
                  <img
                    src={require("assets/sls-icons/V2. New Development/delete.svg")}
                    className="svg-icons mr-1"
                  />
                </Button>

                {/* <Button
                                                    className="icon-btn"
                                                    onClick={(e) =>
                                                      this._goalEditTask(
                                                        idx
                                                      )
                                                    }
                                                >
                                                  <Edit2 className="icon-style" />
                                                </Button> */}
                {/* <Button
                                                  className="icon-btn"
                                                  onClick={(e) =>
                                                    this.setState({
                                                      isEdit: true,
                                                    })
                                                  }
                                                  style={{ color: "grey", fontSize: "1vw" }}
                                                >
                                                  <Edit2 style={{ color: "grey" }} />
                                                </Button> */}
              </>
            )}
        </td>
        <td>
          {data.status == 0 &&
            data.self != 1 &&
            data.status_change_right != 1 &&
            "Scheduled"}
          {data.status == 1 &&
            data.self != 1 &&
            data.status_change_right != 1 &&
            "To do"}
          {data.status == 2 &&
            data.self != 1 &&
            data.status_change_right != 1 &&
            "Inprogress"}
          {data.status == 3 && data.self != 1 && "Completed"}
        </td>
      </tr>
    );
  };

  renderTabBody = (data, idx) => {
    return (
      <tr key={idx}>
        <td>{data.responsible_person_name}</td>
        {data.status == 2 ? (
          <>
            <td>{data.closing_reason}</td>
            <td>{data.closing_remark}</td>
            <td>{data.closing_date.substring(0, 10)}</td>
          </>
        ) : (
          <>
            <td>NA</td>
            <td>NA</td>
            <td>NA</td>
          </>
        )}

        <td className="last-td">
          {data.status_change_right == 1 &&
            data.status == 1 &&
            this.state.goalDetails.status == 1 && (
              <Button
                className="icon-btn"
                style={{
                  color: "tomato",
                  fontSize: "1vw",
                }}
                onClick={() => this._goalClose(data.all_id, 2, 3)}
              >
                Complete
              </Button>
            )}

          {
            data.status_change_right == 0 && data.status == 1
              ? "Inprogress"
              : null
            //   <div style={{ width: '8vw', marginTop: '0vw', marginLeft: '5vw' }}>
            //   <CommonDropdown
            //     isRequired={false}
            //     optionList={this.state.statusList}
            //     onChangeHandle={(val) =>
            //       this._typeChangeHandle(
            //         val,
            //         data.task_id
            //       )
            //     }
            //     value={data.status}

            //   />
            // </div>
          }

          {data.status == 2 && "Completed"}
        </td>
      </tr>
    );
  };
  __generateColumn = (field = 1) => {
    return (
      <>
        {field === 1 ? (
          <>
            {" "}
            <th>Task</th>
            <th>Description</th>
            <th>Due Date</th>
            <th>Assigned To</th>
            <th>Priority</th>
            {this.state.goalDetails.status == 2 &&
              this.state.goalDetails.is_active == 1 && (
                <>
                  <th>Closing Reson</th>
                  <th>Closing Remark</th>
                  <th>Closing Date</th>
                </>
              )}
            <th>Action</th>
            <th>Status</th>
          </>
        ) : (
          <>
            <th>Person</th>

            <th>Closing Reson</th>
            <th>Closing Remark</th>
            <th>Closing Date</th>
            <th>Status</th>
          </>
        )}
      </>
    );
  };
  getFieldProps = (data, type = 1) => {
    return {
      data,
      customHeader: this.__generateColumn(type),
      bodyRendrer: type === 1 ? this.__generateTableBody : this.renderTabBody,
    };
  };
  render() {
    console.log("goal details", this.state.goalDetails);
    const priorityChart = {
      chart: {
        type: "pie",
        height: "90%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.goalDetails.totalPriorityCount +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.priorityCountList?.map((data) => data.color),
      category: this.state.priorityCountList?.map((data) => data.priority),
      series: [
        {
          data: this.state.priorityCountList?.map((data) => data.count),
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    return (
      <div>
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
        {!this.state.dataLoading && (
          <div className="create-form mt-5" ref={this.outerDiv}>
            {/* <div className="rd-head">
                            <div className="rd-heading">
                                <h2>
                                    <Backbutton />
                                    {this.state.goalDetails.goal}
                                </h2>
                            </div>
                        </div> */}
            {/* <div className="goal-header-top">
              <h2>Goal</h2>
            </div> */}
            <div className="d-flex align-items-center justify-content-between ">
              <div>
                <Button
                  className="back-btn"
                  onClick={this.props.history.goBack}
                >
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                </Button>
              </div>
              <div className=" common-black-1 ">
                <p>{this.state.goal}</p>
              </div>
              <div className="d-flex align-items-center">
                {/* DONe Inprogress */}
                {this.state.goalDetails.delete_right == 1 &&
                this.state.goalDetails.status == 0 &&
                this.state.goalDetails.soft_delete == 0 ? (
                  <Button
                    className="icon-btn"
                    onClick={async (e) =>
                      // await this.setState({
                      // showInprogressValPopup: true,
                      // })
                      this._inproValidation()
                    }
                    title="Activate Goal"
                    style={{
                      color: "rgba(0, 255, 10, 0.87)",
                      fontSize: "1vw",
                      padding: "0px",
                    }}
                  >
                    <img
                      src={require("assets/sls-icons/V2. New Development/Complete.svg")}
                      className="svg-icons mr-1"
                      title="Activate Goal"
                    />
                  </Button>
                ) : null}
                {/* //  ( this.state.goalDetails.owner_type == 2 || this.state.goalDetails.owner_type == 4 && (this.state.goalDetails.status != 0 || this.state.goalDetails.status == 0) && (this.state.goalDetails.status != 1 && this.state.goalDetails.status != 2) && this.state.goalDetails.soft_delete == 0 */}
                {/* (!(this.state.goalDetails.status == 1 || this.state.goalDetails.status == 2) || this.state.goalDetails.status == 0  
                                    ? (
                                    <Button
                                      className="icon-btn"
                                      onClick={async (e) =>
                                        // await this.setState({
                                        // showInprogressValPopup: true,
                                        // })
                                        this._inproValidation()
                                      }
                                      style={{ color: "red", fontSize: "1vw" }}
                                    >
                                      Inprogress
                                    </Button>
                                  ) : null

                                )} */}
                {/* Inactive */}

                {(this.state.goalDetails.delete_right == 1 ||
                  this.state.goalDetails.delete_right == 0) &&
                  this.state.goalDetails.is_active == 1 &&
                  this.state.goalDetails.status == 1 &&
                  this.state.userInfo.user_id ==
                    this.state.goalDetails.user_id && (
                    <Button
                      className="icon-btn"
                      title="Inactive Goal"
                      onClick={async (e) =>
                        await this.setState({
                          showInactivePopup: true,
                        })
                      }
                      style={{ padding: "0px" }}
                    >
                      <img
                        src={require("assets/sls-icons/V2. New Development/Cancel.svg")}
                        className="svg-icons mr-1"
                      />
                    </Button>
                  )}
                {/* {this.state.goalDetails.is_active != 1 && (
                                <Button
                                  className="icon-btn" 
                                  title="Inactive Goal"
                                  style={{ color: "grey", fontSize: "1vw" }}
                                >
                                  Inactive
                                </Button>
                              )} */}

                {/* <FcCopyright
                                      style={{ height: 26, width: 26 }}
                                      title="Complete Goal"
                                    />   */}

                {
                  !this.state.isEdit &&
                  this.state.goalDetails.delete_right == 1 &&
                  this.state.goalDetails.status == 0 &&
                  this.state.goalDetails.soft_delete == 0 ? (
                    <Button
                      className="icon-btn"
                      title="Edit Goal"
                      onClick={(e) =>
                        this.setState({
                          isEdit: true,
                        })
                      }
                      style={{ padding: "0px" }}
                    >
                      <img
                        src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                        className="svg-icons mr-1"
                      />
                    </Button>
                  ) : null
                  //  (!(this.state.goalDetails.status == 2 || this.state.goalDetails.status == 1) && this.state.goalDetails.soft_delete == 0 &&
                  //   <Button
                  //     className="icon-btn"
                  //     onClick={(e) =>
                  //       this.setState({
                  //         isEdit: true,
                  //       })
                  //     }
                  //     style={{ color: "grey", fontSize: "1vw" }}
                  //   >
                  //     <Edit2 style={{ color: "grey" }} />
                  //   </Button>
                  // )
                }
                {this.state.isEdit ? (
                  <Button
                    className="icon-btn"
                    title="Edit Cancel"
                    onClick={async (e) =>
                      await this.setState({ isEdit: false })
                    }
                    style={{ padding: "0px" }}
                  >
                    <img
                      src={require("assets/sls-icons/V2. New Development/Cancel.svg")}
                      className="svg-icons mr-1"
                    />
                  </Button>
                ) : null}
                {this.state.isEdit ? (
                  <Button
                    className="icon-btn"
                    title="Edit Goal"
                    onClick={async (e) => await this._goalEdit()}
                    style={{ padding: "0px" }}
                  >
                    <img
                      src={require("assets/sls-icons/V2. New Development/save.svg")}
                      className="svg-icons mr-1"
                    />
                  </Button>
                ) : null}
                {/* delete */}
                {this.state.goalDetails.status == 0 &&
                  this.state.goalDetails.soft_delete == 0 &&
                  this.state.goalDetails.delete_right == 1 && (
                    <Button
                      className="icon-btn"
                      title="Delete Goal"
                      onClick={(e) =>
                        this.setState({
                          showGoalDeletePopup: true,
                          deleteId: this.state.goalDetails.goal_id,
                        })
                      }
                      style={{ padding: "0px" }}
                    >
                      <img
                        src={require("assets/sls-icons/V2. New Development/delete.svg")}
                        className="svg-icons mr-1"
                      />

                      {/* <AiOutlineDelete  style={{ height: 26, width: 26, marginLeft:'3px' }} title="Deleted" /> */}
                    </Button>
                  )}

                {this.state.goalDetails.delete_right == 1 &&
                  this.state.goalDetails.status == 1 && (
                    <Button
                      className="icon-btn"
                      onClick={(e) =>
                        this._goalClose(this.state.goalDetails.goal_id, 2)
                      }
                      style={{
                        padding: "0px",
                      }}
                    >
                      <img
                        src={require("assets/sls-icons/V2. New Development/Complete.svg")}
                        className="svg-icons mr-1"
                        title="Complete Goal"
                      />
                    </Button>
                  )}
              </div>
            </div>
            <Row>
              <Col>
                <div className="user-section" style={{ width: "100%" }}>
                  <div>
                    <div className="dynamic-display">
                      <Row className="goal-details-container">
                        <Col md={7}>
                          {/* <div className="section-dynamic"> */}
                          <div className=" common-grey-border-40 border shadow-none p-5">
                            <Row>
                              <Col md={3} className="mb-2">
                                {this.state.isEdit ? (
                                  <div className="form-group">
                                    <label className="form-label common-black-1 font-20 font-weight-500">
                                      Goal
                                    </label>
                                    {/* <p className="form-view-text">
                                      {this.state.goalDetails.goal}
                                  </p> */}
                                    <input
                                      className="form-control"
                                      value={this.state.goal}
                                      onChange={(event) =>
                                        this._handleChange(
                                          event.target.value,
                                          "goal"
                                        )
                                      }
                                      placeholder={"Enter goal name"}
                                    />
                                  </div>
                                ) : (
                                  <div className="form-group mb-2">
                                    <label className="form-label common-black-1 font-20 font-weight-500">
                                      Goal
                                    </label>
                                    <p className="form-view-text  font-18 ">
                                      {this.state.goal}
                                    </p>
                                  </div>
                                )}
                              </Col>
                              {this.state.goalDetails?.goal_type ? (
                                <Col md={3}>
                                  <div className="form-group mb-2">
                                    <label className="form-label common-black-1 font-20 font-weight-500">
                                      {" "}
                                      Goal Type{" "}
                                    </label>
                                    <p className="form-view-text  font-18  pb-2 ">
                                      {this.state.goalDetails?.goal_type}
                                    </p>
                                  </div>
                                </Col>
                              ) : null}

                              <Col md={3}>
                                <div className="form-group">
                                  <label className="form-label common-black-1 font-20 font-weight-500">
                                    {" "}
                                    Owner Type{" "}
                                  </label>
                                  <p
                                    className="  font-18  b-tomato rounded"
                                    style={{
                                      width: "fit-content",
                                      padding: "1px 3px",
                                    }}
                                  >
                                    {
                                      this.state.goalOwnerTypeList[
                                        this.state.goalDetails?.owner_type - 1
                                      ]?.owner_type
                                    }
                                  </p>
                                </div>
                              </Col>
                              {(this.state.goalDetails.owner_type == 2 ||
                                this.state.goalDetails.owner_type == 3) && (
                                <>
                                  <Col md={3}>
                                    <div className="form-group">
                                      <label
                                        className="form-label common-black-1 font-20 font-weight-500"
                                        // style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        Service Name{" "}
                                      </label>
                                      <p className="form-view-text  font-18 ">
                                        {this.state.serviceName}
                                      </p>
                                    </div>
                                  </Col>

                                  <Col md={3}>
                                    <div className="form-group">
                                      <label className="form-label common-black-1 font-20 font-weight-500">
                                        {" "}
                                        Project Name{" "}
                                      </label>
                                      <p className="form-view-text  font-18 ">
                                        {this.state.projectName}
                                      </p>
                                    </div>
                                  </Col>
                                </>
                              )}

                              <Col sm={3}>
                                <div className="form-group">
                                  <label className="form-label common-black-1 font-20 font-weight-500">
                                    Module Type
                                  </label>
                                  <p
                                    className="
                                   font-18 b-tomato rounded"
                                    style={{
                                      width: "fit-content",
                                      padding: "1px 3px",
                                    }}
                                  >
                                    {this.state.goalDetails.module_name || "NA"}
                                  </p>
                                </div>
                              </Col>
                              <Col sm={3}>
                                {this.state.isEdit ? (
                                  <div className="form-group">
                                    <label className="form-label common-black-1 font-20 font-weight-500">
                                      Progressive Metric
                                    </label>
                                    {/* <p>{this.state.goalDetails.progressive_metric}</p> */}
                                    <input
                                      className="form-control"
                                      style={{ width: "50%" }}
                                      value={
                                        this.state.selectedProgressiveMetric
                                      }
                                      type="number"
                                      onChange={(event) =>
                                        this._handleChange(
                                          event.target.value,
                                          "progressive_metric"
                                        )
                                      }
                                      placeholder={
                                        "Enter progressive metric number"
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div className="form-group">
                                  <label className="form-label common-black-1 font-20 font-weight-500">
                                      Progressive Metric
                                    </label>
                                    <p className="form-view-text  font-18 ">
                                      {this.state.selectedProgressiveMetric}
                                    </p>
                                  </div>
                                )}
                              </Col>
                              {/* <Col sm={3}>
                                <div className="form-group">
                                  <label className="form-label">
                                    Creation Date
                                  </label>
                                  <p>
                                    {this.state.goalDetails.creation_time ||
                                      "NA"}
                                  </p>
                                </div>
                              </Col> */}
                              {this.state.goalDetails.is_active == 2 &&
                                this.state.goalDetails.status == 2 && (
                                  <Col sm={3}>
                                    <div className="form-group">
                                      <label className="form-label common-black-1 font-20">
                                        Inactive Date
                                      </label>
                                      <p className=" font-18 ">
                                        {this.state.goalDetails.inactive_date ||
                                          "NA"}
                                      </p>
                                    </div>
                                  </Col>
                                )}
                              {this.state.goalDetails.soft_delete == 1 && (
                                <Col sm={3}>
                                  <div className="form-group">
                                    <label className="form-label common-black-1 font-20">
                                      Delete Date
                                    </label>
                                    <p className=" font-18 ">
                                      {this.state.goalDetails.delete_date ||
                                        "NA"}
                                    </p>
                                  </div>
                                </Col>
                              )}
                              {/* {((this.state.goalDetails.status == 0 ||
                                this.state.goalDetails.status == 1 ||
                                this.state.goalDetails.status == 2) &&
                                this.state.goalDetails.is_active == 0) ||
                                (this.state.goalDetails.is_active == 1 &&
                                  this.state.goalDetails.soft_delete != 1 && (
                                    <Col>
                                      {this.state.isEdit ? (
                                        <div className="form-group">
                                          <label className="form-label">
                                            Due Date
                                          </label>
                                         
                                          <div className="calender-div">
                                            <DatePicker
                                              selected={
                                                this.state.selectedTargetDate
                                              }
                                              minDate={this.state.minDate}
                                              // showTimeSelect
                                              // timeFormat="HH:mm"
                                              dateFormat="dd/MM/yyyy"
                                              onChange={(jsDate) =>
                                                this._handleChange(
                                                  jsDate,
                                                  "target_date"
                                                )
                                              }
                                            />
                                            <Calendar className="calender-icon" />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="form-group">
                                          <label className="form-label">
                                            Due Date
                                          </label>
                                          <p className="form-view-text">
                                            {this.state.targetDate}
                                          </p>
                                        </div>
                                      )}
                                    </Col>
                                  ))} */}
                              <Col md={3}>
                                {((this.state.goalDetails.status == 0 ||
                                  this.state.goalDetails.status == 1 ||
                                  this.state.goalDetails.status == 2) &&
                                  this.state.goalDetails.is_active == 0) ||
                                  (this.state.goalDetails.is_active == 1 &&
                                    this.state.goalDetails.soft_delete != 1 && (
                                      <>
                                        {this.state.isEdit ? (
                                          <div className="form-group">
                                          <label className="form-label common-black-1 font-20 font-weight-500">
                                              Due Date
                                            </label>

                                            <div className="calender-div">
                                              <DatePicker
                                                selected={
                                                  this.state.selectedTargetDate
                                                }
                                                minDate={this.state.minDate}
                                                // showTimeSelect
                                                // timeFormat="HH:mm"
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(jsDate) =>
                                                  this._handleChange(
                                                    jsDate,
                                                    "target_date"
                                                  )
                                                }
                                              />
                                              <Calendar className="calender-icon" />
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="form-group">
                                            <label className="form-label common-black-1 font-20">
                                              Due Date
                                            </label>
                                            <p className="form-view-text  font-20 ">
                                              {this.state.targetDate}
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    ))}
                              </Col>
                              {/* <Col md={3} ></Col>
                              <Col md={3} ></Col> */}
                              {this.state.goalDetails.review_date !==
                              undefined ? (
                                <Col md={3} className="my-2">
                                  <div className="form-group" style={{marginTop:"-9px"}}>
                                    <label className="form-label common-black-1 font-20">
                                      Review Date
                                    </label>
                                    <p className="form-view-text  font-18 ">
                                      {this.state.goalDetails.review_date}
                                    </p>
                                  </div>
                                </Col>
                              ) : null}
                            </Row>
                            <Row>
                              <Col>
                                {this.state.isEdit ? (
                                  <div className="form-group">
                                    <label className="form-label common-black-1 font-20 ">
                                      Goal Description
                                    </label>
                                    <input
                                      className="form-control"
                                      value={this.state.goalDescription}
                                      onChange={(event) =>
                                        this._handleChange(
                                          event.target.value,
                                          "goal_description"
                                        )
                                      }
                                      placeholder={"Enter goal description"}
                                    />
                                  </div>
                                ) : (
                                  <div className="form-group">
                                    <label className="form-label  common-black-1 font-20">
                                      Goal Description
                                    </label>
                                    <p className="form-view-text  font-18 ">
                                      {this.state.goalDescription}
                                    </p>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            {/* <Row className="second-row">

                              {this.state.goalDetails.is_active == 1 &&
                                this.state.goalDetails.status == 2 &&
                                this.state.goalDetails.closing_date && (
                                  <Col>
                                    <div className="form-group">
                                      <label className="form-label ">
  black                               Closing Date
                                      </label>
                                      <p className="form-view-text">
                                        {this.state.goalDetails.closing_date}
                                      </p>
                                    </div>
                                  </Col>
                                )}
                              {this.state.goalDetails.is_active == 1 &&
                                this.state.goalDetails.status == 2 &&
                                this.state.goalDetails.task_list.map(
                                  (data, idx) => (
                                    <Col>
                                      <div className="form-group">
                                        <label className="form-label ">
  black                                 Closing Date
                                        </label>
                                        <p className="form-view-text">
                                          {data.closing_date?.substring(0, 10)}
                                        </p>
                                      </div>
                                    </Col>
                                  )
                                )}
                            </Row> */}
                          </div>
                          {this.state.goalDetails.closing_reason && (
                            <div className=" common-grey-border-40 border shadow-none  p-5 mt-5">
                              <Row>
                                {this.state.goalDetails.closing_remark && (
                                  <Col md={6}>
                                    <div className="form-group">
                                      <label className="form-label  common-black-1 font-20">
                                        Closing Remark
                                      </label>
                                      <p className="form-view-text  font-18 ">
                                        {this.state.goalDetails.closing_remark}
                                      </p>
                                    </div>
                                  </Col>
                                )}
                                {this.state.goalDetails.is_active == 1 &&
                                  this.state.goalDetails.status == 2 &&
                                  this.state.goalDetails.closing_date && (
                                    <Col md={6}>
                                      <div className="form-group">
                                        <label className="form-label  common-black-1 font-20">
                                          Closing Date
                                        </label>
                                        <p className="form-view-text  font-18 ">
                                          {this.state.goalDetails.closing_date}
                                        </p>
                                      </div>
                                    </Col>
                                  )}
                                {this.state.goalDetails.closing_reason && (
                                  <Col md={12}>
                                    <div className="form-group">
                                      <label className="form-label  common-black-1 font-20 mb-0">
                                        Closing Reason
                                      </label>
                                      {/* <div className="common-seprator-grey-3 my-4"></div> */}
                                      <p className="form-view-text  font-18 ">
                                        {this.state.goalDetails.closing_reason}
                                      </p>
                                    </div>
                                  </Col>
                                )}

                                {/* {this.state.goalDetails.is_active == 1 &&
                                this.state.goalDetails.status == 2 &&
                                this.state.goalDetails.task_list.map(
                                  (data, idx) => (
                                    <Col>
                                      <div className="form-group">
                                        <label className="form-label">
                                          Closing Date
                                        </label>
                                        <p className="form-view-text">
                                          {data.closing_date?.substring(0, 10)}
                                        </p>
                                      </div>
                                    </Col>
                                  )
                                )}  */}
                              </Row>
                            </div>
                          )}
                        </Col>
                        <Col md={5}>
                          <div className=" common-grey-border-40 border shadow-none p-5 ">
                            {/* <TimeLineComponent
                              modules={this.__renderTimeLine(this.state)}
                            /> */}
                            {Object.keys(this.state.goalDetails).length > 0 ? (
                              <VCTimelineComponent
                                enableOutline
                                cardHeight={20}
                                items={this.__renderTimeLine(this.state)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      {/* PieChart       */}
                      <div className="mt-5">
                        {this.state.goalDetails?.total_task_count ? (
                          <Col sm={12}>
                            <Row>
                              <Col sm={3}>
                                {this.state.goalDetails?.total_task_count ? (
                                  <div className="nm-dashboard-box common-grey-border-10 border shadow">
                                    <div className="header-block height-3-5 pt-2 common-border-b-white mx-2">
                                      <h5 className="header-title common-black-1 font-20 pl-0 font-weight-500">
                                        Total Task Count
                                      </h5>
                                    </div>
                                    <div className="graph-block">
                                      <HighchartsReact
                                        highcharts={Highcharts}
                                        options={this._getCountGraph(
                                          this.state.goalDetails
                                            ?.total_task_count
                                        )}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </Col>
                              <Col sm={3}>
                                {this.state.goalDetails?.open_task_count !=
                                0 ? (
                                  <div className="nm-dashboard-box common-grey-border-10 border shadow">
                                    <div className="header-block height-3-5 pt-2 common-border-b-white mx-2">
                                      <h5 className="header-title common-black-1 font-20 pl-0 font-weight-500">
                                        Open Task Count
                                      </h5>
                                    </div>
                                    <div className="graph-block">
                                      <HighchartsReact
                                        highcharts={Highcharts}
                                        options={this._getCountGraph(
                                          this.state.goalDetails
                                            ?.open_task_count
                                        )}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="nm-dashboard-box common-grey-border-10 border shadow">
                                    <div className="header-block height-3-5 pt-2 common-border-b-white mx-2">
                                      <h5 className="header-title common-black-1 font-20 pl-0 font-weight-500">
                                        Open Task Count
                                      </h5>
                                    </div>
                                    <div className="graph-block">
                                      <HighchartsReact
                                        highcharts={Highcharts}
                                        options={this._getCountGraph(
                                          this.state.goalDetails
                                            ?.open_task_count
                                        )}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Col>
                              <Col sm={3}>
                                {this.state.goalDetails?.completed_task_count !=
                                0 ? (
                                  <div className="nm-dashboard-box common-grey-border-10 border shadow">
                                    <div className="header-block height-3-5 pt-2 common-border-b-white mx-2">
                                      <h5 className="header-title common-black-1 font-20 pl-0 font-weight-500">
                                        Completed Task Count{" "}
                                      </h5>
                                    </div>
                                    <div className="graph-block">
                                      <HighchartsReact
                                        highcharts={Highcharts}
                                        options={this._getCountGraph(
                                          this.state.goalDetails
                                            ?.completed_task_count
                                        )}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="nm-dashboard-box common-grey-border-10 border shadow">
                                    <div className="header-block height-3-5 pt-2 common-border-b-white mx-2">
                                      <h5 className="header-title common-black-1 font-20 pl-0 font-weight-500">
                                        Completed Task Count{" "}
                                      </h5>
                                    </div>
                                    <div className="graph-block">
                                      <HighchartsReact
                                        highcharts={Highcharts}
                                        options={this._getCountGraph(
                                          this.state.goalDetails
                                            ?.completed_task_count
                                        )}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Col>
                              {this.state.goalDetails.owner_type != 4 && (
                                <Col sm={3}>
                                  <div className="nm-dashboard-box common-grey-border-10 border shadow-">
                                    <div className="header-block height-3-5 pt-2 common-border-b-white mx-2">
                                      <h5 className="header-title common-black-1 font-20 pl-0 font-weight-500">
                                        Prioritywise Count
                                      </h5>
                                    </div>
                                    <Row noGutters={true}>
                                      <Col sm={6}>
                                        <div className="graph-block">
                                          <HighchartsReact
                                            highcharts={Highcharts}
                                            options={priorityChart}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm={6} className="right-legend">
                                        <Scrollbars style={{ height: `6vw` }}>
                                          <div className="outer-legend-block">
                                            {this.state.priorityCountList.map(
                                              (data, index) => (
                                                <div
                                                  className="soi-kanban-inner-legend-block"
                                                  key={index}
                                                >
                                                  <span
                                                    className="soi-kanban-modal-legend-box"
                                                    style={{
                                                      backgroundColor:
                                                        data.color != ""
                                                          ? data.color
                                                          : "#FAB95C",
                                                    }}
                                                  ></span>
                                                  <span
                                                    style={{
                                                      marginRight: "1.8vw",
                                                    }}
                                                    className="sm-graph-modal-legend-text common-black-1 font-weight-500"
                                                  >
                                                    {data.priority}
                                                  </span>
                                                  <span
                                                    style={{ border: "none" }}
                                                    className=" common-black-1 font-weight-500 legend-number "
                                                  >
                                                    {data.count}
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Scrollbars>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        ) : null}
                      </div>
                      {this.state.goalDetails.owner_type != 4 && (
                        <div className="user-display-box w-99 mt-4">
                          <div className="task-table-header mt-4">
                            <div className="table-heading">
                              <div
                                className="d-flex justify-content-between align-items-baseline"
                                style={{ marginTop: "-4vw" }}
                              >
                                <h3 className="text-danger font-20 font-weight-500">
                                  Tasks
                                </h3>
                                <div className="align-items-baseline d-flex justify-content-between mt-5">
                                  {/* <div className="common-grey-border-30 d-flex  px-5 py-2 mt-2">
                                    <Search className="cursor-pointer" />
                                    <input
                                      type="text"
                                      placeholder="Search Task"
                                      className=" ml-3 border-0"
                                    />
                                  </div> */}
                                  <div>
                                    <div className="align-items-center d-flex">
                                      {/* <span className="font-18 common-black-1 mr-3 font-weight-500">
                                          Sort By
                                        </span>
                                        <div
                                          style={{
                                            minWidth: "11vw",
                                          }}
                                          className="common-dropdown-layout"
                                        >
                                          <CommonDropdown
                                            title={" Option"}
                                            isRequired={false}
                                            optionList={[{ label: "", value: 1 }]}
                                          />
                                        </div> */}

                                      <div className="align-items-center d-flex">
                                        <div className="common-control-button-1 px-1 py-1 cursor-pointer common-border">
                                          <img
                                            src={
                                              this.state.listMode === "table"
                                                ? require("assets/sls-icons/Table/1.svg")
                                                : require("assets/sls-icons/Table/picklist_type.svg")
                                            }
                                            className="svg-list-uauc"
                                            onClick={() =>
                                              this.__toggleGridView("table")
                                            }
                                          />
                                        </div>

                                        <div className="common-control-button-1 px-1 py-1 cursor-pointer common-border">
                                          <img
                                            src={
                                              this.state.listMode === "grid"
                                                ? require("assets/sls-icons/Card/1.svg")
                                                : require("assets/sls-icons//Card/apps.svg")
                                            }
                                            className="svg-kanban-uauc"
                                            onClick={() =>
                                              this.__toggleGridView("grid")
                                            }
                                          />
                                        </div>
                                      </div>

                                      {this.state.goalDetails.delete_right &&
                                      this.state.goalDetails.status == 0 &&
                                      this.state.goalDetails.soft_delete == 0 &&
                                      this.state.goalDetails?.owner_type !==
                                        4 ? (
                                        <div>
                                          <button
                                            className="icon-btn"
                                            onClick={async () =>
                                              await this.setState({
                                                addTask: !this.state.addTask,
                                              })
                                            }
                                          >
                                            <img
                                              src={require("assets/sls-icons/V2. New Development/Add +.svg")}
                                              style={{ height: "2.1vw" }}
                                            />
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {/* <div className="d-flex justify-content-end mr-5 mt-4">
                                    <button className="common-oranger-btn">
                                      Add
                                    </button>
                                  </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="common-seprator-grey-3 mb-3 mt-3"></div>
                          <div className="mt-4 ">
                            {this.state.listMode === "grid" &&
                            !(this.state.addTask || this.state.editTask) ? (
                              <Row className="p-3">
                                {[
                                  ...this.__renderGridView(
                                    this.state.goalDetails.task_list,
                                    this.state.priorityList
                                  ),
                                ]?.map((el, idx) => (
                                  <Col key={idx} md={3}>
                                    <ProfileCard data={el} />
                                  </Col>
                                ))}
                              </Row>
                            ) : (
                              !(this.state.addTask || this.state.editTask) && (
                                <div

                                // style={{
                                //   marginTop: "4vw",
                                //   marginLeft: "5vw",
                                //   width: "75vw",
                                // }}
                                >
                                  {this.state.goalDetails.task_list?.length >
                                    0 && (
                                    <div className="published-section published-table">
                                      <DataTable
                                        {...this.getFieldProps(
                                          this.state.goalDetails.task_list
                                        )}
                                      />
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                          {(this.state.addTask || this.state.editTask) && (
                            <div className="common-grey-border-10 border shadow-none p-5 mx-5 mt-4">
                              <Row>
                                <Col
                                  md={3}
                                  className="col-md-10 d-flex justify-content-between"
                                >
                                  <div className="form-group">
                                    <label
                                      className="form-label common-black-1 font-20 font-weight-500"
                                    >
                                      Task
                                      <span className="inputErrorMsg">
                                        &nbsp;*
                                      </span>{" "}
                                    </label>

                                    <input
                                      style={{ width: "12vw" }}
                                      className="form-control "
                                      value={this.state.task}
                                      onChange={(event) =>
                                        this._handleTask(
                                          event.target.value,
                                          "task"
                                        )
                                      }
                                      placeholder={"Write task"}
                                    />

                                    {!!this.state.taskError && (
                                      <p className="inputErrorMsg">
                                        {requiredMessage}
                                      </p>
                                    )}
                                  </div>
                                  <div>
                                    <label
                                      className="form-label common-black-1 font-20 font-weight-500"
                                    >
                                      Category
                                      <span className="inputErrorMsg">
                                        &nbsp;*
                                      </span>
                                    </label>
                                    {/* {this.state.goalDetails.module_name || "NA"}  */}
                                    <p>
                                      <input
                                        className="form-control"
                                        value={
                                          this.state.goalDetails.module_name ||
                                          "NA"
                                        }
                                      />{" "}
                                    </p>
                                  </div>
                                  <div className="form-group">
                                    <label
                                      className="form-label common-black-1 font-20 font-weight-500"
                                    
                                    >
                                      Due Date
                                    </label>
                                    <div className="calender-div">
                                      <DatePicker
                                        selected={this.state.selectedTargetDate}
                                        minDate={this.state.creationDate}
                                        maxDate={this.state.selectedMaxDate}
                                        // showTimeSelect
                                        // timeFormat="HH:mm"
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(jsDate) =>
                                          this._handleTask(
                                            jsDate,
                                            "target_date"
                                          )
                                        }
                                      />
                                      <Calendar className="calender-icon" />
                                    </div>
                                  </div>
                                  <Col sm={4}>
                                    {/* <div className="rd-box-head"> */}
                                    <label
                                      className="form-label common-black-1 font-20 font-weight-500"
                                    >
                                      Task Description
                                      <span className="inputErrorMsg">
                                        &nbsp;*
                                      </span>
                                    </label>

                                    <input
                                      style={{ width: "20vw" }}
                                      className="form-control"
                                      value={this.state.taskDescription}
                                      onChange={(event) =>
                                        this._handleTask(
                                          event.target.value,
                                          "task_description"
                                        )
                                      }
                                      placeholder={"Write description..."}
                                    />
                                    {!!this.state.taskError && (
                                      <p className="inputErrorMsg">
                                        {requiredMessage}
                                      </p>
                                    )}
                                  </Col>
                                </Col>
                                <Col className="mt-4 ml-4">
                                  <Button
                                    className="icon-btn"
                                    onClick={() => this._addTask()}
                                    style={{ padding: "0px" }}
                                  >
                                    <img
                                      src={require("assets/sls-icons/V2. New Development/save.svg")}
                                      className="svg-icons mr-1"
                                    />
                                  </Button>
                                  <Button
                                    className="icon-btn"
                                    onClick={async () =>
                                      await this.setState({
                                        addTask: !this.state.addTask,
                                        editTask: !this.state.addTask,
                                      })
                                    }
                                    style={{ padding: "0px" }}
                                  >
                                    <img
                                      src={require("assets/sls-icons/V2. New Development/Cancel.svg")}
                                      className=" svg-icons mr-1"
                                    />
                                  </Button>
                                </Col>
                              </Row>

                              <Row className="my-3">
                                <Col>
                                  <label className="form-label common-black-1 font-20 font-weight-500">
                                    Priority
                                  </label>
                                  <CommonDropdown
                                    title="Priority"
                                    optionList={this.state.priorityList}
                                    onChangeHandle={(value) =>
                                      this._handleTask(value, "priority")
                                    }
                                    isRequired={true}
                                    value={this.state.selectedPriority}
                                  />
                                  {!!this.state.priorityError && (
                                    <p className="inputErrorMsg">
                                      {requiredMessage}
                                    </p>
                                  )}
                                </Col>
                                {!this.state.isChecked &&
                                  (this.state.userInfo.designation_flag == 1 ||
                                    this.state.userInfo.designation_flag ==
                                      3) &&
                                  !this.props.ownerType && (
                                    <>
                                      <Col>
                                        <ServiceList
                                          titleview={false}
                                          optionList={this.state.serviceList}
                                          customTitle={
                                            <div
                                            style={{ marginBottom: "11px" }}
                                            >
                                            <span style={{ fontWeight: 500 }}>
                                              {"Service"}
                                              <abbr
                                                class="slds-required"
                                                title="required"
                                              >
                                                *{" "}
                                              </abbr>
                                            </span>
                                            </div>
                                          }
                                          onChangeHandle={
                                            this._serviceChangeHandle
                                          }
                                          value={this.state.selectedServiceId}
                                        />
                                        {!!this.state.serviceError && (
                                          <p className="inputErrorMsg">
                                            {requiredMessage}
                                          </p>
                                        )}
                                      </Col>
                                      <Col>
                                        <ProjectList
                                          titleview={false}
                                          optionList={this.state.projectList}
                                          customTitle={
                                            <div
                                              style={{ marginBottom: "11px" }}
                                            >
                                              <span style={{ fontWeight: 500 }}>
                                                {"Plant/Project"}
                                                <abbr
                                                  className="slds-required"
                                                  title="required"
                                                >
                                                  *{" "}
                                                </abbr>
                                              </span>
                                            </div>
                                          }
                                          onChangeHandle={
                                            this._projectChangeHandle
                                          }
                                          value={this.state.selectedProjectId}
                                        />

                                        {!!this.state.projectError && (
                                          <p className="inputErrorMsg">
                                            {requiredMessage}
                                          </p>
                                        )}
                                      </Col>
                                    </>
                                  )}

                                {!this.state.isChecked && (
                                  <Col>
                                    <label className="form-label common-black-1 font-20 font-weight-500">
                                      Assigned To
                                    </label>

                                    <CommonDropdown
                                      title="Assigned To"
                                      optionList={this.state.userList}
                                      onChangeHandle={(value) =>
                                        this._handleTask(
                                          value,
                                          "responsible_person"
                                        )
                                      }
                                      value={this.state.responsiblePerson}
                                      isRequired={false}
                                    />

                                    {!!this.state.responsiblePersonError && (
                                      <p className="inputErrorMsg">
                                        {requiredMessage}
                                      </p>
                                    )}
                                  </Col>
                                )}
                                <Col>
                                  <div className="form-group mt-4">
                                    <label className="check-box common-grey-1 font-20">
                                      <input
                                        type="checkbox"
                                        checked={this.state.isChecked}
                                        onChange={() => {
                                          this._handleCheck();
                                        }}
                                      />
                                      <span className="checkmark1"></span>
                                      <label>Self</label>
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </div>
                      )}

                      {/* Responsible person list for "All" ownner type */}
                      {this.state.goalDetails.owner_type == 4 && (
                        <div>
                          <div>
                            {this.state.goalDetails.responsible_person_list
                              .length > 0 && (
                              <div className="published-section published-table">
                                <DataTable
                                  {...this.getFieldProps(
                                    this.state.goalDetails
                                      .responsible_person_list,
                                    2
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}

        {!!this.state.showCreateTaskModal && (
          <CreateTaskModal
            ownerType={this.state.goalDetails.owner_type}
            resPersonId={this.state.goalDetails.responsible_person}
            resPerson={this.state.goalDetails.responsible_person_name}
            moduleType={this.state.goalDetails.module_type}
            moduleName={this.state.goalDetails.module_name}
            handleClose={this._handleCreateTaskModal}
            goalId={this.state.goalDetails.goal_id}
            serviceId={this.state.goalDetails.service_id}
            projectId={this.state.goalDetails.project_id}
            _reload={() => {
              this.goaldetails();
              this._fetchModuleTypeList();
            }}
          />
        )}
        {/* Inprogress Complete */}
        {!!this.state.closeModal && (
          <CloseModal
            handleClose={this._handleCloseTaskModal}
            data={this.state.closeData}
            type={1}
          />
        )}
        {!!this.state.showCloseModal && (
          <CloseModal
            handleClose={this._handleCloseModal}
            data={this.state.closeData}
          />
        )}
        <DeletePopup
          showDeletePopup={this.state.showGoalDeletePopup}
          onCancel={() => this.cancelGoalDeletion()}
          onConfirm={() => this._goalDelete()}
        />
        <DeletePopup
          showDeletePopup={this.state.showTaskDeletePopup}
          onCancel={() => this.cancelTaskDeletion()}
          onConfirm={() => this._taskDelete()}
        />
        <InactivePopup
          showInactivePopup={this.state.showInactivePopup}
          onCancel={() =>
            this.setState({ showInactivePopup: !this.state.showInactivePopup })
          }
          onConfirm={() => this._changeGoalStatus(this.state.goalId, 2, 2)}
        />

        <InprogressPopup
          showInprogressPopup={this.state.showInprogressPopup}
          onCancel={() =>
            this.setState({
              showInprogressPopup: !this.state.showInprogressPopup,
            })
          }
          onConfirm={() => this._changeGoalStatus(this.state.goalId, 1)}
        />

        <InprogressValPopup
          showInprogressValPopup={this.state.showInprogressValPopup}
          onCancel={() =>
            this.setState({
              showInprogressValPopup: !this.state.showInprogressValPopup,
            })
          }
          onConfirm={() => this._changeGoalStatus(this.state.goalId, 1)}
        />
      </div>
    );
  }
}

//export default withRouter(Goal);
export default withRouter(
  connect(mapStateToProps)(Goal)
);


export const TimeLineComponent = (data) => {
  return (
    <VerticalTimeline lineColor="#D9D9D9">
      {data?.modules?.map((el, idx) => {
        const {
          moduleName,
          avatar,
          moduleContent,
          haveIcon = false,
          skip = false,
        } = el ?? {};
        const position = idx % 2 === 0 ? "right" : "left";
        return (
          <>
            {!skip ? (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={moduleName}
                key={idx}
                contentStyle={{
                  background: "#DBEBFF",
                  padding: "15px",
                  borderRadius:
                    position === "right"
                      ? "0px 10px 10px 10px"
                      : "10px 0px 10px 10px",
                }}
                icon={
                  haveIcon ? (
                    avatar
                  ) : (
                    <img
                      src={avatar}
                      style={{
                        height: "inherit",
                        width: "inherit",
                        borderRadius: "inherit",
                        boxShadow: "none!important",
                      }}
                    />
                  )
                }
                position={position}
                dateClassName={`common-red-2 font-weight-500 font-20 ${
                  position === "right" ? "pr-4" : "pl-4"
                }`}
              >
                <div className="font-14">
                  <div className="d-flex ">
                    <span className=" font-weight-500 ">
                      {moduleContent.author}
                    </span>
                    <span className="pl-1"> ({moduleContent.operation})</span>
                  </div>
                  {moduleContent?.content.length &&
                    moduleContent?.content.map((sub, i) => {
                      const [key, value] = Object.entries(sub)[0] ?? [];
                      return (
                        <div className="mt-3" key={i}>
                          <span>{key} : </span>
                          <span className="pl-3"> ({value})</span>
                        </div>
                      );
                    })}
                </div>
              </VerticalTimelineElement>
            ) : (
              ""
            )}
          </>
        );
      })}
    </VerticalTimeline>
  );
};

export const VCTimelineComponent = (data) => {
  const {
    mode = "VERTICAL_ALTERNATING",
    items,
    wrapperClasses = "",
    ...rest
  } = data ?? {};
  const elements = items?.filter(({ skip = false, ...rest }) => {
    if (skip === false) {
      return rest;
    }
  });
  return (
    <div className={`vertical-timeline-container ${wrapperClasses} `}>
      <Chrono items={elements} mode={mode} {...rest} />
    </div>
  );
};

export const ProfileCard = ({ data }) => {
  const {
    date,
    avatar,
    author,
    content,
    isControlEnabled,
    component = "",
    dateLeft = "",
    callbacks,
    callbacksParamerter,
  } = data ?? {};

  return (
    <div>
      <div className=" profile-card border">
        <div className="profile-card-header ">
          <div>
            {dateLeft && (
              <span className="common-black-1 font-weight-500 mr-2">
                {dateLeft}
              </span>
            )}
            <span className="card-left">{date}</span>
          </div>
          <div className="d-flex card-right">
            {isControlEnabled ? (
              <>
                <div>
                  <img
                    src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                    className="svg-icons common-blue-1 cursor-pointer"
                    onClick={() => callbacks.edit(callbacksParamerter[0])}
                  />
                </div>
                <div>
                  <img
                    src={require("assets/sls-icons/V2. New Development/delete.svg")}
                    className=" ml-2 svg-icons common-red-3 cursor-pointer"
                    onClick={() => callbacks.delete(callbacksParamerter[1])}
                  />
                </div>{" "}
              </>
            ) : (
              component
            )}
          </div>
        </div>
        <div className="profile-card-body ">
          <div className="profile-card-avatar text-center">
            <img src={avatar} alt="profile-avatar" />
            <h4>{author}</h4>
          </div>
          <div
            className="profile-card-content overflow-auto"
            style={{ maxHeight: "200px" }}
          >
            {Object.entries(content).map((el, idx) => {
              const [key, value] = el ?? [];
              return (
                <div className="profile-card-list ml-3 mb-3 d-flex " key={idx}>
                  <span className="list-key ">{key} : </span>
                  <span className="list-value w-auto">{value}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
