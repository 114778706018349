import React, { Component } from "react";
import AuthLeftBottom from "./AuthLeftBottom";
import Url from "app/shared/constants/Url";
import ConfirmPopupImage from "../../../../assets/images/CLIDEOffice.png"; 

export default class AuthLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentData: {},
    };
  }

  componentWillMount = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.loginPageContent);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ contentData: response.data.data });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  render() {
    return (
      <div className="lleft-wrapper">
        <div className="clide-logo">
          <img
            className="inner-logo"
            //src={require("assets/images/CLIDEOffice.png")}
            src={ConfirmPopupImage}
            alt="clideOffice"
          />
        </div>
        <div className="clearfix" />
        <div className="d-flex justify-cpontent-end mt-5 left-wrap-img">
          <img src={this.state.contentData.gif_url} alt="companyLogo" className="img-log" />
        </div>
        <div className="lright-bottom left-wrap-img">
          <AuthLeftBottom data={this.state.contentData} />
        </div>
      </div>
    );
  }
}
