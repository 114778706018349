import React from 'react';
import { toast } from 'react-toastify';

const AlertMessage = (props, callBackHandler = null) => {

    const alertStyle = {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      onClose: () =>
      callBackHandler ? callBackHandler() : null
    };
  
    if( props.type === 'error' ) {
      toast.error(props.message, alertStyle);
    } else if( props.type === 'warning') {
      toast.warning(props.message, alertStyle);
    }
    else {
      toast.success(props.message, alertStyle);
    }
}

export default AlertMessage;