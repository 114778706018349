import React, { Component } from "react";
import { Button, Row, Col } from 'react-bootstrap';
import _ from "lodash";
import { Trash, Save } from 'react-feather';
import { SingleChoice, ShortAnswer, AddNumber, AddDropdown, UploadFile, AddDate, AddTime, Recommendation } from "app/shared/components/Forms";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Url from "app/shared/constants/Url";
import {
  SuccessToast,
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";
import { toast } from "react-toastify";

const queTypesDropdown = [
  {
    id: 1,
    name: 'Single Choice',
    image: require('assets/images/icons/single.svg')
  },
  {
    id: 2,
    name: 'Short Answer',
    image: require('assets/images/icons/short.svg')
  },
  {
    id: 3,
    name: 'Number',
    image: require('assets/images/icons/number.svg')
  },
  {
    id: 4,
    name: 'Dropdown',
    image: require('assets/images/icons/dropdown.svg')
  },
  {
    id: 5,
    name: 'Upload File',
    image: require('assets/images/icons/upload.svg')
  },
  {
    id: 6,
    name: 'Calendar',
    image: require('assets/images/icons/calendar.svg')
  },
  {
    id: 7,
    name: 'Hours',
    image: require('assets/images/icons/clock.svg')
  },
  {
    id: 8,
    name: 'Recommendation',
    // name: 'Observation',
    // image: require('assets/images/icons/clock.svg')
    image: require('assets/images/icons/recommendation1.svg')
  },
  {
    id: 9,
    name: 'Text',
    image: require('assets/images/icons/text.svg')
  },
  {
    id: 10,
    name: 'Declaration',
    image: require('assets/images/icons/dLogo.svg'),
  },
  {
    id: 11,
    name: 'Upload Image/Video',
    image: require('assets/images/icons/upload1.svg')
  },
];


// integration the special enums respective modules for dropdown options filteration  on 22/1/2024
const specialModules = ["8", "11", "13"]
const whiteListModulesDropdown = [1, 4, 11, 9]


const queTypesDropdownLogic = [
  {
    id: 1,
    name: 'Single Choice',
    image: require('assets/images/icons/single.svg')
  },
  {
    id: 4,
    name: 'Dropdowns',
    image: require('assets/images/icons/dropdown.svg')
  },
];

export default class AddField extends Component {
  constructor(props) {
    super(props);

    // setting value of select section dropdown
    if (this.props.sectionArr !== undefined) {
      var selectedIndex = this.props.sectionArr.length > 0 ? (this.props.sectionArr.length - 1) : '';
    }

    this.state = {
      selectedQueTypeIndex: this.props.selectedQueType || 0,
      question: '',
      description: '',
      mandatory: 0,
      notes: 0,
      evidence: 0,
      logic: 0,
      recommendation: 0,
      scoring: 0,
      options: [],
      submitted: false,
      questionFieldError: false,
      optionFieldError: false,
      logicData: [],
      logicResponseId: null,
      logicQuestion: '',
      logicDescription: '',
      logicMandatory: 0,
      logicNotes: 0,
      logicEvidence: 0,
      logicRecommendation: 0,
      logicScoring: 0,
      logicOptions: [],
      logicQuestionFieldError: false,
      logicOptionFieldError: false,
      logicBoxClick: false,
      logicResponseIdFieldError: false,
      logicSelectedQueTypeIndex: 0,
      selectedSectionIndex: (this.props.selectedQueType === 9 || this.props.selectedQueType === 4 || this.props.selectedQueType === 10) ? "question" : selectedIndex,
      textEditorData: null,
      totalScore: 0,
      logicTotalScore: 0,
      formtype: this.props.formType,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }
  handleClick(e) {
    if (this.node.contains(e.target)) {
      console.log('You clicked INSIDE the component.')
    } else {

    }
  }

  _onHideBlock = () => {
    this.props.hideBlock(this.state.totalScore + this.state.logicTotalScore);
  }

  getData = (data, totalScore1) => {
    this.setState((prevState) => {
      let { options, totalScore } = prevState;
      this.props.totalScoreChange(totalScore, totalScore1);
      totalScore = totalScore1;
      options = data;
      return { options, totalScore }
    }
    );

    if (data.length == 0 || data.length < 2) {
      this.setState({ optionFieldError: true, logic: 0, logicOptionFieldError: true })
    } else {
      this.setState({ optionFieldError: false, logicOptionFieldError: false })
    }
  }

  getLogicData = (logicdata, logicTotalScore1) => {
    // this.setState({ logicOptions: logicdata });

    this.setState((prevState) => {
      let { logicOptions, logicTotalScore } = prevState;
      this.props.totalScoreChange(logicTotalScore, logicTotalScore1);
      logicTotalScore = logicTotalScore1;
      logicOptions = logicdata;
      return { logicOptions, logicTotalScore }
    }
    );

    if (logicdata.length == 0 || logicdata.length < 2) {
      this.setState({ logicOptionFieldError: true })
    } else {
      this.setState({ logicOptionFieldError: false })
    }
  }

  _getSelectedSection = async (e) => {
    let value = e.target.value;
    await this.setState({ selectedSectionIndex: value });
    console.log(this.state);
  }

  _addtoPreview = async () => {
    await this.setState({ submitted: true });

    if (this.state.question.length == 0 && this.state.textEditorData === null) {
      await this.setState({ questionFieldError: true })
      toast.error('Field can not be empty')
    }

    if (this.state.selectedQueTypeIndex == 0 || this.state.selectedQueTypeIndex == 3) {
      if (this.state.options.length == 0 || this.state.options.length < 2) {
        if (this.state.options.length === 1) {
          this.SCRef._addLastOption();
        } else {
          await this.setState({ optionFieldError: true })
        }
      }
      this.SCRef._addLastOption();
    }

    if (this.state.logic === 1) {
      if (this.state.logicQuestion.length == 0) {
        await this.setState({ logicQuestionFieldError: true })
      } else {
        await this.setState({ logicQuestionFieldError: false })
      }

      if (this.state.logicResponseId == null) {
        await this.setState({ logicResponseIdFieldError: true })
      } else {
        await this.setState({ logicResponseIdFieldError: false })
      }

      if (this.state.logicSelectedQueTypeIndex == 0 || this.state.logicSelectedQueTypeIndex == 3) {
        if (this.state.logicOptions.length == 0 || this.state.logicOptions.length < 2) {
          if (this.state.logicOptions.length === 1) {
            this.LogicSCRef._addLastOption();
          } else {
            await this.setState({ logicOptionFieldError: true });
          }
        }
        this.LogicSCRef._addLastOption();
      } else {
        await this.setState({ logicOptionFieldError: false })
      }
    }

    if (this.state.questionFieldError || this.state.optionFieldError || this.state.logicQuestionFieldError || this.state.logicResponseIdFieldError || this.state.logicOptionFieldError) {
      return;
    }

    var question_type = 1
    if (queTypesDropdown[this.state.selectedQueTypeIndex].id == 5 || queTypesDropdown[this.state.selectedQueTypeIndex].id == 11) {
      question_type = 2;
    }

    if (this.state.logic === 1) {
      if (this.state.options.length > 0) {
        this.state.options.map((opt, index) => {
          opt.field_response = [];
          if (this.state.logicResponseId == index) {
            opt.logic_status = 1;
            opt.field_response.push({
              question: this.state.logicQuestion,
              description: this.state.logicDescription,
              mandatory: this.state.logicMandatory,
              notes: this.state.logicNotes,
              evidence: this.state.logicEvidence,
              recommendation: this.state.logicRecommendation,
              response_type: Number(this.state.logicSelectedQueTypeIndex + 1),
              logic_response: this.state.logicOptions,
              scoring: this.state.logicScoring,
              logic_total_score: this.state.logicTotalScore,
            })
          } else {
            opt.logic_status = 0;
          }
        })
      }
    } else {
      this.state.options.map((opt, index) => {
        opt.logic_status = 0;
        opt.field_response = [];
      })
    }

    var sectionTitle;
    if (this.props.section !== undefined) {
      // Setting dynamic section title based on selected section option from dropdown
      if (this.props.sectionArr !== undefined) {
        var index = this.state.selectedSectionIndex;
        sectionTitle = (index === 'question') ? '' : this.props.sectionArr[index].title || this.props.sectionArr[index].section_name;
      } else {
        sectionTitle = this.props.section.title
      }
      //var sectionTitle = this.props.section.title
    } else {
      sectionTitle = '';
    }

    this.props.addNew({
      type: 'notsection',
      question: this.state.question,
      question_type: question_type,
      description: this.state.description,
      mandatory: this.state.mandatory,
      notes: this.state.notes,
      evidence: this.state.evidence,
      recommendation: this.state.recommendation,
      scoring: this.state.scoring,
      logic: this.state.logic,
      response_type: queTypesDropdown[this.state.selectedQueTypeIndex].id,
      section: sectionTitle,
      order: this.props.order,
      file_path: '',
      file_type: '',
      field_response: this.state.options,
      selectedSectionIndex: this.state.selectedSectionIndex,
      text_editor_data: this.state.textEditorData,
      declaration_text: this.state.textEditorData,
      total_score: this.state.totalScore,
    })
  }

  handleTextEditorChange = async (event, editor) => {
    const data = editor.getData();
    await this.setState((prevState) => {
      let { question } = prevState;
      question = data;
      return { question };
    });
  };

  debounceFunc = _.debounce(function (callback) {
    callback()
  }, 300);

  render() {
    const { name, value: formikValue } = this.props.field;
    const { setFieldValue } = this.props.form;

    return (
      <div className="box-dynamic" ref={node => this.node = node}>
        <Row>
          <Col sm={8}>
            <div className="dynamic-section">
              {!(this.state.selectedQueTypeIndex == 8 || this.state.selectedQueTypeIndex == 9) &&
                <>
                  <div className="form-group">
                    <Field
                      type="text"
                      name={`field.question`}
                      placeholder={(this.state.selectedQueTypeIndex === 4 || this.state.selectedQueTypeIndex === 10) ? "Description" : "Write your question"}
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value == '') {
                          this.setState({ questionFieldError: true })
                        } else {
                          this.setState({ questionFieldError: false })
                        }
                        this.setState({ question: e.target.value })
                      }
                      }
                    />
                    {(this.state.questionFieldError && this.state.submitted) && <div className="inputErrorMsg">Question is required</div>}
                  </div>
                  {!(this.state.selectedQueTypeIndex === 4 || this.state.selectedQueTypeIndex === 10) &&
                    <div className="form-group">
                      <Field type="text" name={`field.description`} placeholder="Description" className="form-control" onChange={(e) => this.setState({ description: e.target.value })} />
                    </div>
                  }
                </>
              }
              {this.state.selectedQueTypeIndex == 0 && <SingleChoice getData={this.getData} scoring={this.state.scoring} ref={SCRef => this.SCRef = SCRef} />}

              {this.state.selectedQueTypeIndex == 1 && <ShortAnswer />}

              {this.state.selectedQueTypeIndex == 2 && <AddNumber />}
              {this.state.selectedQueTypeIndex == 3 && <SingleChoice getData={this.getData} scoring={this.state.scoring} ref={SCRef => this.SCRef = SCRef} />}
              {this.state.selectedQueTypeIndex == 4 && <UploadFile type="file" />}
              {this.state.selectedQueTypeIndex == 5 && <AddDate />}
              {this.state.selectedQueTypeIndex == 6 && <AddTime />}
              {this.state.selectedQueTypeIndex == 7 && <Recommendation />}
              {(this.state.selectedQueTypeIndex == 8 || this.state.selectedQueTypeIndex == 9) && <CKEditor
                editor={ClassicEditor}
                data=""
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={this.handleTextEditorChange}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />}
              {this.state.selectedQueTypeIndex == 10 && <UploadFile type="image/video" />}
              {(this.state.optionFieldError && this.state.submitted) && <div className="inputErrorMsg">Minimum 2 options are required</div>}
              {(this.state.logicOptionFieldError && this.state.logicBoxClick && this.state.options.length < 2) && <div className="inputErrorMsg">Minimum 2 options are required for logic</div>}
            </div>
            {!(this.state.selectedQueTypeIndex == 8 || this.state.selectedQueTypeIndex == 9) &&
              <div className="form-group">
                <label className="check-box">Mandatory
                  <Field
                    name={`field.mandatory`}
                    type="checkbox"
                    checked={this.state.mandatory === 1}
                    value={this.state.mandatory}
                    onChange={async () => {
                      if (this.state.mandatory === 0) {
                        var val = 1;
                      } else {
                        var val = 0;
                      }
                      await this.setState({ mandatory: val })
                      setFieldValue(`field.mandatory`, val)
                    }}
                  />
                  <span className="checkmark1"></span>
                </label>
                {!(this.state.selectedQueTypeIndex === 1 || this.props.formType == 8 || this.props.formType == 11 || this.props.formType == 13) &&
                  <label className="check-box">Notes
                    <Field
                      name={`field.notes`}
                      type="checkbox"
                      checked={this.state.notes === 1}
                      value={this.state.notes}
                      onChange={async () => {
                        if (this.state.notes === 0) {
                          var val = 1;
                        } else {
                          var val = 0;
                        }
                        await this.setState({ notes: val })
                        setFieldValue(`field.notes`, val)
                      }}
                    />
                    <span className="checkmark1"></span>
                  </label>
                }
                {!(this.state.selectedQueTypeIndex === 4 || this.state.selectedQueTypeIndex === 10 || this.props.formType == 8 || this.props.formType == 13 || this.props.formType == 11 || this.props.formType == 12) &&
                  <label className="check-box">Evidence
                    <Field
                      name={`field.evidence`}
                      type="checkbox"
                      checked={this.state.evidence === 1}
                      value={this.state.evidence}
                      onChange={async () => {
                        if (this.state.evidence === 0) {
                          var val = 1;
                        } else {
                          var val = 0;
                        }
                        await this.setState({ evidence: val })
                        setFieldValue(`field.evidence`, val)
                      }}
                    />
                    <span className="checkmark1"></span>
                  </label>
                }
                {(this.state.selectedQueTypeIndex == 0 || this.state.selectedQueTypeIndex == 3 || this.props.formType == 11 || this.props.formType == 13) &&
                  <label className="check-box">Logic
                    <Field
                      name={`field.logic`}
                      type="checkbox"
                      checked={this.state.logic === 1}
                      value={this.state.logic}
                      onChange={async () => {
                        if (this.state.options.length > 1) {
                          if (this.state.logic === 0) {
                            var val = 1;
                          } else {
                            var val = 0;
                          }
                          this.SCRef._addLastOption();
                          await this.setState({ logic: val, logicOptionFieldError: false, logicBoxClick: true })
                          setFieldValue(`field.logic`, val)
                        } else {
                          if (this.state.options.length === 1) {
                            this.SCRef._addLastOption();
                          }
                          this.setState({ logicOptionFieldError: true, logicBoxClick: true })
                        }
                      }}
                    />
                    <span className="checkmark1"></span>
                  </label>
                }
                {(!(this.props.formType == 1 || this.props.formType == 3 || this.props.formType == 4 || this.props.formType == 5 || this.props.formType == 6 || this.props.formType == 7 || this.props.formType == 9 || this.props.formType == 12 || this.props.formType == 8 || this.props.formType == 11 || this.props.formType == 13 || this.props.formType == 10) && (this.state.selectedQueTypeIndex == 0 || this.state.selectedQueTypeIndex == 3)) &&
                  <label className="check-box">Recommendation
                    <Field
                      name={`field.recommendation`}
                      type="checkbox"
                      checked={this.state.recommendation === 1}
                      value={this.state.recommendation}
                      onChange={async () => {
                        if (this.state.recommendation === 0) {
                          var val = 1;
                          await this.setState({
                            logicRecommendation: 0
                          })
                        } else {
                          var val = 0;
                        }
                        await this.setState({ recommendation: val })
                        setFieldValue(`field.recommendation`, val)
                      }}
                    />
                    <span className="checkmark1"></span>
                  </label>
                }
                {(!(this.props.formType == 1 || this.props.formType == 5 || this.props.formType == 8 || this.props.formType == 11) && (this.state.selectedQueTypeIndex == 0 || this.state.selectedQueTypeIndex == 3)) &&
                  <label className="check-box">Scoring
                    <Field
                      name={`field.scoring`}
                      type="checkbox"
                      checked={this.state.scoring === 1}
                      value={this.state.scoring}
                      onChange={async () => {
                        var val;
                        if (this.state.scoring === 0) {
                          val = 1;
                        } else {
                          val = 0;
                        }
                        await this.setState({ scoring: val })
                        setFieldValue(`field.scoring`, val)
                      }}
                    />
                    <span className="checkmark1"></span>
                  </label>
                }
              </div>
            }
          </Col>
          <Col sm={4}>
            <div className="box-dynamic-right">
              {this.state.scoring == 1 &&
                <label>Score :
                  <span>{this.state.totalScore}</span>
                </label>
              }
              <div className="dropdown">
                <button className="dropbtn"><img src={queTypesDropdown[this.state.selectedQueTypeIndex].image} /> {queTypesDropdown[this.state.selectedQueTypeIndex].name} <i className="fa fa-angle-down"></i></button>
                <div className="dropdown-content">
                  {
                    queTypesDropdown.map((data, index) => {

                      if (specialModules.includes(this.props.formType)) {
                        if (whiteListModulesDropdown.includes(data.id)) {
                          return <a
                            key={index}
                            onClick={async (e) => {
                              e.preventDefault()
                              await this.setState({ selectedQueTypeIndex: index })
                              if (index == 0 || index == 3) {
                                this.setState({ optionFieldError: true })
                              } else {
                                this.setState({ optionFieldError: false })
                              }
                            }}
                            href="#"
                          >
                            <img src={data.image} alt={`uploaded media`} /> {data.name}
                          </a>
                        } else {
                          return null
                        }
                      } else {
                        return <a
                          key={index}
                          onClick={async (e) => {
                            e.preventDefault()
                            await this.setState({ selectedQueTypeIndex: index })
                            if (index == 0 || index == 3) {
                              this.setState({ optionFieldError: true })
                            } else {
                              this.setState({ optionFieldError: false })
                            }
                          }}
                          href="#"
                        >
                          <img src={data.image} alt={`uploaded media`} /> {data.name} 
                        </a>
                      }

                    }

                    )
                  }
                </div>
              </div>
              {
                (this.props.sectionArr !== undefined && this.props.sectionArr.length > 0) &&
                <select onChange={this._getSelectedSection} className="form-control" style={{ width: '75%' }} value={this.state.selectedSectionIndex}>
                  <option value="question" key="sectionOption-question">Question</option>
                  {!(this.state.selectedQueTypeIndex === 9 || this.state.selectedQueTypeIndex === 4 || this.state.selectedQueTypeIndex === 10) &&
                    this.props.sectionArr.map((item, index) => <option value={index} key={`sectionOption-${index}`}>{item.section_name === undefined ? item.title : item.section_name}</option>
                    )
                  }
                </select>
              }
            </div>
            <div className="box-dynamic-bottom">
              <Button className="icon-btn" title="Delete" onClick={() => this._onHideBlock()}>
                <Trash className="icon-style btn-color" />
              </Button>
              <Button
                className="icon-btn" title="Save"
                onClick={() => {
                  this.debounceFunc(() => {this._addtoPreview() })
                }}

              >
                <Save className="icon-style btn-color" />
              </Button>
            </div>
          </Col>
        </Row>
        {(this.state.logic === 1 && this.state.options.length > 1) &&
          <div className="logic-section">
            <Row>
              <Col sm={8}>
                <h5 style={{ display: 'flex', justifyContent: "space-between" }}>Create Logic
                  {this.state.logicScoring === 1 &&
                    <span>{`Score : ${this.state.logicTotalScore}`}</span>
                  }
                </h5>
                <div className="form-group">
                  <div className="radio-inline-btn">
                    {
                      this.state.options.map((option, optionIndex) =>
                        <label className="radio-box" key={`option-${optionIndex}`}> {option.response}
                          <input
                            type="radio"
                            name="logicOption"
                            value={optionIndex}
                            checked={this.state.logicResponseId === optionIndex}
                            onChange={() => this.setState({ logicResponseId: optionIndex })}
                          />
                          <span className="checkmark"></span>
                        </label>
                      )
                    }
                  </div>
                  {(this.state.submitted && this.state.logicResponseId == null) && <div className="inputErrorMsg">Please select one option to apply logic</div>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="logicQuestion"
                    placeholder="Write your question"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ logicQuestion: e.target.value })
                    }
                    }
                  />
                  {(this.state.logicQuestionFieldError && this.state.submitted) && <div className="inputErrorMsg">Question is required</div>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="logicDescription"
                    placeholder="Description"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ logicDescription: e.target.value })
                    }
                    }
                  />
                </div>
                {this.state.logicSelectedQueTypeIndex == 0 && <SingleChoice getData={this.getLogicData} scoring={this.state.logicScoring} ref={LogicSCRef => this.LogicSCRef = LogicSCRef} />}

                {this.state.logicSelectedQueTypeIndex == 2 && <AddNumber />}

                {this.state.logicSelectedQueTypeIndex == 3 && <SingleChoice getData={this.getLogicData} scoring={this.state.logicScoring} ref={LogicSCRef => this.LogicSCRef = LogicSCRef} />}

                {this.state.logicSelectedQueTypeIndex == 4 && <UploadFile />}
                {this.state.logicSelectedQueTypeIndex == 5 && <AddDate />}
                {this.state.logicSelectedQueTypeIndex == 6 && <AddTime />}
                {this.state.logicSelectedQueTypeIndex == 7 && <Recommendation />}
                {(this.state.logicOptionFieldError && this.state.submitted) && <div className="inputErrorMsg">Minimum 2 options are required</div>}
                <div className="form-group">
                  <label className="check-box">Mandatory
                    <Field
                      name="logicMandatory"
                      type="checkbox"
                      checked={this.state.logicMandatory === 1}
                      value={this.state.logicMandatory}
                      onChange={async () => {
                        if (this.state.logicMandatory === 0) {
                          var val = 1;
                        } else {
                          var val = 0;
                        }
                        await this.setState({ logicMandatory: val })
                      }}
                    />
                    <span className="checkmark1"></span>
                  </label>
                  {!(this.props.formType == 8 || this.props.formType == 11 || this.props.formType == 13) && (
                    <label className="check-box">Notes
                      <Field
                        name="logicNotes"
                        type="checkbox"
                        checked={this.state.logicNotes === 1}
                        value={this.state.logicNotes}
                        onChange={async () => {
                          if (this.state.logicNotes === 0) {
                            var val = 1;
                          } else {
                            var val = 0;
                          }
                          await this.setState({ logicNotes: val })
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>)}

                  {!(this.props.formType == 12 || this.props.formType == 8 || this.props.formType == 11 || this.props.formType == 13) &&
                    <label className="check-box">Evidence
                      <Field
                        name="logicEvidence"
                        type="checkbox"
                        checked={this.state.logicEvidence === 1}
                        value={this.state.logicEvidence}
                        onChange={async () => {
                          if (this.state.logicEvidence === 0) {
                            var val = 1;
                          } else {
                            var val = 0;
                          }
                          await this.setState({ logicEvidence: val })
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>}

                  {(!(this.props.formType == 1 || this.props.formType == 5 || this.props.formType == 6 || this.props.formType == 7 || this.props.formType == 9 || this.props.formType == 12 || this.props.formType == 8 || this.props.formType == 11 || this.props.formType == 13 || this.props.formType == 3 || this.props.formType == 4 || this.props.formType == 6 || this.props.formType == 7 || this.props.formType == 9) && (this.state.selectedQueTypeIndex === 0 || this.state.selectedQueTypeIndex === 3)) &&
                    <label className="check-box">Recommendation
                      <Field
                        name="logicRecommendation"
                        type="checkbox"
                        checked={this.state.logicRecommendation === 1}
                        value={this.state.logicRecommendation}
                        onChange={async () => {
                          if (this.state.logicRecommendation === 0) {
                            var val = 1;
                            await this.setState({
                              recommendation: 0
                            })
                          } else {
                            var val = 0;
                          }
                          await this.setState({ logicRecommendation: val })
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  }
                  {(!(this.props.formType == 1 || this.props.formType == 5) && (this.state.logicSelectedQueTypeIndex === 0 || this.state.logicSelectedQueTypeIndex === 3) && this.state.scoring === 1) &&
                    <label className="check-box">Scoring
                      <Field
                        name="logicScoring"
                        type="checkbox"
                        checked={this.state.logicScoring === 1}
                        value={this.state.logicScoring}
                        onChange={async () => {
                          if (this.state.logicScoring === 0) {
                            var val = 1;
                            await this.setState({
                              logicScoring: 0
                            })
                          } else {
                            var val = 0;
                          }
                          await this.setState({ logicScoring: val })
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  }
                </div>
              </Col>
              <Col sm={4}>
                <div className="box-dynamic-right">
                  <div className="dropdown">

                    <button className="dropbtn"><img src={queTypesDropdown[this.state.logicSelectedQueTypeIndex].image} /> {queTypesDropdown[this.state.logicSelectedQueTypeIndex].name} <i className="fa fa-angle-down"></i></button>

                    {!(this.props.formType == 8 || this.props.formType == 11 || this.props.formType == 13) ? (
                      <div className="dropdown-content">
                        {
                          queTypesDropdown.map((data, index) => {

                            if (specialModules.includes(this.props.formType)) {
                              if (whiteListModulesDropdown.includes(data.id)) {
                                return <a
                                  key={index}
                                  onClick={async (e) => {
                                    e.preventDefault()
                                    await this.setState({ selectedQueTypeIndex: index })
                                    if (index == 0 || index == 3) {
                                      this.setState({ optionFieldError: true })
                                    } else {
                                      this.setState({ optionFieldError: false })
                                    }
                                  }}
                                  href="#"
                                >
                                  <img src={data.image} alt={`uploaded media`} /> {data.name}
                                </a>
                              } else {
                                return null
                              }
                            } else {
                              return <a
                                key={index}
                                onClick={async (e) => {
                                  e.preventDefault()
                                  await this.setState({ selectedQueTypeIndex: index })
                                  if (index == 0 || index == 3) {
                                    this.setState({ optionFieldError: true })
                                  } else {
                                    this.setState({ optionFieldError: false })
                                  }
                                }}
                                href="#"
                              >
                                <img src={data.image} alt={`uploaded media`} /> {data.name}
                              </a>
                            }

                          }

                          )
                        }
                      </div>) : (
                      <div className="dropdown-content">
                        {
                          queTypesDropdown.map((data, index) => {

                            if (specialModules.includes(this.props.formType)) {
                              if (whiteListModulesDropdown.includes(data.id)) {
                                return <a
                                  key={index}
                                  onClick={async (e) => {
                                    e.preventDefault()
                                    await this.setState({ selectedQueTypeIndex: index })
                                    if (index == 0 || index == 3) {
                                      this.setState({ optionFieldError: true })
                                    } else {
                                      this.setState({ optionFieldError: false })
                                    }
                                  }}
                                  href="#"
                                >
                                  <img src={data.image} alt={`uploaded media`} /> {data.name}
                                </a>
                              } else {
                                return null
                              }
                            } else {
                              return <a
                                key={index}
                                onClick={async (e) => {
                                  e.preventDefault()
                                  await this.setState({ selectedQueTypeIndex: index })
                                  if (index == 0 || index == 3) {
                                    this.setState({ optionFieldError: true })
                                  } else {
                                    this.setState({ optionFieldError: false })
                                  }
                                }}
                                href="#"
                              >
                                <img src={data.image} alt={`uploaded media`} /> {data.name}
                              </a>
                            }

                          }

                          )
                        }
                      </div>
                      // <div className="dropdown-content">
                      //   {
                      //     queTypesDropdown.map((data, index) =>
                      //       (index == 0 || index == 3) &&
                      //       <a
                      //         key={index}
                      //         onClick={async (e) => {
                      //           e.preventDefault()
                      //           await this.setState({ logicSelectedQueTypeIndex: index })
                      //           if (index == 0 || index == 3) {
                      //             this.setState({ logicOptionFieldError: true })
                      //           } else {
                      //             this.setState({ logicOptionFieldError: false })
                      //           }
                      //         }}
                      //         href="#"
                      //       >
                      //         <img src={data.image} /> {data.name}test survey
                      //       </a>
                      //     )
                      //   }
                      // </div>
                    )}
                  </div>
                </div>
                <div className="box-dynamic-bottom">
                  <Button
                    className="icon-btn"
                    onClick={() => {
                      this.props.totalScoreChange(this.state.logicTotalScore, 0);
                      this.setState({
                        logic: 0,
                        logicData: [],
                        logicResponseId: null,
                        logicQuestion: '',
                        logicDescription: '',
                        logicMandatory: 0,
                        logicNotes: 0,
                        logicEvidence: 0,
                        logicRecommendation: 0,
                        logicOptions: [],
                        logicQuestionFieldError: false,
                        logicOptionFieldError: false,
                        logicBoxClick: false,
                        logicResponseIdFieldError: false,
                        logicSelectedQueTypeIndex: 0,
                        logicTotalScore: 0,
                        logicScoring: 0,
                      })
                    }
                    }
                  >
                    <Trash className="icon-style" />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        }
      </div>
    )
  }
}