import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import { ArrowLeftCircle } from 'react-feather';
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import Url from 'app/shared/constants/Url';
import axios from 'axios';
const qs = require('qs');

const alertMsg = (props) => {
  if(props.type == 'error') {
    toast.error(props.message,{
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    });
  } else {
    toast.success(props.message,{
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    });
  }
}

export default class ForgotPassword extends Component{

  state = {
    formSubmitting: false
  }
  
  render() {
    return (
      <div className="login-form">
        <h4>Forgot Password</h4>
        <Formik 
          initialValues={{ email: ""}}
          validate={values => {
            let errors = {};
            if (values.email === "") {
              errors.email = "Email is required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = "Invalid email address format";
            }
            return errors;
          }}
          onSubmit={async(values, actions) => {
            this.setState({formSubmitting: true})
            axios.post(Url.forgotPassword,
              qs.stringify({
                email: values.email
              }), 
              {
                headers: { 
                  "Content-Type": "application/x-www-form-urlencoded"
                }
              }
            )
            .then(res => { console.log(res);
              if(res.data.status == 200) {
                alertMsg({message: res.data.msg, type: 'success'});
              } else {
                alertMsg({message: res.data.msg, type: 'error'});
              }
              this.setState({formSubmitting: false})
            })
            .catch(error => {
              this.setState({formSubmitting: false})
            })   
          }}
        >
          {({ touched, errors, isSubmitting, handleSubmit, isValid, dirty, values }) => (
            <Form onSubmit={handleSubmit}>
              <div className="floatingLabelInput">
                <label className={values.email == '' || values.length == 0 ? null: 'on'}>Enter Your Registered Email-Id</label>
                <Field 
                  name="email"  
                  type="email" 
                  placeholder="Enter Your Registered Email-Id"
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="inputErrorMsg"
                />
              </div>
              <div className="login-btn">
                <Button 
                  type="submit" 
                  className={!isValid || !dirty ? 'disabled-login-btn' : 'red-btn'}
                  disabled={!isValid || this.state.formSubmitting || !dirty}
                >
                    {this.state.formSubmitting ? "Please wait..." : "SUBMIT"}
                </Button>
              </div>
              <div className="back-div">
                <ArrowLeftCircle color='#000' size="18" />
                <Link to={`/auth/login`}>Back to LOGIN</Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
}