import React from "react";
import PopupImage from "../../../../assets/images/confirm-popup.png";


const ConfirmPopupImage = () => {
    return (
        <img
            className="submit-img img-fluid"
            src={PopupImage}
            alt="confirm-popup-image"
        />
    )
}

export default ConfirmPopupImage;

