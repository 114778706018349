import React, { Component } from "react";

export default class UploadFile extends Component{
  render() {
    return (
			<div className="form-dynamic">
        <div className="file-upload">
          <label className="file-upload__label">Browse File</label>
          <input id="upload" className="file-upload__input" type="file" name="file-upload" disabled={true}/>
        </div>
        <div className="file-format">
          <p>Allowed file format</p>
          {this.props.type === "file" ?
            <>
              <img src={require('assets/images/icons/fpdf.svg')} /> 
              <img src={require('assets/images/icons/ffile.svg')} /> 
              <img src={require('assets/images/icons/fcross.svg')} /> 
            </>
            :
            <>
              <img src={require('assets/images/icons/fimage.svg')} /> 
              <img src={require('assets/images/icons/play-grey.svg')} /> 
            </>
          }
        </div>
			</div>
    )
  }
}