import React from "react";

// Assumed item => 0 means String name
// item => 1 means Id

const CommonDropdown = ({
  title,
  optionList,
  onChangeHandle,
  isRequired,
  titleview = true,
  value
}) => {
  return (
    <div style={{ paddingRight: '0.8vw' }}>
      {/* {titleview && <label className="form-label"> {title}</label>} */}
      {/* {isRequired && <span className="inputErrorMsg">&nbsp;*</span>} */}
      <select
        className="form-control1"
        onChange={(event) => onChangeHandle(event.target.value)}
        value={value}
      >
        {optionList.length ? (
          <>
            {/* <option value={""}>Select {title}</option> */}

          {title.length===0?"Select TimeZone" :<option value={""}>Select {title}</option> }

            {optionList.map((item, index) => (
              <option
                key={`project-option-${index}`}
                value={
                  Object.keys(item)[1] === "id"
                    ? item[`${Object.keys(item)[1]}`]
                    : item[`${Object.keys(item)[0]}`]
                }
              >
                {Object.keys(item)[1] !== "id"
                  ? item[`${Object.keys(item)[1]}`]
                  : item[`${Object.keys(item)[0]}`]}
              </option>
            ))}
          </>
        ) : (
          <option value={""}>No {title} Available</option>
        )}
      </select>
    </div>
  );
};

CommonDropdown.defaultProps = {
  title: "",
  optionList: [],
  isRequired: true,
  onChangeHandle: () => { },
  value: ""
};

export default CommonDropdown;
