import React from "react";
import LoaderImage from "../../../../assets/images/loader1.gif";

const LoadingView = ({ showLoading }) => {
  return (
    <>
      {showLoading ? (
        <div className="loader" style={{ display: "block" }}>
          <img
            className="loader-img"
            // src={require("assets/images/loader1.gif")}
            src={LoaderImage}
            alt={`loader`}
          />
        </div>
      ) : null}
    </>
  );
};

export default LoadingView;
