import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import { Trash } from 'react-feather';

export default class AddDropdown extends Component{
  render() {
    return (
			<div className="form-dynamic">
        <div className="form-group">
          <div className="radio-delete">
            <label className="radio-box"> Any thing that can add
              <input type="radio" name="radio" />
              <span className="checkmark"></span>
            </label>
            <Button className="radio-btn">
              <Trash className="radio-icon"/>
            </Button>
          </div>
          <div className="radio-delete">
            <label className="radio-box"> If any other option selected
              <input type="radio" name="radio" />
              <span className="checkmark"></span>
            </label>
            <Button className="radio-btn">
              <Trash className="radio-icon"/>
            </Button>
          </div>
          <label className="radio-box">
            <input type="radio" name="radio" />
            <span className="checkmark last-checkmark"></span>
          </label>
          <input type="text" name="option" placeholder="Option 3" className="form-control radio-input"/>
        </div>
			</div>
    )
  }
}