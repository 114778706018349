import React, { useState, useEffect } from "react";
import Url from "app/shared/constants/Url";
import LoadingView from "app/shared/components/Loader/LoadingView";
import axios from "axios";
import FullViewModal from "app/shared/utility/FullViewModal";
import Lightbox from "react-image-lightbox";
import { Trash } from "react-feather";
import {
  SuccessToast,
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";
import { DeletePopup } from "app/shared/components/Popups";
import { Button, Modal } from "react-bootstrap";
import { RiFolderDownloadLine } from "react-icons/ri";
import ConfirmPopupImage from "app/shared/components/Loader/ConfirmPopupImage";

const qs = require("qs");

const PolicyActivityList = ({ isMarket }) => {
  const userInfo = JSON.parse(localStorage.getItem("userData"));
  const [dataLoading, setDataloading] = useState(true);
  const [helpList, setHelpList] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  const [fullImage, setFullImage] = useState("");
  const [showLighBoxImage, setShowLightBoxImage] = useState(false);
  const [showPDFView, setShowPDFView] = useState(false);
  const [pdfURL, setPDFURL] = useState("");
  const [showReportDeletePopup, setShowReportDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [downloadPopUp, showDownloadPopUp] = useState(false);
  const [downloadId, setDownloadId] = useState(null);
  const [downloadName, setDownloadName] = useState(null);

  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (isMarket != 1) {
      _fetchHelpList();
    }
    _fetchTrainingList();
  }, []);

  const _fetchHelpList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let fd = new FormData();
    fd.append("auth_key", token);
    fd.append("user_id", userId);
    fd.append("page_no", 1);
    fd.append("filter_status", 0);

    const contentType = {
      "Content-Type": "multipart/form-data",
    };

    await axios
      .post(Url.tbt.activityList, fd, contentType)
      .then(async (response) => {
        setDataloading(false);
        if (response.data.status == 200) {
          setHelpList(response.data.actList);
        }
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const _fetchTrainingList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let fd = new FormData();
    fd.append("type", 3);

    const contentType = {
      "Content-Type": "multipart/form-data",
    };

    await axios
      .post(Url.external.externalDocumentList, fd, contentType)
      .then(async (response) => {
        setDataloading(false);
        if (response.data.status == 200) {
          setTrainingList(response.data.documentList);
        }
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const _showPDF = async (pdfURL) => {
    setShowPDFView(true);
    setPDFURL(pdfURL);
  };
  const _hidePDFPopup = async (pdfURL) => {
    setShowPDFView(false);
    setPDFURL("");
  };

  const _hideImageLightbox = () => {
    setShowLightBoxImage(false);
  };

  const deleteData = async (id) => {
    setDeleteId(id);
    setShowReportDeletePopup(true);
  };

  const cancelReportDeletion = () => {
    setShowReportDeletePopup(false);
    setDeleteId(null);
  };

  const deleteTrainingData = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      document_id: deleteId,
      type: 3,
    };

    await axios
      .post(Url.tbt.deleteDocument, qs.stringify(requestData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(async (response) => {
        if (response.status == 200) {
          let trainingList1 = trainingList.filter(
            (feed) => feed.document_id !== deleteId
          );
          setTrainingList(trainingList1);
          setShowReportDeletePopup(false);
          SuccessToast("Policy Deleted successfully");
        }
      })
      .catch((error) => {
        SuccessToast("Policy cannot be deleted. Try again");
      });
  };

  const initiateDownload = async (id, name) => {
    await setDownloadId(id);
    await setDownloadName(name);
    await showDownloadPopUp(true);
  };

  const _axiosAPI = async (data) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var requestData = {
      auth_key: token,
      user_id: userId,
    };

    if (data.document_id !== undefined) {
      requestData.document_id = downloadId;
    }

    return axios.post(data.url, qs.stringify(requestData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  };

  const _useForm = async () => {
    var request_data = {
      url: Url.formType1.useDocumentFromMarketPlace,
      document_id: downloadId,
    };
    await setDataloading(false);
    // axiosAPI function for axios http call
    let responseObj = _axiosAPI(request_data);

    // Response processing
    responseObj
      .then(async (response) => {
        if (response.data.status === 200) {
          setTimeout(() => {
            setDataloading(false);
          }, 500);
          SuccessToast("Document downloaded successfully");
          showDownloadPopUp(false);
          _fetchTrainingList();
        }
      })
      .catch((error) => {
        ErrorToast("Error in downloading document");
      });
  };

  const styles = {
    number_circle: {
      borderRadius: "50%",
      width: "24px",
      height: "24px",
      background: "#fff",
      border: "2px solid rgb(0 0 0)",
      color: "rgb(0 0 0)",
      textAlign: "center",
      display: "inline-block",
    },
  };

  return (
    <div className="">
      <LoadingView showLoading={dataLoading} />
      {isMarket != 1 && (
        <div className="shadow p-3 mb-2 rounded d-flex justify-content-between w-100">
          <h5>Policies</h5>
        </div>
      )}
      <div className="d-flex w-100 flex-wrap justify-content-around">
        {helpList.length
          ? helpList.map((helpItem, helpIndex) =>
              helpItem.file_pdf ? (
                <div
                  className="help-video-card m-2"
                  key={`helpItem-${helpIndex}`}
                >
                  {/* {_getDynamicItem(helpItem)} */}
                  <div className="mt-2 p-3">
                    {!!helpItem.file_pdf && (
                      <embed
                        src={`${helpItem.file_pdf}#toolbar=0`}
                        frameborder="0"
                        style={{
                          width: "100%",
                          height: "35vh",
                          paddingBottom: "5%",
                        }}
                        controls
                        controlsList="nodownload"
                      />
                    )}

                    <h5> {helpItem.activity} </h5>
                    {!!helpItem.file_pdf && (
                      <text
                        className="cursor-pointer"
                        style={{ color: "tomato" }}
                        onClick={() => _showPDF(helpItem.file_pdf)}
                      >
                        View
                      </text>
                    )}
                  </div>
                </div>
              ) : null
            )
          : isMarket != 1 && <h5 className="text-danger">No Data found</h5>}

        {trainingList.length ? (
          trainingList.map((helpItem, helpIndex) =>
            helpItem.file_pdf ? (
              <div
                className="help-video-card m-2"
                key={`helpItem-${helpIndex}`}
              >
                {/* {_getDynamicItem(helpItem)} */}
                <div className="mt-2 p-3">
                  {!!helpItem.file_pdf && (
                    <embed
                      src={`${helpItem.file_pdf}#toolbar=0`}
                      frameborder="0"
                      style={{
                        width: "100%",
                        height: "35vh",
                        paddingBottom: "5%",
                      }}
                      controls
                      controlsList="nodownload"
                    />
                  )}

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h5 style={{ display: "inline-block" }}>
                      {" "}
                      {helpItem.document_name}{" "}
                    </h5>
                    {isMarket != 1 && userData?.designation_flag === 1 ? (
                      <Trash onClick={() => deleteData(helpItem.document_id)} />
                    ) : null}
                    {isMarket == 1 && (
                      <>
                        {helpItem.is_downloaded === 0 ? (
                          <Button
                            className="icon-btn"
                            title="Download"
                            style={{ paddingTop: 0 }}
                            onClick={() =>
                              initiateDownload(
                                helpItem.document_id,
                                helpItem.document_name
                              )
                            }
                          >
                            <RiFolderDownloadLine
                              style={{ height: 24, width: 24, color: "black" }}
                            />
                          </Button>
                        ) : null}
                        <div
                          style={{
                            paddingLeft: "12px",
                            display: "inline-block",
                          }}
                        >
                          <div style={styles.number_circle}>
                            {helpItem.download_count}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {!!helpItem.file_pdf && (
                    <text
                      className="cursor-pointer"
                      style={{ color: "tomato" }}
                      onClick={() => _showPDF(helpItem.file_pdf)}
                    >
                      View
                    </text>
                  )}
                </div>
              </div>
            ) : null
          )
        ) : (
          <h5 className="text-danger">No Data found</h5>
        )}
      </div>

      <FullViewModal showModal={showPDFView} hideModal={_hidePDFPopup}>
        <embed
          src={`${pdfURL}#toolbar=0`}
          frameborder="0"
          style={{ width: "100%", height: "80vh" }}
          controls
          controlsList="nodownload"
        />
      </FullViewModal>

      {showLighBoxImage && (
        <Lightbox
          mainSrc={fullImage}
          onCloseRequest={_hideImageLightbox}
          imageTitle={<h2>Do's and Don'ts</h2>}
        />
      )}
      <DeletePopup
        showDeletePopup={showReportDeletePopup}
        onCancel={() => cancelReportDeletion()}
        onConfirm={() => deleteTrainingData()}
      />
      <Modal
        centered
        show={downloadPopUp}
        className="submit-modal"
        onHide={() => this._showHideModal("use_form", {}, false)}
        backdrop="static"
      >
        <Modal.Header />
        <Modal.Body>
          <ConfirmPopupImage />
          <p className="submit-text">
            Do you really want to Download <b>{`${downloadName} ` || ``}</b>
            Form ?
          </p>
          <div className="modal-footer-btn">
            <button
              type="submit"
              className="red-btn"
              onClick={() => showDownloadPopUp(false)}
            >
              NO
            </button>
            <button
              type="submit"
              className="red-btn"
              onClick={() => _useForm()}
            >
              YES
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PolicyActivityList;
