import React, { Component } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import { File, Search, Info } from "react-feather";
import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";
import TrackerList from "./../Tracker/TrackerList";
import CapaList from "./../Tracker/CapaList";
import NmList from "./../NmList/NmList";
import ArchiveList from "./../Archive/ArchiveList";
import Url from "app/shared/constants/Url";
import axios from "axios";
import Workbook from "react-excel-workbook";
import { CHANGE_TAB } from "app/redux/constant/ActionType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import InfoComponent from "app/shared/utility/InfoComponent";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
//component
import NMModuleV2 from "../container";
const qs = require("qs");

const mapStateToProps = (state) => {
  const data = { ...state.nm.nm };
  return {
    service_id: data.service_id,
    project_id: data.project_id,
    service_name: data.service_name,
    project_name: data.project_name,
    start_date: data.start_date,
    end_date: data.end_date,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTab: (tabName) => {
      dispatch({ type: CHANGE_TAB, payload: tabName });
    },
  };
};

class NmDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoading: true,
      currentPage: 1,
      currentPageProgress: 1,
      currentPageCompleted: 1,
      progress_list: [],
      completed_list: [],
      nmHeaderCount: [],
      project_id: [],
      tracker_list: [],
      nmCompletedl: [],
      nmPendingl: [],
      nmProgressl: [],
      progress_list2: [],
      completed_list2: [],
      nmCompleted2: [],
      nmPending2: [],
      nmProgress2: [],
      openListRefreshing: false,
      userFlag: "",
      totalCount: 0,
      perPage: 10,
      hasMore: false,
      moreDataLoading: false,
      trackLi: {},
      active: "dashboard",
      trackerexport: [],
      searchList: "",
      searchList2: "",
      searchList3: "",
      startDate: new Date(),
      endDate: new Date(),
      pageNumber: 1,
      overdue: 0,
      service: [],
      serviceSelect: "",
      project: [],
      projectSelect: "",
      R_person: [],
      extremeSelect: 0,
      extremeValue: 0,
      highSelect: 0,
      mediumSelect: 0,
      lowSelect: 0,
      all: 0,
      allValue: [],
      allS: [],
      severity: [],
      personSelect: [],
      CategoryFilter: [],
      isFirstFetching: false,
      capaInprogressList: [],
      capaCompletedList: [],
      capaOrignalInprogressList: [],
      capaOriganlCompletedList: [],
      capaCompletedCount: 0,
      capaCompletedCount: 0,
      totalCapaCount: 0,
      originFrom: this.props.match.params.type,
      nmPendingOrignalList: [],
      nmProgressOrignalList: [],
      nmCompletedOrignalList: [],
      nmPendingPageNo: 1,
      nmProgressFilterPageNo: 1,
      nmCompleteFilterPageNo: 1,
      nmPendingFilterPageNo: 1,
      pendingFilterCount: 0,
      inprogressFilterCount: 0,
      completeFilterCount: 0,
    };
    // this._getnmHeaderCount();

    this.child = React.createRef();
  }

  componentDidMount() {
    this._getnmHeaderCount();
    this._getNMList(1, "PROJECT");
    this._getNMProgressList(1, "PROJECT");
    this._getNMCompletedList(1, "PROJECT");
    // this.nmTrackerList(1, "ALL");
    this.nmInprogressTrackerList(this.state.pageNumber, "PROJECT");
    // this.nmTrackerCompletedList(1, "ALL");
    this._getTrackerListExport();
    this._getNMListExport();
    this.activeSP = this.activeSP.bind(this);
    // this._getNMProgressList(1);

    this._handleInputChange2 = async (event) => {
      const val = event.target.value;

      await this.setState((prevState) => {
        let { searchList2, searchBtnClicked2, progress_list, progress_list2 } =
          prevState;
        searchList2 = val;
        searchBtnClicked2 = true;
        progress_list = progress_list2.filter((data) => {
          if (data.category !== undefined) {
            if (
              data.category.toLowerCase().includes(searchList2.toLowerCase())
            ) {
              return data;
            } else if (
              data.activity.toLowerCase().includes(searchList2.toLowerCase())
            ) {
              return data;
            } else if (
              data.service.toLowerCase().includes(searchList2.toLowerCase())
            ) {
              return data;
            } else if (
              data.project.toLowerCase().includes(searchList2.toLowerCase())
            ) {
              return data;
            } else if (
              data.recommendedby
                .toLowerCase()
                .includes(searchList2.toLowerCase())
            ) {
              return data;
            } else if (
              data.type_of_Act.toLowerCase().includes(searchList2.toLowerCase())
            ) {
              return data;
            }
          }
        });
        return { searchList2, searchBtnClicked2, progress_list };
      });
      if (!val || val === "") {
        await this.nmTrackerList(1);
      }
    };
    this._handleInputChange3 = async (event) => {
      const val = event.target.value;

      if (!val || val === "") {
        await this.nmTrackerCompletedList(1);
      }
      await this.setState((prevState) => {
        let {
          searchList3,
          searchBtnClicked3,
          completed_list,
          completed_list2,
        } = prevState;
        searchList3 = val;
        searchBtnClicked3 = true;
        completed_list = completed_list2.filter((data) => {
          if (data.category !== undefined) {
            if (
              data.category.toLowerCase().includes(searchList3.toLowerCase())
            ) {
              return data;
            } else if (
              data.activity.toLowerCase().includes(searchList3.toLowerCase())
            ) {
              return data;
            } else if (
              data.service.toLowerCase().includes(searchList3.toLowerCase())
            ) {
              return data;
            } else if (
              data.project.toLowerCase().includes(searchList3.toLowerCase())
            ) {
              return data;
            } else if (
              data.recommendedby
                .toLowerCase()
                .includes(searchList3.toLowerCase())
            ) {
              return data;
            } else if (
              data.type_of_Act.toLowerCase().includes(searchList3.toLowerCase())
            ) {
              return data;
            }
          }
        });
        return { searchList3, searchBtnClicked3, completed_list };
      });
    };
    this.setState({ isFirstFetching: true });
  }

  _textIncludeCheck = (sourceText = "", searchText = "") => {
    return sourceText.toLowerCase().includes(searchText);
  };

  _handleInputChange = async (event) => {
    const val = event.target.value;

    if (val.trim() == "") {
      await this.setState({
        searchList: val,
        searchBtnClicked: true,
        capaInprogressList: this.state.capaOrignalInprogressList,
        capaCompletedList: this.state.capaOriganlCompletedList,
      });
    } else {
      await this.setState((prevState) => {
        let {
          searchList,
          searchBtnClicked,
          capaCompletedList,
          capaInprogressList,
          capaOriganlCompletedList,
          capaOrignalInprogressList,
        } = prevState;

        searchList = val;
        searchBtnClicked = true;
        let searchTerm = searchList.toLowerCase();

        capaInprogressList = capaOrignalInprogressList.filter((data) => {
          if (this._textIncludeCheck(data.activity, searchTerm)) {
            return data;
          } else if (this._textIncludeCheck(data.service, searchTerm)) {
            return data;
          } else if (this._textIncludeCheck(data.project, searchTerm)) {
            return data;
          } else if (
            data.list.some((item) =>
              this._textIncludeCheck(item.recommendedby, searchTerm)
            )
          ) {
            return data;
          } else if (
            data.list.some((item) =>
              this._textIncludeCheck(item.category, searchTerm)
            )
          ) {
            return data;
          } else if (
            data.list.some((item) =>
              this._textIncludeCheck(item.type_of_Act, searchTerm)
            )
          ) {
            return data;
          }
        });

        capaCompletedList = capaOriganlCompletedList.filter((data) => {
          if (this._textIncludeCheck(data.activity, searchTerm)) {
            return data;
          } else if (this._textIncludeCheck(data.service, searchTerm)) {
            return data;
          } else if (this._textIncludeCheck(data.project, searchTerm)) {
            return data;
          } else if (
            data.list.some((item) =>
              this._textIncludeCheck(item.recommendedby, searchTerm)
            )
          ) {
            return data;
          } else if (
            data.list.some((item) =>
              this._textIncludeCheck(item.category, searchTerm)
            )
          ) {
            return data;
          } else if (
            data.list.some((item) =>
              this._textIncludeCheck(item.type_of_Act, searchTerm)
            )
          ) {
            return data;
          }
        });

        return {
          searchList,
          searchBtnClicked,
          capaCompletedList,
          capaInprogressList,
        };
      });
    }
  };

  _getnmHeaderCount = async (type = "PROJECT") => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    let data;
    data = new FormData();
    let projectList = [];

    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }
    console.log(projectList);
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("start_date", this.props.start_date);
    data.append("end_date", this.props.end_date);
    let URL = Url.nmModule.nmHeaderCount;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
    // await axios
    //   .post(
    //     Url.nmModule.nmHeaderCount,
    //     qs.stringify({
    //       auth_key: token,
    //       user_id: userId,
    //       'project_id[]': projectList
    //     }),
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //     }
    //   )
      .then((response) => {
        if (response.status == 200) {
          this.setState({ nmHeaderCount: response.data.data });
        } else {
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.project_id !== prevState.project_id ||
      nextProps.service_id !== prevState.service_id ||
      nextProps.start_date != prevState.start_date ||
      nextProps.end_date != prevState.end_date
    ) {
      return {
        service_id: nextProps.service_id,
        project_id: nextProps.project_id,
        start_date: nextProps.start_date,
        end_date: nextProps.end_date,
      };
    }
    return null;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.service_id !== prevProps.service_id ||
      this.props.project_id !== prevProps.project_id ||
      this.props.start_date != prevProps.start_date ||
      this.props.end_date != prevProps.end_date
    ) {
      if (this.state.isFirstFetching) {
        this.setState({
          dataLoading: true,
          nmCompletedl: [],
          nmPendingl: [],
          nmProgressl: [],
          progress_list2: [],
          completed_list2: [],
          nmCompleted2: [],
          capaInprogressList:[],
          capaCompletedList:[],
          nmPending2: [],
          nmProgress2: [],
          progress_list: [],
          completed_list: [],
        }, async ()  => {
          await this._getnmHeaderCount();
          await this.nmInprogressTrackerList(1);
          await this.nmTrackerCompletedList(1);
          await this._getNMList(1);
          await this._getNMProgressList(1);
          await this._getNMCompletedList(1);
          await this._getTrackerListExport();
          await this._getNMListExport();
        });
        
      } else {
        await this.setState({ isFirstFetching: true });
      }
    }
  }

  _handleFilterReset = async (type = "pending") => {
    // await this.nmTrackerList(1, "ALL");
    if (type == "pending") {
      this._handlePendingFilterReset();
    } else if (type == "progress") {
      this._handleProgressFilterReset();
    } else if (type == "completed") {
      this._handleCompletedFilterReset();
    }
  };

  _handlePendingFilterReset = async () => {
    try {
      await this.setState((prevState) => {
        let { nmPendingl, nmPending2, nmPendingOrignalList } = prevState;
        nmPendingl = [...nmPendingOrignalList];
        nmPending2 = [...nmPendingOrignalList];

        return {
          nmPendingl,

          nmPending2,
        };
      });
      // await this._getNMList(1, "ALL");
    } catch (error) {}
  };

  _handleProgressFilterReset = async () => {
    try {
      await this.setState((prevState) => {
        let {
          nmProgressl,
          progress_list2,
          progress_list,
          nmProgress2,
          nmProgressOrignalList,
        } = prevState;

        nmProgress2 = [...nmProgressOrignalList];
        nmProgressl = [...nmProgressOrignalList];
        progress_list2 = [...nmProgressOrignalList];
        progress_list = [...nmProgressOrignalList];

        return {
          nmProgressl,
          progress_list,
          nmProgress2,
          progress_list2,
        };
      });
      // await this._getNMProgressList(1, "ALL");
    } catch (error) {}
  };

  _handleCompletedFilterReset = async () => {
    try {
      await this.setState((prevState) => {
        let {
          nmCompletedl,
          completed_list2,
          nmCompleted2,
          completed_list,
          nmCompletedOrignalList,
        } = prevState;

        nmCompleted2 = [...nmCompletedOrignalList];
        nmCompleted2 = [...nmCompletedOrignalList];
        nmCompletedl = [...nmCompletedOrignalList];
        completed_list2 = [...nmCompletedOrignalList];
        completed_list = [...nmCompletedOrignalList];

        return {
          nmCompletedl,
          completed_list2,
          nmCompleted2,
          completed_list,
        };
      });
      // await this.nmTrackerCompletedList(1, "ALL");
      // await this._getNMCompletedList(1, "ALL");
    } catch (error) {}
  };

  nmInprogressTrackerList = async (value, type = "PROJECT") => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data;
    data = new FormData();
    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("page_no", value);
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("start_date", this.props.start_date);
    data.append("end_date", this.props.end_date);
    let URL = Url.nmModule.nmTracker;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then(async (response) => {
        if (response.data.status === 200) {
          // tracker data
          const {
            completed_tracker_list,
            inprogress_tracker_list,
            completed_number,
            inprogress_number,
            totalInprogress_cardCounts,
            totalCompleted_cardCounts,
            total_cardCounts,
            total_number,
          } = response?.data?.data;

          await this.setState((prevState) => {
            let {
              capaInprogressList,
              capaOrignalInprogressList,
              // capaProgressCount,
              totalCapaCount,
            } = prevState;

            // capaInprogressList = inprogress_tracker_list;

            // capaOrignalInprogressList = inprogress_tracker_list;

            // capaProgressCount = inprogress_number;
            // capaProgressCount = this.state.capaProgressCount + inprogress_number;
            totalCapaCount = total_cardCounts;

            return {
              capaInprogressList,
              pageNumber: value,
              totalCapaCount,
              capaOrignalInprogressList,
            };
          });

          this.setState({
            nmtrackListRefreshing: false,
            nmtrackListLoading: false,
            totalCount: this.state.nmHeaderCount.tracker_number,
            lastPage: Math.ceil(this.state.nmHeaderCount.tracker_number / 10),
            hasMore:
              this.state.currentPageProgress <=
              Math.ceil(this.state.nmHeaderCount.tracker_number / 10),
            currentPageProgress: this.state.currentPageProgress + 1,
            moreDataLoading: false,
          });

          this.setState({ CategoryFilter: response.data.data.tracker_list });
          if (this.state.capaInprogressList.length == 0) {
            this.setState({
              // inprogress_number: response.data.data.inprogress_number,
              capaProgressCount: totalInprogress_cardCounts,
              capaCompletedCount: totalCompleted_cardCounts,
              totalCountracker: response.data.data.total_number,
            });
            this.setState({
              capaInprogressList: inprogress_tracker_list,
              capaOrignalInprogressList: inprogress_tracker_list,
              capaCompletedList: completed_tracker_list,
              capaOriganlCompletedList: completed_tracker_list,
            });
          } else {
            this.setState({
              capaProgressCount: totalInprogress_cardCounts,
              capaCompletedCount: totalCompleted_cardCounts,
              capaInprogressList: [
                ...this.state.capaInprogressList,
                ...inprogress_tracker_list,
              ],
              capaCompletedList: [
                ...this.state.capaCompletedList,
                ...completed_tracker_list,
              ],

              totalCapaCount:
                Number(this.state.capaProgressCount) +
                Number(this.state.capaCompletedCount),
            });
            this.setState({
              capaOrignalInprogressList: [
                ...this.state.capaOrignalInprogressList,
                ...inprogress_tracker_list,
              ],
              capaOriganlCompletedList: [
                ...this.state.capaOriganlCompletedList,
                ...completed_tracker_list,
              ],
            });
          }
          inprogress_tracker_list.map((data) =>
            this.setState({ R_person: data.tracking_responsible_person })
          );
        } else {
          this.setState({ dataLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  nmTrackerCompletedList = async (value, type = "PROJECT") => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("page_no", value);
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("start_date", this.props.start_date);
    data.append("end_date", this.props.end_date);
    let URL = Url.nmModule.nmTracker;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          const {
            completed_tracker_list,
            total_completedCapaCount,
            completed_number,
          } = response?.data?.data;

          this.setState({
            nmtrackListRefreshing: false,
            nmtrackListLoading: false,
            totalCount: this.state.nmHeaderCount.tracker_number,
            lastPage: Math.ceil(this.state.nmHeaderCount.tracker_number / 10),
            hasMore:
              this.state.currentPageProgress <=
              Math.ceil(this.state.nmHeaderCount.tracker_number / 10),
            currentPageProgress: this.state.currentPageProgress + 1,
            moreDataLoading: false,
          });

          if (this.state.capaCompletedList.length == 0) {
            this.setState({
              capaCompletedCount: total_completedCapaCount,
              capaCompletedList: [],
            });
            this.setState({
              capaCompletedList: completed_tracker_list,
              capaOriganlCompletedList: completed_tracker_list,
            });
          } else {
            this.setState({
              // capaCompletedCount: completed_number,

              capaCompletedCount: total_completedCapaCount,
              capaCompletedList: [
                ...this.state.capaCompletedList,
                ...completed_tracker_list,
              ],
              capaOriganlCompletedList: [
                ...this.state.capaOriganlCompletedList,
                ...completed_tracker_list,
              ],
              totalCapaCount:
                Number(this.state.capaProgressCount) +
                Number(this.state.capaCompletedCount),
            });
          }
        }

        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  nmTrackerListFilterProgress = async (
    severity,
    overdue,
    serviceSelect,
    projectSelect,
    categorySelect,
    startDate,
    endDate,
    personSelect,
    statusCheck
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    await axios
      .post(
        Url.nmModule.trackerfilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            page_no: this.state.currentPage,
            severity: severity,
            overdue: overdue,
            service_id: serviceSelect,
            project_id: projectSelect,
            category: categorySelect,
            start_date: new Date(startDate).toISOString().slice(0, 10),
            end_date: new Date(endDate).toISOString().slice(0, 10),
            responsible_person_id: personSelect,
            status: statusCheck,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            nmtrackListRefreshing: false,
            nmtrackListLoading: false,
            totalCount: this.state.nmHeaderCount.tracker_number,
            lastPage: Math.ceil(this.state.nmHeaderCount.tracker_number / 10),
            hasMore:
              this.state.currentPage <=
              Math.ceil(this.state.nmHeaderCount.tracker_number / 10),
            currentPage: this.state.currentPage + 1,
            moreDataLoading: false,
          });

          if (this.state.progress_list.length == 0) {
            // this.setState({inprogress_number: response.data.data.inprogress_number})
            this.setState({ progress_list: [] });
            this.setState({
              progress_list: response.data.data.tracker_list,
              dataLoading: false,
            });
          } else {
            // this.setState({inprogress_number: response.data.data.inprogress_number})
            this.setState({
              progress_list: [...response.data.data.tracker_list],
              dataLoading: false,
            });
          }
          this.child.current.resetStates();
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  nmTrackerListFilterCompleted = async (
    severity,
    overdue,
    serviceSelect,
    projectSelect,
    categorySelect,
    startDate,
    endDate,
    personSelect,
    statusCheck
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.nmModule.trackerfilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            page_no: this.state.currentPage,
            severity: severity,
            overdue: overdue,
            service_id: serviceSelect,
            project_id: projectSelect,
            category: categorySelect,
            start_date: new Date(startDate).toISOString().slice(0, 10),
            end_date: new Date(endDate).toISOString().slice(0, 10),
            responsible_person_id: personSelect,
            status: statusCheck,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            nmtrackListRefreshing: false,
            nmtrackListLoading: false,
            totalCount: this.state.nmHeaderCount.tracker_number,
            lastPage: Math.ceil(this.state.nmHeaderCount.tracker_number / 10),
            hasMore:
              this.state.currentPage <=
              Math.ceil(this.state.nmHeaderCount.tracker_number / 10),
            currentPage: this.state.currentPage + 1,
            moreDataLoading: false,
          });

          if (this.state.completed_list.length == 0) {
            // this.setState({completed_number: response.data.data.completed_number})
            this.setState({ completed_list: [] });
            this.setState({
              completed_list: response.data.data.tracker_list,
              dataLoading: false,
            });
          } else {
            // this.setState({completed_number: response.data.data.completed_number})
            this.setState({
              completed_list: [...response.data.data.tracker_list],
              dataLoading: false,
            });
          }
          this.child.current.resetStates();
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _getTrackerListExport = async (type = "PROJECT") => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    let data;
    data = new FormData();
    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }
    data.append("auth_key", token);
    data.append("user_id", userId);
    let URL = Url.nmModule.nmtrackerlistexport;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
    // await axios
    //   .post(
    //     Url.nmModule.nmtrackerlistexport,
    //     qs.stringify({
    //       auth_key: token,
    //       user_id: userId,
    //     }),
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //     }
    //   )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            trackerexport: response.data.data.tracker_list,
            dataLoading: false,
          });
        }
      })
      .catch((error) => {});
  };
  activeSP = (eventKey) => {
    this.setState({ active: eventKey });
  };
  // end tracker

  // Start NM List

  _getNMList = async (value, type = "PROJECT") => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }
    data.append("page_no", Math.ceil(value));
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("start_date", this.props.start_date);
    data.append("end_date", this.props.end_date);
    // data.append("page_no", this.state.nmPendingPageNo);

    let URL = Url.nmModule.nmList;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.nmPendingl.length == 0) {
            this.setState({
              total_pendingnm: response.data.data.total_pendingnm,
              nmPendingl: response.data.data.pendingnm.nm_basic_info,
              nmPending2: response.data.data.pendingnm.nm_basic_info,
              nmPendingOrignalList: response.data.data.pendingnm.nm_basic_info,
              nmPendingPageNo: this.state.nmPendingPageNo + 1,
            });
          } else {
            this.setState({
              nmPendingl: [
                ...this.state.nmPendingl,
                ...response.data.data.pendingnm.nm_basic_info,
              ],
              nmPendingPageNo: this.state.nmPendingPageNo + 1,
            });
            this.setState({
              nmPending2: [
                ...this.state.nmPending2,
                ...response.data.data.pendingnm.nm_basic_info,
              ],
            });
          }
        }
      })
      .catch((error) => {});
  };

  _getNMProgressList = async (value, type = "PROJECT") => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data;
    data = new FormData();

    // send project array in request only when project filter is applied
    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }
    data.append("page_no", Math.ceil(value));
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("start_date", this.props.start_date);
    data.append("end_date", this.props.end_date);
    let URL = Url.nmModule.nmList;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.nmProgressl.length == 0) {
            this.setState({
              total_inprogressnm: response.data.data.total_inprogressnm,
              nmProgressl: response.data.data.inprogressnm.nm_basic_info,
              nmProgress2: response.data.data.inprogressnm.nm_basic_info,
              nmProgressOrignalList:
                response.data.data.inprogressnm.nm_basic_info,
            });
          } else {
            this.setState({
              nmProgressl: [
                ...this.state.nmProgressl,
                ...response.data.data.inprogressnm.nm_basic_info,
              ],
            });
            this.setState({
              nmProgress2: [
                ...this.state.nmProgress2,
                ...response.data.data.inprogressnm.nm_basic_info,
              ],
            });
            this.setState({
              nmProgressOrignalList: [
                ...this.state.nmProgressOrignalList,
                ...response.data.data.inprogressnm.nm_basic_info,
              ],
            });
          }
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _getNMCompletedList = async (value, type = "PROJECT") => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (this.state.project_id && this.state.project_id.length > 0) {
        this.state.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }
    data.append("page_no", Math.ceil(value));
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("start_date", this.props.start_date);
    data.append("end_date", this.props.end_date);
    let URL = Url.nmModule.nmList;
    let headerType = "multipart/form-data";
    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.nmCompletedl.length == 0) {
            this.setState({
              total_completednm: response.data.data.total_completednm,
              nmCompletedl: response.data.data.completednm.nm_basic_info,

              nmCompleted2: response.data.data.completednm.nm_basic_info,
              nmCompletedOrignalList:
                response.data.data.completednm.nm_basic_info,
            });
          } else {
            this.setState({
              nmCompletedl: [
                ...this.state.nmCompletedl,
                ...response.data.data.completednm.nm_basic_info,
              ],
            });
            this.setState({
              nmCompleted2: [
                ...this.state.nmCompleted2,
                ...response.data.data.completednm.nm_basic_info,
              ],
            });
            this.setState({
              nmCompletedOrignalList: [
                ...this.state.nmCompletedOrignalList,
                ...response.data.data.completednm.nm_basic_info,
              ],
            });
          }
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _getNMListExport = async () => {
    this.setState({ moreDataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    let project_idString = "";
    if (this.state.project_id  && this.state.project_id.length > 0) {
      project_idString = this.state.project_id.join(",");
    }
    // if (type == "PROJECT") {
    //   if (this.state.project_id && this.state.project_id.length > 0) {
    //     this.state.project_id.forEach((item) => {
    //       data.append("project_id", item);
    //     });
    //   }
    // }
  //   data.append("auth_key", token);
  //   data.append("user_id", userId);
  //  // data.append("page_no", 1);
  //   let URL = Url.nmModule.nmlistexport;
  //   let headerType = "multipart/form-data";
  //   await axios
  //     .post(URL, data, {
  //       headers: {
  //         "Content-Type": headerType,
  //       },
  //     })
    await axios
      .post(
        Url.nmModule.nmlistexport,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          "project_id": project_idString
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            nmPendingexport: response.data.data.pendingnm.pendinglist,
            nmProgressexport: response.data.data.inprogressnm.inprogresslist,
            nmCompletedexport: response.data.data.completednm.completedlist,
            dataLoading: false,
          });
          // console.log('pending', this.state.nmPending)
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _handleSearch = async (event) => {
    const val = event.target.value;

    if (val.trim() == "") {
      await this.setState({
        searchList2: val,
        searchBtnClicked2: true,
        nmPendingl: this.state.nmPendingOrignalList,
        nmCompletedl: this.state.nmCompletedOrignalList,
        nmProgressl: this.state.nmProgressOrignalList,
      });
    } else {
      await this.setState((prevState) => {
        let {
          searchList2,
          searchBtnClicked2,
          nmPendingl,
          nmProgressl,
          nmCompletedl,
          nmPending2,
          nmProgress2,
          nmCompleted2,
        } = prevState;
        searchList2 = val;
        searchBtnClicked2 = true;
        let searchTerm = searchList2.toLowerCase();

        nmPendingl = nmPending2.filter((data) => {
          if (data.activity.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.type_of_act?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.service?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.project?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.reported_by?.toLowerCase().includes(searchTerm)) {
            return data;
          }
        });

        nmProgressl = nmProgress2.filter((data) => {
          if (data.activity.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.type_of_act?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.service?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.project?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.reported_by?.toLowerCase().includes(searchTerm)) {
            return data;
          }
        });

        nmCompletedl = nmCompleted2.filter((data) => {
          if (data.activity?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.type_of_act?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.service?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.project?.toLowerCase().includes(searchTerm)) {
            return data;
          } else if (data.reported_by?.toLowerCase().includes(searchTerm)) {
            return data;
          }
        });
        return {
          searchList2,
          searchBtnClicked2,
          nmPendingl,
          nmProgressl,
          nmCompletedl,
        };
      });
    }
  };

  nmPendingListFilter = async (
    severity,
    startDate,
    endDate,
    serviceSelect,
    projectSelect,
    pageNo
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    console.log("page No ===" + pageNo);

    if (pageNo) {
      await this.setState({
        nmPendingFilterPageNo: pageNo,
        nmPendingl: [],
        pendingFilterCount: 0,
      });
    }

    await axios
      .post(
        Url.nmModule.pendingfilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            // page_no: 1,
            // page_no: this.state.nmPendingFilterPageNo,
            // page_no: value ? value : 1,
            page_no: pageNo ? pageNo : this.state.nmPendingFilterPageNo,
            severity: severity,
            service_id: serviceSelect,
            project_id: projectSelect,
            start_date: startDate,
            end_date: endDate,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            nmtrackListRefreshing: false,
            nmtrackListLoading: false,
            totalCount: response.data.data.list_nm.length,
            lastPage: Math.ceil(response.data.data.list_nm.length / 10),
            hasMore:
              this.state.currentPage <=
              Math.ceil(response.data.data.list_nm.length / 10),
            currentPage: this.state.currentPage + 1,
            moreDataLoading: false,
            nmPendingFilterPageNo: this.state.nmPendingFilterPageNo + 1,
          });

          // this.setState({ nmPendingl: [] });
          this.setState({
            // nmPendingl: response.data.data.list_nm,
            nmPendingl:
              this.state.nmPendingFilterPageNo > 1
                ? [...this.state.nmPendingl, ...response.data.data.list_nm]
                : response.data.data.list_nm,
            pendingFilterCount:
              this.state.pendingFilterCount + response.data.count,
            dataLoading: false,
          });
          // this.child.current.resetState();
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  nmProgressListFilter = async (
    severity,
    startDate,
    endDate,
    serviceSelect,
    projectSelect,
    overdue,
    pageNo
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    if (pageNo) {
      await this.setState({
        nmProgressFilterPageNo: pageNo,
        nmProgressl: [],
        inprogressFilterCount: 0,
      });
    }

    await axios
      .post(
        Url.nmModule.inprogressfilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            // page_no: 1,
            page_no: pageNo ? pageNo : this.state.nmProgressFilterPageNo,
            severity: severity,
            service_id: serviceSelect,
            project_id: projectSelect,
            start_date: startDate,
            end_date: endDate,
            overdue,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            lastPage: Math.ceil(response.data.data.list_nm.length / 10),
            hasMore:
              this.state.currentPage <=
              Math.ceil(response.data.data.list_nm.length / 10),
            currentPage: this.state.currentPage + 1,
            moreDataLoading: false,
            nmProgressFilterPageNo: this.state.nmProgressFilterPageNo + 1,
          });

          // this.setState({ nmProgressl: [] });
          this.setState({
            // nmProgressl: response.data.data.list_nm,
            nmProgressl:
              this.state.nmProgressFilterPageNo > 1
                ? [...this.state.nmProgressl, ...response.data.data.list_nm]
                : response.data.data.list_nm,
            inprogressFilterCount:
              this.state.inprogressFilterCount + response.data.count,
            dataLoading: false,
          });

          // this.child.current.resetState();
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  nmCompletedListFilter = async (
    severity,
    startDate,
    endDate,
    serviceSelect,
    projectSelect,
    overdue,
    pageNo
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    if (pageNo) {
      await this.setState({
        nmCompleteFilterPageNo: pageNo,
        nmCompletedl: [],
        completeFilterCount: 0,
      });
    }

    await axios
      .post(
        Url.nmModule.completedfilter,
        qs.stringify(
          {
            auth_key: token,
            user_id: userId,
            // page_no: 1,
            page_no: pageNo ? pageNo : this.state.nmCompleteFilterPageNo,
            severity: severity,
            service_id: serviceSelect,
            project_id: projectSelect,
            start_date: startDate,
            end_date: endDate,
            overdue,
          },
          { arrayFormat: "repeat" }
        ),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            lastPage: Math.ceil(response.data.data.list_nm.length / 10),
            hasMore:
              this.state.currentPage <=
              Math.ceil(response.data.data.list_nm.length / 10),
            currentPage: this.state.currentPage + 1,
            moreDataLoading: false,
            nmCompleteFilterPageNo: this.state.nmCompleteFilterPageNo + 1,
          });

          // this.setState({ nmCompletedl: [] });
          this.setState({
            // nmCompletedl: response.data.data.list_nm,
            nmCompletedl:
              this.state.nmCompleteFilterPageNo > 1
                ? [...this.state.nmCompletedl, ...response.data.data.list_nm]
                : response.data.data.list_nm,
            completeFilterCount:
              this.state.completeFilterCount + response.data.count,
            dataLoading: false,
          });

          // this.child.current.resetState();
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _handlePendingSearch = async (event) => {
    const val = event.target.value;

    if (val.trim() === "") {
      await this.setState({
        searchList4: val,
        searchBtnClicked: true,
        nmPending2: this.state.nmPendingOrignalList,
      });
    } else {
      await this.setState((prevState) => {
        let { searchList4, searchBtnClicked, nmPendingl, nmPending2 } =
          prevState;
        searchList4 = val;
        searchBtnClicked = true;

        let searchTerm = searchList4.toLowerCase();

        nmPendingl = nmPending2.filter((data) => {
          if (data.activity !== undefined) {
            if (data.activity.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.type_of_act.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.service.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.project.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.reported_by.toLowerCase().includes(searchTerm)) {
              return data;
            }
          }
        });
        return { searchList4, searchBtnClicked, nmPendingl };
      });
    }
  };

  _handleProgressSearch = async (event) => {
    const val = event.target.value;

    if (val.trim() === "") {
      await this.setState({
        nmProgressl: this.state.nmProgressOrignalList,
        searchList5: val,
        searchBtnClicked2: true,
      });
    } else {
      await this.setState((prevState) => {
        let { nmProgressl, nmProgress2, searchList5, searchBtnClicked2 } =
          prevState;
        searchList5 = val;
        searchBtnClicked2 = true;
        let searchTerm = searchList5.toLowerCase();

        nmProgressl = nmProgress2.filter((data) => {
          if (data.activity !== undefined) {
            if (data.activity.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.type_of_act.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.service.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.project.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.reported_by.toLowerCase().includes(searchTerm)) {
              return data;
            }
          }
        });
        return { searchList5, searchBtnClicked2, nmProgressl };
      });
    }
  };

  _handleCompletedSearch = async (event) => {
    const val = event.target.value;

    if (val.trim() === "") {
      await this.setState({
        nmCompletedl: this.state.nmCompletedOrignalList,
        searchList6: val,
        searchBtnClicked2: true,
      });
    } else {
      await this.setState((prevState) => {
        let { nmCompletedl, nmCompleted2, searchList6, searchBtnClicked2 } =
          prevState;
        searchList6 = val;
        searchBtnClicked2 = true;
        let searchTerm = searchList6.toLowerCase();
        nmCompletedl = nmCompleted2.filter((data) => {
          if (data.activity !== undefined) {
            if (data.activity.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.type_of_act.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.service.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.project.toLowerCase().includes(searchTerm)) {
              return data;
            } else if (data.reported_by.toLowerCase().includes(searchTerm)) {
              return data;
            }
          }
        });
        return { searchList6, searchBtnClicked2, nmCompletedl };
      });
    }
  };

  _getDefaultActiveTabKey = () => {
    let defaultTab = "dashboard";

    if (this.props.match.params.type === "tracker") {
      defaultTab = "tracker";
    } else if (this.props.match.params.type === "kanban") {
      defaultTab = "nmlist";
    }
    return defaultTab;
  };

  render() {
    console.log("state data",this.state)
    const completedTrackerExport = this.state.trackerexport.filter(
      (item) => item.status == "Completed"
    );
    const progressTrackerExport = this.state.trackerexport.filter(
      (item) => item.status == "Inprogress"
    );
    const progress =
      this.state.inprogress_number != undefined && this.state.inprogress_number;
    const completed =
      this.state.completed_number != undefined && this.state.completed_number;
    const nmPending =
      this.state.total_pendingnm != undefined && this.state.total_pendingnm;
    const nmProgress =
      this.state.total_inprogressnm != undefined &&
      this.state.total_inprogressnm;
    const nmCompleted =
      this.state.total_completednm != undefined && this.state.total_completednm;
    const totalCountracker =
      this.state.totalCountracker != undefined
        ? this.state.totalCountracker
        : 0;
    return (
      <div className="ua-uc-dashboard">
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
        <div className="ua-uc-section">
          <Tabs
            defaultActiveKey={this._getDefaultActiveTabKey()}
            id="uncontrolled-tab-example"
            onSelect={this.activeSP}
            mountOnEnter={true}
          >
            <Tab eventKey="dashboard" title="Dashboard">
              <Dashboard nmData={this.state.dashboardData} />
            </Tab>
            <Tab
              eventKey="tracker"
              title={"CAPA | " + this.state.totalCapaCount}
            >
              <CapaList
                progress_list={this.state.capaInprogressList}
                completed_list={this.state.capaCompletedList}
                progressListCount={this.state.capaProgressCount}
                completedListCount={this.state.capaCompletedCount}
                nmTrackerList={this.nmInprogressTrackerList}
                nmTrackerCompletedList={this.nmTrackerCompletedList}
                state={this.state}
                handleInputChange={this._handleInputChange}
                progressTrackerExport={progressTrackerExport}
                pageNumber={this.state.pageNumber}
                completedTrackerExport={completedTrackerExport}
                refetch={() => {
                  window.location.reload();
                }}
              />
            </Tab>
            <Tab
              eventKey="nmlist"
              title={"NM List | " + (nmCompleted + nmProgress + nmPending)}
            >
              <NmList
                _getNMList={this._getNMList}
                _getNMProgressList={this._getNMProgressList}
                _getNMCompletedList={this._getNMCompletedList}
                nmPending={this.state.nmPendingl}
                nmCompleted={this.state.nmCompletedl}
                nmProgress={this.state.nmProgressl}
                ref={this.child}
                nmCompletedListFilter={this.nmCompletedListFilter}
                searchList4={this.state.searchList4}
                searchList5={this.state.searchList5}
                searchList6={this.state.searchList6}
                filterReset={this._handleFilterReset}
                nmPendingCount={nmPending}
                nmProgressCount={nmProgress}
                nmCompletedCount={nmCompleted}
                nmPendingListFilter={this.nmPendingListFilter}
                nmProgressListFilter={this.nmProgressListFilter}
                _handlePendingSearch={this._handlePendingSearch}
                _handleProgressSearch={this._handleProgressSearch}
                _handleCompletedSearch={this._handleCompletedSearch}
                pendingFilterCount={this.state.pendingFilterCount}
                inprogressFilterCount={this.state.inprogressFilterCount}
                completeFilterCount={this.state.completeFilterCount}
                state={this.state}
                handleInputChange={this._handleSearch}
              />
            </Tab>
          </Tabs>
          <div className="top-right published-right-btn">
            
            <InfoComponent
              tooltipText={
                this.state.active == "dashboard"
                  ? "Data analysis on various parameter for decision making"
                  : "Download Excel"
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NmDashboard)
);
