import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import "react-awesome-lightbox/build/style.css";
import axios from "axios";
import { Trash } from "react-feather";
import Url from "app/shared/constants/Url";
import CloseModal from "../../CloseModal";
import { DeletePopup } from "app/shared/components/Popups";
import InactivePopup from "../../InactivePopup";
import ActivatePopup from "../../ActivatePopup";
import LoadingView from "app/shared/components/Loader/LoadingView";
import { RiDeleteBin2Line } from "react-icons/ri";
import CompletePopup from "../../CompletePopup";
import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const qs = require("qs");
const mapStateToProps = (state) => {
  const data = { ...state.goal.goal };

  return {
    service_id: data.service_id,
    project_id: data.project_id,
    service_name: data.service_name,
    project_name: data.project_name,
    start_date: data.start_date,
    end_date: data.end_date,
  };
};
class GoalTableList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoading: true,
      goalOwnerTypeList: [
        { type_id: 1, owner_type: "Corporate", owner_type_color: "#E3ABD8" },
        { type_id: 2, owner_type: "Individual", owner_type_color: "#78CF63" },
        {
          type_id: 3,
          owner_type: "Service/project",
          owner_type_color: "#DBA199",
        },
        { type_id: 4, owner_type: "All", owner_type_color: "#CF8B63" },
        { type_id: 5, owner_type: "Self", owner_type_color: "#63CFCC" },
      ],
      showGoalDeletePopup: false,
      deleteId: null,
      showInactivePopup: false,
      closeData: {},
      isFirstFetching: false,
      project_id: [],
      closeModal: false,
      goalId: null,
      goalList: [],
      showCompletePopup: false,
      tabName: ["Inprogres", "Completed", "Inactivated", "Deleted"],
      pageNumber: 1,

    };
  }

  componentDidMount = async () => {
    // if (this.props.type == 0 || this.props.type == 1 || this.props.type == 2) {
    //   this.goalList();
    // } else {
    //   this.inactiveDeletedGoalList();
    // }

    this.handleGoalPaginationOnScroll();
  };
  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.service_id !== prevProps.service_id ||
      this.props.project_id !== prevProps.project_id ||
      this.props.start_date != prevProps.start_date ||
      this.props.end_date != prevProps.end_date
    ) {
      if (this.state.isFirstFetching) {
        this.setState({
          dataLoading: true,
         
        }, async ()  => {
          await this.goalList();
          await this.inactiveDeletedGoalList()
         
        });
        
      } else {
        await this.setState({ isFirstFetching: true });
      }
    }
  }
  handleGoalPaginationOnScroll = () => {
    if (this.props.type == 0 || this.props.type == 1 || this.props.type == 2) {
      this.goalList();
    } else {
      this.inactiveDeletedGoalList();
    }
    this.setState({ isFirstFetching: true });
  }

  _handleCloseModal = async () => {
    this.setState({
      closeModal: false,
      closeData: {},
    });
    // window.location.reload();
  };

  // async goalList() {

  goalList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    
      let project_idString = "";
    if (this.props.project_id  && this.props.project_id.length > 0) {
      project_idString = this.props.project_id.join(",");
    }
    await axios
      .post(
        Url.goal.goalList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          type: this.props.type,
          page_no: this.state.pageNumber,
          "project_id": project_idString,
          start_date: this.props.start_date,
          end_date: this.props.end_date,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({

            // goalList: response.data.data.goal_list,
            goalList: (this.state.pageNumber > 1) ? [...this.state.goalList, ...response.data.data.goal_list] : response.data.data.goal_list,
            pageNumber: this.state.pageNumber + 1,

          });
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  }

  async inactiveDeletedGoalList() {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    let project_idString = "";
    if (this.props.project_id  && this.props.project_id.length > 0) {
      project_idString = this.props.project_id.join(",");
    }
    await axios
      .post(
        Url.goal.inactiveDeletedGoalList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          type: this.props.type == 4 ? 1 : 2,
          page_no: this.state.pageNumber,
          "project_id": project_idString,
          start_date: this.props.start_date,
          end_date: this.props.end_date,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            // goalList: response.data.data.goal_list 

            goalList: (this.state.pageNumber > 1) ? [...this.state.goalList, ...response.data.data.goal_list] : response.data.data.goal_list,
            pageNumber: this.state.pageNumber + 1,
          });
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  }

  _goalDelete = async () => {
    this.setState({ dataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.goal.goalDelete,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          goal_id: this.state.deleteId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          window.location.reload();
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _changeGoalStatus = async (id, status, type = 1) => {
    this.setState({ dataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.goal.changeStatus,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          status: status,
          goal_id: id,
          type: type,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          window.location.reload();
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  cancelGoalDeletion = async () => {
    await this.setState({
      showGoalDeletePopup: false,
      deleteId: null,
    });
  };

  _goalClose = async (id, status, type = 1) => {
    await this.setState({
      closeData: {
        goalId: id,
        status: status,
        type: type,
      },
      closeModal: true,
    });
  };

  render() {
    return (
      <div className="open-section">
        <LoadingView showLoading={this.state.dataLoading} />
        <div className="published-section published-table">
          <InfiniteScroll
            dataLength={this.state.goalList.length}
            // next={this.goalList}
            next={this.handleGoalPaginationOnScroll}
            hasMore={this.state.goalList.length !== this.props.goalCount}
          >
            <Table className="data-table-wrapper">
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  <th >Goal</th>
                  <th>Owner</th>
                  <th>Module</th>
                  {/*<th>Progressive Metric</th>*/}

                  <th>Created By</th>
                  <th>Assigned To</th>

                  {this.props.type == 0 && (
                    <th>Created on</th>
                  )}

                  {this.props.type == 1 && (
                    <th>Due date</th>
                  )}
                  {this.props.type == 2 && (
                    <th>Closed on</th>
                  )}

                  {this.props.type == 3 && (
                    <th>Inactivated on</th>
                  )}

                  {this.props.type == 4 && (
                    <th>Deleted on</th>
                  )}

                  {/* {(this.props.type == 1 || 2) && (
                  <>
                    <th>Open Task</th>
                    <th>Close Task</th>
                  </>
                )} */}

                  {this.props.type != 0 && this.props.type != 4 && <th style={{ textAlign: "center" }}>Open Task</th>}
                  {this.props.type != 0 && this.props.type != 4 && <th style={{ textAlign: "center" }}>Close Task</th>}

                  {this.props.type == 0 && <th style={{ textAlign: "center" }}>Task</th>}
                  {this.props.type == 4 && <th style={{ textAlign: "center" }}>Task</th>}

                  {this.props.type == 0 && (
                    <th className="last-th" style={{ width: "5vw" }}>
                      Action
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {this.state.goalList &&
                  (this.state.goalList?.length === 0 ||
                    this.state.goalList === undefined) ? (
                  <tr>
                    <td colSpan="6">
                      <p className="list-error">Data not available</p>
                    </td>
                  </tr>
                ) : (
                  this.state.goalList &&
                  this.state.goalList?.map((data, index) => (
                    <tr
                      key={`${index}-${data.reporting_form_id}`}
                      style={{ borderBottom: "5px solid #f5f5f5" }}
                    >
                      <td >
                        <Link to={`/${this.props.parent}/${data.goal_id}`}>
                          {data.goal}
                        </Link>
                      </td>
                      <td

                      >
                        <span
                          className="px-3 py-2 rounded-pill font-16 common-white-1 font-weight-500"
                          style={{
                            backgroundColor: this.state.goalOwnerTypeList.filter(
                              (owner_type) => owner_type.type_id == data.owner_type
                            )[0]?.owner_type_color,
                          }}>
                          {
                            this.state.goalOwnerTypeList[data.owner_type - 1]
                              .owner_type
                          }
                        </span>
                      </td>
                      <td ><span className="b-tomato rounded px-2 py-1">{data.module_name}</span></td>
                      {/*<td>{data.progressive_metric}</td>*/}
                      <td>
                        <>
                          {" "}
                          <img className="user-icon" src={data.user_pic} />
                          {data.user_name}
                        </>
                      </td>
                      <td>
                        {(data.owner_type == 2 || data.owner_type == 5) &&
                          data.responsible_person_name}
                        {data.owner_type == 4 && "All"}
                        {data.owner_type == 1 && "Business Goal"}
                        {data.owner_type == 3 && "Plant Goal"}
                      </td>
                      {/* {(this.props.type == 1 || 2) && (
                      <>
                        <td style={{ textAlign: "center" }}>
                          <div className="circle-count open-task">
                            {data.open_task_count}
                          </div>{" "}
                        </td>
                        <td>
                          <div className="circle-count close-task">
                            {data.completed_task_count}
                          </div>
                        </td>
                      </>
                    )} */}

                      {this.props.type == 0 && (
                        <td>{data.creation_time}</td>
                      )}

                      {this.props.type == 1 && (
                        <td>{data.due_date}</td>
                      )}

                      {this.props.type == 2 && (
                        <td>{data.closing_date}</td>
                      )}

                      {this.props.type == 3 && (
                        <td>{data.inactive_date}</td>
                      )}

                      {this.props.type == 4 && (
                        <td>{data.delete_date}</td>
                      )}

                      {(this.props.type != 0) && (this.props.type != 4) && (
                        <>
                          <td style={{ textAlign: "center" }}>
                            <div className="circle-count open-task">
                              {data.open_task_count}
                            </div>{" "}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="circle-count close-task">
                              {data.completed_task_count}
                            </div>
                          </td>
                        </>
                      )}

                      {this.props.type == 0 && (
                        <td style={{ textAlign: "center" }}>
                          {this.state.goalList &&
                            this.state.goalList
                              .filter((e) => e.goal_id === data.goal_id)
                              .map((data) => data.count.total)}
                        </td>
                      )}


                      {this.props.type == 4 && (
                        <td style={{ textAlign: "center" }}>{data.completed_task_count}</td>
                      )}


                      {(this.props.type == 0 || this.props.type == 1) && (
                        <td className="last-td" style={{ display: "flex" }}>
                          {data.is_active != 1 && this.props.type == 3 && (
                            <Button
                              className="icon-btn"
                              style={{ color: "green", fontSize: "1vw" }}
                              onClick={async (e) =>
                                await this.setState({
                                  showActivatePopup: true,
                                  goalId: data.goal_id,
                                })
                              }
                            >
                              Activate
                            </Button>
                          )}
                          {this.props.type == 0 && data.delete_right == 1 && (
                            <Button
                              className="icon-btn"
                              title="Delete Goal"
                              onClick={async (e) =>
                                await this.setState({
                                  showGoalDeletePopup: true,
                                  deleteId: data.goal_id,
                                })
                              }
                            >
                            <img src={require("assets/sls-icons/V2. New Development/delete.svg")} className="svg-icons"  />
                            </Button>
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
        {!!this.state.closeModal && (
          <CloseModal
            handleClose={this._handleCloseModal}
            data={this.state.closeData}
          />
        )}
        <DeletePopup
          showDeletePopup={this.state.showGoalDeletePopup}
          onCancel={() => this.cancelGoalDeletion()}
          onConfirm={() => this._goalDelete()}
        />
        <InactivePopup
          showInactivePopup={this.state.showInactivePopup}
          onCancel={() =>
            this.setState({ showInactivePopup: !this.state.showInactivePopup })
          }
          onConfirm={() => this._changeGoalStatus(this.state.goalId, 2, 2)}
        />
        <ActivatePopup
          showActivatePopup={this.state.showActivatePopup}
          onCancel={() =>
            this.setState({ showActivatePopup: !this.state.showActivatePopup })
          }
          onConfirm={() => this._changeGoalStatus(this.state.goalId, 1, 2)}
        />
        <CompletePopup
          showCompletePopup={this.state.showCompletePopup}
          onCancel={() =>
            this.setState({ showCompletePopup: !this.state.showCompletePopup })
          }
          onConfirm={() => this._changeGoalStatus(this.state.goalId, 2, 2)}
        />
      </div>
    );
  }
}
export default withRouter(
  connect(mapStateToProps)(GoalTableList)
);
//export default GoalTableList;
