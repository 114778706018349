import React from "react";
import "./loaderStyles.scss";
const ClideLoader = () => {
  return (
    <div className="w-100">
      <span class="clide-v2-loader"></span>
    </div>
  );
};

export default ClideLoader;
