import React, { Component } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import Url from "app/shared/constants/Url";
import { connect } from "react-redux";
import MyDropzone from "../DocumentModal/Dropzone";
import axios from "axios";
import { SuccessToast,ErrorToast } from "app/shared/components/Popups/ToastMessage";
import { requiredMessage } from "app/shared/constants/Messages";
import { Button as SLDButton } from "@salesforce/design-system-react";
import {
  DOS_AND_DONTS,
  E_LEARN,
  STANDARD,
  POLICY_MANAGEMENT,
  MSDS,
} from "app/shared/constants/ModuleNames";
import { checkPermittedRoute } from "app/shared/utility/UtilFunction";

const mapStateToProps = (state) => {
  const data = [...state.permitModule.permittedModuleList];
  return {
    permittedModuleList: data,
  };
};

class FormImportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      selectedType: null,
      typeList: [
        { type_id: 1, document: "Do's & Dont's", module: DOS_AND_DONTS },
        { type_id: 2, document: "E-Learn", module: E_LEARN },
        { type_id: 3, document: "Policy", module: POLICY_MANAGEMENT },
        { type_id: 4, document: "MSDS", module: MSDS },
        { type_id: 7, document: "Standard", module: STANDARD },
      ],
      documentName: "",
      selectedFile: "",
      selectedFileError: false,
      isUploadApiError: false,
      uploadErrorMessage: false,
    };
  }

  componentDidMount = async () => {
    this.permitedModules();
  };

  permitedModules = async () => {
    let temp = this.state.typeList.filter((data, index) =>
      this._handlePermittedRoute(data.module)
    );
    this.setState({ typeList: temp });
  };

  _handlePermittedRoute = (checkRoute) =>
    checkPermittedRoute(this.props.permittedModuleList, checkRoute);

  _uploadDocument = async () => {
    // await this._formValidation();

    // If form not have error then submit form

    if (_.isEmpty(this.state.selectedFile)) {
      this.setState({
        selectedFileError: true,
      });
      return;
    } else {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      //await this.setState({ dataLoading: true });

      this._setDataLoading(true);

      let uploadData = new FormData();
      uploadData.append("auth_key", token);
      uploadData.append("user_id", userId);
      uploadData.append("excelFile", this.state.selectedFile || "");

      var ContentType = {
        "Content-Type": "multipart/form-data",
      };

      let URL = Url.formType1.createbasicformfromexcel;

      await axios
        .post(URL, uploadData, ContentType)
        .then(async (response) => {
          if (response.data?.status === 200) {
            
            SuccessToast("Template imported succesfully Successfully!");
            this.props.handleClose();
          } else {
            await this.setState((prevState) => {
              let { isUploadApiError, uploadErrorMessage } = prevState;
              isUploadApiError = true;
              uploadErrorMessage = response.data?.msg;
              return {
                isUploadApiError,
                uploadErrorMessage,
              };
            });
          }
        })
        .catch((error) => {
          ErrorToast("Template can not be import. Try Again!");
        })
        .finally(() => {
          this._setDataLoading(false);
        })

    }
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _uploadFile = async (file) => {
    console.log(file);
    await this.setState((prevState) => {
      let {
        selectedFile,
        selectedFileError,
        isUploadApiError,
        uploadErrorMessage,
      } = prevState;
      selectedFile = file;
      selectedFileError = false;
      isUploadApiError = false;
      uploadErrorMessage = "";
      return {
        selectedFile,
        selectedFileError,
        isUploadApiError,
        uploadErrorMessage,
      };
    });
  };
  debounceFunc = _.debounce(function (callback) {
    callback();
  }, 300);

  render() {
    return (
      <div>
        <Modal
          show={true}
          onHide={this.props.handleClose}
          className="documentPopup"
          backdrop="static"
          keyboard={false}
          // style={{ width: "20vw" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Excel Template Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className={`slds-form-element my-3 ${
                this.state.selectedFileError | this.state.isUploadApiError ? "slds-has-error" : ""
              }`}
            >
              <MyDropzone type={11} uploadFile={this._uploadFile} />
              {this.state.selectedFileError | this.state.isUploadApiError ? (
                <div class="slds-form-element__help " id="error-01">
                  {this.state.isUploadApiError
                    ? this.state.uploadErrorMessage
                    : requiredMessage}
                </div>
              ) : null}
            </div>

            <div className="publish-btn" style={{ marginTop: "1vw" }}>
              <SLDButton
                disabled={this.state.dataLoading}
                label={this.state.dataLoading ? "Uploading..." : "Upload"}
                onClick={() =>
                  this.debounceFunc(() => {
                    this._uploadDocument();
                  })
                }
                variant="brand"
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(FormImportModal);
