import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import { useParams } from "react-router-dom";
import axios from "axios";
import Url from "app/shared/constants/Url";

import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import PageFive from "./PageFive";
import PageSix from "./PageSix";
import PageSeven from "./PageSeven";
import PageEight from "./PageEight";
import PageNine from "./PageNine";
import PageTen from "./PageTen";
import PhysicalInfoSketch from "./PhysicalInfoSketch";
import JobAnalysisQuestion from "./JobAnalysisQuestion";
import RootCauseAnalysis from "./RootCauseAnalysis";
import ProbableRootCause from "./ProbableRootCause";
import RootCauseType from "./RootCauseType";
import CorrectiveActionPlan from "./CorrectiveActionPlan";

const IRAReport = () => {
  const { id } = useParams();
  const [iraDetails, setIRADetails] = useState({});
  const [companyLogo, setCompanyLogo] = useState("");
  const [renderHtml, setRenderHtml] = useState("");

  useEffect(() => {
    _fetchDetails();
    _getCompanyLogo();
  }, []);

  const _getCompanyLogo = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let URL = Url.adminProfile.companyProfileDetail;

      let responseObj = utilFunction.sharedAxiosAPI(URL);

      responseObj
        .then(async (response) => {
          setCompanyLogo(response.data?.data?.logo || "");
        })
        .catch(async (error) => console.log(error));
    });
  };

  const _fetchDetails = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let URL = Url.reporting.ira.viewreport;
      const userData = JSON.parse(localStorage.getItem("userData"));

      let requestData = {
        ira_id: id,
        sub_domain: userData.sub_domain,
      };
      let responseObj = utilFunction.sharedAxiosAPI(
        URL,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          setIRADetails(response.data?.data);
        })
        .catch(async (error) => console.log(error));
    });
  };

  useEffect(() => {
    _createRenderHtml();
  }, [iraDetails]);

  const _createRenderHtml = () => {
    if (iraDetails) {
      let pageOne = PageOne(iraDetails, companyLogo);
      let pageThree = PageThree(iraDetails, companyLogo);
      let pageFour = PageFour(iraDetails);
      let pageFive = PageFive(iraDetails);
      let pageSix = PageSix(iraDetails);
      let pageEight = PageEight(iraDetails);
      let pageNine = PageNine(iraDetails);
      let pageTen = PageTen(iraDetails);
      let physicalInfoSketch = PhysicalInfoSketch(iraDetails);
      let jobAnalysisQuestion = JobAnalysisQuestion(iraDetails);
      let rootCauseAnalysis = RootCauseAnalysis(iraDetails);
      let probableRootCause = ProbableRootCause(iraDetails);
      let rootCauseType = RootCauseType(iraDetails);
      let correctiveActionPlan = CorrectiveActionPlan(iraDetails);

      const rawHtml = `<!DOCTYPE html>
      <html>
      <head>
      <title></title>
      <style>
          body {
            counter-set: page;
          }
          span.page-number:after {
            counter-increment: page;
            content: "Page " counter(page);
            position : absolute;
            left : 50%;
            bottom : 5px;
            font-size: 14px;
          }
          @media print {
            .full-table {
              width : 100%;
            }
            .inner-table {
              padding : 5%
            }
          }
          </style>
      </head>
      <body>
	${pageOne}
  <div style="page-break-before: always;"> </div>
	${PageTwo}
  <div style="page-break-before: always;"> </div>
	${pageThree}
  <div style="page-break-before: always;"> </div>
	${pageFour}
  <div style="page-break-before: always;"> </div>
	${pageFive}
  <div style="page-break-before: always;"> </div>
	${pageSix}
  <div style="page-break-before: always;"> </div>
	${pageEight}
  <div style="page-break-before: always;"> </div>
	${pageNine}
  <div style="page-break-before: always;"> </div>
	${pageTen}
  <div style="page-break-before: always;"> </div>
  ${physicalInfoSketch}
  <div style="page-break-before: always;"> </div>
  ${jobAnalysisQuestion}
  <div style="page-break-before: always;"> </div>
  ${rootCauseAnalysis}
  <div style="page-break-before: always;"> </div>
  ${probableRootCause}
  <div style="page-break-before: always;"> </div>
  ${rootCauseType}
  <div style="page-break-before: always;"> </div>
  ${correctiveActionPlan}
</body>
<script src="https://code.highcharts.com/highcharts.js"></script>
<script src="https://code.highcharts.com/modules/exporting.js"></script>
<script src="https://code.highcharts.com/modules/export-data.js"></script>
<script src="https://code.highcharts.com/modules/accessibility.js"></script>
</html>`;
      setRenderHtml(rawHtml);
    }
  };
  return <>{parse(renderHtml)}</>;
};

export default IRAReport;
