import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import { useParams } from "react-router-dom";
import axios from "axios";
import Url from "app/shared/constants/Url";

import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import PageFive from "./PageFive";
import PageEight from "./PageEight";
import CorrectiveActionPlan from "./CorrectiveActionPlan";
import ProbableRootCause from "./ProbableRootCause";

const NMReport = () => {
  const { id } = useParams();
  const [nmDetails, setNMDetails] = useState({});
  const [companyLogo, setCompanyLogo] = useState("");
  const [renderHtml, setRenderHtml] = useState("");

  useEffect(() => {
    _fetchDetails();
    _getCompanyLogo();
  }, []);

  const _getCompanyLogo = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let URL = Url.adminProfile.companyProfileDetail;

      let responseObj = utilFunction.sharedAxiosAPI(URL);

      responseObj
        .then(async (response) => {
          setCompanyLogo(response.data?.data?.logo || "");
        })
        .catch(async (error) => console.log(error));
    });
  };

  const _fetchDetails = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let URL = Url.reporting.nm.viewreport;
      let requestData = {
        nearmiss_id: id,
      };
      let responseObj = utilFunction.sharedAxiosAPI(
        URL,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          setNMDetails(response.data?.data);
        })
        .catch(async (error) => console.log(error));
    });
  };

  useEffect(() => {
    _createRenderHtml();
  }, [nmDetails]);

  const _createRenderHtml = () => {
    let pageOne = PageOne(nmDetails, companyLogo);
    let pageThree = PageThree(nmDetails, companyLogo);
    let pageFour = PageFour(nmDetails);
    let pageFive = PageFive(nmDetails);
    let pageEight = PageEight(nmDetails);
    let probableRootCause = ProbableRootCause(nmDetails);
    let correctiveActionPlan = CorrectiveActionPlan(nmDetails);

    const rawHtml = `
    <!DOCTYPE html>
      <html>
        <head>
          <title></title>
          <style>
          body {
            counter-set: page;
          }          
          span.page-number:after {              
            counter-increment: page;
            content: "Page " counter(page);
            position : absolute;
            left : 50%;
            bottom : 5px;
            font-size: 14px;
          }            
          @media print {
            .full-table {
              width : 100%;
            }
            .inner-table {
              padding : 5%
            }
          }          
          </style>
        </head>
        <body>
          ${pageOne}
          <div style="page-break-before: always;"> </div>
          ${PageTwo}
          <div style="page-break-before: always;"> </div>
          ${pageThree}
          <div style="page-break-before: always;"> </div>
          ${pageFour}
          <div style="page-break-before: always;"> </div>
          ${pageFive}
          <div style="page-break-before: always;"> </div>
          ${pageEight}
          <div style="page-break-before: always;"> </div>
          ${probableRootCause}
          <div style="page-break-before: always;"> </div>	
          ${correctiveActionPlan}
        </body>
        <script src="https://code.highcharts.com/highcharts.js"></script>
<script src="https://code.highcharts.com/modules/exporting.js"></script>
<script src="https://code.highcharts.com/modules/export-data.js"></script>
<script src="https://code.highcharts.com/modules/accessibility.js"></script>
      </html>
    `;
    setRenderHtml(rawHtml);
  };

  return <React.Fragment>{parse(renderHtml)}</React.Fragment>;
};

export default NMReport;
