import React, { Component } from "react";
import { Button, Modal, Accordion } from 'react-bootstrap';
import { ChevronDown, Circle, ArrowRight } from 'react-feather';

export default class AssignedProject extends Component{
  constructor(props){
    super(props);
    this.state = {
      show: this.props.show,
      assignData : this.props.assignData
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(){
    this.props.modalClose();
  }
 
  render() {
    return (
      <div className="report-section">
        <Modal 
          centered
          show={this.state.show}
          className="assigned-popup"
          onHide={this.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assigned Project</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="assigned-popup">
              {
                this.props.assignData && this.props.assignData.length > 0 &&
                <Accordion defaultActiveKey="0">
                  {
                    this.props.assignData.map( (assignItem , index) => (
                      <React.Fragment key={`assign-${index}`}>
                        <Accordion.Toggle as={Button} variant="link" eventKey={index} key={`assign-${index}`}>
                          <h6>{assignItem.service_name}</h6>
                          <div className="icon-btn chevron-down-btn">
                            <ChevronDown className='chevron-down-icon'/>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index}>
                          <div className="inner-project-list">
                            {
                              assignItem.project_list && assignItem.project_list.map( (projectItem, pIndex) => (
                                <p key={`project-${pIndex}`}>
                                  <ArrowRight /> { projectItem.project_name  }
                                </p>
                              ))
                            }
                          </div>
                        </Accordion.Collapse>
                      </React.Fragment>
                    ))
                  }         
                </Accordion>
              }
              {
                this.props.assignData && this.props.assignData.length === 0 && 
                <p className="text-center list-error">No Data available</p>
              }
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

AssignedProject.defaultProps = {
  show : false,
  assignData : []
}