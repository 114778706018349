import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Image, Mic, MicOff } from "react-feather";
import { ReactMic } from "react-mic";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Redirect, withRouter } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import Url from "app/shared/constants/Url";
import axios from "axios";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import AudioPlayer from "react-h5-audio-player";
import LoadingImage from "app/shared/components/Loader/LoadingImage";

const qs = require("qs");

export default class ResolvePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      reportId: this.props.reportId,
      formSubmitting: false,
      images: [],
      maxFilesAttachLimit: 5,
      fileLimitMsg: false,
      redirectToReferrer: false,
      showRecorder: false,
      closingRemarkAudio: "",
      closingRemarkAudioURL: "",
      recordAudio: false,
      dataLoading: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.setState({
      show: this.props.show,
    });
  }

  _showRecorder = async (flag = false) => {
    await this.setState((prevState) => {
      let { showRecorder } = prevState;
      showRecorder = flag;
      return { showRecorder };
    });
  };

  startRecording = async () => {
    await this.setState({ recordAudio: true });
  };

  stopRecording = async () => {
    await this.setState({ recordAudio: false });
  };

  onStop = async (recordedBlob) => {
    let promise = new Promise((resolve, error) => {
      let reader = new FileReader();
      reader.readAsDataURL(recordedBlob.blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    promise.then(async (response) => {
      await this.setState((prevState) => {
        let { closingRemarkAudio, closingRemarkAudioURL } = prevState;

        closingRemarkAudio = recordedBlob.blob;
        closingRemarkAudioURL = response;
        return {
          closingRemarkAudio,
          closingRemarkAudioURL,
        };
      });
    });
  };

  async closeModal() {
    await this.setState({
      closingRemarkAudio: "",
      closingRemarkAudioURL: "",
      showRecorder: false,
      recordAudio: false,
      show: false,
    });

    console.log("called ");
    this.props.modalClose();
  }

  handleImages = async (event) => {
    let fileObj = [];
    let fileArray = this.state.images;
    fileObj.push(event.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(fileObj[0][i]);
    }
    await this.setState({ images: fileArray });
    this.fileInput.value = "";
  };

  _removePreviewFile = (key) => {
    var array = this.state.images;
    array.splice(key, 1);
    this.setState({ images: array });
  };

  _removeAudioFile = async (type) => {
    await this.setState((prevState) => {
      let {
        closingRemarkAudioURL,
        closingRemarkAudio,
        recordAudio,
        showRecorder,
      } = prevState;
      closingRemarkAudioURL = "";
      closingRemarkAudio = "";
      showRecorder = false;
      return {
        closingRemarkAudioURL,
        closingRemarkAudio,
        recordAudio,
        showRecorder,
      };
    });
  };
  debounceFunc = _.debounce(function (callback) {
    callback()
  }, 300);
  render() {
    if (this.state.redirectToReferrer) {
      return !this.props.reportingDetails ? (
        <Redirect
          to={`/reporting-detail/${this.state.reportId}/resolve/thgPR`}
        />
      ) : (
        <Redirect to={`/ua-uc/resolve`} />
      );
    }

    console.log(this.props.data);
   
    
    return (
      <div className="report-section">
        {/* Data Loading  */}
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
        <Modal
          show={this.state.show}
          centered
          backdrop="static"
          onHide={this.closeModal}
        >
          <Formik
            initialValues={{ closing_remark: "", file: null }}
            validate={(values) => {
              let errors = {};
              if (values.closing_remark === "") {
                errors.closing_remark = "Closing Remark is required";
              }
              return errors;
            }}
            onSubmit={async (values, actions) => {
              await this.setState({ dataLoading: true });

              if (this.state.images.length > 5) {
                this.setState({ fileLimitMsg: true, dataLoading: false });
              } else {
                this.setState({ fileLimitMsg: false });
                let userId = localStorage.getItem("userId");
                let token = localStorage.getItem("token");

                let data = new FormData();
                data.append("auth_key", token);
                data.append("user_id", userId);
                data.append("closing_remark", values.closing_remark);
                data.append("report_id", this.state.reportId);

                this.state.images.map((dataset, i) => {
                  var addData = dataset;
                  data.append("file", addData);
                  console.log(addData);
                });
                data.append(
                  "closing_remark_file",
                  this.state.closingRemarkAudio
                );
                await axios({
                  method: "post",
                  url: Url.smDashboard.resolveSOI,
                  data: data,
                  headers: { "Content-Type": "multipart/form-data" },
                })
                  .then(async (response) => {
                    if (response.status == 200) {
                      setTimeout(() => {
                        this.setState({
                          dataLoading: false,
                          redirectToReferrer: true,
                        });

                        AlertMessage({
                          message: "Resolve successfully",
                          type: "success",
                        });
                      }, 1000);
                    } else {
                      this.closeModal();
                      await this.setState({
                        dataLoading: false,
                      });
                      AlertMessage({
                        message:
                          "Resolve cannot be submitted. Please try again.",
                        type: "error",
                      });
                    }
                  })
                  .catch(async (error) => {
                    await this.setState({
                      dataLoading: false,
                    });
                  });
              }
            }}
          >
            {({
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              isValid,
              dirty,
              setFieldValue,
            }) => (
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {this.props.data?.activity || "RESOLVE REPORT"}
                  </Modal.Title>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    className="modal-btn"
                    onClick={() => this.debounceFunc(handleSubmit)}
                    // onClick={handleSubmit}
                  >
                    RESOLVE
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <div className="form-group">
                      <label>Resolve Remark</label>
                      <Field
                        name="closing_remark"
                        type="text"
                        placeholder="Closing Remark"
                        className="form-control"
                      />
                      <ErrorMessage
                        component="div"
                        name="closing_remark"
                        className="inputErrorMsg"
                      />
                    </div>
                    <div className="form-group">
                      <label>Evidence</label>
                      <div className="box">
                        <input
                          id="file-2"
                          name="file[]"
                          type="file"
                          multiple
                          onChange={this.handleImages}
                          className="inputfile inputfile-2"
                          accept="image/*"
                          ref={(ref) => (this.fileInput = ref)}
                        />
                        <label htmlFor="file-2">
                          <div className="image-custom">
                            <Image color="#28AED9" size="22" />
                          </div>
                          <span>Browse Images</span>
                        </label>
                        {this.state.fileLimitMsg &&
                        this.state.images.length >
                          this.state.maxFilesAttachLimit ? (
                          <div className="inputErrorMsg">
                            {" "}
                            {`Max limit of attach files is ${this.state.maxFilesAttachLimit}`}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="image-preview">
                      {this.state.images &&
                        this.state.images.map((data, index) => (
                          <div
                            className="preview-box"
                            key={`imageList-${index}`}
                          >
                            <img src={URL.createObjectURL(data)} alt="" />
                            <Button
                              variant="outline-primary"
                              className="close-btn"
                              onClick={() => this._removePreviewFile(index)}
                            >
                              X
                            </Button>
                          </div>
                        ))}
                    </div>
                    
                    {/* <div className="form-group mt-5">
                      <label>Closing remark audio </label>

                      {!this.state.showRecorder &&
                      !this.state.closingRemarkAudioURL ? (
                        <Mic
                          className="nm-mic"
                          onClick={() => this._showRecorder(true)}
                        />
                      ) : null}
                    </div> */}

                    {/* Show Recorder */}
                    {this.state.showRecorder &&
                    !this.state.closingRemarkAudioURL ? (
                      <>
                        <div className="obervationRecording">
                          <ReactMic
                            record={this.state.recordAudio}
                            className="sound-wave"
                            onStop={this.onStop}
                            mimeType="audio/mp3"
                            strokeColor="#000000"
                            backgroundColor="#FF4081"
                          />
                        </div>
                        <div className="modal-footer-btn">
                          {!this.state.recordAudio ? (
                            <Button
                              onClick={this.startRecording}
                              className="red-btn"
                            >
                              <Mic /> Start Recording
                            </Button>
                          ) : null}
                          {this.state.recordAudio ? (
                            <Button
                              onClick={this.stopRecording}
                              className="gray-btn"
                            >
                              <MicOff /> Stop Recording
                            </Button>
                          ) : null}
                        </div>
                      </>
                    ) : null}

                    {/* Show Audio player after recording */}
                    {this.state.closingRemarkAudioURL ? (
                      <div className="audioPreviewer mt-2">
                        <AudioPlayer
                          src={this.state.closingRemarkAudioURL}
                          showJumpControls={true}
                          layout="horizontal-reverse"
                          customVolumeControls={[]}
                          customAdditionalControls={[]}
                          style={{
                            width: "400px",
                          }}
                        />
                        <Button
                          variant="outline-link ml-2"
                          onClick={this._removeAudioFile}
                        >
                          X
                        </Button>
                      </div>
                    ) : null}
                  </Form>
                </Modal.Body>
              </div>
            )}
          </Formik>
        </Modal>
      </div>
    );
  }
}
