export const TYPE_ACT = [
  {
    id: 1,
    name: "UA",
  },
  {
    id: 2,
    name: "UC",
  },
];

export const NM_TYPE_ACT = [
  {
    id: "act",
    name: "UA",
  },
  {
    id: "condition",
    name: "UC",
  },
];

export const INCIDENT_TYPE = [
  {
    id: 4,
    name: "Fatal",
  },
  {
    id: 3,
    name: "TRA",
    // Britannia v01
    //  Making this change to ...(Change RTA to TRA becuase of customizations )
  },
  {
    id: 1,
    name: "LTI",
  },
  {
    id: 2,
    name: "MTC",
  },
  {
    id: 7,
    name: "Notable",
  },
  {
    id: 6,
    name: "Occupational Illness",
  },
  {
    id: 5,
    name: "First Aid",
  },
];

export const SEVERITY = [
  {
    id: 4,
    name: "Extreme",
  },
  {
    id: 3,
    name: "High",
  },
  {
    id: 2,
    name: "Medium",
  },
  {
    id: 1,
    name: "Low",
  },
];

export const WEEKDAY = [
  {
    name: "All Days",
  },
  {
    name: "Monday",
  },
  {
    name: "Tuesday",
  },
  {
    name: "Wednseday",
  },
  {
    name: "Thursday",
  },
  {
    name: "Friday",
  },
  {
    name: "Saturday",
  },
  {
    name: "Sunday",
  },
];

const dayGenerate = (length = 15) => {
  let listArray = [];
  for (let index = 0; index <= length; index++) {
    const element =
      index == 0
        ? { name: `Select Day`, value: 0 }
        : { name: `Day - ${index}`, value: index };
    listArray = [...listArray, element];
  }
  return listArray;
};

export const DAYLIST = dayGenerate(15);

// Week list with value
export const WEEKLIST = [
  {
    name: "Select Day",
    value: 0,
  },
  {
    name: "Monday",
    value: 1,
  },
  {
    name: "Tuesday",
    value: 2,
  },
  {
    name: "Wednseday",
    value: 3,
  },
  {
    name: "Thursday",
    value: 4,
  },
  {
    name: "Friday",
    value: 5,
  },
  {
    name: "Saturday",
    value: 6,
  },
  {
    name: "Sunday",
    value: 7,
  },
];

// month List
export const MONTHLIST = dayGenerate(30);
