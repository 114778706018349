const PageNine = (data) => {
  let tempHTML = "";

  try {
    data.witness_details.forEach((item, index) => {
      tempHTML += `<table cellpadding="6" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%" style="margin-bottom: 10px">
      <tr style="background: #ddd">
        <th style="text-align: center;">${++index}</th>
        <th style="text-align: left;" width="30%">Witness Name</th>
        <th style="text-align: left;">${item.witness_name || 'N/A'}</th>
      </tr>
      <tr>
        <td style="text-align: center;">1</td>
        <td style="font-weight: bold;">Photo</td>
        <td valign="middle"><img src="${item.witness_pic?.[0].witness_pic}" height="60" style="border: 3px solid #fff;border-radius:7px;box-shadow:0 0 5px #aaa"></td>
      </tr>
      <tr>
        <td style="text-align: center;">2</td>
        <td style="font-weight: bold;">Employee Type</td>
        <td>${item.employee_type || 'N/A'}</td>
      </tr>
      <tr>
        <td style="text-align: center;">3</td>
        <td style="font-weight: bold;">Statement</td>
        <td>${item.witness_statement || 'N/A'}</td>
      </tr>
    </table>`;
    });
  } catch (error) {}

  return `<table cellpadding="15" cellspacing="0" width="800" style="margin: 0 auto 10px auto;font-family: arial;height: 100vh;font-size: 14px;border: 3px double #797878;position:relative" class="full-table">
<tr>
  <td valign="top" class="inner-table">
  <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Data Gathering</h4>
  <hr />
    <h4 style="font-size: 16px;margin-bottom:10px;font-weight:bold">Witness Details</h4>
    <br />
    ${tempHTML}
    <span class="page-number" />    
  </td>
</tr>
</table>`;
};

export default PageNine;
