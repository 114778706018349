import React, { Component } from "react";
import LoginForm from "./../Login/LoginForm";
import ForgotPassword from "./../Forget/ForgotPassword";
import ResetPassword from "./../Reset/ResetPassword";
import { Switch, Route } from "react-router-dom";
import Url from "app/shared/constants/Url";

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <div className="inner-text">
        <h1>404</h1>
        <h2>Page Not Found</h2>
      </div>
    </div>
  );
};

export default class AuthRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoUrl: "",
      tagLine: {},
    };
  }

  componentDidMount = () => {
    this._getLogo();
    this._getTagline();
  };

  _getLogo = async () => {
    const requestData = {
      status: 1,
      link: Url.clientURL,
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.compnayLogo,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ logoUrl: response.data.data.logo });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  _getTagline = async () => {
    let {hostname, protocol} = window.location;
    const requestData = {
      status: 1,
      link: `${protocol}//${hostname}`,
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.tagLine,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ tagLine: response.data.data });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  render() {
    return (
      <div className="lright-wrapper">
        <div className="client-info">
          <div className="client-img">
            <img src={this.state.logoUrl} alt="Logo" />
          </div>
          <div className="client-para overflow-auto" style={{maxHeight:"6rem"}}>
            <h4>{this.state.tagLine?.tag_line || `At CLIDE we believe`}</h4>
            <p>
              {this.state.tagLine?.tag_line_description ||
                `Every life should be touched with care" or because caring for life
              - Is the way we work`}
            </p>
          </div>
        </div>
        <div className="login-bottom">
          <Switch>
            <Route path="/auth/login">
              <LoginForm />
            </Route>
            <Route path="/auth/forget-password">
              <ForgotPassword />
            </Route>
            <Route path="/auth/set-password/:token/:flag">
              <ResetPassword />
            </Route>
            <Route path="/sid">
              <h1>Auth</h1>
            </Route>
            <Route path="/sid">
              <h1>Auth</h1>
            </Route>
            
            <Route path="*" exact={true} component={PageNotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}
