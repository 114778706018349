import React from "react";

const FormTypeDropDown = ({
  title,
  optionList,
  onChangeHandle,
  isRequired,
  titleview = true,
  value
}) => {
  return (
    <div>
      {isRequired && <span className="inputErrorMsg">&nbsp;*</span>}
      <select
        className="form-control3 bg-transparent border-0"
        onChange={(event) => onChangeHandle(event.target.value)}
        value={value}
      >
        {optionList.length ? (
          <>
            <option value={""}>{title}</option>
            {optionList.map((item, index) => (
              <option
  key={`project-option-${index}`}
  value={
    Object.keys(item)[1] === "id"
      ? item[`${Object.keys(item)[1]}`]
      : item[`${Object.keys(item)[0]}`]
  }
>
  {Object.keys(item)[1] !== "id"
    ? item[`${Object.keys(item)[1]}`]
    : item[`${Object.keys(item)[0]}`]}
</option>

            ))}
          </>
        ) : (
          <option value={""}>No {title} Available</option>
        )}
      </select>
    </div>
  );
};

FormTypeDropDown.defaultProps = {
  title: "",
  optionList: [],
  isRequired: true,
  onChangeHandle: () => {},
  value: ""
};

export default FormTypeDropDown;
