//libraries
import React, { useMemo, useState, Fragment } from "react";
import _ from "lodash";
import Scrollbars from "react-custom-scrollbars";
import { DocumentViewer } from "react-documents";
//ui kit
import {
  Button as SLDButton,
  Modal as SLDModal,
} from "@salesforce/design-system-react";
//icons
import { Eye, Download, Trash } from "react-feather";
//assest
import NoPerviewAvailable from "assets/no-preview.png";

const iconsEnum = {
  pdf: require("assets/sls-icons/pdfIcon.svg"),
  jpeg: require("assets/sls-icons/imageIcon.svg"),
  jpg: require("assets/sls-icons/imageIcon.svg"),
  xlsx: require("assets/images/icons/Excel.svg"),
  sheet: require("assets/images/icons/Excel.svg"),
};

const isPPTFile = ["ppt", "pptx"];
const previewExtension = ["png", "jpg", "pdf", "jpeg", "ppt", "pptx"];
const imagePreviewExtension = ["png", "jpg", "jpeg"];

const getFileName = (url = "", type = 1) => {
  if (url !== "" && typeof url === "string") {
    const strArr = url.split(".");
    const fileName = strArr[strArr.length - 2];
    const fileExtension = strArr[strArr.length - 1];
    const tempName = fileName?.split("/");

    let finalName = fileName;
    if (tempName?.length) {
      finalName = tempName[tempName?.length - 1];
    }
    if (type === 1) {
      return finalName;
    } else if (type === 2) {
      return fileExtension;
    }
    return [finalName, fileExtension];
  } else if (typeof url === "object") {
    const str = url.name;
    const arrContent = str.split(".");

    if (type === 1) {
      return url.name;
    } else if (type === 2) {
      return arrContent[arrContent.length - 1];
    }
    return [url.name, arrContent[arrContent.length - 1]];
  }
};
const downloaFile = (url) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.download = url.split("/").pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const DocumentPreviewer = ({
  documentArray = [],
  mappingKeys = {},
  deleteItems,
  isDeleteCallBackEnable = false,
  scrolStylesProps = {},
  isDeletPerviewRemovedOnDelete = true,
}) => {
  const [documentParameters, setDocumentParameters] = useState({});
  const [deletedItems, setDeleteItems] = useState([]);
  // useEffect(() => {
  //     if (isDeleteCallBackEnable && deleteItems.length) {
  //         deleteItems(deletedItems);
  //     }
  // }, [deletedItems, setDeleteItems]);
  const perviewsList = useMemo(() => {
    const temp = [];
    if (documentArray.length) {
      documentArray.forEach((element) => {
        const [name, extention] = getFileName(element[mappingKeys?.url], 3);
        const { fileName = "" } = element;
        temp.push({
          id: element[mappingKeys?.id],
          url: element[mappingKeys?.url],
          docType: extention,
          name: fileName !== "" ? fileName : name,
          docIcon: iconsEnum[extention],
        });
      });
    }
    return temp;
  }, [documentArray, mappingKeys]);
  const renderImagePreview = (element, idx) => {
    const isPreviewEnabled = previewExtension.includes(
      getFileName(element?.url, 2)
    );
    const isImagePerviewExtension = imagePreviewExtension.includes(
      getFileName(element?.url, 2)
    );

    return (
      <div style={{ width: "15rem" }} key={idx}>
        <div class="slds-file slds-file_card slds-has-title">
          <figure>
            <a class="slds-file__crop">
              <span class="slds-assistive-text">Preview:</span>
              {isImagePerviewExtension ? (
                <img src={element?.url} alt="Description of the file" />
              ) : (
                <img
                  src={NoPerviewAvailable}
                  style={{
                    maxWidth: "8vw",
                    top: "30%",
                  }}
                  alt="Description of the file"
                />
              )}
            </a>
            <figcaption class="slds-file__title slds-file__title_card">
              <div class="slds-media slds-media_small slds-media_center w-100">
                <div class="slds-media__figure slds-line-height_reset user-icon mr-1">
                  <span class="slds-icon_container" title={element?.name}>
                    <img src={element?.docIcon} />
                  </span>
                </div>
                <div class="slds-media__body d-flex align-items-center">
                  <span
                    class="slds-file__text slds-truncate "
                    title={element?.name}
                    style={{
                      width: "70%",
                    }}
                  >
                    {element?.name}
                  </span>
                  {/* <div className="w-75 ml-1 d-flex justify-content-end">
                    {isPreviewEnabled ? (
                      <Eye
                        className="cursor-pointer  mr-1"
                        onClick={() => {
                          setDocumentParameters(element);
                        }}
                      />
                    ) : null}
                    {!isPreviewEnabled ? (
                      <Download
                        className="cursor-pointer  "
                        title="Download"
                        onClick={() => {
                          downloaFile(element?.url);
                        }}
                      />
                    ) : null}
                  </div>
                  {isDeleteCallBackEnable ? (
                    <img
                      src={require("assets/sls-icons/V2. New Development/delete.svg")}
                      onClick={() => {
                        if (isDeletPerviewRemovedOnDelete) {
                          setDeleteItems([...deletedItems, element.id]);
                        }

                        deleteItems(element.id);
                      }}
                      className="cursor-pointer svg-icons ml-2"
                    />
                  ) : null} */}
                </div>
              </div>
            </figcaption>
            <div class="slds-file__actions-menu">
              <div class="slds-button-group" role="group">
                {isPreviewEnabled ? (
                  <button
                    class="slds-button slds-button_icon slds-button_icon slds-button_icon-x-small mx-1"
                    title="Perview"
                  >
                    <Eye
                      className="cursor-pointer  mr-1 "
                      style={{
                        minWidth: "1VW",
                      }}
                      onClick={() => {
                        setDocumentParameters(element);
                      }}
                    />

                    <span class="slds-assistive-text">Download</span>
                  </button>
                ) : null}
                {!isPreviewEnabled ? (
                  <button
                    class="slds-button slds-button_icon slds-button_icon slds-button_icon-x-small mx-1"
                    title="Download"
                  >
                    <Download
                      className="cursor-pointer  "
                      title="Download"
                      onClick={() => {
                        downloaFile(element?.url);
                      }}
                    />

                    <span class="slds-assistive-text">Download</span>
                  </button>
                ) : null}
                {isDeleteCallBackEnable ? (
                  <button
                    class="slds-button slds-button_icon slds-button_icon slds-button_icon-x-small"
                    title="Delete"
                  >
                    <Trash
                      className="cursor-pointer  "
                      title="Delete"
                      onClick={() => {
                        if (isDeletPerviewRemovedOnDelete) {
                          setDeleteItems([...deletedItems, element.id]);
                        }

                        deleteItems(element.id);
                      }}
                    />

                    <span class="slds-assistive-text">Download</span>
                  </button>
                ) : null}
              </div>
            </div>
          </figure>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Scrollbars
        style={{
          maxHeight: "40vw",
          maxWidth: "40vw",
          height: "10vw",
          width: "38vw",
          ...scrolStylesProps,
        }}
      >
        <ul class="slds-grid slds-grid_pull-padded d-flex common-flex-gap">
          {perviewsList?.map((el, idx) => {
            return !deletedItems.includes(el.id) ? (
              <li
                class="slds-p-horizontal_xx-small slds-size_1-of-2 slds-medium-size_1-of-3 mx-2"
                key={idx}
              >
                {renderImagePreview(el, idx)}
              </li>
            ) : null;
          })}
        </ul>
      </Scrollbars>

      <SLDModal
        disableClose
        footer={[
          <SLDButton
            key="promptBtn"
            label="Close"
            onClick={() => {
              setDocumentParameters({});
            }}
          />,
        ]}
        isOpen={!_.isEmpty(documentParameters)}
        contentClassName="text-center"
        size="small"
        title={
          <span className=" font-18 font-weight-500 text-center w-100">
            Document View
          </span>
        }
      >
        {isPPTFile ? (
          <>
            <DocumentViewer
              queryParams="hl=Nl"
              url={documentParameters?.url}
              style={{
                height:"50vh",
                width:"100%"
              }}
              viewer="office"
              
            ></DocumentViewer>
          </>
        ) : (
          <embed
            src={`${documentParameters?.url}#toolbar=0`}
            // frameborder="0"
            frameBorder="0"
            style={{
              width: "100%",
              height: "20vw",
              paddingBottom: "5%",
            }}
            controls
            controlsList="nodownload"
          />
        )}
      </SLDModal>
    </Fragment>
  );
};
