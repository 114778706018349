import { CHANGE_SERVICE_PROJECT, CHANGE_TAB } from '../constant/ActionType';

let initialState = {
  policy: {
    service_id: null,
    service_name: null,
    project_id: [],
    project_name: null
  },
  activeTab: ""
};

function policyReducer(state = initialState, action) {

  switch (action.type) {
    case CHANGE_SERVICE_PROJECT:
      return {
        ...state,
        policy: {
          ...action.payload
        }
      }
    case CHANGE_TAB:
      return {
        ...state,
        activeTab: action.payload
      }
      break;
    default:
      return state;
      break;
  }
};

export default policyReducer;
