/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Accordion,
  OverlayTrigger,
  Popover,
  Modal,
  Badge,
  ProgressBar,
} from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { ResolvePopup, InviteUser } from "app/shared/components/Popups";
import { Tree, TreeNode } from "react-organizational-chart";
import FishboneChart from "fishbone-chart";
import CommonDropdown from "app/shared/components/ReportModal/CommonDropdown";
import {
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  PieChart,
  Send,
  Search,
  mic,
  Filter,
  RefreshCw,
  Mic,
  Info,
  FileText,
  Calendar,
  PlusSquare,
  Save,
  X,
  Edit,
  Trash,
  Book,
  Upload,
  Command,
  GitPullRequest,
  Circle,
  Archive,
  Eye,
} from "react-feather";
import { toast } from "react-toastify";
import Url from "app/shared/constants/Url";
import axios from "axios";
import HighchartsReact from "highcharts-react-official";
import Model from "react-body-highlighter";
import { CarouselComponent } from "app/shared/components/ReusableComponents/Carousel";
import { CarouselV2 } from "app/shared/ui-kit/carousel";
import CrownIcon from "assets/images/icons/Crown .svg";
import AudioPlayer from "react-h5-audio-player";
import { Link } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import DiscussIcon from "assets/images/icons/discuss.svg";
import SampleProof from "assets/images/proof.png";
import SampleCause from "assets/images/sampleRootCause.png";
import CheckFormSettingField from "app/shared/utility/CheckFormSettingField";
import Backbutton from "app/shared/components/Backbutton";
import {
  convertDateFromTimezone,
  toDateByFormat,
} from "app/shared/utility/tzUtilFunction";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";
import { HorizontalNavTab } from "app/shared/components/ReportModal";
import { HorizontaTimeline } from "app/views/Form/UserFormView/UserFormView";
import { Scrollbars } from "react-custom-scrollbars";
import { ChatMessageBox } from "app/views/TaskManagement/TaskDetail";
import HighBadgeIcon from "assets/sls-icons/Risk Level/High.svg";
import MediumBadgeIcon from "assets/sls-icons/Risk Level/Medium.svg";
import LowBadgeIcon from "assets/sls-icons/Risk Level/Low.svg";
import ExtremeBadgeIcon from "assets/sls-icons/Risk Level/Extreme.svg";
import * as Highcharts from "highcharts";
import HC_timeLine from "highcharts/modules/timeline";
import ReactTooltip from "react-tooltip";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import { requiredMessage } from "app/shared/constants/Messages";
import TeamUnavailable from "assets/images/teamun.svg";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
import {
  getUserProfileDetails,
  GetPopoverFrame,
} from "app/views/SocialMedia/ReportingDetail/ReportingDetail";
// ui kit components
import {
  Tooltip,
  File,
  Files,
  Carousel,
  Combobox,
  Textarea,
  Checkbox,
  Input,
  Datepicker,
  comboboxFilterAndLimit,
  Icon,
  IconSettings,
  Timepicker,
  Badge as SLDBadge,
  Modal as SLDModal,
  Button as SLDButton,
  Popover as SLDPopover,
} from "@salesforce/design-system-react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// utils
import { searchInArrary } from "app/views/Form/SubmitFormListing/FormApprovalTableView";
import { getNextDays } from "app/views/Nm/Detail";
import UaUcGraph from "app/shared/components/PredictiveAnalysis/UaUcGraph";
import { config } from "config.js";
import CountGraph from "app/views/Graphs/Graphs/CountGraph";
HC_timeLine(Highcharts);
const qs = require("qs");
const badgeEnum = {
  Extreme: ExtremeBadgeIcon,
  High: HighBadgeIcon,
  Medium: MediumBadgeIcon,
  Low: LowBadgeIcon,
};
const animatedComponents = makeAnimated();
export const _momentDate = (stringDate) =>
  moment(stringDate, "DD-MM-YYYY").toDate();
const month = [
  {
    id: 0,
    name: "0",
  },
  {
    id: 1,
    name: "1",
  },
  {
    id: 2,
    name: "2",
  },
  {
    id: 3,
    name: "3",
  },
  {
    id: 4,
    name: "4",
  },
  {
    id: 5,
    name: "5",
  },
  {
    id: 6,
    name: "6",
  },
  {
    id: 7,
    name: "7",
  },
  {
    id: 8,
    name: "8",
  },
  {
    id: 9,
    name: "9",
  },
  {
    id: 10,
    name: "10",
  },
  {
    id: 11,
    name: "11",
  },
];
const YearSelect = [
  {
    id: 0,
    name: "0",
  },
  {
    id: 1,
    name: "1",
  },
  {
    id: 2,
    name: "2",
  },
  {
    id: 3,
    name: "3",
  },
  {
    id: 4,
    name: "4",
  },
  {
    id: 5,
    name: "5",
  },
  {
    id: 6,
    name: "6",
  },
  {
    id: 7,
    name: "7",
  },
  {
    id: 8,
    name: "8",
  },
  {
    id: 9,
    name: "9",
  },
  {
    id: 10,
    name: "10",
  },
  {
    id: 11,
    name: "11",
  },
  {
    id: 12,
    name: "12",
  },
  {
    id: 13,
    name: "13",
  },
  {
    id: 14,
    name: "14",
  },
  {
    id: 15,
    name: "15",
  },
  {
    id: 16,
    name: "16",
  },
  {
    id: 17,
    name: "17",
  },
  {
    id: 18,
    name: "18",
  },
  {
    id: 19,
    name: "19",
  },
  {
    id: 20,
    name: "20",
  },
  {
    id: 21,
    name: "21",
  },
  {
    id: 22,
    name: "22",
  },
  {
    id: 23,
    name: "23",
  },
  {
    id: 24,
    name: "24",
  },
  {
    id: 25,
    name: "25",
  },
  {
    id: 26,
    name: "26",
  },
  {
    id: 27,
    name: "27",
  },
  {
    id: 28,
    name: "28",
  },
  {
    id: 29,
    name: "29",
  },
  {
    id: 30,
    name: "30",
  },
  {
    id: 31,
    name: "31",
  },
  {
    id: 32,
    name: "32",
  },
  {
    id: 33,
    name: "33",
  },
  {
    id: 34,
    name: "34",
  },
  {
    id: 35,
    name: "35",
  },
  {
    id: 36,
    name: "36",
  },
  {
    id: 37,
    name: "37",
  },
  {
    id: 38,
    name: "38",
  },
  {
    id: 39,
    name: "39",
  },
  {
    id: 40,
    name: "40",
  },
  {
    id: 41,
    name: "41",
  },
  {
    id: 42,
    name: "42",
  },
  {
    id: 43,
    name: "43",
  },
  {
    id: 44,
    name: "44",
  },
  {
    id: 45,
    name: "45",
  },
  {
    id: 46,
    name: "46",
  },
  {
    id: 47,
    name: "47",
  },
  {
    id: 48,
    name: "48",
  },
  {
    id: 49,
    name: "49",
  },
  {
    id: 50,
    name: "50",
  },
];
const postUrl = {
  paper: {
    add: "/listira/step3addpaperdigital",
    edit: "/listira/step3editpaperdigital",
  },
  physcial: {
    add: "/listira/step3addphysicalinfo",
    edit: "/listira/step3editphysicalinfo",
  },
  position: {
    add: "/listira/step3addpositionequipments",
    edit: "/listira/step3editpositionequipments",
  },
  sketch: {
    add: "/listira/step3addsketch",
    edit: "/listira/step3editsketch",
  },
};
const postTooltip = {
  paper: "Procedure / Work Permit / Method statement /Risk matrix",
  physcial: "PPE/Tools/Chemical/Raw Material",
  position:
    "Location of items: people, equipment, materials,structures, EnviroIRAent Condition - Noise, temperature, ventilation",
  sketch: "Incident sketch what exactly happened and how?",
};
const analysisType = {
  Manpower: 1,
  Method: 2,
  Machinery: 3,
  Material: 4,
  Management: 5,
};
const analysisListType = {
  1: "manpowerList",
  2: "methodList",
  3: "machineryList",
  4: "materialList",
  5: "managementList",
};

const analysisContent = {
  Manpower: "manpowerList",
  Method: "methodList",
  Machinery: "machineryList",
  Material: "materialList",
  Management: "managementList",
};
const injuredParts = [
  {
    name: "Bench Press",
    muscles: ["chest", "triceps", "front-deltoids"],
  },
];
const tabs = [
  {
    tabName: "Proposed CAPA",
    key: "prop",
  },
  {
    tabName: "Final CAPA",
    key: "final",
  },
];
const rootCauseMenus = [
  {
    tabName: "Probable Cause",
    key: "1",
  },
  {
    tabName: "Root Cause Analysis",
    key: "2",
  },
];

function toDataURL(file, callback) {
  let fileInfo;
  let baseURL = "";
  // Make new FileReader
  let reader = new FileReader();

  // Convert the file to base64 text
  reader.readAsDataURL(file);

  // on reader load somthing...
  reader.onload = () => {
    // Make a fileInfo Object
    console.log("Called", reader);
    baseURL = reader.result;
    console.log(baseURL);
    callback(baseURL);
  };
  console.log(fileInfo);
}
const StyledNode = styled.div`
  padding: 20px 18px;
  background: #ffffff;
  color: #000000;
  display: inline-block;
  font-size: 0.6vw;
  font-family: inherit;
  border-radius: 0.4vw;
  margin: 5px 0px;
  font-weight: 500;
  width: max-content;
`;
const apiUrl = {
  parameterList: "/listira/addparameterlist",
  step5: {
    propsed: {
      add: "/listira/step5addcorrectiveaction",
      edit: "/listira/editcorrectiveaction",
      approve: "/listira/praposedrecommendationApproved",
      delete: "/listira/deletepraposedcorrectiveaction",
    },
    final: {
      freeze: "/listira/finalrecommendationfreezeall",
      delete: "/listira/finalrecommendationdelete",
    },
    filter: "/listira/recommendationfilter",
  },
  step2: {
    add: "/listira/addinjuredpersondetails",
    edit: "/listira/editinjuredpersondetails",
  },
  step4: {
    chronology: {
      add: "/listira/step4addeventschronology",
      edit: "/listira/editchronologyofevents",
    },
    rootCause: {
      parameterList: "/listira/step4addparameterlist",
      addPobableCause: "/listira/step4addprobablecause",
      addRouteCause: "/listira/addcontributingfactors",
      editRouteCause: "/listira/editcontributingfactors",
    },
  },
  step1: {
    delete: "/listira/deleteinitialresponse",
    edit: "/listira/editinitialresponse",
  },
  step3: {},
};
const intialValue = {
  categoryList: {
    isLoading: false,
    data: [],
  },
  dataGathering: {
    investigation: {
      isLoading: false,
      isImageProcessing: false,
      editId: "",
      deleteId: "",
      proofType: "",
      isAddEnable: false,
      injuredPersonType: "",
      employee_contractor_status: "",
      injured_person_name: [],
      contractor_company_name: "",
      OtherInfomasterlist: [],
      identity_proof: [],
      person_work_type: "",
      joining_date: new Date(),
      month_with_this_emp: "",
      year_with_this_emp: "",
      injured_person_gender: [],
      month_doing_this_job: "",
      year_doing_this_job: "",
      time_of_incident: "",
      working_shift: "",
      injured_parts: [],
    },
    witness: {
      isLoading: false,
      isEdit: "",
      isAddEnable: false,
      employee_contractor_status: null,
      contractor_company: "",
      witness_name: "",
      witness_statement: "",
      files: [],
    },
    posts: {
      editId: "",
      isLoading: false,
      isAddEnable: false,
      module: "",
      files: [],
      savedPalyoad: [],
      file_description: "",
    },
    analysis: {
      editId: "",
      isLoading: false,
      isAddEnable: false,
      questions: [],
    },
    chronology: {
      isEdit: "",
      isLoading: false,
      isAddEnable: false,
      date: "",
      time: "",
      dateTimeOf: null,
      description: "",
      info_source: "",
    },
    recommendation: {
      editId: "",
      isLoading: false,
      isAddEnable: false,
      approveId: "",
      deleteId: "",
      isPropEnable: false,
      category_l: [],
      responsible_p: [],
      correctiveActionText: "",
      selectedDate: null,
    },
    filterParams: {
      isLoading: false,
      isFilterEnable: false,
      category: "",
      recommendation_by: "",
      responsible_person_id: "",
      startDate: null,
      endDate: null,
      page_no: 1,
    },
    rootCause: {
      editId: "",
      isLoading: false,
      isAddEnable: false,
      probable_cause: "",
      section: "1",
      description: "",
      contributing_factor: "",
      masterList: [],
      main_category: [],
      root_cause_type: [],
    },
    cost: {
      isEdit: "",
      isLoading: false,
      isAddEnable: false,
      cost_involved: null,
      mandays_lost: null,
      return_to_work_id: [],
      files: null,
    },
  },
  deleteParams: {
    isLoading: false,
    isDeleteEnable: false,
    parameters: {},
    stepNo: "",
    url: "",
  },
};

const nestedListParser = {
  main_category: {
    id: "main_category_id",
    label: "main_category",
  },
  sub_category: {
    id: "sub_category_id",
    label: "sub_category",
  },
  major_root_cause_category: {
    id: "major_root_cause_category_id",
    label: "major_root_cause_category",
  },
  near_root_cause_category: {
    id: "near_root_cause_category_id",
    label: "near_root_cause_category",
  },
  intermediate_cause_category: {
    id: "intermediate_cause_category_id",
    label: "intermediate_cause_category",
  },
  root_cause_type: {
    id: "root_cause_type_id",
    label: "root_cause_type",
  },
  root_cause: {
    id: "root_cause_id",
    label: "root_cause",
  },
};
const _getAddedInjuredPersonName = (injured_person_details) => {
  try {
    let { person_details } = injured_person_details;
    let tempList = person_details.map((item) => item.injured_person_name);
    return tempList;
  } catch (error) {
    return [];
  }
};

const refreshUrls = [
  "/listira/deletechronologyofevents",
  "/listira/deletepaperdigital",
  "/listira/deletepositionequipments",
  "/listira/deletephysicalinfo",
  "/listira/deletesketch",
];
class IRADetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isFreezeEnabled: false,
      showUser: false,
      iraDetail: {},
      userList: [],
      iraCache: {},
      iraBasicInfo: {},
      iAssignErrorEnabled: false,
      isCategoryErrorEnabled: false,
      isCorretiveActionErrorEnabled: false,
      isCAPAErrorEnabled: false,
      isDueDateErrorEnabled: false,
      isPriortiyErrorEnabled: false,
      // iraDetailStep1: {},
      iraDetailStep1: [],
      iraDetailStep2: {},
      iraDetailStep3: {},
      iraDetailStep4: {},
      iraDetailStep5: {},
      iraFormSetting: [],
      isRootCauseTooltipEnabed: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      workingShift: [],
      masterList: [],
      stepValidations: {
        isIdProofTypeError: false,
        isIdProofDocumentError: false,
      },
      main_category: [],
      rootfactorParams: {},
      rootCauseActiveType: "5 WHY Analysis",
      root_cause_type: [],
      questionMaster: [],
      questionArray: [],
      questionResponse: [
        {
          question_id: 0,
          question: "",
          response: "",
        },
      ],
      priority_l: null,
      intermediateRouteCause: false,
      iraInjuredParts: [],
      toggleStep2: false,
      cflist: [],
      Iflist: [],
      rcalist: [],
      step2Type: "none",
      isFactorLoading: false,
      FishData: {},
      iraidentityProof: [],
      iraTimeOfIncident: [],
      tempQuestionList: [],
      selectedInjuredPerson: "",
      identity_proof_base64: [],
      genderList: [],
      injurtType: [],
      iraWorkType: [],
      injuredPersonList: [],
      iraId: this.props.match.params.id,
      originFlag: this.props.match.params.origin,
      reportStatus: this.props.match.params.reportStatus,
      priority: { extreme: false, high: false, medium: false, low: false },
      dataLoading: true,
      accordionOpen: true,
      currentTab: "prop",
      lightboxactive: false,
      capaType: "",
      subcommentlist: [],
      addIntialResponse: false,
      categoryList: {
        ...intialValue.categoryList,
      },
      comment: 0,
      commentAdd: "",
      commentlist: [],
      actionsmaster: [],
      allComments: false,
      isFactorLoading: false,
      redirectToReferrer: false,
      toggler: false,
      deletePopup: false,
      confirmPopup: false,
      afterTargetDatePopup: false,
      assignedDetails: {},
      initialResponse: {
        editId: "",
        isLoading: false,
        description: "",
        actions: [],
      },
      initialInvestigation: {
        isLoading: false,
        editId: "",
        isAddEnable: false,
      },
      dataGathering: {
        ...intialValue.dataGathering,
      },
      deleteParams: {
        ...intialValue.deleteParams,
      },
      userLikedPopup: false,
      teamLeadID: null,
      fsToggler1: false,
      userLike: [],
      likeNumber: "",
      imageType: "evidence" || "evidence_resolve",
      showContractor: false,
      showZone: false,
      showLocation: false,
      projectList: [],
      incidentPersonName: [],
      completioncount: [],
      capacount: [],
      opencount: [],
      closecount: [],
      gpcount: [],
      tbtcount: [],
      trainingInternalCount: [],
      trainingExternalCount: [],
      trainingEmployeeCount: [],
      trainingContractorCount: [],
      commentError: false,
      fsToggler: false,
      selectedTargetDate: null,
      docUrl: "",
      fishData: {
        Excavation: {
          Management: [],
          Machinery: [],
          Manpower: [],
          Material: [],
          Method: [],
        },
      },
      analysisData: [],
      isAnalysisDataFetching: false,
      activeAnalysisItem: null,
      factorParams: {},
      activeModule: "",
      activePopperParams: {},
      activeEditPopperParams: {
        isLoading: false,
        isEnable: false,
        five_m_analysis_type: "",
        sub_factor_id: null,
      },
      desc: "",
      extremeSelect: 0,
      highSelect: 0,
      mediumSelect: 0,
      lowSelect: 0,
      all: 0,
    };
    this.myRef = React.createRef();
    this.openResolve = this.openResolve.bind(this);
    this.openInviteUser = this.openInviteUser.bind(this);
    this.selectorRef = React.createRef(null);
  }

  componentDidMount() {
    this.CommentList();
    // this.reportDetail();
    this.UserWhoLiked();
    this.iraDetails();
    this.Dasycount();
    this.fetchReturningList();
    if (this.state.reportStatus === "progress") {
      this.fetchActionMaster();
      this.fetchInfoMaster();
      this.fetchInjuredParts();
      this.fetchWorkingShift();
      this.fetchIncidentQuestions();
      this.fetchIdentityProof();
      this.fetchIraIncidentTime();
      this.fetchWorkType();
      this.fetchRootCauseParameters();

      this.fetchGenderList();
      this.fetchInjuryTypeList();
    }
  }

  componentDidUpdate(prevProps) {
    const buttons = document.querySelectorAll(".cause");
    buttons.forEach((button) => {
      button.addEventListener("click", this.manage5mAnalytics);
    });
  }

  showUserLikedPopup = () => {
    this.setState({
      userLikedPopup: true,
    });
  };

  handleClose = () => {
    this.setState({
      userLikedPopup: false,
    });
  };

  openResolve() {
    const show = !this.state.show;
    this.setState({
      show,
    });
  }

  openInviteUser() {
    const showUser = !this.state.showUser;
    this.setState({
      showUser,
    });
  }

  _fetchProjectListFlag = async () => {
    let requestData = {
      service_id: this.state.iraBasicInfo.service_id,
      module_name: "ira",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { showContractor, showZone, showLocation, projectList } =
                prevState;

              projectList = response.data.data;

              // check contractor flag
              const isContractor = projectList.map(
                (item) => item.contractor_flag
              );
              showContractor = isContractor == 0 ? false : true;
              console.log("isContractor == " + isContractor);

              const isZone = projectList.map((item) => item.zone_location_flag);
              showZone = isZone == 0 ? false : true;
              console.log("isZone == " + isZone);

              const isLocation = projectList.map(
                (item) => item.zone_location_flag
              );
              showLocation = isLocation == 0 ? false : true;
              console.log("isLocation == " + isLocation);

              return {
                showContractor,
                showZone,
                showLocation,
                projectList,
              };
            });
          }
        })
        .catch((error) => {
          this.setState({
            dataLoading: false,
          });
        });
    });
  };

  removeInviteUser = async (selectedUserId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.iraBasicInfo.service_id,
      project_id: this.state.iraBasicInfo.project_id,
      ira_id: this.state.iraId,
      team_member_id: selectedUserId,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/listira/removeteammembers", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          AlertMessage({
            message: "User removed successfully",
            type: "success",
          });
          window.location.reload(false);
          //       this.reportDetail();
        }
      });

    // await axios
    //   .post(
    //     Url.smDashboard.removeInviteUser,
    //     qs.stringify({
    //       auth_key: token,
    //       user_id: userId,
    //       report_id: this.state.soiId,
    //       selected_user_id: selectedUserId,
    //     }),
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     if (response.data.status == 200) {
    //       AlertMessage({
    //         message: "User removed successfully",
    //         type: "success",
    //       });
    //       this.reportDetail();
    //     } else {
    //       AlertMessage({
    //         message: "User not removed. Please try again.",
    //         type: "error",
    //       });
    //     }
    //   })
    //   .catch((error) => {});
  };

  CommentList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.getCommentsList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.iraId,
          report_type: "ira",
          limit: 99999,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            commentlist: response.data.data,
            total_number: response.data.total_number,
            comment: 0,
          });
        }
      })
      .catch((error) => {});
  };
  subCommentList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetailcommentsubcommentlist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.iraId,
          comment_id: this.state.commentId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ subcommentlist: response.data.data, comment: 1 });
        }
      })
      .catch((error) => {});
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.commentError = tempObj.commentAdd ? false : true;

      return prevState;
    });
  };

  CommentAdd = async () => {
    await this._formValidation();

    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          console.log(this.state[propertyName]);
          formErrorStatus = true;
          console.log("erroe");
          return;
        } else {
          formErrorStatus = false;
        }
        console.log(formErrorStatus);
      }
    }

    if (!formErrorStatus) {
      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");

      await axios
        .post(
          Url.smDashboard.addMainComment,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            id: this.state.iraId,
            module: "ira",
            comment: this.state.commentAdd,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.CommentList();
            this.setState({ commentAdd: "" });
            AlertMessage({
              message: "Comment Added Successfully",
              type: "success",
            });
          } else {
            AlertMessage({
              message: "Comment Add Failed. Please try again.",
              type: "error",
            });
          }
        })
        .catch((error) => {});
    }
  };

  ReplyCommentAdd = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.replyToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reply_status: this.state.comment,
          comment_reply_id: 0,
          main_comment_id: this.state.commentId,
          comment: this.state.replycommentAdd,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.subCommentList();
          this.setState({ replycommentAdd: "" });
          AlertMessage({
            message: "Reply Added Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Reply Add Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentAdd = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.addLikeToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Liked Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Like Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentRemove = async (Cid, Ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboardcontentcommentremoveunlike,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: Ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Liked Remove Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Like Remove Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  CommentRemove = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    var reportData = this.state.iraDetail;

    await axios
      .post(
        Url.soiDashboard.soideletecomment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: reportData.service_id,
          project_id: reportData.project_id,
          soi_id: this.state.soiId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Deleted Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Deleted Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  deleteReport = async (iraId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        //Url.soiDashboard.soiopendelete,
        Url.iraModule.deleteIRAReport,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          ira_id: iraId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: "IRA Deleted Successfully",
            type: "success",
          });
          setTimeout(() => {
            this.setState({ redirectToReferrer: true });
          }, 3000);
        } else {
          AlertMessage({
            message: "IRA Delete Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  UserWhoLiked = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetaillikelist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.iraId,
          report_type: "ira",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLike: response.data.data,
            likeNumber: response.data.like_number,
          });
        }
      })
      .catch((error) => {});
  };

  submitTargetDate() {
    var targetDate = this.state.selectedTargetDate;
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const ira_reported_id = this.state.iraId;

    let data = {
      auth_key: token,
      user_id: userId,
      ira_id: ira_reported_id,
      target_date: moment(targetDate).format("YYYY-MM-DD"),
      service_id: service_id,
      project_id: project_id,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/listira/iratargetdateupdate", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        // console.warn(responseJson)
        if (responseJson.status == 200) {
          AlertMessage({
            message: "Target date updated successfully",
            type: "success",
          });
          this.setState({
            afterTargetDatePopup: this.state.reportStatus === "pending",
            teamLeadID: null,
            confirmPopup: false,
          });
          this.iraDetails();
        } else {
          AlertMessage({ message: "unable to set target date", type: "error" });
        }
      });
  }

  confirmSubmitTargetDate() {
    this.setState({ confirmPopup: true });
  }

  cancleSubmitTargetTime() {
    this.setState({ selectedTargetDate: null });
    // console.log("heheh")
  }

  _handleChange = async (item = null, type = "observation") => {
    if (item != null && type == "target_date") {
      this.setState({ selectedTargetDate: item });
    }
  };

  _AddTeamLeader = async (user_id) => {
    this.setState({
      dataLoading: true,
    });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const ira_reported_id = this.state.iraId;
    const team_member_id = user_id;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      ira_id: ira_reported_id,
      team_member_id: team_member_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/listira/createteamleader", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        //window.location.reload(false);
        this.setState({
          dataLoading: false,
        });
        if (responseJson.status == 200) {
          AlertMessage({
            message: "Team Leader selected successfully",
            type: "success",
          });
          this.setState({ teamLeadID: user_id, dataLoading: false });
          await this.setState((prevState) => {
            let { people_involved_list } = prevState?.iraBasicInfo;
            people_involved_list = people_involved_list?.filter(
              (team) => team.user_id !== user_id
            );
            return {
              iraBasicInfo: {
                ...prevState?.iraBasicInfo,
                people_involved_list,
              },
            };
          });
          // this._iraDetails();
        }
      });
  };

  _addUsersToList = async (newUsers) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const reportId = this.state.iraId;
    //console.log(newUsers);
    var selectedUserIds = [];
    newUsers.map((item, key) => selectedUserIds.push(item.team_member_id));
    console.log(newUsers);
    console.log("sayan 2 " + selectedUserIds);

    var data = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.iraBasicInfo.service_id,
      project_id: this.state.iraBasicInfo.project_id,
      ira_id: reportId,
      team_members: selectedUserIds,
      status: 0,
    };

    console.log("https://dev-api.clideoffice.com/listira/addteammembers");

    fetch(Url.clientURL + "/listira/addteammembers", {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          AlertMessage({ message: "Team added successfully", type: "success" });
          window.location.reload(false);
          // this.reportDetail();
        }
      });

    // var formBody = [];
    // for (var property in data) {
    //   var encodedKey = encodeURIComponent(property);
    //   var encodedValue = encodeURIComponent(data[property]);

    //   if (property == "selected_user_id") {
    //     data[property].map((item, key) =>
    //       formBody.push(encodedKey + "=" + item)
    //     );
    //   } else {
    //     formBody.push(encodedKey + "=" + encodedValue);
    //   }
    // }
    // formBody = formBody.join("&");
    // // console.log(formBody)

    // await axios
    //   .post(Url.clientURL+"/listira/addteammembers", formBody, {
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //   })

    //   .then((response) => {
    //     console.log("sayan resp")
    //     if (response.data.status == 200) {
    //       console.log("sayan 200")
    //       AlertMessage({ message: "User added successfully", type: "success" });
    //       this.reportDetail();
    //     } else {
    //       AlertMessage({
    //         message: "User not added. Please try again.",
    //         type: "error",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("sayan err")

    //   });
  };

  _toggleDataLoading = async (flag) => {
    await this.setState({ dataLoading: flag });
  };
  resetFactorsState = (callback = {}) => {
    this.setState(
      {
        cflist: [],
        Iflist: [],
        rcalist: [],
      },
      callback
    );
  };
  toggleStep2Fun(type = "none") {
    let activeLabel = type;
    if (type == "immediate") {
      activeLabel = "Immediate Factor";
    } else if (type == "root cause") {
      activeLabel = "Root Cause Analysis";
    } else if (type == "contributing") {
      activeLabel = "Contributing Factor";
    }

    this.setState({ step2Type: activeLabel }, () => {
      if (this.state.toggleStep2) {
        this.setState({ toggleStep2: false });
      } else {
        this.setState({ toggleStep2: true });
      }
    });
  }
  _InProgressContributingList = async () => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const iraID = this.state.iraId;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: iraID,
      module_name: "ira",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/rootcause/contributingfactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          console.log("sayan 200 CFlist");
          await this.setState({ CFList: responseJson.data });
         
        }
      });
  };
  _InProgressImmediateList = async () => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const iraID = this.state.iraId;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: iraID,
      module_name: "ira",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/rootcause/immediatefactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          console.log("sayan 200 IFlist");
          this.setState({ IFList: responseJson.data });
          
        }
      });
  };
  _InProgressRcaList = async () => {
    // console.log("sayan fired")
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const iraID = this.state.iraId;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: iraID,
      module_name: "ira",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/rootcause/rcafactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          console.log("RCAF 200 sayan");
          this.setState({ RCAFList: responseJson.data });
          
        }
      });
  };

  setFishData() {
    const RawFishdata =
      this.state.iraDetailStep4.ira_reported_detail_RCA_factor_list;
    console.log("sayan RCA ", RawFishdata);
    const filtered =
      this.state.iraDetailStep4.ira_reported_detail_RCA_factor_list.map(
        (el) => el.factor
      );

    const extractData = (RawFishdata) => {
      let modules = {};
      RawFishdata.forEach((el) => {
        modules = {
          ...modules,
          [el]: [],
        };
      });
      return modules;
    };
    var FishDataforGraph = {
      [this.state.iraBasicInfo?.activity]: {
        ...extractData(filtered),
      },
    };

    console.log(FishDataforGraph);

    this.setState({ FishData: FishDataforGraph });
  }
  iraDetails = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let requestData = {
        ira_id: this.state.iraId,
      };
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.iraModule.iraDetails,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          try {
            if (response.data.status == 200) {
              this.state.reportStatus == "completed" &&
                (function () {
                  const chronologicalEvents =
                    response?.data?.data?.step_4?.chronology_of_events || [];

                  Highcharts.chart("ira-details-chronology-events", {
                    chart: {
                      type: "timeline",
                    },
                    accessibility: {
                      screenReaderSection: {
                        beforeChartFormat:
                          "<h5>{chartTitle}</h5>" +
                          "<div>{typeDescription}</div>" +
                          "<div>{chartSubtitle}</div>" +
                          "<div>{chartLongdesc}</div>" +
                          "<div>{viewTableButton}</div>",
                      },
                      point: {
                        valueDescriptionFormat:
                          "{index}. {point.label}. {point.description}.",
                      },
                    },
                    xAxis: {
                      visible: false,
                    },
                    yAxis: {
                      visible: false,
                    },
                    title: {
                      text: "",
                    },
                    subtitle: {
                      text: "",
                    },
                    colors: [
                      "#4185F3",
                      "#427CDD",
                      "#406AB2",
                      "#3E5A8E",
                      "#3B4A68",
                      "#363C46",
                    ],

                    series: [
                      {
                        data: chronologicalEvents
                          .map((ce, i) => ({
                            ...ce,
                            datetime: toDateByFormat(
                              `${ce.date} ${ce.time}`,
                              "dd/mm/yyyy hh:ii"
                            ),
                          }))
                          .sort(
                            (a, b) =>
                              a.datetime.getTime() - b.datetime.getTime()
                          )
                          .map((o) => ({
                            name: o.info_source,
                            label: `${o.info_source}- ${o.date}:${o.time}`,
                            description: o.description,
                          })),
                      },
                    ],
                  });
                })();
              if (response.data.data?.basic_ira.reported_by_id) {
                this.fetchReportingPerson(
                  response.data.data?.basic_ira.reported_by_id
                );
              }
              this._restrictExistingPersonFromDropDown(response.data.data);
              await this.setState(
                (prevState) => {
                  let {
                    dataLoading,
                    iraDetail,
                    iraCache,
                    iraBasicInfo,
                    iraFormSetting,
                    iraDetailStep1,
                    iraDetailStep2,
                    iraDetailStep3,
                    iraDetailStep4,
                    iraDetailStep5,
                    incidentPersonName,
                  } = prevState;
                  let { data, form_setting } = response.data;
                  dataLoading = false;
                  iraDetail = data;
                  iraBasicInfo = data.basic_ira;

                  //optimized code==========================
                  // iraDetailStep1 = data.step_1;

                  iraDetailStep1 = data.step_1List;

                  iraDetailStep2 = data.step_2;
                  iraDetailStep3 = data.step_3;
                  iraDetailStep4 = data.step_4;
                  iraDetailStep5 = data.step_5;
                  iraFormSetting = form_setting?.setting;
                  incidentPersonName = iraBasicInfo.incident_person_details;
                  return {
                    dataLoading,
                    iraCache: iraDetailStep5,
                    iraDetail,
                    iraBasicInfo,
                    iraFormSetting,
                    iraDetailStep1,
                    iraDetailStep2,
                    iraDetailStep3,
                    iraDetailStep4,
                    iraDetailStep5,
                    incidentPersonName,
                  };
                },
                () => {
                  this.fetchParameters();
                  this._InProgressContributingList();
                  this._InProgressImmediateList();
                  this._InProgressRcaList();
                  this.setFishData();
                  const involvedPersonsList =
                    this.state?.iraBasicInfo?.incident_person_details?.map(
                      (el) => parseInt(el.searched_incident_person_id)
                    );
                  if (involvedPersonsList.length) {
                    this.fetchUsersList(involvedPersonsList);
                  }
                }
              );
              this._fetchProjectListFlag();
            } else {
              this._toggleDataLoading(false);
            }
          } catch (error) {
            this._toggleDataLoading(false);
          }
        })
        .catch((error) => {
          this._toggleDataLoading(false);
        });
    });
  };

  //dayscount
  Dasycount = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.iraModule.iraDaysCount,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          ira_id: this.state.iraId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            completioncount: response.data.data.capa_completion_days,
            capacount: response.data.data.report_completion_days,
            opencount: response.data.data.recommendation_open_count,
            closecount: response.data.data.recommendation_close_count,
            gpcount: response.data.data.gp_count?.length
              ? response.data.data.gp_count
              : [{ gp_count: 0 }],
            tbtcount: response.data.data.tbt_count?.length
              ? response.data.data.tbt_count
              : [{ tbt_count: 0 }],
            trainingInternalCount: response.data.data.training_internal_count
              ?.length
              ? response.data.data.training_internal_count
              : [{ training_internal_count: 0 }],
            trainingExternalCount: response.data.data.training_external_count
              ?.length
              ? response.data.data.training_external_count
              : [{ training_external_count: 0 }],
            trainingEmployeeCount: response.data.data.training_employee_count
              ?.length
              ? response.data.data.training_employee_count
              : [{ training_employee_count: 0 }],
            trainingContractorCount: response.data.data
              .training_contractor_count?.length
              ? response.data.data.training_contractor_count
              : [{ training_contractor_count: 0 }],
            // gpcount: response.data.data.gp_count,
            // tbtcount: response.data.data.tbt_count,
            // trainingInternalCount: response.data.data.training_internal_count,
            // trainingExternalCount: response.data.data.training_external_count,
            // trainingEmployeeCount: response.data.data.training_employee_count,
            // trainingContractorCount:
            //   response.data.data.training_contractor_count,

            // likeNumber: response.data.like_number,
          });
        }
      })
      .catch((error) => {});
  };

  cancelItem_onClick = () => {
    this.props.history.goBack();
    //window.history.back();
  };

  togglecommentId = async (comment_id) => {
    await this.setState({ commentId: comment_id });
    await this.subCommentList();
  };

  backToMainComment = async () => {
    await this.CommentList();
    await this.setState({ comment: 0, accordionOpen: true });
  };

  showModal = () => {
    this.setState({ deletePopup: true });
  };

  hideModal = () => {
    this.setState({ deletePopup: false });
    this.setState({ confirmPopup: false });
    this.setState({ afterTargetDatePopup: false });
  };

  _handleBackButtonAction = () => {
    if (this.state.originFlag == "kanban") {
      return "/ira/kanban";
    } else {
      return "/";
    }
  };

  _handleLightBox = async (list) => {
    await this.setState({
      evidencelightbox: [],
      evidencelightboxType: [],
      lightboxactive: true,
      fsToggler1: !this.state.fsToggler1,
      evidencelightbox: list.map(
        (item) =>
          item.evidence || item.image_link || item.proof || item.witness_pic
      ),
      evidencelightboxType: list.map((item) => "image"),
    });
  };

  modalHideSearch = () => {
    this.setState({
      showPopupSearch: false,
    });
  };
  modalShowSearch = (rec) => {
    this.setState({
      showPopupSearch: true,
      obs_rec: rec,
    });
  };
  __generateCarouselData = (items = []) => {
    const content = [];
    items.forEach((data) => {
      const format = data?.evidence_format ?? data.format;
      if (format !== undefined) {
        content.push({
          url: data.link ?? data.evidence_image,
          contentType:
            format != "text"
              ? format == "image" || format == "img"
                ? "image"
                : "video"
              : "video",
        });
      }
    });
    return content;
  };
  fetchReportingPerson = (id) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    axios
      .post(
        Url.clientURL + "/user/reportedpersonprofile",
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reported_per_id: id,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          assignedDetails: res.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  getDepartment = (text = "") => {
    const [label = "", value] = text.split(":") ?? [];
    if ((label.trim() === "Department" && value.trim() !== "null") || null) {
      return value;
    }
    return "NA";
  };
  renderTimeLine = (reportData, state) => {
    if (state.dataLoading) {
      return [];
    }
    let details = [
      {
        title: <span className="font-14">Reported Details</span>,
        cardTitle: (
          <div className="d-flex  " style={{ width: "15vw" }}>
            <img
              src={reportData.reported_by_pic}
              className="user-icon"
              title={reportData.reported_by}
              alt="avatar"
            />
            <div className="d-flex flex-column">
              <span className="common-grey-1 font-14">Reporting Date </span>
              <span className="common-black-1 font-14 font-weight-500">
                {`${reportData.reported_date} `} | {reportData.reported_time}
              </span>
            </div>
          </div>
        ),
      },
      {
        title: <span className="font-14">Incident Date</span>,
        cardTitle: (
          <div className="d-flex  " style={{ width: "15vw" }}>
            <img
              src={reportData.reported_by_pic}
              className="user-icon"
              title={reportData.reported_by}
              alt="avatar"
            />
            <div className="d-flex flex-column">
              <span className="common-grey-1 font-14">Date & Time </span>
              <span className="common-black-1 font-14 font-weight-500">
                {`${reportData.incident_date} `} | {reportData?.incident_time}{" "}
              </span>
            </div>
          </div>
        ),
      },
    ];
    if (reportData.incident_person_details?.length) {
      details = [
        ...details,
        ...reportData.incident_person_details?.map((image, index) => {
          return {
            title: <span className="font-14">Incident Employee Details </span>,
            cardTitle: (
              <div className="d-flex   " style={{ width: "11vw" }}>
                <img
                  className="user-icon "
                  src={require("../../../../assets/sls-icons/V2. New Development/user.svg")}
                  alt=""
                  title={image.incident_person_name}
                />

                <div>
                  <div className="common-grey-1 font-14">Incident Person</div>
                  <div className="common-black-1 font-14 font-weight-500">
                    {" "}
                    {image.incident_person_name} |{" "}
                    {image.incident_person_id ?? index + 1}
                  </div>
                </div>
              </div>
            ),
          };
        }),
      ];
    }
    console.log("timeline", reportData.people_involved_list);
    if (reportData.people_involved_list?.length) {
      details = [
        ...details,
        ...reportData.people_involved_list?.map((image, index) => {
          return {
            title: <span className="font-14">Invited Team </span>,
            cardTitle: (
              <div className="d-flex   " style={{ width: "11vw" }}>
                <img
                  className="user-icon "
                  src={image.user_pic}
                  alt=""
                  title={image.user_name}
                />

                <div>
                  <div className="common-grey-1 font-14">
                    Team {image.status == 1 ? "Lead" : "Member"}
                  </div>
                  <div className="common-black-1 font-14 font-weight-500">
                    {" "}
                    {image.user_name}
                  </div>
                </div>
              </div>
            ),
          };
        }),
      ];
    }

    if (state.commentlist?.length) {
      const { comment, comment_time, user_name, user_pic } =
        state.commentlist[0] ?? {};
      details = [
        ...details,
        {
          title: <span className="font-14">Latest Comment</span>,
          cardTitle: (
            <div
              className="d-flex  cursor-pointer "
              style={{ width: "11vw" }}
              onClick={() => {
                this.myRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              <img
                src={user_pic}
                className="user-icon"
                title={user_name}
                alt="avatar"
              />
              <div>
                <div className="common-grey-1 font-14">Message</div>
                <div className="common-black-1 font-14 font-weight-500">
                  {" "}
                  {comment}{" "}
                </div>
                <span className="font-12 common-grey-1">{comment_time}</span>
              </div>
            </div>
          ),
        },
      ];
    }
    return details;
  };
  fetchActionMaster = () => {
    const user_id = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const details = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/listira/actionsmaster", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            actionsmaster: responseJson.data.list?.map((el) => {
              return {
                ...el,
                label: el.action,
                value: el?.id,
              };
            }),
          });
        }
      });
  };
  renderChronology = (arr, state, isActionEnabled = false) => {
    return arr?.map((reportData) => {
      return {
        title: <span className="font-14">Info source</span>,
        cardTitle: (
          <div>
            <div className="d-flex space-between mx-3 flex-wrap">
              <div className="d-flex flex-wrap ">
                <div class="feild-group field d-flex align-items-baseline mr-3">
                  <span class="label  common-black-1 font-weight-500 font-14 w-max ">
                    Date
                  </span>
                  <span class="common-grey-1 font-14 mx-2 mx-2">
                    {reportData?.date}
                  </span>
                </div>
                <div class="feild-group field d-flex align-items-baseline">
                  <span class="label  common-black-1 font-weight-500 font-14 w-max mr-2 ">
                    Time
                  </span>
                  <span class="common-grey-1 font-14 mx-2">
                    {reportData?.time}
                  </span>
                </div>
                <div class="feild-group field d-flex align-items-baseline">
                  <span class="label  common-black-1 font-weight-500 font-14 w-max mr-2 ">
                    Info Source
                  </span>
                  <span class="common-grey-1 font-14 mx-2">
                    {reportData?.info_source}
                  </span>
                </div>

                <div class="   mb-2 w-100">
                  <span class="label  common-black-1 font-weight-500 font-14 w-max mr-2 w-100">
                    Description :
                  </span>
                  <span class="common-grey-1 font-14 mx-2">
                    {reportData?.description}
                  </span>
                </div>
              </div>
              {isActionEnabled ? (
                <div className="d-flex ">
                  <img
                    src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.manageChoronologyPayload(reportData);
                      })
                    }
                    className="cursor-pointer svg-icons mr-1"
                  />{" "}
                  <img
                    src={require("assets/sls-icons/V2. New Development/delete.svg")}
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.manageDataGatheringParams(
                          "",
                          {
                            isDeleteEnable: true,
                            parameters: {
                              event_detail_id: reportData?.id,
                            },
                            url: "/listira/deletechronologyofevents",
                          },
                          false,
                          "deleteParams"
                        );
                      })
                    }
                    className="cursor-pointer svg-icons mr-2"
                  />
                </div>
              ) : null}
            </div>
          </div>
        ),
      };
    });
  };

  resetIntialParams = () => {
    this.setState({
      addIntialResponse: false,
      initialResponse: {
        ...this.state.initialResponse,
        isLoading: false,
        description: "",
        actions: [],
        editId: "",
      },
    });
  };
  _initialresponseAdd = async () => {
    this.setState({
      initialResponse: {
        ...this.state.initialResponse,
        isLoading: true,
      },
    });
    const user_id = localStorage.getItem("userId");
    const auth_key = localStorage.getItem("token");
    const { description, actions, editId } = this.state.initialResponse;
    const list = actions.map((el) => el.value);
    let data = {
      auth_key,
      user_id,
      ira_id: this.state.iraId,
      service_id: this.state.iraBasicInfo.service_id,
      project_id: this.state.iraBasicInfo.project_id,
      [editId !== "" ? "edited_response" : "description"]: description,
      actions: list,
      initial_response_id: editId !== "" ? editId : undefined,
    };
    const basePath =
      editId !== "" ? apiUrl.step1.edit : "/listira/addinitialresponse";
    fetch(Url.clientURL + basePath, {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .finally(() => {
        this.resetIntialParams();
      });
  };

  manageIntialParams = (editId) => {};
  manageNestedState = (moduleName = "", val = {}) => {
    this.setState({
      [moduleName]: {
        ...this.state[moduleName],
        ...val,
      },
    });
  };
  _checkSettingParameter = (checkingParam = "") => {
    let checkingText = checkingParam.toLowerCase();
    const settingList = this.state.iraFormSetting[0]?.step_setting || [];
    return settingList.some(
      (item) =>
        item.parameter.toLowerCase() == checkingText && item.parameter_value
    );
  };

  manageDataGatheringParams = (
    moduleName,
    val,
    reset = false,
    objName = "dataGathering",
    intial = intialValue.dataGathering
  ) => {
    if (reset) {
      this.setState({
        [objName]: {
          ...intial,
        },
        questionArray: [],
      });
      return;
    }
    this.setState((prev) => {
      return {
        [objName]: {
          ...prev[objName],
          ...(objName !== "dataGathering"
            ? { ...val }
            : {
                [moduleName]: {
                  ...prev[objName][moduleName],
                  ...val,
                },
              }),
        },
      };
    });
  };
  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _pickCropImage = async (e, module = "witness") => {
    const lowStorage = this.checkLowStorage();
    let fileObj = [];
    let fileArray = [];
    let files = [...(this.state.dataGathering[module]["files"] ?? [])];
    fileObj.push(e.target.files);

    lowStorage
      .then(async (response) => {
        const { status, msg } = response;

        if (status == 200) {
          for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push(fileObj[0][i]);
            let obj = {
              file: fileObj[0][i],
              file_status: 1,
              file_id: "",
            };
            files.push(obj);
          }
          this.manageDataGatheringParams(module, {
            files: fileArray,
            files,
          });
        } else {
          await this.setState({
            lowStorageMessage: msg,
            showLowStoragePopup: status == 200 ? false : true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  addpeoples = async () => {
    this.manageDataGatheringParams("witness", {
      isLoading: true,
    });
    const user_id = localStorage.getItem("userId");
    const auth_key = localStorage.getItem("token");
    const {
      witness_name,
      contractor_company,
      employee_contractor_status,
      files,
      witness_statement,
      isEdit,
      witness_evidence_id = "",
    } = this.state.dataGathering.witness ?? {};
    const isEditEnabled = isEdit !== "";
    let data = new FormData();
    data.append("auth_key", auth_key);
    data.append("user_id", user_id);
    data.append("service_id", this.state.iraBasicInfo.service_id);
    data.append("project_id", this.state.iraBasicInfo.project_id);
    data.append("ira_id", this.state.iraId);
    data.append("employee_contractor_status", employee_contractor_status);
    data.append("contractor_company", contractor_company);
    data.append("witness_name", witness_name);
    data.append("witness_statement", witness_statement);
    // data.append('record', '');

    // console.warn(data);
    if (isEditEnabled) {
      data.append("witness_id", isEdit);
      data.append("witness_evidence_id", witness_evidence_id);
    }
    if (files != "") {
      data.append("file_status", 1);
      files.forEach((el) => {
        if (typeof el === "object") {
          data.append("file", el.file);
        }
      });
    } else {
      data.append("file_status", 0);
    }
    const basePath = isEditEnabled
      ? "/listira/step3editpeoples"
      : "/listira/step3addpeoples";
    axios
      .post(Url.clientURL + basePath, data)
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  getCarouselProps = (arr = [], callback) => {
    return arr.map((el) => {
      return callback(el);
    });
  };
  fetchInfoMaster = () => {
    const user_id = localStorage.getItem("userId");
    const auth_key = localStorage.getItem("token");
    let details = {
      auth_key,
      user_id,
    };
    let formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iraotherinfomasterlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          let OtherInfomasterlist = responseJson.data.questions.map(function (
            o
          ) {
            o.response = null;
            o.notes = "";
            return o;
          });
          this.setState({
            OtherInfomasterlist,
            tempQuestionList: OtherInfomasterlist,
          });
        }
        // console.warn(this.state.OtherInfomasterlist)
      });
  };
  insertPosts = () => {
    this.manageDataGatheringParams("posts", {
      isLoading: true,
    });
    const user_id = localStorage.getItem("userId");
    const auth_key = localStorage.getItem("token");
    const { file_description, files, module, savedPalyoad } =
      this.state.dataGathering.posts ?? {};
    const isEditEnabled = savedPalyoad.length;
    let databody = new FormData();
    databody.append("auth_key", auth_key);
    databody.append("user_id", user_id);
    databody.append("service_id", this.state.iraBasicInfo.service_id);
    databody.append("project_id", this.state.iraBasicInfo.project_id);
    databody.append("ira_id", this.state.iraId);
    databody.append("file_description", file_description);
    if (isEditEnabled) {
      databody.append("image_id", savedPalyoad[0]["image_id"]);
    }
    if (files.length) {
      files.forEach((el) => {
        databody.append("file", el.file);
      });
    }

    axios
      .post(
        Url.clientURL + postUrl[module][isEditEnabled ? "edit" : "add"],
        databody
      )
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  insertAnalysisQuestions = () => {};
  fetchParameters = () => {
    this.manageDataGatheringParams(
      "",
      {
        isLoading: true,
      },
      false,
      "categoryList"
    );
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + apiUrl.parameterList, {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.data) {
            this.manageDataGatheringParams(
              "",
              {
                data: {
                  category: responseJson.data.category_list || [],
                  responsible_person:
                    responseJson.data.responsible_person || [],
                },
              },
              false,
              "categoryList"
            );
          }
        }
      })
      .catch((error) => {
        throw error;
        console.log("sayan error");
      })
      .finally(() => {
        this.manageDataGatheringParams(
          "",
          {
            isLoading: false,
          },
          false,
          "categoryList"
        );
      });
  };
  generateDropdownParameter = (arr = [], key = {}) => {
    return arr?.map((el) => {
      return {
        id: el[key?.id]?.toString(),
        label: el[key?.label],
      };
    });
  };
  onPriorityChange(num) {
    if (num == 0) {
      this.setState({
        priority: { extreme: true, high: false, medium: false, low: false },
      });
      this.setState({ priority_l: 4 });
    }
    if (num == 1) {
      this.setState({
        priority: { extreme: false, high: true, medium: false, low: false },
      });
      this.setState({ priority_l: 3 });
    }
    if (num == 2) {
      this.setState({
        priority: { extreme: false, high: false, medium: true, low: false },
      });
      this.setState({ priority_l: 2 });
    }
    if (num == 3) {
      this.setState({
        priority: { extreme: false, high: false, medium: false, low: true },
      });
      this.setState({ priority_l: 1 });
    }
  }

  manageCapaValidation = (isEditTrue = false) => {
    const { responsible_p, correctiveActionText, category_l, selectedDate } =
      this.state.dataGathering.recommendation;
    const { capaType, priority_l } = this.state;
    let isErrorEnabled = false;
    if (
      !responsible_p?.length ||
      !category_l?.length ||
      !capaType ||
      !correctiveActionText ||
      !selectedDate ||
      (!isEditTrue && !priority_l)
    ) {
      isErrorEnabled = true;
    }
    this.setState({
      iAssignErrorEnabled: !responsible_p?.length,
      isCategoryErrorEnabled: !category_l?.length,
      isCorretiveActionErrorEnabled: !correctiveActionText,
      isCAPAErrorEnabled: !capaType,
      isDueDateErrorEnabled: !selectedDate,
      isPriortiyErrorEnabled: isEditTrue ? false : !priority_l,
    });
    return isErrorEnabled;
  };
  _AddRecommendation = async () => {
    if (this.manageCapaValidation()) {
      return false;
    }
    const { category_l, responsible_p, selectedDate, correctiveActionText } =
      this.state.dataGathering.recommendation;
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const ira_id = this.state.iraId;

    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const category_id = category_l[0].id;
    const recommendation = correctiveActionText;
    const target_date = moment(selectedDate).format("YYYY-MM-DD");
    const priority = this.state.priority_l;
    const responsible_person_id = responsible_p[0]?.id;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id,
      project_id,
      ira_id,
      category_id,
      recommendation,
      target_date,
      priority,
      capa_type: this.state.capaType,
      responsible_person_id,
    };
    console.log(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + apiUrl.step5.propsed.add, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
        } else {
          console.log("sayan non 200");
        }
      })
      .catch((error) => {
        throw error;
        console.log("sayan error");
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
        this.setState({
          priority: { extreme: false, high: false, medium: false, low: false },
          capaType: "",
        });
      });
  };
  manageEdit = (id = "", item = {}, arr = []) => {
    console.log(id, arr);
    this.manageDataGatheringParams("recommendation", {
      editId: id,
      isAddEnable: true,
      category_l: this.generateDropdownParameter(
        this.state.categoryList.data.category.filter(
          (el) => el.category_id === item.category_id
        ),
        {
          id: "category_id",
          label: "category",
        }
      ),
      responsible_p: this.generateDropdownParameter(
        this.state.categoryList.data.responsible_person.filter(
          (el) => el.person_id === item.responsible_person[0]["person_id"]
        ),
        {
          id: "person_id",
          label: "person",
        }
      ),
      selectedDate: _momentDate(item?.target_date),
      correctiveActionText:
        item?.recommendation?.map((el) => el?.recommendation) ?? "",
    });
    this.setState({
      priority: {
        extreme: item?.priority === "Critical",
        high: item?.priority === "Urgent",
        medium: item?.priority === "Important",
        low: item?.priority === "Normal",
      },
      capaType: item.recommendation[0]?.capaType,
    });
  };

  approveRecommendation = (approveId) => {
    const itemsLength =
      this.state.iraDetailStep5?.praposed_corrective_action?.length;
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    let data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      ira_id,
      recommendation_id: approveId,
    };
    // console.warn(data)

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + apiUrl.step5.propsed.approve, {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({ message: responseJson.msg });
          if (itemsLength === 1) {
            this.setState({ currentTab: "final" }, this.iraDetails);
          }

          this.iraDetails();
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };
  removeFinalRecord = (id) => {
    const itemsLength =
      this.state.iraDetailStep5?.final_corrective_action?.length;
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      ira_id,
      recommendation_id: id,
    };
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + apiUrl.step5.final.delete, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then(() => {
        if (itemsLength === 1) {
          this.setState({ currentTab: "prop" }, this.iraDetails);
        }
        this.iraDetails();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };
  finalRecordFreeze = (id) => {
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const selected_recommendations =
      this.state.iraDetailStep5?.final_corrective_action?.map(
        (el) => el.recommended_id
      );
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      ira_id,
      selected_recommendations,
    };

    fetch(Url.clientURL + apiUrl.step5.final.freeze, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: qs.stringify(data, { arrayFormat: "repeat" }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          AlertMessage({
            type: "success",
            message: res?.msg ?? "Record  completed ",
          });
          this.props.history.push(`/ira-detail/${ira_id}/kanban/completed`);
          window.location.reload();
        } else {
          AlertMessage({
            type: "error",
            message: res?.msg ?? "Something went wrong",
          });
        }

        // this._handleBackButtonAction();
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
        this.setState({
          isFreezeEnabled: false,
        });
      });
  };
  fetchInjuredParts = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/irainjuredparts", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            iraInjuredParts: this.generateDropdownParameter(
              responseJson.data.injured_parts,
              {
                id: "id",
                label: "injured_part",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchWorkingShift = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iraworkingshift", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            workingShift: this.generateDropdownParameter(
              responseJson.data.working_shift,
              {
                id: "id",
                label: "working_shift",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchIncidentQuestions = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iraincidentanalysisquestions", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            questionMaster: this.generateDropdownParameter(
              responseJson.data.questions,
              {
                id: "question_id",
                label: "question",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchIdentityProof = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iraidentityproof", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            iraidentityProof: this.generateDropdownParameter(
              responseJson.data.identity_proof,
              {
                id: "id",
                label: "identity_proof",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchIraIncidentTime = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iratimeofincident", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            iraTimeOfIncident: this.generateDropdownParameter(
              responseJson.data.incident_time,
              {
                id: "id",
                label: "incident_time",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchWorkType = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iraworktype", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            iraWorkType: this.generateDropdownParameter(
              responseJson.data.work_type,
              {
                id: "id",
                label: "work_type",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  _restrictExistingPersonFromDropDown = async (data) => {
    const addedInjuredPersonList = _getAddedInjuredPersonName(
      data.step_2.injured_person_details
    );
    try {
      const tempFinalList = data.basic_ira.incident_person_details.filter(
        (el) => {
          if (!addedInjuredPersonList.includes(el.incident_person_name)) {
            return el;
          }
        }
      );

      await this.setState({
        injuredPersonList: this.generateDropdownParameter(tempFinalList, {
          id: "incident_person_name",
          label: "incident_person_name",
        }),
      });
    } catch (error) {
      console.log(error);
    }
  };
  removePropRecord = (id) => {
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      ira_id,
      recommendation_id: id,
    };
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + apiUrl.step5.propsed.delete, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then(() => {
        this.iraDetails();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };
  _EditRecommendation = () => {
    if (this.manageCapaValidation(true)) {
      return false;
    }
    const { capaType, dataGathering } = this.state ?? {};
    const {
      category_l,
      responsible_p,
      selectedDate,
      correctiveActionText,
      editId,
    } = dataGathering?.recommendation;
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const ira_id = this.state.iraId;

    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const category_id = category_l[0].id;
    const recommendation = correctiveActionText;
    const target_date = moment(selectedDate).format("YYYY-MM-DD");
    const priority = this.state.priority_l;
    const responsible_person_id = responsible_p[0].id;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id,
      project_id,
      ira_id,
      category_id,
      recommendation,
      target_date,
      priority,
      recommendation_id: editId,
      responsible_person_id,
      capa_type: capaType,
    };
    console.log(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + apiUrl.step5.propsed.edit, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
        this.setState({
          priority: { extreme: false, high: false, medium: false, low: false },
          capaType: "",
        });
      });
  };
  makeFilterList = () => {
    this.manageDataGatheringParams("filterParams", {
      isLoading: true,
    });
    const user_id = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const isPropsed = this.state.currentTab === "prop" ? 0 : 1;
    const { extremeSelect, highSelect, mediumSelect, lowSelect } = this.state;
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      ira_id,
      category: this.state.dataGathering.filterParams.category[0]?.id ?? "",
      recommendation_by:
        this.state.dataGathering.filterParams.recommendation_by[0]?.id ?? "",
      responsible_person_id:
        this.state.dataGathering.filterParams.responsible_person_id[0]?.id ??
        "",
      // startDate: this.state.dataGathering.filterParams.startDate,
      // endDate: this.state.dataGathering.filterParams.endDate,
      startDate:
        this.state.dataGathering.filterParams.startDate !== null
          ? moment(this.state.dataGathering.filterParams.startDate).format(
              "YYYY-MM-DD"
            )
          : "",
      endDate:
        this.state.dataGathering.filterParams.endDate !== null
          ? moment(this.state.dataGathering.filterParams.endDate).format(
              "YYYY-MM-DD"
            )
          : "",
      list_type: isPropsed,
      page_no: 0,
      priority: [
        extremeSelect ? extremeSelect : undefined,
        highSelect ? highSelect : undefined,
        mediumSelect ? mediumSelect : undefined,
        lowSelect ? lowSelect : undefined,
      ],
    };
    axios
      .post(
        Url.clientURL + apiUrl.step5.filter,
        qs.stringify(data, { arrayFormat: "repeat" }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            iraDetailStep5: {
              ...this.state.iraDetailStep5,
              [isPropsed === 0
                ? "praposed_corrective_action"
                : "final_corrective_action"]: res.data.data.recommendationlist,
            },
          });
        } else if (res.data.status === 500) {
          AlertMessage({
            type: "error",
            message: "Something is wrong ",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.manageDataGatheringParams("filterParams", {
          isLoading: false,
          isFilterEnable: false,
        });
      });
  };
  generateTheIdProof = (e, category) => {
    const lowStorage = this.checkLowStorage();
    const { files } = e.target;
    const { name } = files[0];
    lowStorage
      .then(async (response) => {
        const { status, msg } = response;

        if (status == 200) {
          toDataURL(files[0], async (url) => {
            const content = this.state.identity_proof_base64.concat(url);
            await this.setState({ identity_proof_base64: content });
            const { editId, identity_proof } =
              this.state.dataGathering.investigation;
            let data = {
              proof_data: "file://" + url,
              proof: this.state.identity_proof_base64,
              name: name,
              category,
            };
            if (editId !== "") {
              data = {
                ...data,
                proof_id: identity_proof[0]?.proof_id,
              };
            }
            this.setState({
              dataGathering: {
                ...this.state.dataGathering,
                investigation: {
                  ...this.state.dataGathering.investigation,
                  identity_proof: [
                    ...this.state.dataGathering.investigation.identity_proof,
                    data,
                  ],
                },
              },
            });
          });
        } else {
          await this.setState({
            lowStorageMessage: msg,
            showLowStoragePopup: status == 200 ? false : true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterQuestionDropDown = (state) => {};
  manageQuestion = (res = "", arr = [], idx) => {
    const content = Array.from(arr);
    content[idx]["response"] = res;
    this.setState({
      questionArray: content,
    });
  };
  saveIntialInvestigation = () => {
    const hasErrors = this.validateStep2Fields();
    if (hasErrors.length) {
      return false;
    }
    this.manageDataGatheringParams("investigation", {
      isLoading: true,
    });
    const userId = localStorage.getItem("userId");
    let auth_key = localStorage.getItem("token");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;

    const {
      employee_contractor_status,
      injuredPersonType,
      injured_person_name,
      contractor_company_name,
      identity_proof,
      joining_date,
      month_with_this_emp,
      year_with_this_emp,
      month_doing_this_job,
      year_doing_this_job,
      time_of_incident,
      working_shift,
      injured_parts,
      editId,
      person_work_type,
      injured_person_age,
      proofType,
      injured_person_gender,
      injured_type_id,
    } = this.state.dataGathering.investigation ?? {};
    const isEditEnabled = editId !== "";
    const injured_person_details = {
      employee_contractor_status,
      injured_person_name: isEditEnabled
        ? injured_person_name
        : injuredPersonType === "other"
        ? injured_person_name
        : injuredPersonType,
      injured_person_age,
      injured_person_gender: injured_person_gender?.length
        ? injured_person_gender[0]?.label
        : "",
      injured_type_id: injured_type_id?.length ? injured_type_id[0]?.id : "",
      contractor_company_name,
      identity_proof: identity_proof.filter((el) => {
        if (Object.hasOwn(el, "name")) {
          return {
            ...el,
          };
        }
      })[0],
      person_work_type: person_work_type?.length ? person_work_type[0].id : "",
      joining_date: moment(joining_date, "YYYY-MM-DD").format("YYYY-MM-DD"),
      month_with_this_emp: month_with_this_emp?.length
        ? month_with_this_emp[0]?.id
        : "",
      year_with_this_emp: year_with_this_emp?.length
        ? year_with_this_emp[0]?.id
        : "",
      month_doing_this_job: month_doing_this_job?.length
        ? month_doing_this_job[0]?.id
        : "",
      year_doing_this_job: year_doing_this_job?.length
        ? year_doing_this_job[0]?.id
        : "",
      time_of_incident: time_of_incident?.length ? time_of_incident[0]?.id : "",
      working_shift: working_shift?.length ? working_shift[0]?.id : "",
      injured_parts: injured_parts?.map((el) => {
        return { injured_parts_id: el.id };
      }),
    };
    const data = {
      auth_key,
      userId,
      service_id,
      project_id,
      ira_id,
      injured_person_details,
      injured_person_id: editId,
      incident_analysis_questions: this.state.questionArray.map((el) => {
        return {
          question_id: el.question_id,
          response: el.response,
        };
      }),
    };
    if (!isEditEnabled) {
      delete data.injured_person_id;
    }

    fetch(Url.clientURL + apiUrl.step2[isEditEnabled ? "edit" : "add"], {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          AlertMessage({
            type: "success",
            message: `Record ${
              isEditEnabled ? "updated" : "created"
            } Successfully`,
          });
          this.iraDetails();
        } else if (responseJson.status === 500 || 401) {
          AlertMessage({
            type: "error",
            message: "An error occurred",
          });
        }
        this.iraDetails();
      })
      .catch(() => {
        AlertMessage({
          type: "error",
          message: "An error occurred",
        });
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  insertChronology = () => {
    this.manageDataGatheringParams("chronology", {
      isLoading: true,
    });
    const userKey = localStorage.getItem("userId");
    let auth_key = localStorage.getItem("token");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const { date, description, info_source, dateTimeOf, isEdit } =
      this.state.dataGathering.chronology ?? {};
    const isEditEnabled = isEdit !== "";
    const keys = {
      date: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
      time: moment(dateTimeOf).format("HH:mm"),
      description,
      info_source,
    };
    const data = {
      auth_key,
      [isEditEnabled ? "userId" : "user_id"]: userKey,
      service_id,
      project_id,
      ira_id,
      chronology_of_events: [keys],
      ...(!isEditEnabled && {
        ...keys,
      }),
      event_id: isEditEnabled ? isEdit : undefined,
    };
    if (!isEditEnabled) {
      delete data.event_id;
      delete data.chronology_of_events;
    }

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(
      Url.clientURL + apiUrl.step4.chronology[isEditEnabled ? "edit" : "add"],
      {
        method: "post",
        headers: {
          "Content-Type": isEditEnabled
            ? "application/json"
            : "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: isEditEnabled ? JSON.stringify(data) : formBody,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          window.location.reload();
          this.iraDetails();
        }
      })
      .catch((error) => {
        //throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };
  fetchRootCauseParameters = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + apiUrl.step4.rootCause.parameterList, {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.status == 200) {
            this.setState({
              masterList: responseJson.data,
              main_category: responseJson.data.main_category,
              root_cause_type: responseJson.data.root_cause_type,
            });
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  generateCommonParams = () => {
    return {
      auth_key: localStorage.getItem("token"),
      user_id: localStorage.getItem("userId"),
      ira_id: this.state.iraId,
      project_id: this.state.iraBasicInfo.project_id,
      service_id: this.state.iraBasicInfo.service_id,
    };
  };
  addProbableCause = () => {
    this.manageDataGatheringParams("rootCause", {
      isLoading: true,
    });
    const { description, probable_cause, editId } =
      this.state.dataGathering.rootCause ?? {};
    const isEditEnabled = editId !== "";
    let data = {
      ...this.generateCommonParams(),
      description,
      probable_cause,
      probable_cause_id: editId,
    };
    if (!isEditEnabled) {
      delete data.probable_cause_id;
    }
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + apiUrl.step4.rootCause.addPobableCause, {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  generateValue = (arr = [], idx, val = {}) => {
    const elements = [...arr];
    elements[idx] = val[0];
    return elements;
  };

  // __parseChild = (arr = [], moduleName = "", parentId, idKey) => {
  //   const element = [];
  //   forEach((el) => {

  //   })
  // }
  getMajorCauseList = (masterArr = [], rootCause = []) => {
    const data = [];
    masterArr.filter((el) => {
      if (
        el.main_category_id.toString() === rootCause?.main_category[0]["id"]
      ) {
        el.sub_category.filter((sub) => {
          if (
            sub.sub_category_id.toString() === rootCause?.main_category[1]["id"]
          ) {
            data.push(sub);
          }
        });
      }
    });
    if (data.length) {
      return this.generateDropdownParameter(
        data[0]["major_root_cause_category"],
        nestedListParser.major_root_cause_category
      );
    }
  };
  getNearCauseList = (masterArr = [], rootCause = []) => {
    const data = [];
    masterArr.filter((el) => {
      if (
        el.main_category_id.toString() === rootCause?.main_category[0]["id"]
      ) {
        el.sub_category.filter((sub) => {
          if (
            sub.sub_category_id.toString() === rootCause?.main_category[1]["id"]
          ) {
            sub.major_root_cause_category.filter((third) => {
              if (
                third.major_root_cause_category_id.toString() ===
                rootCause?.main_category[2]["id"]
              ) {
                data.push(third);
              }
            });
          }
        });
      }
    });
    if (data.length) {
      return this.generateDropdownParameter(
        data[0]["near_root_cause_category"],
        nestedListParser.near_root_cause_category
      );
    }
  };
  getImmediateCauseList = (masterArr = [], rootCause = []) => {
    const data = [];
    masterArr.filter((el) => {
      if (
        el.main_category_id.toString() === rootCause?.main_category[0]["id"]
      ) {
        el.sub_category.filter((sub) => {
          if (
            sub.sub_category_id.toString() === rootCause?.main_category[1]["id"]
          ) {
            sub.major_root_cause_category.filter((third) => {
              if (
                third.major_root_cause_category_id.toString() ===
                rootCause?.main_category[2]["id"]
              ) {
                third.near_root_cause_category.filter((fourth) => {
                  if (
                    fourth.near_root_cause_category_id.toString() ===
                    rootCause?.main_category[3]["id"]
                  ) {
                    data.push(fourth);
                  }
                });
              }
            });
          }
        });
      }
    });
    if (data.length) {
      return this.generateDropdownParameter(
        data[0]["intermediate_cause_category"],
        nestedListParser.intermediate_cause_category
      );
    }
  };
  addRootCause = () => {
    this.manageDataGatheringParams("rootCause", {
      isLoading: true,
    });
    const { contributing_factor, editId } =
      this.state.dataGathering.rootCause ?? {};
    const isEditEnabled = editId !== "";
    let tempCat = [...this.state.main_category];

    const mainCat = tempCat
      .filter((item) => item.isChecked)
      .map((item) => {
        return {
          main_category_id: item.main_category_id,
          sub_category: item.sub_category
            .filter((subItem) => subItem.isChecked)
            .map((subItem) => {
              return {
                sub_category_id: subItem.sub_category_id,
                major_root_cause_category: subItem.major_root_cause_category
                  .filter((majorItem) => majorItem.isChecked)
                  .map((majorItem) => {
                    return {
                      major_root_cause_category_id:
                        majorItem.major_root_cause_category_id,
                      near_root_cause_category:
                        majorItem.near_root_cause_category
                          .filter((nearItem) => nearItem.isChecked)
                          .map((nearItem) => {
                            return {
                              near_root_cause_category_id:
                                nearItem.near_root_cause_category_id,
                              intermediate_cause_category:
                                nearItem.intermediate_cause_category
                                  .filter((interItem) => interItem.isChecked)
                                  .map((interItem) => {
                                    return {
                                      intermediate_cause_category_id:
                                        interItem.intermediate_cause_category_id,
                                    };
                                  }),
                            };
                          }),
                    };
                  }),
              };
            }),
        };
      });

    // main route cause
    let tempRoot = [...this.state.root_cause_type];

    const mainRoot = tempRoot
      .filter((item) => item.isChecked)
      .map((item) => {
        return {
          root_cause_type_id: item.root_cause_type_id,
          root_cause: item.root_cause
            .filter((subItem) => subItem.isChecked)
            .map((subItem) => {
              return {
                root_cause_id: subItem.root_cause_id,
              };
            }),
        };
      });
    let data = {
      ...this.generateCommonParams(),
      userId: localStorage.getItem("userId"),
      main_category: mainCat,
      root_cause_type: mainRoot,
      contributing_factor,
    };
    // if (!isEditEnabled) {
    //   // delete data.user_id
    // } else {
    //   delete data.userId
    // }

    fetch(
      Url.clientURL +
        apiUrl.step4.rootCause[
          isEditEnabled ? "editRouteCause" : "addRouteCause"
        ],
      {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };
  getChildren = (obj) => {
    const data = [];
    const element = Object.entries(obj).map((el) => {
      if (typeof el[1] === "object") {
        data.push(el[1]);
      }
    });
    return data[0];
  };
  renderNestedPart = (data = []) => {
    if (!data.length) {
      return;
    }
    const parameters = Object.keys(nestedListParser);
    return Object.entries(data)?.map((el) => {
      return Object.entries(el[1]).map((sub) => {
        const [key, value] = sub;
        const children = this.getChildren(el[1]) ?? {};
        if (parameters.includes(key) && typeof value !== "object") {
          return (
            <TreeNode
              label={
                <StyledNode>
                  {" "}
                  <div className="d-flex align-items-center">
                    <Book className="mr-3 text-danger" />{" "}
                    <span className="slds-form-element__label  font-weight-500">
                      {" "}
                      {value}{" "}
                    </span>
                  </div>
                </StyledNode>
              }
            >
              {children?.length ? this.renderNestedPart(children) : null}
            </TreeNode>
          );
        }
      });
    });
  };
  renderTree = (menu, rootName = "root", isRoot = false) => {
    if (Object.keys(menu).length > 2 && !this.state.dataLoading) {
      const { probable_cause, contributing_factor } = menu ?? {};
      const { main_category, root_cause_type } = contributing_factor ?? {};
      return (
        <Tree
          lineHeight={"30px"}
          nodePadding={"20px"}
          lineWidth={"2px"}
          lineColor={"#bbc"}
          lineBorderRadius={"12px"}
          label={
            <StyledNode>
              {rootName !== "" ? "Root Cause" : probable_cause?.probable_cause}
            </StyledNode>
          }
        >
          {this.renderNestedPart(isRoot ? main_category : root_cause_type)}
        </Tree>
      );
    }
  };
  _toggleMainCategoryCheck = async (val) => {
    let factorIndex = this.state.main_category.findIndex(
      (item) => item.main_category_id === val
    );

    await this.setState((prevState) => {
      let { main_category } = prevState;
      let tempMainCat = { ...main_category[factorIndex] };
      tempMainCat.isChecked = !tempMainCat.isChecked;
      main_category[factorIndex] = tempMainCat;
      return { main_category };
    });
  };
  _toggleSubCategoryCheck = async (mainCatId, subCatId) => {
    let mainIndex = this.state.main_category.findIndex(
      (item) => item.main_category_id === mainCatId
    );

    await this.setState((prevState) => {
      let { main_category, majorRouteCauseArr } = prevState;
      let tempMainCat = { ...main_category[mainIndex] };

      let subIndex = tempMainCat.sub_category.findIndex(
        (item) => item.sub_category_id === subCatId
      );
      let tempSubCat = { ...tempMainCat.sub_category[subIndex] };

      tempSubCat.isChecked = !tempSubCat.isChecked;

      tempMainCat.sub_category[subIndex] = tempSubCat;

      main_category[mainIndex] = tempMainCat;

      return { main_category };
    });
  };
  _toggleMajorCategoryCheck = async (mainCatId, subCatId, majorCatId) => {
    let mainIndex = this.state.main_category.findIndex(
      (item) => item.main_category_id === mainCatId
    );

    await this.setState((prevState) => {
      let { main_category } = prevState;

      let tempMainCat = { ...main_category[mainIndex] };

      let subIndex = tempMainCat.sub_category.findIndex(
        (item) => item.sub_category_id === subCatId
      );
      let tempSubCat = { ...tempMainCat.sub_category[subIndex] };
      let majorIndex = tempSubCat.major_root_cause_category.findIndex(
        (item) => item.major_root_cause_category_id === majorCatId
      );
      let tempMajorCat = {
        ...tempSubCat.major_root_cause_category[majorIndex],
      };
      tempMajorCat.isChecked = !tempMajorCat.isChecked;

      tempSubCat.major_root_cause_category[majorIndex] = tempMajorCat;
      tempMainCat.sub_category[subIndex] = tempSubCat;
      main_category[mainIndex] = tempMainCat;

      // Activating Near route cause
      const tempNew = main_category[mainIndex].sub_category.filter(
        (item) => item.isChecked
      );
      let showNear = false;
      tempNew.forEach((subItem) => {
        const tempArr = subItem.major_root_cause_category.filter(
          (majorItem) => majorItem.isChecked
        );
        if (tempArr.length > 0) {
          showNear = true;
          return;
        }
      });

      let tempNewMainCat = { ...main_category[mainIndex] };
      tempNewMainCat.showNearCouse = showNear;
      main_category[mainIndex] = tempNewMainCat;

      return { main_category };
    });
  };
  _toggleNearCategoryCheck = async (
    mainCatId,
    subCatId,
    majorCatId,
    NearCatId
  ) => {
    let mainIndex = this.state.main_category.findIndex(
      (item) => item.main_category_id === mainCatId
    );

    await this.setState((prevState) => {
      let { main_category } = prevState;
      let tempMainCat = { ...main_category[mainIndex] };

      // Accessing sub category
      let subIndex = tempMainCat.sub_category.findIndex(
        (item) => item.sub_category_id === subCatId
      );
      let tempSubCat = { ...tempMainCat.sub_category[subIndex] };

      // Accessing major cause
      let majorIndex = tempSubCat.major_root_cause_category.findIndex(
        (item) => item.major_root_cause_category_id === majorCatId
      );
      let tempMajorCat = {
        ...tempSubCat.major_root_cause_category[majorIndex],
      };

      // Accessing near cause
      let nearIndex = tempMajorCat.near_root_cause_category.findIndex(
        (item) => item.near_root_cause_category_id === NearCatId
      );
      let tempNearCat = { ...tempMajorCat.near_root_cause_category[nearIndex] };

      tempNearCat.isChecked = !tempNearCat.isChecked;

      tempMajorCat.near_root_cause_category[nearIndex] = tempNearCat;
      tempSubCat.major_root_cause_category[majorIndex] = tempMajorCat;
      tempMainCat.sub_category[subIndex] = tempSubCat;
      main_category[mainIndex] = tempMainCat;

      //Activating Intermediate route cause ( need check if at least one checkbox is checked then show intermediate causes)
      const tempSub = main_category[mainIndex].sub_category.filter(
        (item) => item.isChecked
      );
      let showInter = false;

      tempSub.forEach((subItem) => {
        const tempMajor = subItem.major_root_cause_category.filter(
          (majorItem) => majorItem.isChecked
        );

        tempMajor.forEach((subMajor) => {
          const tempNear = subMajor.near_root_cause_category.filter(
            (nearItem) => nearItem.isChecked
          );
          if (tempNear.length > 0) {
            showInter = true;
            return;
          }
        });
      });

      // Setting hide/show intercause
      let tempNewMainCat = { ...main_category[mainIndex] };
      tempNewMainCat.showInterCouse = showInter;
      main_category[mainIndex] = tempNewMainCat;

      return { main_category };
    });
  };
  _toggleInterCategoryCheck = async (
    mainCatId,
    subCatId,
    majorCatId,
    nearCatId,
    interCatID
  ) => {
    let mainIndex = this.state.main_category.findIndex(
      (item) => item.main_category_id === mainCatId
    );

    await this.setState((prevState) => {
      let { main_category, intermediateRouteCause } = prevState;
      let tempMainCat = { ...main_category[mainIndex] };

      // Accessing sub category
      let subIndex = tempMainCat.sub_category.findIndex(
        (item) => item.sub_category_id === subCatId
      );
      let tempSubCat = { ...tempMainCat.sub_category[subIndex] };

      // Accessing major cause
      let majorIndex = tempSubCat.major_root_cause_category.findIndex(
        (item) => item.major_root_cause_category_id === majorCatId
      );
      let tempMajorCat = {
        ...tempSubCat.major_root_cause_category[majorIndex],
      };

      // Accessing near cause
      let nearIndex = tempMajorCat.near_root_cause_category.findIndex(
        (item) => item.near_root_cause_category_id === nearCatId
      );
      let tempNearCat = { ...tempMajorCat.near_root_cause_category[nearIndex] };

      // Accessing inter cause
      let interIndex = tempNearCat.intermediate_cause_category.findIndex(
        (item) => item.intermediate_cause_category_id === interCatID
      );
      let tempInterCat = {
        ...tempNearCat.intermediate_cause_category[interIndex],
      };

      tempInterCat.isChecked = !tempInterCat.isChecked;

      tempNearCat.intermediate_cause_category[interIndex] = tempInterCat;
      tempMajorCat.near_root_cause_category[nearIndex] = tempNearCat;
      tempSubCat.major_root_cause_category[majorIndex] = tempMajorCat;
      tempMainCat.sub_category[subIndex] = tempSubCat;
      main_category[mainIndex] = tempMainCat;

      let showRouteCause = false;

      //Activating Intermediate route cause ( need check if at least one checkbox is checked then show intermediate causes)
      main_category.forEach((mainItem) => {
        const tempSub = mainItem.sub_category.filter((item) => item.isChecked);

        tempSub.forEach((subItem) => {
          const tempMajor = subItem.major_root_cause_category.filter(
            (majorItem) => majorItem.isChecked
          );

          tempMajor.forEach((subMajor) => {
            const tempNear = subMajor.near_root_cause_category.filter(
              (nearItem) => nearItem.isChecked
            );

            tempNear.forEach((nearItem) => {
              const tempInter = nearItem.intermediate_cause_category.filter(
                (interItem) => interItem.isChecked
              );

              if (tempInter.length > 0) {
                showRouteCause = true;
                return;
              }
            });
          });
        });
      });

      intermediateRouteCause = showRouteCause;
      return { main_category, intermediateRouteCause };
    });
  };
  _toggleRootCauseTypeCheck = async (typeId) => {
    let typeIndex = this.state.root_cause_type.findIndex(
      (item) => item.root_cause_type_id === typeId
    );

    await this.setState((prevState) => {
      let { root_cause_type } = prevState;
      let typeCause = { ...root_cause_type[typeIndex] };
      typeCause.isChecked = !typeCause.isChecked;
      root_cause_type[typeIndex] = typeCause;
      return { root_cause_type };
    });
  };
  _toggleRootCauseCheck = async (typeId, causeId) => {
    let typeIndex = this.state.root_cause_type.findIndex(
      (item) => item.root_cause_type_id === typeId
    );

    await this.setState((prevState) => {
      let { root_cause_type } = prevState;
      let typeCause = { ...root_cause_type[typeIndex] };
      let causeIndex = typeCause.root_cause.findIndex(
        (item) => item.root_cause_id === causeId
      );
      let tempCause = { ...typeCause.root_cause[causeIndex] };
      tempCause.isChecked = !tempCause.isChecked;
      typeCause.root_cause[causeIndex] = tempCause;
      root_cause_type[typeIndex] = typeCause;
      return { root_cause_type };
    });
  };
  fetchReturningList = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
      page_no: 1,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/irareturntowork", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.status == 200) {
            this.setState({
              returnToWorkList: this.generateDropdownParameter(
                responseJson.data.return_to_work,
                {
                  id: "id",
                  label: "return_to_work_info",
                }
              ),
            });
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchGenderList = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
      page_no: 1,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iradetailsgenderlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.status == 200) {
            this.setState({
              genderList: this.generateDropdownParameter(
                responseJson.data.iraGenderList,
                {
                  id: "id",
                  label: "ira_gender_info",
                }
              ),
            });
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchInjuryTypeList = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
      page_no: 1,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iradetailsinjurytypelist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.status == 200) {
            this.setState({
              injurtType: this.generateDropdownParameter(
                responseJson.data.injuryTypeList,
                {
                  id: "id",
                  label: "injury_type_info",
                }
              ),
            });
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  imageToBinary = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsBinaryString(file);
    });
  };
  insertCost = async () => {
    this.manageDataGatheringParams("cost", {
      isLoading: true,
    });
    const { mandays_lost, cost_involved, return_to_work_id, files, editId } =
      this.state.dataGathering.cost ?? {};
    const isEditEnabled = editId !== undefined;

    const user_id = localStorage.getItem("userId");
    const auth_key = localStorage.getItem("token");

    let uploadData = new FormData();
    // const file = await this.imageToBinary(files[0].file);

    uploadData.append("auth_key", auth_key);
    uploadData.append("user_id", user_id);
    uploadData.append("ira_id", this.state.iraId);
    uploadData.append("project_id", this.state.iraBasicInfo.project_id);
    uploadData.append("service_id", this.state.iraBasicInfo.service_id);
    uploadData.append(
      isEditEnabled ? "edited_mandays_lost" : "mandays_lost",
      mandays_lost
    );
    uploadData.append(
      isEditEnabled ? "edited_cost_involved" : "cost_involved",
      cost_involved
    );
    uploadData.append(
      isEditEnabled ? "edited_return_to_work_id" : "return_to_work_id",
      return_to_work_id[0]["id"]
    );
    if (isEditEnabled) {
      uploadData.append("ira_reported_cost_detail_id", editId);
    }
    uploadData.append("file", files);
    const basePath = isEditEnabled
      ? "/listira/step3editcost"
      : "/listira/step3addcost";
    axios
      .post(Url.clientURL + basePath, uploadData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.status == 200) {
            this.iraDetails();
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
    // fetch(Url.clientURL + "/listira/step3addcost", {
    //   method: 'post',
    //   body: uploadData,
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   },
    // })
    //   .then(response => response.json())
    //   .then(responseJson => {

    //   })
    //   .catch(error => {
    //     throw error;
    //   }).finally(() => {
    //     this.manageDataGatheringParams("", {
    //     }, true);
    //   })
  };
  addOtherInfo = async () => {
    this.manageDataGatheringParams("analysis", {
      isLoading: true,
    });
    const { editId } = this.state.dataGathering.analysis;

    const isEditEnabled = editId !== "";
    let OtherInfomasterlist = this.state.OtherInfomasterlist.map(function (o) {
      let q = {
        questions_id: o.questions_id,
        response: o.response === null ? 0 : o.response,
        notes: o.notes,
      };
      return q;
    });
    let databody = {
      ...this.generateCommonParams(),
      userId: localStorage.getItem("userId"),
      other_info: OtherInfomasterlist,
    };
    const editPayload = {
      ...this.generateCommonParams(),
      other_info_master_id: editId,
      response: OtherInfomasterlist[0]?.response,
      notes: OtherInfomasterlist[0]?.notes,
    };
    let formBody = [];
    for (var property in editPayload) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(editPayload[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const content_type = !isEditEnabled
      ? "application/json"
      : "application/x-www-form-urlencoded";
    const url = isEditEnabled
      ? "/listira/step3editotherinfo"
      : "/listira/step3otherinfo";
    fetch(Url.clientURL + url, {
      method: "post",
      body: isEditEnabled ? formBody : JSON.stringify(databody),
      headers: {
        "Content-Type": content_type,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.iraDetails();
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  deleteRecords = async () => {
    const { parameters, stepNo, url } = this.state.deleteParams;

    this.manageDataGatheringParams(
      "",
      {
        isLoading: true,
      },
      false,
      "deleteParams"
    );
    const details = {
      ...this.generateCommonParams(),
      ...parameters,
    };
    let formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const prefixPath = url !== "" ? url : apiUrl[stepNo]?.delete;
    fetch(Url.clientURL + prefixPath, {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (refreshUrls.includes(url)) {
          window.location.reload();
          return;
        }
        this.iraDetails();
      })
      .finally(() => {
        if (stepNo === "step1") {
          this.resetIntialParams();
        }

        this.manageDataGatheringParams(
          "",
          {
            ...intialValue.deleteParams,
          },
          false,
          "deleteParams"
        );
      });
  };
  manageWitnessPayload = (el) => {
    this.manageDataGatheringParams("witness", {
      isEdit: el?.witness_id,
      isAddEnable: true,
      employee_contractor_status: el?.employee_contractor_status,
      contractor_company: el?.contractor_company,
      witness_name: el?.witness_name,
      witness_statement: el?.witness_statement,
      files: [el?.witness_pic[0]["witness_pic"]],
      witness_evidence_id: el?.witness_pic[0]?.pic_id,
    });
  };
  manageChoronologyPayload = (el) => {
    const { date, time, description, info_source } = el;
    this.manageDataGatheringParams("chronology", {
      isEdit: el?.id,
      isAddEnable: true,
      date: _momentDate(date),
      time,
      description,
      info_source,
    });
  };
  manageRootCausePayload = (el) => {
    const { date, time, description, info_source } = el;
    this.manageDataGatheringParams("chronology", {
      isEdit: el?.id,
      isAddEnable: true,
      date: _momentDate(date),
      time,
      description,
      info_source,
    });
  };
  // Set intial value i.e existing values
  _intialValue = async (data) => {
    const tempData = [...data.contributing_factor.main_category];
    const tempRootData = [...data.contributing_factor.root_cause_type];

    // Main category values
    if (tempData.length > 0) {
      // main categories
      tempData.forEach(async (item) => {
        await this._toggleMainCategoryCheck(item.main_category_id);

        // Sub categories
        if (item.sub_category && item.sub_category.length) {
          item.sub_category.forEach(async (subItem) => {
            await this._toggleSubCategoryCheck(
              item.main_category_id,
              subItem.sub_category_id
            );

            // major categories Array
            if (
              subItem.major_root_cause_category &&
              subItem.major_root_cause_category.length
            ) {
              subItem.major_root_cause_category.forEach(async (majorItem) => {
                await this._toggleMajorCategoryCheck(
                  item.main_category_id,
                  subItem.sub_category_id,
                  majorItem.major_root_cause_category_id
                );

                // Near root categories Array
                if (
                  majorItem.near_root_cause_category &&
                  majorItem.near_root_cause_category.length
                ) {
                  majorItem.near_root_cause_category.forEach(
                    async (nearItem) => {
                      await this._toggleNearCategoryCheck(
                        item.main_category_id,
                        subItem.sub_category_id,
                        majorItem.major_root_cause_category_id,
                        nearItem.near_root_cause_category_id
                      );

                      // Intermediate Array
                      if (
                        nearItem.intermediate_cause_category &&
                        nearItem.intermediate_cause_category.length
                      ) {
                        nearItem.intermediate_cause_category.forEach(
                          async (interItem) => {
                            await this._toggleInterCategoryCheck(
                              item.main_category_id,
                              subItem.sub_category_id,
                              majorItem.major_root_cause_category_id,
                              nearItem.near_root_cause_category_id,
                              interItem.intermediate_cause_category_id
                            );
                          }
                        );
                      }
                    }
                  );
                }
              });
            }
          });
        }
      });
    }

    // Root cause type values
    if (tempRootData.length > 0) {
      // Root cause type
      tempRootData.forEach(async (typeItem) => {
        await this._toggleRootCauseTypeCheck(typeItem.root_cause_type_id);
        // Root cause
        if (typeItem.root_cause && typeItem.root_cause.length) {
          typeItem.root_cause.forEach(async (rootItem) => {
            await this._toggleRootCauseCheck(
              typeItem.root_cause_type_id,
              rootItem.root_cause_id
            );
          });
        }
      });
    }
    this.manageDataGatheringParams("rootCause", {
      isAddEnable: true,
      editId: data.probable_cause.id,
      contributing_factor: data.contributing_factor.contributing_factor,
      probable_cause: data?.probable_cause?.probable_cause,
      description: data?.probable_cause?.description,
    });
  };
  mapInvesigationParams = (el) => {
    const injurtParts = el.joining_details.injured_parts?.map((el) =>
      el?.id?.toString()
    );
    this.manageDataGatheringParams("investigation", {
      isLoading: false,
      isImageProcessing: false,
      editId: el?.injured_person_id,
      proofType: el?.identity_proof?.length
        ? this.state.iraidentityProof.filter(
            (sub) => sub.id === el?.identity_proof[0]["category_id"].toString()
          )
        : [],
      isAddEnable: true,
      injured_person_age: el?.injured_person_age,
      injuredPersonType: this.state.injuredPersonList.length
        ? this.state.injuredPersonList?.filter(
            (sub) => sub.label === el?.injured_person_name
          )
        : "other",
      employee_contractor_status: el?.employee_type === "Employee" ? 1 : 2,
      injured_person_name: el?.injured_person_name ?? "",
      contractor_company_name: el?.contractor_company ?? "",
      OtherInfomasterlist: [],
      identity_proof: el?.identity_proof ?? [],
      person_work_type: this.state.iraWorkType.filter(
        (sub) => sub.id === el.person_work_type_id.toString()
      ),
      joining_date: _momentDate(el?.joining_date),
      month_with_this_emp: this.generateDropdownParameter(
        month.filter(
          (sub) => sub.id === el?.joining_details?.company_exp_month
        ),
        {
          id: "id",
          label: "name",
        }
      ),
      year_with_this_emp: this.generateDropdownParameter(
        YearSelect.filter(
          (sub) => sub.id === el?.joining_details?.company_exp_year
        ),
        {
          id: "id",
          label: "name",
        }
      ),
      month_doing_this_job: this.generateDropdownParameter(
        month.filter(
          (sub) => sub.id === el?.joining_details?.overall_company_exp_month
        ),
        {
          id: "id",
          label: "name",
        }
      ),
      year_doing_this_job: this.generateDropdownParameter(
        YearSelect.filter(
          (sub) => sub.id === el?.joining_details?.overall_company_exp_year
        ),
        {
          id: "id",
          label: "name",
        }
      ),
      injured_person_gender: this.state.genderList.filter(
        (sub) => sub.label === el?.injured_person_gender
      ),
      injured_type_id: this.state.injurtType.filter(
        (sub) => sub.label === el?.injury_type
      ),
      time_of_incident: this.state.iraTimeOfIncident.filter(
        (sub) => sub.id === el.joining_details.time_of_incident_id.toString()
      ),
      working_shift: this.state.workingShift.filter(
        (sub) => sub.id === el.joining_details.working_shift_id.toString()
      ),
      injured_parts: this.state.iraInjuredParts.filter((sub) => {
        if (injurtParts.includes(sub.id)) {
          return sub;
        }
      }),
    });
    this.setState({
      questionArray: el?.incident_analysis_question,
    });
  };
  manageCostPayload = (el) => {
    if (Object.keys(el).length) {
      this.manageDataGatheringParams("cost", {
        editId: el?.ira_cost_involved_id,
        isAddEnable: true,
        cost_involved: el?.cost_involved,
        mandays_lost: el?.mandays_lost,
        return_to_work_id: this.state.returnToWorkList.filter(
          (sub) =>
            sub.id === el.return_to_work_list[0]?.return_to_work_id.toString()
        ),
        files: [],
      });
    }
  };

  filterQuestion = (question = [], arr = []) => {
    const tempSelected = arr.map((el) => el.question_id?.toString());
    const tempArry = [];
    question.map((el) => {
      if (!tempSelected.includes(el.id)) {
        tempArry.push(el);
      }
    });
    return tempArry;
  };
  getFileName = (url = "", type = 1) => {
    if (url !== "") {
      const strArr = url.split("/");
      const fileName = strArr[strArr.length - 1];
      const fileExtension = fileName.split(".")[1];
      if (type === 1) {
        return fileName;
      } else if (type === 2) {
        return fileExtension;
      }
      return [fileName, fileExtension];
    }
  };
  getAnalyisiData = (five_m_analysis_type) => {
    this.setState({
      isAnalysisDataFetching: true,
    });
    axios
      .post(
        Url.clientURL + "/listira/fivemanalysisfactorlist",
        qs.stringify({
          ...this.generateCommonParams(),
          five_m_analysis_type,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          analysisData: res.data.data.ira5MAnalysisFactors_list.map((el) => {
            return {
              ...el,
              isActive: false,
            };
          }),
          isAnalysisDataFetching: false,
        });
      })
      .catch((err) => console.log(err));
  };
  manage5mAnalytics = (e) => {
    if (this.state.reportStatus !== "completed") {
      const el = analysisType[e.target.outerText];
      if (el) {
        this.getAnalyisiData(el);
        this.setState({
          activeAnalysisItem: el,
          activeModule: e.target.outerText,
        });
      }
    }
  };
  manage5mPayload = (id) => {
    this.setState((prev) => {
      return {
        analysisData: prev?.analysisData.filter((el) => {
          if (el.factor_id === id) {
            el.isActive = !el.isActive;
          }
          return el;
        }),
      };
    });
  };
  save5MAnalysis = (five_m_analysis_type, arr) => {
    const sub_factor_id = [];
    arr.forEach((item) => {
      if (item.isActive) {
        sub_factor_id.push(item.factor_id);
      }
    });

    const data = {
      ...this.generateCommonParams(),
      five_m_analysis_type,
      sub_factor_id,
    };
    axios
      .post(
        Url.clientURL + "/listira/add5MAnalysisfactors",
        qs.stringify(data, { arrayFormat: "repeat" }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ activeAnalysisItem: null });
          this.iraDetails();
        }
      })
      .catch((err) => console.log(err));
  };
  save5MAnalysisDesc = () => {
    const data = {
      ...this.generateCommonParams(),
      ...this.state.activePopperParams,
      description: this.state.desc,
    };
    axios
      .post(
        Url.clientURL + "/listira/adddescription5mfactors",
        qs.stringify(data, { arrayFormat: "repeat" }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          AlertMessage({
            message: "Description saved successfully",
            type: "success",
          });
          this.setState({ activePopperParams: {}, desc: "" });
          this.iraDetails();
        }
      })
      .catch((err) => {
        AlertMessage({
          message: "Description not saved ",
          type: "error",
        });
      });
  };
  update5MAnalysisDesc = () => {
    this.setState((prev) => {
      return {
        activeEditPopperParams: {
          ...prev.activeEditPopperParams,
          isLoading: true,
        },
      };
    });

    const data = {
      ...this.generateCommonParams(),
      ...this.state.activeEditPopperParams,
      description: this.state.desc,
    };
    axios
      .post(
        Url.clientURL + "/listira/editdescription5mfactors",
        qs.stringify(data, { arrayFormat: "repeat" }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          AlertMessage({
            message: "Description updated successfully",
            type: "success",
          });
          this.setState({
            activePopperParams: {},
            activeEditPopperParams: {
              isLoading: false,
              isEnable: false,
              five_m_analysis_type: "",
              sub_factor_id: null,
            },
            desc: "",
          });
          this.iraDetails();
        }
      })
      .catch((err) => {
        AlertMessage({
          message: "Description not updated ",
          type: "error",
        });
      });
  };
  getData = (obj = {}, key = "") => {
    if (Object.keys(obj).length) {
      return obj[analysisListType[key]];
    }
  };
  deleteAnalyisiData = () => {
    this.setState({
      isFactorLoading: true,
    });
    axios
      .post(
        Url.clientURL + "/listira/delete5MAnalysisfactors",
        qs.stringify({
          ...this.generateCommonParams(),
          ...this.state.factorParams,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.iraDetails();
          this.getAnalyisiData(this.state.factorParams.five_m_analysis_type);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          isFactorLoading: false,
          factorParams: {},
        });
      });
  };

  renderPopoverContent = (arr = [], isReduced = false, isEditEnabled) => {
    const minWidth = isReduced ? "9vw" : "15vw";
    return (
      <div className="">
        <Scrollbars
          style={{
            height: "5vw",
            maxWidth: "15vw",
            minWidth,
          }}
        >
          <div className=" d-flex flex-column mr-3">
            {arr?.map((el, idx) => {
              const { activePopperParams = {}, activeEditPopperParams } =
                this.state ?? {};
              const { five_m_analysis_type = "", sub_factor_id = "" } =
                activePopperParams ?? {};
              const { isLoading = false, isEnable = false } =
                activeEditPopperParams ?? {};
              {
              }
              const { factor_description = "" } = el ?? {};
              return (
                <SLDPopover
                  isOpen={
                    five_m_analysis_type === el?.analysis_type &&
                    sub_factor_id === el?.factor_id
                  }
                  body={
                    <>
                      {factor_description !== "" && !isEnable ? (
                        <div>
                          <span>{factor_description}</span>
                        </div>
                      ) : this.state.reportStatus !== "completed" ? (
                        <Textarea
                          aria-describedby="error-1"
                          id="text-area-error"
                          name="required-textarea-error"
                          label={`${isEnable ? "Update" : "Add"} Description`}
                          placeholder="Enter description"
                          value={this.state.desc}
                          disabled={
                            this.state.reportStatus === "completed" ||
                            !isEditEnabled
                          }
                          onChange={(e) =>
                            this.setState({
                              desc: e.target.value,
                            })
                          }
                        />
                      ) : (
                        "No Description Found"
                      )}
                    </>
                  }
                  key={idx}
                  footer={
                    <div className="slds-text-align_right">
                      <SLDButton
                        label={
                          factor_description === "" &&
                          this.state.reportStatus !== "completed"
                            ? "Cancel"
                            : "Close"
                        }
                        // disabled={this.state.reportStatus === "completed"}
                        onClick={() =>
                          this.debounceFunc(() => {
                            this.setState({
                              activePopperParams: {},
                              desc: "",
                              activeEditPopperParams: {
                                isLoading: false,
                                isEnable: false,
                                five_m_analysis_type: "",
                                sub_factor_id: null,
                              },
                            });
                          })
                        }
                      />
                      {factor_description === "" &&
                      this.state.reportStatus !== "completed" ? (
                        <SLDButton
                          variant="brand"
                          label="Save"
                          onClick={() =>
                            this.debounceFunc(() => {
                              this.save5MAnalysisDesc();
                            })
                          }
                          disabled={this.state.desc === ""}
                        />
                      ) : null}
                      {isEnable ? (
                        <SLDButton
                          variant="brand"
                          label={isLoading ? "Updating..." : "Update"}
                          onClick={() =>
                            this.debounceFunc(() => {
                              this.update5MAnalysisDesc();
                            })
                          }
                          disabled={this.state.desc === ""}
                        />
                      ) : null}
                      {factor_description !== "" &&
                      !isEnable &&
                      isEditEnabled ? (
                        <SLDButton
                          variant="brand"
                          label="Edit"
                          disabled={this.state.reportStatus === "completed"}
                          onClick={() =>
                            this.debounceFunc(() => {
                              this.setState({
                                activeEditPopperParams: {
                                  isEnable: true,
                                  five_m_analysis_type,
                                  sub_factor_id,
                                },
                                desc: factor_description,
                              });
                            })
                          }
                        />
                      ) : null}
                    </div>
                  }
                  heading="Description"
                  id="popover-controlled-with-footer"
                  position="overflowBoundaryElement"
                >
                  <span
                    className={`badge border p-2 font-weight-500 font-16 m-2  cursor-pointer`}
                    disabled={this.state.reportStatus === "completed"}
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.setState({
                          activePopperParams: {
                            five_m_analysis_type: el?.analysis_type,
                            sub_factor_id: el?.factor_id,
                          },
                        });
                      })
                    }
                  >
                    {el?.factor}
                  </span>
                </SLDPopover>
              );
            })}
          </div>
        </Scrollbars>
      </div>
    );
  };
  manageFishBoneChart = (fishData, obj = {}, isEditEnabled = false) => {
    let temp = {};
    Object.entries(fishData?.Excavation).forEach((el) => {
      const [key] = el;
      const activeKey = analysisContent[key];
      console.log("5m array", obj);
      if (Object.keys(obj).length) {
        const isWidthReducedEnabled = !["Management", "Machinery"].includes(
          key
        );
        temp = {
          ...temp,
          [key]: [
            this.renderPopoverContent(
              obj[activeKey],
              isWidthReducedEnabled,
              isEditEnabled
            ),
          ],
        };
      }
    });
    return {
      Excavation: {
        ...temp,
      },
    };
  };
  manageJobAnalysisEdit = (el) => {
    this.manageDataGatheringParams("analysis", {
      isAddEnable: true,
      editId: el?.questions_id,
    });
    this.setState({
      OtherInfomasterlist: [
        {
          ...el,
          response: el.response_type,
        },
      ],
    });
  };
  assetRender = (url, filType = "url") => {
    const [name, type] = this.getFileName(url, 0);
    return (
      <div style={{ width: "13rem" }}>
        <div class="slds-file slds-file_card slds-has-title">
          <figure>
            <a class="slds-file__crop">
              <span class="slds-assistive-text">Preview:</span>
              <img
                src={
                  type !== "pdf"
                    ? url
                    : require("../../../../assets/sls-icons/pdfIcon.svg")
                }
                alt="Description of the image"
              />
            </a>
            <figcaption class="slds-file__title slds-file__title_card">
              <div class="slds-media slds-media_small slds-media_center">
                <div class="slds-media__figure slds-line-height_reset user-icon mr-1">
                  <span class="slds-icon_container" title="pdf">
                    <img
                      src={require(type !== "pdf"
                        ? "../../../../assets/sls-icons/imageIcon.svg"
                        : "../../../../assets/sls-icons/pdfIcon.svg")}
                    />
                  </span>
                </div>
                <div class="slds-media__body d-flex">
                  <span class="slds-file__text slds-truncate" title="File">
                    {name}
                  </span>
                  <Eye
                    className="cursor-pointer ml-1 w-50"
                    onClick={() => {
                      this.setState({
                        docUrl: url,
                      });
                    }}
                  />
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    );
  };
  getInjuredParts = (el) => {
    const temp = [];
    const { injured_parts } = el?.joining_details;

    injured_parts.forEach((el) => {
      temp.push(el?.injured_parts?.toLowerCase());
    });
    return temp;
  };
  _removeFactor = (factorId, sub_factor_id, callBack) => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    this.setState({ isFactorLoading: true });
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id,
      project_id,
      factor_id: sub_factor_id,
      sub_factor_id: factorId,
      module_unique_id: this.state.iraId,
      module_name: "ira",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/rootcause/deletefactors", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
          this._InProgressContributingList();
          this._InProgressImmediateList();
          this._InProgressRcaList();
          this.resetFactorsState(this.toggleStep2Fun);
          this.hideModal();
        }
      })
      .finally(() => {
        callBack(sub_factor_id);
      });
  };
  manageFactors = (data) => {
    const { factorID, formType } = data ?? {};
    this.setState(
      {
        isFactorLoading: true,
      },
      this._removeFactor(factorID, formType, (id) => {
        this.setState({
          isFactorLoading: false,
          rootfactorParams: {},
        });
      })
    );
  };
  _InProgressFactorAddList = async (factorId) => {
    const { iraBasicInfo, iraId, CFList, IFList, RCAFList } = this.state;
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
  
    // Set loading state
    this.setState({ isFactorLoading: true });
  
    // Retrieve project, service, and module IDs
    const { project_id, service_id } = iraBasicInfo;
    const near_miss_id = iraId;
  
    // Map factorId to corresponding factor list
    const factorLists = {
      1: CFList?.factors_list,
      2: IFList?.factors_list,
      3: RCAFList?.factors_list,
    };
    
    const factorList = factorLists[factorId]?.filter(el => el?.checked)?.map(el => el?.factor_id) || [];
    console.log(factorList);
  
    // Prepare data for API request
    const data = {
      auth_key: token,
      user_id: userId,
      service_id,
      project_id,
      module_unique_id: near_miss_id,
      factor_id: factorId,
      sub_factor_id: factorList,
      module_name: "ira",
      custom_cf: [],
    };
  
    if (factorList.length > 0) {
      try {
        const response = await fetch(`${Url.clientURL}/rootcause/addfactors`, {
          method: "POST",
          body: qs.stringify(data, { arrayFormat: "repeat" }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        });
  
        const responseJson = await response.json();
  
        if (responseJson.status === 200) {
          console.log("Success: Status 200");
          await this.resetFactorsState(this.toggleStep2Fun);
          await this.iraDetails();
        } else {
          console.error(`Failed to add factors. Status: ${responseJson.status}, Message: ${responseJson.message}`);
        }
      } catch (error) {
        console.error("Error occurred while adding factors:", error.message || error);
      } finally {
        this.setState({ isFactorLoading: false });
      }
    } else {
      this.toggleStep2Fun();
    }
  };

  handleCheckboxChangeIF = (event) => {
    const { factors_list } = this.state?.IFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      IFList: {
        ...prevState.IFList,
        factors_list: updatedFactorsList,
      },
    }));
  };

  handleCheckboxChangeRAC = (event) => {
    const { factors_list } = this.state?.RCAFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      RCAFList: {
        ...prevState.RCAFList,
        factors_list: updatedFactorsList,
      },
    }));
  };

  handleCheckboxChange = (event) => {
    const { factors_list } = this.state?.CFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      CFList: {
        ...prevState.CFList,
        factors_list: updatedFactorsList,
      },
    }));
  };
  hideModal = () => {
    this.setState({ toggleStep2: false });
  };

  validateStep2Fields = () => {
    const { investigation } = this.state.dataGathering;
    const errorFieldNames = [];
    if (investigation?.editId === "") {
      if (
        investigation.proofType === "" ||
        investigation.identity_proof.length === 0
      ) {
        errorFieldNames.push("isIdProofTypeError");
        this.setState({
          stepValidations: {
            ...this.state.stepValidations,
            isIdProofTypeError: investigation.proofType === "",
            isIdProofDocumentError: investigation.identity_proof.length === 0,
          },
        });
      } else {
        this.setState({
          stepValidations: {
            isIdProofTypeError: false,
            isIdProofDocumentError: false,
          },
        });
      }
    }

    return errorFieldNames;
  };
  fetchUsersList = async (ids = []) => {
    let databody = new FormData();
    const userList = ids.filter((el) => {
      return !Number.isNaN(el);
    });
    if (!userList.length) {
      return false;
    }
    databody.append("auth_key", localStorage.getItem("token"));
    databody.append("user_id", localStorage.getItem("userId"));
    databody.append("user_id_list", userList);
    await axios
      .post(Url.usersList, databody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLists: response.data?.listUserProfileDetailsData,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  FilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ extremeSelect: 4, all: 0 });
      } else {
        this.setState({ extremeSelect: 0, all: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ highSelect: 3, all: 0 });
      } else {
        this.setState({ highSelect: 0, all: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ mediumSelect: 2, all: 0 });
      } else {
        this.setState({ mediumSelect: 0, all: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ lowSelect: 1, all: 0 });
      } else {
        this.setState({ lowSelect: 0, all: 0 });
      }
    }
  }

  CountDoughnut = (data) => {
    return data.length > 0 ? (
      <div style={{ justifyContent: "center" }}>
        <UaUcGraph
          title=""
          graphData={data}
          list={false}
          height={75}
          cutout={"55%"}
          radius={"100%"}
        />
      </div>
    ) : null;
  };
  debounceFunc = _.debounce(function (callback) {
    callback();
  }, 300);
  render() {
    var reportData = this.state.iraDetail;
    var sourcesList = [];
    var sourcesType = [];
    var inputMessageFlag = 0;
    const isCompleted = this.state.reportStatus === "completed";
    const isInprogress = this.state.reportStatus === "progress";
    const isRootCauseTabsDisabled = !_.isUndefined(
      this.state.iraDetail?.step_4_root_cause_option_selected
    );
    console.log("isRootCauseTabsDisabled", isRootCauseTabsDisabled);

    const {
      dataGathering,
      categoryList,
      iraWorkType,
      iraInjuredParts,
      masterList,
      deleteParams,
      iraId,
      userData,
    } = this.state;
    const {
      investigation = {},
      witness = {},
      posts = {},
      analysis = {},
      recommendation = {},
      filterParams = {},
      chronology = {},
      rootCause = {},
      cost = {},
    } = dataGathering ?? {};

    console.log("filterParams", filterParams);
    const {
      iraBasicInfo,
      iraDetail,
      iraDetailStep5,
      iraDetailStep1,
      iraDetailStep2,
      iraDetailStep3,
      iraDetailStep4,
      iraCache,
    } = this.state;
    const { costList = [] } = iraDetailStep3?.cost_details ?? {};
    const capaMinDate = !_.isUndefined(iraBasicInfo.target_date)
      ? getNextDays(_momentDate(iraBasicInfo.target_date))
      : new Date();
    const teamLead =
      iraBasicInfo?.people_involved_list?.filter((el) => el.status === 1)[0] ??
      [];
    const isTreeAvailable =
      iraDetailStep4?.contributing_factor?.main_category?.length;

    const isReportedByEnabled =
      userData?.user_id === iraBasicInfo?.reported_by_id;
    const accountsWithIcon = iraInjuredParts?.length
      ? iraInjuredParts?.map((elem) => ({
          ...elem,
          ...{
            icon: (
              <Icon
                assistiveText={{ label: "Account" }}
                category="standard"
                name={"account"}
              />
            ),
          },
        }))
      : [];
    const isTeamMemeberLoggedIn = Boolean(
      searchInArrary(iraBasicInfo?.people_involved_list, {
        key: "user_id",
        value: userData?.user_id,
      })
    );

    const isApproveEnabled =
      userData?.user_id === teamLead?.user_id ||
      ["3", "1", "5"].includes(userData?.designation_flag?.toString());
    const list =
      this.state.currentTab === "prop"
        ? iraCache?.praposed_corrective_action
        : iraCache?.final_corrective_action ?? [];

    const recommendedby = _.unionBy(
      list?.map((el) => {
        return {
          recommendedBy: el?.recommended_by,
          recommended_id: el?.recommended_by_id,
        };
      }),
      "recommended_id"
    );
    const isCrownEnabled =
      ["progress", "pending"].includes(this.state.reportStatus) &&
      isApproveEnabled;
    const isDeleteEnabled =
      ["progress", "pending"].includes(this.state.reportStatus) &&
      isApproveEnabled;
    // if (this.state.dataLoading == false) {
    //   var evidencePics =
    //     this.state.iraDetail && this.state.iraDetail.evidence
    //       ? this.state.iraDetail.evidence.map((item) => {
    //           return {
    //             url: item.link,
    //             type: item.format,
    //           };
    //         })
    //       : [];

    if (this.state.imageType && this.state.imageType == "evidence") {
      sourcesList = iraBasicInfo.evidence_images?.map(
        (item) => item.evidence_image
      );
      sourcesType = iraBasicInfo.evidence_images?.map(
        (item) => item.evidence_format
      );
    }

    if (this.state.redirectToReferrer) {
      return <Redirect to={`/ira/dashboard`} />;
    }

    let uaUcChartData = [];
    if (iraBasicInfo?.analysis != undefined) {
      uaUcChartData = [
        {
          y: iraBasicInfo.analysis?.unsafe_act,
          name: "Unsafe Act",
          color: "#C7AC83",
        },
        {
          y: iraBasicInfo.analysis?.unsafe_condition,
          name: "Unsafe Condition",
          color: "#554040",
        },
      ];
    }

    const uaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: iraBasicInfo.analysis?.unsafe_act,
              name: "Unsafe Act",
              color: "#C7AC83",
            },
            {
              y: iraBasicInfo.analysis?.unsafe_condition,
              name: "Unsafe Condition",
              color: "#554040",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    // const severityChart = {
    //   chart: {
    //     type: "column",
    //     height: 150,
    //   },
    //   credits: {
    //     enabled: false,
    //   },
    //   legend: {
    //     enabled: false,
    //   },
    //   title: null,
    //   xAxis: {
    //     categories: ["Extreme", "High", "Medium", "Low"],
    //     lineColor: "#000000",
    //   },
    //   yAxis: {
    //     min: 0,
    //     max: 5,
    //     tickInterval: 1,
    //     title: {
    //       enabled: false,
    //     },
    //     allowDecimals: false,
    //     gridLineColor: "rgba(0, 0, 0, 0.04)",
    //   },
    //   tooltip: {
    //     formatter: function () {
    //       return "<b>" + this.point.name + "</b>: " + this.y;
    //     },
    //   },
    //   plotOptions: {
    //     series: {
    //       borderWidth: 0,
    //       dataLabels: {
    //         enabled: true,
    //         format: "{point.y}",
    //       },
    //     },
    //   },
    //   series: [
    //     {
    //       data: [
    //         {
    //           y: reportData.extreme_number,
    //           color: reportData.extreme_number_color,
    //           name: "Extreme",
    //         },
    //         {
    //           y: reportData.high_number,
    //           color: reportData.high_number_color,
    //           name: "HIGH",
    //         },
    //         {
    //           y: reportData.medium_number,
    //           color: reportData.medium_number_color,
    //           name: "MEDIUM",
    //         },
    //         {
    //           y: reportData.low_number || 0,
    //           color: reportData.low_number_color,
    //           name: "LOW",
    //         },
    //       ],
    //     },
    //   ],
    // };

    let nmuaUcChartData = [];

    if (iraBasicInfo?.analysis != undefined) {
      nmuaUcChartData = [
        {
          y: iraBasicInfo.analysis?.nm_ua_number,
          name: "Unsafe Act",
          color: iraBasicInfo.analysis?.nm_ua_color,
        },
        {
          y: iraBasicInfo.analysis?.nm_uc_number,
          name: "Unsafe Condition",
          color: iraBasicInfo.analysis?.nm_uc_color,
        },
      ];
    }

    const nmuaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: iraBasicInfo.analysis?.nm_ua_number,
              name: "Unsafe Act",
              color: iraBasicInfo.analysis?.nm_ua_color,
            },
            {
              y: iraBasicInfo.analysis?.nm_uc_number,
              name: "Unsafe Condition",
              color: iraBasicInfo.analysis?.nm_uc_color,
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    let analysisChartData = [];
    if (iraBasicInfo?.analysis != undefined) {
      analysisChartData = [
        {
          y: iraBasicInfo.analysis?.fa_number ?? 0,
          name: "FA",
          color: "#FF4242",
        },
        {
          y: iraBasicInfo.analysis?.rta_number ?? 0,
          name: "RTA",
          color: "#FAAC15",
        },
        {
          y: iraBasicInfo.analysis?.mtc_number ?? 0,
          name: "MTC",
          color: "#086992",
        },
        {
          y: iraBasicInfo.analysis?.lti_number ?? 0,
          name: "LTI",
          color: "#18DA07",
        },
        {
          y: iraBasicInfo.analysis?.first_aid_number ?? 0,
          name: "First Aid",
          color: "#1589EE",
        },
        {
          y: iraBasicInfo.analysis?.occupational_illness_number ?? 0,
          name: "OI",
          color: "#C511FF",
        },
        {
          y: iraBasicInfo.analysis?.notable_number || 0,
          name: "Notable",
          color: "rgb(161, 255, 206)",
        },
      ];
    }

    const analysisChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: iraBasicInfo.analysis?.fa_number || 0,
              name: "FA",
              color: "#FF4242",
            },
            {
              y: iraBasicInfo.analysis?.mtc_number || 0,
              name: "MTC",
              color: "#086992",
            },
            {
              y: iraBasicInfo.analysis?.tra_number || 0,
              name: "RTA",
              color: "#FAAC15",
            },
            {
              y: iraBasicInfo.analysis?.lti_number || 0,
              name: "LTI",
              color: "#18DA07",
            },
            {
              y: iraBasicInfo.analysis?.first_aid_number || 0,
              name: "First Aid",
              color: "#1589EE",
            },
            {
              y: iraBasicInfo.analysis?.occupational_illness_number || 0,
              name: "OI",
              color: "#C511FF",
            },
            {
              y: iraBasicInfo.analysis?.notable_number || 0,
              name: "Notable",
              color: "rgb(161, 255, 206)",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    //days
    const Newdays = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.completioncount.map(
            (data) => data.capa_completion_days_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.completioncount.map((data) => data.color),
      series: [
        {
          // data: [this.state.userFormView.basic_info?.total_score],
          data: [
            this.state.completioncount.map(
              (data) => data.capa_completion_days_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //CAPA
    const CapaCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.capacount.map(
            (data) => data.report_completion_days_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.capacount.map((data) => data.color),
      series: [
        {
          // data: [this.state.userFormView.basic_info?.total_score],
          data: [
            this.state.capacount.map(
              (data) => data.report_completion_days_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //Open
    const OpenCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.opencount.map((data) => data.recommendation_open_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.opencount.map((data) => data.color),
      series: [
        {
          // data: [this.state.userFormView.basic_info?.total_score],
          data: [
            this.state.opencount.map((data) => data.recommendation_open_count),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    const CloseCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.closecount.map((data) => data.recommendation_close_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.closecount.map((data) => data.color),
      series: [
        {
          // data: [this.state.userFormView.basic_info?.total_score],
          data: [
            this.state.closecount.map(
              (data) => data.recommendation_close_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //GP Count
    const gpCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.gpcount.map((data) => data.gp_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.gpcount.map((data) => data.color),
      series: [
        {
          data: [this.state.gpcount.map((data) => data.gp_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //tbtCount
    const tbtCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.tbtcount.map((data) => data.tbt_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.tbtcount.map((data) => data.color),
      series: [
        {
          data: [this.state.tbtcount.map((data) => data.tbt_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //InternalCOunt
    const internalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingInternalCount.map(
            (data) => data.training_internal_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingInternalCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingInternalCount.map(
              (data) => data.training_internal_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //externalcount
    const externalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          //  this.state.trainingExternalCount.map((data) => data.training_external_count ? data.training_external_count : 0)[0]  +
          this.state.trainingExternalCount.map((data) =>
            data.training_external_count ? data.training_external_count : 0
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingExternalCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingExternalCount.map(
              (data) => data.training_external_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //employeeCount
    const employeeCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingEmployeeCount.map(
            (data) => data.training_employee_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingEmployeeCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingEmployeeCount.map(
              (data) => data.training_employee_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //contractorCount
    const contractorCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingContractorCount.map(
            (data) => data.training_contractor_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingContractorCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingContractorCount.map(
              (data) => data.training_contractor_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    return (
      <div className="report-detail bg-white">
        {!this.state.dataLoading ? (
          <FsLightbox
            toggler={this.state.fsToggler}
            types={sourcesType}
            sources={sourcesList}
          />
        ) : null}

        {!!this.state.lightboxactive && (
          <FsLightbox
            toggler={this.state.fsToggler1}
            types={this.state.evidencelightboxType}
            disableLocalStorage={true}
            sources={this.state.evidencelightbox}
          />
        )}
        <Modal
          className="reportPopup searchPopup"
          centered
          show={this.state.showPopupSearch}
          onHide={this.modalHideSearch}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <AudioPlayer
                src={this.state.obs_rec}
                showJumpControls={true}
                layout="horizontal-reverse"
                customVolumeControls={[]}
                customAdditionalControls={[]}
                onPlay={(e) => {
                  console.log(e);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
        {/* <div className="rd-head">
          <div className="rd-heading">
            <h2>
              <Link to={this._handleBackButtonAction()}>
                <Button className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true" />
                </Button>
              </Link>
              {iraBasicInfo.type_of_incidence} | {iraBasicInfo.activity}
            </h2>
          </div>
        </div> */}

        <div className="rd-content  flex-column p-5">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <div>
              <h2>
                <Link to={this._handleBackButtonAction()}>
                  <Button className="back-btn">
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </Button>
                </Link>
              </h2>
            </div>
            {/* <div>
              <span className="common-black-1 font-weight-500">  {iraId} -  {iraBasicInfo.type_of_incidence} | {iraBasicInfo.activity} -  {iraBasicInfo.reportedmonth_name}
              </span>
            </div> */}
            <div>
              <span className="common-black-1 font-weight-500">
                {" "}
                {iraId ?? ""}{" "}
                <span
                  style={{
                    borderLeft: "3px Solid Orange",
                    padding: "0 5px 0 5px",
                  }}
                >
                  {" "}
                  {iraBasicInfo.type_of_incidence} - {iraBasicInfo.activity}{" "}
                </span>
                <span
                  style={{
                    borderLeft: "3px Solid Orange",
                    padding: "0 5px 0 5px",
                  }}
                >
                  {iraBasicInfo.reportedmonth_name}
                </span>
              </span>
            </div>
            <div className="d-flex align-items-center">
              {["pending", "progress"].includes(this.state.reportStatus) ? (
                <>
                  {iraBasicInfo.edit_flag == 1 && (
                    <Link
                      to={`/edit-ira-reporting-detail/${iraBasicInfo.ira_id}/${iraBasicInfo.service_id}/${iraBasicInfo.project_id}/edit`}
                    >
                      <div className="rd-btn">
                        <img
                          src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                          className="svg-icons mr-1"
                          aria-hidden="true"
                        />
                      </div>
                    </Link>
                  )}

                  {iraBasicInfo.delete_flag == 1 ? (
                    <div className="rd-btn">
                      <img
                        src={require("assets/sls-icons/V2. New Development/delete.svg")}
                        size="44"
                        className=" svg-icons mr-1"
                        aria-hidden="true"
                        onClick={() =>
                          this.debounceFunc(() => {
                            this.showModal();
                          })
                        }
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
              {["pending", "progress"].includes(this.state.reportStatus) &&
              isApproveEnabled ? (
                <img
                  src={require("assets/sls-icons/V2. New Development/Add.svg")}
                  className=" cursor-pointer common-grey-1 mr-1 svg-icons"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.openInviteUser();
                    })
                  }
                />
              ) : null}
              {this.state.reportStatus == "completed" ? (
                <div className="rd-btn right-block-gap">
                  <Link
                    to={`/ira-report/${iraBasicInfo.ira_id}`}
                    target="_blank"
                    // className="btn btn-info"
                  >
                    <img
                      src={require("assets/sls-icons/V2. New Development/PDF.svg")}
                      className="svg-icons"
                      alt="completed pdf"
                    />
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-flex">
            <div
              className="horizontal-timeline-wrapper shadow-none border  mb-4 "
              style={{ width: "100%" }}
            >
              <div className="timeline-header">
                <p className="common-black-1 font-weight-500">Timeline</p>
              </div>
              <div>
                {Object.keys(iraBasicInfo).length > 0 ? (
                  <HorizontaTimeline
                    data={this.renderTimeLine(iraBasicInfo, this.state)}
                    reInitlization={[
                      iraBasicInfo,
                      this.state.commentlist,
                      this.state,
                    ]}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="d-flex  ">
            <div className="rd-left-content mt-5 pl-0">
              <VerticalTimeline
                layout={"1-column-left"}
                className="m-0"
                lineColor={"#7D6767"}
              >
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: "4vw",
                    paddingTop: 0,
                    boxShadow: "none",
                  }}
                  icon={<FileText />}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0.5vw",
                      }}
                      className="mb-5"
                    >
                      <div
                        style={{
                          background: "rgba(217, 217, 217, 0.5)",
                          borderRadius: "28px",
                        }}
                        className="px-3 py-2 common-black-1 font-weight-500  text-center"
                      >
                        Reporting Details
                      </div>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="rd-box w-75">
                          <div className="label mb-3 font-weight-500 ">
                            View Evidence
                          </div>
                          <div className="rd-box-bottom">
                            <div className="images-section">
                              <CarouselComponent
                                items={this.__generateCarouselData(
                                  iraBasicInfo.evidence_images ?? []
                                )}
                                showArrows={false}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="label mb-3 font-weight-500 ">
                          Incident Description
                        </div>
                        <div className="rd-box-bottom">
                          <Row>
                            <Col>
                              <div className="feild-group rd-box">
                                {iraBasicInfo.observation != "" ? (
                                  <div className="output-text">
                                    {iraBasicInfo.observation}
                                  </div>
                                ) : (
                                  "NA"
                                )}
                              </div>
                              <div className="feild-group rd-box ">
                                {/* <span className="mic-icon"><Mic /></span> */}
                                {iraBasicInfo.observation_record != undefined &&
                                  iraBasicInfo.observation_record.substr(
                                    iraBasicInfo.observation_record.length - 3
                                  ) == "mp3" && (
                                    <AudioPlayer
                                      src={iraBasicInfo.observation_record}
                                      showJumpControls={true}
                                      layout="horizontal-reverse"
                                      customVolumeControls={[]}
                                      customAdditionalControls={[]}
                                      className="border-0  shadow-none"
                                    />
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* <div className="d-flex flex-column justify-content-between ">
                          {CheckFormSettingField(
                            this.state.iraFormSetting, "hazard"
                          ) && iraBasicInfo?.hazard ? (
                            <div className="feild-group d-flex">
                              <div className="label mb-2 common-black-1 font-weight-500">Hazard</div>
                              <div className="output-text ml-4">
                                {iraBasicInfo.hazard}
                              </div>
                            </div>
                          ) : null}
                          <div className="feild-group d-flex align-items-center ">

                            <div className="label mb-2 common-black-1 font-weight-500 mr-4">
                              Risk Level
                            </div>
                            <img src={badgeEnum[iraBasicInfo.severity]} style={{ width: "50px" }} />
                            <label className="output-text ml-3">{iraBasicInfo.severity}</label>
                          </div>
                        </div> */}
                      </Col>
                    </Row>
                  </div>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: "4vw",
                    paddingTop: 0,
                    boxShadow: "none",
                    boxShadow: "none",
                    textAlign: "center",
                  }}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  icon={<Info />}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div className="rd-box-head">
                      <div className="mt-5">
                        <Row
                          style={{
                            marginBottom: "0.5vw",
                            rowGap: "2vw",
                          }}
                          className="text-left"
                        >
                          <Col sm={6}>
                            <div className="rd-box">
                              <div className="rd-box-bottom pt-0">
                                <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                  Activity info
                                </h3>
                                <Row
                                  style={{
                                    rowGap: "2vw",
                                    marginTop: "1.5vw",
                                  }}
                                >
                                  <Col md={6}>
                                    <div className="feild-group">
                                      <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                        Service
                                      </div>
                                      <div className="output-text w-max">
                                        {iraBasicInfo.service}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="feild-group">
                                      <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                        Plant/Project
                                      </div>
                                      <div className="output-text w-max">
                                        {iraBasicInfo.project}
                                      </div>
                                    </div>
                                  </Col>

                                  {CheckFormSettingField(
                                    this.state.iraFormSetting,
                                    "barrier"
                                  ) ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                          Barrier
                                        </div>
                                        <div className="output-text w-max">
                                          {iraBasicInfo.barrier}
                                        </div>
                                      </div>
                                    </Col>
                                  ) : null}

                                  {CheckFormSettingField(
                                    this.state?.iraFormSetting,
                                    "sub hazard"
                                  ) ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                          Sub Hazard
                                        </div>
                                        <div className="output-tex w-maxt">
                                          {iraBasicInfo.sub_hazard} ?? "NA"
                                        </div>
                                      </div>
                                    </Col>
                                  ) : null}
                                </Row>
                              </div>
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="rd-box">
                              <div className="rd-box-bottom pt-0">
                                <h3 className="font-weight-500 common-red-3 font-18  heading-red-border ">
                                  Department/Contractor/Location
                                </h3>
                                <Row
                                  style={{
                                    rowGap: "2vw",
                                    marginTop: "1.5vw",
                                  }}
                                >
                                  {CheckFormSettingField(
                                    this.state.iraFormSetting,
                                    "department"
                                  ) ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                          Department
                                        </div>
                                        {!iraBasicInfo.department ? (
                                          <div className="output-text">NA</div>
                                        ) : (
                                          <div className="output-text w-max">
                                            {iraBasicInfo.department}
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  ) : null}
                                  {CheckFormSettingField(
                                    this.state.iraFormSetting,
                                    "contractor"
                                  ) &&
                                  this.state.showContractor &&
                                  iraBasicInfo.contractor ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                          Contractor
                                        </div>
                                        {!iraBasicInfo.contractor ? (
                                          <div className="output-text w-max">
                                            NA
                                          </div>
                                        ) : (
                                          <div className="output-text w-max">
                                            {iraBasicInfo.contractor}
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  ) : null}
                                  {CheckFormSettingField(
                                    this.state.iraFormSetting,
                                    "zone"
                                  ) &&
                                  this.state.showZone &&
                                  iraBasicInfo.zone ? (
                                    <Col md={6}>
                                      {this.state.showZone &&
                                        iraBasicInfo.zone && (
                                          <div className="feild-group">
                                            <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                              Zone
                                            </div>
                                            <div className="output-text w-max">
                                              {iraBasicInfo.zone}
                                            </div>
                                          </div>
                                        )}
                                    </Col>
                                  ) : null}
                                  {CheckFormSettingField(
                                    this.state.iraFormSetting,
                                    "location"
                                  ) &&
                                  this.state.showLocation &&
                                  iraBasicInfo.location ? (
                                    <Col md={6}>
                                      {this.state.showLocation &&
                                        iraBasicInfo.location && (
                                          <div className="feild-group">
                                            <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                              Location
                                            </div>
                                            <div className="output-text w-max">
                                              {iraBasicInfo.location}
                                            </div>
                                          </div>
                                        )}
                                    </Col>
                                  ) : null}
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: "4vw",
                    paddingTop: 0,
                    boxShadow: "none",
                  }}
                  icon={<Info />}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0.5vw",
                      }}
                      className="mb-5"
                    >
                      <div
                        style={{
                          background: "rgba(217, 217, 217, 0.5)",
                          borderRadius: "28px",
                        }}
                        className="px-3 py-2 common-black-1 font-weight-500  text-center"
                      >
                        Incident Details
                      </div>
                    </div>

                    <Row
                      style={{
                        rowGap: "2vw",
                        marginTop: "1.5vw",
                      }}
                    >
                      <Col md={12}>
                        <div className="d-flex common-flex-gap">
                          <div className="feild-group">
                            <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                              Incident Date
                            </div>
                            <div className="output-text">
                              {`${iraBasicInfo.incident_date}`}
                            </div>
                          </div>

                          <div className="feild-group">
                            <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                              Incident Time
                            </div>
                            <div className="output-text">
                              {iraBasicInfo.incident_time}
                            </div>
                          </div>
                          <div className="feild-group">
                            <div className="label mb-3 common-black-1 font-weight-500 font-18">
                              Injured Employee Details
                            </div>
                            <Scrollbars
                              style={{ height: "10vw", width: "40vw" }}
                            >
                              <div className="d-flex ">
                                {this.state.incidentPersonName?.map(
                                  (data, index, arr) => {
                                    const detail =
                                      getUserProfileDetails(
                                        parseInt(
                                          data.searched_incident_person_id
                                        ),
                                        this.state.userLists
                                      ) ?? {};
                                    const isUserFromOrgnization =
                                      data?.searched_incident_person_id;
                                    const position =
                                      arr.length - 1 === index
                                        ? "left"
                                        : "right";
                                    return isUserFromOrgnization ? (
                                      <div
                                        className="feild-group common-card-border-layout-4 px-2 py-2 mr-2 rounded-0 shadow-none text-center "
                                        style={{
                                          minWidth: "11vw",
                                        }}
                                      >
                                        <div className="output-text ">
                                          <span className="label mt-3 font-14 common-black-1 font-weight-500">
                                            {data.incident_person_name}
                                          </span>
                                          <div
                                            className="image-box my-3 "
                                            style={{ marginRight: "0.5vw" }}
                                          >
                                            <GetPopoverFrame
                                              element={
                                                <>
                                                  <img
                                                    src={detail?.user_pic}
                                                    alt=""
                                                    className="cursor-pointer"
                                                  />
                                                </>
                                              }
                                              mappingKeys={() => {
                                                return {
                                                  ID:
                                                    detail["employeeId"] ??
                                                    "NA",
                                                  Department:
                                                    detail["field"]?.split(
                                                      ":"
                                                    )[1] ?? "NA",
                                                  Type:
                                                    detail["userType"] ?? "NA",
                                                  Designation:
                                                    detail["designation"] ??
                                                    "NA",
                                                  Contact:
                                                    detail["contact_number"] ??
                                                    "NA",
                                                  // Zone: detail['designation'],
                                                  // Location: detail['designation'],
                                                };
                                              }}
                                            />
                                          </div>
                                          <div className="feild-group d-flex justify-content-center flex-column">
                                            <div className="label common-black-1 mx-auto font-weight-500 font-14 mr-2  pb-0">
                                              Id{" "}
                                            </div>
                                            <div className=" label font-14 font-weight-500">
                                              {data.incident_person_id ??
                                                index + 1}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div
                                          className="feild-group mr-2 common-card-border-layout-4 px-2 py-2 rounded-0 shadow-none text-center "
                                          style={{
                                            minWidth: "11vw",
                                          }}
                                          key={index}
                                        >
                                          <div className="output-text ">
                                            <span className="label mt-3 font-14 common-black-1 font-weight-500">
                                              {data.incident_person_name}
                                            </span>
                                            <div className=" my-3">
                                              <img
                                                src={require("../../../../assets/sls-icons/V2. New Development/user.svg")}
                                                alt=""
                                              />
                                            </div>
                                            <div className="feild-group d-flex justify-content-center flex-column">
                                              <div className="label common-black-1 mx-auto font-weight-500 font-14 mr-2  pb-0">
                                                Id{" "}
                                              </div>
                                              <div className=" label font-14 font-weight-500">
                                                {data.incident_person_id ??
                                                  index + 1}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </Scrollbars>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </VerticalTimelineElement>
                {iraBasicInfo.tl_assign_date != "" ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    icon={<Calendar />}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5vw",
                        }}
                        className="mb-5"
                      >
                        <div
                          style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px",
                          }}
                          className="px-3 py-2 common-black-1 font-weight-500  text-center"
                        >
                          Start Time
                        </div>
                      </div>
                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                        IRA Investigation Start Detail
                      </h3>
                      <Row
                        style={{
                          rowGap: "2vw",
                          marginTop: "1.5vw",
                        }}
                      >
                        <Col md={12}>
                          <div className="d-flex common-flex-gap">
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Start Date{" "}
                                <OverlayTrigger
                                  trigger="hover"
                                  placement="right"
                                  rootClose={true}
                                  overlay={
                                    <Popover
                                      id="popover-positioned-right"
                                      style={{
                                        width: "10vw",
                                        height: "3vw",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div className="output-text font-16 font-weight-500 mt-2">
                                        Investigation Team Assigned Date
                                      </div>
                                    </Popover>
                                  }
                                >
                                  <Info
                                    className="cursor-pointer  d-block text-danger ml-1"
                                    style={{ height: "20px" }}
                                  />
                                </OverlayTrigger>
                              </div>

                              <div className="output-text">
                                {iraBasicInfo.tl_assign_date ?? ""}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                {iraBasicInfo.assign_to_id != "" ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    icon={<Calendar />}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5vw",
                        }}
                        className="mb-5"
                      >
                        <div
                          style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px",
                          }}
                          className="px-3 py-2 common-black-1 font-weight-500  text-center"
                        >
                          Team / Target Date
                        </div>
                      </div>
                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                        IRA Investigation Target Detail & Investigation Team
                        Details
                      </h3>
                      <Row
                        style={{
                          rowGap: "2vw",
                          marginTop: "1.5vw",
                        }}
                      >
                        <Col md={12}>
                          <div className="d-flex common-flex-gap">
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Target Date{" "}
                                <OverlayTrigger
                                  trigger="hover"
                                  placement="right"
                                  rootClose={true}
                                  overlay={
                                    <Popover
                                      id="popover-positioned-right"
                                      style={{
                                        width: "10vw",
                                        height: "3vw",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div className="output-text font-16 font-weight-500 mt-2">
                                        Target Date To Complete Investigation
                                      </div>
                                    </Popover>
                                  }
                                >
                                  <Info
                                    className="cursor-pointer  d-block text-danger ml-1"
                                    style={{ height: "20px" }}
                                  />
                                </OverlayTrigger>
                              </div>

                              <div className="output-text">
                                {iraBasicInfo.target_date ?? "NA"}
                              </div>
                            </div>
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Investigation Team{" "}
                              </div>

                              <Scrollbars
                                style={{ height: "4vw", width: "25vw" }}
                              >
                                <div className="d-flex ">
                                  {iraBasicInfo?.people_involved_list?.map(
                                    (item, index) => (
                                      <div
                                        key={`investigation-team-${index}`}
                                        className="position-relative"
                                      >
                                        <img
                                          src={item.user_pic}
                                          alt={item.user_name}
                                          width="96"
                                          height="96"
                                          title={item.user_name}
                                          className="user-icon border-0"
                                        />
                                        {item.status == 1 && (
                                          <img
                                            src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                                            alt=""
                                            style={{
                                              position: "absolute",
                                              height: "1vw",
                                              width: "1vw",
                                              left: "1vw",
                                              top: "0.8vw",
                                            }}
                                            title={item.user_name}
                                            className="user-icon border-0 position-absolute"
                                          />
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              </Scrollbars>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                {iraBasicInfo.closing_date != 0 ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    icon={<Calendar />}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5vw",
                        }}
                        className="mb-5"
                      >
                        <div
                          style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px",
                          }}
                          className="px-3 py-2 common-black-1 font-weight-500  text-center"
                        >
                          Completed Date
                        </div>
                      </div>
                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                        IRA Investigation Completed Detail
                      </h3>
                      <Row
                        style={{
                          rowGap: "2vw",
                          marginTop: "1.5vw",
                        }}
                      >
                        <Col md={12}>
                          <div className="d-flex common-flex-gap">
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                IRA Investigation Completed Date{" "}
                              </div>

                              <div className="output-text">
                                {iraBasicInfo.closing_date ?? ""}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                {iraBasicInfo.capa_completion_date != "" ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    icon={<Calendar />}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5vw",
                        }}
                        className="mb-5"
                      >
                        <div
                          style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px",
                          }}
                          className="px-3 py-2 common-black-1 font-weight-500  text-center"
                        >
                          CAPA Detail
                        </div>
                      </div>
                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                        CAPA Recommendation Details & CAPA Status
                      </h3>
                      <Row
                        style={{
                          rowGap: "2vw",
                          marginTop: "1.5vw",
                        }}
                      >
                        <Col md={12}>
                          <div className="d-flex common-flex-gap">
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Recommendation Date{" "}
                              </div>

                              <div className="output-text">
                                {iraBasicInfo.capa_completion_date ?? ""}
                              </div>
                            </div>
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Completed Percentage Status{" "}
                              </div>

                              <ProgressBarWrapper
                                value={iraBasicInfo.progress}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                {this.state.reportStatus !== "pending" ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "1vw",
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    icon={<Info />}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5vw",
                        }}
                        className="mb-5"
                      >
                        <div
                          style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px",
                          }}
                          className="px-3 py-2 common-black-1 font-weight-500  text-center"
                        >
                          Investigation
                        </div>
                      </div>
                      <div className="text-center mb-5">
                        <div className="common-seprator-grey-3 my-4"></div>
                        <span className="font-weight-500 common-red-3 font-22 ">
                          Incident Investigation Analysis{" "}
                        </span>
                        <div className="common-seprator-grey-3 my-4"></div>
                      </div>
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                            {" "}
                            Step 1 : Initial Response
                          </h3>
                          {isInprogress &&
                          isApproveEnabled &&
                          !iraDetailStep1?.length ? (
                            <div>
                              <PlusSquare
                                className="cursor-pointer common-red-3"
                                onClick={() =>
                                  this.debounceFunc(() => {
                                    this.setState({
                                      addIntialResponse: true,
                                    });
                                  })
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                        <Row className="my-4">
                          <Col md="8">
                            {this.state.addIntialResponse ? (
                              <div className="border rounded p-3 d-flex space-between">
                                <div className="w-75">
                                  <div class="slds-form-element slds-has-error">
                                    <label
                                      class="slds-form-element__label slds-m-bottom_xx-small "
                                      for="form-element-05  "
                                    >
                                      <abbr
                                        class="slds-required"
                                        title="required"
                                      >
                                        *{" "}
                                      </abbr>
                                      Add Initial Response
                                    </label>
                                    <div class="slds-form-element__control">
                                      <input
                                        type="text"
                                        id="form-element-05"
                                        value={
                                          this.state.initialResponse.description
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            initialResponse: {
                                              ...this.state.initialResponse,
                                              description: e.target.value,
                                            },
                                          })
                                        }
                                        placeholder="Enter intial response"
                                      />
                                    </div>
                                    {/* <div class="slds-form-element__help" id="form-error-01">Enter a value.</div> */}
                                  </div>
                                  <div class="slds-form-element font-weight-lighter">
                                    <label
                                      class="slds-form-element__label"
                                      for="select-01"
                                    >
                                      <abbr
                                        class="slds-required"
                                        title="required"
                                      >
                                        *{" "}
                                      </abbr>
                                      Action Taken :
                                    </label>
                                    <Select
                                      closeMenuOnSelect={false}
                                      components={animatedComponents}
                                      minHeight="100"
                                      isMulti
                                      value={this.state.initialResponse.actions}
                                      options={this.state.actionsmaster ?? []}
                                      onChange={(el) => {
                                        console.log(el);
                                        this.setState({
                                          initialResponse: {
                                            ...this.state.initialResponse,
                                            actions: el,
                                          },
                                        });
                                      }}
                                    />
                                    {/* <div class="slds-form-element__control select-bg-remove select">
                                  <div class="slds-select_container">
                                    <select class="slds-select" id="select-01" required="" onChange={e => this.setState({
                                      initialResponse: {
                                        ...this.state.initialResponse,
                                        actions: [...this.state.initialResponse.actions, e.target.value]
                                      }
                                    })}>
                                      <option value="" disabled>Choose action type</option>
                                      {this.state.actionsmaster?.map((el, idx) =>
                                        !this.state.initialResponse.actions.includes(el?.id?.toString()) ? <option key={idx} value={el?.id}>{el?.action}</option> : null
                                      )
                                      }
                                    </select>
                                  </div>
                                </div> */}
                                  </div>
                                  {/* <CommonDropdown
                                title="Action Taken"
                                optionList={this.state.actionsmaster.map((el) => {
                                  if (!this.state.initialResponse.actions.includes(el?.id?.toString())) {
                                    return {
                                      ...el,
                                      id: el.id,
                                      label: el?.action
                                    }
                                  }

                                })}
                                onChangeHandle={e => this.setState({
                                  initialResponse: {
                                    ...this.state.initialResponse,
                                    actions: [...this.state.initialResponse.actions, e]
                                  }
                                })}
                                isRequired={true}
                                value={this.state.initialResponse.actions.includes[0] ?? ""}
                              /> */}
                                  {/* {this.state.initialResponse.actions.length ? <div className="my-2">
                                <Scrollbars style={{ height: "3vw", width: "30vw" }}>
                                  <div className="d-flex my-2">
                                    {this.state.actionsmaster?.map((el, idx) =>
                                      this.state.initialResponse.actions.includes(el?.id?.toString()) ? <span className="slds-badge px-3 py-2 font-weight-500" key={idx} >{el?.action}<span class=" ml-3 slds-badge__icon slds-badge__icon_right">
                                        <span className="slds-icon_container slds-icon-utility-moneybag slds-current-color " title="Description of icon when needed">
                                          <Trash className="slds-icon slds-icon_xx-small cursor-pointer" onClick={e => this.setState({
                                            initialResponse: {
                                              ...this.state.initialResponse,
                                              actions: this.state.initialResponse.actions.filter((sub) => sub !== el?.id?.toString())
                                            }
                                          })} />
                                        </span>
                                      </span></span> : null
                                    )
                                    }
                                  </div>
                                </Scrollbars>
                              </div> : null} */}
                                </div>
                                <div>
                                  <div className="d-flex space-between">
                                    {this.state.initialResponse.isLoading ? (
                                      <div className=" mx-3 mt-3">
                                        <Spinner />{" "}
                                      </div>
                                    ) : (
                                      <>
                                        <Save
                                          className="cursor-pointer "
                                          onClick={() =>
                                            this.debounceFunc(() => {
                                              this._initialresponseAdd();
                                            })
                                          }
                                        />
                                        <X
                                          className="cursor-pointer mx-3"
                                          onClick={() =>
                                            this.debounceFunc(() => {
                                              this.resetIntialParams();
                                            })
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {!this.state.addIntialResponse ? (
                              <Scrollbars
                                style={{ height: "10vw", width: "40vw" }}
                              >
                                {iraDetailStep1.map((el, idx) => (
                                  <div
                                    key={idx}
                                    className="border common-border-radius mr-5 p-3 d-flex space-between mb-3"
                                  >
                                    <div>
                                      <div className="form-group">
                                        <span className="font-16 my-2 common-grey-1">
                                          {el?.initial_response}
                                        </span>
                                      </div>
                                      <div className=" d-flex flex-column">
                                        <span className="   font-16 common-black-1 font-weight-500 my-2">
                                          Action Taken :
                                        </span>
                                        <Scrollbars
                                          style={{
                                            height: "2.8vw",
                                            width: "30vw",
                                          }}
                                        >
                                          <div className="d-flex">
                                            {el.action_taken?.map((sub, i) => (
                                              <span
                                                key={i}
                                                className="slds-badge px-3 py-2 font-weight-500 slds-badge_lightest mr-3"
                                              >
                                                {sub.action}
                                              </span>
                                            ))}
                                          </div>
                                        </Scrollbars>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="d-flex space-between">
                                        {isInprogress && isApproveEnabled ? (
                                          <div>
                                            <img
                                              src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                                              onClick={() =>
                                                this.debounceFunc(() => {
                                                  this.setState({
                                                    addIntialResponse: true,
                                                    initialResponse: {
                                                      editId:
                                                        el?.initial_response_id,
                                                      description:
                                                        el?.initial_response,
                                                      actions:
                                                        el?.action_taken.map(
                                                          (sub) => {
                                                            return {
                                                              value: sub?.id,
                                                              label:
                                                                sub?.action,
                                                            };
                                                          }
                                                        ),
                                                    },
                                                  });
                                                })
                                              }
                                              className="cursor-pointer svg-icons mr-2"
                                            />
                                          </div>
                                        ) : null}
                                        {isInprogress && isApproveEnabled ? (
                                          <div>
                                            <img
                                              src={require("assets/sls-icons/V2. New Development/delete.svg")}
                                              onClick={() =>
                                                this.debounceFunc(() => {
                                                  this.manageDataGatheringParams(
                                                    "",
                                                    {
                                                      isDeleteEnable: true,
                                                      parameters: {
                                                        initial_response_id:
                                                          el?.initial_response_id,
                                                      },
                                                      stepNo: "step1",
                                                    },
                                                    false,
                                                    "deleteParams"
                                                  );
                                                })
                                              }
                                              className="cursor-pointer svg-icons mr-2"
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Scrollbars>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                      <div className="my-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex space-between w-100">
                            <h3 className="font-weight-500 common-red-3 font-18 heading-red-border mr-3">
                              {" "}
                              Step 2 - Initial Investigation
                            </h3>
                            {isInprogress &&
                            isApproveEnabled &&
                            !investigation.isAddEnable ? (
                              <div>
                                <PlusSquare
                                  className="cursor-pointer common-red-3"
                                  onClick={() =>
                                    this.debounceFunc(() => {
                                      this.manageDataGatheringParams(
                                        "investigation",
                                        {
                                          isAddEnable: true,
                                        }
                                      );
                                    })
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                          <div>
                            {investigation.isAddEnable &&
                            investigation.editId === "" ? (
                              <HorizontalNavTab
                                tabs={
                                  this.state.injuredPersonList.length
                                    ? [...this.state.injuredPersonList.map((el) => {
                                        return {
                                          tabName: el?.label,
                                          key: el?.id,
                                        };
                                      }), { tabName: "Other", key: "other" }]
                                    : 
                                    [{ tabName: "Other", key: "other" }]
                                }
                                activeItem={investigation?.injuredPersonType}
                                handleChange={(key) =>
                                  this.manageDataGatheringParams(
                                    "investigation",
                                    {
                                      injuredPersonType: key,
                                      injured_person_name:
                                        key !== "other" ? key : "",
                                    }
                                  )
                                }
                              />
                            ) : null}
                          </div>
                        </div>
                        <Row className="my-4">
                          <Col md="12">
                            {investigation.isAddEnable &&
                            investigation?.injuredPersonType ? (
                              <div className="border rounded p-3 d-flex space-between">
                                <div
                                  className="d-flex flex-wrap "
                                  style={{
                                    columnGap: "0.9vw",
                                    rowGap: "0.3vw",
                                  }}
                                >
                                  <div className="w-100 d-flex">
                                    <Combobox
                                      id="combobox-readonly-single"
                                      events={{
                                        onSelect: (event, { selection }) => {
                                          this.manageDataGatheringParams(
                                            "investigation",
                                            {
                                              proofType: selection,
                                            }
                                          );
                                        },
                                      }}
                                      labels={{
                                        label: "Add Identity Proof",
                                        placeholder: "Select  Type",
                                      }}
                                      aria-required={true}
                                      required
                                      errorText={
                                        this.state.stepValidations
                                          .isIdProofTypeError
                                          ? "Please select the Identity Proof"
                                          : null
                                      }
                                      options={this.state.iraidentityProof}
                                      selection={investigation.proofType}
                                      variant="readonly"
                                    />
                                    {investigation?.proofType !== "" ? (
                                      <div className="slds-form-element ml-3 w-25 slds-has-error">
                                        <span
                                          className="slds-form-element__label"
                                          id="file-selector-primary-label-101"
                                        >
                                          Id Proof
                                        </span>
                                        <div className="slds-form-element__control">
                                          <div className="slds-file-selector slds-file-selector_files">
                                            <div className="slds-file-selector__dropzone">
                                              <input
                                                type="file"
                                                className="slds-file-selector__input slds-assistive-text"
                                                accept="image/png"
                                                id="file-upload-input-103"
                                                onChange={(e) =>
                                                  this.generateTheIdProof(
                                                    e,
                                                    investigation.proofType[0][
                                                      "id"
                                                    ]
                                                  )
                                                }
                                                aria-labelledby="file-selector-primary-label-101 file-selector-secondary-label102"
                                              />
                                              <label
                                                className="slds-file-selector__body mb-0"
                                                for="file-upload-input-103"
                                                id="file-selector-secondary-label102"
                                              >
                                                <span className="slds-file-selector__button slds-button slds-button_neutral font-14">
                                                  <Upload className=" slds-button__icon slds-button__icon_left cursor-pointer mx-3" />
                                                  Upload Id Proof
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        {this.state.stepValidations
                                          .isIdProofDocumentError ? (
                                          <div
                                            className="slds-form-element__help"
                                            id="error-108"
                                          >
                                            Please Upload Id Document
                                          </div>
                                        ) : null}
                                      </div>
                                    ) : null}

                                    {investigation.identity_proof?.length ? (
                                      <Scrollbars
                                        style={{
                                          height: "10vw",
                                          width: "25vw",
                                        }}
                                      >
                                        <div className="m-3">
                                          <Files id="files-loading-example">
                                            {investigation.identity_proof?.map(
                                              (el, idx) => {
                                                if (
                                                  Object.hasOwn(
                                                    el,
                                                    "category_id"
                                                  )
                                                ) {
                                                  return (
                                                    <File
                                                      id={`${idx} - demo-id`}
                                                      labels={{
                                                        title: "Uploaded File",
                                                      }}
                                                      image={el?.proof}
                                                      crop="1-by-1"
                                                    />
                                                  );
                                                }
                                                return (
                                                  <File
                                                    id={`${idx} - ${el?.name}`}
                                                    key={idx}
                                                    labels={{
                                                      title: el?.name,
                                                    }}
                                                    image={el?.proof}
                                                    crop="1-by-1"
                                                  />
                                                );
                                              }
                                            )}
                                          </Files>
                                        </div>
                                      </Scrollbars>
                                    ) : null}
                                  </div>
                                  <fieldset>
                                    <div className="d-flex">
                                      <legend className="slds-form-element__legend slds-form-element__label">
                                        Add People
                                      </legend>
                                      <div>
                                        <Tooltip
                                          id="help"
                                          align="top left"
                                          content={
                                            "Capture multiple witnesses statement"
                                          }
                                          variant="learnMore"
                                        >
                                          <button
                                            className="slds-button slds-button_icon"
                                            aria-describedby="help"
                                          >
                                            {" "}
                                            <Info className="w-100 cursor-pointer" />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </div>

                                    <div className="slds-form-element__control">
                                      <div className="slds-checkbox">
                                        <input
                                          type="checkbox"
                                          name="options"
                                          id="checkbox-unique-id-163"
                                          checked={
                                            investigation?.employee_contractor_status ===
                                            1
                                          }
                                          onChange={() =>
                                            this.manageDataGatheringParams(
                                              "investigation",
                                              {
                                                employee_contractor_status: 1,
                                              }
                                            )
                                          }
                                        />
                                        <label
                                          className="slds-checkbox__label"
                                          for="checkbox-unique-id-163"
                                        >
                                          <span className="slds-checkbox_faux"></span>
                                          <span className="slds-form-element__label ">
                                            Employee
                                          </span>
                                        </label>
                                      </div>
                                      <div className="slds-checkbox">
                                        <input
                                          type="checkbox"
                                          name="options"
                                          id="checkbox-unique-id-164"
                                          checked={
                                            investigation?.employee_contractor_status ===
                                            2
                                          }
                                          onChange={() =>
                                            this.manageDataGatheringParams(
                                              "investigation",
                                              {
                                                employee_contractor_status: 2,
                                                contractor_company_name: "",
                                              }
                                            )
                                          }
                                        />
                                        <label
                                          className="slds-checkbox__label"
                                          for="checkbox-unique-id-164"
                                        >
                                          <span className="slds-checkbox_faux"></span>
                                          <span className="slds-form-element__label ">
                                            Contractor
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </fieldset>
                                  {investigation?.employee_contractor_status ===
                                  2 ? (
                                    <Input
                                      assistiveText={{
                                        label: "Contractor’s Company",
                                      }}
                                      id="assistiveLabel-id"
                                      placeholder="Enter company name"
                                      label={"Contractor’s Company"}
                                      value={
                                        investigation?.contractor_company_name
                                      }
                                      onChange={(e) =>
                                        this.manageDataGatheringParams(
                                          "investigation",
                                          {
                                            contractor_company_name:
                                              e.target.value,
                                          }
                                        )
                                      }
                                    />
                                  ) : null}

                                  {investigation?.injuredPersonType ===
                                  "other" ? (
                                    <Input
                                      assistiveText={{ label: "Name" }}
                                      id="assistiveLabel-id"
                                      placeholder="Enter name"
                                      label={"Name of Injured Person"}
                                      value={investigation?.injured_person_name}
                                      onChange={(e) =>
                                        this.manageDataGatheringParams(
                                          "investigation",
                                          {
                                            injured_person_name: e.target.value,
                                          }
                                        )
                                      }
                                    />
                                  ) : null}
                                  <Combobox
                                    id="combobox-readonly-single-33"
                                    events={{
                                      onSelect: (event, { selection }) => {
                                        this.manageDataGatheringParams(
                                          "investigation",
                                          {
                                            injured_person_gender: selection,
                                          }
                                        );
                                      },
                                    }}
                                    labels={{
                                      label: "Gender",
                                      placeholder: "Select gender",
                                    }}
                                    options={this.state.genderList}
                                    selection={
                                      investigation.injured_person_gender
                                    }
                                    variant="readonly"
                                  />
                                  <Combobox
                                    id="combobox-readonly-single-33"
                                    events={{
                                      onSelect: (event, { selection }) => {
                                        this.manageDataGatheringParams(
                                          "investigation",
                                          {
                                            injured_type_id: selection,
                                          }
                                        );
                                      },
                                    }}
                                    labels={{
                                      label: "Injury Type",
                                      placeholder: "Select type",
                                    }}
                                    options={this.state.injurtType}
                                    selection={investigation.injured_type_id}
                                    variant="readonly"
                                  />
                                  <Input
                                    id="assistiveLabel-id"
                                    placeholder="Enter age"
                                    label={"Age"}
                                    value={investigation?.injured_person_age}
                                    onChange={(e) =>
                                      this.manageDataGatheringParams(
                                        "investigation",
                                        { injured_person_age: e.target.value }
                                      )
                                    }
                                  />
                                  <Combobox
                                    id="combobox-readonly-single-33"
                                    events={{
                                      onSelect: (event, { selection }) => {
                                        this.manageDataGatheringParams(
                                          "investigation",
                                          {
                                            person_work_type: selection,
                                          }
                                        );
                                      },
                                    }}
                                    labels={{
                                      label: "Person Working Type",
                                      placeholder: "Select  Type",
                                    }}
                                    options={iraWorkType}
                                    selection={investigation.person_work_type}
                                    variant="readonly"
                                  />
                                  <div>
                                    <Datepicker
                                      labels={{
                                        label: "Joining Date",
                                      }}
                                      onChange={(event, data) => {
                                        this.manageDataGatheringParams(
                                          "investigation",
                                          { joining_date: data.date }
                                        );
                                      }}
                                      formatter={(date) => {
                                        return date
                                          ? moment(date).format("M/D/YYYY")
                                          : "";
                                      }}
                                      value={investigation?.joining_date}
                                    />
                                  </div>
                                  <div className="mt-auto">
                                    <Combobox
                                      id="combobox-readonly-single"
                                      events={{
                                        onSelect: (event, { selection }) => {
                                          this.manageDataGatheringParams(
                                            "investigation",
                                            {
                                              working_shift: selection,
                                            }
                                          );
                                        },
                                      }}
                                      labels={{
                                        label: "Working Shift",
                                        placeholder: "Select  Type",
                                      }}
                                      options={this.state.workingShift}
                                      selection={investigation.working_shift}
                                      variant="readonly"
                                    />
                                  </div>
                                  {!this._checkSettingParameter(
                                    "month_year_with_this_employee"
                                  ) ? (
                                    <div>
                                      <span
                                        className="slds-form-element__label font-14 common-black-1"
                                        id="file-selector-primary-label-101 mb-0 "
                                      >
                                        {" "}
                                        Months/Year with this employee
                                      </span>
                                      <div className="d-flex">
                                        <Combobox
                                          id="combobox-readonly-single"
                                          events={{
                                            onSelect: (
                                              event,
                                              { selection }
                                            ) => {
                                              this.manageDataGatheringParams(
                                                "investigation",
                                                {
                                                  year_with_this_emp: selection,
                                                }
                                              );
                                            },
                                          }}
                                          labels={{
                                            label: "Year ",
                                            placeholder: "Select  Year",
                                          }}
                                          options={this.generateDropdownParameter(
                                            YearSelect,
                                            {
                                              id: "id",
                                              label: "name",
                                            }
                                          )}
                                          selection={
                                            investigation.year_with_this_emp
                                          }
                                          variant="readonly"
                                        />
                                        <div className="ml-2">
                                          <Combobox
                                            id="combobox-readonly-single"
                                            events={{
                                              onSelect: (
                                                event,
                                                { selection }
                                              ) => {
                                                this.manageDataGatheringParams(
                                                  "investigation",
                                                  {
                                                    month_with_this_emp:
                                                      selection,
                                                  }
                                                );
                                              },
                                            }}
                                            labels={{
                                              label: "Month",
                                              placeholder: "Select  Month",
                                            }}
                                            options={this.generateDropdownParameter(
                                              month,
                                              {
                                                id: "id",
                                                label: "name",
                                              }
                                            )}
                                            selection={
                                              investigation.month_with_this_emp
                                            }
                                            variant="readonly"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {!this._checkSettingParameter(
                                    "month_year_doing_this_job"
                                  ) ? (
                                    <div>
                                      <span
                                        className="slds-form-element__label font-14 common-black-1"
                                        id="file-selector-primary-label-101 mb-0 "
                                      >
                                        {" "}
                                        Months/Year doing this job
                                      </span>
                                      <div className="d-flex">
                                        <Combobox
                                          id="combobox-readonly-single"
                                          events={{
                                            onSelect: (
                                              event,
                                              { selection }
                                            ) => {
                                              this.manageDataGatheringParams(
                                                "investigation",
                                                {
                                                  year_doing_this_job:
                                                    selection,
                                                }
                                              );
                                            },
                                          }}
                                          labels={{
                                            label: "Year ",
                                            placeholder: "Select  Year",
                                          }}
                                          options={this.generateDropdownParameter(
                                            YearSelect,
                                            {
                                              id: "id",
                                              label: "name",
                                            }
                                          )}
                                          selection={
                                            investigation.year_doing_this_job
                                          }
                                          variant="readonly"
                                        />
                                        <div className="ml-2">
                                          <Combobox
                                            id="combobox-readonly-single"
                                            events={{
                                              onSelect: (
                                                event,
                                                { selection }
                                              ) => {
                                                this.manageDataGatheringParams(
                                                  "investigation",
                                                  {
                                                    month_doing_this_job:
                                                      selection,
                                                  }
                                                );
                                              },
                                            }}
                                            labels={{
                                              label: "Month",
                                              placeholder: "Select  Month",
                                            }}
                                            options={this.generateDropdownParameter(
                                              month,
                                              {
                                                id: "id",
                                                label: "name",
                                              }
                                            )}
                                            selection={
                                              investigation.month_doing_this_job
                                            }
                                            variant="readonly"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <IconSettings iconPath="/assets/icons">
                                    <div className="mt-auto">
                                      <Combobox
                                        id="combobox-readonly-single"
                                        events={{
                                          onSelect: (event, { selection }) => {
                                            this.manageDataGatheringParams(
                                              "investigation",
                                              {
                                                injured_parts: selection,
                                              }
                                            );
                                          },
                                          onRequestRemoveSelectedOption: (
                                            event,
                                            data
                                          ) => {
                                            this.manageDataGatheringParams(
                                              "investigation",
                                              {
                                                injured_parts: data.selection,
                                              }
                                            );
                                          },
                                        }}
                                        labels={{
                                          label: "Injured Body Part",
                                          placeholder: "Select Part",
                                        }}
                                        multiple
                                        options={comboboxFilterAndLimit({
                                          inputValue: "",
                                          limit: 9999,
                                          options: accountsWithIcon,
                                          selection:
                                            investigation.injured_parts ?? [],
                                        })}
                                        selection={investigation.injured_parts}
                                        variant="readonly"
                                      />
                                    </div>
                                  </IconSettings>
                                  <div className="mt-auto">
                                    <Combobox
                                      id="combobox-readonly-single"
                                      events={{
                                        onSelect: (event, { selection }) => {
                                          this.manageDataGatheringParams(
                                            "investigation",
                                            {
                                              time_of_incident: selection,
                                            }
                                          );
                                        },
                                      }}
                                      labels={{
                                        label: "Time of Incident",
                                        placeholder: "Select  time",
                                      }}
                                      options={this.state.iraTimeOfIncident}
                                      selection={investigation.time_of_incident}
                                      variant="readonly"
                                    />
                                  </div>
                                  <div className="my-2 w-100 d-flex space-between">
                                    <div className="w-50 ">
                                      <Combobox
                                        id="combobox-readonly-single"
                                        events={{
                                          onSelect: (_, { selection }) => {
                                            this.setState((prev) => {
                                              return {
                                                questionArray: [
                                                  ...prev.questionArray,
                                                  {
                                                    question_id:
                                                      selection[0].id,
                                                    question:
                                                      selection[0].label,
                                                    response: "",
                                                  },
                                                ],
                                              };
                                            });
                                          },
                                        }}
                                        labels={{
                                          label:
                                            "Incidence Analysis Question (5 WHY Analysis)",
                                          placeholder: "Select Questions",
                                        }}
                                        options={this.filterQuestion(
                                          this.state.questionMaster,
                                          this.state.questionArray
                                        )}
                                        // selection={investigation.injured_parts}
                                        variant="readonly"
                                      />
                                    </div>
                                    <div className=" ml-3 w-50">
                                      {this.state.questionArray.length ? (
                                        <Scrollbars style={{ height: "8vw" }}>
                                          {this.state.questionArray.map(
                                            (el, idx, arr) => (
                                              <div className=" d-flex space-between mr-3">
                                                {" "}
                                                <div
                                                  className="slds-col_padded"
                                                  key={idx}
                                                >
                                                  <h1 className="slds-text-title_caps slds-p-vertical_medium">
                                                    {el.question}
                                                  </h1>
                                                  <Input
                                                    assistiveText={{
                                                      label: "My label",
                                                    }}
                                                    id={el?.question_id}
                                                    placeholder="Enter response"
                                                    value={el.response}
                                                    onChange={(e) =>
                                                      this.manageQuestion(
                                                        e.target.value,
                                                        arr,
                                                        idx
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div>
                                                  <Trash
                                                    className="cursor-pointer mx-3"
                                                    onClick={() =>
                                                      this.debounceFunc(() => {
                                                        this.setState({
                                                          questionArray:
                                                            arr.filter(
                                                              (sub) =>
                                                                sub.question_id !==
                                                                el.question_id
                                                            ),
                                                        });
                                                      })
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </Scrollbars>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div className="d-flex space-between">
                                    {investigation.isLoading ? (
                                      <div className=" mx-3 mt-3">
                                        <Spinner />{" "}
                                      </div>
                                    ) : (
                                      <>
                                        <Save
                                          className="cursor-pointer  "
                                          onClick={() =>
                                            this.debounceFunc(() => {
                                              this.saveIntialInvestigation();
                                            })
                                          }
                                        />
                                        <X
                                          className="cursor-pointer mx-3"
                                          onClick={() =>
                                            this.debounceFunc(() => {
                                              this.manageDataGatheringParams(
                                                "",
                                                {},
                                                true
                                              );
                                            })
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {!investigation.isAddEnable &&
                              iraDetailStep2?.injured_person_details?.person_details?.map(
                                (el, idx) => (
                                  <>
                                    {" "}
                                    <div
                                      key={idx}
                                      className="horizontal-timeline-wrapper shadow-none border   mt-4  "
                                      style={{ width: "100%" }}
                                    >
                                      <div
                                        className="timeline-header d-flex align-items-baseline mr-3"
                                        style={{ top: "-30px" }}
                                      >
                                        <p className="common-grey-1 font-18 font-weight-500">
                                          Injured Person :{" "}
                                        </p>{" "}
                                        <span className="common-red-3 ml-2 font-18 font-weight-500">
                                          {" "}
                                          {el?.injured_person_name}
                                        </span>
                                      </div>
                                      <div className="d-flex space-between p-4 mt-2">
                                        <div>
                                          <div className="d-flex common-flex-gap flex-wrap">
                                            <div class="feild-group field">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Identity Proof
                                              </div>
                                              {el?.identity_proof?.length ? (
                                                <>
                                                  <div>
                                                    {this.assetRender(
                                                      el?.identity_proof[0][
                                                        "proof"
                                                      ]
                                                    )}
                                                  </div>
                                                  <div class="output-text mt-2 text-center">
                                                    {
                                                      el?.identity_proof[0][
                                                        "category"
                                                      ]
                                                    }
                                                  </div>
                                                </>
                                              ) : null}
                                            </div>
                                            <div class="feild-group field">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Type
                                              </div>
                                              <div class="output-text">
                                                {el?.employee_type}
                                              </div>
                                            </div>
                                            <div class="feild-group field">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Gender
                                              </div>
                                              <div class="output-text">
                                                {el?.injured_person_gender}
                                              </div>
                                            </div>
                                            <div class="feild-group field">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Age
                                              </div>
                                              <div class="output-text">
                                                {el?.injured_person_age}
                                              </div>
                                            </div>
                                            <div>
                                              <Model
                                                style={{
                                                  maxWidth: "5vw",
                                                }}
                                                data={[
                                                  {
                                                    name: "Bench Press",
                                                    muscles:
                                                      this.getInjuredParts(el),
                                                  },
                                                ]}
                                                highlightedColors={["#EC6B15"]}
                                              />
                                            </div>
                                            <div class="feild-group field">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Injury Type
                                              </div>
                                              <div class="output-text">
                                                {el?.injury_type}
                                              </div>
                                            </div>
                                            <div class="feild-group field ">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Working Shift
                                              </div>
                                              <div class="output-text">
                                                {
                                                  el?.joining_details
                                                    ?.working_shift
                                                }
                                              </div>
                                            </div>
                                            <div class="feild-group field w-50">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Injured Body Part
                                              </div>

                                              <Scrollbars
                                                style={{
                                                  height: "3.8vw",
                                                  width: "22vw",
                                                }}
                                              >
                                                <div className="d-flex">
                                                  {el?.joining_details?.injured_parts?.map(
                                                    (sub, idx) => (
                                                      <span className="badge border font-weight-500 p-2 mr-2 font-16">
                                                        {sub?.injured_parts}
                                                      </span>
                                                    )
                                                  )}
                                                </div>
                                              </Scrollbars>
                                            </div>
                                            <div class="feild-group field ">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Time of Incident
                                              </div>

                                              <Scrollbars
                                                style={{
                                                  height: "3.8vw",
                                                  width: "22vw",
                                                }}
                                              >
                                                <div className="d-flex">
                                                  <span className="badge border font-weight-500 p-2 mr-2 font-16">
                                                    {
                                                      el?.joining_details
                                                        ?.time_of_incident
                                                    }
                                                  </span>
                                                </div>
                                              </Scrollbars>
                                            </div>
                                            <div class="feild-group field ">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Person Working Type
                                              </div>

                                              <Scrollbars
                                                style={{
                                                  height: "3.8vw",
                                                  width: "22vw",
                                                }}
                                              >
                                                <div className="d-flex">
                                                  <span className="badge border font-weight-500 p-2 mr-2 font-16">
                                                    {el?.person_work_type}
                                                  </span>
                                                </div>
                                              </Scrollbars>
                                            </div>
                                            <div class="feild-group field">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Months/Year with this job
                                                (Overall)
                                              </div>
                                              <div class="output-text">
                                                {
                                                  el?.joining_details
                                                    ?.month_year_with_this_employee
                                                }
                                              </div>
                                            </div>
                                            <div class="feild-group field">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Joining Date
                                              </div>
                                              <div class="output-text">
                                                {el?.joining_date}
                                              </div>
                                            </div>
                                            <div class="feild-group field">
                                              <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Months/Year doing this job
                                                (Overall)
                                              </div>
                                              <div class="output-text">
                                                {
                                                  el?.joining_details
                                                    ?.month_year_doing_this_job
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div className="my-5 text-center">
                                            <span className="common-red-3 font-weight-500 font-20 ">
                                              Incidence Analysis Question (5Y
                                              Analysis)
                                            </span>
                                          </div>
                                          {el?.incident_analysis_question?.map(
                                            (el, idx) => (
                                              <div className="d-flex" key={idx}>
                                                <div className="d-flex justify-content-between mr-3">
                                                  <div className="nm-question-wrapper mb-3">
                                                    <div className="question-count w-max">
                                                      <span>Q {idx + 1} </span>
                                                    </div>
                                                    <div className="question-view">
                                                      {el?.question}
                                                    </div>
                                                    <div className="answer-view">
                                                      {el?.response}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        <div>
                                          <div className="d-flex space-between">
                                            {isInprogress &&
                                            isApproveEnabled ? (
                                              <>
                                                <img
                                                  src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                                                  onClick={() =>
                                                    this.debounceFunc(() => {
                                                      this.mapInvesigationParams(
                                                        el
                                                      );
                                                    })
                                                  }
                                                  className="cursor-pointer svg-icons mr-1"
                                                />
                                                <img
                                                  src={require("assets/sls-icons/V2. New Development/delete.svg")}
                                                  onClick={() =>
                                                    this.debounceFunc(() => {
                                                      this.manageDataGatheringParams(
                                                        "",
                                                        {
                                                          isDeleteEnable: true,
                                                          parameters: {
                                                            injured_person_id:
                                                              el?.injured_person_id,
                                                          },
                                                          url: "/listira/deleteinjuredpersondetails",
                                                        },
                                                        false,
                                                        "deleteParams"
                                                      );
                                                    })
                                                  }
                                                  className="cursor-pointer svg-icons mr-5"
                                                />
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="my-5"></div>
                                  </>
                                )
                              )}
                          </Col>
                        </Row>
                      </div>
                      <div className="my-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                            {" "}
                            Step 3 : Data Gathering
                          </h3>
                          {isInprogress && isApproveEnabled ? (
                            <div>
                              {isApproveEnabled ? (
                                <PlusSquare
                                  className="cursor-pointer common-red-3"
                                  onClick={() =>
                                    this.debounceFunc(() => {
                                      this.manageDataGatheringParams(
                                        "witness",
                                        {
                                          isAddEnable: true,
                                        }
                                      );
                                    })
                                  }
                                />
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                        <Row className="my-5">
                          <Col md="12">
                            {witness?.isAddEnable ? (
                              <div className="border rounded p-3 d-flex space-between">
                                <div>
                                  <div className="d-flex common-flex-gap flex-wrap">
                                    <fieldset>
                                      <div className="d-flex">
                                        <legend className="slds-form-element__legend slds-form-element__label">
                                          Add People
                                        </legend>
                                        <div>
                                          <Tooltip
                                            id="help"
                                            align="top left"
                                            content={
                                              "Capture multiple witnesses statement"
                                            }
                                            variant="learnMore"
                                          >
                                            <button
                                              className="slds-button slds-button_icon"
                                              aria-describedby="help"
                                            >
                                              {" "}
                                              <Info className="w-100 cursor-pointer" />
                                            </button>
                                          </Tooltip>
                                        </div>
                                      </div>

                                      <div className="slds-form-element__control">
                                        <div className="slds-checkbox">
                                          <input
                                            type="checkbox"
                                            name="options"
                                            id="checkbox-unique-id-163"
                                            checked={
                                              witness?.employee_contractor_status ===
                                              1
                                            }
                                            onChange={() =>
                                              this.manageDataGatheringParams(
                                                "witness",
                                                {
                                                  employee_contractor_status: 1,
                                                }
                                              )
                                            }
                                          />
                                          <label
                                            className="slds-checkbox__label"
                                            for="checkbox-unique-id-163"
                                          >
                                            <span className="slds-checkbox_faux"></span>
                                            <span className="slds-form-element__label ">
                                              Employee
                                            </span>
                                          </label>
                                        </div>
                                        <div className="slds-checkbox">
                                          <input
                                            type="checkbox"
                                            name="options"
                                            id="checkbox-unique-id-164"
                                            checked={
                                              witness?.employee_contractor_status ===
                                              2
                                            }
                                            onChange={() =>
                                              this.manageDataGatheringParams(
                                                "witness",
                                                {
                                                  employee_contractor_status: 2,
                                                  contractor_company:
                                                    witness.isEdit !== ""
                                                      ? witness?.contractor_company
                                                      : "",
                                                }
                                              )
                                            }
                                          />
                                          <label
                                            className="slds-checkbox__label"
                                            for="checkbox-unique-id-164"
                                          >
                                            <span className="slds-checkbox_faux"></span>
                                            <span className="slds-form-element__label ">
                                              Contractor
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </fieldset>
                                    {witness?.employee_contractor_status ===
                                    2 ? (
                                      <div className="slds-form-element slds-has-error">
                                        <label
                                          className="slds-form-element__label slds-m-bottom_xx-small "
                                          for="form-element-05  "
                                        >
                                          <abbr
                                            className="slds-required"
                                            title="required"
                                          >
                                            *{" "}
                                          </abbr>
                                          Contractor’s Company
                                        </label>
                                        <div className="slds-form-element__control">
                                          <input
                                            type="text"
                                            id="form-element-05"
                                            value={witness?.contractor_company}
                                            onChange={(e) =>
                                              this.manageDataGatheringParams(
                                                "witness",
                                                {
                                                  contractor_company:
                                                    e.target.value,
                                                }
                                              )
                                            }
                                            placeholder="Enter contractor’s company"
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="slds-form-element slds-has-error">
                                      <label
                                        className="slds-form-element__label slds-m-bottom_xx-small "
                                        for="form-element-05  "
                                      >
                                        <abbr
                                          className="slds-required"
                                          title="required"
                                        >
                                          *{" "}
                                        </abbr>
                                        Witness Name
                                      </label>
                                      <div className="slds-form-element__control">
                                        <input
                                          type="text"
                                          id="form-element-05"
                                          value={witness?.witness_name}
                                          onChange={(e) =>
                                            this.manageDataGatheringParams(
                                              "witness",
                                              {
                                                witness_name: e.target.value,
                                              }
                                            )
                                          }
                                          placeholder="Enter witness name"
                                        />
                                      </div>
                                    </div>
                                    <div class="slds-form-element ">
                                      <span
                                        class="slds-form-element__label"
                                        id="file-selector-primary-label-101"
                                      >
                                        Witness Images
                                      </span>
                                      <div class="slds-form-element__control">
                                        <div class="slds-file-selector slds-file-selector_files">
                                          <div class="slds-file-selector__dropzone">
                                            <input
                                              type="file"
                                              class="slds-file-selector__input slds-assistive-text"
                                              accept="image/png"
                                              id="file-upload-input-103"
                                              onChange={(e) =>
                                                this._pickCropImage(e)
                                              }
                                              aria-labelledby="file-selector-primary-label-101 file-selector-secondary-label102"
                                            />
                                            <label
                                              class="slds-file-selector__body"
                                              for="file-upload-input-103"
                                              id="file-selector-secondary-label102"
                                            >
                                              <Upload className="cursor-pointer mx-3" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {witness.files?.length ? (
                                    <Scrollbars
                                      style={{ height: "10vw", width: "35vw" }}
                                    >
                                      <div className="m-3">
                                        <Files id="files-loading-example">
                                          {witness.files?.map((el, idx) => {
                                            if (typeof el === "string") {
                                              return (
                                                <File
                                                  id={`${idx} - demo-id`}
                                                  labels={{
                                                    title: "Uploaded File",
                                                  }}
                                                  image={el}
                                                  crop="1-by-1"
                                                />
                                              );
                                            }
                                            return (
                                              <File
                                                id={`${idx} - ${el.file.name}`}
                                                key={idx}
                                                labels={{
                                                  title: el.file.name,
                                                }}
                                                image={URL.createObjectURL(
                                                  el.file
                                                )}
                                                crop="1-by-1"
                                              />
                                            );
                                          })}
                                        </Files>
                                      </div>
                                    </Scrollbars>
                                  ) : null}

                                  <div className="slds-form-element slds-has-error">
                                    <label
                                      className="slds-form-element__label slds-m-bottom_xx-small "
                                      for="form-element-05  "
                                    >
                                      <abbr
                                        className="slds-required"
                                        title="required"
                                      >
                                        *{" "}
                                      </abbr>
                                      Witness Statement
                                    </label>
                                    <div className="slds-form-element__control">
                                      <input
                                        type="text"
                                        id="form-element-05"
                                        value={witness?.witness_statement}
                                        onChange={(e) =>
                                          this.manageDataGatheringParams(
                                            "witness",
                                            {
                                              witness_statement: e.target.value,
                                            }
                                          )
                                        }
                                        placeholder="Enter witness statement"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex space-between">
                                    {witness?.isLoading ? (
                                      <div className=" mx-3 mt-3">
                                        <Spinner />{" "}
                                      </div>
                                    ) : (
                                      <>
                                        <Save
                                          className="cursor-pointer "
                                          onClick={() =>
                                            this.debounceFunc(() => {
                                              this.addpeoples();
                                            })
                                          }
                                        />
                                        <X
                                          className="cursor-pointer mx-3"
                                          onClick={() =>
                                            this.debounceFunc(() => {
                                              this.manageDataGatheringParams(
                                                "",
                                                {},
                                                true
                                              );
                                            })
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {!this.state.dataGathering.witness?.isAddEnable ? (
                              <div className="mr-5 ">
                                {this.state.iraDetailStep3?.witness_details?.witness?.map(
                                  (el, idx) => (
                                    <>
                                      {" "}
                                      <div
                                        key={idx}
                                        className="horizontal-timeline-wrapper  rounded shadow-none border   w-100 mt-3"
                                      >
                                        <div
                                          className="timeline-header d-flex align-items-baseline"
                                          style={{ top: "-30px" }}
                                        >
                                          <p className="common-grey-1 font-18 font-weight-500">
                                            Witness Person :{" "}
                                          </p>{" "}
                                          <span className="common-red-3 ml-2 font-18 font-weight-500">
                                            {" "}
                                            {el?.witness_name}
                                          </span>
                                        </div>
                                        <div className="d-flex space-between p-4 mt-2">
                                          <div className="w-90">
                                            <div className="d-flex common-flex-gap ">
                                              <div class="feild-group field">
                                                <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                  Incident Date
                                                </div>
                                                <div
                                                  style={{
                                                    maxWidth: "16vw",
                                                    minWidth: "16vw",
                                                  }}
                                                  className="carousel-slds-height"
                                                >
                                                  {el?.witness_pic.length ? (
                                                    <Carousel
                                                      id="carousel-three-items-example"
                                                      items={this.getCarouselProps(
                                                        el?.witness_pic,
                                                        (sub) => {
                                                          return {
                                                            id: sub?.pic_id,
                                                            src: sub?.witness_pic,
                                                          };
                                                        }
                                                      )}
                                                      itemsPerPanel={1}
                                                      onItemClick={(item) => {}}
                                                    />
                                                  ) : (
                                                    <span className="font-14 mt-5">
                                                      No Evidence Available{" "}
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="d-flex flex-column common-flex-gap">
                                                <div className="d-flex  common-flex-gap">
                                                  <div class="feild-group field">
                                                    <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                      Type
                                                    </div>
                                                    <div class="output-text">
                                                      {el?.employee_type}
                                                    </div>
                                                  </div>
                                                  {el.employee_contractor_status ===
                                                  2 ? (
                                                    <div class="feild-group field">
                                                      <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                        Contractor Company
                                                      </div>
                                                      <div class="output-text">
                                                        {el?.contractor_company}
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                                <div class="feild-group field">
                                                  <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Witness Statement
                                                  </div>
                                                  <div class="output-text">
                                                    {el?.witness_statement}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div>
                                            <div className="d-flex space-between">
                                              {isInprogress &&
                                              isApproveEnabled ? (
                                                <>
                                                  <img
                                                    src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                                                    className="cursor-pointer svg-icons mr-1"
                                                    onClick={() =>
                                                      this.debounceFunc(() => {
                                                        this.manageWitnessPayload(
                                                          el
                                                        );
                                                      })
                                                    }
                                                  />
                                                  <img
                                                    src={require("assets/sls-icons/V2. New Development/delete.svg")}
                                                    onClick={() =>
                                                      this.debounceFunc(() => {
                                                        this.manageDataGatheringParams(
                                                          "",
                                                          {
                                                            isDeleteEnable: true,
                                                            parameters: {
                                                              witness_id:
                                                                el?.witness_id,
                                                            },
                                                            url: "/listira/deletewitnessdetails",
                                                          },
                                                          false,
                                                          "deleteParams"
                                                        );
                                                      })
                                                    }
                                                    className="cursor-pointer svg-icons mr-2"
                                                  />
                                                </>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="my-5"></div>
                                    </>
                                  )
                                )}
                              </div>
                            ) : null}
                            <div className="my-4">
                              {posts?.isAddEnable ? (
                                <div>
                                  <div className="d-flex  my-5">
                                    <h3 className="font-weight-500 common-red-3 mr-3 font-18 heading-red-border ">
                                      {" "}
                                      {posts?.savedPalyoad.length
                                        ? "Edit"
                                        : "Add"}{" "}
                                      {capitalizeFirstLetter(posts?.module)}{" "}
                                      Info
                                    </h3>
                                    <div>
                                      <Tooltip
                                        id="help"
                                        align="top left"
                                        content={postTooltip[posts?.module]}
                                        variant="learnMore"
                                      >
                                        <button
                                          className="slds-button slds-button_icon"
                                          aria-describedby="help"
                                        >
                                          {" "}
                                          <Info className="w-100 cursor-pointer" />
                                        </button>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div className="border rounded p-3 d-flex space-between mt-3 w-75">
                                    <div>
                                      <div className="d-flex common-flex-gap flex-wrap">
                                        <div>
                                          <div className="d-flex space-between">
                                            <span
                                              className="slds-form-element__label"
                                              id="file-selector-primary-label-101"
                                            >
                                              Add Images
                                            </span>
                                            <div>
                                              <Tooltip
                                                id="help"
                                                align="top left"
                                                content={"Max image upload 1"}
                                                variant="learnMore"
                                              >
                                                <button
                                                  className="slds-button slds-button_icon"
                                                  aria-describedby="help"
                                                >
                                                  {" "}
                                                  <Info className="w-100 cursor-pointer" />
                                                </button>
                                              </Tooltip>
                                            </div>
                                          </div>
                                          <div class="slds-form-element__control">
                                            <div class="slds-file-selector slds-file-selector_files">
                                              <div class="slds-file-selector__dropzone">
                                                <input
                                                  type="file"
                                                  disabled={
                                                    posts?.isAddEnable &&
                                                    posts?.files.length > 0
                                                  }
                                                  class="slds-file-selector__input slds-assistive-text"
                                                  accept="image/png"
                                                  id="file-upload-input-103"
                                                  onChange={(e) =>
                                                    this._pickCropImage(
                                                      e,
                                                      "posts"
                                                    )
                                                  }
                                                  aria-labelledby="file-selector-primary-label-101 file-selector-secondary-label102"
                                                />
                                                <label
                                                  class="slds-file-selector__body"
                                                  for="file-upload-input-103"
                                                  id="file-selector-secondary-label102"
                                                >
                                                  <Upload className="cursor-pointer mx-3" />
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="slds-form-element slds-has-error">
                                          <label
                                            className="slds-form-element__label slds-m-bottom_xx-small "
                                            for="form-element-05  "
                                          >
                                            <abbr
                                              className="slds-required"
                                              title="required"
                                            >
                                              *{" "}
                                            </abbr>
                                            Notes
                                          </label>
                                          <div className="slds-form-element__control">
                                            <input
                                              type="text"
                                              id="form-element-05"
                                              value={posts.file_description}
                                              onChange={(e) =>
                                                this.manageDataGatheringParams(
                                                  "posts",
                                                  {
                                                    file_description:
                                                      e.target.value,
                                                  }
                                                )
                                              }
                                              placeholder="Add notes"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      {[...posts.files, ...posts.savedPalyoad]
                                        .length ? (
                                        <Scrollbars
                                          style={{
                                            height: "10vw",
                                            width: "35vw",
                                          }}
                                        >
                                          <div className="m-3">
                                            <Files id="files-loading-example">
                                              {[
                                                ...posts.savedPalyoad,
                                                ...posts.files,
                                              ].map((el, idx) => {
                                                if (
                                                  Object.hasOwn(el, "image_id")
                                                ) {
                                                  return (
                                                    <File
                                                      id={`${idx} - ${el.image_id}`}
                                                      key={idx}
                                                      labels={{
                                                        title: "Uploaded File",
                                                      }}
                                                      image={el?.image_link}
                                                      crop="1-by-1"
                                                    />
                                                  );
                                                } else {
                                                  return (
                                                    <File
                                                      id={`${idx} - ${el.file.name}`}
                                                      key={idx}
                                                      labels={{
                                                        title: el.file.name,
                                                      }}
                                                      image={URL.createObjectURL(
                                                        el.file
                                                      )}
                                                      crop="1-by-1"
                                                    />
                                                  );
                                                }
                                              })}
                                            </Files>
                                          </div>
                                        </Scrollbars>
                                      ) : null}
                                    </div>
                                    <div>
                                      <div className="d-flex space-between">
                                        {posts?.isLoading ? (
                                          <div className=" mx-3 mt-3">
                                            <Spinner />{" "}
                                          </div>
                                        ) : (
                                          <>
                                            <Save
                                              className="cursor-pointer "
                                              onClick={() =>
                                                this.debounceFunc(() => {
                                                  this.insertPosts();
                                                })
                                              }
                                            />
                                            <X
                                              className="cursor-pointer mx-3"
                                              onClick={() =>
                                                this.manageDataGatheringParams(
                                                  "",
                                                  {},
                                                  true
                                                )
                                              }
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex space-between">
                                  <div className="w-50 mr-5">
                                    <div className="d-flex justify-content-between align-items-center my-5">
                                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                        {" "}
                                        Paper Digital
                                      </h3>
                                      <div>
                                        {isInprogress && isApproveEnabled ? (
                                          <PlusSquare
                                            className=" ml-2 cursor-pointer common-red-3"
                                            onClick={() =>
                                              this.debounceFunc(() => {
                                                this.manageDataGatheringParams(
                                                  "posts",
                                                  {
                                                    isAddEnable: true,
                                                    module: "paper",
                                                  }
                                                );
                                              })
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div>
                                        {/* {this.state.iraDetailStep3?.paperDigital?.images.length && isInprogress ?
                                        <div>
                                          <img src={require("assets/sls-icons/V2. New Development/delete.svg")} onClick={() => this.manageDataGatheringParams("", {
                                            isDeleteEnable: true,
                                            parameters: {
                                              image_id: this.state.iraDetailStep3?.paperDigital?.images[0]['image_id']
                                            },
                                            url: "/listira/deletepaperdigital",
                                          }, false, "deleteParams")} className="cursor-pointer svg-icons mr-2" />
                                          <img src={require("assets/sls-icons/V2. New Development/Edit.svg")} onClick={() => this.manageDataGatheringParams("posts", {
                                            isAddEnable: true,
                                            module: "paper",
                                            savedPalyoad: this.state.iraDetailStep3?.paperDigital?.images,
                                            file_description: this.state.iraDetailStep3?.paperDigital?.images[0]['image_description']

                                          })} className="cursor-pointer svg-icons " />
                                        </div> : null} */}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        maxWidth: "16vw",
                                        minWidth: "16vw",
                                        minHeight: "16vw",
                                      }}
                                      className="carousel-slds-height"
                                    >
                                      {this.state.iraDetailStep3?.paperDigital
                                        ?.images?.length ? (
                                        <Carousel
                                          id="carousel-three-items-example"
                                          items={
                                            this.getCarouselProps(
                                              this.state.iraDetailStep3
                                                ?.paperDigital?.images,
                                              (sub) => {
                                                return {
                                                  id: sub?.image_id ?? "11",
                                                  src: sub?.image_link,
                                                  heading: (
                                                    <div className=" d-flex space-between ">
                                                      {" "}
                                                      <span className="output-text">
                                                        {sub?.image_description}
                                                      </span>{" "}
                                                      <div className="ml-2 d-flex w-5vw">
                                                        {isInprogress &&
                                                        isApproveEnabled ? (
                                                          <>
                                                            <img
                                                              src={require("assets/sls-icons/V2. New Development/delete.svg")}
                                                              onClick={() =>
                                                                this.debounceFunc(
                                                                  () => {
                                                                    this.manageDataGatheringParams(
                                                                      "",
                                                                      {
                                                                        isDeleteEnable: true,
                                                                        parameters:
                                                                          {
                                                                            image_id:
                                                                              sub?.image_id,
                                                                          },
                                                                        url: "/listira/deletepaperdigital",
                                                                      },
                                                                      false,
                                                                      "deleteParams"
                                                                    );
                                                                  }
                                                                )
                                                              }
                                                              className="cursor-pointer svg-icons mr-2"
                                                            />
                                                            <img
                                                              src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                                                              onClick={() =>
                                                                this.debounceFunc(
                                                                  () => {
                                                                    this.manageDataGatheringParams(
                                                                      "posts",
                                                                      {
                                                                        isAddEnable: true,
                                                                        module:
                                                                          "paper",
                                                                        savedPalyoad:
                                                                          [sub],
                                                                        file_description:
                                                                          sub?.image_description,
                                                                      }
                                                                    );
                                                                  }
                                                                )
                                                              }
                                                              className="cursor-pointer svg-icons "
                                                            />
                                                          </>
                                                        ) : null}
                                                      </div>{" "}
                                                    </div>
                                                  ),
                                                };
                                              }
                                            ) ?? []
                                          }
                                          onItemClick={(item) => {}}
                                          itemsPerPanel={1}
                                        />
                                      ) : null}
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center my-5 ">
                                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                        {" "}
                                        Position / Equipments
                                      </h3>
                                      <div>
                                        {isInprogress && isApproveEnabled ? (
                                          <PlusSquare
                                            className=" ml-2 cursor-pointer common-red-3"
                                            onClick={() =>
                                              this.debounceFunc(() => {
                                                this.manageDataGatheringParams(
                                                  "posts",
                                                  {
                                                    isAddEnable: true,
                                                    module: "position",
                                                  }
                                                );
                                              })
                                            }
                                          />
                                        ) : null}

                                        {/* {this.state.iraDetailStep3?.positionEquipments?.images.length && isInprogress ?
                                        <> <img src={require("assets/sls-icons/V2. New Development/delete.svg")} onClick={() => this.manageDataGatheringParams("", {
                                          isDeleteEnable: true,
                                          parameters: {
                                            image_id: this.state.iraDetailStep3?.positionEquipments?.images[0]['image_id']
                                          },
                                          url: "/listira/deletepositionequipments",
                                        }, false, "deleteParams")} className="cursor-pointer svg-icons mr-2" />
                                          <img src={require("assets/sls-icons/V2. New Development/Edit.svg")} onClick={() => this.manageDataGatheringParams("posts", {
                                            isAddEnable: true,
                                            module: "position",
                                            savedPalyoad: this.state.iraDetailStep3?.positionEquipments?.images,
                                            file_description: this.state.iraDetailStep3?.positionEquipments?.images[0]['image_description']

                                          })} className="cursor-pointer svg-icons " />
                                        </>
                                        : null} */}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        maxWidth: "16vw",
                                        minWidth: "16vw",
                                        minHeight: "16vw",
                                      }}
                                      className="carousel-slds-height"
                                    >
                                      <Carousel
                                        id="carousel-three-items-example"
                                        items={this.getCarouselProps(
                                          this.state.iraDetailStep3
                                            ?.positionEquipments?.images,
                                          (sub) => {
                                            return {
                                              id: sub?.image_id,
                                              src: sub?.image_link,
                                              heading: (
                                                <div className=" d-flex space-between ">
                                                  {" "}
                                                  <span className="output-text">
                                                    {sub?.image_description}
                                                  </span>{" "}
                                                  <div className="ml-2 d-flex w-5vw">
                                                    {isInprogress &&
                                                    isApproveEnabled ? (
                                                      <>
                                                        <img
                                                          src={require("assets/sls-icons/V2. New Development/delete.svg")}
                                                          onClick={() =>
                                                            this.debounceFunc(
                                                              () => {
                                                                this.manageDataGatheringParams(
                                                                  "",
                                                                  {
                                                                    isDeleteEnable: true,
                                                                    parameters:
                                                                      {
                                                                        image_id:
                                                                          sub?.image_id,
                                                                      },
                                                                    url: "/listira/deletepositionequipments",
                                                                  },
                                                                  false,
                                                                  "deleteParams"
                                                                );
                                                              }
                                                            )
                                                          }
                                                          className="cursor-pointer svg-icons mr-2"
                                                        />
                                                        <img
                                                          src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                                                          onClick={() =>
                                                            this.debounceFunc(
                                                              () => {
                                                                this.manageDataGatheringParams(
                                                                  "posts",
                                                                  {
                                                                    isAddEnable: true,
                                                                    module:
                                                                      "position",
                                                                    savedPalyoad:
                                                                      [sub],
                                                                    file_description:
                                                                      sub?.image_description,
                                                                  }
                                                                );
                                                              }
                                                            )
                                                          }
                                                          className="cursor-pointer svg-icons "
                                                        />
                                                      </>
                                                    ) : null}
                                                  </div>{" "}
                                                </div>
                                              ),
                                            };
                                          }
                                        )}
                                        onItemClick={(item) => {}}
                                        itemsPerPanel={1}
                                      />
                                    </div>
                                  </div>
                                  <div className="w-50">
                                    <div className="d-flex justify-content-between align-items-center my-5">
                                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                        {" "}
                                        Physcial Info
                                      </h3>
                                      <div>
                                        {isInprogress && isApproveEnabled ? (
                                          <PlusSquare
                                            className=" ml-2 cursor-pointer common-red-3"
                                            onClick={() =>
                                              this.debounceFunc(() => {
                                                this.manageDataGatheringParams(
                                                  "posts",
                                                  {
                                                    isAddEnable: true,
                                                    module: "physcial",
                                                  }
                                                );
                                              })
                                            }
                                          />
                                        ) : null}
                                        {/* {this.state.iraDetailStep3?.physicalInfo?.images.length && isInprogress ? <> <img src={require("assets/sls-icons/V2. New Development/delete.svg")} onClick={() => this.manageDataGatheringParams("", {
                                        isDeleteEnable: true,
                                        parameters: {
                                          image_id: this.state.iraDetailStep3?.physicalInfo?.images[0]['image_id']
                                        },
                                        url: "/listira/deletephysicalinfo",
                                      }, false, "deleteParams")} className="cursor-pointer svg-icons mr-2" />
                                        <img src={require("assets/sls-icons/V2. New Development/Edit.svg")} onClick={() => this.manageDataGatheringParams("posts", {
                                          isAddEnable: true,
                                          module: "physcial",
                                          savedPalyoad: this.state.iraDetailStep3?.physicalInfo?.images,
                                          file_description: this.state.iraDetailStep3?.physicalInfo?.images[0]['image_description']

                                        })} className="cursor-pointer svg-icons " />
                                      </>
                                        : null} */}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        maxWidth: "16vw",
                                        minWidth: "16vw",
                                        minHeight: "16vw",
                                      }}
                                      className="carousel-slds-height"
                                    >
                                      <Carousel
                                        id="carousel-three-items-example"
                                        items={this.getCarouselProps(
                                          this.state.iraDetailStep3
                                            ?.physicalInfo?.images,
                                          (sub) => {
                                            return {
                                              id: sub?.image_id,
                                              src: sub?.image_link,
                                              heading: (
                                                <div className=" d-flex space-between ">
                                                  {" "}
                                                  <span className="output-text">
                                                    {sub?.image_description}
                                                  </span>{" "}
                                                  <div className="ml-2 d-flex w-5vw">
                                                    {isInprogress &&
                                                    isApproveEnabled ? (
                                                      <>
                                                        <img
                                                          src={require("assets/sls-icons/V2. New Development/delete.svg")}
                                                          onClick={() =>
                                                            this.debounceFunc(
                                                              () => {
                                                                this.manageDataGatheringParams(
                                                                  "",
                                                                  {
                                                                    isDeleteEnable: true,
                                                                    parameters:
                                                                      {
                                                                        image_id:
                                                                          sub?.image_id,
                                                                      },
                                                                    url: "/listira/deletephysicalinfo",
                                                                  },
                                                                  false,
                                                                  "deleteParams"
                                                                );
                                                              }
                                                            )
                                                          }
                                                          className="cursor-pointer svg-icons mr-2"
                                                        />
                                                        <img
                                                          src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                                                          onClick={() =>
                                                            this.debounceFunc(
                                                              () => {
                                                                this.manageDataGatheringParams(
                                                                  "posts",
                                                                  {
                                                                    isAddEnable: true,
                                                                    module:
                                                                      "physcial",
                                                                    savedPalyoad:
                                                                      [sub],
                                                                    file_description:
                                                                      sub?.image_description,
                                                                  }
                                                                );
                                                              }
                                                            )
                                                          }
                                                          className="cursor-pointer svg-icons "
                                                        />
                                                      </>
                                                    ) : null}
                                                  </div>{" "}
                                                </div>
                                              ),
                                            };
                                          }
                                        )}
                                        onItemClick={(item) => {}}
                                        itemsPerPanel={1}
                                      />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center my-5 ">
                                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                        {" "}
                                        Sketch
                                      </h3>
                                      <div>
                                        {isInprogress && isApproveEnabled ? (
                                          <PlusSquare
                                            className=" ml-2 cursor-pointer common-red-3"
                                            onClick={() =>
                                              this.debounceFunc(() => {
                                                this.manageDataGatheringParams(
                                                  "posts",
                                                  {
                                                    isAddEnable: true,
                                                    module: "sketch",
                                                  }
                                                );
                                              })
                                            }
                                          />
                                        ) : null}
                                        {/* {this.state.iraDetailStep3?.sketch?.images.length && isInprogress ? <> <img src={require("assets/sls-icons/V2. New Development/delete.svg")} onClick={() => this.manageDataGatheringParams("", {
                                        isDeleteEnable: true,
                                        parameters: {
                                          image_id: this.state.iraDetailStep3?.sketch?.images[0]['image_id']
                                        },
                                        url: "/listira/deletesketch",
                                      }, false, "deleteParams")} className="cursor-pointer svg-icons mr-2" />
                                        <img src={require("assets/sls-icons/V2. New Development/Edit.svg")} onClick={() => this.manageDataGatheringParams("posts", {
                                          isAddEnable: true,
                                          module: "sketch",
                                          savedPalyoad: this.state.iraDetailStep3?.sketch?.images,
                                          file_description: this.state.iraDetailStep3?.sketch?.images[0]['image_description']

                                        })} className="cursor-pointer svg-icons " />
                                      </>
                                        : null} */}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        maxWidth: "16vw",
                                        minWidth: "16vw",
                                      }}
                                      className="carousel-slds-height"
                                    >
                                      <Carousel
                                        id="carousel-three-items-example"
                                        items={this.getCarouselProps(
                                          this.state.iraDetailStep3?.sketch
                                            ?.images,
                                          (sub) => {
                                            return {
                                              id: sub?.image_id,
                                              src: sub?.image_link,
                                              heading: (
                                                <div className=" d-flex space-between ">
                                                  {" "}
                                                  <span className="output-text">
                                                    {sub?.image_description}
                                                  </span>{" "}
                                                  <div className="ml-2 d-flex w-5vw">
                                                    {isInprogress &&
                                                    isApproveEnabled ? (
                                                      <>
                                                        <img
                                                          src={require("assets/sls-icons/V2. New Development/delete.svg")}
                                                          onClick={() =>
                                                            this.manageDataGatheringParams(
                                                              "",
                                                              {
                                                                isDeleteEnable: true,
                                                                parameters: {
                                                                  image_id:
                                                                    sub?.image_id,
                                                                },
                                                                url: "/listira/deletesketch",
                                                              },
                                                              false,
                                                              "deleteParams"
                                                            )
                                                          }
                                                          className="cursor-pointer svg-icons mr-2"
                                                        />
                                                        <img
                                                          src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                                                          onClick={() =>
                                                            this.debounceFunc(
                                                              () => {
                                                                this.manageDataGatheringParams(
                                                                  "posts",
                                                                  {
                                                                    isAddEnable: true,
                                                                    module:
                                                                      "sketch",
                                                                    savedPalyoad:
                                                                      [sub],
                                                                    file_description:
                                                                      sub?.image_description,
                                                                  }
                                                                );
                                                              }
                                                            )
                                                          }
                                                          className="cursor-pointer svg-icons "
                                                        />
                                                      </>
                                                    ) : null}
                                                  </div>{" "}
                                                </div>
                                              ),
                                            };
                                          }
                                        )}
                                        onItemClick={(item) => {}}
                                        itemsPerPanel={1}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="my-4">
                              <div className="d-flex justify-content-between align-items-center my-5">
                                <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                  {" "}
                                  Manday's / Cost
                                </h3>
                                <div>
                                  {!costList?.length &&
                                  (isInprogress || isCompleted) &&
                                  isApproveEnabled &&
                                  !cost?.isAddEnable ? (
                                    <img
                                      src={require("assets/sls-icons/V2. New Development/ADD2.svg")}
                                      className="cursor-pointer svg-icons"
                                      onClick={() =>
                                        this.debounceFunc(() => {
                                          this.manageDataGatheringParams(
                                            "cost",
                                            {
                                              isAddEnable: true,
                                            }
                                          );
                                        })
                                      }
                                    />
                                  ) : null}

                                  {costList?.length > 0 &&
                                  (isInprogress || isCompleted) &&
                                  isApproveEnabled &&
                                  !cost?.isAddEnable ? (
                                    <img
                                      src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                                      className="svg-icons  cursor-pointer"
                                      aria-hidden="true"
                                      onClick={() =>
                                        this.debounceFunc(() => {
                                          this.manageCostPayload(costList[0]);
                                        })
                                      }
                                    />
                                  ) : null}
                                  {costList?.length &&
                                  this.state.reportStatus === "progress" &&
                                  isApproveEnabled &&
                                  !cost?.isAddEnable ? (
                                    <img
                                      src={require("assets/sls-icons/V2. New Development/delete.svg")}
                                      onClick={() =>
                                        this.debounceFunc(() => {
                                          this.manageDataGatheringParams(
                                            "",
                                            {
                                              isDeleteEnable: true,
                                              parameters: {
                                                ira_cost_detail_id:
                                                  costList[0]
                                                    ?.ira_cost_involved_id,
                                              },
                                              url: "/listira/step3deletecost",
                                            },
                                            false,
                                            "deleteParams"
                                          );
                                        })
                                      }
                                      className="cursor-pointer svg-icons ml-2"
                                    />
                                  ) : null}
                                </div>
                              </div>
                              {cost?.isAddEnable ? (
                                <div className="border rounded p-3 d-flex space-between w-100">
                                  <div
                                    className="d-flex flex-wrap "
                                    style={{
                                      columnGap: "0.9vw",
                                      rowGap: "0.3vw",
                                    }}
                                  >
                                    <Input
                                      label={"Mandays Lost"}
                                      placeholder="Enter mandays"
                                      value={cost?.mandays_lost}
                                      disabled={costList?.length && isCompleted}
                                      onChange={(e) =>
                                        this.manageDataGatheringParams("cost", {
                                          mandays_lost: e.target.value,
                                        })
                                      }
                                    />
                                    <div>
                                      <Input
                                        label={"Cost involved in rupees"}
                                        placeholder="Enter rupees"
                                        value={cost?.cost_involved}
                                        disabled={
                                          costList?.length && isCompleted
                                        }
                                        onChange={(e) =>
                                          this.manageDataGatheringParams(
                                            "cost",
                                            { cost_involved: e.target.value }
                                          )
                                        }
                                      />
                                    </div>
                                    <div>
                                      <div className="d-flex space-between">
                                        <span
                                          className="slds-form-element__label"
                                          id="file-selector-primary-label-101"
                                        >
                                          Upload Files
                                        </span>
                                        <div>
                                          <Tooltip
                                            id="help"
                                            align="top left"
                                            content={"Max file upload 1"}
                                            variant="learnMore"
                                          >
                                            <button
                                              className="slds-button slds-button_icon"
                                              aria-describedby="help"
                                            >
                                              {" "}
                                              <Info className="w-100 cursor-pointer" />
                                            </button>
                                          </Tooltip>
                                        </div>
                                      </div>
                                      {/* <div class="slds-form-element__control">
                                      <div class="slds-file-selector slds-file-selector_files">
                                        <div class="slds-file-selector__dropzone">
                                          <input type="file" disabled={cost?.isAddEnable && cost?.files.length > 0} class="slds-file-selector__input slds-assistive-text" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" id="file-upload-input-103" onChange={(e) => this._pickCropImage(e, "cost")} aria-labelledby="file-selector-primary-label-101 file-selector-secondary-label102" />
                                          <label class="slds-file-selector__body" for="file-upload-input-103" id="file-selector-secondary-label102" >
                                            <Upload className="cursor-pointer mx-3" />
                                          </label>
                                        </div>
                                      </div>
                                    </div> */}
                                      <div className="my-2">
                                        <MyDropzone
                                          type={"9"}
                                          uploadFile={(file) => {
                                            this.manageDataGatheringParams(
                                              "cost",
                                              { files: file }
                                            );
                                          }}
                                        />
                                      </div>
                                      {costList?.length > 0 &&
                                      costList[0]?.file_nmae ? (
                                        <div style={{ width: "13rem" }}>
                                          <div class="slds-file slds-file_card slds-has-title">
                                            <figure>
                                              <a class="slds-file__crop">
                                                <span class="slds-assistive-text">
                                                  Preview:
                                                </span>
                                                <img
                                                  src={
                                                    this.getFileName(
                                                      costList[0]?.file_nmae,
                                                      2
                                                    ) !== "pdf"
                                                      ? costList[0]?.file_nmae
                                                      : require("../../../../assets/sls-icons/pdfIcon.svg")
                                                  }
                                                  alt="Description of the image"
                                                />
                                              </a>
                                              <figcaption class="slds-file__title slds-file__title_card">
                                                <div class="slds-media slds-media_small slds-media_center">
                                                  <div class="slds-media__figure slds-line-height_reset user-icon mr-1">
                                                    <span
                                                      class="slds-icon_container"
                                                      title="pdf"
                                                    >
                                                      <img
                                                        src={require(this.getFileName(
                                                          costList[0]
                                                            ?.file_nmae,
                                                          2
                                                        ) !== "pdf"
                                                          ? "../../../../assets/sls-icons/imageIcon.svg"
                                                          : "../../../../assets/sls-icons/pdfIcon.svg")}
                                                      />
                                                    </span>
                                                  </div>
                                                  <div class="slds-media__body d-flex">
                                                    <span
                                                      class="slds-file__text slds-truncate"
                                                      title="File"
                                                    >
                                                      {this.getFileName(
                                                        costList[0]?.file_nmae
                                                      )}
                                                    </span>
                                                    <Eye
                                                      className="cursor-pointer ml-1"
                                                      onClick={() =>
                                                        this.debounceFunc(
                                                          () => {
                                                            this.setState({
                                                              docUrl:
                                                                costList[0]
                                                                  ?.file_nmae,
                                                            });
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </figcaption>
                                            </figure>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                    <div>
                                      <Combobox
                                        id="combobox-readonly-single"
                                        events={{
                                          onSelect: (event, { selection }) => {
                                            this.manageDataGatheringParams(
                                              "cost",
                                              {
                                                return_to_work_id: selection,
                                              }
                                            );
                                          },
                                        }}
                                        labels={{
                                          label: "Returned to work",
                                          placeholder: "Select  option",
                                        }}
                                        options={this.state.returnToWorkList}
                                        selection={cost.return_to_work_id}
                                        variant="readonly"
                                      />
                                    </div>
                                    <div className="w-100">
                                      {cost.files?.length ? (
                                        <Scrollbars
                                          style={{
                                            minHeight: "10vw",
                                            maxHeight: "12vw",
                                            width: "w-5vw",
                                          }}
                                        >
                                          <div className="m-3">
                                            <Files id="files-loading-example">
                                              {cost.files?.map((el, idx) => (
                                                <File
                                                  id={`${idx} - ${el.file.name}`}
                                                  key={idx}
                                                  labels={{
                                                    title: el.file.name,
                                                  }}
                                                  image={URL.createObjectURL(
                                                    el.file
                                                  )}
                                                  crop="1-by-1"
                                                />
                                              ))}
                                            </Files>
                                          </div>
                                        </Scrollbars>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div>
                                    <div className="d-flex space-between">
                                      {cost.isLoading ? (
                                        <div className=" mx-3 mt-3">
                                          <Spinner />{" "}
                                        </div>
                                      ) : (
                                        <>
                                          <img
                                            src={require("assets/sls-icons/V2. New Development/save.svg")}
                                            className="cursor-pointer svg-icons mr-1"
                                            onClick={() =>
                                              this.debounceFunc(() => {
                                                this.insertCost();
                                              })
                                            }
                                          />
                                          <img
                                            src={require("assets/sls-icons/V2. New Development/Cancel.svg")}
                                            className="cursor-pointer svg-icons"
                                            onClick={() =>
                                              this.debounceFunc(() => {
                                                this.manageDataGatheringParams(
                                                  "",
                                                  {},
                                                  true
                                                );
                                              })
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : costList?.length ? (
                                <div className="d-flex  common-flex-gap">
                                  <div class="feild-group field">
                                    <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                      Mandays Lost
                                    </div>
                                    <div class="output-text">
                                      {costList[0]?.mandays_lost}
                                    </div>
                                  </div>
                                  <div class="feild-group field">
                                    <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                      Cost involved in rupees
                                    </div>
                                    <div class="output-text">
                                      {costList[0]?.cost_involved}
                                    </div>
                                  </div>
                                  <div class="feild-group field">
                                    <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                      Returned to work
                                    </div>
                                    <div class="output-text">
                                      {costList[0]?.return_to_work_list.map(
                                        (el) => el?.return_to_work_info
                                      )}
                                    </div>
                                  </div>
                                  <div class="feild-group field">
                                    <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                      Uploaded File
                                    </div>
                                    <div class="output-text mt-4">
                                      {/* <Files id="files-loading-example">
                                      <File
                                        id={` - demo-id`}
                                        labels={{
                                          title: "Uploaded File",
                                        }}
                                        image={costList[0]?.file_nmae}
                                        crop="1-by-1"
                                      />


                                    </Files> */}
                                      <div style={{ width: "13rem" }}>
                                        <div class="slds-file slds-file_card slds-has-title">
                                          <figure>
                                            <a class="slds-file__crop">
                                              <span class="slds-assistive-text">
                                                Preview:
                                              </span>
                                              <img
                                                src={
                                                  this.getFileName(
                                                    costList[0]?.file_nmae,
                                                    2
                                                  ) !== "pdf"
                                                    ? costList[0]?.file_nmae
                                                    : require("../../../../assets/sls-icons/pdfIcon.svg")
                                                }
                                                alt="Description of the image"
                                              />
                                            </a>
                                            <figcaption class="slds-file__title slds-file__title_card">
                                              <div class="slds-media slds-media_small slds-media_center">
                                                <div class="slds-media__figure slds-line-height_reset user-icon mr-1">
                                                  <span
                                                    class="slds-icon_container"
                                                    title="pdf"
                                                  >
                                                    <img
                                                      src={require(this.getFileName(
                                                        costList[0]?.file_nmae,
                                                        2
                                                      ) !== "pdf"
                                                        ? "../../../../assets/sls-icons/imageIcon.svg"
                                                        : "../../../../assets/sls-icons/pdfIcon.svg")}
                                                    />
                                                  </span>
                                                </div>
                                                <div class="slds-media__body d-flex">
                                                  <span
                                                    class="slds-file__text slds-truncate"
                                                    title="File"
                                                  >
                                                    {this.getFileName(
                                                      costList[0]?.file_nmae
                                                    )}
                                                  </span>
                                                  <Eye
                                                    className="cursor-pointer ml-1"
                                                    onClick={() =>
                                                      this.debounceFunc(() => {
                                                        this.setState({
                                                          docUrl:
                                                            costList[0]
                                                              ?.file_nmae,
                                                        });
                                                      })
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </figcaption>
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            {/* // Britannia v01
              //  Making this change to ...(Integrate this 5m analysis because of britian custoimization ) */}
                            <div className="my-4 w-90">
                              <div className="d-flex justify-content-between align-items-center my-5">
                                <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                  {" "}
                                  5M Analysis{" "}
                                </h3>
                              </div>
                              <div className=" fishbon-chart-wrapper mx-3">
                                <FishboneChart
                                  data={this.manageFishBoneChart(
                                    this.state.fishData,
                                    iraDetailStep3?.ira_5M_details,
                                    isInprogress && isApproveEnabled
                                  )}
                                  ref={this.selectorRef}
                                />
                              </div>
                            </div>
                            <div className="my-4 w-90">
                              <div className="d-flex justify-content-between align-items-center my-5">
                                <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                  {" "}
                                  Job Analysis Question
                                </h3>
                                {this.state.reportStatus === "progress" &&
                                !iraDetailStep3?.other_info?.question_response
                                  ?.length &&
                                isApproveEnabled ? (
                                  <PlusSquare
                                    className=" ml-2 cursor-pointer common-red-3"
                                    onClick={() =>
                                      this.debounceFunc(() => {
                                        this.manageDataGatheringParams(
                                          "analysis",
                                          {
                                            isAddEnable: true,
                                            module: "paper",
                                          }
                                        );
                                      })
                                    }
                                  />
                                ) : null}
                              </div>
                              {analysis?.isAddEnable ? (
                                <>
                                  <div>
                                    <div className="border rounded p-3 d-flex space-between mt-3 w-75">
                                      <div className="mr-3 w-100">
                                        <Scrollbars style={{ height: "12vw" }}>
                                          {this.state.OtherInfomasterlist?.map(
                                            (data, index) => (
                                              <div
                                                key={index}
                                                className="d-flex  flex-column mr-3 my-3"
                                              >
                                                <div>
                                                  <span className="slds-form-element__label font-16 font-weight-500">
                                                    {data.questions}
                                                  </span>
                                                </div>
                                                <div>
                                                  <Checkbox
                                                    checked={data.response == 1}
                                                    labels={{
                                                      label: "Yes",
                                                    }}
                                                    value={"Yes"}
                                                    onChange={() => {
                                                      this.setState({
                                                        OtherInfomasterlist:
                                                          this.state.OtherInfomasterlist.map(
                                                            (item, i) =>
                                                              index === i
                                                                ? {
                                                                    ...item,
                                                                    response: 1,
                                                                  }
                                                                : item
                                                          ),
                                                      });
                                                    }}
                                                  />
                                                  <Checkbox
                                                    checked={data.response == 0}
                                                    labels={{
                                                      label: "No",
                                                    }}
                                                    value={"No"}
                                                    onChange={() => {
                                                      this.setState({
                                                        OtherInfomasterlist:
                                                          this.state.OtherInfomasterlist.map(
                                                            (item, i) =>
                                                              index === i
                                                                ? {
                                                                    ...item,
                                                                    response: 0,
                                                                  }
                                                                : item
                                                          ),
                                                      });
                                                    }}
                                                  />
                                                </div>
                                                <div>
                                                  <Input
                                                    label={"Add Notes"}
                                                    placeholder="Enter notes"
                                                    value={data.notes}
                                                    className="w-75"
                                                    onChange={(e) => {
                                                      this.setState({
                                                        OtherInfomasterlist:
                                                          this.state.OtherInfomasterlist.map(
                                                            (item, i) =>
                                                              index === i
                                                                ? {
                                                                    ...item,
                                                                    notes:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                : item
                                                          ),
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </Scrollbars>
                                      </div>
                                      <div>
                                        <div className="d-flex space-between">
                                          {analysis?.isLoading ? (
                                            <div className=" mx-3 mt-3">
                                              <Spinner />{" "}
                                            </div>
                                          ) : (
                                            <>
                                              <Save
                                                className="cursor-pointer "
                                                onClick={() =>
                                                  this.debounceFunc(() => {
                                                    this.addOtherInfo();
                                                  })
                                                }
                                              />
                                              <X
                                                className="cursor-pointer mx-3"
                                                onClick={() =>
                                                  this.debounceFunc(() => {
                                                    this.manageDataGatheringParams(
                                                      "",
                                                      {},
                                                      true
                                                    );
                                                  })
                                                }
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {this.state.reportStatus === "progress" ? (
                                    <>
                                      <Scrollbars style={{ height: "14vw" }}>
                                        {iraDetailStep3?.other_info?.question_response?.map(
                                          (el, idx) => (
                                            <>
                                              {" "}
                                              <div
                                                className=" my-1 horizontal-timeline-wrapper  rounded shadow-none border   w-75 "
                                                style={{ minHeight: "auto" }}
                                                key={idx}
                                              >
                                                <div className="timeline-header d-flex align-items-baseline p-0">
                                                  <span class="badge bg-transparent border common-black-1 font-weight-500 font-18 px-4 py-3 rounded-pill">
                                                    Q {idx + 1}{" "}
                                                  </span>
                                                </div>
                                                <div className="d-flex space-between p-4 mt-4">
                                                  <div className="d-flex flex-column">
                                                    <div class="feild-group field d-flex common-flex-gap">
                                                      <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                        Question :
                                                      </div>
                                                      <div class="output-text">
                                                        {el?.questions}
                                                      </div>
                                                    </div>
                                                    <div class="feild-group field d-flex common-flex-gap">
                                                      <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                        Option :
                                                      </div>
                                                      <div class="d-flex">
                                                        <Checkbox
                                                          checked={
                                                            el?.response ===
                                                            "Yes"
                                                          }
                                                          labels={{
                                                            label: "Yes",
                                                          }}
                                                          value={"Yes"}
                                                        />
                                                        <Checkbox
                                                          checked={
                                                            el?.response ===
                                                            "No"
                                                          }
                                                          labels={{
                                                            label: "No",
                                                          }}
                                                          value={"No"}
                                                          className="ml-3"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="feild-group field d-flex common-flex-gap">
                                                      <div class="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                        Notes :
                                                      </div>
                                                      <div class="output-text">
                                                        {el?.notes}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex space-between">
                                                      {isInprogress &&
                                                      isApproveEnabled ? (
                                                        <img
                                                          src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                                                          className="cursor-pointer svg-icons mr-1"
                                                          onClick={() =>
                                                            this.debounceFunc(
                                                              () => {
                                                                this.manageJobAnalysisEdit(
                                                                  el
                                                                );
                                                              }
                                                            )
                                                          }
                                                        />
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="my-5"></div>{" "}
                                            </>
                                          )
                                        )}
                                      </Scrollbars>
                                    </>
                                  ) : (
                                    <>
                                      <div class="slds-grid slds-gutters">
                                        <div class="slds-col ">
                                          <SLDBadge
                                            content="Questions"
                                            className="px-5 py-2 font-weight-500 font-18 mb-4"
                                          />
                                        </div>
                                        <div class="slds-col text-center">
                                          <SLDBadge
                                            content="Options"
                                            className="px-5 py-2 font-weight-500   mb-4 font-18"
                                          />
                                        </div>
                                        <div class="slds-col ">
                                          <SLDBadge
                                            content="Notes"
                                            className="px-5 py-2 font-weight-500   mb-4 font-18"
                                          />
                                        </div>
                                      </div>
                                      <Scrollbars style={{ height: "12vw" }}>
                                        {iraDetailStep3?.other_info?.question_response?.map(
                                          (el, idx) => (
                                            <div
                                              className="mx-2 w-75"
                                              key={idx}
                                            >
                                              <div class="slds-grid slds-gutters">
                                                <div class="slds-col slds-size_1-of-3">
                                                  <div className="my-2 mr-3">
                                                    <span className=" font-weight-500 font-14 ">
                                                      {el?.questions}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="slds-col text-center">
                                                  <div className="my-2 mr-3">
                                                    <SLDBadge
                                                      color={`${
                                                        el?.response === "Yes"
                                                          ? "inverse"
                                                          : ""
                                                      }`}
                                                      content="Y"
                                                      className="px-3 py-2 font-weight-500   mb-4 font-18"
                                                    />
                                                    <SLDBadge
                                                      color={`${
                                                        el?.response !== "Yes"
                                                          ? "inverse"
                                                          : ""
                                                      }`}
                                                      content="N"
                                                      className="px-3 py-2 font-weight-500   mb-4 font-18"
                                                    />
                                                  </div>
                                                </div>
                                                <div class="slds-col">
                                                  <div className="my-2">
                                                    <span className=" font-weight-500 font-14 ">
                                                      {el?.response}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </Scrollbars>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="my-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                            {" "}
                            Step 4 : Root Cause Analysis
                          </h3>
                        </div>
                        <div className="my-4 ">
                          <div className="d-flex justify-content-between align-items-center my-5">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "0.5vw",
                              }}
                            >
                              <div
                                style={{
                                  background: "rgba(217, 217, 217, 0.5)",
                                  borderRadius: "28px",
                                }}
                                className="px-3 py-2 common-black-1 font-weight-500  text-center"
                              >
                                Chronology of Events
                              </div>
                            </div>
                            {isInprogress && isApproveEnabled ? (
                              <PlusSquare
                                className=" ml-2 cursor-pointer common-red-3"
                                onClick={() =>
                                  this.debounceFunc(() => {
                                    this.manageDataGatheringParams(
                                      "chronology",
                                      {
                                        isAddEnable: true,
                                      }
                                    );
                                  })
                                }
                              />
                            ) : null}
                          </div>
                          {chronology.isAddEnable ? (
                            <div className="border rounded p-3 d-flex space-between w-75">
                              <div
                                className="d-flex flex-wrap "
                                style={{ columnGap: "0.9vw", rowGap: "0.3vw" }}
                              >
                                <div>
                                  <Datepicker
                                    labels={{
                                      label: " Date",
                                    }}
                                    onChange={(event, data) => {
                                      this.manageDataGatheringParams(
                                        "chronology",
                                        { date: data.date }
                                      );
                                    }}
                                    formatter={(date) => {
                                      return date
                                        ? moment(date).format("M/D/YYYY")
                                        : "";
                                    }}
                                    value={chronology?.date}
                                  />
                                </div>
                                <div>
                                  <Timepicker
                                    label="Time"
                                    stepInMinutes={30}
                                    onDateChange={(date, inputStr) => {
                                      this.manageDataGatheringParams(
                                        "chronology",
                                        { time: inputStr, dateTimeOf: date }
                                      );
                                    }}
                                    placeholder="Choose time"
                                    strValue={chronology?.time}
                                  />
                                </div>
                                <Input
                                  assistiveText={{ label: "Info Source" }}
                                  label={"Info Source"}
                                  placeholder="Enter source"
                                  value={chronology?.info_source}
                                  onChange={(e) =>
                                    this.manageDataGatheringParams(
                                      "chronology",
                                      { info_source: e.target.value }
                                    )
                                  }
                                />
                                <div className="w-50">
                                  <Input
                                    assistiveText={{ label: "Description" }}
                                    label={"Description"}
                                    placeholder="Enter description"
                                    value={chronology?.description}
                                    onChange={(e) =>
                                      this.manageDataGatheringParams(
                                        "chronology",
                                        { description: e.target.value }
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div>
                                <div className="d-flex space-between">
                                  {chronology.isLoading ? (
                                    <div className=" mx-3 mt-3">
                                      <Spinner />{" "}
                                    </div>
                                  ) : (
                                    <>
                                      <Save
                                        className="cursor-pointer "
                                        onClick={() =>
                                          this.debounceFunc(() => {
                                            this.insertChronology();
                                          })
                                        }
                                      />
                                      <X
                                        className="cursor-pointer mx-3"
                                        onClick={() =>
                                          this.debounceFunc(() => {
                                            this.manageDataGatheringParams(
                                              "",
                                              {},
                                              true
                                            );
                                          })
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {this.state.reportStatus === "completed" ? (
                                <div id="ira-details-chronology-events"></div>
                              ) : (
                                <HorizontaTimeline
                                  data={this.renderChronology(
                                    iraDetailStep4?.chronology_of_events,
                                    this.state,
                                    isInprogress && isApproveEnabled
                                  )}
                                  reInitlization={[iraBasicInfo, this.state]}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          className="d-flex justify-content-between align-items-center my-5"
                          onMouseEnter={() => {
                            if (
                              !this.state.isRootCauseTooltipEnabed &&
                              !isRootCauseTabsDisabled
                            ) {
                              this.setState({
                                isRootCauseTooltipEnabed: true,
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            if (this.state.isRootCauseTooltipEnabed) {
                              this.setState({
                                isRootCauseTooltipEnabed: false,
                              });
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "0.5vw",
                            }}
                          >
                            <div
                              style={{
                                background: "rgba(217, 217, 217, 0.5)",
                                borderRadius: "28px",
                              }}
                              className="px-3 py-2 common-black-1 font-weight-500  text-center"
                            >
                              Root Cause
                            </div>{" "}
                            &nbsp;{" "}
                            {!isRootCauseTabsDisabled && (
                              <Tooltip
                                id="help"
                                align="top left"
                                isOpen={this.state.isRootCauseTooltipEnabed}
                                content={
                                  <div>
                                    User can choose one method out of 2 method
                                    <br />
                                    <div className="my-2">
                                      1st method - 5 WHY Analysis
                                    </div>
                                    <div className="my-2 mb-0">
                                      2nd Method - CLC
                                    </div>
                                    <div>
                                      {" "}
                                      Once you choose the one method other
                                      method will be disable automatically.
                                    </div>
                                  </div>
                                }
                                variant="learnMore"
                              >
                                <button
                                  className="slds-button slds-button_icon mb-4"
                                  aria-describedby="help"
                                >
                                  {" "}
                                  <Info className="w-100 cursor-pointer" />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                          {!isRootCauseTabsDisabled && (
                            <HorizontalNavTab
                              tabs={[
                                { tabName: "5 WHY Analysis", key: "5 WHY Analysis" },
                                {
                                  tabName: "CLC Analysis",
                                  key: "CLC Analysis",
                                },
                              ]}
                              activeItem={this.state.rootCauseActiveType}
                              handleChange={(key) =>
                                this.setState({ rootCauseActiveType: key })
                              }
                            />
                          )}
                          <div>
                            {!isCompleted ? (
                              isTreeAvailable &&
                              (this.state.iraDetail
                                ?.step_4_root_cause_option_selected ||
                                this.state.rootCauseActiveType) ===
                                "CLC Analysis" &&
                              isApproveEnabled ? (
                                <>
                                  <img
                                    src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                                    className="cursor-pointer mr-2"
                                    style={{ height: "1.8vw" }}
                                    onClick={() =>
                                      this.debounceFunc(() => {
                                        this._intialValue(iraDetailStep4);
                                      })
                                    }
                                  />
                                </>
                              ) : isInprogress &&
                                isApproveEnabled &&
                                (this.state.rootCauseActiveType ||
                                  this.state.iraDetail
                                    ?.step_4_root_cause_option_selected) ===
                                  "CLC Analysis" ? (
                                <PlusSquare
                                  className=" ml-2 cursor-pointer common-red-3"
                                  onClick={() =>
                                    this.debounceFunc(() => {
                                      this.manageDataGatheringParams(
                                        "rootCause",
                                        { isAddEnable: true }
                                      );
                                    })
                                  }
                                />
                              ) : null
                            ) : null}
                          </div>
                        </div>
                        {(this.state.iraDetail
                          ?.step_4_root_cause_option_selected ||
                          this.state.rootCauseActiveType) === "CLC Analysis" ? (
                          <div>
                            {rootCause.isAddEnable ? (
                              <div className="border rounded p-3 d-flex space-between w-75">
                                <div
                                  className="d-flex w-75 flex-wrap flex-column"
                                  style={{
                                    columnGap: "0.9vw",
                                    rowGap: "0.3vw",
                                  }}
                                >
                                  <HorizontalNavTab
                                    classes=" mb-4"
                                    tabs={rootCauseMenus}
                                    activeItem={rootCause.section}
                                    handleChange={(key) =>
                                      this.manageDataGatheringParams(
                                        "rootCause",
                                        { section: key }
                                      )
                                    }
                                  />

                                  <div className="d-flex  flex-column">
                                    {rootCause.section === "1" ? (
                                      <>
                                        <div className="d-flex  ">
                                          <span
                                            className="slds-form-element__label"
                                            id="file-selector-primary-label-101"
                                          >
                                            Probable Cause
                                          </span>
                                          <Tooltip
                                            id="help"
                                            align="top left"
                                            content={
                                              "Enter what could have cause the incident? and choose the category"
                                            }
                                            variant="learnMore"
                                          >
                                            <button
                                              className="slds-button slds-button_icon"
                                              aria-describedby="help"
                                            >
                                              {" "}
                                              <Info className="w-100 cursor-pointer" />
                                            </button>
                                          </Tooltip>
                                        </div>
                                        <Input
                                          placeholder="Enter cause"
                                          value={rootCause?.probable_cause}
                                          onChange={(e) =>
                                            this.manageDataGatheringParams(
                                              "rootCause",
                                              { probable_cause: e.target.value }
                                            )
                                          }
                                        />
                                        <Input
                                          label="Description"
                                          placeholder="Enter description"
                                          value={rootCause?.description}
                                          onChange={(e) =>
                                            this.manageDataGatheringParams(
                                              "rootCause",
                                              { description: e.target.value }
                                            )
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <div className="d-flex  ">
                                          <span
                                            className="slds-form-element__label"
                                            id="file-selector-primary-label-101"
                                          >
                                            Critical Factor
                                          </span>
                                          <Tooltip
                                            id="help"
                                            align="top left"
                                            content={
                                              "Enter the contributing factors and choose the category"
                                            }
                                            variant="learnMore"
                                          >
                                            <button
                                              className="slds-button slds-button_icon"
                                              aria-describedby="help"
                                            >
                                              {" "}
                                              <Info className="w-100 cursor-pointer" />
                                            </button>
                                          </Tooltip>
                                        </div>
                                        <Input
                                          placeholder="Enter factor"
                                          value={rootCause?.contributing_factor}
                                          onChange={(e) =>
                                            this.manageDataGatheringParams(
                                              "rootCause",
                                              {
                                                contributing_factor:
                                                  e.target.value,
                                              }
                                            )
                                          }
                                        />

                                        <div className="w-100 ">
                                          <Scrollbars
                                            style={{ height: "14vw" }}
                                          >
                                            <div>
                                              <label className="slds-form-element__label font-16 my-2 font-weight-500">
                                                {" "}
                                                Casual Factor{" "}
                                              </label>
                                              {this.state.main_category &&
                                                this.state.main_category?.map(
                                                  (data, mainIndex) => (
                                                    <div key={mainIndex}>
                                                      <Checkbox
                                                        checked={data.isChecked}
                                                        labels={{
                                                          label:
                                                            data[
                                                              "main_category"
                                                            ],
                                                        }}
                                                        value={
                                                          data["main_category"]
                                                        }
                                                        onChange={() => {
                                                          this._toggleMainCategoryCheck(
                                                            data.main_category_id
                                                          );
                                                        }}
                                                      />

                                                      {data.isChecked && (
                                                        <div className="ml-3 my-1">
                                                          <label className="slds-form-element__label font-16 my-2 font-weight-500 mb-2">
                                                            {" "}
                                                            Problem Categories{" "}
                                                          </label>
                                                          {data.sub_category.map(
                                                            (subData) => (
                                                              <>
                                                                <Checkbox
                                                                  labels={{
                                                                    label:
                                                                      subData[
                                                                        "sub_category"
                                                                      ],
                                                                  }}
                                                                  checked={
                                                                    subData.isChecked
                                                                  }
                                                                  value={
                                                                    subData[
                                                                      "sub_category"
                                                                    ]
                                                                  }
                                                                  onChange={() =>
                                                                    this._toggleSubCategoryCheck(
                                                                      data.main_category_id,
                                                                      subData.sub_category_id
                                                                    )
                                                                  }
                                                                />
                                                                {subData.isChecked && (
                                                                  <div className="ml-3 my-1">
                                                                    <label className="slds-form-element__label font-16 my-2 font-weight-500 mb-2">
                                                                      {" "}
                                                                      Major Root
                                                                      Cause
                                                                      Category{" "}
                                                                    </label>
                                                                    {subData.major_root_cause_category.map(
                                                                      (
                                                                        majorData
                                                                      ) => (
                                                                        <>
                                                                          <Checkbox
                                                                            labels={{
                                                                              label:
                                                                                majorData[
                                                                                  "major_root_cause_category"
                                                                                ],
                                                                            }}
                                                                            checked={
                                                                              majorData.isChecked
                                                                            }
                                                                            value={
                                                                              subData[
                                                                                "major_root_cause_category"
                                                                              ]
                                                                            }
                                                                            onChange={() =>
                                                                              this._toggleMajorCategoryCheck(
                                                                                data.main_category_id,
                                                                                subData.sub_category_id,
                                                                                majorData.major_root_cause_category_id
                                                                              )
                                                                            }
                                                                          />
                                                                          {majorData.isChecked &&
                                                                            data.isChecked &&
                                                                            data?.showNearCouse && (
                                                                              <div className="ml-3 my-1">
                                                                                <label className="slds-form-element__label font-16 my-2 font-weight-500 mb-2">
                                                                                  {" "}
                                                                                  Near
                                                                                  Root
                                                                                  Cause
                                                                                  Category{" "}
                                                                                </label>
                                                                                {majorData?.near_root_cause_category.map(
                                                                                  (
                                                                                    nearData
                                                                                  ) => (
                                                                                    <>
                                                                                      <Checkbox
                                                                                        labels={{
                                                                                          label:
                                                                                            nearData[
                                                                                              "near_root_cause_category"
                                                                                            ],
                                                                                        }}
                                                                                        checked={
                                                                                          nearData.isChecked
                                                                                        }
                                                                                        value={
                                                                                          subData[
                                                                                            "near_root_cause_category"
                                                                                          ]
                                                                                        }
                                                                                        onChange={() =>
                                                                                          this._toggleNearCategoryCheck(
                                                                                            data.main_category_id,
                                                                                            subData.sub_category_id,
                                                                                            majorData.major_root_cause_category_id,
                                                                                            nearData.near_root_cause_category_id
                                                                                          )
                                                                                        }
                                                                                      />
                                                                                      {nearData?.isChecked &&
                                                                                        data?.isChecked &&
                                                                                        data?.showInterCouse && (
                                                                                          <div className="ml-3 my-1">
                                                                                            <label className="slds-form-element__label font-16 my-2 font-weight-500 mb-2">
                                                                                              {" "}
                                                                                              Intermediate
                                                                                              Cause
                                                                                              Category{" "}
                                                                                            </label>
                                                                                            {nearData?.intermediate_cause_category.map(
                                                                                              (
                                                                                                interData
                                                                                              ) => (
                                                                                                <>
                                                                                                  <Checkbox
                                                                                                    labels={{
                                                                                                      label:
                                                                                                        interData[
                                                                                                          "intermediate_cause_category"
                                                                                                        ],
                                                                                                    }}
                                                                                                    checked={
                                                                                                      interData.isChecked
                                                                                                    }
                                                                                                    value={
                                                                                                      subData[
                                                                                                        "intermediate_cause_category"
                                                                                                      ]
                                                                                                    }
                                                                                                    onChange={() =>
                                                                                                      this._toggleInterCategoryCheck(
                                                                                                        data.main_category_id,
                                                                                                        subData.sub_category_id,
                                                                                                        majorData.major_root_cause_category_id,
                                                                                                        nearData.near_root_cause_category_id,
                                                                                                        interData.intermediate_cause_category_id
                                                                                                      )
                                                                                                    }
                                                                                                  />
                                                                                                </>
                                                                                              )
                                                                                            )}
                                                                                          </div>
                                                                                        )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </div>
                                                                            )}
                                                                        </>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                )}
                                                              </>
                                                            )
                                                          )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                          </Scrollbars>
                                        </div>
                                        <div className="w-100 my-2">
                                          <label className="slds-form-element__label font-16 my-2 font-weight-500">
                                            {" "}
                                            Root Cause Type{" "}
                                          </label>
                                          {this.state
                                            .intermediateRouteCause && (
                                            <Scrollbars
                                              style={{ height: "8vw" }}
                                            >
                                              <div className="my-2">
                                                {this.state.root_cause_type.map(
                                                  (root) => (
                                                    <React.Fragment>
                                                      <Checkbox
                                                        labels={{
                                                          label:
                                                            root[
                                                              "root_cause_type"
                                                            ],
                                                        }}
                                                        value={
                                                          root[
                                                            "root_cause_type"
                                                          ]
                                                        }
                                                        checked={
                                                          root?.isChecked
                                                        }
                                                        onChange={() =>
                                                          this._toggleRootCauseTypeCheck(
                                                            root.root_cause_type_id
                                                          )
                                                        }
                                                      />
                                                      {root?.isChecked && (
                                                        <div className="ml-3 my-1">
                                                          <label className="slds-form-element__label font-16 my-2 font-weight-500 mb-2">
                                                            {" "}
                                                            Root Cause{" "}
                                                          </label>
                                                          {root?.root_cause.map(
                                                            (
                                                              routeCauseData
                                                            ) => (
                                                              <Checkbox
                                                                labels={{
                                                                  label:
                                                                    routeCauseData[
                                                                      "root_cause"
                                                                    ],
                                                                }}
                                                                key={
                                                                  routeCauseData.root_cause_id
                                                                }
                                                                checked={
                                                                  routeCauseData.isChecked
                                                                }
                                                                onChange={() =>
                                                                  this._toggleRootCauseCheck(
                                                                    root.root_cause_type_id,
                                                                    routeCauseData.root_cause_id
                                                                  )
                                                                }
                                                              />
                                                            )
                                                          )}
                                                        </div>
                                                      )}
                                                    </React.Fragment>
                                                  )
                                                )}
                                              </div>
                                            </Scrollbars>
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex space-between">
                                    {rootCause.isLoading ? (
                                      <div className=" mx-3 mt-3">
                                        <Spinner />{" "}
                                      </div>
                                    ) : (
                                      <>
                                        <Save
                                          className="cursor-pointer "
                                          onClick={() =>
                                            this.debounceFunc(() => {
                                              rootCause.section === "1"
                                                ? this.addProbableCause()
                                                : this.addRootCause();
                                            })
                                          }
                                        />
                                        <X
                                          className="cursor-pointer mx-3"
                                          onClick={() =>
                                            this.debounceFunc(async () => {
                                              this.manageDataGatheringParams(
                                                "",
                                                {},
                                                true
                                              );
                                              await this.setState((prev) => {
                                                return {
                                                  main_category:
                                                    prev.masterList
                                                      .main_category,
                                                  root_cause_type:
                                                    prev.masterList
                                                      .root_cause_type,
                                                };
                                              });
                                            })
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="my-2">
                                {isTreeAvailable ? (
                                  <div className="bg-gary p-5 rounded">
                                    <Scrollbars
                                      style={{ height: "48vw", width: "53vw" }}
                                    >
                                      <div className="mr-3">
                                        {this.renderTree(
                                          iraDetailStep4,
                                          "",
                                          true
                                        )}
                                        <div className="my-5">
                                          {this.renderTree(iraDetailStep4)}
                                        </div>
                                      </div>
                                    </Scrollbars>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className=" my-5">
                            {/* <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.5vw",
                          }}
                        >
                          <span style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px"
                          }} className="px-3 py-2 common-black-1 font-weight-500  text-center">Root Cause Analysis</span>
                        </div> */}
                            {isInprogress && isApproveEnabled ? (
                              <Row className="my-5">
                                <Col md={5}>
                                  <div
                                    className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                                    style={{ width: "100%" }}
                                  >
                                    <div className="timeline-header p-0">
                                      <button
                                        style={{
                                          backgroundColor: "darkgray",
                                          color: "white",
                                        }}
                                        disabled={!isApproveEnabled}
                                        className="badge  border font-weight-500 px-3 py-3 rounded-pill"
                                        onClick={() =>
                                          this.debounceFunc(() => {
                                            this.toggleStep2Fun("contributing");
                                          })
                                        }
                                      >
                                        Add Contributing Factor{" "}
                                      </button>
                                    </div>
                                    <div className="mx-3 my-3 px-3 pt-5">
                                      <Scrollbars style={{ height: "15vw" }}>
                                        <div className="pr-4">
                                          {iraDetailStep4?.ira_reported_detail_contrubuting_factor_list?.map(
                                            (el, idx) => (
                                              <div className="user-badge p-3 mb-2 mr-2 w-100">
                                                <div
                                                  key={idx}
                                                  className="user-name"
                                                >
                                                  {el.factor}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Scrollbars>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={7}>
                                  <div
                                    className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                                    style={{ width: "100%" }}
                                  >
                                    <div className="timeline-header p-0">
                                      <button
                                        style={{
                                          backgroundColor: "darkgray",
                                          color: "white",
                                        }}
                                        className="badge border font-weight-500 px-3 py-3 rounded-pill"
                                        onClick={() =>
                                          this.debounceFunc(() => {
                                            this.toggleStep2Fun("immediate");
                                          })
                                        }
                                        disabled={!isApproveEnabled}
                                      >
                                        Add Immediate Factor{" "}
                                      </button>
                                    </div>
                                    <div className="mx-3 my-3 px-3 pt-5">
                                      <Scrollbars style={{ height: "15vw" }}>
                                        <div
                                          className="pr-4 d-flex flex-wrap "
                                          style={{
                                            rowGap: "0.7vw",
                                            columnGap: "0.7vw",
                                          }}
                                        >
                                          {iraDetailStep4?.ira_reported_detail_immediate_factor_list?.map(
                                            (el, idx) => (
                                              <div className="user-badge p-3">
                                                <div
                                                  key={idx}
                                                  className="user-name"
                                                >
                                                  {el.factor}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Scrollbars>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col>
                                  {/* Contributing Factor  */}

                                  <div style={{ margin: "2vh" }}>
                                    {iraDetailStep4
                                      .ira_reported_detail_contrubuting_factor_list
                                      ?.length
                                      ? iraDetailStep4.ira_reported_detail_contrubuting_factor_list
                                          .slice(
                                            0,
                                            iraDetailStep4
                                              .ira_reported_detail_contrubuting_factor_list
                                              .length / 2
                                          )
                                          .map((item, index) => (
                                            <>
                                              <Row>
                                                <Col>
                                                  <div
                                                    class="vl"
                                                    style={{
                                                      marginLeft: "2vw",
                                                      borderLeft:
                                                        "3px solid red",
                                                      height: "2vw",
                                                      borderTop:
                                                        "3px solid red",
                                                      width: "1vw",
                                                      display: "inline-block",
                                                    }}
                                                  ></div>
                                                  <spam
                                                    style={{
                                                      position: "absolute",
                                                    }}
                                                  >
                                                    {item.factor}
                                                  </spam>
                                                </Col>
                                              </Row>
                                            </>
                                          ))
                                      : null}

                                    <button
                                      style={{
                                        background: "red",
                                        color: "white",
                                        borderRadius: "8px",
                                        border: "none",
                                        fontFamily: "monospace",
                                        padding: "2vh",
                                      }}
                                      disabled={!isApproveEnabled}
                                    >
                                      Contributing
                                      <br />
                                      Factor
                                    </button>

                                    {iraDetailStep4
                                      .ira_reported_detail_contrubuting_factor_list
                                      ?.length
                                      ? iraDetailStep4.ira_reported_detail_contrubuting_factor_list
                                          .slice(
                                            iraDetailStep4
                                              .ira_reported_detail_contrubuting_factor_list
                                              .length / 2,
                                            iraDetailStep4
                                              .ira_reported_detail_contrubuting_factor_list
                                              .length
                                          )
                                          .map((item, index) => (
                                            <>
                                              <Row>
                                                <Col>
                                                  <div
                                                    class="vl"
                                                    style={{
                                                      marginLeft: "2vw",
                                                      borderLeft:
                                                        "3px solid red",
                                                      height: "2vw",
                                                      borderBottom:
                                                        "3px solid red",
                                                      width: "1vw",
                                                      display: "inline-block",
                                                    }}
                                                  ></div>
                                                  <spam
                                                  // style={{fontSize:' 2vw !important'}}
                                                  >
                                                    {item.factor}
                                                  </spam>
                                                </Col>
                                              </Row>
                                            </>
                                          ))
                                      : null}
                                  </div>
                                </Col>

                                <Col>
                                  {/* Immediate Factor  */}

                                  {iraDetailStep4
                                    .ira_reported_detail_immediate_factor_list
                                    ?.length
                                    ? iraDetailStep4.ira_reported_detail_immediate_factor_list
                                        .slice(
                                          0,
                                          iraDetailStep4
                                            .ira_reported_detail_immediate_factor_list
                                            .length / 2
                                        )
                                        .map((item, index) => (
                                          <>
                                            <Row>
                                              <Col>
                                                <div
                                                  class="vl"
                                                  style={{
                                                    marginLeft: "2vw",
                                                    borderLeft:
                                                      "3px solid #ff4d6c",
                                                    height: "2vw",
                                                    borderTop: "3px solid red",
                                                    width: "1vw",
                                                    display: "inline-block",
                                                  }}
                                                ></div>
                                                <spam
                                                  style={{
                                                    position: "absolute",
                                                  }}
                                                >
                                                  {item.factor}
                                                </spam>
                                              </Col>
                                            </Row>
                                          </>
                                        ))
                                    : null}

                                  <div style={{ margin: "2vh" }}>
                                    <button
                                      style={{
                                        background: "#ff4d6c",
                                        color: "white",
                                        borderRadius: "8px",
                                        border: "none",
                                        fontFamily: "monospace",
                                        padding: "2vh",
                                      }}
                                    >
                                      Immediate
                                      <br />
                                      Factor
                                    </button>
                                  </div>

                                  {iraDetailStep4
                                    .ira_reported_detail_immediate_factor_list
                                    ?.length
                                    ? iraDetailStep4.ira_reported_detail_immediate_factor_list
                                        .slice(
                                          iraDetailStep4
                                            .ira_reported_detail_immediate_factor_list
                                            .length / 2,
                                          iraDetailStep4
                                            .ira_reported_detail_immediate_factor_list
                                            .length
                                        )
                                        .map((item, index) => (
                                          <>
                                            <Row>
                                              <Col>
                                                <div
                                                  class="vl"
                                                  style={{
                                                    marginLeft: "2vw",
                                                    borderLeft:
                                                      "3px solid #ff4d6c",
                                                    height: "2vw",
                                                    borderBottom:
                                                      "3px solid red",
                                                    width: "1vw",
                                                    display: "inline-block",
                                                  }}
                                                ></div>
                                                <spam>{item.factor}</spam>
                                              </Col>
                                            </Row>
                                          </>
                                        ))
                                    : null}
                                </Col>
                                <Col>
                                  {/* Root Cause Analysis  */}

                                  <div style={{ margin: "2vh" }}></div>
                                </Col>
                              </Row>
                            )}
                            <Row className="mt-5">
                              <Col
                                md={12}
                                className="justify-content-center d-flex fishbon-chart-wrapper position-relative"
                              >
                                <div
                                    className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                                    style={{ width: "100%" }}
                                  >
                                    <div className="timeline-header p-0">
                                      {isInprogress && isApproveEnabled ? (
                                        <button
                                          style={{
                                            backgroundColor: "darkgray",
                                            color: "white",
                                          }}
                                          disabled={!isApproveEnabled}
                                          className="badge  border font-weight-500 px-3 py-3 rounded-pill"
                                          onClick={() =>
                                            this.debounceFunc(() => {
                                              this.toggleStep2Fun("root cause");
                                            })
                                          }
                                        >
                                          Add Root Cause Analysis{" "}
                                        </button> )
                                        :
                                        (
                                          <button
                                            style={{
                                              background: "red",
                                              color: "white",
                                              borderRadius: "8px",
                                              border: "none",
                                              fontFamily: "monospace",
                                              padding: "2vh",
                                            }}
                                            disabled={!isApproveEnabled}
                                          >
                                            Root Cause
                                            <br />
                                            Analysis
                                          </button>
                                        ) 
                                      }
                                    </div>
                                    <div className="mx-3 my-3 px-3 pt-4"></div>
                                      {/* Root Cause<br/>Analysis */}
                                      <div className="d-flex-center">
                                        <FishboneChart data={this.state.FishData} />
                                      </div>
                                    </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        <div className="my-4 ">
                          <div className="d-flex justify-content-between align-items-center my-5">
                            <div className="d-flex justify-content-between align-items-center">
                              <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                {" "}
                                Step 5 : CAPA
                              </h3>
                            </div>
                          </div>
                          {this.state.reportStatus === "pending" ||
                          "progress" ? (
                            <>
                              {!isCompleted ? (
                                <div className="d-flex justify-content-around my-2">
                                  <HorizontalNavTab
                                    tabs={tabs}
                                    activeItem={this.state.currentTab}
                                    handleChange={(key) =>
                                      this.setState({ currentTab: key })
                                    }
                                    extraValue={{
                                      "prop":`(${iraDetail?.basic_ira?.proposedCapaCount ?? 0})`,
                                      "final":`(${iraDetail?.basic_ira?.finalCapaCount ?? 0})`
                                    }}
                                  />
                                </div>
                              ) : null}
                              <div className="d-flex space-between align-items-center my-5">
                                <div></div>

                                {isApproveEnabled || isTeamMemeberLoggedIn ? (
                                  <div className="d-flex space-between align-items-center ">
                                    {isInprogress ? (
                                      <img
                                        src={require("assets/sls-icons/V2. New Development/Filter.svg")}
                                        style={{ height: "1.8vw" }}
                                        className="cursor-pointer mr-1"
                                        onClick={() =>
                                          this.debounceFunc(() => {
                                            this.manageDataGatheringParams(
                                              "filterParams",
                                              {
                                                isFilterEnable: true,
                                              }
                                            );
                                          })
                                        }
                                      />
                                    ) : null}

                                    {this.state.currentTab == "prop" &&
                                    !isCompleted &&
                                    (isApproveEnabled ||
                                      isTeamMemeberLoggedIn) ? (
                                      <img
                                        src={require("assets/sls-icons/V2. New Development/ADD2.svg")}
                                        className="cursor-pointer svg-icons"
                                        onClick={() =>
                                          this.debounceFunc(() => {
                                            this.manageDataGatheringParams(
                                              "recommendation",
                                              {
                                                isAddEnable: true,
                                              }
                                            );
                                          })
                                        }
                                      />
                                    ) : (
                                      <>
                                        {" "}
                                        {this.state.iraDetailStep5
                                          ?.final_corrective_action?.length &&
                                        isApproveEnabled ? (
                                          <button
                                            className="common-oranger-btn w-max px-3 py-2 font-weight-500"
                                            onClick={() =>
                                              this.debounceFunc(() => {
                                                this.setState({
                                                  isFreezeEnabled: true,
                                                });
                                              })
                                            }
                                          >
                                            Freeze All
                                          </button>
                                        ) : null}
                                      </>
                                    )}
                                  </div>
                                ) : null}
                              </div>{" "}
                            </>
                          ) : null}

                          <div>
                            {this.state.reportStatus === "completed" ? (
                              <>
                                <Scrollbars style={{ height: "30vw" }}>
                                  <div>
                                    {iraDetailStep5?.freeze_corrective_action?.map(
                                      (item, idx) => (
                                        <div
                                          className="nm-capa-recommendation-list-wrapper mr-5 mb-3"
                                          key={idx}
                                        >
                                          <div
                                            className="pl-4"
                                            style={{
                                              borderLeft: `3px solid ${item.priority_color}`,
                                            }}
                                          >
                                            <div className="d-flex space-between align-items-center ">
                                              <div>
                                                <span
                                                  style={{
                                                    color: item.priority_color,
                                                  }}
                                                  className="font-18 font-weight-500"
                                                >
                                                  {" "}
                                                  {item.priority}&nbsp;{" "}
                                                </span>
                                                <span className="font-18 font-weight-500 common-black-1">
                                                  &nbsp; | &nbsp;
                                                  {item?.category || ""} &nbsp;
                                                  | &nbsp;
                                                  {` ${item.recommendation?.[0].capaType}` ||
                                                    ""}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="common-seprator-grey-3 my-3"></div>
                                            <div className="d-flex space-between  ">
                                              <div className="feild-group">
                                                <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                  Activity
                                                </div>
                                                <div className="output-text">
                                                  {item.activity}
                                                </div>
                                              </div>
                                              <div className="feild-group">
                                                <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                  Proposed By
                                                </div>
                                                <div className="output-text">
                                                  <div className="d-flex mb-3 align-items-center">
                                                    <img
                                                      src={
                                                        item.recommended_by_pic
                                                      }
                                                      className="user-icon"
                                                    />{" "}
                                                    <span className="w-max">
                                                      {item.recommended_by}
                                                    </span>{" "}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="feild-group">
                                                <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                  Due Date
                                                </div>
                                                <div className="output-text">
                                                  {item.target_date}
                                                </div>
                                              </div>
                                              <div className="feild-group">
                                                <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                  Closed On
                                                </div>
                                                <div className="output-text">
                                                  {[
                                                    ...(item?.recommendation ??
                                                      item?.responsible_person),
                                                  ]?.map((data, idx) => (
                                                    <div
                                                      key={idx}
                                                      className="d-flex mb-3 align-items-center"
                                                    >
                                                      <span className="w-max">
                                                        {data.closing_date ??
                                                          "NA"}
                                                      </span>{" "}
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                              <div className="feild-group">
                                                <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                  Assigned To
                                                </div>
                                                <div className="output-text">
                                                  <Scrollbars
                                                    style={{
                                                      height: "4vw",
                                                      width: "8vw",
                                                    }}
                                                  >
                                                    {" "}
                                                    {[
                                                      ...(item?.responsible_person_list ??
                                                        item?.responsible_person),
                                                    ]?.map((data, idx) => (
                                                      <div
                                                        key={idx}
                                                        className="d-flex mb-3 align-items-center"
                                                      >
                                                        <img
                                                          src={data.person_pic}
                                                          className="user-icon"
                                                          alt="user-avatar"
                                                        />{" "}
                                                        <span className="w-max">
                                                          {data.person_name}
                                                        </span>{" "}
                                                      </div>
                                                    ))}{" "}
                                                  </Scrollbars>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="my-3 d-flex">
                                              <div className="feild-group">
                                                <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                  Corrective Action
                                                </div>
                                                <div className="output-text">
                                                  {` ${item.recommendation?.[0]?.recommendation}` ||
                                                    ""}
                                                </div>
                                              </div>
                                              <div className="feild-group  h-fit mx-5 w-100">
                                                <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                                  Closing Remark{" "}
                                                </div>
                                                <div className="w-75">
                                                  <CarouselComponent
                                                    items={
                                                      item.recommendation?.[0]
                                                        ?.recommendation_evidence
                                                        .length > 0
                                                        ? [
                                                            {
                                                              url: item
                                                                .recommendation?.[0]
                                                                .recommendation_evidence[0]
                                                                ?.evidence,
                                                              contentType:
                                                                "image",
                                                            },
                                                          ]
                                                        : []
                                                    }
                                                    showArrows={false}
                                                  />
                                                </div>
                                              </div>

                                              <div className="d-flex  w-100 mb-4  mt-3">
                                                <div className="output-text ml-5">
                                                  {item.recommendation?.[0]
                                                    .observation || "N/A"}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Scrollbars>
                              </>
                            ) : (
                              <div>
                                <Scrollbars style={{ height: "30vw" }}>
                                  {this.state.currentTab == "prop" ? (
                                    <div>
                                      {this.state.iraDetailStep5?.praposed_corrective_action?.map(
                                        (item, idx, arr) => (
                                          <div
                                            className="nm-capa-recommendation-list-wrapper mr-5 mb-3"
                                            key={idx}
                                          >
                                            <div
                                              className="pl-4"
                                              style={{
                                                borderLeft: `3px solid ${item.priority_color}`,
                                              }}
                                            >
                                              <div className="d-flex space-between align-items-center ">
                                                <div>
                                                  <span
                                                    style={{
                                                      color:
                                                        item.priority_color,
                                                    }}
                                                    className="font-18 font-weight-500"
                                                  >
                                                    {" "}
                                                    {item.priority}&nbsp;{" "}
                                                  </span>
                                                  <span className="font-18 font-weight-500 common-black-1">
                                                    | &nbsp; {item.category} |{" "}
                                                    {item.recommendation.map(
                                                      (data) => data.capaType
                                                    )}
                                                  </span>
                                                </div>
                                                <div>
                                                  {isApproveEnabled ? (
                                                    <button
                                                      className="btn btn-danger  rounded-pill font-weight-500"
                                                      onClick={() =>
                                                        this.debounceFunc(
                                                          () => {
                                                            this.manageDataGatheringParams(
                                                              "recommendation",
                                                              {
                                                                approveId:
                                                                  item.recommended_id,
                                                              }
                                                            );
                                                          }
                                                        )
                                                      }
                                                    >
                                                      Approve
                                                    </button>
                                                  ) : null}

                                                  {userData?.user_id ===
                                                  item.recommended_by_id ? (
                                                    <>
                                                      {" "}
                                                      <Edit
                                                        className="cursor-pointer mx-3 text-info"
                                                        onClick={() =>
                                                          this.debounceFunc(
                                                            () => {
                                                              this.manageEdit(
                                                                item.recommended_id,
                                                                item,
                                                                arr
                                                              );
                                                            }
                                                          )
                                                        }
                                                      />
                                                      <Trash
                                                        className="cursor-pointer common-red-3"
                                                        onClick={() =>
                                                          this.debounceFunc(
                                                            () => {
                                                              this.manageDataGatheringParams(
                                                                "recommendation",
                                                                {
                                                                  deleteId:
                                                                    item.recommended_id,
                                                                  isPropEnable: true,
                                                                }
                                                              );
                                                            }
                                                          )
                                                        }
                                                      />{" "}
                                                    </>
                                                  ) : null}
                                                </div>
                                              </div>
                                              <div className="common-seprator-grey-3 my-3"></div>
                                              <div className="d-flex space-between  ">
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Activity
                                                  </div>
                                                  <div className="output-text">
                                                    {item.activity}
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Proposed By
                                                  </div>
                                                  <div className="output-text">
                                                    <div className="d-flex mb-3 align-items-center">
                                                      <img
                                                        src={
                                                          item.recommended_by_pic
                                                        }
                                                        className="user-icon"
                                                      />{" "}
                                                      <span className="w-max">
                                                        {item.recommended_by}
                                                      </span>{" "}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Due Date
                                                  </div>
                                                  <div className="output-text">
                                                    {item.target_date}
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Assigned To
                                                  </div>
                                                  <div className="output-text">
                                                    <Scrollbars
                                                      style={{
                                                        height: "4vw",
                                                        width: "8vw",
                                                      }}
                                                    >
                                                      {" "}
                                                      {[
                                                        ...(item?.responsible_person_list ??
                                                          item?.responsible_person),
                                                      ]?.map((data, idx) => (
                                                        <div
                                                          key={idx}
                                                          className="d-flex mb-3 align-items-center"
                                                        >
                                                          <img
                                                            src={
                                                              data.person_pic
                                                            }
                                                            className="user-icon"
                                                          />{" "}
                                                          <span className="w-max">
                                                            {data.person_name}
                                                          </span>{" "}
                                                        </div>
                                                      ))}{" "}
                                                    </Scrollbars>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="d-flex space-between align-items-center mt-3">
                                                <div className="feild-group d-flex">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max mr-5">
                                                    Corrective Action
                                                  </div>
                                                  <div className="output-text">
                                                    {item.recommendation.map(
                                                      (data) =>
                                                        data.recommendation
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      {this.state.iraDetailStep5?.final_corrective_action?.map(
                                        (item, idx, arr) => (
                                          <div
                                            className="nm-capa-recommendation-list-wrapper mr-5 mb-3"
                                            key={idx}
                                          >
                                            <div
                                              className="pl-4"
                                              style={{
                                                borderLeft: `3px solid ${item.priority_color}`,
                                              }}
                                            >
                                              <div className="d-flex space-between align-items-center ">
                                                <div>
                                                  <span
                                                    style={{
                                                      color:
                                                        item.priority_color,
                                                    }}
                                                    className="font-18 font-weight-500"
                                                  >
                                                    {" "}
                                                    {item.priority}&nbsp;{" "}
                                                  </span>
                                                  <span className="font-18 font-weight-500 common-black-1">
                                                    | &nbsp; {item.category} |{" "}
                                                    {item.recommendation.map(
                                                      (data) => data.capaType
                                                    )}
                                                  </span>
                                                </div>
                                                <div>
                                                  {isApproveEnabled ? (
                                                    <Trash
                                                      className="cursor-pointer common-red-3"
                                                      onClick={() =>
                                                        this.debounceFunc(
                                                          () => {
                                                            this.manageDataGatheringParams(
                                                              "recommendation",
                                                              {
                                                                deleteId:
                                                                  item.recommended_id,
                                                              }
                                                            );
                                                          }
                                                        )
                                                      }
                                                    />
                                                  ) : null}
                                                </div>
                                              </div>
                                              <div className="common-seprator-grey-3 my-3"></div>
                                              <div className="d-flex space-between  ">
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Activity
                                                  </div>
                                                  <div className="output-text">
                                                    {item.activity}
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Proposed By
                                                  </div>
                                                  <div className="output-text">
                                                    <div className="d-flex mb-3 align-items-center">
                                                      <img
                                                        src={
                                                          item.recommended_by_pic
                                                        }
                                                        className="user-icon"
                                                      />{" "}
                                                      <span className="w-max">
                                                        {item.recommended_by}
                                                      </span>{" "}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Due Date
                                                  </div>
                                                  <div className="output-text">
                                                    {item.target_date}
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Assigned To
                                                  </div>
                                                  <div className="output-text">
                                                    <Scrollbars
                                                      style={{
                                                        height: "4vw",
                                                        width: "8vw",
                                                      }}
                                                    >
                                                      {" "}
                                                      {[
                                                        ...(item?.responsible_person_list ??
                                                          item?.responsible_person),
                                                      ]?.map((data, idx) => (
                                                        <div
                                                          key={idx}
                                                          className="d-flex mb-3 align-items-center"
                                                        >
                                                          <img
                                                            src={
                                                              data.person_pic
                                                            }
                                                            className="user-icon"
                                                          />{" "}
                                                          <span className="w-max">
                                                            {data.person_name}
                                                          </span>{" "}
                                                        </div>
                                                      ))}{" "}
                                                    </Scrollbars>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="d-flex space-between align-items-center mt-3">
                                                <div className="feild-group d-flex">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max mr-5">
                                                    Corrective Action
                                                  </div>
                                                  <div className="output-text">
                                                    {item.recommendation.map(
                                                      (data) =>
                                                        data.recommendation
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                </Scrollbars>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    paddingTop: 0,
                    boxShadow: "none",
                  }}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  icon={<img src={DiscussIcon} className="px-2 w-100  h-100" />}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div
                      style={{
                        background: "rgba(217, 217, 217, 0.5)",
                        borderRadius: "28px",
                        width: "max-content",
                      }}
                      className="px-3 py-2 common-black-1 font-weight-500  text-center"
                      id="discuss"
                      ref={this.myRef}
                    >
                      Discuss
                    </div>
                    <div className="mt-5">
                      <div className="horizontal-timeline-wrapper shadow-none border mt-5 px-5 pt-5">
                        <div className="rd-box">
                          <div>
                            {this.state.comment == 0 ? (
                              ""
                            ) : (
                              <div className="d-flex">
                                <ChevronLeft
                                  className="cursor-pointer common-orange"
                                  onClick={() =>
                                    this.debounceFunc(() => {
                                      this.backToMainComment();
                                    })
                                  }
                                />{" "}
                                Comments details
                              </div>
                            )}
                          </div>

                          <Scrollbars
                            style={{ height: `19vw` }}
                            ref={(c) => {
                              this.myChatRef = c;
                            }}
                          >
                            <div className="mr-5 d-flex flex-column-reverse">
                              {!this.state.dataLoading &&
                              this.state.comment == 0 &&
                              this.state.commentlist != "" ? (
                                this.state?.commentlist.map((el, idx) => {
                                  const position = el.delete_flag
                                    ? "right"
                                    : "left";
                                  const callbacks = {
                                    delete: this.CommentRemove,
                                    like:
                                      el.like_flag != 1
                                        ? this.LikeCommentAdd
                                        : this.LikeCommentRemove,
                                    reply: this.togglecommentId,
                                  };

                                  if (!el.delete_flag) {
                                    delete callbacks.delete;
                                  }

                                  return (
                                    <div key={idx} id={el.comment_id}>
                                      <ChatMessageBox
                                        message={el.comment}
                                        isLiked={el.like_flag}
                                        likeCount={el.like_number}
                                        hasReplies={el.reply_number}
                                        commentTime={el.comment_time}
                                        repliesCount={el.reply_number}
                                        user_name={el.user_name}
                                        user_pic={el.user_pic}
                                        position={position}
                                        callbacks={callbacks}
                                        callbacksParams={{
                                          delete: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          like: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          reply: {
                                            id: el.comment_id,
                                          },
                                        }}
                                      />
                                      {el.subComment.map((sub, i) => {
                                        const callbacks = {
                                          delete: this.CommentRemove,
                                          like:
                                            sub.like_flag != 1
                                              ? this.LikeCommentAdd
                                              : this.LikeCommentRemove,
                                        };
                                        if (!sub.delete_flag) {
                                          delete callbacks.delete;
                                        }
                                        return (
                                          <div
                                            className={` ${
                                              position === "right"
                                                ? "mr-5 pr-5"
                                                : "ml-5 pl-5"
                                            }`}
                                            key={i}
                                          >
                                            <ChatMessageBox
                                              message={sub.comment}
                                              isLiked={sub.like_flag}
                                              likeCount={sub.like_number}
                                              hasReplies={sub.reply_number}
                                              commentTime={sub.comment_time}
                                              repliesCount={sub.reply_number}
                                              user_name={sub.user_name}
                                              position={position}
                                              user_pic={sub.user_pic}
                                              callbacks={callbacks}
                                              callbacksParams={{
                                                delete: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                                like: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="box d-flex inner-box justify-content-center pt-5">
                                  {this.state.comment == 0 &&
                                    this.state.commentlist == "" && (
                                      <div className="box inner-box">
                                        <p>No Comments Available</p>{" "}
                                      </div>
                                    )}
                                </div>
                              )}
                              {!this.state.dataLoading &&
                              this.state.comment == 1 &&
                              this.state.subcommentlist != "" ? (
                                this.state.subcommentlist.map((el, idx) => {
                                  const position = el.delete_flag
                                    ? "right"
                                    : "left";
                                  const callbacks = {
                                    delete: this.CommentRemove,
                                    like:
                                      el.like_flag != 1
                                        ? this.LikeCommentAdd
                                        : this.LikeCommentRemove,
                                    reply: this.togglecommentId,
                                  };

                                  if (!el.delete_flag) {
                                    delete callbacks.delete;
                                  }
                                  return (
                                    <div key={idx}>
                                      <ChatMessageBox
                                        message={el.comment}
                                        isLiked={el.like_flag}
                                        likeCount={el.like_number}
                                        hasReplies={el.reply_number}
                                        commentTime={el.comment_time}
                                        repliesCount={el.reply_number}
                                        user_name={el.user_name}
                                        user_pic={el.user_pic}
                                        position={position}
                                        callbacks={callbacks}
                                        callbacksParams={{
                                          delete: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          like: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          reply: {
                                            id: el.comment_id,
                                          },
                                        }}
                                      />
                                      {el.subComment.map((sub, i) => {
                                        const callbacks = {
                                          delete: this.CommentRemove,
                                          like:
                                            sub.like_flag != 1
                                              ? this.LikeCommentAdd
                                              : this.LikeCommentRemove,
                                        };
                                        if (!sub.delete_flag) {
                                          delete callbacks.delete;
                                        }
                                        return (
                                          <div
                                            className={` ${
                                              position === "right"
                                                ? "mr-5 pr-5"
                                                : "ml-5 pl-5"
                                            }`}
                                            key={i}
                                          >
                                            <ChatMessageBox
                                              message={sub.comment}
                                              isLiked={sub.like_flag}
                                              likeCount={sub.like_number}
                                              hasReplies={sub.reply_number}
                                              commentTime={sub.comment_time}
                                              repliesCount={sub.reply_number}
                                              user_name={sub.user_name}
                                              position={position}
                                              user_pic={sub.user_pic}
                                              callbacks={callbacks}
                                              callbacksParams={{
                                                delete: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                                like: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="box inner-box">
                                  {this.state.comment == 1 &&
                                    this.state.subcommentlist == "" && (
                                      <p>No Sub Comments Available</p>
                                    )}
                                </div>
                              )}
                            </div>
                          </Scrollbars>
                          <div className="common-seprator-grey-3 my-4"></div>
                          {this.state.comment == 0 ? (
                            <div className="align-items-center comments-input d-flex input-block">
                              <div className="w-100 px-4 border-right">
                                <input
                                  name="safety-thought"
                                  type="text"
                                  placeholder="Write a comment"
                                  className="form-control border-0"
                                  multiple
                                  onChange={(e) =>
                                    this.setState({
                                      commentAdd: e.target.value.trimStart(),
                                    })
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      console.log("checking", inputMessageFlag);
                                      if (inputMessageFlag === 0) {
                                        inputMessageFlag = 1;
                                        console.log(
                                          "checking",
                                          inputMessageFlag
                                        );

                                        setTimeout(() => {
                                          this.CommentAdd();
                                          inputMessageFlag = 0;
                                        }, 500);
                                        // this.CommentAdd()
                                      }
                                    }
                                  }}
                                  value={this.state.commentAdd}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="px-4 send-btn">
                                <Send
                                  aria-disabled="true"
                                  className="send-icon cursor-pointer"
                                  onClick={() =>
                                    this.debounceFunc(() => {
                                      this.CommentAdd();
                                    })
                                  }
                                  style={{
                                    color: "59B0F6",
                                    height: "1.5vw",
                                    width: "1.5vw",
                                  }}
                                />
                              </div>
                              {!!this.state.commentError && (
                                <p className="inputErrorMsg">
                                  {requiredMessage}
                                </p>
                              )}
                            </div>
                          ) : (
                            <div className="align-items-center comments-input d-flex input-block">
                              <div className="w-100 px-4 border-right">
                                <input
                                  name="safety-thought"
                                  type="text"
                                  placeholder="Write a Reply"
                                  className="form-control border-0"
                                  multiple
                                  onChange={(e) =>
                                    this.setState({
                                      replycommentAdd:
                                        e.target.value.trimStart(),
                                    })
                                  }
                                  // onKeyDown={(e) => {
                                  //   debugger
                                  //   if (e.key === 'Enter' && this.state?.replycommentAdd != "") {
                                  //     this.ReplyCommentAdd()
                                  //   }
                                  // }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      console.log("checking", inputMessageFlag);
                                      if (inputMessageFlag === 0) {
                                        inputMessageFlag = 1;
                                        console.log(
                                          "checking",
                                          inputMessageFlag
                                        );

                                        setTimeout(() => {
                                          this.ReplyCommentAdd();
                                          inputMessageFlag = 0;
                                        }, 500);
                                        // this.CommentAdd()
                                      }
                                    }
                                  }}
                                  value={this.state.replycommentAdd}
                                />
                              </div>
                              <div className="px-4 send-btn">
                                <Send
                                  aria-disabled="true"
                                  className="send-icon  cursor-pointer"
                                  onClick={(e) =>
                                    this.debounceFunc(() => {
                                      if (
                                        this.state?.replycommentAdd != "" &&
                                        e.detail == 1
                                      ) {
                                        this.ReplyCommentAdd();
                                      }
                                    })
                                  }
                                  style={{
                                    color: "59B0F6",
                                    height: "1.5vw",
                                    width: "1.5vw",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>

            <div className="rd-right-content">
              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <div className="text-center mb-3">
                  <span className="common-black-1 font-weight-500 font-20">
                    Reported By
                  </span>
                  <div className="d-flex flex-column align-items-center">
                    <OverlayTrigger
                      trigger="hover"
                      placement="left"
                      rootClose={true}
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          style={{ width: "15vw", borderRadius: "10px" }}
                        >
                          <Container>
                            <div className="my-3">
                              <span className="common-black-1 font-weight-500 font-16">
                                Reported Date :
                              </span>
                              <span className="common-black-1 ml-3 font-16">
                                {iraBasicInfo.reported_date}
                              </span>
                            </div>
                            <div className="my-3">
                              <span className="common-black-1 font-weight-500 font-16">
                                Reported Time :
                              </span>
                              <span className="common-black-1 ml-3 font-16">
                                {iraBasicInfo.reported_time}
                              </span>
                            </div>
                            <div className="my-3">
                              <span className="common-black-1 font-weight-500 font-16">
                                Reported Month :
                              </span>
                              <span className="common-black-1 ml-3 font-16">
                                {iraBasicInfo.reportedmonth_name}
                              </span>
                            </div>
                          </Container>
                        </Popover>
                      }
                    >
                      <img
                        src={iraBasicInfo.reported_by_pic}
                        className="user-icon my-3 cursor-pointer"
                      />
                    </OverlayTrigger>
                  </div>
                  <span className="common-black-1  font-18">
                    {iraBasicInfo.reported_by}
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-1">
                  <div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        ID :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.state.assignedDetails?.emp_id ?? "NA"}
                      </span>
                    </div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        Department :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.getDepartment(this.state.assignedDetails?.field)}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        Type :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.state.assignedDetails?.type ?? "NA"}
                      </span>
                    </div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        Designation :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.state.assignedDetails?.designation ?? "NA"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="rd-box liked-btn shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <div className="side-invite-user">
                  <div className="image-box">
                    <span className="common-black-1 font-weight-500 font-20">{`Badges   (${this.state.likeNumber})`}</span>
                    &nbsp;
                    {this.state.userLike != "" ? (
                      this.state.userLike?.map(
                        (item, index) =>
                          index < 5 && (
                            <React.Fragment>
                              <img
                                src={item.user_profile}
                                alt=""
                                data-tip
                                data-for={`user-name-${index}`}
                              />

                              <ReactTooltip
                                effect="solid"
                                place="right"
                                id={`user-name-${index}`}
                              >
                                <>
                                  <span>{item.user_name}</span>
                                </>
                              </ReactTooltip>
                            </React.Fragment>
                          )
                        // </div>
                      )
                    ) : (
                      <p className="data-not-present">Badges not available </p>
                    )}
                    {this.state.userLike.length > 5 ? (
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          this.debounceFunc(() => {
                            this.showUserLikedPopup();
                          })
                        }
                      >{`+${this.state.userLike.length - 5} More`}</span>
                    ) : null}
                  </div>
                </div>
              </div>

              {this.state.reportStatus == "completed" ? (
                <div
                  className="rd-box shadow-none border"
                  style={{ borderRadius: "1vw" }}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Toggle
                      eventKey="0"
                      as={Button}
                      variant="link"
                      onClick={() =>
                        this.setState({
                          accordionOpen: !this.state.accordionOpen,
                        })
                      }
                    >
                      <div className="rd-box-head1">
                        <h3>Investigation Team</h3>
                        {this.state.accordionOpen ? (
                          <ChevronDown className="indicator-icon" />
                        ) : (
                          <ChevronRight className="indicator-icon" />
                        )}
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <div className="rd-left-bottom">
                        <Row>
                          <Col>
                            {iraBasicInfo.people_involved_list?.length
                              ? iraBasicInfo.people_involved_list.map(
                                  (item, index) => (
                                    <div
                                      className="chip m-2 position-relative"
                                      key={`investigation-team-${index}`}
                                    >
                                      <img
                                        src={item.user_pic}
                                        width="96"
                                        height="96"
                                        style={{
                                          position: "absolute",
                                          left: "-15px",
                                        }}
                                      />
                                      {item.status == 1 && (
                                        <img
                                          src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                                          alt=""
                                          className="user-icon border-0 position-absolute"
                                        />
                                      )}
                                      {item.user_name}
                                    </div>
                                  )
                                )
                              : null}
                          </Col>
                        </Row>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
              ) : null}

              {this.state.reportStatus == "pending" ||
              this.state.reportStatus == "progress" ? (
                <div
                  className="rd-box shadow-none border"
                  style={{ borderRadius: "1vw" }}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Toggle
                      eventKey="0"
                      as={Button}
                      variant="link"
                      onClick={() =>
                        this.setState({
                          accordionOpen: !this.state.accordionOpen,
                        })
                      }
                    >
                      <div className="rd-box-head1">
                        <h3>Investigation Team</h3>
                        {this.state.accordionOpen ? (
                          <ChevronDown className="indicator-icon" />
                        ) : (
                          <ChevronRight className="indicator-icon" />
                        )}
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <div className="rd-left-bottom">
                        <Row>
                          <Col>
                            <h6 className="common-black-1 font-18 font-weight-500">
                              Team Lead
                            </h6>
                            <p className=" my-2 font-14 font-weight-500">
                              Select a team lead from team members
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.state.iraBasicInfo.people_involved_list
                              ?.length
                              ? this.state.iraBasicInfo.people_involved_list.map(
                                  (item, index) => (
                                    <>
                                      {item.status == 1 && (
                                        <div
                                          className="chip m-2 position-relative"
                                          key={`investigation-team-${index}`}
                                          style={{ width: "100%" }}
                                        >
                                          <img
                                            src={item.user_pic}
                                            alt={item.user_name}
                                            width="96"
                                            height="96"
                                            style={{
                                              position: "absolute",
                                              left: "-15px",
                                            }}
                                          />
                                          {item.status == 1 && (
                                            <img
                                              src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                                              alt=""
                                              className="user-icon border-0 position-absolute"
                                              style={{
                                                height: "1.3vw",
                                                width: "1.3vw",
                                                background: "white",
                                                bottom: "-6px",
                                                left: "0.8vw",
                                              }}
                                            />
                                          )}
                                          &nbsp;{item.user_name ?? "NA"}
                                          {/* <spam style={{ position: 'absolute', right: '1.5vw'}} >
                                      <i class="fa fa-crown"  onClick={()=>{this._AddTeamLeader(item.user_id)}}   ></i>
                                      <i class="fa fa-trash" onClick={()=>{this.removeInviteUser(item.user_id)}} style={{padding:'8px'}}  aria-hidden="true"></i>
                                    </spam> */}
                                        </div>
                                      )}
                                    </>
                                  )
                                )
                              : null}
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <h6 className="common-black-1 font-18 font-weight-500 my-3">
                              Team Members{" "}
                            </h6>
                            {this.state.iraBasicInfo.people_involved_list
                              ?.length ? (
                              this.state.iraBasicInfo.people_involved_list.map(
                                (item, index) => (
                                  <>
                                    {item.status == 0 && (
                                      <div
                                        className="chip m-2 d-flex space-between  align-items-center"
                                        key={`investigation-team-${index}`}
                                        style={{ width: "100%" }}
                                      >
                                        <div className="ml-2">
                                          <img
                                            src={item.user_pic}
                                            alt={item.user_name}
                                            width="96"
                                            height="96"
                                            style={{
                                              position: "absolute",
                                              left: "7px",
                                            }}
                                          />
                                          {item.status == 1 && (
                                            <img
                                              src={require("assets/images/team-lead-active.png")}
                                              alt=""
                                              style={{
                                                height: "2vw",
                                                width: "2vw",
                                                marginLeft: "-1.5vw",
                                                marginTop: "1.5vw",
                                                background: "white",
                                              }}
                                            />
                                          )}
                                          {item.user_name}
                                        </div>
                                        <div>
                                          <div className="d-flex align-items-center ">
                                            {isCrownEnabled && (
                                              <img
                                                src={CrownIcon}
                                                alt=""
                                                onClick={() =>
                                                  this.debounceFunc(() => {
                                                    this._AddTeamLeader(
                                                      item.user_id
                                                    );
                                                  })
                                                }
                                                className="user-icon border-0 cursor-pointer"
                                                style={{
                                                  height: "1.3vw",
                                                  width: "1.3vw",
                                                  background: "white",
                                                  bottom: "-6px",
                                                }}
                                              />
                                            )}
                                            {isDeleteEnabled && (
                                              <>
                                                <img
                                                  src={require("assets/sls-icons/V2. New Development/delete (1).svg")}
                                                  className="cursor-pointer"
                                                  style={{
                                                    height: "1.3vw",
                                                    width: "1.3vw",
                                                  }}
                                                  onClick={() =>
                                                    this.debounceFunc(() => {
                                                      this.removeInviteUser(
                                                        item.user_id
                                                      );
                                                    })
                                                  }
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )
                              )
                            ) : (
                              <div className="text-center ">
                                <img
                                  src={TeamUnavailable}
                                  className="img-fluid "
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
              ) : null}

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Toggle
                    eventKey="0"
                    as={Button}
                    variant="link"
                    onClick={() =>
                      this.setState({
                        accordionOpen: !this.state.accordionOpen,
                      })
                    }
                  >
                    <div className="rd-box-head1">
                      <h3>{iraBasicInfo.activity} | Analysis</h3>
                      {this.state.accordionOpen ? (
                        <ChevronDown className="indicator-icon" />
                      ) : (
                        <ChevronRight className="indicator-icon" />
                      )}
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="rd-left-bottom">
                      <Row>
                        <Col>
                          <div className="report-detail-graph-label">
                            IRA Analysis
                          </div>
                          {iraBasicInfo.analysis?.fa_number == 0 &&
                          iraBasicInfo.analysis?.mtc_number == 0 &&
                          iraBasicInfo.analysis?.tra_number == 0 &&
                          iraBasicInfo.analysis?.lti_number == 0 &&
                          iraBasicInfo.analysis?.first_aid_number == 0 &&
                          iraBasicInfo.analysis?.occupational_illness_number ==
                            0 ? (
                            <div className="graph-not-available-text">
                              Graph not available
                            </div>
                          ) : (
                            <>
                              <Row>
                                <Col sm={6}>
                                  {config.isChartJsEnabled ? (
                                    this.CountDoughnut(analysisChartData)
                                  ) : (
                                    <div style={{ width: "9vw" }}>
                                      <HighchartsReact
                                        highcharts={Highcharts}
                                        options={analysisChart}
                                      />
                                    </div>
                                  )}
                                </Col>
                                <Col
                                  sm={3}
                                  className="report-detail-graph-legend-block"
                                >
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#FF4242" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {iraBasicInfo.analysis?.fa_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    FA
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#086992" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {iraBasicInfo.analysis?.mtc_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    MTC
                                  </div>
                                </Col>
                                <Col
                                  sm={3}
                                  className="report-detail-graph-legend-block"
                                >
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#FAAC15" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {iraBasicInfo.analysis?.tra_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    TRA
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#18DA07" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {iraBasicInfo.analysis?.lti_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    LTI
                                  </div>
                                </Col>
                                {/* /* // Britannia v01
    //  Making this change to ...(Integrate this rta to tra and faid oi notable integration because of britian custoimization ) */}

                                <Col className="d-flex justify-content-end">
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#C511FF" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value mr-1">
                                      {
                                        iraBasicInfo.analysis
                                          ?.occupational_illness_number
                                      }
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text   mr-5">
                                    OI
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#1589EE" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value mr-1">
                                      {iraBasicInfo.analysis?.first_aid_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text mr-2">
                                    First Aid
                                  </div>

                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{
                                        backgroundColor: "rgb(161, 255, 206)",
                                      }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {iraBasicInfo.analysis?.notable_number}{" "}
                                      &nbsp;
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    Notable
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                          <div className="report-detail-graph-label">
                            NM Analysis
                          </div>
                          {iraBasicInfo.nm_ua_number == 0 &&
                          iraBasicInfo.nm_uc_number == 0 ? (
                            <div className="graph-not-available-text">
                              Graph not available
                            </div>
                          ) : (
                            <Row>
                              <Col>
                                <Row
                                  style={{
                                    borderRight:
                                      "0.03vw solid rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  <Col sm={6}>
                                    {config.isChartJsEnabled ? (
                                      this.CountDoughnut(nmuaUcChartData)
                                    ) : (
                                      <div style={{ width: "9vw" }}>
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={nmuaUcChart}
                                        />
                                      </div>
                                    )}
                                  </Col>
                                  <Col
                                    sm={6}
                                    className="report-detail-graph-legend-block"
                                  >
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{
                                          backgroundColor:
                                            iraBasicInfo.analysis?.nm_ua_color,
                                        }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {iraBasicInfo.analysis?.nm_ua_number}
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Act
                                    </div>
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{
                                          backgroundColor:
                                            iraBasicInfo.analysis?.nm_uc_color,
                                        }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {iraBasicInfo.analysis?.nm_uc_number}
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Condition
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                          <div className="report-detail-graph-label">
                            UA Vs UC Analysis
                          </div>
                          {iraBasicInfo.analysis?.ua_number == 0 &&
                          iraBasicInfo.analysis?.uc_number == 0 ? (
                            <div className="graph-not-available-text">
                              Graph not available
                            </div>
                          ) : (
                            <Row>
                              <Col>
                                <Row
                                  style={{
                                    borderRight:
                                      "0.03vw solid rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  <Col sm={6}>
                                    {config.isChartJsEnabled ? (
                                      this.CountDoughnut(uaUcChartData)
                                    ) : (
                                      <div style={{ width: "9vw" }}>
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={uaUcChart}
                                        />
                                      </div>
                                    )}
                                  </Col>
                                  <Col
                                    sm={6}
                                    className="report-detail-graph-legend-block"
                                  >
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{ backgroundColor: "#C7AC83" }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {iraBasicInfo.analysis?.unsafe_act}
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Act
                                    </div>
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{ backgroundColor: "#554040" }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {
                                          iraBasicInfo.analysis
                                            ?.unsafe_condition
                                        }
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Condition
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </div>

              {!(
                this.state.reportStatus == "pending" ||
                this.state.reportStatus == "progress"
              ) && (
                <>
                  <div
                    className="rd-box shadow-none border"
                    style={{ borderRadius: "1vw" }}
                  >
                    <label style={{ marginLeft: "6vw" }}>
                      {" "}
                      Completion Days (Numbers){" "}
                    </label>
                    <Col sm={6}>
                      <div style={{ width: "9vw" }}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={Newdays}
                        />
                      </div>
                      <label style={{ marginLeft: "2vw" }}>
                        {" "}
                        Investigation{" "}
                      </label>
                    </Col>
                    <Col
                      sm={6}
                      style={{ marginLeft: "10vw", marginTop: "-10.70vw" }}
                    >
                      <div style={{ width: "9vw" }}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={CapaCount}
                        />
                      </div>
                      <label style={{ marginLeft: "4vw" }}> CAPA </label>
                    </Col>
                  </div>
                  <div className="rd-box">
                    <label style={{ marginLeft: "4vw" }}>
                      {" "}
                      CAPA Recommendation Count{" "}
                    </label>
                    <Col sm={6}>
                      <div style={{ width: "9vw" }}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={OpenCount}
                        />
                      </div>
                      <label style={{ marginLeft: "3vw" }}> Open </label>
                    </Col>
                    <Col
                      sm={6}
                      style={{ marginLeft: "10vw", marginTop: "-10.70vw" }}
                    >
                      <div style={{ width: "9vw" }}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={CloseCount}
                        />
                      </div>
                      <label style={{ marginLeft: "3vw" }}> Closed </label>
                    </Col>
                  </div>
                </>
              )}

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500 font-20">
                  {" "}
                  Good Act & TBT Count{" "}
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.gpcount.map((data) => data.gp_count)}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.gpcount.map((data) => data.color)}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={gpCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> GA </label>
                  </div>
                </Col>
                <Col
                  sm={6}
                  style={{
                    marginLeft: "10vw",
                    marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw",
                  }}
                >
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.tbtcount.map(
                          (data) => data.tbt_count
                        )}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.tbtcount.map((data) => data.color)}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={tbtCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> TBT </label>
                  </div>
                </Col>
              </div>

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500 font-20">
                  Internal & External Training Count
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.trainingInternalCount.map(
                          (data) => data.training_internal_count
                        )}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingInternalCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={internalCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> Internal </label>
                  </div>
                </Col>
                <Col
                  sm={6}
                  style={{
                    marginLeft: "10vw",
                    marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw",
                  }}
                >
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingExternalCount.map(
                            (data) => data.training_external_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingExternalCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={externalCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> External </label>
                  </div>
                </Col>
              </div>

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500 font-20">
                  {" "}
                  Employee & Contractor Training Count{" "}
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingEmployeeCount.map(
                            (data) => data.training_employee_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingEmployeeCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={employeeCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> Employee </label>
                  </div>
                </Col>

                <Col
                  sm={6}
                  style={{
                    marginLeft: "10vw",
                    marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw",
                  }}
                >
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingContractorCount.map(
                            (data) => data.training_contractor_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingContractorCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={contractorCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> Contractor </label>
                  </div>
                </Col>
              </div>
              {/* <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500">
                  {" "}
                  CA & PA
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                  {config.isChartJsEnabled ? <CountGraph
                      count={iraDetail.CA_Count ?? 0}
                      radius={'77%'}
                      cutout={"82%"}
                      height={100}
                      fontWeight={500}
                      fontSize={'2.1vw'}
                      color={this.state.trainingEmployeeCount.map((data) => data.color)}
                    />
                      :
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={employeeCount}
                    />}
                  </div>
                  <div className="d-flex-center"><label > CA </label></div>
                  
                </Col>

                <Col
                  sm={6}
                  style={{ marginLeft: "10vw", marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw" }}
                >
                  <div style={{ width: "9vw" }}>
                  {config.isChartJsEnabled ? <CountGraph
                      count={iraDetail?.PA_Count ?? 0}
                      radius={'77%'}
                      cutout={"82%"}
                      height={100}
                      fontWeight={500}
                      fontSize={'2.1vw'}
                      color={this.state.trainingContractorCount.map((data) => data.color)}
                    />
                      :
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={contractorCount}
                    />}
                  </div>
                  <div className="d-flex-center"><label > PA </label></div>
                  
                </Col>
              </div> */}
              <Modal
                centered
                show={this.state.deletePopup}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={this.hideModal}
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/delete-popup.svg")}
                  />
                  <p className="submit-text">
                    Are you sure you want to delete this?
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={this.hideModal}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.deleteReport(this.state.iraId);
                        })
                      }
                    >
                      YES, DELETE
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                centered
                show={this.state.confirmPopup}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={this.hideModal}
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/confirm-popup.svg")}
                  />
                  <p className="submit-text">
                    Click Confirm to freeze target date
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={this.hideModal}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.submitTargetDate();
                        })
                      }
                    >
                      CONFIRM
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                centered
                show={this.state.afterTargetDatePopup}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={() => {
                    this.setState({
                      afterTargetDatePopup: false,
                    });
                  }}
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/confirm-popup.svg")}
                  />
                  <p className="submit-text">
                    After adding target date this report will move in Inprogress
                    list
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() => {
                        this.setState({
                          afterTargetDatePopup: false,
                        });
                      }}
                    >
                      CLOSE
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                centered
                show={this.state.isFreezeEnabled}
                className="submit-modal"
              >
                <Modal.Header
                  onClick={() => {
                    this.setState({
                      isFreezeEnabled: false,
                    });
                  }}
                ></Modal.Header>

                <Modal.Body>
                  <p className="submit-text">
                    Are you sure you want to freeze all final corrective action
                  </p>
                  {recommendation?.isLoading ? (
                    <div className="mx-2">
                      <Spinner />
                    </div>
                  ) : (
                    <div className="modal-footer-btn">
                      <button
                        type="submit"
                        className="red-btn"
                        onClick={() =>
                          this.debounceFunc(() => {
                            this.finalRecordFreeze();
                          })
                        }
                      >
                        Freeze All
                      </button>
                      <button
                        type="submit"
                        className="red-btn"
                        onClick={() => {
                          this.setState({
                            isFreezeEnabled: false,
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        {this.state.show ? (
          <ResolvePopup
            show={this.state.show}
            modalClose={this.openResolve}
            reportId={this.state.soiId}
            reportingDetails
          />
        ) : null}

        {this.state.showUser ? (
          <InviteUser
            showUser={this.state.showUser}
            closeUser={this.openInviteUser}
            reportId={this.state.iraId}
            moduleType={"ira"}
            onAddNewUsers={this._addUsersToList}
            project_id={this.state.iraBasicInfo.project_id}
            service_id={this.state.iraBasicInfo.service_id}
            showInvitedUser={false}
            // project_id={20}
            // service_id={9}
          />
        ) : null}

        <Modal
          show={this.state.userLikedPopup}
          onHide={this.handleClose}
          className="likeduser"
        >
          <Modal.Header closeButton>
            <Modal.Title>User Who Liked</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="totalLikedUser">
              <span>All</span>
              <span className="count">{this.state.likeNumber}</span>
            </div>
            {this.state.userLike != "" ? (
              this.state.userLike.map((items, index) => (
                <div className="user-list" key={index}>
                  <img src={items.user_profile} alt={items.user_name} />
                  <h6 className="user-name">{items.user_name}</h6>
                </div>
              ))
            ) : (
              <div className="user-list">
                <p className="data-not-present">Likes not available </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
        <Modal show={this.state.teamLeadID !== null} className="likeduser">
          {/* <Modal.Header closeButton>
            <Modal.Title>User Who Liked</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <div className=" shadow-none ">
              <div className="d-flex space-between">
                <div className="rd-box-head1 text-center">
                  <h3 className="font-weight-500 common-black-1">
                    Select Due Date
                  </h3>
                </div>
                {isInprogress && (
                  <div>
                    <span className="font-weight-light font-16">
                      Previous Due Date : {iraBasicInfo.target_date}
                    </span>
                  </div>
                )}
              </div>
              <Col style={{ margin: "1rem", width: "80%" }}>
                <DatePicker
                  selected={this.state.selectedTargetDate}
                  minDate={new Date()}
                  placeholderText="Due date"
                  inputStyle={{ textAlign: "center" }}
                  onChange={(jsDate) =>
                    this._handleChange(jsDate, "target_date")
                  }
                />
              </Col>

              <Col className="text-center">
                <button
                  type="submit"
                  className="btn btn-outline-danger font-weight-500"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.confirmSubmitTargetDate();
                    })
                  }
                >
                  Confirm
                </button>
              </Col>
            </div>
          </Modal.Body>
        </Modal>
        <Modal centered show={recommendation?.isAddEnable}>
          <Modal.Header className="align-items-center">
            <div className="align-items-center d-flex justify-content-between">
              <div>
                <span className="font-20 font-weight-500 common-white-2">
                  {" "}
                  {recommendation.editId !== "" ? "EDIT" : "Add"} CAPA
                </span>
              </div>
            </div>
            <div className="align-items-center d-flex modal-controls">
              <button
                //  onClick={() => this.manageDataGatheringParams("", {}, true)}
                onClick={() =>
                  this.debounceFunc(() => {
                    this.manageDataGatheringParams("", {}, true);
                    this.setState({
                      priority: {
                        extreme: false,
                        high: false,
                        medium: false,
                        low: false,
                      },
                      capaType: "",
                      iAssignErrorEnabled: false,
                      isCategoryErrorEnabled: false,
                      isCorretiveActionErrorEnabled: false,
                      isCAPAErrorEnabled: false,
                      isDueDateErrorEnabled: false,
                      isPriortiyErrorEnabled: false,
                    });
                  })
                }

                // onClick={() => {
                //   this.manageDataGatheringParams("", {}, true);
                //   this.setState({
                //     priority: {
                //       extreme: false,
                //       high: false,
                //       medium: false,
                //       low: false,
                //     },
                //     capaType: "",
                //     iAssignErrorEnabled: false,
                //     isCategoryErrorEnabled: false,
                //     isCorretiveActionErrorEnabled: false,
                //     isCAPAErrorEnabled: false,
                //     isDueDateErrorEnabled: false,
                //     isPriortiyErrorEnabled: false,
                //   });
                // }}
              >
                Close
              </button>
              <button
                disabled={recommendation.isLoading}
                onClick={(e) => {
                  if (e.detail == 1) {
                    recommendation.editId !== ""
                      ? this._EditRecommendation()
                      : this._AddRecommendation();
                  }
                }}
              >
                {recommendation.isLoading ? (
                  <RefreshCw className="rotate-360" />
                ) : recommendation.editId !== "" ? (
                  "EDIT"
                ) : (
                  "Add"
                )}
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <Combobox
                  id="combobox-readonly-single"
                  events={{
                    onSelect: (event, { selection }) => {
                      this.manageDataGatheringParams("recommendation", {
                        category_l: selection,
                      });
                    },
                  }}
                  required
                  labels={{
                    label: "Select Category",

                    placeholderReadOnly: "Choose",
                  }}
                  options={this.generateDropdownParameter(
                    categoryList.data.category,
                    {
                      id: "category_id",
                      label: "category",
                    }
                  )}
                  selection={recommendation.category_l}
                  variant="readonly"
                  errorText={
                    this.state.isCategoryErrorEnabled
                      ? "Please choose option"
                      : null
                  }
                />

                <Textarea
                  aria-describedby="error-1"
                  id="text-area-error"
                  name="required-textarea-error"
                  label={
                    <div
                      className={
                        this.state.isCAPAErrorEnabled
                          ? "slds-has-error my-0"
                          : ""
                      }
                    >
                      <span
                        className="required"
                        style={{ color: "red", paddingLeft: "4px" }}
                        title="required"
                      >
                        *
                      </span>
                      <span className="required" style={{ paddingLeft: "4px" }}>
                        CAPA
                      </span>

                      <div className="control1 common-check-box-layout d-flex">
                        <input
                          required
                          type="checkbox"
                          id="CA"
                          checked={this.state.capaType === "CA"}
                          onChange={() => this.setState({ capaType: "CA" })}
                          name="CA"
                          value="CA"
                        />
                        <label htmlFor="Priority1" className="mr-3">
                          {" "}
                          CA
                        </label>
                        <input
                          type="checkbox"
                          id="PA"
                          checked={this.state.capaType === "PA"}
                          onChange={() => this.setState({ capaType: "PA" })}
                          name="PA"
                          value="PA"
                        />
                        <label htmlFor="Priority2"> PA</label>
                      </div>
                      {this.state.isCAPAErrorEnabled ? (
                        <div id="lfzisi1cXH" class="slds-form-element__help">
                          Please select capa type
                        </div>
                      ) : null}
                    </div>
                  }
                  placeholder="Enter Corrective Action"
                  value={recommendation.correctiveActionText}
                  errorText={
                    this.state.isCorretiveActionErrorEnabled
                      ? "Please enter action"
                      : null
                  }
                  onChange={(e) =>
                    this.manageDataGatheringParams("recommendation", {
                      correctiveActionText: e.target.value,
                    })
                  }
                />
                <Combobox
                  id="combobox-readonly-single-repsonable"
                  required
                  events={{
                    onSelect: (event, { selection }) => {
                      this.manageDataGatheringParams("recommendation", {
                        responsible_p: selection,
                      });
                    },
                  }}
                  labels={{
                    label: "Assigned To",
                    placeholder: "Choose option",
                  }}
                  options={this.generateDropdownParameter(
                    categoryList.data.responsible_person,
                    {
                      id: "person_id",
                      label: "person",
                    }
                  )}
                  selection={recommendation.responsible_p}
                  // value={recommendation.responsible_p.label}
                  variant="readonly"
                  errorText={
                    this.state.iAssignErrorEnabled
                      ? "Please choose option"
                      : null
                  }
                />
                <div className="with-mic required">
                  {/* <span className="required" style={{ color: 'red', paddingLeft: '4px' }} title="required">*</span>
<span className="required" style={{ paddingLeft: '4px', fontSize:"9px" }}>Due Date</span> */}
                  <span
                    className="required"
                    style={{ color: "red", paddingLeft: "4px" }}
                    title="required"
                  >
                    *
                  </span>
                  <span
                    className="required"
                    style={{ paddingLeft: "4px", fontSize: "12px" }}
                  >
                    Due Date
                  </span>
                </div>

                <div
                  className={
                    this.state.isDueDateErrorEnabled ? "slds-has-error" : ""
                  }
                >
                  <DatePicker
                    selected={recommendation.selectedDate}
                    placeholderText="Due Date"
                    inputStyle={{ textAlign: "center" }}
                    minDate={capaMinDate}
                    onChange={(jsDate) =>
                      this.manageDataGatheringParams("recommendation", {
                        selectedDate: jsDate,
                      })
                    }
                  />
                  {this.state.isDueDateErrorEnabled ? (
                    <div id="lfzisi1cXH" class="slds-form-element__help">
                      Please select date
                    </div>
                  ) : null}
                </div>

                <div className="with-mic ">
                  <span
                    className="required"
                    style={{ color: "red", paddingLeft: "4px" }}
                    title="required"
                  >
                    *
                  </span>
                  <span
                    className="required"
                    style={{ paddingLeft: "4px", fontSize: "12px" }}
                  >
                    Priority
                  </span>
                </div>
                <div
                  className={
                    this.state.isPriortiyErrorEnabled ? "slds-has-error  " : ""
                  }
                >
                  <div className={"d-flex"}>
                    <Checkbox
                      assistiveText={{
                        label: "Extreme",
                      }}
                      labels={{
                        label: "Critical",
                      }}
                      id="Priority"
                      checked={this.state.priority.extreme}
                      onChange={() => this.onPriorityChange(0)}
                      name="Priority1"
                      value="Extreme"
                    />
                    <Checkbox
                      assistiveText={{
                        label: "High",
                      }}
                      labels={{
                        label: "Urgent",
                      }}
                      id="High"
                      checked={this.state.priority.high}
                      onChange={() => this.onPriorityChange(1)}
                      name="Priority2"
                      value="High"
                    />
                    <Checkbox
                      assistiveText={{
                        label: "Medium",
                      }}
                      labels={{
                        label: "Important",
                      }}
                      id="Medium"
                      checked={this.state.priority.medium}
                      onChange={() => this.onPriorityChange(2)}
                      name="Priority3"
                      value="High"
                    />
                    <Checkbox
                      assistiveText={{
                        label: "Low",
                      }}
                      labels={{
                        label: "Normal",
                      }}
                      id="Low"
                      checked={this.state.priority.low}
                      onChange={() => this.onPriorityChange(3)}
                      name="Priority3"
                      value="Low"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={recommendation?.approveId !== ""}
          className="submit-modal"
        >
          <Modal.Header></Modal.Header>

          <Modal.Body>
            <img
              className="submit-img"
              src={require("assets/images/delete-popup.svg")}
            />
            <p className="submit-text font-18">
              Are you sure you want to Approve this?
            </p>
            <div className="modal-footer-btn ">
              {recommendation?.isLoading ? (
                <div className="m-3">
                  <Spinner />{" "}
                </div>
              ) : (
                <>
                  <button
                    type="submit"
                    className="red-btn"
                    onClick={() =>
                      this.manageDataGatheringParams("recommendation", {
                        approveId: "",
                      })
                    }
                  >
                    CANCEL
                  </button>{" "}
                  <button
                    type="submit"
                    className="red-btn"
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.approveRecommendation(recommendation?.approveId);
                      })
                    }
                  >
                    YES, APPROVE
                  </button>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={recommendation?.deleteId !== ""}
          className="submit-modal"
        >
          <Modal.Header></Modal.Header>

          <Modal.Body>
            <img
              className="submit-img"
              src={require("assets/images/confirm-popup.svg")}
            />
            <p className="submit-text">
              {recommendation?.isPropEnable
                ? "Do you really want to delete this?"
                : " If you Delete this Corrective Action from here it will be store in Proposed Corrective Action"}
            </p>
            <div className="modal-footer-btn">
              {recommendation?.isLoading ? (
                <div className="m-3">
                  <Spinner />{" "}
                </div>
              ) : (
                <>
                  <button
                    type="submit"
                    className="red-btn"
                    onClick={() =>
                      this.manageDataGatheringParams("recommendation", {
                        deleteId: "",
                      })
                    }
                  >
                    CLOSE
                  </button>
                  <button
                    type="submit"
                    className="red-btn"
                    onClick={() =>
                      this.debounceFunc(() => {
                        recommendation?.isPropEnable
                          ? this.removePropRecord(recommendation?.deleteId)
                          : this.removeFinalRecord(recommendation?.deleteId);
                      })
                    }
                    // onClick={() =>
                    //   recommendation?.isPropEnable
                    //     ? this.removePropRecord(recommendation?.deleteId)
                    //     : this.removeFinalRecord(recommendation?.deleteId)
                    // }
                  >
                    YES,DELETE
                  </button>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={filterParams?.isFilterEnable ?? false}
          size="sm"
          onHide={() => {
            this.manageDataGatheringParams("filterParams", {
              isLoading: false,
              isFilterEnable: false,
            });
          }}
        >
          <Modal.Header className="align-items-center">
            <div className="align-items-center d-flex justify-content-between">
              <div>
                <span className="font-20 font-weight-500 common-white-2">
                  {" "}
                  Filter
                </span>
              </div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.manageDataGatheringParams("", {}, true);
                      this.iraDetails();
                      this.setState({
                        extremeSelect: 0,
                        extremeValue: 0,
                        highSelect: 0,
                        mediumSelect: 0,
                        lowSelect: 0,
                      });
                    })
                  }
                  // onClick={() => {
                  //   this.manageDataGatheringParams("", {}, true);
                  //   this.iraDetails();
                  //   this.setState({
                  //     extremeSelect: 0,
                  //     extremeValue: 0,
                  //     highSelect: 0,
                  //     mediumSelect: 0,
                  //     lowSelect: 0,
                  //   });
                  // }}
                >
                  Reset
                </button>
                <button
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.makeFilterList();
                    })
                  }
                >
                  {filterParams.isLoading ? (
                    <RefreshCw className="rotate-360" />
                  ) : (
                    "Apply"
                  )}
                </button>
              </div>
              <X
                style={{
                  height: "1.4vw",
                  width: "1.4vw",
                }}
                className="cursor-pointer mx-2 common-white-2"
                onClick={() =>
                  this.debounceFunc(() => {
                    this.manageDataGatheringParams("", {}, true);
                  })
                }
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <Combobox
                  id="combobox-readonly-single"
                  events={{
                    onSelect: (event, { selection }) => {
                      this.manageDataGatheringParams("filterParams", {
                        category: selection,
                      });
                    },
                  }}
                  labels={{
                    label: " Select Category",
                    placeholder: "Choose Option",
                  }}
                  options={this.generateDropdownParameter(
                    categoryList.data.category,
                    {
                      id: "category_id",
                      label: "category",
                    }
                  )}
                  selection={filterParams.category}
                  variant="readonly"
                />
                <Combobox
                  id="combobox-readonly-single"
                  events={{
                    onSelect: (event, { selection }) => {
                      this.manageDataGatheringParams("filterParams", {
                        recommendation_by: selection,
                      });
                    },
                  }}
                  labels={{
                    label: "Corrective Action Proposed By",
                    placeholder: "Select  Category",
                  }}
                  options={this.generateDropdownParameter(recommendedby, {
                    id: "recommended_id",
                    label: "recommendedBy",
                  })}
                  selection={filterParams.recommendation_by}
                  variant="readonly"
                />
                <Combobox
                  id="combobox-readonly-single"
                  events={{
                    onSelect: (event, { selection }) => {
                      this.manageDataGatheringParams("filterParams", {
                        responsible_person_id: selection,
                      });
                    },
                  }}
                  labels={{
                    label: " Assigned To",
                    placeholder: "Choose option",
                  }}
                  options={this.generateDropdownParameter(
                    categoryList.data.responsible_person,
                    {
                      id: "person_id",
                      label: "person",
                    }
                  )}
                  selection={filterParams.responsible_person_id}
                  variant="readonly"
                />
                <div className="d-flex space-between">
                  <div className="mr-4 w-50">
                    <div className="with-mic ">
                      <label className="slds-form-element__label">
                        Start Date
                      </label>
                    </div>

                    <div className="common-datepick-layout">
                      <DatePicker
                        selected={filterParams.startDate}
                        placeholderText="Start Date"
                        inputStyle={{ textAlign: "center" }}
                        id={filterParams.startDate}
                        minDate={capaMinDate}
                        onChange={(jsDate) =>
                          this.manageDataGatheringParams("filterParams", {
                            startDate: jsDate,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className=" w-50">
                    <div className="with-mic ">
                      <label className="slds-form-element__label">
                        End Date
                      </label>
                    </div>

                    <div className="common-datepick-layout">
                      <DatePicker
                        selected={filterParams.endDate}
                        placeholderText="End Date"
                        inputStyle={{ textAlign: "center" }}
                        id={filterParams.endDate}
                        minDate={filterParams.startDate}
                        onChange={(jsDate) =>
                          this.manageDataGatheringParams("filterParams", {
                            endDate: jsDate,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="with-mic my-3" >
                  <h6>
                    Corrective Action Proposed By
                    <span className="inputErrorMsg">&nbsp;*</span>
                  </h6>
                </div>
                <div className="common-dropdown-layout">


                  <select
                    className="form-control1"
                    id={this.state.filterParams.recommendation_by}
                    value={this.state.filterParams.recommendation_by}
                    onChange={(e) => this.manageFilterParams('recommendation_by', e.target.value)}
                  >
                    <option value={""} disabled>Choose Option</option>
                    {recommendedby?.map((item, index) =>
                    (
                      <option key={index} value={item.recommended_id}  >{item.recommendedBy}</option>

                    )
                    )}

                  </select>


                </div> */}

                {/* <div className="with-mic my-3">
                  <h6>
                    Responsible Person
                    <span className="inputErrorMsg">&nbsp;*</span>
                  </h6>
                </div>

                <div className="common-dropdown-layout">


                  <select
                    className="form-control1"
                    id={this.state.filterParams.responsible_person_id}
                    value={this.state.filterParams.responsible_person_id}
                    onChange={(e) => this.manageFilterParams('responsible_person_id', e.target.value)}
                  >
                    <option value={""} disabled>Choose Option</option>
                    {this.state.responsible_person?.map((item, index) =>
                    (
                      <option key={index} value={item.person_id}  >{item.person}</option>

                    )
                    )}

                  </select>


                </div> */}

                {/* <div className="with-mic my-3">
                  <h6>
                    Target Date
                    <span className="inputErrorMsg">&nbsp;*</span>
                  </h6>
                </div>

                <div className="d-flex common-datepick-layout">
                  <div className="w-50">
                    <DatePicker
                      placeholderText="Start Date"
                      inputStyle={{ textAlign: 'center' }}
                      className="mx-2"
                      id={this.state.filterParams.startDate}
                      selected={this.state.filterParams.startDate}
                      onChange={(jsDate) => this.manageFilterParams('startDate', jsDate)}
                    />
                  </div>
                  <div className="w-50 mx-3">
                    <DatePicker
                      placeholderText="End Date"
                      inputStyle={{ textAlign: 'center' }}
                      className="mx-2"
                      id={this.state.filterParams.endDate}
                      minDate={this.state.filterParams.startDate}
                      selected={this.state.filterParams.endDate}
                      onChange={(jsDate) => this.manageFilterParams('endDate', jsDate)}
                    />
                  </div>


                </div> */}
                {/* 
                <div className="with-mic my-3">
                  <h6>
                    Priority
                    <span className="inputErrorMsg">&nbsp;*</span>
                  </h6>
                </div>

                <div
                  className="control1 common-check-box-layout d-flex"
                >
                  <input type="checkbox" id="Priority" checked={this.state.extremeSelect} onChange={() => this.FilterData(this.state.extremeSelect, 4)} name="Priority1" value="Extreme" />
                  <label htmlFor="Priority1" className="mr-3"> Extreme</label>
                  <input type="checkbox" id="Priority" checked={this.state.highSelect} onChange={() => this.FilterData(this.state.highSelect, 3)} name="Priority2" value="High" />
                  <label htmlFor="Priority2"> High</label>
                  <br />
                  <input type="checkbox" className="ml-3" id="Priority" checked={this.state.mediumSelect} onChange={() => this.FilterData(this.state.mediumSelect, 2)} name="Priority3" value="Medium" />
                  <label htmlFor="Priority3" className="mr-3"> Medium</label>
                  <input type="checkbox" id="Priority" checked={this.state.lowSelect} onChange={() => this.FilterData(this.state.lowSelect, 1)} name="Priority4" value="Low" />
                  <label htmlFor="Priority4"> Low</label>

                </div> */}

                <div className="with-mic ">
                  <label className="slds-form-element__label">Priority</label>
                </div>
                <div className="d-flex">
                  <Checkbox
                    assistiveText={{
                      label: "Extreme",
                    }}
                    labels={{
                      label: "Critical",
                    }}
                    id="Priority"
                    checked={this.state.extremeSelect}
                    onChange={() =>
                      this.FilterData(this.state.extremeSelect, 4)
                    }
                    name="Priority1"
                    value="Extreme"
                  />
                  <Checkbox
                    assistiveText={{
                      label: "High",
                    }}
                    labels={{
                      label: "Urgent",
                    }}
                    id="High"
                    checked={this.state.highSelect}
                    onChange={() => this.FilterData(this.state.highSelect, 3)}
                    name="Priority2"
                    value="High"
                  />
                  <Checkbox
                    assistiveText={{
                      label: "Medium",
                    }}
                    labels={{
                      label: "Important",
                    }}
                    id="Medium"
                    checked={this.state.mediumSelect}
                    onChange={() => this.FilterData(this.state.mediumSelect, 2)}
                    name="Priority3"
                    value="High"
                  />
                  <Checkbox
                    assistiveText={{
                      label: "Low",
                    }}
                    labels={{
                      label: "Normal",
                    }}
                    id="Low"
                    checked={this.state.lowSelect}
                    onChange={() => this.FilterData(this.state.lowSelect, 1)}
                    name="Priority3"
                    value="Low"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <SLDModal
          disableClose
          footer={[
            <SLDButton
              key="promptBtn1"
              label={this.state.isFactorLoading ? "Loading ..." : "Yes"}
              onClick={() =>
                this.debounceFunc(() => {
                  this.manageFactors(this.state.rootfactorParams);
                })
              }
            />,
            <SLDButton
              key="promptBtn"
              label="No"
              onClick={() =>
                this.debounceFunc(() => {
                  this.setState({
                    rootfactorParams: {},
                  });
                })
              }
              disabled={this.state.isFactorLoading}
            />,
          ]}
          isOpen={Object.keys(this.state.rootfactorParams).length}
          contentClassName="text-center"
          prompt="error"
          size="small"
          title={
            <span className=" font-18 font-weight-500 text-center w-100">
              Delete Record
            </span>
          }
        >
          <div className="common-black-1 font-16 font-weight-500 slds-m-around_medium">
            Do you really want to delete this?
          </div>
        </SLDModal>
        <SLDModal
          disableClose
          footer={[
            <SLDButton
              key="promptBtn1"
              label={deleteParams.isLoading ? "Loading ..." : "Yes"}
              onClick={() =>
                this.debounceFunc(() => {
                  this.deleteRecords();
                })
              }
            />,
            <SLDButton
              key="promptBtn"
              label="No"
              onClick={() =>
                this.debounceFunc(() => {
                  this.manageDataGatheringParams(
                    "",
                    {
                      isDeleteEnable: false,
                    },
                    false,
                    "deleteParams"
                  );
                })
              }
              disabled={this.state.isFactorLoading}
            />,
          ]}
          isOpen={deleteParams.isDeleteEnable}
          contentClassName="text-center"
          prompt="error"
          size="small"
          title={
            <span className=" font-18 font-weight-500 text-center w-100">
              Delete Record
            </span>
          }
        >
          <div className="common-black-1 font-16 font-weight-500 slds-m-around_medium">
            Do you really want to delete this?
          </div>
        </SLDModal>
        <SLDModal
          disableClose
          footer={[
            <SLDButton
              key="promptBtn"
              label="Close"
              onClick={() =>
                this.debounceFunc(() => {
                  this.setState({
                    docUrl: "",
                  });
                })
              }
            />,
          ]}
          isOpen={this.state.docUrl !== ""}
          contentClassName="text-center"
          size="small"
          title={
            <span className=" font-18 font-weight-500 text-center w-100">
              Document View
            </span>
          }
        >
          <embed
            src={`${this.state.docUrl}#toolbar=0`}
            // frameborder="0"
            frameBorder="0"
            style={{
              width: "100%",
              height: "20vw",
              paddingBottom: "5%",
            }}
            controls
            controlsList="nodownload"
          />
        </SLDModal>
        <Modal
          centered
          show={this.state.activeAnalysisItem !== null && isApproveEnabled}
          className="submit-modal"
        >
          <Modal.Header className="align-items-center">
            <div className="align-items-center d-flex justify-content-between">
              <div>
                <span className="font-20 font-weight-500 common-black-1">
                  {" "}
                  {this.state.activeModule}{" "}
                </span>
              </div>
            </div>
            <div className="modal-controls">
              <button
                onClick={() =>
                  this.setState({ activeAnalysisItem: null, activeModule: "" })
                }
              >
                Close
              </button>

              <button
                type="button"
                onClick={() =>
                  this.debounceFunc(() => {
                    this.save5MAnalysis(
                      this.state.activeAnalysisItem,
                      this.state.analysisData
                    );
                  })
                }
              >
                Submit
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex space-between mx-5">
              <p className="common-black-1 font-16 mb-0 font-weight-500">
                Selected
              </p>
              <p className="common-black-1 font-16 mb-0 font-weight-500">
                Unselected
              </p>
            </div>
            <hr />
            <div className="d-flex space-between ">
              <div>
                {this.getData(
                  iraDetailStep3.ira_5M_details,
                  this.state.activeAnalysisItem
                )?.map((item, index) => (
                  <div
                    key={index}
                    className="my-2 common-check-box-layout d-flex space-between"
                  >
                    <div>
                      <input type="checkbox" checked />
                      <label htmlFor={item.factor}>{item.factor}</label>
                    </div>
                    <div>
                      <img
                        src={require("assets/sls-icons/V2. New Development/delete.svg")}
                        size="44"
                        className=" svg-icons cursor-pointer ml-2 "
                        aria-hidden="true"
                        onClick={() =>
                          this.debounceFunc(() => {
                            this.setState({
                              factorParams: {
                                sub_factor_id: item.factor_id,
                                five_m_analysis_type: item?.analysis_type,
                              },
                            });
                          })
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div>
                {this.state.analysisData?.map((item, index) => (
                  <div
                    key={index}
                    className="my-2 common-check-box-layout d-flex space-between"
                  >
                    <div>
                      <input
                        type="checkbox"
                        checked={item?.isActive}
                        onChange={() => {
                          this.manage5mPayload(item.factor_id);
                        }}
                      />
                      <label htmlFor={item.factor_name}>
                        {item.factor_name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal centered show={this.state.toggleStep2} className="submit-modal">
          <Modal.Header className="align-items-center">
            <div className="align-items-center d-flex justify-content-between">
              <div>
                <span className="font-20 font-weight-500 common-black-1">
                  {" "}
                  {this.state.step2Type}{" "}
                </span>
              </div>
            </div>
            <div className="modal-controls">
              <button onClick={this.hideModal}>Close</button>

              <button
                type="button"
                //  onClick={this.handleContrubutingSubmit}
                disabled={this.state.isFactorLoading}
                onClick={(e) =>
                  this.debounceFunc(() => {
                    if (e.detail == 1) {
                      this._InProgressFactorAddList(
                        this.state.step2Type === "Contributing Factor"
                          ? 1
                          : this.state.step2Type === "Immediate Factor"
                          ? 2
                          : this.state.RCAFList
                          ? 3
                          : ""
                      );
                    }
                  })
                }
              >
                Submit
              </button>
            </div>
          </Modal.Header>

          <div>
            {this.state.step2Type === "Contributing Factor" ? (
              <Modal.Body>
                
                <div className="d-flex space-between flex-row-reverse">
                  <form className="common-check-box-layout">
                    {
                      this.state.CFList &&
                        this.state.CFList.factors_list.map((item, index) => (
                          <div key={index}>
                            <input
                              type="checkbox"
                              onChange={this.handleCheckboxChange}
                              id={item.factor_id}
                              checked={item.checked}
                            />
                            <label htmlFor={item.factor_name}>
                              {item.factor_name}
                            </label>
                          </div>
                        ))
                      // :<h3>No data</h3>
                    }
                  </form>
                  
                </div>
              </Modal.Body>
            ) : null}

            {this.state.step2Type === "Immediate Factor" ? (
              <Modal.Body>
                
                <div className="d-flex space-between flex-row-reverse">
                  <form className="common-check-box-layout">
                    {this.state.IFList &&
                      this.state.IFList.factors_list.map((item, index) => (
                        <div key={index}>
                          <input
                            type="checkbox"
                            onChange={this.handleCheckboxChangeIF}
                            id={item.factor_id}
                            checked={item.checked}
                          />
                          <label htmlFor={item.factor_name}>
                            {item.factor_name}
                          </label>
                        </div>
                      ))}
                  </form>
                  
                </div>
              </Modal.Body>
            ) : null}

            {this.state.step2Type === "Root Cause Analysis" ? (
              <Modal.Body>
               
                <div className="d-flex space-between flex-row-reverse">
                  <form className="common-check-box-layout">
                    {this.state.RCAFList &&
                      this.state.RCAFList.factors_list.map((item, index) => (
                        <div key={index} className="d-flex space-between">
                          <div>
                            <input
                              type="checkbox"
                              onChange={this.handleCheckboxChangeRAC}
                              id={item.factor_id}
                              checked={item.checked}
                            />
                            <label htmlFor={item.factor_name}>
                              {item.factor_name}
                            </label>
                          </div>
                        </div>
                      ))}
                  </form>
                  
                </div>
              </Modal.Body>
            ) : null}
          </div>
        </Modal>
        <SLDModal
          disableClose
          footer={[
            <SLDButton
              key="promptBtn1"
              label={this.state.isFactorLoading ? "Loading ..." : "Yes"}
              onClick={() =>
                this.debounceFunc(() => {
                  this.deleteAnalyisiData();
                })
              }
            />,
            <SLDButton
              key="promptBtn"
              label="No"
              onClick={() => {
                this.setState({
                  factorParams: {},
                });
              }}
              disabled={this.state.isFactorLoading}
            />,
          ]}
          isOpen={Object.keys(this.state.factorParams).length}
          contentClassName="text-center"
          prompt="error"
          size="small"
          title={
            <span className=" font-18 font-weight-500 text-center w-100">
              Delete Record
            </span>
          }
        >
          <div className="common-black-1 font-16 font-weight-500 slds-m-around_medium">
            Do you really want to delete this?
          </div>
        </SLDModal>
      </div>
    );
  }
}

export default withRouter(IRADetail);

export const ProgressBarWrapper = ({ value }) => {
  return (
    <div className="output-text d-flex align-items-center">
      <div className="w-100 rounded-pill" style={{ height: "0.5vw" }}>
        <ProgressBar className="rounded-pill h-100" now={value} label={""} />
      </div>
      <div>
        {" "}
        <span className="font-weight-500 font-18 ml-3">{value + "%"} </span>
      </div>
    </div>
  );
};

export const Spinner = () => {
  return (
    <div class="demo-only m-2" style={{ position: "relative" }}>
      <div class="slds-spinner_container">
        <div
          role="status"
          class="slds-spinner slds-spinner_small slds-spinner_brand"
        >
          <span class="slds-assistive-text">Loading</span>
          <div class="slds-spinner__dot-a"></div>
          <div class="slds-spinner__dot-b"></div>
        </div>
      </div>
    </div>
  );
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
