// Salseforce ui-kit theme css
import "@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.min.css"
import "./App.css";
import "./Admin.css";
import "./ReactToastify.css";
import "react-image-lightbox/style.css";
import "react-h5-audio-player/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-awesome-lightbox/build/style.css";
import "font-awesome/css/font-awesome.min.css";


import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { persistor, store } from "./app/redux/store";
import { Auth } from "./app/views/Auth";
import ExternalUserFormView from "./app/views/ExternalSurveryForm/ExternalUserFormView";
import { IRAReport } from "./app/views/Ira";
import Market from "./app/views/MarketForm/Market";
import { NMReport } from "./app/views/Nm";
import { PersistGate } from "redux-persist/integration/react";
import Preloader from "./app/shared/components/Preloader";
import { Provider } from "react-redux";
import React from "react";
import { ToastContainer } from "react-toastify";
import { Chart, ArcElement, LinearScale, Tooltip, Legend, Title, CategoryScale, BarElement, PointElement, LineElement } from 'chart.js';
import ExternalReport from "app/shared/components/ReportModal/ExternalReport";
Chart.register(ArcElement, PointElement, LineElement, CategoryScale, BarElement, LinearScale, Tooltip, Legend, Title);

const SidebarLayout = React.lazy(() =>
  import("app/shared/components/Layouts/SidebarLayout/SidebarLayout")
);

//V2 sidebar layout
const SidebarLayoutV2 = React.lazy(() =>
  import("app/shared/components/Layouts/SidebarLayoutV2")
);
const AdminSidebarLayout = React.lazy(() =>
  import("app/shared/components/Layouts/AdminSidebarLayout/AdminSidebarLayout")
);

const SafetyAdminSidebarLayout = React.lazy(() =>
  import("app/shared/components/Layouts/SafetyAdminSidebarLayout/SafetyAdminSidebarLayout")
);

const ReportingUserSidebarLayout = React.lazy(() =>
  import("app/shared/components/Layouts/ReportingUserSidebarLayout/ReportingUserSidebarLayout")
);

const PrivateRoute = React.lazy(() =>
  import("app/shared/components/Authentication/PrivateRoute")
);

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <div className="inner-text">
        <h1>404</h1>
        <h2>Page Not Found</h2>
      </div>
    </div>
  );
};

const SIDEBAR_ROUTE_PATHS = [
  "/ua-uc",
  "/nm",
  "/ira",
  "/good-practices",
  "/training",
  "/tbt",
  "/audit",
  "/training-program",
  "/article",
  "/investigation",
  "/comparative-dashboard",
  "/financial-graphs",
  "/h-triangle",
  "/kpis",
  "/profile",
  "/reporting-detail",
  "/nm-detail",
  "/ira-detail",
  "/edit-reporting-detail",
  "/edit-nm-reporting-detail",
  "/edit-ira-reporting-detail",
  "/create-form",
  "/published-from",

  "/user-form-view",
  "/predictive-analysis",
  "/H-TriangleAnalysis",
  "/edit-form",
  "/setting",
  "/archive",
  "/escalation",
  "/assignform",
  "/market-place",
  "/dashboard",
  "/published-view",
  "/marketplace",
  "/safe-man-hours/rights",

  // "/safety-meeting",

  "/safety-meeting/dashboard",

  "/safety-meeting/scheduled",
  "/safety-meeting/discussion/:meeting_id/scheduled",

  "/safety-meeting/inprogress",
  "/safety-meeting/discussion/:meeting_id/inprogress",

  "/safety-meeting/completed",
  "/safety-meeting/discussion/:meeting_id/completed",

  "/safety-meeting/all-closed",
  "/safety-meeting/discussion/:meeting_id/all-closed",

  "/safety-meeting/assignUsers",

  "/safety-meeting/deleted",

  // SUBMIT FORM LISTING UI ROUTES HERE
  // -- WARNING DONT CHANGE THE ORDER --
  "/submit-form-listing/unlock-form-view/:service_id/:project_id/:form_id/:unlock_request_status",
  // "/submit-form-listing/:formType/:tab/view-form/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:survey_id",
  // "/submit-form-listing/:formType/:tab/user-form-view/:form_id/:service_id/:project_id/:wpu_id/:timeline_id",
  // "/submit-form-listing/:formType/:tab/view-form/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:survey_id/:elearning_id",
  // "/submit-form-listing/:formType/:tab/view-form/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:survey_id/:elearning_id/:user_id",
  "/submit-form-listing/:formType/:tab/view-form/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:survey_id/:elearning_id/:user_id/:form_reporting_assign_id",
  "/submit-form-listing/:formType/:tab/user-form-view/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:elearning_id/:form_reporting_assign_id",
  "/submit-form-listing/:formType/:tab/analysis/:form_id/:project_id/:category_id/:user_id",
  "/submit-form-listing/:formType/:tab/published-view/:form_id",
  // "/submit-form-listing/:formType/:tab/:training_id",
  "/submit-form-listing/:formType/:tab/:training_id/:status",
  "/submit-form-listing/:formType/:tab",
  "/submit-form-listing/:formType",
  "/submit-form-listing/:formType/assign-user",

  // E-LEARNING UI ROUTES REGISTERED HERE
  "/elearning/:formType/:tab",
  // "/elearning/:formType/:tab/view-form/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:survey_id/:elearning_id/:user_id/:form_reporting_assign_id",
  // "/elearning/:formType/:tab/user-form-view/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:elearning_id/:form_reporting_assign_id",

  // POLLING UI ROUTES REGISTERED HERE
  "/polls",
  "/polls-dashboard",
  "/poll/management/:pollId",

  // GOAL UI ROUTES REGISTERED HERE
  "/goal-dashboard",
  "/goal-create",
  "/goal-inprogress",
  "/goal-completed",
  "/goal-inactivated",
  "/goal-deleted",

  "/goal-create/:goalId",
  "/goal-inprogress/:goalId",
  "/goal-completed/:goalId",
  "/goal-inactivated/:goalId",
  "/goal-deleted/:goalId",

  //Training Ui Routes registered here

  "/training/:type/dashboard",

  "/training/:type/schedule",
  "/training/:type/schedule/:id/:type/:status",

  "/training/:type/completed",
  "/training/:type/completed/:id/:type/:status",

  "/training/:type/assignUsers",


  // "/task-management",
  "/task-management/dashboard",
  "/task-management/task",
  "/task-management/task/:id/:module",
  "/task-management/task/:id/:module/:tab",
  "/safety-thought",
  "/safety-champion",
  "/capa",
  "/mis",
  "/bsc",
  "/safety-man",
  "/tool-box-talk",
  "/policy-management",
  "/inspection",
  "/checklist",
  "/survey",
  "/other",
  "/safetyadmin-map",
  "/userside-help",
  "/tbtactivitylist",
  "/policyactivitylist",
  "/dosdontsactivitylist",
  "/goal",
  "/msdsactivitylist",
  "/standardactivitylist",
];

const ADMIN_ROUTE_PATHS = [
  "/admin-dashboard",
  "/services",
  "/project",
  "/company-profile",
  "/admin-master",
  "/admin-ira",
  "/admin-profile",
  "/admin-source",
  "/adminmaster-contributing-factor",
  "/adminmaster-immediate-factor",
  "/adminmaster-rootcause-analysis",
  "/adminmaster-category",
  "/admin-performance-setting",
  "/admin-user",
  "/admin-space",
  "/admin-department",
  "/admin-contractor",
  "/admin-module",
  "/admin-hira",
  "/admin-category",
  "/admin-costing",
  "/admin-help",
  "/admin-good-practice",
  "/admin-nm-workflow-setting",
  "/admin-ira-workflow-setting",
  "/admin-mis-workflow-setting",
  "/admin-uauc-workflow-setting",
  "/admin-ga-workflow-setting",
  "/admin-nm",
  "/admin-designation",
  "/admin-bsc-report",
];

const SAFETY_ADMIN_ROUTE_PATHS = [
  "/safety-admin-dashboard",
  "/projects",
  "/admin-users",
];

const REPORTING_USER_ROUTE_PATHS = [
  "/reporting",
];

const menuItem = [
  "/userside-user",
  "/userside-hira",
  "/userside-safetyteam",
  "/userside-master",
];


const generateV2Routest = (arr = []) => {
  return arr.map((el) => `/v2${el}`)
}

function App() {
  console.log("appsjs",generateV2Routest(SIDEBAR_ROUTE_PATHS))
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.Suspense fallback={<Preloader />}>
          <Router>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <Switch>
              <PrivateRoute exact path="/">
                <SidebarLayout />
              </PrivateRoute>
              <PrivateRoute exact path="/v2/">
                
                <SidebarLayoutV2 />
              </PrivateRoute>
              <PrivateRoute path={SIDEBAR_ROUTE_PATHS}>
              
                <SidebarLayout />
              </PrivateRoute>
              <PrivateRoute path={generateV2Routest(SIDEBAR_ROUTE_PATHS)}>                
                <SidebarLayoutV2 />
              </PrivateRoute>

              <PrivateRoute path="/ira-report/:id">
                <IRAReport />
              </PrivateRoute>
              <PrivateRoute path="/nm-report/:id">
                <NMReport />
              </PrivateRoute>

              {menuItem.map((linkItem, index) => (
                <PrivateRoute path={linkItem} key={`menuLink-${index}`}>
                  <SidebarLayout />
                </PrivateRoute>
              ))}
            

              {/* Admin routes */}
              <PrivateRoute path={ADMIN_ROUTE_PATHS}>
                <AdminSidebarLayout />
              </PrivateRoute>

              {/* Safety Admin routes */}
              <PrivateRoute path={SAFETY_ADMIN_ROUTE_PATHS}>
                <SafetyAdminSidebarLayout />
              </PrivateRoute>

              {/* Reporting user routes */}
              <PrivateRoute path={REPORTING_USER_ROUTE_PATHS}>
                <ReportingUserSidebarLayout />
              </PrivateRoute>

              <Route path="/auth">
                <Auth />
              </Route>
              <Route path="/market">
                <Market />
              </Route>
              <Route path="/survey-response/:formId/:surveyUseId">
                <ExternalUserFormView />
              </Route>
              <Route path="/external-report/:encyptedKey">
                <ExternalReport />
              </Route>
              <Route path="*" exact={true} component={PageNotFound} />
            </Switch>
          </Router>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
