import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Trash, Copy, MoreVertical, Save, Edit } from "react-feather";
import { Field, ErrorMessage } from "formik";
import Url from "app/shared/constants/Url";
import {
  SuccessToast,
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";

export default class AddSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      titleFieldError: false,
      titleDupicateFieldError: false,
      allSections: this.props.allSections,
      duplicateErrorMessage: "This Section Tilte is already added",
    };
  }

  _onHideBlock = () => {
    this.props.hideBlock();
  };

  _addtoPreview = async () => {
    this.setState({
      submitted: true,
    });

    if (this.state.title.length == 0) {
      await this.setState({ titleFieldError: true });
      return;
    }

    await this.state.allSections.map((data, ind) => {
      if ((data.title || data.section_name) === this.state.title) {
        this.setState({ titleDupicateFieldError: true });
        return;
      }
    });

    if (!this.state.titleFieldError && !this.state.titleDupicateFieldError) {

      const checkDuplicateName = this._checkDuplicateSectionName();

      checkDuplicateName.then((response) => {
        try {
          // if (response.data.status == 401) {
          //   this.setState({
          //     titleDupicateFieldError: true,
          //     duplicateErrorMessage: response.data.msg,
          //   });
          // } else {
          this._sendPreviewData();
          this.setState({
            titleFieldError: false,
            titleDupicateFieldError: false,
            duplicateErrorMessage: "",
          });
          // }
        } catch (error) {
          this._sendPreviewData();
        }
      });
    }
  };

  _sendPreviewData = async () => {
    this.props.addNew({
      type: "section",
      title: this.state.title,
      description: this.state.description,
      isEditing: false,
      save: 0,
    });
  }

  _checkDuplicateSectionName = async () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let requestData = {
        section_name: this.state.title,
      };

      return utilFunction.sharedAxiosAPI(
        Url.formType1.checkDuplicateSectionName,
        undefined,
        requestData
      );
    });
  };

  _handleSectionName = async (event) => {
    const value = event.target.value;

    console.log(value);

    await this.setState((prevState) => {
      let { titleDupicateFieldError, title, titleFieldError } = prevState;

      titleDupicateFieldError = false;
      title = value;
      titleFieldError = value == "" ? true : false;

      return { titleDupicateFieldError, title, titleFieldError };
    });

    this.state.allSections.map((data, ind) => {
      if (data.title == value) {
        this.setState({ titleDupicateFieldError: true });
      }
    });
  };

  render() {
    const { name, value: formikValue } = this.props.field;
    const { setFieldValue } = this.props.form;

    return (
      <div className="section-dynamic">
        <div className="section-head">
          <h2>Section Title</h2>
        </div>
        <div className="box-dynamic box-for-section-head">
          <div className="form-dynamic">
            <div className="form-group">
              <Field
                type="text"
                name={`section.title`}
                placeholder="Add Section Title"
                className="form-control"
                onChange={this._handleSectionName}
              />
              {this.state.titleFieldError && this.state.submitted && (
                <div className="inputErrorMsg">Section Tilte is required</div>
              )}
              {this.state.titleDupicateFieldError && this.state.submitted && (
                <div className="inputErrorMsg">
                  {this.state.duplicateErrorMessage}
                </div>
              )}
            </div>
            <div className="form-group">
              <Field
                type="text"
                name={`section.description`}
                placeholder="Description"
                className="form-control"
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </div>
            <div className="section-btn">
              {/* <Button className="icon-btn">
                <Copy className="icon-style"/>
              </Button> */}
              <Button className="icon-btn" onClick={() => this._onHideBlock()}>
                <Trash className="icon-style btn-color" />
              </Button>
              <Button className="icon-btn" onClick={() => this._addtoPreview()}>
                <Save className="icon-style btn-color" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
