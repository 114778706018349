import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Trash } from "react-feather";
import { Field, FieldArray } from "formik";
import Dragula from "react-dragula";
import { Row, Col } from "react-bootstrap";
import {
  SuccessToast,
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";

export default class SingleChoice extends Component {
  constructor(props) {
    super(props);

    // previous option value if editing question
    var optionValue = [];
    if (this.props.oldData !== undefined) {
      optionValue = this.props.oldData;
    }

    this.state = {
      options: optionValue,
      totalScore: 0,
      naDisabled: 0,
    };


  }

  componentDidMount = () => {
    this.state.options.map((option) => {
      if (option.markAsNA == 1) {
        this.setState({
          naDisabled: 1,
        });
        return;
      }
    });
  };

 

  // componentDidUpdate(prevProps, prevState) {

  //   var optionVal = [];

  //   if (this.props.oldData !== undefined) {
  //     optionVal = this.props.oldData;
  //   }

  //   if (prevProps.oldData !== prevState.options) {
  //     this.setState({
  //       options: optionVal,
  //     });

      
  //     var temp = 0;
  //     this.state.options.map((option) => {
  //       if (option.markAsNA == 1) {
  //         temp = 1;
  //       }
  //     });

  //     if(temp != 1){
  //       this.setState({
  //         naDisabled: 0,
  //       });
  //     }
  //   }
  // }


  _addNewOption = async (e) => {
    if (
      e.keyCode === 13 &&
      e.target.value.length > 0 &&
      e.target.value !== ""
    ) {
      let checkDuplicate = this._checkDuplicateOption(e.target.value);
      if (!checkDuplicate) {
        var joined = this.state.options.concat({ response: e.target.value });
        await this.setState({ options: joined });
        this.props.getData(this.state.options, this.state.totalScore);
        this.inputTitle.value = "";
      }
    }
  };

  _checkDuplicateOption = (value) => {
    let checkDuplicate = false;
    let options = this.state.options;

    checkDuplicate = options.some((item) => {
      if (item && item.response == value) {
        ErrorToast("Duplicate Option");
        return true;
      }
      return false;
    });
    return checkDuplicate;
  };


  _addNewOptionScore = async (e, index) => {
    var data = this.state.options[index];
    var dataWithScore;
    if (e.target.value > 0 || e.target.value != "/") {
      dataWithScore = { ...data, markAsFailed: 0, score: e.target.value };
    } else {
      dataWithScore = { ...data, score: e.target.value };
    }
    var tempOptions = this.state.options;
    tempOptions[index] = dataWithScore;
    var i = 0;
    tempOptions.map(
      (data) => (i += !isNaN(data?.score) && Number(data?.score))
    );
    console.log(i);
    await this.setState({ options: tempOptions, totalScore: i });
    this.props.getData(this.state.options, i);
    this.inputTitle.value = "";
  };

  _markAsFailed = async (index) => {
    var data = this.state.options[index];
    var val;
    if (data.markAsFailed == 1) {
      val = 0;
    } else {
      val = 1;
    }
    var dataWithScore = { ...data, score: 0, markAsFailed: val };
    var tempOptions = this.state.options;
    tempOptions[index] = dataWithScore;
    var i = 0;
    tempOptions.map(
      (data) => (i += !isNaN(data?.score) && Number(data?.score))
    );
    await this.setState({ options: tempOptions, totalScore: i });
    this.props.getData(this.state.options, this.state.totalScore);
  };

  _markAsNA = async (index) => {
    var data = this.state.options[index];
    var val;
    if (data.markAsNA == 1) {
      val = 0;
    } else {
      val = 1;
    }
    this.setState({
      naDisabled: val,
    });
    var dataWithScore = { ...data, score: val == 1 ? "NA" : 0, markAsNA: val };
    var tempOptions = this.state.options;
    tempOptions[index] = dataWithScore;
    var i = 0;
    tempOptions.map(
      (data) => (i += !isNaN(data?.score) && Number(data?.score))
    );
    await this.setState({ options: tempOptions, totalScore: i });
    this.props.getData(this.state.options, this.state.totalScore);
  };

  // adding last option in state
  _addLastOption = async () => {
    if (this.inputTitle.value.trim() !== "") {
      var joined = this.state.options.concat({
        response: this.inputTitle.value,
      });
      this.props.getData(joined);
    }
  };

  _deleteOption = async (number) => {
    var array = [...this.state.options];
    array.splice(number, 1);
    var i = 0;
    array.map((data) => (i += !isNaN(data?.score) && Number(data?.score)));
    await this.setState({ options: array, totalScore: i });
    this.props.getData(this.state.options, i);
  };

  onSortEnd = async (startIndex, endIndex) => {
    let newList = [...this.state.options]; // make a separate copy of the array
    const itemm = newList[startIndex];
    await newList.splice(startIndex, 1);
    await newList.splice(endIndex, 0, itemm);

    this.setState({
      options: [],
    });
    this.setState({
      options: newList,
    });
    this.props.getData(this.state.options);
  };

  render() {
    const options = this.state.options;
    return (
      <div className="form-dynamic">
        <div className="form-group">
          <FieldArray
            name={`field.field_response`}
            render={(prop) => {
              const { field } = prop;
              return (
                <div ref={this.dragulaDecorator}>
                  {options &&
                    options.length > 0 &&
                    options.map((data, index) => (
                      <div
                        className="radio-delete"
                        key={`option-${index}`}
                        id={index}
                      >
                        <Row>
                          <Col sm={4}>
                            <label className="radio-box">
                              {data.response}
                              <Field
                                type="radio"
                                name={`field.field_response[${index}].response`}
                                value={data.response}
                                checked={this.state.checked_option === index}
                                onChange={() => {
                                  this.setState({ checked_option: index });
                                }}
                                disabled
                              />
                              {/* <span className="checkmark" style={{ backgroundColor: data.markAsFailed == 1 ? "red" : "green" }}></span> */}
                              <span
                                className="checkmark"
                                style={{
                                  backgroundColor: this.props.scoring == 1 ?
                                    (data.markAsFailed == 1
                                      ? "red"
                                      : data.markAsNA == 1
                                        ? "gray"
                                        : "green") : "green",
                                }}
                              ></span>
                            </label>
                            <Button
                              className="radio-btn1" 
                              onClick={() => this._deleteOption(index)}
                            >
                              <Trash className="radio-icon" />
                            </Button>
                          </Col>
                          {this.props.scoring == 1 && (
                            <>
                              <Col sm={2}>
                                <input
                                  type="text"
                                  name="single_option"
                                  placeholder={`Score ${index + 1}`}
                                  className="form-control"
                                  onChange={(e) => {
                                    this._addNewOptionScore(e, index);
                                  }}
                                  ref={(el) => (this.inputTitle = el)}
                                  value={data.markAsNA ? "NA" : data.score}
                                />
                              </Col>
                              <Col sm={6}>
                                <>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    onClick={() => this._markAsFailed(index)}
                                    checked={data.markAsFailed == 1}
                                    disabled={data.markAsNA}
                                  />
                                  <label
                                    name="Mark as failed"
                                    style={{ marginLeft: "0.3vw" }}
                                  >
                                    Mark as failed
                                  </label>
                                </>
                                <>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    style={{ marginLeft: "1vw" }}
                                    onClick={() => this._markAsNA(index)}
                                    checked={data.markAsNA == 1}
                                    disabled={
                                      (this.state.naDisabled &&
                                        !data.markAsNA) ||
                                      data.markAsFailed
                                    }
                                  />
                                  <label
                                    name="Mark as NA"
                                    style={{ marginLeft: "0.3vw" }}
                                  >
                                    Mark as NA
                                  </label>
                                </>
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>
                    ))}
                </div>
              );
            }}
          />
          <label className="radio-box">
            <input type="radio" name="radio" />
            <span className="checkmark last-checkmark"></span>
          </label>
          <input
            type="text"
            name="single_option"
            placeholder={`Option ${this.state.options.length + 1}`}
            className="form-control radio-input"
            onKeyUp={(e) => {
              this._addNewOption(e);
            }}
            ref={(el) => (this.inputTitle = el)}
          />
        </div>
      </div>
    );
  }

  dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      let optionsConf = { dragable: true, shadow: true };
      const dragula = Dragula([componentBackingInstance], optionsConf);

      dragula.on("drop", (el, target, source, sibling) => {
        this.onSortEnd(
          Number(el.getAttribute("id")),
          Array.from(el.parentNode.children).indexOf(el)
        );
      });
    }
  };
}
