import React from "react";
import { Accordion, Tabs, Tab } from "react-bootstrap";
import  DosDontsActivityList  from "app/views/MarketForm/DosDontsActivityList";
import TBTActivityList from "app/views/MarketForm/TBTActivityList"
import PolicyActivityList from "app/views/MarketForm/PolicyActivityList";
import MsdsActivityList  from "app/views/MarketForm/MSDS";
import StandardActivityList from "app/views/MarketForm/StandardActivityList";
import FooterLink from "./FooterLink";
import MarketForm2 from "app/views/MarketForm/MarketForm2";
const Market = ({ type }) => {

    return (
        <div className="ua-uc-dashboard" style = {{margin : "2vw"}}>
            <div className="ua-uc-section">
           
                <Tabs
                    defaultActiveKey="forms"
                    id="uncontrolled-tab-example"
                    style={{ marginBottom: '2vw' }}
                >
                     <Tab eventKey="forms" title="Marketplace">
                        <MarketForm2 />
                    </Tab> 
                    {/* <Tab eventKey="dosdonts" title="Do's & Dont's">
                        <DosDontsActivityList isMarket={1} />
                    </Tab>
                    <Tab eventKey="training" title="Training">
                        <TBTActivityList isMarket={1} />
                    </Tab>
                     <Tab eventKey="policies" title="Policies">
                        <PolicyActivityList isMarket={1} />
                    </Tab>  
                    <Tab eventKey="msds" title="MSDS">
                        <MsdsActivityList isMarket={1} />
                    </Tab>
                    <Tab eventKey="standard" title="Standard">
                        <StandardActivityList isMarket={1} />
                    </Tab> */}
                </Tabs>
                
            </div>
            <div className="login-footer">
          <img
            className="footer-img"
            src={require("assets/images/footer-shape.png")}
            alt=""
          />
          <div className="footer-box">
            <div className="social-box">
              <FooterLink
                link="https://www.linkedin.com/showcase/clide-safety/?viewAsMember=true"
                iconName="fa-linkedin"
                title="View our Linkedin page"
              />

              <FooterLink
                link="https://twitter.com/CLIDEo"
                iconName="fa-twitter"
                title="View our Twitter page"
              />

              <FooterLink
                link="https://www.facebook.com/CLIDEOffice-107416677331001/?modal=admin_todo_tour"
                iconName="fa-facebook"
                title="View our Facebook page"
              />

              <FooterLink
                link="https://www.youtube.com/channel/UC0pxWwgntN9AXOEH0gr_yug"
                iconName="fa-youtube"
                title="Visit our youtube channel"
              />
            </div>

            <p className="footer-text">Powered by CLIDEG</p>
          </div>
         </div>
        </div >
        
    );
};

export default Market;