import React, { Component } from "react";

export default class ShortAnswer extends Component{
  render() {
    return (
			<div className="form-dynamic">
        <div className="form-group">
          <textarea disabled={true} type="email" name="email" placeholder="Write your Answer" className="form-control"/>
        </div>
			</div>
    )
  }
}