import React, { useEffect, useState } from "react";

import VideoBox from "app/shared/components/ReusableComponents/VideoPlayer/container";

const MediaPlayer = ({ item }) => {
  const [type, setType] = useState("");
  useEffect(() => {
    if (item.video === "") {
      return;
    }
    if (
      item.video.includes(".jpeg") ||
      item.video.includes(".png") ||
      item.video.includes(".jpg")
    ) {
      setType("image");
    } else if (
      item.video.includes(".mp4") ||
      item.video.includes(".omv") ||
      item.video.includes(".wav") ||
      item.video.includes(".mkv")
    ) {
      setType("file");
    } else if (item.video.includes("youtu")) {
      setType("youtube");
    } else if (item.video.includes("vimeo")) {
      setType("vimeo");
    }
  }, [item]);

  switch (type) {
    case "image":
      return (
        <div>
          <img
            style={{ height: "60%", display: "block", width: "100%" }}
            src={item.video}
            alt=""
          />
        </div>
      );
    case "file":
      return (
        <div>
          <div>

            <VideoBox url={item.video} />
          </div>
        </div>
      );
    case "youtube":
    case "vimeo":
      return (
        <div>
          <div>
            <VideoBox url={item.video} />
          </div>
        </div>
      );

    default:
      return <></>;
  }
};

export default MediaPlayer;
