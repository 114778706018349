import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Trash, Copy, MoreVertical, Save, Edit } from "react-feather";
import { Field, ErrorMessage } from "formik";
import Url from "app/shared/constants/Url";

export default class EditSection extends Component {
  constructor(props) {
    super(props);

    var title = "";
    var description = "";
    var secId = "";

    if (this.props.sectionData !== undefined) {
      title =
        this.props.sectionData.title !== undefined
          ? this.props.sectionData.title
          : this.props.sectionData.section_name;

      description =
        this.props.sectionData.description !== undefined
          ? this.props.sectionData.description
          : this.props.sectionData.section_description;

      secId =
        this.props.sectionData.section_id !== undefined
          ? this.props.sectionData.section_id
          : this.props.sectionData.section_id;
    }

    this.state = {
      title: title,
      description: description,
      titleFieldError: false,
      titleDupicateFieldError: false,
      allSections: this.props.allSections,
      duplicateErrorMessage: "This Section Tilte is already added",
      sectionId: secId,
    };
  }

  _onDeleteBlock = () => {
    this.props.onDelete();
  };

  _addtoPreview = async () => {

    await this.setState({
      submitted: true,
    });

    if (this.state.title.length === 0) {
      await this.setState({ titleFieldError: true });
      return;
    }

    var section_index =
      this.props.sectionIndex !== undefined ? this.props.sectionIndex : null;
    await this.state.allSections.map((data, index) => {
      // Editing title check index is already available
      if (section_index !== index) {
        if ((data.title || data.section_name) === this.state.title) {
          this.setState({ titleDupicateFieldError: true });
          return;
        }
      }
    });

    if (!this.state.titleFieldError && !this.state.titleDupicateFieldError) {
      const checkDuplicateName = this._checkDuplicateSectionName();

      checkDuplicateName.then((response) => {
        try {
          // if (response.data.status == 401) {
          //   this.setState({
          //     titleDupicateFieldError: true,
          //     duplicateErrorMessage: response.data.msg,
          //   });
          // } else {
          this._sendPreviewData(section_index);
          this.setState({
            titleFieldError: false,
            titleDupicateFieldError: false,
            duplicateErrorMessage: "",
          });
          // }
        } catch (error) {
          this._sendPreviewData(section_index);
        }
      });
    }
  };

  _sendPreviewData = (sectionIndex) => {
    this.props.editBlock({
      type: "section",
      title: this.state.title,
      description: this.state.description,
      isEditing: false,
      save: 0,
      sectionIndex: sectionIndex,
      section_id: this.state.sectionId,
    });
  }

  _checkDuplicateSectionName = async () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let requestData = {
        section_name: this.state.title,
      };

      return utilFunction.sharedAxiosAPI(
        Url.formType1.checkDuplicateSectionName,
        undefined,
        requestData
      );
    });
  };

  render() {
    const { name, value: formikValue } = this.props.field;
    const { setFieldValue } = this.props.form;

    return (
      <div className="section-dynamic" tabIndex="1">
        <div className="section-head">
          <h2>
            {this.state.title !== undefined
              ? this.state.title
              : `Section Title`}{" "}
          </h2>
        </div>
        <div className="box-dynamic box-for-section-head">
          <div className="form-dynamic">
            <div className="form-group">
              <Field
                type="text"
                name={`section.title`}
                placeholder="Add Section Title"
                className="form-control"
                value={this.state.title}
                onChange={(e) => {
                  this.setState({ titleDupicateFieldError: false });
                  if (e.target.value == "") {
                    this.setState({ titleFieldError: true });
                  } else {
                    this.setState({ titleFieldError: false });
                  }
                  this.state.allSections.map((data, ind) => {
                    if (data.title == e.target.value) {
                      this.setState({ titleDupicateFieldError: true });
                    }
                  });
                  this.setState({ title: e.target.value });
                }}
              />
              {this.state.titleFieldError && this.state.submitted && (
                <div className="inputErrorMsg">Section Tilte is required</div>
              )}
              {this.state.titleDupicateFieldError && this.state.submitted && (
                <div className="inputErrorMsg">
                  {this.state.duplicateErrorMessage}
                </div>
              )}
            </div>
            <div className="form-group">
              <Field
                type="text"
                name={`section.description`}
                placeholder="Description"
                value={this.state.description}
                className="form-control"
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </div>
            <div className="section-btn">
              <Button
                className="icon-btn"
                title="Copy Question"
                onClick={this.props.onCopy}
              >
                <Copy className="icon-style btn-color" />
              </Button>
              <Button
                className="icon-btn"
                title="Delete Question"
                onClick={this.props.onDelete}
              >
                <Trash className="icon-style btn-color" />
              </Button>
              <Button className="icon-btn" onClick={() => this._addtoPreview()}>
                <Save className="icon-style btn-color" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
