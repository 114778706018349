const PageSix = (data) => {
  let injuredPersonData = "";
  let actionHtml = "";

  try {
    data.injured_person_details.forEach((item, index) => {
      injuredPersonData += `<table cellpadding="6" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%">
    <tr style="background: #ccc">
      <th style="text-align: center;">${++index}</th>
      <th style="text-align: left;">Injured Employee Name</th>
      <th style="text-align: left;">${item.person_name || "N/A"}</th>
    </tr>
    <tr>
      <td style="text-align: center;">1</td>
      <td>Employee Type</td>
      <td>${item.employee_type || "N/A"}</td>
    </tr>
    <tr>
      <td style="text-align: center;">2</td>
      <td>Work Type</td>
      <td>${item.work_type || "N/A"}</td>
    </tr>
    <tr>
      <td style="text-align: center;">3</td>
      <td>Joining Date</td>
      <td> ${item.joining_date || "N/A"}</td>
    </tr>
    <tr>
      <td style="text-align: center;">4</td>
      <td>Months/Year with this employee</td>
      <td>${item.month_year_with_this_employee || "N/A"} Year</td>
    </tr>
    <tr>
      <td style="text-align: center;">5</td>
      <td>Months/Year doint this job(Overall)</td>
      <td>${item.month_year_doing_this_job_overall || "N/A"} Year</td>
    </tr>
    <tr>
      <td style="text-align: center;">6</td>
      <td >Time of Incident</td>
      <td>${item.time_of_incident || "N/A"}</td>
    </tr>
    <tr>
      <td style="text-align: center;">6</td>
      <td >Working Shift</td>
      <td>${item.working_shift || "N/A"}</td>
    </tr>
    <tr>
      <td style="text-align: center;">7</td>
      <td >Injured Parts</td>
      <td>${item.injured_part
        .map((subItem) => subItem.injured_parts)
        .join(", ")}</td>
    </tr>
  </table>

  <table cellpadding="0" cellspacing="0" border="0" bordercolor="#e2e2e2" width="100%" style="margin-top: 20px;text-align: center;">
    <tr>
  `;

      item.injured_person_evidence.forEach((subItem, index) => {
        injuredPersonData += `
      <td style="border: 2px solid #fff">
        <div style="min-height:170px;padding-top: 10px;border: 1px solid #ddd;border-bottom: 0">
          <img src="${
            subItem.proof
          }" style="width:232px;border-radius: 4px;height: 150px">
        </div>
        <h5 style="background: #eee;display: block;margin:0;padding: 10px;font-size: 15px;
font-weight: bold;border: 1px solid #ddd;border-top: 0">${
          subItem.category || "N/A"
        }</h5>
      </td>     
       
`;
      });
      injuredPersonData += `</tr></table>`;
    });

    if (data.action_taken) {
      data.action_taken.forEach((item, index) => {
        actionHtml += `<p> ${++index} ${item.action}</p>`;
      });

    } else {
      actionHtml += "N/A";
    }

  } catch (error) {}

  return `
    <style>
      /* CSS Styles for Page Six */
      .full-table {
        margin: 0 auto 10px auto;
        font-family: Arial, sans-serif;
        font-size: 14px;
        border: 3px double #797878;
        position: relative;
      }
      .inner-table {
        text-align: center;
      }
      .inner-table h4 {
        font-size: 16px;
        margin-bottom: 5px;
        font-weight: bold;
      }
      .inner-table hr {
        border: none;
        border-top: 1px solid #ddd;
        margin-top: 5px;
        margin-bottom: 10px;
      }
      .page-number {
        display: block;
        text-align: right;
        margin-top: 10px;
        font-style: italic;
      }
      .license {
        text-align: center;
      /* Add more custom CSS styles as needed */
    </style>
    <table cellpadding="15" cellspacing="0" width="800" class="full-table">
      <tr>
        <td valign="top" class="inner-table">
          <h4>Intial Response</h4>
          <hr />
          <p style="margin-top: 0; line-height: 20px">${data.summary || "N/A"}</p>
          <br />
          <h4>Action Taken</h4>
          <hr />
          ${actionHtml}
          <br />
          <h4>Injured Persons Details</h4>
          <br />
          ${injuredPersonData}
          <span class="page-number" />
        </td>
      </tr>
    </table>`;
};

export default PageSix;
