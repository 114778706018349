import React, { Component } from "react";
import ReactJoyride from "react-joyride";
import { FaPlaneDeparture } from "react-icons/fa";
export default class Tour1 extends React.Component {
    constructor() {
        super();
        this.state={
           run: false,
           steps: [
            {
              target: ".tour1",
              content: "This is the dashboard for the NM module. Here you will see the charts and graphs along with the CAPA recommendation status.In the CAPA page you will see the CAPA open and closed recommendation status. In the NM list page you will see the near miss reported, in-progress and nm investigation completed reports."
            },
           
             
           

           ]
         }
    }
    handleClick = e => {
      e.preventDefault();
      
      this.setState({
       run: true
      });
    };
    render () {
      return (
        <div className="app">
          <ReactJoyride
            steps={this.state.steps}
            run={this.state.run}
            continuous
            showProgress
            showSkipButton
            styles={{
             options: {
                // modal arrow and background color
                arrowColor: "white",
                backgroundColor: "white",
                // page overlay color
                overlayColor: "tomato",//"rgba(79, 45, 0, 0.4)"
                //button color
                primaryColor: "tomato",
                //text color
                textColor: "black",
     
                //width of modal
                width: 500,
                //zindex of modal
                zIndex: 1000,
                
            }
         }}
      />
         
  <div className="container">
  <div className="vertical-center">
  {/* <button className="btn btn-warning" onClick={this.handleClick}>Audit Tour</button> */}
  <img src={require("assets/sls-icons/V2. New Development/Take Tour.svg")} className="svg-icons mb-2" title="NearMiss tour" onClick={this.handleClick} color="tomato" style={{position:"absolute",right:"1vw",top:"2vw"}} /> 
  
  </div>
</div>
        
       
      </div>
       
      );
    }
}
