import React, { Component } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import Url from "app/shared/constants/Url";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import { connect } from "react-redux";
import axios from "axios";
import { SuccessToast } from "app/shared/components/Popups/ToastMessage";
import { withRouter, NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Calendar } from "react-feather";
import moment from "moment";
import { AiOutlineUserAdd } from "react-icons/ai";
import AlertMessage from 'app/shared/components/Popups/AlertMessage';
import CommonDropdown from "../ReportModal/CommonDropdown";
import ServiceList from "../ReportModal/ServiceList";
import ProjectList from "../ReportModal/ProjectList";

import { requiredMessage } from "app/shared/constants/Messages";
import { forEach } from "lodash";
import { convertDateToUTC } from "app/shared/utility/tzUtilFunction";


const qs = require("qs");

const mapStateToProps = (state) => {
  const data = [...state.permitModule.permittedModuleList];
  return {
    permittedModuleList: data,
  };
};

class CreateTaskModal extends Component {
  constructor(props) {
    super(props);
    var date = new Date();
    this.state = {
      selectedStartDate: date,
      maxDatepickerDate: date,
      selectedTargetDate: date,
      dataLoading:false,
      userData: JSON.parse(localStorage.getItem('userData')),
      userList: [],
      task: null,
      taskError: false,
      taskDescription: null,
      taskDescriptionError: false,
      //priorityList: [{ id: 1, priority: "Low" }, { id: 2, priority: "Medium" }, { id: 3, priority: "High" }, { id: 4, priority: "Extreme" }],
      priorityList: [
        { id: 1, priority: "Normal", priority_color: "#E7C76E" },
        { id: 2, priority: "Important", priority_color: "#93B7F2" },
        { id: 3, priority: "Urgent", priority_color: "#E6EE42" },
        { id: 4, priority: "Critical", priority_color: "#E68895" },
      ],
      selectedPriority: null,
      priorityError: false,
      selectedServiceId: null,
      selectedProjectId: null,
      userInfo: JSON.parse(localStorage.getItem("userData")) || null,
      responsiblePerson: this.props.resPersonId ? this.props.resPersonId : null,
      responsiblePersonError: false,
      selectedCategory: this.props.moduleType ? this.props.moduleType : null,
      selectedCategoryError: false,
      isChecked: false,
    };
  }

  componentDidMount = async () => {
    if (!(this.state.userInfo.designation_flag == 1 || this.state.userInfo.designation_flag == 3 || this.state.userInfo.designation_flag == 5)) {
      this._fetchUserServiceProject();
    } else {
      this._fetchServiceList();
    }

    this._fetchCategoryList();
    if (this.props.ownerType == 3) {
      this._fetchUserList(this.props.serviceId, this.props.projectId);
    }
    if (this.props.ownerType == 1) {
      this._fetchUserList(0, 0, 3);
    }
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _handleChange = async (item = null, type = "start_date") => {
    await this.setState((prevState) => {
      switch (type) {
        case "start_date":
          let { selectedStartDate } = prevState;
          selectedStartDate = item;
          return { selectedStartDate };

        case "target_date":
          let { selectedTargetDate } = prevState;
          selectedTargetDate = item;
          return { selectedTargetDate };

        case "task":
          let { task, taskError } = prevState;
          task = item;
          taskError = item ? false : true;
          return { task, taskError };

        case "priority":
          let { selectedPriority, priorityError } = prevState;
          selectedPriority = item;
          priorityError = item ? false : true;
          return { selectedPriority, priorityError };

        case "task_description":
          let { taskDescription, taskDescriptionError } = prevState;
          taskDescription = item;
          taskDescriptionError = item ? false : true;
          return { taskDescription, taskDescriptionError };

        case "responsible_person":
          let { responsiblePerson, responsiblePersonError } = prevState;
          responsiblePerson = item;
          responsiblePersonError = item ? false : true;
          return { responsiblePerson, responsiblePersonError };

        case "category":
          let { selectedCategory, selectedCategoryError } = prevState;
          selectedCategory = item;
          selectedCategoryError = item ? false : true;
          return { selectedCategory, selectedCategoryError };

        default:
          return prevState;
      }
    });
  };

  _taskReport = async (type) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    this._setDataLoading(true);
    let URL = Url.taskManagement.taskReport;
    let headerType = "multipart/form-data";

    let data = new FormData();
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("task", this.state.task);
    data.append("task_description", this.state.taskDescription);
    data.append("category", this.state.selectedCategory);
    data.append("priority", this.state.selectedPriority);

    // var startDate = moment(this.state.selectedStartDate).format(
    //   "YYYY-MM-DD HH:mm:ss");
    // var targetDate = moment(this.state.selectedTargetDate).format(
    //   "YYYY-MM-DD HH:mm:ss");

   var startDate = convertDateToUTC(this.state.selectedStartDate);
   var targetDate = convertDateToUTC(this.state.selectedTargetDate);


    data.append("start_date", startDate);
    data.append("target_date", targetDate);
    data.append("responsible_person", this.state.responsiblePerson);
    // if (this.props.ownerType) {
    data.append("goal_id", this.props.goalId || 0);
    data.append("service_id", this.state.selectedServiceId || 0);
    data.append("project_id", this.state.selectedProjectId || 0);
    data.append("self", this.state.isChecked ? 1 : 0);

    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          if (this.props.ownerType) {
            this.props._reload();
          } else {
            this.props.history.push(`/task-management/task`);
          }
        }
        this.setState({ dataLoading: false });
        this.props.handleClose();
      })
      .finally((error) => {
        this._setDataLoading(false);
      });
  };

  _fetchUserServiceProject = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.userServiceProject);
      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { selectedServiceId, selectedProjectId } = prevState;
              selectedServiceId = response.data.data[0].service_id;
              selectedProjectId = response.data.data[0].project_id;
              return { selectedServiceId, selectedProjectId };
            });
            // this._setDataLoading(false);
            this._fetchUserList(this.state.selectedServiceId, this.state.selectedProjectId);
          }
        })
        .catch((error) => {
          // this._setDataLoading(false);
        });
    });
  };

  _fetchServiceList = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.serviceList);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { serviceList } = prevState;
              serviceList = response.data.data;
              return { serviceList };
            });
            this._setDataLoading(false);
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _serviceChangeHandle = async (serviceId) => {
    await this.setState((prevState) => {
      let { selectedServiceId, serviceError } = prevState;
      selectedServiceId = serviceId;
      serviceError = serviceId ? false : true;
      return { selectedServiceId, serviceError };
    });
    await this._resetDropdwon();
    this._fetchProjectList(serviceId);
  };

  _fetchProjectList = async (serviceId) => {
   
    let requestData = {
      service_id: serviceId,
      module_name: "task_management",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { projectList } = prevState;
              projectList = response.data.data;
              return { projectList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _projectChangeHandle = async (projectId) => {
    await this._resetDropdwon(false);

    await this.setState((prevState) => {
      let {
        selectedProjectId,
        projectError,
        projectList,
      } = prevState;

      selectedProjectId = projectId;
      projectError = projectId ? false : true;

      return { selectedProjectId, projectError };
    });
    await this._fetchUserList(this.state.selectedServiceId, this.state.selectedProjectId);
  };

  _fetchUserList = async (serviceId, projectId, type) => {
    await this.setState({
      dataLoading: true,
      selectedServiceId: serviceId,
      selectedProjectId: projectId,
    })
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var requestData = {
      auth_key: token,
      user_id: userId,
      service_id: serviceId,
      project_id: projectId,
      type: type,
      // form_id: this.state.formId,
    };

    const contentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(Url.UserList, qs.stringify(requestData), contentType)
      .then(async (response) => {
        if (response.data.status == 200) {
          await this.setState({
            dataLoading: false,
            userList: response.data.data,
          })
        }
      })
      .catch(async (error) => {
        await this.setState({
          dataLoading: false,
        });
      });
  };

  _resetDropdwon = async (projectReset = true) => {
    await this.setState((prevState) => {
      let {
        selectedProjectId,
        projectList,
      } = prevState;

      if (projectReset) {
        selectedProjectId = null;
        projectList = [];
      }

      return {
        selectedProjectId,
        projectList,
      };
    });
  };

  _fetchCategoryList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      form_type: 17,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios.post(Url.formCategoryList, qs.stringify(requestData), {
      headers: ContentType,
    }).then((response) => {
      if (response.data.status === 200) {
        var list = response.data.data.map((data) => {
          delete data.description;
          return data;
        })
        this.setState({ categoryList: list });
      }
    })
      .catch((error) => { });
  }

  _handleCheck = async () => {
    this.setState({ isChecked: !this.state.isChecked, responsiblePerson: this.state.userInfo.user_id });
  };


  render() {
    return (
      <div>
        <Modal
          show={true}
          onHide={this.props.handleClose}
          className="closePopup"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton style={{ alignItems: 'center' }}>
            <Modal.Title style={{ paddingRight: '16vw' }}>Create Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Data Loading  */}
            <div
              className="loader"
              style={{ display: this.state.dataLoading ? "block" : "none" }}
            >
              <LoadingImage />
            </div>
            {/* Observation    */}
            <Row>
              <Col sm={6}>
                <div className="form-group">
                  <label className="form-label">
                    Task
                  </label>
                  <span className="inputErrorMsg">&nbsp;*</span>
                  <input
                    className="form-control"
                    value={this.state.task}
                    onChange={(event) =>
                      this._handleChange(
                        event.target.value,
                        "task"
                      )
                    }
                    placeholder={'Enter Task Name'}
                  />
                  {!!this.state.taskError &&
                    <p className="inputErrorMsg">{requiredMessage}</p>
                  }
                </div>
              </Col>
              <Col sm={6}>
              {this.props.ownerType ?
                <div className="form-group ">
                  <label className="form-label">
                    Category
                  </label>
                  <input
                    className="form-control"
                    value={this.props.moduleName}
                    placeholder={'Enter description'}
                  />
                </div>
                :
                <CommonDropdown
                  title="Category"
                  name="category"
                  optionList={this.state.categoryList}
                  onChangeHandle={(value) =>
                    this._handleChange(
                      value,
                      "category"
                    )
                  }
                  value={this.state.selectedCategory}
                  isRequired={true}
                />
              }
              {!!this.state.selectedCategoryError && (
                <p className="inputErrorMsg">{requiredMessage}</p>
              )}
            </Col>
              <Col sm={12}>
                <div className="font-weight-500 ">
                  <label className="form-label">
                    Task Description
                  </label>
                  <span className="inputErrorMsg">&nbsp;*</span>
                  <input
                    className="form-control"
                    value={this.state.taskDescription}
                    onChange={(event) =>
                      this._handleChange(
                        event.target.value,
                        "task_description"
                      )
                    }
                    placeholder={'Enter Description'}
                  />
                  {!!this.state.taskDescriptionError &&
                    <p className="inputErrorMsg">{requiredMessage}</p>
                  }
                </div>
              </Col>
             
              {/* <Col sm={12}>
                <div className="form-group">
                  <label className="form-label">
                    Start Date
                  </label>
                  <div className="calender-div">
                    <DatePicker
                      selected={this.state.selectedStartDate}
                      minDate={this.state.maxDatepickerDate}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      onChange={(jsDate) =>
                        this._handleChange(
                          jsDate,
                          "start_date"
                        )
                      }
                    />
                    <Calendar className="calender-icon" />
                  </div>
                </div>
              </Col> */}
              <Col sm={6} className="mt-2">
              <CommonDropdown
                title="Priority"
                optionList={this.state.priorityList}
                onChangeHandle={(value) =>
                  this._handleChange(
                    value,
                    "priority"
                  )
                }
                isRequired={true}
                value={this.state.selectedPriority}
              />
              {!!this.state.priorityError && (
                <p className="inputErrorMsg">{requiredMessage}</p>
              )}
            </Col>
              <Col sm={6} className="mt-2">
                <div className="form-group">
                  <label className="form-label">
                    Due Date
                  </label>
                  <span className="inputErrorMsg">&nbsp;*</span>
                  <div className="calender-div">
                    <DatePicker
                      selected={this.state.selectedTargetDate}
                      minDate={this.state.selectedStartDate}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      onChange={(jsDate) =>
                        this._handleChange(
                          jsDate,
                          "target_date"
                        )
                      }
                    />
                    <Calendar className="calender-icon" />
                  </div>
                </div>
              </Col>
             
              {!this.state.isChecked && (this.state.userInfo.designation_flag == 1 || this.state.userInfo.designation_flag == 3 || this.state.userInfo.designation_flag == 5) && !this.props.ownerType &&
                <>
                  <Col sm={6}>
                    <ServiceList
                      optionList={this.state.serviceList}
                      onChangeHandle={this._serviceChangeHandle}
                      value={this.state.selectedServiceId}
                    />
                    {!!this.state.serviceError && (
                      <p className="inputErrorMsg">{requiredMessage}</p>
                    )}
                  </Col>
                  <Col sm={6}>
                    <ProjectList
                      optionList={this.state.projectList}
                      onChangeHandle={this._projectChangeHandle}
                      value={this.state.selectedProjectId}
                    />
                    {!!this.state.projectError && (
                      <p className="inputErrorMsg">{requiredMessage}</p>
                    )}
                  </Col>
                </>
              }

              
              {!this.state.isChecked &&
                <Col sm={6}>
                  {(this.props.ownerType == 2 || this.props.ownerType == 5) ?
                    <div className="form-group">
                      <label className="form-label">
                        Assigned To
                      </label>
                      
                      <input
                        className="form-control"
                        value={this.props.resPerson}
                        placeholder={'Enter task description'}
                      />
                    </div>
                    :
                    <CommonDropdown
                    title="Assigned To"
                    optionList={this.state.userList}
                    onChangeHandle={(value) =>
                      this._handleChange(
                        value,
                        "responsible_person"
                      )
                    }
                    value={this.state.responsiblePerson}
                    isRequired={true}
                    placeholder="Select user"
                  />
                  
                  }
                  {!!this.state.responsiblePersonError && (
                    <p className="inputErrorMsg">{requiredMessage}</p>
                  )}
                </Col>
              }
              <Col sm={6}>
              <div
                className="form-group mt-4 mr-4"
              >
                <label className="check-box" >
                  <input
                    type="checkbox"
                    checked={this.state.isChecked}
                    onChange={() => {
                      this._handleCheck();
                    }}
                  />
                  <span className="checkmark1"></span>
                  <label>Self</label>
                </label>
              </div>
            </Col>
            </Row>
            <div className="modal-footer-btn">
              <Button
                variant="primary"
                onClick={() => { this._taskReport() }}
                className="red-btn btn-width"
                disabled={this.state.dataLoading}
              >
                {this.state.dataLoading ? "Updating..." : "Create Task"}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(CreateTaskModal));
