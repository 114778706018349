import React from "react";
import { withRouter } from "react-router-dom";

const Backbutton = (props) => {

  const _handleBack = () => {
    console.log("back pressed");
    props.history.goBack();
  };

  return (
    <button
      type="button"
      className="back-btn btn"
      onClick={_handleBack}
      title=" << Go Back"
    >
      <i className="fa fa-angle-left" aria-hidden="true"></i>
    </button>
  );
};

export default withRouter(Backbutton);
