import React, { Component, useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import {
  Trash,
  MoreVertical,
  AlignLeft,
  AlignRight,
  AlignCenter,
  Video,
  Save,
} from "react-feather";
import { Field, ErrorMessage } from "formik";
import VideoThumbnail from "react-video-thumbnail";
import LoadingSpinner from "../../Loader/loadingSpinner";
import LowSpace from "app/shared/components/Popups/LowSpace";
import Url from "app/shared/constants/Url";

export default class AddVideo extends Component {
  constructor(props) {
    super(props);

    if (this.props.sectionArr !== undefined) {
      var selectedIndex =
        this.props.sectionArr.length > 0
          ? this.props.sectionArr.length - 1
          : "";
    }
    this.state = {
      queIndex: this.props.index,
      video: [],
      loading: false,
      videoPreviewUrl: "",
      submitted: false,
      fileFieldError: false,
      order: this.props.order,
      selectedSectionIndex: selectedIndex,
      alignVideoPreview: "flex-start",
      isCheckLowSpace: false,
      lowStorageMessage: "",
      lowStorageStatus: 200,
      showLowStoragePopup: false,
    };
  }

  _handleImageChange = async (e) => {

    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    this.setState({
      video: "",
      loading: true,
      videoPreviewUrl: "",
    });

    const lowStorage = this.checkLowStorage();
    lowStorage
      .then(async (response) => {
        const { status, msg } = response.data;

        if (status == 200) {
          await reader.readAsDataURL(file);
          reader.onload = async () => {
            this.setState({
              video: file,
              loading: false,
              videoPreviewUrl: reader.result,
              fileFieldError: false,
            });
          };
          this.props.form.setFieldValue(`field.file_path`, file);
        }
        await this.setState({
          lowStorageStatus: status,
          lowStorageMessage: msg,
          showLowStoragePopup: status == 200 ? false : true,
          loading: false
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _handleLowSpace = async (statusFlag) => {
    await this.setState({ showLowStoragePopup: false });
  };

  _onHideBlock = () => {
    this.props.hideBlock();
  };
  _getSelectedSection = async (e) => {
    let value = e.target.value;
    await this.setState({ selectedSectionIndex: value });
  };

  _addtoPreview = async () => {
    this.setState({ submitted: true });

    if (this.state.videoPreviewUrl === "") {
      await this.setState({ fileFieldError: true });
      return;
    }

    var sectionTitle;
    if (this.props.section !== undefined) {
      // Setting dynamic section title based on selected section option from dropdown
      if (this.props.sectionArr !== undefined) {
        var index = this.state.selectedSectionIndex;
        sectionTitle =
          index === "question" ? "" : this.props.sectionArr[index].title;
      } else {
        sectionTitle = this.props.section.title;
      }
      //var sectionTitle = this.props.section.title
    } else {
      sectionTitle = "";
    }

    if (this.state.alignVideoPreview == "flex-end") {
      var alignmentValue = 2;
    } else if (this.state.alignVideoPreview == "center") {
      var alignmentValue = 1;
    } else {
      var alignmentValue = 0;
    }

    this.props.addNew({
      type: "notsection",
      // alignment: this.state.alignVideoPreview,
      alignment: alignmentValue,
      alignment_value: alignmentValue,
      question: this.state.question,
      question_type: 2,
      description: "",
      mandatory: 0,
      notes: 0,
      evidence: 0,
      logic: 0,
      response_type: 0,
      section: sectionTitle,
      order: this.state.order,
      file_path: this.state.videoPreviewUrl,
      file_type: 2,
      field_response: [],
      selectedSectionIndex: this.state.selectedSectionIndex,
      isEditing: false,
    });
    this.props.hideBlock();
  };

  render() {
    const { name, value: formikValue } = this.props.field;
    const { setFieldValue } = this.props.form;
    const { loading } = this.state;
    return (
      <div className="section-dynamic">
        <div className="box-dynamic">
          <div className="form-dynamic">
            <Row>
              <Col sm={9}>
                <div className="form-group">
                  <Field
                    type="text"
                    name={`field.question`}
                    placeholder="Add Video Title"
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ question: e.target.value })
                    }
                  />
                </div>
                <div className="file-upload">
                  <label htmlFor="upload" className="file-upload__label">
                    Browse File
                  </label>
                  <input
                    type="file"
                    name={`field.file_path`}
                    accept="video/*"
                    className="file-upload__input"
                    onChange={this._handleImageChange}
                  />
                </div>
                {this.state.fileFieldError && this.state.submitted && (
                  <div className="inputErrorMsg">Video file is required</div>
                )}
              </Col>
              <Col sm={3}>
                <Button
                  className="icon-btn"
                  style={{
                    border:
                      this.state.alignVideoPreview == "flex-start"
                        ? "0.1vw solid #FF6347"
                        : "",
                  }}
                  onClick={() =>
                    this.setState({ alignVideoPreview: "flex-start" })
                  }
                >
                  <AlignLeft className="icon-video" />
                </Button>
                <Button
                  className="icon-btn"
                  style={{
                    border:
                      this.state.alignVideoPreview == "center"
                        ? "0.1vw solid #FF6347"
                        : "",
                  }}
                  onClick={() => this.setState({ alignVideoPreview: "center" })}
                >
                  <AlignCenter className="icon-video" />
                </Button>
                <Button
                  className="icon-btn"
                  style={{
                    border:
                      this.state.alignVideoPreview == "flex-end"
                        ? "0.1vw solid #FF6347"
                        : "",
                  }}
                  onClick={() =>
                    this.setState({ alignVideoPreview: "flex-end" })
                  }
                >
                  <AlignRight className="icon-video" />
                </Button>
              </Col>
            </Row>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <div
                className="browse-image video-preview"
                style={{ justifyContent: this.state.alignVideoPreview }}
              >
                {this.state.videoPreviewUrl != "" && (
                  <VideoThumbnail videoUrl={this.state.videoPreviewUrl} />
                )}
                <Video className="video-icon-style" />
              </div>
            )}
            {this.props.sectionArr !== undefined &&
              this.props.sectionArr.length > 0 && (
                <Row>
                  <Col sm={4}>
                    <div>
                      <select
                        onChange={this._getSelectedSection}
                        className="form-control"
                        value={this.state.selectedSectionIndex}
                      >
                        <option value="question" key="sectionOption-question">
                          Question
                        </option>
                        {this.props.sectionArr.map((item, index) => (
                          <option value={index} key={`sectionOption-${index}`}>
                            {item.section_name === undefined
                              ? item.title
                              : item.section_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              )}
            <div className="section-btn">
              <Button className="icon-btn" onClick={() => this._onHideBlock()}>
                <Trash className="icon-style" />
              </Button>
              <Button className="icon-btn" onClick={() => this._addtoPreview()}>
                <Save className="icon-style" />
              </Button>
            </div>
          </div>
        </div>
        {this.state.showLowStoragePopup ? (
          <LowSpace
            message={this.state.lowStorageMessage}
            handleLowSpace={this._handleLowSpace}
          />
        ) : null}
      </div>
    );
  }
}
