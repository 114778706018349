/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Accordion,
  OverlayTrigger,
  Popover,
  Modal,
  Badge,
  Container,
} from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import LoadingView from "app/shared/components/Loader/LoadingView";
import "font-awesome/css/font-awesome.min.css";
import { ResolvePopup, InviteUser } from "app/shared/components/Popups";
import CommonDropdown from "app/views/TaskManagement/CommonDropdown";
import TeamUnavailable from "assets/images/teamun.svg";
import {
  ChevronDown,
  ChevronRight,
  PieChart,
  Send,
  mic,
  Mic,
  FileText,
  UserPlus,
  Trash,
  Edit,
  Info,
  Calendar,
  ChevronLeft,
  Search,
  Filter,
  Plus,
  PlusSquare,
  RefreshCw,
  X,
  Clock,
} from "react-feather";
import { toast } from "react-toastify";
import Url from "app/shared/constants/Url";
import axios from "axios";
import HighchartsReact from "highcharts-react-official";
import AudioPlayer from "react-h5-audio-player";
import { Link } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import CheckFormSettingField from "app/shared/utility/CheckFormSettingField";
import { CarouselComponent } from "app/shared/components/ReusableComponents/Carousel";
import { DeletePopup } from "app/shared/components/Popups";
import Backbutton from "app/shared/components/Backbutton";
import { convertDateFromTimezone } from "app/shared/utility/tzUtilFunction";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { convertDateFromTimezoneIST } from "app/shared/utility/tzUtilFunction";
import NmDashboard from "../Dashboard/NmDashboard";
import moment from "moment";
import DiscussIcon from "assets/images/icons/discuss.svg";
import CrownIcon from "assets/images/icons/Crown .svg";
import AssignedIcon from "assets/images/icons/account.svg";
import EscalatedIcon from "assets/images/icons/escalated.svg";
import ResolvedIcon from "assets/images/icons/resolved.svg";
import ControlMeasureIcon from "assets/images/icons/controlmeasure.svg";

import { HorizontalNavTab } from "app/shared/components/ReportModal";
import HighBadgeIcon from "assets/sls-icons/Risk Level/High.svg";
import MediumBadgeIcon from "assets/sls-icons/Risk Level/Medium.svg";
import LowBadgeIcon from "assets/sls-icons/Risk Level/Low.svg";
import ExtremeBadgeIcon from "assets/sls-icons/Risk Level/Extreme.svg";
import { Scrollbars } from "react-custom-scrollbars";
import { Recommendation } from "app/shared/components/Forms";
import * as Highcharts from "highcharts";
import ReactTooltip from "react-tooltip";
import img from "assets/images/no-image2.jpg";
import { HorizontaTimeline } from "app/views/Form/UserFormView/UserFormView";
import { ChatMessageBox } from "app/views/TaskManagement/TaskDetail";
import { requiredMessage } from "app/shared/constants/Messages";
import URL from "app/shared/constants/Url";
import DatePicker from "react-datepicker";
import FishboneChart from "fishbone-chart";
import { ProgressBarWrapper } from "app/views/Ira/Detail";
// ui kit components
import {
  ProgressBar as SLDProgressBar,
  Modal as SLDModal,
  Button as SLDButton,
} from "@salesforce/design-system-react";
import { FaRegTrashCan } from "react-icons/fc";
import { Spinner } from "app/views/Ira/Detail";
//valition components

import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";

//utils
import _ from "lodash";
import {
  getUserProfileDetails,
  GetPopoverFrame,
} from "app/views/SocialMedia/ReportingDetail/ReportingDetail";

import { searchInArrary } from "app/views/Form/SubmitFormListing/FormApprovalTableView";
import UaUcGraph from "app/shared/components/PredictiveAnalysis/UaUcGraph";
import { config } from "config.js";
import CountGraph from "app/views/Graphs/Graphs/CountGraph";

const qs = require("qs");
const badgeEnum = {
  "High Critical": ExtremeBadgeIcon,
  High: HighBadgeIcon,
  Medium: MediumBadgeIcon,
  Low: LowBadgeIcon,
};
const tabs = [
  {
    tabName: "Proposed CAPA",
    key: "prop",
  },
  {
    tabName: "Final CAPA",
    key: "final",
  },
];
export const getNextDays = (currentDate = new Date(), daysToAdd = 1) => {
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + daysToAdd);
  return nextDate;
};
export const _momentDate = (stringDate) =>
  moment(stringDate, "DD-MM-YYYY").toDate();
class NMDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecommendationEnabled: false,
      iAssignErrorEnabled: false,
      isCategoryErrorEnabled: false,
      isCorretiveActionErrorEnabled: false,
      isCAPAErrorEnabled: false,
      isDueDateErrorEnabled: false,
      isPriortiyErrorEnabled: false,
      isFreezeEnabled: false,
      isFreezeLoadingEnabled: false,
      show: false,
      userLists: [],
      nmCache: {},
      showUser: false,
      nmDetail: [],
      nmFormSetting: [],
      nmId: this.props.match.params.id,
      originFlag: this.props.match.params.origin,
      reportStatus: this.props.match.params.reportStatus,
      soiId: this.props.match.params.id,
      resolveFlag: this.props.match.params.flag,
      selectedTargetDate: null,
      selectedDate: null,
      factorParams: {},
      isFactorLoading: false,
      isCommentApiLoading: false,
      toggleAddQuestion: false,
      correctiveAction: false,
      isCommentApiLoading: false,
      toggleStep3: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      toggleStep3edit: false,
      toggleStep2: false,
      step2Type: "none",
      currentTab: "prop",
      dataLoading: true,
      accordionOpen: true,
      lightboxactive: false,
      subcommentlist: [],
      isRecommendationEnable: false,
      comment: 0,
      isDataFilterEnable: false,
      isFilterLoading: false,
      isQuestionLoading: false,
      extremeSelect: 0,
      extremeValue: 0,
      deleteQuestionId: null,
      highSelect: 0,
      recommendedby: [],
      mediumSelect: 0,
      lowSelect: 0,
      all: 0,
      filterParams: {
        category: "",
        recommendation_by: "",
        responsible_person_id: "",
        startDate: "",
        endDate: "",
        page_no: 1,
      },
      commentAdd: "",
      commentlist: [],
      allComments: false,
      redirectToReferrer: false,
      toggler: false,
      teamLeadID: null,
      deletePopup: false,
      confirmPopup: false,
      afterTargetDatePopup: false,
      userLikedPopup: false,
      fsToggler: false,
      userLike: [],
      likeNumber: "",
      imageType: "evidence" || "evidence_resolve",
      fsToggler1: false,
      toggler: false,
      lightboxactive: false,
      evidencelightbox: [],
      evidencelightboxType: [],
      assignedDetails: {},
      showPopupSearch: false,
      obs_rec: "",
      deletePopupCAPA: false,
      ApproveTab: false,
      showContractor: false,
      showZone: false,
      showLocation: false,
      projectList: [],
      completioncount: [],
      capacount: [],
      opencount: [],
      closecount: [],
      gpcount: [],
      tbtcount: [],
      trainingInternalCount: [],
      trainingExternalCount: [],
      incidentPersonName: [],
      trainingEmployeeCount: [],
      trainingContractorCount: [],
      commentError: false,
      ContributingFactors: [],
      ImmediateFactors: [],
      RcaFactors: [],
      cflist: [],
      Iflist: [],
      rcalist: [],
      fcrdelID: null,
      questionList: [],
      Category_list: [],
      responsible_person: [],
      responsible_p: null,
      category_l: null,
      recommendation: "",
      CorrectiveActionText: "",
      priority: { extreme: false, high: false, medium: false, low: false },
      priority_l: 0,
      r_id: -1,
      editR_id: null,
      FishData: {},
      capaType: "",
    };
    this.myRef = React.createRef();
    this.openResolve = this.openResolve.bind(this);
    this.openInviteUser = this.openInviteUser.bind(this);
  }

  componentDidMount() {
    this.CommentList();
    this.nmDetail();
    this.UserWhoLiked();
    this.nmDetails();
    this.Dasycount();
  }
  // componentDidUpdate() {
  //   if (!this.state.dataLoading) {
  //     let el = document.getElementsByClassName("effectValue");

  //     if (el?.length) {

  //       el[0].outerHTML( `<div>${this.state.nmDetail?.activity_name} ${<OverlayTrigger
  //         trigger="hover"
  //         placement="right"
  //         rootClose={true}

  //         overlay={
  //           <Popover
  //             id="popover-positioned-right"
  //             style={{ width: "10vw", height: "3vw", borderRadius: "10px", textAlign: "center" }}
  //           >

  //             <div className="output-text font-16 font-weight-500 mt-2">
  //               Root Cause Analysis
  //             </div>
  //           </Popover>
  //         }
  //       >
  //         <Info className="cursor-pointer  d-block text-danger ml-1" style={{ height: "20px" }} />
  //       </OverlayTrigger>}</div>`)

  //       console.log(el);

  //     }
  //   }

  // }

  //Add question
  addQuestion = async () => {
    console.log("Sayan this.addQuestion");
    await this.setState((prevState) => {
      let { questionList } = prevState;
      questionList.push({
        question: "",
        response: "",
        isQuestionErrorEnabled: false,
        isDescriptionEnabled: false,
      });
      return { questionList };
    });
  };

  onChangeHandle = async (index, question) => {
    console.log("condition 1");
    await this.setState((prevState) => {
      let { questionList } = prevState;
      questionList[index].question = question;
      return { questionList };
    });
  };

  onChangeHandle2 = async (index, response) => {
    await this.setState((prevState) => {
      let { questionList } = prevState;
      questionList[index].response = response;
      return { questionList };
    });
  };

  async removeQuestion(index) {
    var tIndex = this.state.questionList.findIndex((item) => item == index);
    await this.setState((prevState) => {
      let { questionList } = prevState;
      questionList.splice(tIndex, 1);
      return { questionList };
    });
  }

  handleCheckboxChange = (event) => {
    const { factors_list } = this.state?.CFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      CFList: {
        ...prevState.CFList,
        factors_list: updatedFactorsList,
      },
    }));
  };

  setFishData() {
    const RawFishdata = this.state.nmDetail.nm_reported_detail_RCA_factor_list;
    console.log("sayan RCA ", RawFishdata);
    const filtered = this.state.nmDetail.nm_reported_detail_RCA_factor_list.map(
      (el) => el.factor
    );

    const extractData = (RawFishdata) => {
      let modules = {};
      RawFishdata.forEach((el) => {
        modules = {
          ...modules,
          [el]: [],
        };
      });
      return modules;
    };
    var FishDataforGraph = {
      [this.state.nmDetail?.activity]: {
        ...extractData(filtered),
      },
    };

    console.log(FishDataforGraph);

    this.setState({ FishData: FishDataforGraph });
  }

  ApproveRemmondation = async (approve) => {
    const itemsLength =
      this.state.nmDetail.nm_reported_detail_proposed_recommendation_list
        ?.length;
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    const near_miss_id = this.state.soiId;
    const project_d = this.state.nmDetail.project_id;
    const service_d = this.state.nmDetail.service_id;
    const category_id = this.state.category_l;
    const recommended_id = approve;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_d,
      project_id: project_d,
      nearmiss_id: near_miss_id,
      recommendation_id: recommended_id,
    };
    // console.warn(data)

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/praposedrecommendationApproved", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({ message: responseJson.msg });
          // console.warn(this.state.message)
          // this._ProposedRecommend();

          this.hideModal();
          if (itemsLength === 1) {
            this.setState({ currentTab: "final" });
          }
          this.nmDetail();
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  _editRecommendation = async (id) => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const near_miss_id = this.state.soiId;

    const project_d = this.state.nmDetail.project_id;
    const service_d = this.state.nmDetail.service_id;
    const category_id = this.state.category_l;
    const recommendation = this.state.CorrectiveActionText;
    const target_date = moment(this.state.targetDate).format("YYYY-MM-DD");
    const priority = this.state.priority_l;
    const person_id = this.state.responsible_p;
    const recommendedId = id;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_d,
      project_id: project_d,
      nearmiss_id: near_miss_id,
      category_id: category_id,
      recommendation: recommendation,
      recommendation_id: recommendedId,
      target_date: target_date,
      priority: priority,
      responsible_person_id: person_id,
    };

    fetch(URL.clientURL + "/listnm/editrecommendation", {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.nmDetail();
          console.log("sayan 200");
        }
      })
      .catch((error) => {
        // throw error;
        // ToastMessage(error);
        console.log("error");
      });
  };

  DeleteRemmondation = async (r_id) => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const near_miss_id = this.state.soiId;

    const recommended_id = r_id;

    let data = {
      auth_key: token,
      user_id: userId,
      recommendation_id: recommended_id,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/deleterecommendation", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          // this.finalFreezeDetails();
          console.log("sayan 200");
          this.hideModal();
          this.nmDetail();
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  handleCheckboxChangeIF = (event) => {
    const { factors_list } = this.state?.IFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      IFList: {
        ...prevState.IFList,
        factors_list: updatedFactorsList,
      },
    }));
  };

  handleCheckboxChangeRAC = (event) => {
    const { factors_list } = this.state?.RCAFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      RCAFList: {
        ...prevState.RCAFList,
        factors_list: updatedFactorsList,
      },
    }));
  };

  onPriorityChange(num) {
    if (num == 0) {
      this.setState({
        priority: { extreme: true, high: false, medium: false, low: false },
      });
      this.setState({ priority_l: 4 });
    }
    if (num == 1) {
      this.setState({
        priority: { extreme: false, high: true, medium: false, low: false },
      });
      this.setState({ priority_l: 3 });
    }
    if (num == 2) {
      this.setState({
        priority: { extreme: false, high: false, medium: true, low: false },
      });
      this.setState({ priority_l: 2 });
    }
    if (num == 3) {
      this.setState({
        priority: { extreme: false, high: false, medium: false, low: true },
      });
      this.setState({ priority_l: 1 });
    }
  }

  _AddRecommendation = async () => {
    if (this.manageCapaValidation()) {
      return false;
    }
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    this.setState({ isRecommendationEnabled: true });
    const near_miss_id = this.state.soiId;

    const project_d = this.state.nmDetail.project_id;
    const service_d = this.state.nmDetail.service_id;
    const category_id = this.state.category_l;
    const recommendation = this.state.CorrectiveActionText;
    const target_date = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const priority = this.state.priority_l;
    const person_id = this.state.responsible_p;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_d,
      project_id: project_d,
      nearmiss_id: near_miss_id,
      category_id: category_id,
      recommendation: recommendation,
      target_date: target_date,
      capa_type: this.state.capaType,
      priority: priority,
      responsible_person_id: person_id,
    };
    console.log(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    // Object.entries(data).forEach((el) => {
    //   formBody.push(el[0], el[1])
    // })

    fetch(URL.clientURL + "/listnm/addrecommendation", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({ recommendations: responseJson.data });
          this.setState({ isRecommendationEnabled: false });
          console.log("sayan 200 recommendations");
          this.manageReset();
          this.hideModal();
          this.cancleSubmitTargetTime();
          this.nmDetail();
        } else {
          console.log("sayan non 200");
        }
      })
      .catch((error) => {
        throw error;
        console.log("sayan error");
      });
  };
  _EditRecommendation = async () => {
    if (this.manageCapaValidation(true)) {
      return false;
    }
    this.setState({ isRecommendationEnabled: true });
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const near_miss_id = this.state.soiId;

    const project_d = this.state.nmDetail.project_id;
    const service_d = this.state.nmDetail.service_id;
    const category_id = this.state.category_l;
    const recommendation = this.state.CorrectiveActionText;
    const target_date = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const priority = this.state.priority_l;
    const person_id = this.state.responsible_p;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_d,
      project_id: project_d,
      nearmiss_id: near_miss_id,
      category_id: category_id,
      recommendation: recommendation,
      target_date: target_date,
      priority: priority,
      responsible_person_id: person_id,
      recommendation_id: this.state.editR_id,
      capa_type: this.state.capaType,
    };
    console.log(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    // Object.entries(data).forEach((el) => {
    //   formBody.push(el[0], el[1])
    // })

    fetch(URL.clientURL + "/listnm/editrecommendation", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({ recommendations: responseJson.data });
          this.setState({ isRecommendationEnabled: false });
          console.log("sayan 200 recommendations");
          this.manageReset();
          this.hideModal();
          this.cancleSubmitTargetTime();
          this.nmDetail();
        } else {
          console.log("sayan non 200");
        }
      })
      .catch((error) => {
        throw error;
        console.log("sayan error");
      });
  };
  manageEdit = (id, arr = []) => {
    const user = arr?.filter((el) => el.recommendation_id === id)[0] ?? {};
    this.setState({
      category_l: user.category_id?.toString(),
      CorrectiveActionText:
        user?.recommendation?.map((el) => el?.recommendation) ?? "",
      responsible_p: user?.responsible_person_list[0]?.person_id?.toString(),
      selectedDate: _momentDate(user?.target_date),
      editR_id: id,
      priority: {
        extreme: user?.priority === "Critical",
        high: user?.priority === "Urgent",
        medium: user?.priority === "Important",
        low: user?.priority === "Normal",
      },
      capaType: user.recommendation[0]?.capaType,
    });
    this.toggleStep3Tab();
  };

  manageReset = () => {
    this.setState({
      category_l: null,
      CorrectiveActionText: "",
      responsible_p: null,
      selectedDate: null,
      capaType: "",
      editR_id: null,
      priority: { extreme: false, high: false, medium: false, low: false },
    });
  };
  _removefinalcr = async (fcaId) => {
    const itemsLength =
      this.state.nmDetail.nm_reported_detail_final_recommendation_list?.length;
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    const near_miss_id = this.state.soiId;

    const project_d = this.state.nmDetail.project_id;
    const service_d = this.state.nmDetail.service_id;

    const data = {
      auth_key: token,
      user_id: userId,
      service_id: service_d,
      project_id: project_d,
      nearmiss_id: near_miss_id,
      recommendation_id: fcaId,
    };
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(URL.clientURL + "/listnm/finalrecommendationdelete", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then(() => {
        this.setState({
          fcrdelID: null,
        });
        if (itemsLength === 1) {
          this.setState({ currentTab: "prop" });
        }
        this.nmDetail();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  _AddParameter = async () => {
    // console.log("ADD Parameter fired")
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const near_miss_id = this.state.soiId;

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      nearmiss_id: near_miss_id,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/addparameterlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          console.log("sayan 200");
          if (responseJson.data) {
            this.setState({
              Category_list: responseJson.data.category_list || [],
              responsible_person: responseJson.data.responsible_person || [],
              dataLoading: false,
            });
          } else {
            this.setState({ dataLoading: false });
          }
        }
      })
      .catch((error) => {
        throw error;
        console.log("sayan error");
      });
    console.log(
      "1. category: ",
      this.state.Category_list,
      " 2. responsible: ",
      this.state.responsible_person
    );
  };

  nmDetail = async () => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    const clientUrl = localStorage.getItem("clientUrl");
    const near_miss_id = this.state.soiId;

    let details = {
      auth_key: token,
      user_id: userId,
      nearmiss_id: near_miss_id,
    };
    let formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/nearmissdetail", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          console.log("Tushar");
          console.log(responseJson);

          try {
            const {
              nm_reported_detail_people_involved_list,
              nm_reported_detail_proposed_recommendation_list,
              nm_reported_detail_final_recommendation_list,
              nm_reported_detail_contrubuting_factor_list,
              nm_reported_detail_immediate_factor_list,
              nm_reported_detail_RCA_factor_list,
              target_date,
              nm_reported_detail_incidence_analysis_question,
            } = responseJson.data;
            if (responseJson.data.reported_by_id) {
              this.fetchReportingPerson(responseJson.data.reported_by_id);
            }

            await this.setState(
              {
                nmCache: responseJson.data,
                nmDetail: responseJson.data,
                nm_reported_detail_people_involved_list:
                  nm_reported_detail_people_involved_list,
                proposedRecommendation:
                  nm_reported_detail_proposed_recommendation_list,
                finalRecommendation:
                  nm_reported_detail_final_recommendation_list,
                ContributingFactors:
                  nm_reported_detail_contrubuting_factor_list,
                ImmediateFactors: nm_reported_detail_immediate_factor_list,
                RcaFactors: nm_reported_detail_RCA_factor_list,
                incidentDateforView: target_date,
                iaq: nm_reported_detail_incidence_analysis_question,
                dataLoading: false,
                form_setting: responseJson.form_setting?.setting || [],
                teamsl: nm_reported_detail_people_involved_list.map(
                  (item) => item.status
                ),
                teamMember: responseJson.team_member,
              },
              () => {
                const involvedPersonsList =
                  this.state?.nmDetail?.incident_person_details?.map(
                    (el) => el.searched_incident_person_id
                  );
                if (involvedPersonsList.length) {
                  this.fetchUsersList(involvedPersonsList);
                }
              }
            );
            this.setFishData();
          } catch (error) {
            console.log(error);
          }
        }
      });
    console.log("sayan " + this.state.nmDetail.project_id);
  };

  handleContrubutingSubmit = () => {};

  showUserLikedPopup = () => {
    this.setState({
      userLikedPopup: true,
    });
  };

  handleClose = () => {
    this.setState({
      userLikedPopup: false,
    });
  };

  openResolve() {
    const show = !this.state.show;
    this.setState({
      show,
    });
  }

  openInviteUser() {
    const showUser = !this.state.showUser;
    this.setState({
      showUser,
    });
  }

  toggleCorrectiveAction() {
    console.log("fired");
    console.log(this.state.correctiveAction);
    if (this.state.correctiveAction) {
      this.setState({ correctiveAction: false });
    } else {
      this.setState({ correctiveAction: true });
    }
  }

  toggleAddQuestionTab() {
    if (this.state.toggleAddQuestion) {
      this.setState({ toggleAddQuestion: false });
      this.setState({ step2Type: "none" });
    } else {
      this.setState({ toggleAddQuestion: true });
    }
  }

  toggleStep3Tab() {
    if (this.state.toggleStep3) {
      this.manageReset();
      this.setState({ toggleStep3: false });
      this.setState({ correctiveAction: false });
    } else {
      this.setState({ toggleStep3: true });
      this.setState({ correctiveAction: true });
    }
  }

  toggleStep3EditTab(id) {
    if (this.state.toggleStep3edit) {
      this.setState({ toggleStep3edit: false });
      this.setState({ editR_id: null });
    } else {
      this.setState({ toggleStep3edit: true });
      this.setState({ editR_id: id });
    }
  }

  toggleApproveTabCAPA(id) {
    if (this.state.ApproveTab) {
      this.setState({ ApproveTab: false, r_id: -1 });
    } else {
      this.setState({ ApproveTab: true, r_id: id });
    }
  }

  toggleDeletePopupCAPA(id) {
    if (this.state.deletePopupCAPA) {
      this.setState({ deletePopupCAPA: false, r_id: -1 });
    } else {
      this.setState({ deletePopupCAPA: true, r_id: id });
    }
  }

  toggleStep2Fun(type = "none") {
    if (this.state.toggleStep2) {
      this.setState({ toggleStep2: false });
    } else {
      this.setState({ toggleStep2: true });
    }

    if (type == "immediate") {
      this.state.step2Type = "Immediate Factor";
    } else if (type == "root cause") {
      this.state.step2Type = "Root Cause Analysis";
    } else if (type == "contributing") {
      this.state.step2Type = "Contributing Factor";
    } else {
      this.state.step2Type = "none";
    }
  }

  
  _InProgressFactorAddList = async (factorId) => {
    const { nmDetail, soiId, CFList, IFList, RCAFList } = this.state;
  
    // Retrieve necessary items from localStorage and state
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const { project_id, service_id } = nmDetail;
    const near_miss_id = soiId;
  
    // Set loading state
    this.setState({ isFactorLoading: true });
  
    // Helper function to get the factor list based on factorId
    const getFactorList = (factorList) => {
      return factorList?.factors_list?.filter(el => el?.checked)?.map(el => el?.factor_id) || [];
    };
  
    // Determine factor list based on factorId
    const factorListMap = {
      1: CFList,
      2: IFList,
      3: RCAFList,
    };
    const selectedFactorList = getFactorList(factorListMap[factorId]);
  
    const data = {
      auth_key: token,
      user_id: userId,
      service_id,
      project_id,
      module_unique_id: near_miss_id,
      factor_id: factorId,
      sub_factor_id: selectedFactorList,
      module_name: "near_miss",
      custom_cf: [],
    };
  
    try {
      const response = await fetch(`${URL.clientURL}/rootcause/addfactors`, {
        method: "POST",
        body: qs.stringify(data, { arrayFormat: "repeat" }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });
  
      const responseJson = await response.json();
  
      if (responseJson.status === 200) {
        console.log("Success: Status 200");
        await Promise.all([
          this.resetFactorsState(this.toggleStep2Fun),
          this.nmDetail(),
          this.nmDetails()
        ]);
      } else {
        console.error(`Failed to add factors. Status: ${responseJson.status}, Message: ${responseJson.message}`);
      }
    } catch (error) {
      console.error(`Error occurred while adding factors: ${error.message || error}`);
    } finally {
      this.setState({ isFactorLoading: false });
    }
  };

  _InProgressContributingList = async () => {
    console.log("sayan fired");
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: near_miss_id,
      module_name: "near_miss",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/rootcause/contributingfactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          console.log("sayan 200 CFlist");
          await this.setState({ CFList: responseJson.data });
          // await this.setState((prevState) => {
          //   let CFList = { ...prevState.CFList };
          //   CFList.factors_list = CFList.factors_list.map((item) => ({
          //     ...item,
          //     isChecked: false,
          //   }));
          //   return { CFList };
          // });
        }
      });
  };

  resetFactorsState = (callback = {}) => {
    this.setState(
      {
        cflist: [],
        Iflist: [],
        rcalist: [],
      },
      callback
    );
  };
  _InProgressImmediateList = async () => {
    console.log("sayan fired");
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: near_miss_id,
      module_name: "near_miss",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/rootcause/immediatefactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          console.log("sayan 200 IFlist");
          this.setState({ IFList: responseJson.data });
          // this.setState((prevState) => {
          //   let IFList = { ...prevState.IFList };
          //   IFList.factors_list = IFList.factors_list.map((item) => ({
          //     ...item,
          //     isChecked: false,
          //   }));
          //   return { IFList };
          // });
        }
      });
  };

  _InProgressRcaList = async () => {
    // console.log("sayan fired")
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: near_miss_id,
      module_name: "near_miss",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/rootcause/rcafactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          console.log("RCAF 200 sayan");
          this.setState({ RCAFList: responseJson.data });
          // this.setState((prevState) => {
          //   let RCAFList = { ...prevState.RCAFList };
          //   RCAFList.factors_list = RCAFList.factors_list.map((item) => ({
          //     ...item,
          //     isChecked: false,
          //   }));
          //   return { RCAFList };
          // });
        }
      });
  };

  validateQuestions = (list = []) => {
    const invalid = [];

    const temp = list.map((el) => {
      if (el?.question === "" || el?.response === "") {
        invalid.push(el);
        return {
          ...el,
          isQuestionErrorEnabled: el?.question === "",
          isDescriptionEnabled: el?.response === "",
        };
      } else {
        return {
          ...el,
          isQuestionErrorEnabled: false,
          isDescriptionEnabled: false,
        };
      }
    });
    if (invalid.length > 0) {
      this.setState({ questionList: temp });
    }
    return invalid.length > 0 ? true : false;
  };
  // add incidence analysis question api
  analysisQuestionAdd = async () => {
    const isFormInvalid = this.validateQuestions(this.state.questionList);
    if (isFormInvalid && this.state.questionList.length > 0) {
      return false;
    }
    this.setState({
      isQuestionLoading: true,
    });
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;

    const question = this.state.questionList.map((item) => item.question);
    const response = this.state.questionList.map((item) => item.response);

    let databody = new FormData();
    databody.append("auth_key", token);
    databody.append("user_id", userId);
    databody.append("service_id", service_id);
    databody.append("project_id", project_id);
    databody.append("near_miss_id", near_miss_id);
    question.map((item) => item !== "" && databody.append("question", item));
    response.map(
      (item) => item !== undefined && databody.append("response", item)
    );

    fetch(URL.clientURL + "/listnm/nmaddquestion", {
      method: "post",
      body: databody,
      // headers: {
      //   'Content-Type': 'multipart/form-data',
      // },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.hideModal();
          this.nmDetail();
          this.nmDetails();

          AlertMessage({
            message: "Questions added successfully",
            type: "success",
          });
          this.setState({
            questionList: [],
          });
        } else {
          AlertMessage({
            message: "There is an error in adding. Please try again",
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error");
        AlertMessage({
          message: "There is an error in adding. Please try again",
          type: "error",
        });
      })
      .finally((done) =>
        this.setState({
          isQuestionLoading: false,
        })
      );
  };
  analysisQuestionDelete = async (questionId) => {
    console.log("sayan fired");
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      near_miss_id: near_miss_id,
      question_id: questionId,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/nmdeletequestion", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          console.log("sayan 200");
          AlertMessage({
            message: "Questions deleted successfully",
            type: "success",
          });
          this.nmDetail();
          this.nmDetails();
          this.setState({ deleteQuestionId: null });
        } else {
          AlertMessage({
            message: "There is an error in deleting. Please try again",
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error");
        AlertMessage({
          message: "There is an error in deleting. Please try again",
          type: "error",
        });
      });
  };

  submitTargetDate() {
    var targetDate = this.state.selectedTargetDate;
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;
    let data = {
      auth_key: token,
      user_id: userId,
      nearmiss_id: near_miss_id,
      target_date: moment(targetDate).format("YYYY-MM-DD"),
      service_id: service_id,
      project_id: project_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/nmtargetdateupdate", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          AlertMessage({
            message: "Target date updated successfully",
            type: "success",
          });
          this.setState({
            afterTargetDatePopup: this.state.reportStatus === "pending",
            teamLeadID: null,
            confirmPopup: false,
          });
        } else {
          AlertMessage({ message: "unable to set target date", type: "error" });
        }
      });
  }

  confirmSubmitTargetDate() {
    this.setState({ confirmPopup: true });
  }

  cancleSubmitTargetTime() {
    this.setState({ selectedTargetDate: null });
    this.setState({ selectedDate: null });
    // console.log("heheh")
  }

  _handleChange = async (item = null, type = "observation") => {
    if (item != null && type == "target_date") {
      this.setState({ selectedTargetDate: item });
    }

    if (item != null && type == "step3Date") {
      this.setState({ selectedDate: item });
    }
  };

  _AddTeamLeader = async (user_id) => {
    this.setState({
      dataLoading: true,
    });
    console.log(user_id);
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;
    const team_member_id = user_id;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      nearmiss_id: near_miss_id,
      team_member_id: team_member_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/createteamleader", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        //window.location.reload(false);
        this.setState({ teamLeadID: user_id, dataLoading: false });
        if (Math.max.apply(null, this.state.teamsl) != 1) {
        }
        if (responseJson.status == 200) {
          await this.setState((prevState) => {
            let { nm_reported_detail_people_involved_list } = prevState;
            nm_reported_detail_people_involved_list =
              nm_reported_detail_people_involved_list.filter(
                (team) => team.user_id !== user_id
              );
            return { nm_reported_detail_people_involved_list };
          });

          this.nmDetail();
        }
      });
  };

  removeInviteUser = async (selectedUserId) => {
    console.log(selectedUserId);
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    // await axios
    //   .post(
    //     URL.clientURL+"listnm/removeteammembers",
    //     qs.stringify({
    //       auth_key: token,
    //       user_id: userId,
    //       project_id: this.state.nmDetail.project_id,
    //       service_id: this.state.nmDetail.service_id,
    //       nearmiss_id: this.state.soiId,
    //       team_member_id: selectedUserId,
    //     }),
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     console.log("sayan responce")
    //     if (response.data.status == 200) {
    //       console.log("sayan 200")
    //       AlertMessage({
    //         message: "User removed successfully",
    //         type: "success",
    //       });
    //       this.nmDetail();
    //     } else {
    //       console.log("sayan error")
    //       AlertMessage({
    //         message: "User not removed. Please try again.",
    //         type: "error",
    //       });
    //     }
    //   })
    //   .catch((error) => {

    //     console.log("error")

    //   });

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      nearmiss_id: near_miss_id,
      team_member_id: selectedUserId,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/removeteammembers", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          // this.nmDetail();
          await this.setState((prevState) => {
            let { nm_reported_detail_people_involved_list } = prevState;
            nm_reported_detail_people_involved_list =
              nm_reported_detail_people_involved_list.filter(
                (team) => team.user_id !== selectedUserId
              );
            return { nm_reported_detail_people_involved_list };
          });
          window.location.reload(false);
        }
      })
      .catch((error) => {
        console.log("sayan err");
      });
  };

  _fetchProjectListFlag = async () => {
    const { reportType } = this.state;
    let moduleId = 2;
    // if (reportType == "soi") {
    //   moduleId = 1;
    // } else if (reportType == "nm") {
    //   moduleId = 2;
    // } else if (reportType == "ira") {
    //   moduleId = 3;
    // }

    let requestData = {
      service_id: this.state.nmDetail.service_id,
      module_name: "near_miss",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { showContractor, showZone, showLocation, projectList } =
                prevState;

              projectList = response.data.data;

              // check contractor flag
              const isContractor = projectList.map(
                (item) => item.contractor_flag
              );
              showContractor = isContractor == 0 ? false : true;
              console.log("isContractor == " + isContractor);

              const isZone = projectList.map((item) => item.zone_location_flag);
              showZone = isZone == 0 ? false : true;
              console.log("isZone == " + isZone);

              const isLocation = projectList.map(
                (item) => item.zone_location_flag
              );
              showLocation = isLocation == 0 ? false : true;
              console.log("isLocation == " + isLocation);

              return {
                showContractor,
                showZone,
                showLocation,
                projectList,
              };
            });
          }
        })
        .catch((error) => {
          this.setState({
            dataLoading: false,
          });
        });
    });
  };

  CommentList = async (limitComment = 99999) => {
    this.setState({ isCommentApiLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.getCommentsList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.nmId,
          report_type: "nm",
          limit: limitComment,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            commentlist: response.data.data,
            total_number: response.data.total_number,
            comment: 0,
          });
        }
      })
      .catch((error) => {})
      .finally(() => this.setState({ isCommentApiLoading: false }));
  };

  subCommentList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    this.setState({ isCommentApiLoading: true });
    await axios
      .post(
        Url.smDashboard.dashboarddetailcommentsubcommentlist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.nmId,
          comment_id: this.state.commentId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            subcommentlist: response.data.data,
            comment: 1,
            isCommentApiLoading: false,
          });
        }
      })
      .catch((error) => {})
      .finally(() => this.setState({ isCommentApiLoading: false }));
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.commentError = tempObj.commentAdd ? false : true;

      return prevState;
    });
  };

  CommentAdd = async () => {
    await this._formValidation();

    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          console.log(this.state[propertyName]);
          formErrorStatus = true;
          console.log("erroe");
          return;
        } else {
          formErrorStatus = false;
        }
        console.log(formErrorStatus);
      }
    }

    if (!formErrorStatus) {
      this.setState({ isCommentApiLoading: true });
      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");

      await axios
        .post(
          Url.smDashboard.addMainComment,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            id: this.state.nmId,
            module: "nm",
            comment: this.state.commentAdd,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.CommentList();
            this.setState({ commentAdd: "", isCommentApiLoading: false });
            AlertMessage({
              message: "Comment Added Successfully",
              type: "success",
            });
          } else {
            AlertMessage({
              message: "Comment Add Failed. Please try again.",
              type: "error",
            });
          }
        })
        .catch((error) => {});
    }
  };

  ReplyCommentAdd = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    this.setState({ isCommentApiLoading: true });
    await axios
      .post(
        Url.smDashboard.replyToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reply_status: this.state.comment,
          comment_reply_id: 0,
          main_comment_id: this.state.commentId,
          comment: this.state.replycommentAdd,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.subCommentList();
          this.setState({ replycommentAdd: "", isCommentApiLoading: false });
          AlertMessage({
            message: "Reply Added Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Reply Add Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentAdd = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.addLikeToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Liked Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Like Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentRemove = async (Cid, Ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboardcontentcommentremoveunlike,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: Ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Liked Remove Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Like Remove Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  CommentRemove = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    var nmDetail = this.state.nmDetail;

    await axios
      .post(
        Url.soiDashboard.soideletecomment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: nmDetail.service_id,
          project_id: nmDetail.project_id,
          soi_id: this.state.soiId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Deleted Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Deleted Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  deleteReport = async (nmId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.nmModule.deleteNMReport,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          near_miss_id: nmId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: "NM Deleted Successfully",
            type: "success",
          });
          setTimeout(() => {
            this.setState({ redirectToReferrer: true });
          }, 3000);
        } else {
          AlertMessage({
            message: "NM Delete Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  UserWhoLiked = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetaillikelist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.nmId,
          report_type: "nm",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLike: response.data.data,
            likeNumber: response.data.like_number,
          });
        }
      })
      .catch((error) => {});
  };

  //dayscount
  Dasycount = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.nmModule.nmDaysCount,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          nearmiss_id: this.state.nmId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            completioncount: response.data.data.capa_completion_days,
            capacount: response.data.data.report_completion_days,
            opencount: response.data.data.recommendation_open_count,
            closecount: response.data.data.recommendation_close_count,
            gpcount: response.data.data.gp_count?.length
              ? response.data.data.gp_count
              : [{ gp_count: 0 }],
            tbtcount: response.data.data.tbt_count?.length
              ? response.data.data.tbt_count
              : [{ tbt_count: 0 }],
            trainingInternalCount: response.data.data.training_internal_count
              ?.length
              ? response.data.data.training_internal_count
              : [{ training_internal_count: 0 }],
            trainingExternalCount: response.data.data.training_external_count
              ?.length
              ? response.data.data.training_external_count
              : [{ training_external_count: 0 }],
            trainingEmployeeCount: response.data.data.training_employee_count
              ?.length
              ? response.data.data.training_employee_count
              : [{ training_employee_count: 0 }],
            trainingContractorCount: response.data.data
              .training_contractor_count?.length
              ? response.data.data.training_contractor_count
              : [{ training_contractor_count: 0 }],
          });
        }
      })
      .catch((error) => {});
  };

  _addUsersToList = async (newUsers) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const reportId = this.state.soiId;
    // console.log(newUsers);
    var selectedUserIds = [];
    newUsers.map((item, key) => selectedUserIds.push(item.team_member_id));

    var data = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.nmDetail.service_id,
      project_id: this.state.nmDetail.project_id,
      nearmiss_id: this.state.soiId,
      // selected_user_id: selectedUserIds,
      team_members: selectedUserIds,
      status: 0,
    };
    // console.log(""+token+"  " + userId + " "+ this.state.nmDetail.project_id + " "+ this.state.soiId + " ")

    // var formBody = [];
    // for (var property in data) {
    //   var encodedKey = encodeURIComponent(property);
    //   var encodedValue = encodeURIComponent(data[property]);
    //   formBody.push(encodedKey + '=' + encodedValue);
    //   if (property == "selected_user_id") {
    //     data[property].map((item, key) =>
    //       formBody.push(encodedKey + "=" + item)
    //     );
    //   } else {
    //     formBody.push(encodedKey + "=" + encodedValue);
    //   }
    // }
    // formBody = formBody.join("&");

    // await axios
    //   .post("https://dev-api.clideoffice.com/listnm/addteammembers", formBody, {
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //   })

    fetch(URL.clientURL + "/listnm/addteammembers", {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({ message: "User added successfully", type: "success" });
          console.log("done cheee ");
          this.nmDetail();
        } else {
          console.log(" not done cheee ");
          AlertMessage({
            message: "User not added. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.log("not not done cheee ");
        window.location.reload(false);
      });
  };

  _toggleDataLoading = async (flag) => {
    await this.setState({ dataLoading: flag });
  };

  nmDetails = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let requestData = {
        nearmiss_id: this.state.nmId,
      };
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.nmModule.nmDetail,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          try {
            if (response.data.status == 200) {
              await this.setState(
                (prevState) => {
                  let {
                    dataLoading,
                    nmDetail,
                    nmFormSetting,
                    incidentPersonName,
                  } = prevState;
                  let { data, form_setting } = response.data;
                  dataLoading = false;
                  nmDetail = data;
                  nmFormSetting = form_setting?.setting;
                  incidentPersonName =
                    response.data.data.incident_person_details;

                  return {
                    dataLoading,
                    nmDetail,
                    nmFormSetting,
                    incidentPersonName,
                  };
                },
                () => {
                  this._AddParameter();
                  this._InProgressContributingList();
                  this._InProgressImmediateList();
                  this._InProgressRcaList();
                }
              );
              this._fetchProjectListFlag();
            } else {
              this._toggleDataLoading(false);
            }
          } catch (error) {
            this._toggleDataLoading(false);
          }
        })
        .catch((error) => {
          this._toggleDataLoading(false);
        });
    });
  };

  cancelItem_onClick = () => {
    this.props.history.goBack();
    //window.history.back();
  };

  togglecommentId = async (comment_id) => {
    await this.setState({ commentId: comment_id });
    await this.subCommentList();
  };

  backToMainComment = async () => {
    await this.CommentList();
    await this.setState({ comment: 0, accordionOpen: true });
  };

  showModal = () => {
    this.setState({ deletePopup: true });
  };

  hideModal = () => {
    this.setState({ deletePopup: false });
    this.setState({ confirmPopup: false });
    this.setState({ afterTargetDatePopup: false });
    this.setState({ toggleAddQuestion: false });
    this.setState({ toggleStep3: false });
    this.setState({ toggleStep2: false });
    this.setState({ deletePopupCAPA: false });
    this.setState({ ApproveTab: false });
    this.setState({ toggleStep3edit: false });
  };

  _handleBackButtonAction = () => {
    if (this.state.originFlag == "kanban") {
      return "/nm/kanban";
    } else {
      return "/";
    }
  };

  _handleLightBox = async (list) => {
    console.log(list.map((item) => item.format));
    await this.setState({
      lightboxactive: true,
      fsToggler1: !this.state.fsToggler1,
      evidencelightbox: list.map((item) => item.evidence),
      evidencelightboxType: list.map((item) => "image"),
    });
  };

  modalHideSearch = () => {
    this.setState({
      showPopupSearch: false,
    });
  };
  modalShowSearch = (rec) => {
    this.setState({
      showPopupSearch: true,
      obs_rec: rec,
    });
  };

  comapareTargetDate = (date = "") => {
    if (date) {
      const targetDate = moment(date, "DD/MM/YYYY");
      const today = moment();
      if (targetDate.isSame(today, "day")) {
        return true;
      }
      return false;
    }
  };

  renderTimeLine = (reportData, state) => {
    if (state.dataLoading) {
      return [];
    }
    let details = [
      {
        title: <span className="font-14">Reported Details</span>,
        cardTitle: (
          <div className="d-flex  " style={{ width: "15vw" }}>
            <img
              src={reportData.reported_by_pic}
              className="user-icon"
              title={reportData.reported_by}
              alt="avatar"
            />
            <div className="d-flex flex-column">
              <span className="common-grey-1 font-14">Reported On </span>
              <span className="common-black-1 font-14 font-weight-500">
                {`${reportData.reported_date} `} | {reportData?.reported_time}{" "}
              </span>
            </div>
          </div>
        ),
      },
      {
        title: <span className="font-14">Incident Date</span>,
        cardTitle: (
          <div className="d-flex  " style={{ width: "15vw" }}>
            <img
              src={reportData.reported_by_pic}
              className="user-icon"
              title={reportData.reported_by}
              alt="avatar"
            />
            <div className="d-flex flex-column">
              <span className="common-grey-1 font-14">Date & Time </span>
              <span className="common-black-1 font-14 font-weight-500">
                {`${reportData.incident_date} `} | {reportData?.incident_time}{" "}
              </span>
            </div>
          </div>
        ),
      },
    ];
    if (reportData.incident_person_details?.length) {
      details = [
        ...details,
        ...reportData.incident_person_details?.map((image, index) => {
          return {
            title: <span className="font-14">Incident Employee Details </span>,
            cardTitle: (
              <div className="d-flex   " style={{ width: "11vw" }}>
                <img
                  className="user-icon "
                  src={require("../../../../assets/sls-icons/V2. New Development/user.svg")}
                  alt=""
                  title={image.incident_person_name}
                />

                <div>
                  <div className="common-grey-1 font-14">Incident Person</div>
                  <div className="common-black-1 font-14 font-weight-500">
                    {" "}
                    {image.incident_person_name} |{" "}
                    {image.incident_person_id ?? index + 1}
                  </div>
                </div>
              </div>
            ),
          };
        }),
      ];
    }
    if (reportData.nm_reported_detail_people_involved_list?.length) {
      details = [
        ...details,
        ...reportData.nm_reported_detail_people_involved_list?.map(
          (image, index) => {
            return {
              title: <span className="font-14">Invited Team </span>,
              cardTitle: (
                <div className="d-flex   " style={{ width: "11vw" }}>
                  <img
                    className="user-icon "
                    src={image.user_pic}
                    alt=""
                    title={image.user_name}
                  />

                  <div>
                    <div className="common-grey-1 font-14">
                      Team {image.status == 1 ? "Lead" : "Member"}
                    </div>
                    <div className="common-black-1 font-14 font-weight-500">
                      {" "}
                      {image.user_name}
                    </div>
                  </div>
                </div>
              ),
            };
          }
        ),
      ];
    }

    if (state.commentlist?.length) {
      const { comment, comment_time, user_name, user_pic } =
        state.commentlist[0] ?? {};
      details = [
        ...details,
        {
          title: <span className="font-14">Latest Comment</span>,
          cardTitle: (
            <div
              className="d-flex  cursor-pointer "
              style={{ width: "11vw" }}
              onClick={() => {
                this.myRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              <img
                src={user_pic}
                className="user-icon"
                title={user_name}
                alt="avatar"
              />
              <div>
                <div className="common-grey-1 font-14">Message</div>
                <div className="common-black-1 font-14 font-weight-500">
                  {" "}
                  {comment}{" "}
                </div>
                <span className="font-12 common-grey-1">{comment_time}</span>
              </div>
            </div>
          ),
        },
      ];
    }
    return details;
  };
  __generateCarouselData = (items = []) => {
    const content = [];
    items.forEach((data) => {
      const format = data?.evidence_format ?? data.format;
      if (format !== undefined) {
        content.push({
          url: data.link ?? data.evidence_image,
          contentType:
            format != "text"
              ? format == "image" || format == "img"
                ? "image"
                : "video"
              : "video",
        });
      }
    });
    return content;
  };

  actionFreezeAll = () => {
    this.setState({
      isFreezeLoadingEnabled: true,
    });
    const baseUrl = URL.clientURL + "/listnm/finalrecommendationfreezeall";
    const user_id = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const nearmiss_id = this.state.soiId;
    const selected_recommendations =
      this.state.nmDetail.nm_reported_detail_final_recommendation_list.map(
        (el) => el.recommendation_id
      );
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      nearmiss_id,
      selected_recommendations,
    };
    fetch(baseUrl, {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          AlertMessage({
            type: "success",
            message: res?.msg ?? "Record  completed ",
          });

          this.props.history.push(`/nm-detail/${nearmiss_id}/kanban/completed`);
          window.location.reload();
        } else {
          AlertMessage({
            type: "error",
            message: res?.msg ?? "Something went wrong",
          });
        }
      })
      .catch((err) => console.log("error", err))
      .finally(() =>
        this.setState({
          isFreezeEnabled: false,
          isFreezeLoadingEnabled: false,
        })
      );
  };

  fetchReportingPerson = (id) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    axios
      .post(
        Url.clientURL + "/user/reportedpersonprofile",
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reported_per_id: id,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          assignedDetails: res.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  getDepartment = (text = "") => {
    const [label = "", value] = text.split(":") ?? [];
    if ((label.trim() === "Department" && value.trim() !== "null") || null) {
      return value;
    }
    return "NA";
  };

  manageFilterParams = (id, value) => {
    this.setState({
      filterParams: {
        ...this.state.filterParams,
        [id]: value,
      },
    });
  };

  getFilterData = () => {
    this.setState({
      isFilterLoading: true,
    });
    const baseUrl = URL.clientURL + "/listnm/recommendationfilter";
    const user_id = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const nearmiss_id = this.state.soiId;
    const isPropsed = this.state.currentTab === "prop" ? 0 : 1;
    const { extremeSelect, highSelect, mediumSelect, lowSelect } = this.state;
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      nearmiss_id,
      ...this.state.filterParams,
      startDate:
        this.state.filterParams.startDate !== ""
          ? moment(this.state.filterParams.startDate).format("YYYY-MM-DD")
          : "",
      endDate:
        this.state.filterParams.endDate !== ""
          ? moment(this.state.filterParams.endDate).format("YYYY-MM-DD")
          : "",
      list_type: isPropsed,
      priority: [
        extremeSelect ? extremeSelect : undefined,
        highSelect ? highSelect : undefined,
        mediumSelect ? mediumSelect : undefined,
        lowSelect ? lowSelect : undefined,
      ],
    };
    axios
      .post(baseUrl, qs.stringify(data, { arrayFormat: "repeat" }), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            nmDetail: {
              ...this.state.nmDetail,
              [isPropsed === 0
                ? "nm_reported_detail_proposed_recommendation_list"
                : "nm_reported_detail_final_recommendation_list"]:
                res.data.data.recommendationlist,
            },
            isDataFilterEnable: false,
          });
        } else if (res.data.status === 500) {
          AlertMessage({
            type: "error",
            message: "Something is wrong ",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          isFilterLoading: false,
        });
      });
  };
  FilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ extremeSelect: 4, all: 0 });
      } else {
        this.setState({ extremeSelect: 0, all: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ highSelect: 3, all: 0 });
      } else {
        this.setState({ highSelect: 0, all: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ mediumSelect: 2, all: 0 });
      } else {
        this.setState({ mediumSelect: 0, all: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ lowSelect: 1, all: 0 });
      } else {
        this.setState({ lowSelect: 0, all: 0 });
      }
    }
    // if (value == 'overdue') {
    //   if (param == 0) {
    //     this.setState({ overdue: 1, all: 0 });
    //   } else {
    //     this.setState({ overdue: 0, all: 0 });
    //   }
    // }
    // if (value == 'all') {
    //   if (param == 0) {
    //     this.setState({
    //       all: 1,
    //       extremeSelect: 4,
    //       highSelect: 3,
    //       mediumSelect: 2,
    //       lowSelect: 1,
    //       severity: [1, 2, 3, 4],
    //     });
    //   } else {
    //     this.setState({
    //       all: 0,
    //       extremeSelect: 0,
    //       highSelect: 0,
    //       mediumSelect: 0,
    //       lowSelect: 0,
    //       severity: [],
    //     });
    //   }
    // }
  }

  makeReset = () => {
    this.setState(
      {
        isDataFilterEnable: false,
        extremeSelect: 0,
        extremeValue: 0,
        highSelect: 0,
        mediumSelect: 0,
        lowSelect: 0,
        filterParams: {
          category: "",
          recommendation_by: "",
          responsible_person_id: "",
          startDate: "",
          endDate: "",
          page_no: 1,
        },
      },
      this.nmDetail
    );
  };
  _getCommonParams = () => {
    return {
      auth_key: localStorage.getItem("token"),
      user_id: localStorage.getItem("userId"),
      service_id: this.state.nmDetail.service_id,
      project_id: this.state.nmDetail.project_id,
      nearmiss_id: this.state.soiId,
      module_name: "near_miss",
    };
  };
  _removeFactor = (factorId, sub_factor_id, callBack) => {
    let data = {
      ...this._getCommonParams(),
      factor_id: sub_factor_id,
      sub_factor_id: factorId,
      module_unique_id: this.state.soiId,
      module_name: "near_miss",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/rootcause/deletefactors", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.nmDetail();
          this.nmDetails();
          this._InProgressContributingList();
          this._InProgressImmediateList();
          this._InProgressRcaList();
          this.resetFactorsState(this.toggleStep2Fun);
        }
      })
      .finally(() => {
        callBack(sub_factor_id);
      });
  };
  manageFactors = (data) => {
    const { factorID, formType } = data ?? {};
    this.setState(
      {
        isFactorLoading: true,
      },
      this._removeFactor(factorID, formType, (id) => {
        this.setState({
          isFactorLoading: false,
          factorParams: {},
        });
        // if (id === 3) {
        //   this._InProgressRcaList();
        // } else if (id === 1) {
        //   this._InProgressContributingList();
        // } else {
        //   this._InProgressImmediateList();
        // }
      })
    );
  };
  manageCapaValidation = (isEditTrue = false) => {
    const {
      responsible_p,
      category_l,
      capaType,
      selectedDate,
      CorrectiveActionText,
      priority_l,
    } = this.state;
    let isErrorEnabled = false;
    if (
      !responsible_p ||
      !category_l ||
      !capaType ||
      !CorrectiveActionText ||
      !selectedDate ||
      (!isEditTrue && !priority_l)
    ) {
      isErrorEnabled = true;
    }
    this.setState({
      iAssignErrorEnabled: !responsible_p,
      isCategoryErrorEnabled: !category_l,
      isCorretiveActionErrorEnabled: !CorrectiveActionText,
      isCAPAErrorEnabled: !capaType,
      isDueDateErrorEnabled: !selectedDate,
      isPriortiyErrorEnabled: isEditTrue ? false : !priority_l,
    });
    return isErrorEnabled;
  };
  fetchUsersList = async (ids = []) => {
    let databody = new FormData();
    const userList = ids.filter((el) => {
      return !Number.isNaN(el);
    });
    if (!userList.length) {
      return false;
    }
    databody.append("auth_key", localStorage.getItem("token"));
    databody.append("user_id", localStorage.getItem("userId"));
    databody.append("user_id_list", userList);
    await axios
      .post(Url.usersList, databody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLists: response.data?.listUserProfileDetailsData,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  CountDoughnut = (data) => {
    return data.length > 0 ? (
      <div style={{ justifyContent: "center" }}>
        <UaUcGraph
          title=""
          graphData={data}
          list={false}
          height={75}
          cutout={"55%"}
          radius={"100%"}
        />
      </div>
    ) : null;
  };
  debounceFunc = _.debounce(function (callback) {
    callback();
  }, 300);

  render() {
    console.log("nm detailsssss", this.state.nmDetail.activity_analysis);
    var sourcesList = [];
    var sourcesType = [];
    var inputMessageFlag = 0;
    const { nmDetail, soiId, nmCache, userData, filterParams } = this.state;
    const teamLead =
      nmDetail?.nm_reported_detail_people_involved_list?.filter(
        (el) => el.status === 1
      )[0] ?? [];
    const isApproveEnabled =
      userData?.designation_flag === 1 ||
      userData?.designation_flag === 3 ||
      userData?.designation_flag === 5 ||
      userData?.user_id === teamLead?.user_id;
    const isReportedByEnabled = userData?.user_id === nmDetail?.reported_by_id;
    const capaMinDate = !_.isUndefined(nmDetail.target_date)
      ? getNextDays(_momentDate(nmDetail.target_date))
      : new Date();
    const isTeamMemeberLoggedIn = Boolean(
      searchInArrary(nmDetail?.nm_reported_detail_people_involved_list, {
        key: "user_id",
        value: userData?.user_id,
      })
    );

    const list =
      this.state.currentTab === "prop"
        ? nmCache?.nm_reported_detail_proposed_recommendation_list
        : nmCache?.nm_reported_detail_final_recommendation_list ?? [];

    const recommendedby = _.unionBy(
      list?.map((el) => {
        return {
          recommendedBy: el?.recommended_by,
          recommended_id: el?.recommended_by_id,
        };
      }),
      "recommended_id"
    );
    // [...new Map(arr.map(item => [item[key], item])).values()]
    const isTargetDateEnabled = Boolean(
      nmDetail.nm_reported_detail_people_involved_list?.filter(
        (el) => el.status === 1
      )?.length
    );
    console.log("is Target date enabled", isTargetDateEnabled);
    console.log("nm details", nmDetail);
    console.log(
      isTargetDateEnabled === true &&
        (this.state.reportStatus == "pending" || "progress")
    );
    if (this.state.imageType && this.state.imageType == "evidence") {
      sourcesList = nmDetail.nm_reported_detail_evidence_images?.map(
        (item) => item.evidence_image
      );
      sourcesType = nmDetail.nm_reported_detail_evidence_images?.map(
        (item) => item.evidence_format
      );
    }
    const isInProgress = ["progress"].includes(this.state.reportStatus);
    const isCrownEnabled =
      ["progress", "pending"].includes(this.state.reportStatus) &&
      isApproveEnabled;
    const isDeleteEnabled =
      ["progress", "pending"].includes(this.state.reportStatus) &&
      isApproveEnabled;
    if (this.state.redirectToReferrer) {
      return <Redirect to={`/nm/dashboard`} />;
    }

    let uaUcChartData = [];
    if (nmDetail?.activity_analysis != undefined) {
      uaUcChartData = [
        {
          y: nmDetail.activity_analysis?.ua_number,
          name: "Unsafe Act",
          color: "#C7AC83",
        },
        {
          y: nmDetail.activity_analysis?.uc_number,
          name: "Unsafe Condition",
          color: "#554040",
        },
      ];
    }

    const uaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: nmDetail.activity_analysis?.ua_number,
              name: "Unsafe Act",
              color: "#C7AC83",
            },
            {
              y: nmDetail.activity_analysis?.uc_number,
              name: "Unsafe Condition",
              color: "#554040",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    let nmuaUcChartData = [];

    if (nmDetail?.activity_analysis != undefined) {
      nmuaUcChartData = [
        {
          y: nmDetail.activity_analysis?.nm_ua_number,
          name: "Unsafe Act",
          color: nmDetail.activity_analysis?.nm_ua_color,
        },
        {
          y: nmDetail.activity_analysis?.nm_uc_number,
          name: "Unsafe Condition",
          color: nmDetail.activity_analysis?.nm_uc_color,
        },
      ];
    }

    const nmuaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: nmDetail.activity_analysis?.nm_ua_number,
              name: "Unsafe Act",
              color: nmDetail.activity_analysis?.nm_ua_color,
            },
            {
              y: nmDetail.activity_analysis?.nm_uc_number,
              name: "Unsafe Condition",
              color: nmDetail.activity_analysis?.nm_uc_color,
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    let analysisChartData = [];
    if (nmDetail?.activity_analysis != undefined) {
      analysisChartData = [
        {
          y: nmDetail?.activity_analysis?.fa_number ?? 0,
          name: "FA",
          color: "#FF4242",
        },
        {
          y: nmDetail?.activity_analysis?.rta_number ?? 0,
          name: "RTA",
          color: "#FAAC15",
        },
        {
          y: nmDetail?.activity_analysis?.mtc_number ?? 0,
          name: "MTC",
          color: "#086992",
        },
        {
          y: nmDetail?.activity_analysis?.lti_number ?? 0,
          name: "LTI",
          color: "#18DA07",
        },
        {
          y: nmDetail?.activity_analysis?.first_aid_number ?? 0,
          name: "First Aid",
          color: "#1589EE",
        },
        {
          y: nmDetail?.activity_analysis?.occupational_illness_number ?? 0,
          name: "OI",
          color: "#C511FF",
        },
        {
          y: nmDetail?.activity_analysis?.notable_number || 0,
          name: "Notable",
          color: "rgb(161, 255, 206)",
        },
      ];
    }

    const analysisChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: nmDetail.activity_analysis?.fa_number || 0,
              name: "FA",
              color: "#FF4242",
            },
            {
              y: nmDetail.activity_analysis?.tra_number || 0,
              name: "RTA",
              color: "#FAAC15",
            },
            {
              y: nmDetail.activity_analysis?.mtc_number || 0,
              name: "MTC",
              color: "#086992",
            },
            {
              y: nmDetail.activity_analysis?.lti_number || 0,
              name: "LTI",
              color: "#18DA07",
            },
            {
              y: nmDetail.activity_analysis?.first_aid_number || 0,
              name: "First Aid",
              color: "#1589EE",
            },
            {
              y: nmDetail.activity_analysis?.occupational_illness_number || 0,
              name: "OI",
              color: "#C511FF",
            },
            {
              y: nmDetail.activity_analysis?.notable_number || 0,
              name: "Notable",
              color: "#rgb(161, 255, 206)",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    //DaysCount
    const newDays = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.completioncount.map(
            (data) => data.capa_completion_days_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.completioncount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.completioncount.map(
              (data) => data.capa_completion_days_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //Investigation
    const investigationChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.capacount.map(
            (data) => data.report_completion_days_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.capacount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.capacount.map(
              (data) => data.report_completion_days_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //Open
    const openChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.opencount.map((data) => data.recommendation_open_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.opencount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.opencount.map((data) => data.recommendation_open_count),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //Close
    const closeChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.closecount.map((data) => data.recommendation_close_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.closecount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.closecount.map(
              (data) => data.recommendation_close_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //GpCount
    const gpChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.gpcount.map((data) => data.gp_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.gpcount.map((data) => data.color),
      series: [
        {
          data: [this.state.gpcount.map((data) => data.gp_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //tbtCount
    const tbtChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.tbtcount.map((data) => data.tbt_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.tbtcount.map((data) => data.color),
      series: [
        {
          data: [this.state.tbtcount.map((data) => data.tbt_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //InternalCOunt
    const internalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingInternalCount.map(
            (data) => data.training_internal_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingInternalCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingInternalCount.length > 0
              ? this.state.trainingInternalCount.map(
                  (data) => data.training_internal_count
                )
              : 0,
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //externalcount
    const externalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingExternalCount.map(
            (data) => data.training_external_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingExternalCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingExternalCount.map(
              (data) => data.training_external_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //employeeCount
    const employeeCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingEmployeeCount.map(
            (data) => data.training_employee_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingEmployeeCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingEmployeeCount.map(
              (data) => data.training_employee_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //contractorCount
    const contractorCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingContractorCount.map(
            (data) => data.training_contractor_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingContractorCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingContractorCount.map(
              (data) => data.training_contractor_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    if (this.state.originFlag == "socialMedia") {
      var originUrl = {
        pathname: `/`,
      };
    } else if (this.state.originFlag == "thgPO") {
      var originUrl = {
        pathname: `/ua-uc/open`,
      };
    } else if (this.state.originFlag == "thgPR") {
      var originUrl = {
        pathname: `/ua-uc/resolve`,
      };
    }

    return (
      <div className="report-detail  bg-white">
        {!this.state.dataLoading ? (
          <FsLightbox
            toggler={this.state.fsToggler}
            types={sourcesType}
            sources={sourcesList}
          />
        ) : null}

        {!!this.state.lightboxactive && (
          <FsLightbox
            toggler={this.state.fsToggler1}
            types={this.state.evidencelightboxType}
            disableLocalStorage={true}
            sources={this.state.evidencelightbox}
          />
        )}

        <Modal
          className="reportPopup searchPopup"
          centered
          show={this.state.showPopupSearch}
          onHide={this.modalHideSearch}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <AudioPlayer
                src={this.state.obs_rec}
                showJumpControls={true}
                layout="horizontal-reverse"
                customVolumeControls={[]}
                customAdditionalControls={[]}
                onPlay={(e) => {
                  console.log(e);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>

        {/* <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <img
            className="loader-img"
            src={require("assets/images/loader1.gif")}
          />
        </div> */}
        <LoadingView showLoading={this.state.dataLoading} />

        {/* <div className="right-icons" style={{ width: "5vw" }}>
          {nmDetail.edit_flag == 1 && (
            <Link
              to={`/edit-nm-reporting-detail/${nmDetail.nm_id}/${nmDetail.service_id}/${nmDetail.project_id}/edit`}
            >
              <div className="rd-btn right-block-gap">
                <i size="44" className="fa fa-pencil" aria-hidden="true"></i>
              </div>
            </Link>
          )}

          {nmDetail.delete_flag == 1 ? (
            <div className="rd-btn right-block-gap">
              <i
                size="44"
                className="fa fa-trash"
                aria-hidden="true"
                onClick={() => this.showModal()}
              ></i>
            </div>
          ) : null}

          {this.state.reportStatus == "completed" ? (
            <div className="rd-btn right-block-gap">
              <Link
                to={`/nm-report/${nmDetail.nm_id}`}
                target="_blank"
              >
                <img
                  src={require("assets/images/icons/fpdf.svg")}
                  alt="completed pdf"
                />
              </Link>
            </div>
          ) : null}
        </div> */}

        {/* new Work look */}
        <div className="rd-content  flex-column p-5">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <div>
              <h2>
                <Link to={this._handleBackButtonAction()}>
                  <Button className="back-btn">
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </Button>
                </Link>
              </h2>
            </div>
            <div>
              <span className="common-black-1 font-weight-500">
                {" "}
                {soiId ?? ""}{" "}
                <span
                  style={{
                    borderLeft: "3px Solid Orange",
                    padding: "0 5px 0 5px",
                  }}
                >
                  {" "}
                  {nmDetail.type_of_act == "condition" ? "UC" : "UA"} -{" "}
                  {nmDetail.activity}{" "}
                </span>
                <span
                  style={{
                    borderLeft: "3px Solid Orange",
                    padding: "0 5px 0 5px",
                  }}
                >
                  {nmDetail?.reportedmonth_name}
                </span>
              </span>
            </div>

            <div className="d-flex align-items-center">
              {["pending", "progress"].includes(this.state.reportStatus) ? (
                <>
                  {nmDetail.edit_flag == 1 && (
                    <Link
                      to={`/edit-nm-reporting-detail/${nmDetail.nm_id}/${nmDetail.service_id}/${nmDetail.project_id}/edit`}
                    >
                      <img
                        src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                        className="common-grey-1 mr-1 svg-icons"
                      />
                    </Link>
                  )}

                  {nmDetail.delete_flag == 1 ? (
                    <div className="rd-btn ">
                      <img
                        src={require("assets/sls-icons/V2. New Development/delete.svg")}
                        className=" cursor-pointer common-grey-1 svg-icons mr-1"
                        onClick={() => this.showModal()}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}

              {this.state.reportStatus == "completed" ? (
                <div className="rd-btn right-block-gap ">
                  <Link to={`/nm-report/${nmDetail.nm_id}`} target="_blank">
                    <img
                      src={require("assets/sls-icons/V2. New Development/PDF.svg")}
                      className="svg-icons"
                      alt="completed pdf"
                    />
                  </Link>
                </div>
              ) : null}
              <div>
                {this.state.resolveFlag == "resolve" ? (
                  <span className="common-black-1 font-weight-500">
                    {" "}
                    Resolved{" "}
                  </span>
                ) : null}

                {/* {this.state.resolveFlag != "resolve" &&
                  this.state.reportStatus != "completed" &&
                  (
                    <img
                      src={require("assets/sls-icons/V2. New Development/Add.svg")}
                      className=" cursor-pointer common-grey-1 mr-1 svg-icons"
                      onClick={this.openInviteUser}
                    />
                  )} */}
                {["pending", "progress"].includes(this.state.reportStatus) &&
                isApproveEnabled ? (
                  <img
                    src={require("assets/sls-icons/V2. New Development/Add.svg")}
                    className=" cursor-pointer common-grey-1 mr-1 svg-icons"
                    onClick={this.openInviteUser}
                  />
                ) : null}
                <div className="image-box">
                  {nmDetail.invited_user?.map((image, index) => (
                    <img
                      style={{ marginRight: "0.5vw" }}
                      src={image.userpic}
                      alt=""
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div
              className="horizontal-timeline-wrapper shadow-none border  mb-4 "
              style={{ width: "100%" }}
            >
              <div className="timeline-header">
                <p className="common-black-1 font-weight-500">Timeline</p>
              </div>
              <div>
                {Object.keys(nmDetail).length > 0 ? (
                  <HorizontaTimeline
                    data={this.renderTimeLine(nmDetail, this.state)}
                    reInitlization={[
                      nmDetail,
                      this.state.commentlist,
                      this.state,
                    ]}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="d-flex  ">
            <div className="rd-left-content mt-5 pl-0">
              <VerticalTimeline
                layout={"1-column-left"}
                className="m-0"
                lineColor={"#7D6767"}
              >
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: "4vw",
                    paddingTop: 0,
                    boxShadow: "none",
                  }}
                  icon={<FileText />}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0.5vw",
                      }}
                      className="mb-5"
                    >
                      <div
                        style={{
                          background: "rgba(217, 217, 217, 0.5)",
                          borderRadius: "28px",
                        }}
                        className="px-3 py-2 common-black-1 font-weight-500  text-center"
                      >
                        Reporting Details
                      </div>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="rd-box w-75">
                          <div className="label mb-3 font-weight-500 ">
                            View Evidence
                          </div>
                          <div className="rd-box-bottom">
                            <div className="images-section">
                              <CarouselComponent
                                items={this.__generateCarouselData(
                                  this.state.resolveFlag == "open"
                                    ? this.state.nmDetail.evidence
                                    : nmDetail.nm_reported_detail_evidence_images ??
                                        []
                                )}
                                showArrows={false}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="label mb-3 font-weight-500 ">
                          Incident Description
                        </div>
                        <div className="rd-box-bottom">
                          <Row>
                            <Col>
                              <div className="feild-group rd-box">
                                {nmDetail.observation != "" ? (
                                  <div className="output-text">
                                    {nmDetail.observation}
                                  </div>
                                ) : (
                                  "NA"
                                )}
                              </div>
                              <div className="feild-group rd-box ">
                                {/* <span className="mic-icon"><Mic /></span> */}
                                {nmDetail.observation_recording != undefined &&
                                  nmDetail.observation_recording.substr(
                                    nmDetail.observation_recording.length - 3
                                  ) == "mp3" && (
                                    <AudioPlayer
                                      src={nmDetail.observation_recording}
                                      showJumpControls={true}
                                      layout="horizontal-reverse"
                                      customVolumeControls={[]}
                                      customAdditionalControls={[]}
                                      className="border-0  shadow-none"
                                    />
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="d-flex flex-column justify-content-between ">
                          {CheckFormSettingField(
                            this.state.nmFormSetting,
                            "hazard"
                          ) && nmDetail?.hazard ? (
                            <div className="feild-group d-flex">
                              <div className="label mb-2 common-black-1 font-weight-500">
                                Hazard
                              </div>
                              <div className="output-text ml-4">
                                {nmDetail.hazard}
                              </div>
                            </div>
                          ) : null}
                          <div className="feild-group d-flex align-items-center ">
                            <div className="label mb-2 common-black-1 font-weight-500 mr-4">
                              Risk Level
                            </div>
                            <img
                              src={badgeEnum[nmDetail.severity]}
                              style={{ width: "30px" }}
                            />
                            <label className="output-text ml-3">
                              {nmDetail.severity}
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: "4vw",
                    paddingTop: 0,
                    boxShadow: "none",
                    boxShadow: "none",
                    textAlign: "center",
                  }}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  icon={<Info />}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div className="rd-box-head">
                      <div className="mt-5">
                        <Row
                          style={{
                            marginBottom: "0.5vw",
                            rowGap: "2vw",
                          }}
                          className="text-left"
                        >
                          <Col sm={6}>
                            <div className="rd-box">
                              <div className="rd-box-bottom pt-0">
                                <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                  Activity info
                                </h3>
                                <Row
                                  style={{
                                    rowGap: "2vw",
                                    marginTop: "1.5vw",
                                  }}
                                >
                                  <Col md={6}>
                                    <div className="feild-group">
                                      <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                        Service
                                      </div>
                                      <div className="output-text w-max">
                                        {nmDetail.service}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="feild-group">
                                      <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                        Plant/Project
                                      </div>
                                      <div className="output-text w-max">
                                        {nmDetail.project}
                                      </div>
                                    </div>
                                  </Col>

                                  {CheckFormSettingField(
                                    this.state.nmFormSetting,
                                    "barrier"
                                  ) ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                          Barrier
                                        </div>
                                        <div className="output-text w-max">
                                          {nmDetail.barrier}
                                        </div>
                                      </div>
                                    </Col>
                                  ) : null}

                                  {CheckFormSettingField(
                                    this.state.nmFormSetting,
                                    "sub hazard"
                                  ) ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                          Sub Hazard
                                        </div>
                                        <div className="output-tex w-maxt">
                                          {nmDetail.sub_hazard} ?? "NA"
                                        </div>
                                      </div>
                                    </Col>
                                  ) : null}
                                </Row>
                              </div>
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="rd-box">
                              <div className="rd-box-bottom pt-0">
                                <h3 className="font-weight-500 common-red-3 font-18  heading-red-border ">
                                  Department/Contractor/Location
                                </h3>
                                <Row
                                  style={{
                                    rowGap: "2vw",
                                    marginTop: "1.5vw",
                                  }}
                                >
                                  {CheckFormSettingField(
                                    this.state.nmFormSetting,
                                    "department"
                                  ) ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                          Department
                                        </div>
                                        {!nmDetail.department ? (
                                          <div className="output-text">NA</div>
                                        ) : (
                                          <div className="output-text w-max">
                                            {nmDetail.department}
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  ) : null}
                                  {CheckFormSettingField(
                                    this.state.nmFormSetting,
                                    "contractor"
                                  ) &&
                                  this.state.showContractor &&
                                  nmDetail.contractor ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                          Contractor
                                        </div>
                                        {!nmDetail.contractor ? (
                                          <div className="output-text w-max">
                                            NA
                                          </div>
                                        ) : (
                                          <div className="output-text w-max">
                                            {nmDetail.contractor}
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  ) : null}
                                  {CheckFormSettingField(
                                    this.state.nmFormSetting,
                                    "zone"
                                  ) &&
                                  this.state.showZone &&
                                  nmDetail.zone ? (
                                    <Col md={6}>
                                      {this.state.showZone && nmDetail.zone && (
                                        <div className="feild-group">
                                          <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                            Zone
                                          </div>
                                          <div className="output-text w-max">
                                            {nmDetail.zone}
                                          </div>
                                        </div>
                                      )}
                                    </Col>
                                  ) : null}
                                  {CheckFormSettingField(
                                    this.state.nmFormSetting,
                                    "location"
                                  ) &&
                                  this.state.showLocation &&
                                  nmDetail.location ? (
                                    <Col md={6}>
                                      {this.state.showLocation &&
                                        nmDetail.location && (
                                          <div className="feild-group">
                                            <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                              Location
                                            </div>
                                            <div className="output-text w-max">
                                              {nmDetail.location}
                                            </div>
                                          </div>
                                        )}
                                    </Col>
                                  ) : null}
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: "4vw",
                    paddingTop: 0,
                    boxShadow: "none",
                  }}
                  icon={<Info />}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0.5vw",
                      }}
                      className="mb-5"
                    >
                      <div
                        style={{
                          background: "rgba(217, 217, 217, 0.5)",
                          borderRadius: "28px",
                        }}
                        className="px-3 py-2 common-black-1 font-weight-500  text-center"
                      >
                        Incident Details
                      </div>
                    </div>

                    <Row
                      style={{
                        rowGap: "2vw",
                        marginTop: "1.5vw",
                      }}
                    >
                      <Col md={12}>
                        <div className="d-flex common-flex-gap">
                          <div className="feild-group">
                            <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                              Incident Date
                            </div>
                            <div className="output-text">
                              {`${nmDetail.incident_date}`}
                            </div>
                          </div>

                          <div className="feild-group">
                            <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                              Incident Time
                            </div>
                            <div className="output-text">
                              {nmDetail.incident_time}
                            </div>
                          </div>
                          <div className="feild-group">
                            <div className="label mb-3 common-black-1 font-weight-500 font-18">
                              Incident Employee Details
                            </div>
                            <Scrollbars
                              style={{ height: "11vw", width: "40vw" }}
                            >
                              <div className="d-flex ">
                                {this.state.incidentPersonName?.map(
                                  (data, index, arr) => {
                                    const detail =
                                      getUserProfileDetails(
                                        parseInt(
                                          data.searched_incident_person_id
                                        ),
                                        this.state.userLists
                                      ) ?? {};
                                    const isUserFromOrgnization =
                                      data?.searched_incident_person_id;
                                    const position =
                                      arr.length - 1 === index
                                        ? "left"
                                        : "right";
                                    return isUserFromOrgnization ? (
                                      <div
                                        className="feild-group common-card-border-layout-4 px-2 py-2 mr-2 rounded-0 shadow-none text-center "
                                        style={{
                                          minWidth: "11vw",
                                        }}
                                      >
                                        <div className="output-text ">
                                          <span className="label mt-3 font-14 common-black-1 font-weight-500">
                                            {data.incident_person_name}
                                          </span>
                                          <div
                                            className="image-box my-3 "
                                            style={{ marginRight: "0.5vw" }}
                                          >
                                            {/* {GetPopoverFrame(
                                          <img
                                            src={detail?.user_pic}
                                            alt=""
                                            className="cursor-pointer"
                                            style={{
                                              height: "2.7vw",
                                              width: "2.7vw"
                                            }}
                                          />
                                          , () => {
                                            return {
                                              ID: detail['employeeId'] ?? "NA",
                                              Department: detail['field']?.split(':')[1] ?? "NA",
                                              Type: detail['userType'] ?? "NA",
                                              Designation: detail['designation'] ?? "NA",
                                              Contact: detail['contact_number'] ?? "NA",
                                            }
                                          },
                                          position
                                        )
                                        } */}
                                            <GetPopoverFrame
                                              element={
                                                <>
                                                  <img
                                                    src={detail?.user_pic}
                                                    alt=""
                                                    className="cursor-pointer"
                                                  />
                                                </>
                                              }
                                              mappingKeys={() => {
                                                return {
                                                  ID:
                                                    detail["employeeId"] ??
                                                    "NA",
                                                  Department:
                                                    detail["field"]?.split(
                                                      ":"
                                                    )[1] ?? "NA",
                                                  Type:
                                                    detail["userType"] ?? "NA",
                                                  Designation:
                                                    detail["designation"] ??
                                                    "NA",
                                                  Contact:
                                                    detail["contact_number"] ??
                                                    "NA",
                                                  // Zone: detail['designation'],
                                                  // Location: detail['designation'],
                                                };
                                              }}
                                            />
                                          </div>
                                          <div className="feild-group d-flex justify-content-center flex-column">
                                            <div className="label common-black-1 mx-auto font-weight-500 font-14 mr-2  pb-0">
                                              Id{" "}
                                            </div>
                                            <div className=" label font-14 font-weight-500">
                                              {data?.incident_person_id}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div
                                          className="feild-group mr-2 common-card-border-layout-4 px-2 py-2 rounded-0 shadow-none text-center "
                                          style={{
                                            minWidth: "11vw",
                                          }}
                                          key={index}
                                        >
                                          <div className="output-text ">
                                            <span className="label mt-3 font-14 common-black-1 font-weight-500">
                                              {data.incident_person_name}
                                            </span>
                                            <div className=" my-3">
                                              <img
                                                src={require("../../../../assets/sls-icons/V2. New Development/user.svg")}
                                                alt=""
                                              />
                                            </div>
                                            <div className="feild-group d-flex justify-content-center flex-column">
                                              <div className="label common-black-1 mx-auto font-weight-500 font-14 mr-2  pb-0">
                                                Id{" "}
                                              </div>
                                              <div className=" label font-14 font-weight-500">
                                                {data.incident_person_id ??
                                                  index + 1}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </Scrollbars>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </VerticalTimelineElement>
                {nmDetail.tl_assign_date != "" ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    icon={<Calendar />}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5vw",
                        }}
                        className="mb-5"
                      >
                        <div
                          style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px",
                          }}
                          className="px-3 py-2 common-black-1 font-weight-500  text-center"
                        >
                          Start Time
                        </div>
                      </div>
                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                        NM Investigation Start Detail
                      </h3>
                      <Row
                        style={{
                          rowGap: "2vw",
                          marginTop: "1.5vw",
                        }}
                      >
                        <Col md={12}>
                          <div className="d-flex common-flex-gap">
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Start Date{" "}
                                <OverlayTrigger
                                  trigger="hover"
                                  placement="right"
                                  rootClose={true}
                                  overlay={
                                    <Popover
                                      id="popover-positioned-right"
                                      style={{
                                        width: "10vw",
                                        height: "3vw",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div className="output-text font-16 font-weight-500 mt-2">
                                        Investigation Team Assigned Date
                                      </div>
                                    </Popover>
                                  }
                                >
                                  <Info
                                    className="cursor-pointer  d-block text-danger ml-1"
                                    style={{ height: "20px" }}
                                  />
                                </OverlayTrigger>
                              </div>

                              <div className="output-text">
                                {nmDetail.tl_assign_date ?? ""}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                {nmDetail.assign_to_id != "" ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    icon={<Calendar />}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5vw",
                        }}
                        className="mb-5"
                      >
                        <div
                          style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px",
                          }}
                          className="px-3 py-2 common-black-1 font-weight-500  text-center"
                        >
                          Team / Due Date
                        </div>
                      </div>
                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                        NM Investigation Target Detail & Investigation Team
                        Details
                      </h3>
                      <Row
                        style={{
                          rowGap: "2vw",
                          marginTop: "1.5vw",
                        }}
                      >
                        <Col md={12}>
                          <div className="d-flex common-flex-gap">
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Due Date{" "}
                                <OverlayTrigger
                                  trigger="hover"
                                  placement="right"
                                  rootClose={true}
                                  overlay={
                                    <Popover
                                      id="popover-positioned-right"
                                      style={{
                                        width: "10vw",
                                        height: "3vw",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div className="output-text font-16 font-weight-500 mt-2">
                                        Target Date To Complete Investigation
                                      </div>
                                    </Popover>
                                  }
                                >
                                  <Info
                                    className="cursor-pointer  d-block text-danger ml-1"
                                    style={{ height: "20px" }}
                                  />
                                </OverlayTrigger>
                              </div>

                              <div className="output-text">
                                {nmDetail.target_date ?? "NA"}
                              </div>
                            </div>
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Investigation Team{" "}
                              </div>

                              <Scrollbars
                                style={{ height: "4vw", width: "25vw" }}
                              >
                                <div className="d-flex common-flex-gap">
                                  {nmDetail.nm_reported_detail_people_involved_list?.map(
                                    (item, index) => (
                                      <div
                                        key={`investigation-team-${index}`}
                                        className="position-relative"
                                      >
                                        <img
                                          src={item.user_pic}
                                          alt={item.user_name}
                                          width="96"
                                          height="96"
                                          title={item.user_name}
                                          className="user-icon border-0"
                                        />
                                        {item.status == 1 && (
                                          <img
                                            src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                                            alt=""
                                            style={{
                                              position: "absolute",
                                              height: "1vw",
                                              width: "1vw",
                                              left: "1vw",
                                              top: "0.8vw",
                                            }}
                                            title={item.user_name}
                                            className="user-icon border-0 position-absolute"
                                          />
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              </Scrollbars>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                {nmDetail.closing_date != 0 ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    icon={<Calendar />}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5vw",
                        }}
                        className="mb-5"
                      >
                        <div
                          style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px",
                          }}
                          className="px-3 py-2 common-black-1 font-weight-500  text-center"
                        >
                          Completed Date
                        </div>
                      </div>
                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                        NM Investigation Completed Detail
                      </h3>
                      <Row
                        style={{
                          rowGap: "2vw",
                          marginTop: "1.5vw",
                        }}
                      >
                        <Col md={12}>
                          <div className="d-flex common-flex-gap">
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Investigation Completed Date{" "}
                              </div>

                              <div className="output-text">
                                {nmDetail.closing_date ?? ""}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                {nmDetail.capa_completion_date != "" ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    icon={<Calendar />}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5vw",
                        }}
                        className="mb-5"
                      >
                        <div
                          style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px",
                          }}
                          className="px-3 py-2 common-black-1 font-weight-500  text-center"
                        >
                          CAPA Detail
                        </div>
                      </div>
                      <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                        CAPA Recommendation Details & CAPA Status
                      </h3>
                      <Row
                        style={{
                          rowGap: "2vw",
                          marginTop: "1.5vw",
                        }}
                      >
                        <Col md={12}>
                          <div className="d-flex common-flex-gap">
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Recommendation Date{" "}
                              </div>

                              <div className="output-text">
                                {nmDetail.capa_completion_date ?? ""}
                              </div>
                            </div>
                            <div className="feild-group ">
                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                Completed Percentage Status{" "}
                              </div>
                              <ProgressBarWrapper
                                id="setup-progress-bar"
                                value={nmDetail?.progress}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                {["progress", "completed"].includes(this.state.reportStatus) ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    icon={<Calendar />}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5vw",
                        }}
                        className="mb-5"
                      >
                        <div
                          style={{
                            background: "rgba(217, 217, 217, 0.5)",
                            borderRadius: "28px",
                          }}
                          className="px-3 py-2 common-black-1 font-weight-500  text-center"
                        >
                          Analysis
                        </div>
                      </div>
                      <div className="text-center mb-5">
                        <div className="common-seprator-grey-3 my-4"></div>
                        <span className=" common-red-3 font-22 ">
                          NM Investigation Analysis{" "}
                        </span>
                        <div className="common-seprator-grey-3 my-4"></div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                          Step 1 : Incident Analysis Question (5 WHY Analysis)
                        </h3>
                        <div>
                          {this.state.reportStatus === "progress" &&
                          isApproveEnabled ? (
                            <img
                              src={require("assets/sls-icons/V2. New Development/ADD2.svg")}
                              onClick={() => {
                                this.toggleAddQuestionTab();
                              }}
                              className="cursor-pointer svg-icons"
                            />
                          ) : null}
                        </div>
                      </div>
                      <Row
                        style={{
                          rowGap: "2vw",
                          marginTop: "1.5vw",
                        }}
                      >
                        <Col md={12}>
                          <Scrollbars style={{ height: "15vw" }}>
                            {nmDetail.nm_reported_detail_incidence_analysis_question?.map(
                              (el, idx) => (
                                <div className="d-flex justify-content-between mr-3">
                                  <div className="nm-question-wrapper mb-3">
                                    <div className="question-count">
                                      <span>Q {idx + 1} </span>
                                    </div>
                                    <div className="question-view">
                                      {el.question}
                                    </div>
                                    <div className="answer-view">
                                      {el.response}
                                    </div>
                                  </div>
                                  {this.state.reportStatus === "progress" &&
                                  isApproveEnabled ? (
                                    <div>
                                      <img
                                        src={require("assets/sls-icons/V2. New Development/delete.svg")}
                                        className="cursor-pointer svg-icons"
                                        onClick={() =>
                                          this.setState({
                                            deleteQuestionId: el?.question_id,
                                          })
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              )
                            )}
                          </Scrollbars>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-between align-items-center my-4">
                        <h3 className="font-weight-500 common-red-3 font-18 heading-red-border mb-4">
                          {" "}
                          Step 2 : Root Cause Analysis
                        </h3>
                      </div>
                      {this.state.reportStatus == "progress" &&
                      isApproveEnabled ? (
                        <Row>
                          <Col md={5}>
                            <div
                              className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                              style={{ width: "100%" }}
                            >
                              <div className="timeline-header p-0">
                                <button
                                  style={{
                                    backgroundColor: "darkgray",
                                    color: "white",
                                  }}
                                  disabled={!isApproveEnabled}
                                  className="badge  border font-weight-500 px-3 py-3 rounded-pill"
                                  onClick={() => {
                                    this.toggleStep2Fun("contributing");
                                  }}
                                >
                                  Add Contributing Factor{" "}
                                </button>
                              </div>
                              <div className="mx-3 my-3 px-3 pt-5">
                                <Scrollbars style={{ height: "15vw" }}>
                                  <div className="pr-4">
                                    {nmDetail?.nm_reported_detail_contrubuting_factor_list?.map(
                                      (el, idx) => (
                                        <div className="user-badge p-3 mb-2 mr-2 w-100">
                                          <div key={idx} className="user-name">
                                            {el.factor}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Scrollbars>
                              </div>
                            </div>
                          </Col>
                          <Col md={7}>
                            <div
                              className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                              style={{ width: "100%" }}
                            >
                              <div className="timeline-header p-0">
                                <button
                                  style={{
                                    backgroundColor: "darkgray",
                                    color: "white",
                                  }}
                                  className="badge border font-weight-500 px-3 py-3 rounded-pill"
                                  onClick={() => {
                                    this.toggleStep2Fun("immediate");
                                  }}
                                  disabled={!isApproveEnabled}
                                >
                                  Add Immediate Factor{" "}
                                </button>
                              </div>
                              <div className="mx-3 my-3 px-3 pt-5">
                                <Scrollbars style={{ height: "15vw" }}>
                                  <div
                                    className="pr-4 d-flex flex-wrap "
                                    style={{
                                      rowGap: "0.7vw",
                                      columnGap: "0.7vw",
                                    }}
                                  >
                                    {nmDetail?.nm_reported_detail_immediate_factor_list?.map(
                                      (el, idx) => (
                                        <div className="user-badge p-3">
                                          <div key={idx} className="user-name">
                                            {el.factor}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Scrollbars>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col>
                            {/* Contributing Factor  */}

                            <div style={{ margin: "2vh" }}>
                              {nmDetail
                                .nm_reported_detail_contrubuting_factor_list
                                ?.length
                                ? nmDetail.nm_reported_detail_contrubuting_factor_list
                                    .slice(
                                      0,
                                      nmDetail
                                        .nm_reported_detail_contrubuting_factor_list
                                        .length / 2
                                    )
                                    .map((item, index) => (
                                      <>
                                        <Row>
                                          <Col>
                                            <div
                                              class="vl"
                                              style={{
                                                marginLeft: "2vw",
                                                borderLeft: "3px solid red",
                                                height: "2vw",
                                                borderTop: "3px solid red",
                                                width: "1vw",
                                                display: "inline-block",
                                              }}
                                            ></div>
                                            <spam
                                              style={{
                                                position: "absolute",
                                              }}
                                            >
                                              {item.factor}
                                            </spam>
                                          </Col>
                                        </Row>
                                      </>
                                    ))
                                : null}

                              <button
                                style={{
                                  background: "red",
                                  color: "white",
                                  borderRadius: "8px",
                                  border: "none",
                                  fontFamily: "monospace",
                                  padding: "2vh",
                                }}
                                disabled={!isApproveEnabled}
                              >
                                Contributing
                                <br />
                                Factor
                              </button>

                              {nmDetail
                                .nm_reported_detail_contrubuting_factor_list
                                ?.length
                                ? nmDetail.nm_reported_detail_contrubuting_factor_list
                                    .slice(
                                      nmDetail
                                        .nm_reported_detail_contrubuting_factor_list
                                        .length / 2,
                                      nmDetail
                                        .nm_reported_detail_contrubuting_factor_list
                                        .length
                                    )
                                    .map((item, index) => (
                                      <>
                                        <Row>
                                          <Col>
                                            <div
                                              class="vl"
                                              style={{
                                                marginLeft: "2vw",
                                                borderLeft: "3px solid red",
                                                height: "2vw",
                                                borderBottom: "3px solid red",
                                                width: "1vw",
                                                display: "inline-block",
                                              }}
                                            ></div>
                                            <spam
                                            // style={{fontSize:' 2vw !important'}}
                                            >
                                              {item.factor}
                                            </spam>
                                          </Col>
                                        </Row>
                                      </>
                                    ))
                                : null}
                            </div>
                          </Col>

                          <Col>
                            {/* Immediate Factor  */}

                            {nmDetail.nm_reported_detail_immediate_factor_list
                              ?.length
                              ? nmDetail.nm_reported_detail_immediate_factor_list
                                  .slice(
                                    0,
                                    nmDetail
                                      .nm_reported_detail_immediate_factor_list
                                      .length / 2
                                  )
                                  .map((item, index) => (
                                    <>
                                      <Row>
                                        <Col>
                                          <div
                                            class="vl"
                                            style={{
                                              marginLeft: "2vw",
                                              borderLeft: "3px solid #ff4d6c",
                                              height: "2vw",
                                              borderTop: "3px solid red",
                                              width: "1vw",
                                              display: "inline-block",
                                            }}
                                          ></div>
                                          <spam
                                            style={{
                                              position: "absolute",
                                            }}
                                          >
                                            {item.factor}
                                          </spam>
                                        </Col>
                                      </Row>
                                    </>
                                  ))
                              : null}

                            <div style={{ margin: "2vh" }}>
                              <button
                                style={{
                                  background: "#ff4d6c",
                                  color: "white",
                                  borderRadius: "8px",
                                  border: "none",
                                  fontFamily: "monospace",
                                  padding: "2vh",
                                }}
                              >
                                Immediate
                                <br />
                                Factor
                              </button>
                            </div>

                            {nmDetail.nm_reported_detail_immediate_factor_list
                              ?.length
                              ? nmDetail.nm_reported_detail_immediate_factor_list
                                  .slice(
                                    nmDetail
                                      .nm_reported_detail_immediate_factor_list
                                      .length / 2,
                                    nmDetail
                                      .nm_reported_detail_immediate_factor_list
                                      .length
                                  )
                                  .map((item, index) => (
                                    <>
                                      <Row>
                                        <Col>
                                          <div
                                            class="vl"
                                            style={{
                                              marginLeft: "2vw",
                                              borderLeft: "3px solid #ff4d6c",
                                              height: "2vw",
                                              borderBottom: "3px solid red",
                                              width: "1vw",
                                              display: "inline-block",
                                            }}
                                          ></div>
                                          <spam>{item.factor}</spam>
                                        </Col>
                                      </Row>
                                    </>
                                  ))
                              : null}
                          </Col>
                          <Col>
                            {/* Root Cause Analysis  */}

                            <div style={{ margin: "2vh" }}></div>
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-5">
                        <Col
                          md={12}
                          className="justify-content-center d-flex fishbon-chart-wrapper "
                        >
                          <div
                            className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                            style={{ width: "100%" }}
                          >
                            <div className="timeline-header p-0">
                              {this.state.reportStatus == "progress" &&
                              isApproveEnabled ? (
                                <button
                                  style={{
                                    backgroundColor: "darkgray",
                                    color: "white",
                                  }}
                                  className="badge border font-weight-500 px-3 py-3 rounded-pill"
                                  onClick={() => {
                                    if (this.state.reportStatus == "progress") {
                                      this.toggleStep2Fun("root cause");
                                    }
                                  }}
                                  disabled={!isApproveEnabled}
                                >
                                  Add Root Cause Analysis{" "}
                                </button>
                              ) : (
                                <button
                                  style={{
                                    background: "red",
                                    color: "white",
                                    borderRadius: "8px",
                                    border: "none",
                                    fontFamily: "monospace",
                                    padding: "2vh",
                                  }}
                                  disabled={!isApproveEnabled}
                                >
                                  Root Cause
                                  <br />
                                  Analysis
                                </button>
                              )}
                            </div>
                            <div className="mx-3 my-3 px-3 pt-5">
                              <div className="d-flex-center">
                                {/* Root Cause<br/>Analysis */}
                                <FishboneChart data={this.state.FishData} />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-between align-items-center my-4">
                        <h3 className="font-weight-500 common-red-3 font-18 heading-red-border mb-4">
                          {" "}
                          Step 3 : CAPA Recommendation
                        </h3>
                      </div>
                      <Row>
                        <Col md={12}>
                          {this.state.reportStatus == "progress" ? (
                            <div>
                              <div className="d-flex justify-content-around my-2">
                                <HorizontalNavTab
                                  tabs={tabs}
                                  activeItem={this.state.currentTab}
                                  handleChange={(key) =>
                                    this.setState({ currentTab: key })
                                  }
                                  extraValue={{
                                    prop: `(${
                                      nmDetail?.proposedCapaCount ?? 0
                                    })`,
                                    final: `(${nmDetail?.finalCapaCount ?? 0})`,
                                  }}
                                />
                              </div>
                              <div className="d-flex space-between align-items-center mt-5">
                                <div></div>
                                {/* <div className="search-bar-wrapper space-between  w-75 mr-3">
                                  <div className="mr-3 w-100">
                                    <input
                                      type="text"
                                      placeholder="Search name"
                                      name="search"
                                      className="w-100 py-1 px-2"
                                      // value={searchList}
                                      // onChange={(e) => _handleInputChange(e)}
                                    />
                                  </div>
                                  <div className="mr-1">
                                    <Search />
                                  </div>
                                </div> */}

                                <div className="d-flex space-between align-items-center ">
                                  <img
                                    src={require("assets/sls-icons/V2. New Development/Filter.svg")}
                                    style={{ height: "1.8vw" }}
                                    className="cursor-pointer mr-1"
                                    onClick={() => {
                                      this.setState({
                                        isDataFilterEnable: true,
                                      });
                                    }}
                                  />
                                  {this.state.currentTab == "prop" &&
                                  (isApproveEnabled ||
                                    isTeamMemeberLoggedIn) ? (
                                    <img
                                      src={require("assets/sls-icons/V2. New Development/ADD2.svg")}
                                      className="cursor-pointer svg-icons"
                                      onClick={() => {
                                        this.toggleStep3Tab();
                                      }}
                                    />
                                  ) : nmDetail
                                      .nm_reported_detail_final_recommendation_list
                                      ?.length && isApproveEnabled ? (
                                    <button
                                      className="common-oranger-btn w-max px-3 py-2 font-weight-500"
                                      onClick={() => {
                                        this.setState({
                                          isFreezeEnabled: true,
                                        });
                                      }}
                                    >
                                      Freeze All
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                              <div className="my-4">
                                <Scrollbars style={{ height: "30vw" }}>
                                  {this.state.currentTab == "prop" ? (
                                    <div>
                                      {nmDetail.nm_reported_detail_proposed_recommendation_list?.map(
                                        (item, idx, arr) => (
                                          <div
                                            className="nm-capa-recommendation-list-wrapper mr-5 mb-3"
                                            key={idx}
                                            style={{
                                              borderLeft: ` 0.2vw solid ${
                                                item.severity_color ??
                                                item?.priority_color
                                              } `,
                                            }}
                                          >
                                            <div className="pl-4">
                                              <div className="d-flex space-between align-items-center ">
                                                <div>
                                                  <span
                                                    style={{
                                                      color:
                                                        item.severity_color ??
                                                        item?.priority_color,
                                                    }}
                                                    className="font-18 font-weight-500"
                                                  >
                                                    {" "}
                                                    {item.priority ??
                                                      item?.severity}
                                                    &nbsp;{" "}
                                                  </span>
                                                  <span className="font-18 font-weight-500 common-black-1">
                                                    | &nbsp;{" "}
                                                    {item.category_name} |{" "}
                                                    {item.recommendation.map(
                                                      (data) => data.capaType
                                                    )}
                                                  </span>
                                                </div>
                                                {/* <div>
                                          {isApproveEnabled ? <button style={{ border: "none", backgroundColor: "white", marginRight: "-0.4vw" }} onClick={() => (this.toggleApproveTabCAPA(item.recommendation_id))}>
                                            <img src={require("assets/sls-icons/V2. New Development/Group 2321.svg")} className="cursor-pointer" style={{ height: "1.8vw" }} alt="Approve" />

                                          </button>
                                            : null}

                                          {userData.user_id === item.recommended_by_id ? <> <img src={require("assets/sls-icons/V2. New Development/Edit.svg")} className="cursor-pointer svg-icons mr-1 text-info" onClick={() => (this.manageEdit(item.recommendation_id, arr))} />
                                            <img src={require("assets/sls-icons/V2. New Development/delete.svg")} className="cursor-pointer svg-icons" onClick={() => (this.toggleDeletePopupCAPA(item.recommendation_id))} /> </> : null}
                                        </div> */}

                                                <div>
                                                  {isApproveEnabled ? (
                                                    <button
                                                      className="btn btn-danger  rounded-pill font-weight-500"
                                                      onClick={() =>
                                                        this.toggleApproveTabCAPA(
                                                          item.recommendation_id
                                                        )
                                                      }
                                                    >
                                                      Approve
                                                    </button>
                                                  ) : null}

                                                  {userData?.user_id ===
                                                  item.recommended_by_id ? (
                                                    <>
                                                      {" "}
                                                      <Edit
                                                        className="cursor-pointer mx-3 text-info"
                                                        onClick={() =>
                                                          this.manageEdit(
                                                            item.recommendation_id,
                                                            arr
                                                          )
                                                        }
                                                      />
                                                      <Trash
                                                        className="cursor-pointer common-red-3"
                                                        onClick={() =>
                                                          this.toggleDeletePopupCAPA(
                                                            item.recommendation_id
                                                          )
                                                        }
                                                      />{" "}
                                                    </>
                                                  ) : null}
                                                </div>
                                              </div>
                                              <div className="common-seprator-grey-3 my-3"></div>
                                              <div className="d-flex space-between  ">
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Activity
                                                  </div>
                                                  <div className="output-text">
                                                    {item.activity_name}
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Proposed By
                                                  </div>
                                                  <div className="output-text">
                                                    <div className="d-flex mb-3 align-items-center">
                                                      <img
                                                        src={
                                                          item.recommended_by_pic
                                                        }
                                                        className="user-icon"
                                                      />{" "}
                                                      <span className="w-max">
                                                        {item.recommended_by}
                                                      </span>{" "}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Due Date
                                                  </div>
                                                  <div className="output-text">
                                                    {item.target_date}
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Assigned To
                                                  </div>
                                                  <div className="output-text">
                                                    <Scrollbars
                                                      style={{
                                                        height: "4vw",
                                                        width: "8vw",
                                                      }}
                                                    >
                                                      {" "}
                                                      {[
                                                        ...(item?.responsible_person_list ??
                                                          item?.responsible_person),
                                                      ]?.map((data, idx) => (
                                                        <div
                                                          key={idx}
                                                          className="d-flex mb-3 align-items-center"
                                                        >
                                                          <img
                                                            src={
                                                              data.person_pic
                                                            }
                                                            className="user-icon"
                                                          />{" "}
                                                          <span className="w-max">
                                                            {data.person_name}
                                                          </span>{" "}
                                                        </div>
                                                      ))}{" "}
                                                    </Scrollbars>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="d-flex space-between align-items-center mt-3">
                                                <div className="feild-group d-flex">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max mr-5">
                                                    Corrective Action
                                                  </div>
                                                  <div className="output-text">
                                                    {item.recommendation.map(
                                                      (data) =>
                                                        data.recommendation
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      {nmDetail.nm_reported_detail_final_recommendation_list?.map(
                                        (item, idx) => (
                                          <div
                                            className="nm-capa-recommendation-list-wrapper mr-5 mb-3"
                                            key={idx}
                                            style={{
                                              borderLeft: ` 0.2vw solid ${
                                                item.severity_color ??
                                                item?.priority_color
                                              } `,
                                            }}
                                          >
                                            <div className="pl-4">
                                              <div className="d-flex space-between align-items-center ">
                                                <div>
                                                  <span
                                                    style={{
                                                      color:
                                                        item.severity_color,
                                                    }}
                                                    className="font-18 font-weight-500"
                                                  >
                                                    {" "}
                                                    {item.priority}&nbsp;{" "}
                                                  </span>
                                                  <span className="font-18 font-weight-500 common-black-1">
                                                    | &nbsp;{" "}
                                                    {item.category_name} |{" "}
                                                    {item.recommendation.map(
                                                      (data) => data.capaType
                                                    )}
                                                  </span>
                                                </div>
                                                <div>
                                                  {isApproveEnabled ? (
                                                    <Trash
                                                      className="cursor-pointer common-red-3"
                                                      onClick={() =>
                                                        this.setState({
                                                          fcrdelID:
                                                            item?.recommendation_id,
                                                        })
                                                      }
                                                    />
                                                  ) : null}
                                                </div>
                                              </div>
                                              <div className="common-seprator-grey-3 my-3"></div>
                                              <div className="d-flex space-between  ">
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Activity
                                                  </div>
                                                  <div className="output-text">
                                                    {item.activity_name}
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Proposed By
                                                  </div>
                                                  <div className="output-text">
                                                    <div className="d-flex mb-3 align-items-center">
                                                      <img
                                                        src={
                                                          item.recommended_by_pic
                                                        }
                                                        className="user-icon"
                                                      />{" "}
                                                      <span className="w-max">
                                                        {item.recommended_by}
                                                      </span>{" "}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Due Date
                                                  </div>
                                                  <div className="output-text">
                                                    {item.target_date}
                                                  </div>
                                                </div>
                                                <div className="feild-group">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                    Assigned To
                                                  </div>
                                                  <div className="output-text">
                                                    <Scrollbars
                                                      style={{
                                                        height: "4vw",
                                                        width: "8vw",
                                                      }}
                                                    >
                                                      {" "}
                                                      {[
                                                        ...(item?.responsible_person_list ??
                                                          item?.responsible_person),
                                                      ]?.map((data, idx) => (
                                                        <div
                                                          key={idx}
                                                          className="d-flex mb-3 align-items-center"
                                                        >
                                                          <img
                                                            src={
                                                              data.person_pic
                                                            }
                                                            className="user-icon"
                                                          />{" "}
                                                          <span className="w-max">
                                                            {data.person_name}
                                                          </span>{" "}
                                                        </div>
                                                      ))}{" "}
                                                    </Scrollbars>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="d-flex space-between align-items-center mt-3">
                                                <div className="feild-group d-flex">
                                                  <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max mr-5">
                                                    Preventive Action
                                                  </div>
                                                  <div className="output-text">
                                                    {item.recommendation.map(
                                                      (data) =>
                                                        data.recommendation
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                </Scrollbars>
                              </div>
                            </div>
                          ) : null}
                          {this.state.reportStatus == "completed" ? (
                            <div>
                              <Scrollbars style={{ height: "30vw" }}>
                                <div>
                                  {nmDetail.nm_reported_detail_freezeall_recommendation_list?.map(
                                    (item, idx) => (
                                      <div
                                        className="nm-capa-recommendation-list-wrapper mr-5 mb-3"
                                        key={idx}
                                      >
                                        <div
                                          className="pl-4"
                                          style={{
                                            borderLeft: `3px solid ${item.priority_color}`,
                                          }}
                                        >
                                          <div className="d-flex space-between align-items-center ">
                                            <div>
                                              <span
                                                style={{
                                                  color: item.priority_color,
                                                }}
                                                className="font-18 font-weight-500"
                                              >
                                                {" "}
                                                {item.priority}&nbsp;{" "}
                                              </span>
                                              <span className="font-18 font-weight-500 common-black-1">
                                                &nbsp; | &nbsp;
                                                {item?.category_name || ""}{" "}
                                                &nbsp; | &nbsp;
                                                {` ${item.recommendation?.[0].capaType}` ||
                                                  ""}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="common-seprator-grey-3 my-3"></div>
                                          <div className="d-flex space-between  ">
                                            <div className="feild-group">
                                              <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Activity
                                              </div>
                                              <div className="output-text">
                                                {item.activity_name}
                                              </div>
                                            </div>
                                            <div className="feild-group">
                                              <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Proposed By
                                              </div>
                                              <div className="output-text">
                                                <div className="d-flex mb-3 align-items-center">
                                                  <img
                                                    src={
                                                      item.recommended_by_pic
                                                    }
                                                    className="user-icon"
                                                  />{" "}
                                                  <span className="w-max">
                                                    {item.recommended_by}
                                                  </span>{" "}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="feild-group">
                                              <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Due Date
                                              </div>
                                              <div className="output-text">
                                                {item.target_date}
                                              </div>
                                            </div>
                                            <div className="feild-group">
                                              <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Closed On
                                              </div>
                                              <div className="output-text">
                                                {[
                                                  ...(item?.recommendation ??
                                                    item?.responsible_person),
                                                ]?.map((data, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="d-flex mb-3 align-items-center"
                                                  >
                                                    <span className="w-max">
                                                      {data.closing_date ??
                                                        "NA"}
                                                    </span>{" "}
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                            <div className="feild-group">
                                              <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                Assigned To
                                              </div>
                                              <div className="output-text">
                                                <Scrollbars
                                                  style={{
                                                    height: "4vw",
                                                    width: "8vw",
                                                  }}
                                                >
                                                  {" "}
                                                  {[
                                                    ...(item?.responsible_person_list ??
                                                      item?.responsible_person),
                                                  ]?.map((data, idx) => (
                                                    <div
                                                      key={idx}
                                                      className="d-flex mb-3 align-items-center"
                                                    >
                                                      <img
                                                        src={data.person_pic}
                                                        className="user-icon"
                                                      />{" "}
                                                      <span className="w-max">
                                                        {data.person_name}
                                                      </span>{" "}
                                                    </div>
                                                  ))}{" "}
                                                </Scrollbars>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="my-3 d-flex">
                                            <div className="feild-group">
                                              <div className="label mb-3 common-black-1 font-weight-500 font-18 w-max">
                                                preventive Action
                                              </div>
                                              <div className="output-text">
                                                {` ${item.recommendation?.[0]?.recommendation}` ||
                                                  ""}
                                              </div>
                                            </div>
                                            <div className="feild-group  h-fit mx-5 w-100">
                                              <div className="label mb-3 common-black-1 d-flex font-weight-500 font-18 w-max">
                                                Closing Reason{" "}
                                              </div>
                                              <div className="w-75">
                                                <CarouselComponent
                                                  items={
                                                    item.recommendation?.[0]
                                                      ?.recommendation_evidence
                                                      .length > 0
                                                      ? [
                                                          {
                                                            url: item
                                                              .recommendation?.[0]
                                                              .recommendation_evidence[0]
                                                              ?.evidence,
                                                            contentType:
                                                              "image",
                                                          },
                                                        ]
                                                      : []
                                                  }
                                                  showArrows={false}
                                                />
                                              </div>
                                            </div>

                                            <div className="d-flex  w-100 mb-4  mt-3">
                                              <div className="output-text ml-5">
                                                {item.recommendation?.[0]
                                                  .observation || "N/A"}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </Scrollbars>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    paddingTop: 0,
                    boxShadow: "none",
                  }}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  icon={<img src={DiscussIcon} className="px-2 w-100  h-100" />}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div
                      style={{
                        background: "rgba(217, 217, 217, 0.5)",
                        borderRadius: "28px",
                        width: "max-content",
                      }}
                      className="px-3 py-2 common-black-1 font-weight-500  text-center"
                      id="discuss"
                      ref={this.myRef}
                    >
                      Discuss
                    </div>
                    <div className="mt-5">
                      <div className="horizontal-timeline-wrapper shadow-none border mt-5 px-5 pt-5">
                        <div className="rd-box">
                          <div>
                            {this.state.comment == 0 ? (
                              ""
                            ) : (
                              <div className="d-flex">
                                <ChevronLeft
                                  className="cursor-pointer common-orange"
                                  onClick={() => this.backToMainComment()}
                                />{" "}
                                Comments details
                              </div>
                            )}
                          </div>

                          <Scrollbars
                            style={{ height: `19vw` }}
                            ref={(c) => {
                              this.myChatRef = c;
                            }}
                          >
                            <div className="mr-5 d-flex flex-column-reverse">
                              {!this.state.dataLoading &&
                              this.state.comment == 0 &&
                              this.state.commentlist != "" ? (
                                this.state?.commentlist.map((el, idx) => {
                                  const position = el.delete_flag
                                    ? "right"
                                    : "left";
                                  const callbacks = {
                                    delete: this.CommentRemove,
                                    like:
                                      el.like_flag != 1
                                        ? this.LikeCommentAdd
                                        : this.LikeCommentRemove,
                                    reply: this.togglecommentId,
                                  };

                                  if (!el.delete_flag) {
                                    delete callbacks.delete;
                                  }

                                  return (
                                    <div key={idx} id={el.comment_id}>
                                      <ChatMessageBox
                                        message={el.comment}
                                        isLiked={el.like_flag}
                                        likeCount={el.like_number}
                                        hasReplies={el.reply_number}
                                        commentTime={el.comment_time}
                                        repliesCount={el.reply_number}
                                        user_name={el.user_name}
                                        user_pic={el.user_pic}
                                        position={position}
                                        callbacks={callbacks}
                                        callbacksParams={{
                                          delete: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          like: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          reply: {
                                            id: el.comment_id,
                                          },
                                        }}
                                      />
                                      {el.subComment.map((sub, i) => {
                                        const callbacks = {
                                          delete: this.CommentRemove,
                                          like:
                                            sub.like_flag != 1
                                              ? this.LikeCommentAdd
                                              : this.LikeCommentRemove,
                                        };
                                        if (!sub.delete_flag) {
                                          delete callbacks.delete;
                                        }
                                        return (
                                          <div
                                            className={` ${
                                              position === "right"
                                                ? "mr-5 pr-5"
                                                : "ml-5 pl-5"
                                            }`}
                                            key={i}
                                          >
                                            <ChatMessageBox
                                              message={sub.comment}
                                              isLiked={sub.like_flag}
                                              likeCount={sub.like_number}
                                              hasReplies={sub.reply_number}
                                              commentTime={sub.comment_time}
                                              repliesCount={sub.reply_number}
                                              user_name={sub.user_name}
                                              position={position}
                                              user_pic={sub.user_pic}
                                              callbacks={callbacks}
                                              callbacksParams={{
                                                delete: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                                like: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="box d-flex inner-box justify-content-center pt-5">
                                  {this.state.comment == 0 &&
                                    this.state.commentlist == "" && (
                                      <div className="box inner-box">
                                        <p>No Comments Available</p>{" "}
                                      </div>
                                    )}
                                </div>
                              )}
                              {!this.state.dataLoading &&
                              this.state.comment == 1 &&
                              this.state.subcommentlist != "" ? (
                                this.state.subcommentlist.map((el, idx) => {
                                  const position = el.delete_flag
                                    ? "right"
                                    : "left";
                                  const callbacks = {
                                    delete: this.CommentRemove,
                                    like:
                                      el.like_flag != 1
                                        ? this.LikeCommentAdd
                                        : this.LikeCommentRemove,
                                    reply: this.togglecommentId,
                                  };

                                  if (!el.delete_flag) {
                                    delete callbacks.delete;
                                  }
                                  return (
                                    <div key={idx}>
                                      <ChatMessageBox
                                        message={el.comment}
                                        isLiked={el.like_flag}
                                        likeCount={el.like_number}
                                        hasReplies={el.reply_number}
                                        commentTime={el.comment_time}
                                        repliesCount={el.reply_number}
                                        user_name={el.user_name}
                                        user_pic={el.user_pic}
                                        position={position}
                                        callbacks={callbacks}
                                        callbacksParams={{
                                          delete: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          like: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          reply: {
                                            id: el.comment_id,
                                          },
                                        }}
                                      />
                                      {el.subComment.map((sub, i) => {
                                        const callbacks = {
                                          delete: this.CommentRemove,
                                          like:
                                            sub.like_flag != 1
                                              ? this.LikeCommentAdd
                                              : this.LikeCommentRemove,
                                        };
                                        if (!sub.delete_flag) {
                                          delete callbacks.delete;
                                        }
                                        return (
                                          <div
                                            className={` ${
                                              position === "right"
                                                ? "mr-5 pr-5"
                                                : "ml-5 pl-5"
                                            }`}
                                            key={i}
                                          >
                                            <ChatMessageBox
                                              message={sub.comment}
                                              isLiked={sub.like_flag}
                                              likeCount={sub.like_number}
                                              hasReplies={sub.reply_number}
                                              commentTime={sub.comment_time}
                                              repliesCount={sub.reply_number}
                                              user_name={sub.user_name}
                                              position={position}
                                              user_pic={sub.user_pic}
                                              callbacks={callbacks}
                                              callbacksParams={{
                                                delete: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                                like: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="box inner-box">
                                  {this.state.comment == 1 &&
                                    this.state.subcommentlist == "" && (
                                      <p>No Sub Comments Available</p>
                                    )}
                                </div>
                              )}
                            </div>
                          </Scrollbars>
                          <div className="common-seprator-grey-3 my-4"></div>
                          {this.state.comment == 0 ? (
                            <div className="align-items-center comments-input d-flex input-block">
                              <div className="w-100 px-4 border-right">
                                <input
                                  name="safety-thought"
                                  type="text"
                                  placeholder="Write a comment"
                                  className="form-control border-0"
                                  multiple
                                  onChange={(e) =>
                                    this.setState({
                                      commentAdd: e.target.value.trimStart(),
                                    })
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      console.log("checking", inputMessageFlag);
                                      if (inputMessageFlag === 0) {
                                        inputMessageFlag = 1;
                                        console.log(
                                          "checking",
                                          inputMessageFlag
                                        );

                                        setTimeout(() => {
                                          this.CommentAdd();
                                          inputMessageFlag = 0;
                                        }, 500);
                                      }
                                    }
                                  }}
                                  value={this.state.commentAdd}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="px-4 send-btn">
                                <Send
                                  aria-disabled="true"
                                  className="send-icon cursor-pointer"
                                  onClick={() =>
                                    this.debounceFunc(() => {
                                      this.CommentAdd();
                                    })
                                  }
                                  disabled={this.state.isCommentApiLoading}
                                  style={{
                                    color: "59B0F6",
                                    height: "1.5vw",
                                    width: "1.5vw",
                                  }}
                                />
                              </div>
                              {!!this.state.commentError && (
                                <p className="inputErrorMsg">
                                  {requiredMessage}
                                </p>
                              )}
                            </div>
                          ) : (
                            <div className="align-items-center comments-input d-flex input-block">
                              <div className="w-100 px-4 border-right">
                                <input
                                  name="safety-thought"
                                  type="text"
                                  placeholder="Write a Reply"
                                  className="form-control border-0"
                                  multiple
                                  onChange={(e) =>
                                    this.setState({
                                      replycommentAdd:
                                        e.target.value.trimStart(),
                                    })
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      console.log("checking", inputMessageFlag);
                                      if (inputMessageFlag === 0) {
                                        inputMessageFlag = 1;
                                        console.log(
                                          "checking",
                                          inputMessageFlag
                                        );

                                        setTimeout(() => {
                                          this.ReplyCommentAdd();
                                          inputMessageFlag = 0;
                                        }, 500);
                                      }
                                    }
                                  }}
                                  value={this.state.replycommentAdd}
                                />
                              </div>
                              <div className="px-4 send-btn">
                                <Send
                                  aria-disabled="true"
                                  className="send-icon  cursor-pointer"
                                  disabled={this.state.isCommentApiLoading}
                                  onClick={(e) =>
                                    this.debounceFunc(() => {
                                      if (
                                        this.state?.replycommentAdd != "" &&
                                        e.detail == 1
                                      ) {
                                        this.ReplyCommentAdd();
                                      }
                                    })
                                  }
                                  // onClick={(e) => {
                                  //   if (
                                  //     this.state?.replycommentAdd != "" &&
                                  //     e.detail == 1
                                  //   ) {
                                  //     this.ReplyCommentAdd();
                                  //   }
                                  // }}
                                  style={{
                                    color: "59B0F6",
                                    height: "1.5vw",
                                    width: "1.5vw",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>
            <div className="rd-right-content">
              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <div className="text-center mb-3">
                  <span className="common-black-1 font-weight-500 font-20">
                    Reported By
                  </span>
                  <div className="d-flex flex-column align-items-center">
                    <OverlayTrigger
                      trigger="hover"
                      placement="left"
                      rootClose={true}
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          style={{ width: "15vw", borderRadius: "10px" }}
                        >
                          <Container>
                            <div className="my-3">
                              <span className="common-black-1 font-weight-500 font-16">
                                Reported Date :
                              </span>
                              <span className="common-black-1 ml-3 font-16">
                                {nmDetail.reported_date}
                              </span>
                            </div>
                            <div className="my-3">
                              <span className="common-black-1 font-weight-500 font-16">
                                Reported Time :
                              </span>
                              <span className="common-black-1 ml-3 font-16">
                                {nmDetail.reported_time}
                              </span>
                            </div>
                            <div className="my-3">
                              <span className="common-black-1 font-weight-500 font-16">
                                Reported Month :
                              </span>
                              <span className="common-black-1 ml-3 font-16">
                                {nmDetail.reportedmonth_name}
                              </span>
                            </div>
                          </Container>
                        </Popover>
                      }
                    >
                      <img
                        src={nmDetail.reported_by_pic}
                        className="user-icon my-3 cursor-pointer"
                      />
                    </OverlayTrigger>
                  </div>
                  <span className="common-black-1  font-18">
                    {nmDetail.reported_by}
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-1">
                  <div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        ID :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.state.assignedDetails?.emp_id ?? "NA"}
                      </span>
                    </div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        Department :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.getDepartment(this.state.assignedDetails?.field)}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        Type :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.state.assignedDetails?.type ?? "NA"}
                      </span>
                    </div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        Designation :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.state.assignedDetails?.designation ?? "NA"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="rd-box shadow-none border liked-btn"
                style={{ borderRadius: "1vw" }}
              >
                <div className="side-invite-user">
                  <div className="image-box">
                    <span className="common-black-1 font-weight-500 font-20">{`Badges (${this.state.likeNumber})`}</span>
                    {this.state.userLike != "" ? (
                      this.state.userLike?.map(
                        (item, idx) =>
                          idx < 5 && (
                            <React.Fragment>
                              <img
                                src={item.user_profile}
                                alt=""
                                data-tip
                                data-for={`user-name-${idx}`}
                              />

                              <ReactTooltip
                                effect="solid"
                                place="right"
                                id={`user-name-${idx}`}
                              >
                                <>
                                  <span>{item.user_name}</span>
                                </>
                              </ReactTooltip>
                            </React.Fragment>
                          )
                        // </div>
                      )
                    ) : (
                      <p className="data-not-present">Badges not available </p>
                    )}
                    {this.state.userLike.length > 5 ? (
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          this.debounceFunc(() => {
                            this.showUserLikedPopup();
                          })
                        }
                      >{`+${this.state.userLike.length - 5} More`}</span>
                    ) : null}
                  </div>
                </div>
              </div>

              {this.state.reportStatus == "completed" ? (
                <div
                  className="rd-box shadow-none border "
                  style={{ borderRadius: "1vw" }}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Toggle
                      eventKey="0"
                      as={Button}
                      variant="link"
                      onClick={() =>
                        this.setState({
                          accordionOpen: !this.state.accordionOpen,
                        })
                      }
                    >
                      <div className="rd-box-head1">
                        <h3>Investigation Team</h3>
                        {this.state.accordionOpen ? (
                          <ChevronDown className="indicator-icon" />
                        ) : (
                          <ChevronRight className="indicator-icon" />
                        )}
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <div className="rd-left-bottom">
                        <Row>
                          <Col>
                            {nmDetail.nm_reported_detail_people_involved_list
                              ?.length
                              ? nmDetail.nm_reported_detail_people_involved_list.map(
                                  (item, index) => (
                                    <div
                                      className="chip m-2 position-relative"
                                      key={`investigation-team-${index}`}
                                      style={{
                                        position: "absolute",
                                        top: "0.3vw",
                                      }}
                                    >
                                      <img
                                        src={item.user_pic}
                                        alt={item.user_name}
                                        className="user-icon border-0"
                                      />
                                      {item.status == 1 && (
                                        <div className="people-lead-box">
                                          <img
                                            src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                                            alt=""
                                            style={{
                                              position: "absolute",
                                              height: "1vw",
                                              width: "1vw",
                                              left: "1.8vw",
                                              top: "0.25vw",
                                            }}
                                          />
                                        </div>
                                      )}
                                      {item.user_name}
                                    </div>
                                  )
                                )
                              : null}
                          </Col>
                        </Row>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
              ) : null}

              {/* Targeted Date time picker */}

              {/* {isTargetDateEnabled !== true && this.state.reportStatus != "completed" ?
                <div className="rd-box shadow-none border" style={{ borderRadius: "1vw" }}>
                  <div className="rd-box-head1" >
                    <h3>Select Targeted Date</h3>
                  </div>

                  <Col style={{ margin: '1rem', width: '80%', }}>

                    <DatePicker
                      selected={this.state.selectedTargetDate}
                      inputStyle={{ textAlign: 'center' }}
                      onChange={(jsDate) =>
                        this._handleChange(jsDate, "target_date")
                      }
                    />

                  </Col>

                  <Col>

                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() => { this.confirmSubmitTargetDate() }}
                    >
                      CONFIRM
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      style={{ margin: '1rem' }}
                      onClick={() => { this.cancleSubmitTargetTime() }}
                    >
                      CANCEL
                    </button>

                  </Col>

                </div>
                : null
              } */}

              {this.state.reportStatus != "completed" ? (
                <div
                  className="rd-box shadow-none border"
                  style={{ borderRadius: "1vw" }}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Toggle
                      eventKey="0"
                      as={Button}
                      variant="link"
                      onClick={() =>
                        this.setState({
                          accordionOpen: !this.state.accordionOpen,
                        })
                      }
                    >
                      <div className="rd-box-head1">
                        <h3>Investigation Team</h3>
                        {this.state.accordionOpen ? (
                          <ChevronDown className="indicator-icon" />
                        ) : (
                          <ChevronRight className="indicator-icon" />
                        )}
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <div className="rd-left-bottom">
                        <Row>
                          <Col>
                            <h6 className="common-black-1 font-18 font-weight-500 my-3">
                              Team Lead
                            </h6>
                            <p className=" my-2 font-14 font-weight-500">
                              Select a team lead from team members
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {nmDetail.nm_reported_detail_people_involved_list
                              ?.length
                              ? nmDetail.nm_reported_detail_people_involved_list.map(
                                  (item, index) => (
                                    <>
                                      {item.status == 1 && (
                                        <div
                                          className="chip m-2 position-relative"
                                          key={`investigation-team-${index}`}
                                          style={{ width: "100%" }}
                                        >
                                          <img
                                            src={item.user_pic}
                                            alt={item.user_name}
                                            width="96"
                                            style={{
                                              position: "absolute",
                                              left: "-15px",
                                            }}
                                            height="96"
                                          />
                                          {item.status == 1 && (
                                            <img
                                              src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                                              alt=""
                                              className="user-icon border-0 position-absolute"
                                              style={{
                                                height: "1.3vw",
                                                width: "1.3vw",
                                                background: "white",
                                                bottom: "-6px",
                                                left: "0.8vw",
                                              }}
                                            />
                                          )}
                                          {item.user_name ?? "NA"}

                                          {/* <spam style={{ position: 'absolute', right: '1.5vw'}} >
                                      <i class="fa fa-crown"  onClick={()=>{this._AddTeamLeader(item.user_id)}}   ></i>
                                      <i class="fa fa-trash" onClick={()=>{this.removeInviteUser(item.user_id)}} style={{padding:'8px'}}  aria-hidden="true"></i>
                                    </spam> */}
                                        </div>
                                      )}
                                    </>
                                  )
                                )
                              : null}
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <h6 className="common-black-1 font-18 font-weight-500 my-3">
                              Team Members
                            </h6>
                            {nmDetail.nm_reported_detail_people_involved_list
                              ?.length ? (
                              nmDetail.nm_reported_detail_people_involved_list.map(
                                (item, index) => (
                                  <>
                                    {item.status == 0 && (
                                      <div
                                        className="chip m-2 d-flex space-between  align-items-center"
                                        key={`investigation-team-${index}`}
                                        style={{ width: "100%" }}
                                      >
                                        <div className="ml-2">
                                          <img
                                            src={item.user_pic}
                                            alt={item.user_name}
                                            width="96"
                                            height="96"
                                            style={{
                                              position: "absolute",
                                              left: "7px",
                                            }}
                                          />
                                          {item.status == 1 && (
                                            <img
                                              src={require("assets/images/team-lead-active.png")}
                                              alt=""
                                              style={{
                                                height: "2vw",
                                                width: "2vw",
                                                marginLeft: "-1.5vw",
                                                marginTop: "1.5vw",
                                                background: "white",
                                              }}
                                            />
                                          )}
                                          {item.user_name}
                                        </div>

                                        <div className="d-flex align-items-center ">
                                          {isCrownEnabled && (
                                            <img
                                              src={CrownIcon}
                                              alt=""
                                              onClick={() =>
                                                this.debounceFunc(() => {
                                                  this._AddTeamLeader(
                                                    item.user_id
                                                  );
                                                })
                                              }
                                              // onClick={() =>
                                              //   this._AddTeamLeader(
                                              //     item.user_id
                                              //   )
                                              // }
                                              className="user-icon border-0 cursor-pointer"
                                              style={{
                                                height: "1.3vw",
                                                width: "1.3vw",
                                                background: "white",
                                                bottom: "-6px",
                                              }}
                                            />
                                          )}

                                          {isDeleteEnabled && (
                                            <img
                                              src={require("assets/sls-icons/V2. New Development/delete (1).svg")}
                                              className="cursor-pointer"
                                              style={{
                                                height: "1.3vw",
                                                width: "1.3vw",
                                              }}
                                              alt=""
                                              onClick={() =>
                                                this.debounceFunc(() => {
                                                  this.removeInviteUser(
                                                    item.user_id
                                                  );
                                                })
                                              }
                                              // onClick={() => {
                                              //   this.removeInviteUser(
                                              //     item.user_id
                                              //   );
                                              // }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )
                              )
                            ) : (
                              <div className="text-center ">
                                <img
                                  src={TeamUnavailable}
                                  className="img-fluid "
                                  alt=""
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
              ) : null}

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Toggle
                    eventKey="0"
                    as={Button}
                    variant="link"
                    onClick={() =>
                      this.setState({
                        accordionOpen: !this.state.accordionOpen,
                      })
                    }
                  >
                    <div className="rd-box-head1">
                      <h3>{nmDetail.activity} | Analysis</h3>
                      {this.state.accordionOpen ? (
                        <ChevronDown className="indicator-icon" />
                      ) : (
                        <ChevronRight className="indicator-icon" />
                      )}
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="rd-left-bottom">
                      <Row>
                        <Col>
                          <div className="report-detail-graph-label">
                            IRA Analysis
                          </div>
                          {nmDetail.activity_analysis?.fa_number == 0 &&
                          nmDetail.activity_analysis?.mtc_number == 0 &&
                          nmDetail.activity_analysis?.tra_number == 0 &&
                          nmDetail.activity_analysis?.lti_number == 0 ? (
                            <div className="graph-not-available-text">
                              Graph not available
                            </div>
                          ) : (
                            <>
                              <Row>
                                <Col sm={6}>
                                  <div style={{ width: "9vw" }}>
                                    {config.isChartJsEnabled ? (
                                      this.CountDoughnut(analysisChartData)
                                    ) : (
                                      <HighchartsReact
                                        highcharts={Highcharts}
                                        options={analysisChart}
                                      />
                                    )}
                                  </div>
                                </Col>
                                <Col
                                  sm={3}
                                  className="report-detail-graph-legend-block"
                                >
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#FF4242" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {nmDetail.activity_analysis?.fa_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    FA
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#086992" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {nmDetail.activity_analysis?.mtc_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    MTC
                                  </div>
                                </Col>
                                <Col
                                  sm={3}
                                  className="report-detail-graph-legend-block"
                                >
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#FAAC15" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {nmDetail.activity_analysis?.tra_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    TRA
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#18DA07" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {nmDetail.activity_analysis?.lti_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    LTI
                                  </div>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#C511FF" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value mr-1">
                                      {
                                        nmDetail.activity_analysis
                                          ?.occupational_illness_number
                                      }
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text   mr-5">
                                    OI
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#1589EE" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value mr-1">
                                      {
                                        nmDetail.activity_analysis
                                          ?.first_aid_number
                                      }
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text mr-2">
                                    First Aid
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{
                                        backgroundColor: "rgb(161, 255, 206)",
                                      }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {
                                        nmDetail.activity_analysis
                                          ?.notable_number
                                      }{" "}
                                      &nbsp;
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    Notable
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                          <div className="report-detail-graph-label">
                            NM Analysis
                          </div>
                          {nmDetail.activity_analysis?.nm_ua_number == 0 &&
                          nmDetail.activity_analysis?.nm_uc_number == 0 ? (
                            <div className="graph-not-available-text">
                              Graph not available
                            </div>
                          ) : (
                            <Row>
                              <Col>
                                <Row
                                  style={{
                                    borderRight:
                                      "0.03vw solid rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  <Col sm={6}>
                                    <div style={{ width: "9vw" }}>
                                      {config.isChartJsEnabled ? (
                                        this.CountDoughnut(nmuaUcChartData)
                                      ) : (
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={nmuaUcChart}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                  <Col
                                    sm={6}
                                    className="report-detail-graph-legend-block"
                                  >
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{
                                          backgroundColor:
                                            nmDetail.activity_analysis
                                              ?.nm_ua_color,
                                        }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {
                                          nmDetail.activity_analysis
                                            ?.nm_ua_number
                                        }
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Act
                                    </div>
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{
                                          backgroundColor:
                                            nmDetail.activity_analysis
                                              ?.nm_uc_color,
                                        }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {
                                          nmDetail.activity_analysis
                                            ?.nm_uc_number
                                        }
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Condition
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}

                          <div className="report-detail-graph-label">
                            UA Vs UC Analysis
                          </div>
                          {nmDetail.activity_analysis?.ua_number == 0 &&
                          nmDetail.activity_analysis?.uc_number == 0 ? (
                            <div className="graph-not-available-text">
                              Graph not available
                            </div>
                          ) : (
                            <Row>
                              <Col>
                                <Row
                                  style={{
                                    borderRight:
                                      "0.03vw solid rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  <Col sm={6}>
                                    <div style={{ width: "9vw" }}>
                                      {config.isChartJsEnabled ? (
                                        this.CountDoughnut(uaUcChartData)
                                      ) : (
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={uaUcChart}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                  <Col
                                    sm={6}
                                    className="report-detail-graph-legend-block"
                                  >
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{ backgroundColor: "#C7AC83" }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {nmDetail.activity_analysis?.ua_number}
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Act
                                    </div>
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{ backgroundColor: "#554040" }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {nmDetail.activity_analysis?.uc_number}
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Condition
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </div>

              {!(
                this.state.reportStatus == "pending" ||
                this.state.reportStatus == "progress"
              ) && (
                <>
                  <div
                    className="rd-box shadow-none border"
                    style={{ borderRadius: "1vw" }}
                  >
                    <label className="common-black-1 font-weight-500">
                      {" "}
                      Completion Days (Numbers){" "}
                    </label>
                    <Col sm={6}>
                      <div style={{ width: "9vw" }}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={investigationChart}
                        />
                      </div>
                      <label style={{ marginLeft: "2vw" }}>
                        {" "}
                        Investigation{" "}
                      </label>
                    </Col>
                    <Col
                      sm={6}
                      style={{ marginLeft: "10vw", marginTop: "-10.70vw" }}
                    >
                      <div style={{ width: "9vw" }}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={newDays}
                        />
                      </div>
                      <label style={{ marginLeft: "4vw" }}> CAPA </label>
                    </Col>
                  </div>
                  <div
                    className="rd-box shadow-none border"
                    style={{ borderRadius: "1vw" }}
                  >
                    <label className="common-black-1 font-weight-500">
                      {" "}
                      CAPA Recommendation Count{" "}
                    </label>
                    <Col sm={6}>
                      <div style={{ width: "9vw" }}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={openChart}
                        />
                      </div>
                      <label style={{ marginLeft: "3vw" }}> Open </label>
                    </Col>
                    <Col
                      sm={6}
                      style={{ marginLeft: "10vw", marginTop: "-10.70vw" }}
                    >
                      <div style={{ width: "9vw" }}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={closeChart}
                        />
                      </div>
                      <label style={{ marginLeft: "3vw" }}> Closed </label>
                    </Col>
                  </div>
                </>
              )}

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500">
                  {" "}
                  Good Act & TBT Count{" "}
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.gpcount.map((data) => data.gp_count)}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.gpcount.map((data) => data.color)}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={gpChart}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> GA </label>
                  </div>
                </Col>
                <Col
                  sm={6}
                  style={{
                    marginLeft: "10vw",
                    marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw",
                  }}
                >
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.tbtcount.map(
                          (data) => data.tbt_count
                        )}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.tbtcount.map((data) => data.color)}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={tbtChart}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> TBT </label>
                  </div>
                </Col>
              </div>

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500">
                  {" "}
                  Internal & External Training Count{" "}
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.trainingInternalCount.map(
                          (data) => data.training_internal_count
                        )}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingInternalCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={internalCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> Internal </label>
                  </div>
                </Col>
                <Col
                  sm={6}
                  style={{
                    marginLeft: "10vw",
                    marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw",
                  }}
                >
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingExternalCount.map(
                            (data) => data.training_external_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingExternalCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={externalCount}
                      />
                    )}
                  </div>
                  <label style={{ marginLeft: "3vw" }}> External </label>
                </Col>
              </div>

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500">
                  {" "}
                  Employee & Contractor Training Count{" "}
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingEmployeeCount.map(
                            (data) => data.training_employee_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingEmployeeCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={employeeCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> Employee </label>
                  </div>
                </Col>

                <Col
                  sm={6}
                  style={{
                    marginLeft: "10vw",
                    marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw",
                  }}
                >
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingContractorCount.map(
                            (data) => data.training_contractor_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingContractorCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={contractorCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> Contractor </label>
                  </div>
                </Col>
              </div>
              {/* <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500">
                  {" "}
                  CA & PA
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                  {config.isChartJsEnabled ? <CountGraph
                      count={nmDetail.CA_Count ?? 0}
                      radius={'77%'}
                      cutout={"82%"}
                      height={100}
                      fontWeight={500}
                      fontSize={'2.1vw'}
                      color={this.state.trainingEmployeeCount.map((data) => data.color)}
                    />
                      :
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={employeeCount}
                    />}
                  </div>
                  <div className="d-flex-center"><label > CA </label></div>
                  
                </Col>

                <Col
                  sm={6}
                  style={{ marginLeft: "10vw", marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw" }}
                >
                  <div style={{ width: "9vw" }}>
                  {config.isChartJsEnabled ? <CountGraph
                      count={nmDetail?.PA_Count ?? 0}
                      radius={'77%'}
                      cutout={"82%"}
                      height={100}
                      fontWeight={500}
                      fontSize={'2.1vw'}
                      color={this.state.trainingContractorCount.map((data) => data.color)}
                    />
                      :
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={contractorCount}
                    />}
                  </div>
                  <div className="d-flex-center"><label > PA </label></div>
                  
                </Col>
              </div> */}

              <Modal
                centered
                show={this.state.deletePopup}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={this.hideModal}
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/delete-popup.svg")}
                  />
                  <p className="submit-text">
                    Are you sure you want to delete this?
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={this.hideModal}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.deleteReport(this.state.nmId);
                        })
                      }
                    >
                      YES, DELETE
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                centered
                show={this.state.deletePopupCAPA}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={this.hideModal}
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/delete-popup.svg")}
                  />
                  <p className="submit-text">
                    Are you sure you want to delete this?
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={this.hideModal}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.DeleteRemmondation(this.state.r_id);
                        })
                      }
                    >
                      YES, DELETE
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                centered
                show={this.state.ApproveTab}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={this.hideModal}
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/delete-popup.svg")}
                  />
                  <p className="submit-text">
                    Are you sure you want to Approve this?
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={this.hideModal}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.ApproveRemmondation(this.state.r_id);
                        })
                      }
                    >
                      YES, APPROVE
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                centered
                show={this.state.confirmPopup}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={this.hideModal}
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/confirm-popup.svg")}
                    alt=""
                  />
                  <p className="submit-text">
                    Click Confirm to freeze target date
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={this.hideModal}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.submitTargetDate();
                        })
                      }
                      // onClick={() => this.submitTargetDate()}
                    >
                      CONFIRM
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                centered
                show={this.state.toggleAddQuestion}
                className="submit-modal"
              >
                <Modal.Header
                  className="align-items-center"
                  style={{
                    backgroundColor: "rgb(255, 99, 71)",
                    color: "white",
                  }}
                >
                  <div className="align-items-center d-flex justify-content-between">
                    <div>
                      <span className="font-20 font-weight-500 ">
                        {" "}
                        Add Questions
                      </span>
                    </div>
                  </div>
                  <div className="modal-controls">
                    <button
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.hideModal();
                          this.setState({ questionList: [] });
                        })
                      }
                    >
                      Close
                    </button>
                    <button
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.analysisQuestionAdd();
                        })
                      }
                      // onClick={(e) => {
                      //   if (e.detail == 1) {
                      //     this.analysisQuestionAdd();
                      //   }
                      // }}
                      disabled={
                        !this.state.questionList.length &&
                        this.state.isQuestionLoading
                      }
                    >
                      {this.state.isQuestionLoading ? (
                        <RefreshCw className="rotate-360" />
                      ) : (
                        "Apply"
                      )}
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <p className="submit-text">
                    Create Questions
                    <button
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.addQuestion();
                        })
                      }
                      // onClick={(e) => {
                      //   if (e.detail == 1) {
                      //     this.addQuestion();
                      //   }
                      // }}
                      style={{
                        position: "absolute",
                        right: "2vh",
                        top: "4vh",
                        backgroundColor: "#fff",
                        color: "rgb(255, 99, 71)",
                        minWidth: "5vh",
                        border: "none",
                      }}
                      className="icon-btn"
                    >
                      <Plus />
                    </button>
                  </p>
                  <div>
                    <Scrollbars style={{ height: "15vw" }}>
                      {this.state.questionList.map((question, index) => (
                        <div
                          key={index}
                          className="mr-4 my-3 slds-form-element"
                        >
                          <div className="d-flex space-between my-2">
                            <span className="font-18 font-weight-500 m-1">
                              Question {index + 1}
                            </span>
                            <Trash
                              onClick={() => {
                                this.removeQuestion(index);
                              }}
                              className="cursor-pointer"
                            />
                          </div>
                          <div
                            className={`slds-form-element ${
                              question?.isQuestionErrorEnabled
                                ? "slds-has-error"
                                : ""
                            }`}
                          >
                            <label
                              className="slds-form-element__label"
                              for="text-input-id-47"
                            >
                              <abbr className="slds-required" title="required">
                                *{" "}
                              </abbr>
                              Question
                            </label>
                            <div class="slds-form-element__control">
                              <input
                                type="text"
                                placeholder="Write your question "
                                className="slds-input "
                                onChange={(question) => {
                                  this.onChangeHandle(
                                    index,
                                    question.target.value
                                  );
                                }}
                                style={{ color: "black" }}
                                required
                              />
                            </div>
                            {question?.isQuestionErrorEnabled ? (
                              <div
                                className="slds-form-element__help"
                                id="error-message-id-49"
                              >
                                Please enter question
                              </div>
                            ) : null}
                          </div>
                          <div
                            className={`slds-form-element ${
                              question?.isDescriptionEnabled
                                ? "slds-has-error"
                                : ""
                            }`}
                          >
                            <label
                              className="slds-form-element__label"
                              for="text-input-id-47"
                            >
                              <abbr className="slds-required" title="required">
                                *{" "}
                              </abbr>
                              Description
                            </label>
                            <div class="slds-form-element__control">
                              <input
                                type="text"
                                placeholder="Describe"
                                className="slds-input "
                                onChange={(response) => {
                                  this.onChangeHandle2(
                                    index,
                                    response.target.value
                                  );
                                }}
                                style={{ color: "black" }}
                                required
                              />
                            </div>
                            {question?.isDescriptionEnabled ? (
                              <div
                                className="slds-form-element__help"
                                id="error-message-id-49"
                              >
                                Please enter description.
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ))}
                    </Scrollbars>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                centered
                show={this.state.toggleStep2}
                className="submit-modal"
              >
                <Modal.Header className="align-items-center">
                  <div className="align-items-center d-flex justify-content-between">
                    <div>
                      <span className="font-20 font-weight-500 common-black-1">
                        {" "}
                        {this.state.step2Type}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="modal-controls">
                    <button onClick={this.hideModal}>Close</button>

                    <button
                      type="button"
                      //  onClick={this.handleContrubutingSubmit}
                      disabled={this.state.isFactorLoading}
                      onClick={() =>
                        this.debounceFunc(() => {
                          this._InProgressFactorAddList(
                            this.state.step2Type === "Contributing Factor"
                              ? 1
                              : this.state.step2Type === "Immediate Factor"
                              ? 2
                              : this.state.RCAFList
                              ? 3
                              : ""
                          );
                        })
                      }
                    >
                      Submit
                    </button>
                  </div>
                </Modal.Header>

                <div>
                  {this.state.step2Type === "Contributing Factor" ? (
                    <Modal.Body>
                      <hr />
                      <div className="d-flex space-between flex-row-reverse">
                        <form className="common-check-box-layout">
                          {
                            this.state.CFList &&
                              this.state.CFList.factors_list.map(
                                (item, index) => (
                                  <div key={index}>
                                    <input
                                      type="checkbox"
                                      onChange={this.handleCheckboxChange}
                                      id={item.factor_id}
                                      checked={item.checked}
                                    />
                                    <label htmlFor={item.factor_name}>
                                      {item.factor_name}
                                    </label>
                                  </div>
                                )
                              )
                            // :<h3>No data</h3>
                          }
                        </form>
                      </div>
                    </Modal.Body>
                  ) : null}

                  {this.state.step2Type === "Immediate Factor" ? (
                    <Modal.Body>
                      <div className="d-flex space-between flex-row-reverse">
                        <form className="common-check-box-layout">
                          {this.state.IFList &&
                            this.state.IFList.factors_list.map(
                              (item, index) => (
                                <div key={index}>
                                  <input
                                    type="checkbox"
                                    onChange={this.handleCheckboxChangeIF}
                                    id={item.factor_id}
                                    checked={item.checked}
                                  />
                                  <label htmlFor={item.factor_name}>
                                    {item.factor_name}
                                  </label>
                                </div>
                              )
                            )}
                        </form>
                        
                      </div>
                    </Modal.Body>
                  ) : null}

                  {this.state.step2Type === "Root Cause Analysis" ? (
                    <Modal.Body>
                     
                      <div className="d-flex space-between flex-row-reverse">
                        <form className="common-check-box-layout">
                          {this.state.RCAFList &&
                            this.state.RCAFList.factors_list.map(
                              (item, index) => (
                                <div
                                  key={index}
                                  className="d-flex space-between"
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      onChange={this.handleCheckboxChangeRAC}
                                      id={item.factor_id}
                                      checked={item.checked}
                                    />
                                    <label htmlFor={item.factor_name}>
                                      {item.factor_name}
                                    </label>
                                  </div>
                                </div>
                              )
                            )}
                        </form>
                        
                      </div>
                    </Modal.Body>
                  ) : null}
                </div>
              </Modal>

              <Modal centered show={this.state.toggleStep3}>
                <Modal.Header className="align-items-center">
                  <div className="align-items-center d-flex justify-content-between">
                    <div>
                      <span className="font-20 font-weight-500 common-white-2">
                        {" "}
                        {this.state.editR_id ? "EDIT" : "Add"} CAPA
                      </span>
                    </div>
                  </div>
                  <div className="modal-controls">
                    <button
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.toggleStep3Tab();
                          this.setState({
                            iAssignErrorEnabled: false,
                            isCategoryErrorEnabled: false,
                            isCorretiveActionErrorEnabled: false,
                            isCAPAErrorEnabled: false,
                            isDueDateErrorEnabled: false,
                            isPriortiyErrorEnabled: false,
                          });
                        })
                      }
                      // onClick={() => {
                      //   this.toggleStep3Tab();
                      //   this.setState({
                      //     iAssignErrorEnabled: false,
                      //     isCategoryErrorEnabled: false,
                      //     isCorretiveActionErrorEnabled: false,
                      //     isCAPAErrorEnabled: false,
                      //     isDueDateErrorEnabled: false,
                      //     isPriortiyErrorEnabled: false,
                      //   });
                      // }}
                    >
                      Close
                    </button>
                    <button
                      disabled={this.state.isRecommendationEnabled}
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.state.editR_id
                            ? this._EditRecommendation()
                            : this._AddRecommendation();
                        })
                      }
                      // onClick={(e) => {
                      //   if (e.detail == 1) {
                      //     this.state.editR_id
                      //       ? this._EditRecommendation()
                      //       : this._AddRecommendation();
                      //   }
                      // }}
                      // onClick={this.state.editR_id ? this._EditRecommendation : this._AddRecommendation}
                    >
                      {this.state.isRecommendationEnabled ? (
                        <RefreshCw className="rotate-360" />
                      ) : this.state.editR_id ? (
                        "EDIT"
                      ) : (
                        "Add"
                      )}
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <div className="rd-box-head">
                      <div className="with-mic">
                        <h6>
                          Select Category
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div className="common-dropdown-layout">
                        <select
                          className="form-control1"
                          id={this.state.category_l}
                          value={this.state.category_l}
                          placeholder="Select Category"
                          onChange={(e) =>
                            this.setState({ category_l: e.target.value })
                          }
                        >
                          <option value="" disabled selected>
                            Choose option
                          </option>
                          {this.state.Category_list?.map((item, index) => (
                            <option key={index} value={item.category_id}>
                              {item.category}
                            </option>
                          ))}
                        </select>
                        {this.state.isCategoryErrorEnabled ? (
                          <p className="text-danger font-16">
                            Please choose option
                          </p>
                        ) : null}
                      </div>

                      <div className="with-mic my-3">
                        <h6>
                          CAPA
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>

                        <div className="control1 common-check-box-layout d-flex">
                          <input
                            type="checkbox"
                            id="CA"
                            checked={this.state.capaType === "CA"}
                            onChange={() => this.setState({ capaType: "CA" })}
                            name="CA"
                            value="CA"
                          />
                          <label htmlFor="Priority1" className="mr-3">
                            {" "}
                            CA
                          </label>
                          <input
                            type="checkbox"
                            id="PA"
                            checked={this.state.capaType === "PA"}
                            onChange={() => this.setState({ capaType: "PA" })}
                            name="PA"
                            value="PA"
                          />
                          <label htmlFor="Priority2"> PA</label>
                        </div>
                        {this.state.isCAPAErrorEnabled ? (
                          <p className="text-danger font-16">
                            Please select capa type
                          </p>
                        ) : null}
                      </div>

                      <div className="with-mic my-3">
                        {this.state.capaType === "PA" ? (
                          <h6>
                            Preventive Action
                            <span className="inputErrorMsg">&nbsp;*</span>
                          </h6>
                        ) : (
                          <h6>
                            Corrective Action
                            <span className="inputErrorMsg">&nbsp;*</span>
                          </h6>
                        )}
                        {/* <h6>
                          Corrective Action12
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6> */}
                      </div>

                      <textarea
                        className="form-control"
                        onChange={(event) =>
                          this.setState({
                            CorrectiveActionText: event.target.value,
                          })
                        }
                        value={this.state.CorrectiveActionText}
                        placeholder={
                          this.state.capaType === "PA"
                            ? "Enter Preventive Action"
                            : "Enter Corrective Action"
                        }
                      ></textarea>
                      {this.state.isCorretiveActionErrorEnabled ? (
                        <p className="text-danger font-16">
                          Please enter action
                        </p>
                      ) : null}
                      <div className="with-mic my-3">
                        <h6>
                          Assigned To
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div className="common-dropdown-layout">
                        <select
                          className="form-control1"
                          id={this.state.responsible_p}
                          value={this.state.responsible_p}
                          onChange={(e) =>
                            this.setState({ responsible_p: e.target.value })
                          }
                        >
                          <option value="" disabled selected>
                            Choose option
                          </option>
                          {this.state.responsible_person?.map((item, index) => (
                            <option key={index} value={item.person_id}>
                              {item.person}
                            </option>
                          ))}
                        </select>
                      </div>
                      {this.state.iAssignErrorEnabled ? (
                        <p className="text-danger font-16">
                          Please choose option
                        </p>
                      ) : null}

                      <div className="with-mic my-3">
                        <h6>
                          Due Date
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div>
                        <DatePicker
                          selected={this.state.selectedDate}
                          placeholderText="Due Date"
                          inputStyle={{ textAlign: "center" }}
                          minDate={capaMinDate}
                          onChange={(jsDate) =>
                            this._handleChange(jsDate, "step3Date")
                          }
                        />
                        {this.state.isDueDateErrorEnabled ? (
                          <p className="text-danger font-16">
                            Please select date
                          </p>
                        ) : null}
                      </div>

                      <div className="with-mic my-3">
                        <h6>
                          Priority
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div className="control1 common-check-box-layout d-flex">
                        <input
                          type="checkbox"
                          id="Priority"
                          checked={this.state.priority.extreme}
                          onChange={() => this.onPriorityChange(0)}
                          name="Priority1"
                          value="Extreme"
                        />
                        <label htmlFor="Priority1" className="mr-3">
                          {" "}
                          Critical
                        </label>
                        <input
                          type="checkbox"
                          id="Priority"
                          checked={this.state.priority.high}
                          onChange={() => this.onPriorityChange(1)}
                          name="Priority2"
                          value="High"
                        />
                        <label htmlFor="Priority2"> Urgent</label>
                        <br />
                        <input
                          type="checkbox"
                          className="ml-3"
                          id="Priority"
                          checked={this.state.priority.medium}
                          onChange={() => this.onPriorityChange(2)}
                          name="Priority3"
                          value="Medium"
                        />
                        <label htmlFor="Priority3" className="mr-3">
                          {" "}
                          Important
                        </label>
                        <input
                          type="checkbox"
                          id="Priority"
                          checked={this.state.priority.low}
                          onChange={() => this.onPriorityChange(3)}
                          name="Priority4"
                          value="Low"
                        />
                        <label htmlFor="Priority4"> Normal</label>
                      </div>
                      {this.state.isPriortiyErrorEnabled ? (
                        <p className="text-danger font-16">
                          Please choose priority
                        </p>
                      ) : null}
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                centered
                show={this.state?.isDataFilterEnable ?? false}
                size="sm"
              >
                <Modal.Header className="align-items-center">
                  <div className="align-items-center d-flex justify-content-between">
                    <div>
                      <span className="font-20 font-weight-500 common-white-2">
                        {" "}
                        Filter
                      </span>
                    </div>
                  </div>
                  <div className="d-flex  align-items-center">
                    <div className="modal-controls">
                      <button
                        onClick={() =>
                          this.debounceFunc(() => {
                            this.makeReset();
                          })
                        }
                      >
                        Reset
                      </button>
                      <button
                        onClick={() =>
                          this.debounceFunc(() => {
                            this.getFilterData();
                          })
                        }
                      >
                        {this.state.isFilterLoading ? (
                          <RefreshCw className="rotate-360" />
                        ) : (
                          "Apply"
                        )}
                      </button>
                    </div>
                    <X
                      style={{
                        height: "1.4vw",
                        width: "1.4vw",
                      }}
                      className="cursor-pointer mx-2 common-white-2"
                      onClick={() =>
                        this.setState({
                          isDataFilterEnable: false,
                        })
                      }
                    />
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <div className="rd-box-head">
                      <div className="with-mic">
                        <h6>
                          Select Category
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div className="common-dropdown-layout">
                        <select
                          className="form-control1"
                          id={this.state.filterParams.category}
                          value={this.state.filterParams.category}
                          onChange={(e) =>
                            this.manageFilterParams("category", e.target.value)
                          }
                        >
                          <option value={""} disabled>
                            Choose Option
                          </option>
                          {this.state.Category_list?.map((item, index) => (
                            <option key={index} value={item.category_id}>
                              {item.category}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="with-mic my-3">
                        <h6>
                          {" "}
                          Action Proposed By
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>

                        {/* {this.state.capaType === "PA" ? (<h6>
                          Preventive Action Proposed By
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>):(<h6>Corrective Action Proposed By
                                <span className="inputErrorMsg">&nbsp;*</span>
                                </h6>)} */}
                      </div>
                      <div className="common-dropdown-layout">
                        <select
                          className="form-control1"
                          id={this.state.filterParams.recommendation_by}
                          value={this.state.filterParams.recommendation_by}
                          onChange={(e) =>
                            this.manageFilterParams(
                              "recommendation_by",
                              e.target.value
                            )
                          }
                        >
                          <option value={""} disabled>
                            Choose Option
                          </option>
                          {recommendedby?.map((item, index) => (
                            <option key={index} value={item?.recommended_id}>
                              {item?.recommendedBy}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="with-mic my-3">
                        <h6>
                          Assigned To
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div className="common-dropdown-layout">
                        <select
                          className="form-control1"
                          id={this.state.filterParams.responsible_person_id}
                          value={this.state.filterParams.responsible_person_id}
                          onChange={(e) =>
                            this.manageFilterParams(
                              "responsible_person_id",
                              e.target.value
                            )
                          }
                        >
                          <option value={""} disabled>
                            Choose Option
                          </option>
                          {this.state.responsible_person?.map((item, index) => (
                            <option key={index} value={item.person_id}>
                              {item.person}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="with-mic my-3">
                        <h6>
                          Due Date
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div className="d-flex common-datepick-layout">
                        <div className="w-50">
                          <DatePicker
                            placeholderText="Start Date"
                            inputStyle={{ textAlign: "center" }}
                            className="mx-2"
                            minDate={capaMinDate}
                            id={this.state.filterParams.startDate}
                            selected={this.state.filterParams.startDate}
                            onChange={(jsDate) =>
                              this.manageFilterParams("startDate", jsDate)
                            }
                          />
                        </div>
                        <div className="w-50 mx-3">
                          <DatePicker
                            placeholderText="End Date"
                            inputStyle={{ textAlign: "center" }}
                            className="mx-2"
                            id={this.state.filterParams.endDate}
                            minDate={this.state.filterParams.startDate}
                            selected={this.state.filterParams.endDate}
                            onChange={(jsDate) =>
                              this.manageFilterParams("endDate", jsDate)
                            }
                          />
                        </div>
                      </div>

                      <div className="with-mic my-3">
                        <h6>
                          Priority
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div className="control1 common-check-box-layout d-flex">
                        <input
                          type="checkbox"
                          id="Priority"
                          checked={this.state.extremeSelect}
                          onChange={() =>
                            this.FilterData(this.state.extremeSelect, 4)
                          }
                          name="Priority1"
                          value="Extreme"
                        />
                        <label htmlFor="Priority1" className="mr-3">
                          Critical{" "}
                        </label>
                        <input
                          type="checkbox"
                          id="Priority"
                          checked={this.state.highSelect}
                          onChange={() =>
                            this.FilterData(this.state.highSelect, 3)
                          }
                          name="Priority2"
                          value="High"
                        />
                        <label htmlFor="Priority2"> Urgent</label>
                        <br />
                        <input
                          type="checkbox"
                          className="ml-3"
                          id="Priority"
                          checked={this.state.mediumSelect}
                          onChange={() =>
                            this.FilterData(this.state.mediumSelect, 2)
                          }
                          name="Priority3"
                          value="Medium"
                        />
                        <label htmlFor="Priority3" className="mr-3">
                          {" "}
                          Important
                        </label>
                        <input
                          type="checkbox"
                          id="Priority"
                          checked={this.state.lowSelect}
                          onChange={() =>
                            this.FilterData(this.state.lowSelect, 1)
                          }
                          name="Priority4"
                          value="Low"
                        />
                        <label htmlFor="Priority4"> Normal</label>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* <Modal
                centered
                show={this.state.toggleStep3edit}
                className="submit-modal"
              >
                <Modal.Header closeButton onClick={this.hideModal}
                  style={{ background: 'rgb(255, 99, 71)', color: 'black' }}
                >Add Corrective Action</Modal.Header>
                <button
                  type="submit"
                  className="red-btn"
                  style={{ marginTop: '1vh', width: '80%' }}
                  onClick={() => (

                    this._editRecommendation(this.state.editR_id)
                  )
                  }
                >
                  ADD
                </button>
                <Modal.Body>
                  <div className="form-group">
                    <div className="rd-box-head">
                      <div className="with-mic">
                        <h6>
                          Select Category
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div style={{ paddingRight: '0.8vw' }}>


                        <select
                          className="form-control1"
                          id={this.state.category_l}
                          onChange={(e) => (this.setState({ category_l: e.target.value }))}
                        >

                          {this.state.Category_list?.map((item, index) =>
                          (
                            <option value={this.state.Category_list.category_id}  >{item.category}</option>

                          )
                          )}

                        </select>


                      </div>


                      <div className="with-mic">
                        <h6>
                          Corrective Action
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <textarea
                        className="form-control"
                        onChange={(event) =>
                          this.setState({ CorrectiveActionText: event.target.value })
                        }
                        value={this.state.CorrectiveActionText}
                        placeholder={"Enter Corrective Action "}
                      ></textarea>

                      <div className="with-mic">
                        <h6>
                        Assigned To
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div style={{ paddingRight: '0.8vw' }}>


                        <select
                          className="form-control1"
                          id={this.state.responsible_p}
                          onChange={(e) => (this.setState({ responsible_p: e.target.value }))}
                        >

                          {this.state.responsible_person?.map((item, index) =>
                          (
                            <option value={this.state.responsible_person.responsible_person_id}  >{item.person}</option>

                          )
                          )}

                        </select>


                      </div>


                      <div className="with-mic">
                        <h6>
                          Due Date
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div>

                        <DatePicker
                          selected={this.state.selectedDate}
                          inputStyle={{ textAlign: 'center' }}
                          onChange={(jsDate) =>
                            this._handleChange(jsDate, "step3Date")
                          }
                        />

                      </div>

                      <div className="with-mic">
                        <h6>
                          Priority
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>
                      </div>

                      <div
                        className="control1"
                      >
                        <input type="checkbox" id="Priority" checked={this.state.priority.extreme} onChange={() => (this.onPriorityChange(0))} name="Priority1" value="Extreme" />
                        <label for="Priority1"> Critical</label>
                        <input type="checkbox" id="Priority" checked={this.state.priority.high} onChange={() => (this.onPriorityChange(1))} name="Priority2" value="High" />
                        <label for="Priority2"> Urgent</label>
                        <br />
                        <input type="checkbox" id="Priority" checked={this.state.priority.medium} onChange={() => (this.onPriorityChange(2))} name="Priority3" value="Medium" />
                        <label for="Priority3"> Important</label>
                        <input type="checkbox" id="Priority" checked={this.state.priority.low} onChange={() => (this.onPriorityChange(3))} name="Priority4" value="Low" />
                        <label for="Priority4"> Normal</label>

                      </div>



                    </div>

                    <div className="modal-footer-btn">
                      <button
                        type="submit"
                        className="red-btn"
                        onClick={() => (
                          this.hideModal(),
                          this.cancleSubmitTargetTime()
                        )
                        }
                      >
                        CLOSE
                      </button>

                    </div>

                  </div>
                </Modal.Body>
              </Modal> */}

              <Modal
                centered
                show={this.state.afterTargetDatePopup}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={this.hideModal}
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/confirm-popup.svg")}
                  />
                  <p className="submit-text">
                    After adding target date this report will move in Inprogress
                    list
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={this.hideModal}
                    >
                      CLOSE
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                centered
                show={this.state.fcrdelID !== null}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={() =>
                    this.setState({
                      fcrdelID: null,
                    })
                  }
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/confirm-popup.svg")}
                  />
                  <p className="submit-text">
                    If you Delete this Corrective Action from here it will be
                    store in Proposed Corrective Action
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() =>
                        this.setState({
                          fcrdelID: null,
                        })
                      }
                    >
                      CLOSE
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() =>
                        this.debounceFunc(() => {
                          this._removefinalcr(this.state.fcrdelID);
                        })
                      }
                    >
                      YES,DELETE
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        <DeletePopup
          showDeletePopup={this.state.deleteQuestionId}
          onCancel={() => this.setState({ deleteQuestionId: null })}
          onConfirm={() =>
            this.debounceFunc(() => {
              this.analysisQuestionDelete(this.state.deleteQuestionId);
            })
          }
          // onConfirm={() =>
          //   this.analysisQuestionDelete(this.state.deleteQuestionId)
          // }
        />
        {this.state.show ? (
          <ResolvePopup
            show={this.state.show}
            modalClose={this.openResolve}
            reportId={this.state.soiId}
            reportingDetails
          />
        ) : null}

        {this.state.showUser ? (
          <InviteUser
            showUser={this.state.showUser}
            closeUser={this.openInviteUser}
            reportId={this.state.soiId}
            moduleType={"nm"}
            // invitedUsersList={this.state.nmDetail.nm_reported_detail_people_involved_list}
            showInvitedUser={false}
            onAddNewUsers={this._addUsersToList}
            project_id={this.state.nmDetail.project_id}
            service_id={this.state.nmDetail.service_id}
          />
        ) : null}
        <Modal
          show={this.state.userLikedPopup}
          onHide={this.handleClose}
          className="likeduser"
        >
          <Modal.Header closeButton>
            <Modal.Title>User Who Liked</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="totalLikedUser">
              <span>All</span>
              <span className="count">{this.state.likeNumber}</span>
            </div>
            {this.state.userLike != "" ? (
              this.state.userLike.map((items, index) => (
                <div className="user-list" key={index}>
                  <img src={items.user_profile} alt={items.user_name} />
                  <h6 className="user-name">{items.user_name}</h6>
                </div>
              ))
            ) : (
              <div className="user-list">
                <p className="data-not-present">Likes not available </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
        <Modal show={this.state.teamLeadID !== null} className="likeduser">
          {/* <Modal.Header closeButton>
            <Modal.Title>User Who Liked</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <div className="rd-box shadow-none ">
              <div className="d-flex space-between">
                <div className="rd-box-head1 text-center">
                  <h3 className="font-weight-500 common-black-1">
                    Select Due Date
                  </h3>
                </div>
                {isInProgress && (
                  <div>
                    <span className="font-weight-light font-16">
                      Previous Due Date : {nmDetail.target_date}
                    </span>
                  </div>
                )}
              </div>

              <Col style={{ margin: "1rem", width: "80%" }}>
                <DatePicker
                  selected={this.state.selectedTargetDate}
                  minDate={new Date()}
                  placeholderText="Due date"
                  inputStyle={{ textAlign: "center" }}
                  onChange={(jsDate) =>
                    this._handleChange(jsDate, "target_date")
                  }
                />
              </Col>

              <Col className="text-center">
                <button
                  disabled={this.state.selectedTargetDate === null}
                  type="submit"
                  className="btn btn-outline-danger font-weight-500"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.confirmSubmitTargetDate();
                    })
                  }
                  // onClick={() => {
                  //   this.confirmSubmitTargetDate();
                  // }}
                >
                  Confirm
                </button>
              </Col>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={this.state.isFreezeEnabled}
          className="submit-modal"
        >
          <Modal.Header
            onClick={() => {
              this.setState({
                isFreezeEnabled: false,
              });
            }}
          ></Modal.Header>

          <Modal.Body>
            <p className="submit-text">
              Are you sure you want to freeze all final corrective action
            </p>
            {this.state.isFreezeLoadingEnabled ? (
              <div className="mx-2">
                <Spinner />
              </div>
            ) : (
              <div className="modal-footer-btn">
                <button
                  type="submit"
                  className="red-btn"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.actionFreezeAll();
                    })
                  }
                >
                  Freeze All
                </button>
                <button
                  type="submit"
                  className="red-btn"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.setState({
                        isFreezeEnabled: false,
                      });
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            )}
          </Modal.Body>
        </Modal>
        <SLDModal
          disableClose
          footer={[
            <SLDButton
              key="promptBtn1"
              label={this.state.isFactorLoading ? "Loading ..." : "Yes"}
              onClick={() =>
                this.debounceFunc(() => {
                  this.manageFactors(this.state.factorParams);
                })
              }
            />,
            <SLDButton
              key="promptBtn"
              label="No"
              onClick={() =>
                this.debounceFunc(() => {
                  this.setState({
                    factorParams: {},
                  });
                })
              }
              disabled={this.state.isFactorLoading}
            />,
          ]}
          isOpen={Object.keys(this.state.factorParams).length}
          contentClassName="text-center"
          prompt="error"
          size="small"
          title={
            <span className=" font-18 font-weight-500 text-center w-100">
              Delete Record
            </span>
          }
        >
          <div className="common-black-1 font-16 font-weight-500 slds-m-around_medium">
            Do you really want to delete this?
          </div>
        </SLDModal>
      </div>
    );
  }
}

export default withRouter(NMDetail);
