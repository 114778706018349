import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Url from "app/shared/constants/Url";

const LowSpace = ({ message, handleLowSpace }) => {
  
  return (
    <SweetAlert
      custom
      confirmBtnText="Okay"
      confirmBtnBsStyle="success"
      customIcon={require("assets/images/low_storage.svg")}
      title="Warning!"
      onConfirm={handleLowSpace}
    >
      {message}
    </SweetAlert>
  );
};

LowSpace.defaultProps = {
  handleLowSpace: () => {},
};
export default LowSpace;
