import React, { Component } from "react";
import LoginLeft from "./AuthLeft";
import LoginRight from "./AuthRight";
import FooterLink from "./FooterLink";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";



const LinkList = [
  {
    name: "ECLIDE",
    link: "https://eclide.clideanalyser.in",
    id: 101,
  },
  {
    name: "CLIDEAnalyser",
    link: "https://clideanalyser.in",
    id: 202,
  },
  {
    name: "CLIDEG",
    link: "https://clideg.clideanalyser.in",
    id: 303,
  },
];


export default class Auth extends Component {
  render() {
    return (
      <div className="login-wrapper">
        <div className="login-left">
          <LoginLeft />
        </div>
        <div className="login-right">
          <LoginRight />
        </div>
        <div className="login-footer">
          <img
            className="footer-img"
            src={require("assets/images/footer-shape.png")}
            alt=""
          />
          <div className="footer-box">
            <div className="social-box">
              <FooterLink
                link="https://www.linkedin.com/showcase/clide-safety/?viewAsMember=true"
                iconName="fa-linkedin"
                title="View our Linkedin page"
              />

              <FooterLink
                link="https://twitter.com/CLIDEo"
                iconName="fa-twitter"
                title="View our Twitter page"
              />
              <FooterLink
                link="https://www.facebook.com/CLIDEOffice-107416677331001/?modal=admin_todo_tour"
                iconName="fa-facebook"
                title="View our Facebook page"
              />
              <FooterLink
                link="https://www.youtube.com/channel/UC0pxWwgntN9AXOEH0gr_yug"
                iconName="fa-youtube"
                title="Visit our youtube channel"
              />

              <NavLink to="/market">
                <FooterLink
                  // link="/market"
                  iconName="fa-shopping-cart"
                  title="Visit Market-Place"
                />
              </NavLink>
              <FooterLink
                link="https://play.google.com/store/apps/details?id=com.Clideoffice"
                iconName="fa-play"
                title="Clide Analyser App"
              />



            </div>
            <div>
              <a href="https://eclide.clideoffice.com" target="_blank" >
                <p className="footer-text">ECLIDE</p> </a>
            </div>
            <div style={{ marginLeft: "-27vw" }} className="border-left pl-2 border-right pr-2 py-2">
              <a href="https://clideoffice.com" target="_blank" >
                <p className="footer-text">CLIDEAnalyser </p></a>
            </div>
            <div style={{ marginLeft: "-27vw" }}>
              <a href="https://clideg.clideoffice.com" target="_blank" >
                <p className="footer-text">CLIDEG</p></a>
            </div>

            <p className="footer-text">Powered by CLIDEG</p>
          </div>
          <div className="clide-text">
            {LinkList.map((item) => (
              <a href={item.link} key={item.id} target="_blank">
                <h6>{item.name}</h6>
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
