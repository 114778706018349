import React, { useEffect, useState } from "react";

const ProjectList = ({ optionList, onChangeHandle, value, hasError = false,
  errorMessage = "", isRquired = true,   customTitle = <></>,   titleview = true
}) => {
  const [selectValue, setSelectValue] = useState("");
  useEffect(() => {
    setSelectValue("null");
    console.log("updated");
  }, [optionList]);

  return (
    <div className={`slds-form-element ${hasError ? "slds-has-error" : ""} select-remove-icon`}>
     {titleview &&  <label className="slds-form-element__label ">Plant/Project {isRquired  ? <abbr className="slds-required" title="required">* </abbr> : null }</label>}
     {customTitle}

      <div className="slds-form-element__control">
        <div className="slds-select_container">
          <select
            className="slds-select"
            onChange={(event) => onChangeHandle(event.target.value)}
            value={value}
          >
            {optionList.length ? (
              <>
                <option value="">Select Project</option>
                {optionList.map((item, index) => (
                  <option
                    key={`project-option-${index}`}
                    value={item.project_id}
                    showcontractor={item.contractor_flag}
                  >
                    {item.project}
                  </option>
                ))}
              </>
            ) : (
              <option value={null} selected>
                No Project Available
              </option>
            )}
          </select>
        </div>
      </div>
      {hasError ? <div class="slds-form-element__help" id="error-01">{errorMessage !== "" ? errorMessage : "This is mandatory field."}</div> : null}
    </div>
  );
};

ProjectList.defaultProps = {
  optionList: [],
  onChangeHandle: () => { },
  value: ""
};

export default ProjectList;
