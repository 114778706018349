import React, { Component } from "react";
import ReactJoyride from "react-joyride";
import { FaPlaneDeparture } from "react-icons/fa";
export default class Tour1 extends React.Component {
    constructor() {
        super();
        this.state={
           run: false,
           steps: [

            {
                target: ".tour1",
                content: "For configuring the Incident Reporting Analysis reporting form for your company set the reporting parameters. Tick and un-tick the parameter you wish to include in reporting form. "
              },
              {
                target: ".ira0",
                content: "- Sample preview is where you can see the changes done by you and how you final IRA reporting form will look like. We suggest not to make frequent changes to this form. "
              },
              {
                target: ".ira1",
                content: "First Reporting parameter - choose the parameter you want to include in the reporting form."
              },
            {
                target: ".ira2",
                content: " Second Location based parameter- These are set when you configure the plant and project."
              },
              {
                target: ".ira3",
                content: "Third System Parameter - which has five steps for investigating the incident. You can choose the parameters which you want to include in the investing steps. Boxes which are ticked and appear in grey colour are mandatory parameter."
              },
              {
                target: ".ira4",
                content: "Forth System Default Parameter are mandatory."
              },
             
           
             
           

           ]
         }
    }
    handleClick = e => {
      e.preventDefault();
      
      this.setState({
       run: true
      });
    };
    manageReset = (state) => {
      if (state.action === "reset") {
        this.setState({
          run: false
        });
      }
    }
    render () {
      return (
        <div className="app">
          <ReactJoyride
            steps={this.state.steps}
            run={this.state.run}
            continuous
            showProgress
            showSkipButton
            disableScrolling={true}
            styles={{
             options: {
                // modal arrow and background color
                arrowColor: "white",
                backgroundColor: "white",
                // page overlay color
                overlayColor: "tomato",//"rgba(79, 45, 0, 0.4)"
                //button color
                primaryColor: "tomato",
                //text color
                textColor: "black",
     
                //width of modal
                width: 500,
                //zindex of modal
                zIndex: 1000,
                
            }
         }}
         callback={this.manageReset}
      />
         
  <div className="container">
  <div className="vertical-center">
  {/* <button className="btn btn-warning" onClick={this.handleClick}>Audit Tour</button> */}
  <img src={require("assets/sls-icons/V2. New Development/Take Tour.svg")} className="svg-icons cursor-pointer" title="IRA Tour" onClick={this.handleClick} color="tomato" /> 
  
  </div>
</div>
        
       
      </div>
       
      );
    }
}
