const PageTwo = `
  <div style="margin: 20px auto; width: 800px; height: 98vh; border: 3px double #797878; position: relative; box-sizing: border-box;">
    <table cellpadding="0" cellspacing="0" width="100%" style="text-align: center; font-family: Arial, sans-serif; height: 100%;">
      <tr>
        <td valign="top" style="padding: 20px;">
          <h3 style="text-transform: uppercase; text-align:center; margin-bottom: 20px;">Index</h3>
          <table cellpadding="6" cellspacing="0" border="1" bordercolor="#e2e2e2" style="width: 100%; margin: 0 auto; font-size: 14px;">
            <tr style="background: #ddd; color: black;">
              <th>#</th>
              <th style="text-align: left;">Particulars</th>
              <th>Page No.</th>
            </tr>
            <tr>
              <td>1</td>
              <td style="text-align: left;">Activity Info</td>
              <td>1</td>
            </tr>
            <tr>
              <td>2</td>
              <td style="text-align: left;">Reporting Info</td>
              <td>2</td>
            </tr>
            <tr>
              <td>3</td>
              <td style="text-align: left;">Observation</td>
              <td>3</td>
            </tr>
            <tr>
              <td>4</td>
              <td style="text-align: left;">Evidence</td>
              <td>4</td>
            </tr>
            <tr>
              <td>5</td>
              <td style="text-align: left;">Team</td>
              <td>5</td>
            </tr>
            <tr>
              <td>6</td>
              <td style="text-align: left;">Analysis</td>
              <td>6</td>
            </tr>
            <tr>
              <td>7</td>
              <td style="text-align: left;">Corrective Action Plan</td>
              <td>7</td>
            </tr>
          </table>
          <span class="page-number"></span>
        </td>
      </tr>
    </table>
  </div>
`;

export default PageTwo;
