import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import { Trash, Copy, Edit } from 'react-feather';

export default class TextEditorPreview extends Component{
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
  }

  _onDelete = () => { 
    this.props.onDelete();
  }

  createMarkup(rawHtml) {
    return { __html: rawHtml };
  }

  render() {
    const data = this.state.data;

    return (
			<div className="box-dynamic">
        <div className="que-display">
          <div className="que-option">
            <div className="form-group">
              {data.response_type == 10 &&
                <input type="checkbox" />
              }
              <div
                className="p-2"
                style={{display : "inline-block"}}
                dangerouslySetInnerHTML={this.createMarkup(
                  this.state.data.question
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-dynamic">
          <div className="section-btn">
            {
              this.props.onEdit &&
                <Button className="icon-btn" title="Edit Question" onClick={this.props.onEdit}>
                  <Edit className="icon-style btn-color"/>
                </Button>
            }
            {
              this.props.onCopy &&
              <Button className="icon-btn" title="Copy Question" onClick={this.props.onCopy}>
                <Copy className="icon-style btn-color"/>
              </Button>
            }
            {
              this.props.onDelete &&
              <Button className="icon-btn" title="Delete Question" onClick={() => this._onDelete()}>
                <Trash className="icon-style btn-color"/>
              </Button>
            }
          </div>
        </div>
      </div>
    )
  }
}