import React, { Component } from "react";

export default class Recommendation extends Component{
  render() {
    return (
			<div className="form-dynamic">
        <div className="form-group">
          {/* <textarea type="email" name="email" placeholder="Write your Recommendation" className="form-control" disabled/> */}
          <textarea type="email" name="email" placeholder="Write your observation" className="form-control" disabled/>
        </div>
			</div>
    )
  }
}