import React from "react";
import ReactJoyride from "react-joyride";
export default class Tour1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      run: false,
      isVersionV2: this.props.isVersionV2 ?? false,
      steps: [
        {
          target: ".ir1",
          content:
            "UAUC - To report an unsafe act/condition attach a photo or video as evidence fill in the required mandatory fields and click report to submit the observation.",
        },
        {
          target: ".ir2",
          content:
            "NM - To report a near-miss attach a photo or video as evidence, write the observation fill the required mandatory fields and click on report to submit the near-miss.",
        },
        {
          target: ".ir3",
          content:
            "IRA - To report an accident attach a photo or video as evidence write the observation, fill the required mandatory fields and click on report to submit the accident.",
        },
        {
          target: ".ir4",
          content:
            "GA - To report good practice attach a photo or video as evidence write the observation, fill the required mandatory fields and click on report to submit the good act.",
        },
        {
          target: ".ir5",
          content:
            "Training/TBT - To report Training/Tool Box Talk attach a photo or video as evidence enter the topic and what all covered, fill in the required mandatory fields, and click on report to submit the Training/TBT.",
        },
      ],
    };
  }
  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      run: true,
    });
  };
  manageReset = (state) => {
    if (state.action === "reset") {
      this.setState({
        run: false,
      });
    }
  };
  render() {
    return (
      <div className="app ">
        <ReactJoyride
          steps={this.state.steps}
          run={this.state.run}
          continuous
          showProgress
          showSkipButton
          className="i-report-tour-fix"
          styles={{
            options: {
              // modal arrow and background color
              arrowColor: "white",
              backgroundColor: "white",
              // page overlay color
              overlayColor: "tomato", //"rgba(79, 45, 0, 0.4)"
              //button color
              primaryColor: "tomato",
              //text color
              textColor: "black",

              //width of modal
              width: 500,
              //zindex of modal
              zIndex: 1000,
            },
            beacon: {
              top: "2.5vw",
              left: "28.5vw",
            },
          }}
          callback={this.manageReset}
        />

        <div class="container">
          <div class="vertical-center ">
            {this.state.isVersionV2 ? (
              <div
                className="cursor-pointer"
                title="Tour a Checklist"
                onClick={this.handleClick}
              >
                {this.props.content}
              </div>
            ) : (
              <img
                src={require("assets/sls-icons/V2. New Development/Take Tour.svg")}
                alt="tour"
                className="svg-icons cursor-pointer"
                title="Tour a Checklist"
                onClick={this.handleClick}
                style={{
                  position: "absolute",
                  right: "1.5vw",
                  top: "0.5vw",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
