import { CHANGE_SUMMARY_SERVICE_PROJECT } from '../constant/ActionType';

let initialState = {
  summary : {
    service_id : null,
    service_name : null,
    project_id : null,
    project_name : null
  }
};

function summaryReducer(state = initialState, action) {

  switch (action.type) {
    case CHANGE_SUMMARY_SERVICE_PROJECT : 
      return {
        ...state,
        summary : {
          ...action.payload,
          project_id : action.payload.project_id[0] // project_id is array , so take only first
        }
      }
    break;
    default:
      return state;
      break;
  }  
};

export default summaryReducer;