import React, { Component } from "react";
import { Button, Modal } from 'react-bootstrap';
import { Image } from 'react-feather';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Redirect, withRouter} from "react-router-dom";
import { toast } from 'react-toastify';
import Url from 'app/shared/constants/Url';
import axios from 'axios';

export default class ReportingPopup extends Component{

    constructor(props){
        super(props);
        this.state = {
          show: false,
          reportId: this.props.reportId,
          formSubmitting: false,
          images: [],
          maxFilesAttachLimit: 5,
          fileLimitMsg: false,
          redirectToReferrer: false
        };
        //this.closeModal = this.closeModal.bind(this);
    }

    render() {
        return (
            <div className="report-section">
                <Modal show={this.state.show} centered>
                    <div>
                        <Modal.Header closeButton onClick={this.closeModal}>
                        <Modal.Title>Reporting UA/UC</Modal.Title>
                        <Button type="submit" variant="outline-primary" className="modal-btn" >DONE</Button>
                        </Modal.Header>
                        <Modal.Body>
                        <Form>
                            <div className="form-group">
                            <label>Closing Remark</label>
                            <Field 
                                name="closing_remark"  
                                type="text" 
                                placeholder="Closing Remark"
                                className="form-control"
                            />
                            <ErrorMessage
                                component="div"
                                name="closing_remark"
                                className="inputErrorMsg"
                            />
                            </div>
                            <div className="form-group">
                            <label>Add Closing Evidence</label>
                            <div className="box">
                                <input id="file-2" name="file[]" type="file" multiple onChange={ this.handleImages } className="inputfile inputfile-2" accept="image/*"/>
                                <label for="file-2">
                                <div className="image-custom">
                                    <Image color='#28AED9' size="22" />
                                </div>
                                <span>Browse Images</span>
                                </label>
                                {
                                this.state.fileLimitMsg && this.state.images.length > this.state.maxFilesAttachLimit ? <div className="inputErrorMsg"> {`Max limit of attach files is ${this.state.maxFilesAttachLimit}`}
                                </div> : null
                                } 
                            </div>
                            </div>
                            <div className="image-preview">
                            {
                                this.state.images && this.state.images.map((data, index) => (
                                <div className="preview-box">
                                    <img src={URL.createObjectURL(data)} alt=""/>
                                    <Button variant="outline-primary" className="close-btn" onClick={() => this._removePreviewFile(index)}>X</Button>
                                </div>
                                ))
                            }
                            </div>
                        </Form>
                        </Modal.Body>
                    </div>
                </Modal>

                
            </div>
        )
    }
}


