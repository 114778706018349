import React, { Component } from "react";
import ReactJoyride from "react-joyride";
import { FaPlaneDeparture } from "react-icons/fa";
export default class Tour1 extends React.Component {
    constructor() {
        super();
        this.state={
           run: false,
           steps: [
          
            {
                target: ".tourr2",
                content: "This is the Goal dashboard module. Here you will see the various charts/graphs related to Goal month wise. "
              },
              {
                target: ".tourr3",
                content: " In the Planned tab you will see all the goals created by you, click on the goal name to visit the detail page. "
              },
             
              {
                target: ".tourr4",
                content: "In the in-progress goal tab you will see all the goals which are in-progress, click on the goal name to visit the detail page."
              },

              {
                target: ".tourr5",
                content: "In the completed tab, you will see all the completed goals, click on the goal name to visit the detail page. "
              },
              {
                target: ".tourr6",
                content: " In the inactived tab, you will see all the goals which are marked as inactive. "
              },
              {
                target: ".tourr7",
                content: " In the deleted goal tab you will see all the goals which are deleted by you."
              },
             
           

           ]
         }
    }
    handleClick = e => {
      e.preventDefault();
      
      this.setState({
       run: true
      });
    };
    render () {
      return (
        <div className="app">
          <ReactJoyride
            steps={this.state.steps}
            run={this.state.run}
            continuous
            showProgress
            showSkipButton
            disableScrolling={true}
            styles={{
             options: {
                // modal arrow and background color
                arrowColor: "grey",
                backgroundColor: "white",
                // page overlay color
                overlayColor: "tomato",//"rgba(79, 45, 0, 0.4)"
                //button color
                primaryColor: "tomato",
                //text color
                textColor: "black",
     
                //width of modal
                width: 500,
                //zindex of modal
                zIndex: 1000,
                
            }
         }}
      />
         
  <div class="container" style={{marginLeft:"87vw",marginTop:"-2.5vw"}}>
  <div class="vertical-center">
  {/* <button className="btn btn-warning" onClick={this.handleClick}>Goal Tour</button> */}
  <img src={require("assets/sls-icons/V2. New Development/Take Tour.svg")} className="svg-icons" title="Goal tour" onClick={this.handleClick} color="tomato" /> 
  </div>
</div>
        
       
      </div>
       
      );
    }
}
