//icons
import React from "react";

const severityoption = [
  { value: 1, label: "Low" },
  { value: 2, label: "Medium" },
  { value: 3, label: "High" },
  { value: 4, label: "High Critical" },
];

const content = {
  dasboardTabs: [
    {
      tabName: "Dashboard",
      key: "dashboard",
      keyClass: "ir1",
    },
    {
      tabName: "CAPA",
      key: "capa",
      keyClass: "ir2",
    },
    {
      tabName: "NM List",
      key: "list",
      keyClass: "ir3",
    },
  ],

  dashboardPageState: {
    isLoading: true,
    status: {},
    severity: [],
    uauc: [],
    category: [],
    contributing_factor: [],
    department_count: [],
    contractorWiseNmCount: [],
    locationWiseNMCount: [],
    zoneWiseNMCount: [],
    immediate_factor: [],
    rca_factor: [],
    serviceSeverityGraph: [],
    activitySeverityGraph: [],
    resetServiceSeverityGraph: [],
    LocationSeverityGraph: [],
    resetLocationSeverityGraph: [],
    month_wise_nm_reported: [],
    reset_month_wise_nm_reported: [],
    ServiceSeverityPopup: false,
    ProjectSeverityPopup: false,
    ActivitySeverityPopup: false,
    monthwisePopup: false,
    service: [],
    serviceSelect: "",
    project: [],
    projectSelect: "",
    R_person: [],
    extremeSelect: 0,
    extremeValue: 0,
    highSelect: 0,
    mediumSelect: 0,
    lowSelect: 0,
    all: 0,
    allValue: [],
    allS: [],
    severityoption: severityoption,
    selectedOption: null,
    selectedOptionseverity: null,
    selectedOptionproject: null,
    plantProjectFilterSelectedService: null,
    selectedProjectFilterOptionseverity: null,
    start_month: null,
    start_year: null,
    end_month: null,
    end_year: null,
    add: 1,
    selectedOptions: "",
    total_data: null,
    isFirstFetching: false,
    zoneStatus: null,
    locationStatus: null,
    departmentStatus: null,
    contractorStatus: null,
    userInfo: JSON.parse(localStorage.getItem("userData")) || null,
  },
  capaPageState: {
    dataLoading: true,
    currentPage: 1,
    nmHeaderCount: [],
    completed_number: "",
    inprogress_number: "",
    searchList: "",
    searchList2: "",
    searchBtnClicked: false,
    searchBtnClicked2: false,
    searchCompleted: [],
    trackerFilterPopup: false,
    startDate: new Date(),
    endDate: new Date(),
    overdue: 0,
    service: [],
    serviceSelect: "",
    project: [],
    projectSelect: "",
    R_person: [],
    extremeSelect: 0,
    extremeValue: 0,
    highSelect: 0,
    mediumSelect: 0,
    activeService: "",
    activeProject: "",
    lowSelect: 0,
    listMode: "kanban",
    all: 0,
    allValue: [],
    allS: [],
    severity: [],
    personSelect: [],
    CategoryFilter: [],
    dflag: JSON.parse(localStorage.getItem("userData")),

    fsToggler: false,
    toggler: false,
    lightboxactive: false,
    evidencelightbox: [],
    evidencelightboxType: [],

    modalParams: {},
    recommendationNumber: null,
    capaParams: {},
    evidenceParams: {},
    deleteIds: [],
    observationText: "",
    correctiveItem: {},
    correctionId: null,
    declineMessage: "",
    isDeclineViewEnabled: false,
    recommendation_id: null,
    nearmiss_id: null,
    showPopupSearch: false,
    obs_rec: "",
    activeService: "",
    activeProject: "",
  },
  capaResetState:{
    extremeSelect: 0,
    highSelect: 0,
    mediumSelect: 0,
    lowSelect: 0,
    startDate: new Date(),
    severity: [],
    endDate: new Date(),
    serviceSelect: null,
    projectSelect: null,
    categorySelect: null,
    personSelect: null,
    overdue: 0,
    all: 0,
  },
  dashboardLeftContent: {
    graphType: "donut",
    cardHeading: "Investigation Status",
  },
  chartJsOptions: {
    rotation: -90,
    circumference: 180,
    cutoutPercentage: 80,
    textAlign: "center",
  },
};

export { content as nmContent };
