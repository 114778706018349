const PageTwo = `<table cellpadding="0" cellspacing="0" width="800" style="margin: 0 auto 10px auto; text-align: center; font-family: Arial, sans-serif; height: 100vh; position:relative" class="full-table">
  <tr>
    <td valign="top" style="padding: 20px">
    <h3 style="text-transform: uppercase; text-align: center;">Index</h3>
    <style>
      .full-table {
        width: 100%;
        border-collapse: collapse;
      }
    
      th, td {
        padding: 6px;
      }
      th {
        background: #ddd;
        text-align: left;
      }
    </style>
    <table cellpadding="6" cellspacing="0" width="600" style="margin:0 auto;font-size: 14px">
      <colgroup>
        <col style="width: 10%">
        <col style="width: 60%">
        <col style="width: 30%">
      </colgroup>
      <thead>
        <tr>
          <th>#</th>
          <th>Particulars</th>
          <th>Page No.</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Incident Info</td>
          <td>1</td>
        </tr>
        <tr>
        <td>4</td>
        <td style="text-align: left;">Incident Info</td>
        <td>1</td>
      </tr>
      <tr>
        <td>5</td>
        <td style="text-align: left;">Evidence</td>
        <td>2</td>
      </tr>
      <tr>
        <td>5</td>
        <td style="text-align: left;">Team</td>
        <td>3</td>
      </tr>
       <tr>
        <td>6</td>
        <td style="text-align: left;">Analysis</td>
        <td>3</td>
      </tr>
      <tr>
        <td>7</td>
        <td style="text-align: left;">Summary</td>
        <td>4</td>
      </tr>
      <tr>
        <td>8</td>
        <td style="text-align: left;">Injured Employee Details</td>
        <td>4,5,6</td>
      </tr>
      <tr>
        <td>9</td>
        <td style="text-align: left;">Incident Analysis Question</td>
        <td>7</td>
      </tr>
      <tr>
        <td>10</td>
        <td style="text-align: left;">Witness Details</td>
        <td>8</td>
      </tr>
      <tr>
        <td>11</td>
        <td style="text-align: left;">Paper Digital</td>
        <td>9</td>
      </tr>
      <tr>
        <td>12</td>
        <td style="text-align: left;">Position & Equipments</td>
        <td>9</td>
      </tr>
      <tr>
        <td>13</td>
        <td style="text-align: left;">Physical Info</td>
        <td>10</td>
      </tr>
      <tr>
        <td>14</td>
        <td style="text-align: left;">Sketch</td>
        <td>10</td>
      </tr>
      <tr>
        <td>15</td>
        <td style="text-align: left;">Job Analysis Question</td>
        <td>11</td>
      </tr>
      <tr>
        <td>16</td>
        <td style="text-align: left;">Chronology of Events(Graph)</td>
        <td>12</td>
      </tr>
      <tr>
        <td>17</td>
        <td style="text-align: left;">Chronology of Events(Table)</td>
        <td>13</td>
      </tr>
      <tr>
        <td>18</td>
        <td style="text-align: left;">Probable Cause</td>
        <td>14</td>
      </tr>
      <tr>
        <td>19</td>
        <td style="text-align: left;">Contributing Factor</td>
        <td>15,16</td>
      </tr>
    
        <tr>
          <td>20</td>
          <td>Corrective Action Plan</td>
          <td>17</td>
        </tr>
      </tbody>
    </table>
    <span class="page-number" />
  </td>
</tr>
</table>`;

export default PageTwo;
