import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import { Trash, Copy, Edit } from 'react-feather';
import MetaCheckboxes from "../Shared/MetaCheckboxes";


export default class ShortAnswerPreview extends Component{
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
  }

  // _onDelete = () => { 
  //   this.props.onDelete();
  // }

  render() {
    const data = this.state.data;

    return (
			<div className="box-dynamic">
        <div className="que-display">
          <h5>{data.question}</h5>
          <p>{data.question_description||data.description || ''}</p>
          <div className="que-option">
            <div className="form-group">
              <input type="text" name="value" placeholder="Enter Number" className="form-control" disabled style={{backgroundColor: '#FFFFFF'}} />
            </div>
          </div>
        </div>

        <MetaCheckboxes data={data} type={`field`} />

        <div className="form-dynamic">
          <div className="section-btn">
            {
              this.props.onEdit &&
                <Button className="icon-btn" title="Edit Question" onClick={this.props.onEdit}>
                  <Edit className="icon-style btn-color"/>
                </Button>
            }
            {
              this.props.onCopy &&
              <Button className="icon-btn" title="Copy Question" onClick={this.props.onCopy}>
                <Copy className="icon-style btn-color"/>
              </Button>
            }
            {
              this.props.onDelete &&
              <Button className="icon-btn" title="Delete Question" onClick={this.props.onDelete}>
                <Trash className="icon-style btn-color"/>
              </Button>
            }
          </div>
        </div>
      </div>
    )
  }
}