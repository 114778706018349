export const CHANGE_SERVICE_PROJECT = "CHANGE_SERVICE_PROJECT";
export const CHANGE_TAB = "CHANGE_TAB";
export const CHANGE_SUMMARY_SERVICE_PROJECT = "CHANGE_SUMMARY_SERVICE_PROJECT";
export const ADMIN_CHANGE_SERVICE_PROJECT = "ADMIN_CHANGE_SERVICE_PROJECT";
export const UPDATE_ADMIN_PROJECT_DETAIL = "UPDATE_ADMIN_PROJECT_DETAIL";
export const UPDATE_ADMIN_PROJECT_USER_DETAIL = "UPDATE_ADMIN_PROJECT_USER_DETAIL";
export const UPDATE_COMPANY_PROFILE_DETAIL = "UPDATE_COMPANY_PROFILE_DETAIL";
export const UPDATE_UNLOCK_FORM_COUNT = "UPDATE_UNLOCK_FORM_COUNT";

export const UPDATE_PERMITTED_MODULE_LIST = "UPDATE_PERMITTED_MODULE_LIST"

export const USER_LOGOUT = "USER_LOGOUT";