const CorrectiveActionPlan = (data) => {
  let tempHtml = "";

  try {
    data.correctiveAction.forEach((item, index) => {
      tempHtml += `
        <tr>
          <td style="text-align: center; border: 1px solid #ccc; padding: 8px;">${++index}</td>
          <td style="font-weight: bold; border: 1px solid #ccc; padding: 8px;">${item.category_name}</td>
          <td style="border: 1px solid #ccc; padding: 8px;">${item.recommendation}</td>
          <td style="background: ${item.priority_color || ''}; text-align: center; color: ${item.priority ? '#fff' : '#000'}; font-weight: bold; border: 1px solid #ccc; padding: 8px;">${item.priority}</td>
          <td style="border: 1px solid #ccc; padding: 8px;">${item.target_date}</td>
          <td style="border: 1px solid #ccc; padding: 8px;">${item.responsible_person_list.length ? item.responsible_person_list[0].person_name : 'N/A'}</td>
        </tr>
      `;
    });
  } catch (error) {
    console.log(error);
  }

  return `
    <div style="margin: 20px auto; max-width: 800px; border: 3px double #797878; position: relative; box-sizing: border-box; overflow: hidden;">
      <div style="overflow: auto;">
        <table cellpadding="15" cellspacing="0" width="800%" style="font-family: arial; font-size: 12px;">
          <tr>
            <td valign="top" class="inner-table">
              <h4 style="font-size: 16px; margin-bottom: 5px; font-weight: bold; text-align: center;">Corrective Action Plan - CAPA <span style="font-weight: normal; font-size: 14px;">(Recommendation shown as per priority)</span></h4>
              <table cellpadding="10" cellspacing="0" style="border-collapse: collapse; width: 100%; border: 1px solid #000;">
                <tr style="background: #f5f5f5;">
                  <th style="border: 1px solid #ccc; padding: 8px;">#</th>
                  <th style="text-align: left; border: 1px solid #ccc; padding: 8px;" width="15%">Category</th>
                  <th style="text-align: left; border: 1px solid #ccc; padding: 8px;" width="40%">Recommendation</th>
                  <th style="border: 1px solid #ccc; padding: 8px;">Priority</th>
                  <th style="border: 1px solid #ccc; padding: 8px;">Due Date</th>
                  <th style="border: 1px solid #ccc; padding: 8px;">Assigned To</th>
                </tr>
                ${tempHtml}
              </table>
              <span class="page-number"></span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  `;
};

export default CorrectiveActionPlan;
