import React, { Component } from "react";

export default class AddNumber extends Component{
  render() {
    return (
			<div className="form-dynamic">
        <div className="form-group">
          <input disabled={true} type="email" name="email" placeholder="Enter Number" className="form-control"/>
        </div>
			</div>
    )
  }
}