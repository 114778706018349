import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Trash, Copy, Save } from "react-feather";
import { Field, ErrorMessage } from "formik";
import LowSpace from "app/shared/components/Popups/LowSpace";
import Url from "app/shared/constants/Url";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";
import VideoBox from "app/shared/components/ReusableComponents/VideoPlayer/container";

export default class EditImage extends Component {
  constructor(props) {
    super(props);

    if (this.props.sectionArr !== undefined) {
      var selectedIndex;
      // previous section index
      if (this.props.sectionIndex !== undefined) {
        selectedIndex = this.props.sectionIndex;
      } else {
        selectedIndex =
          this.props.sectionArr.length > 0
            ? this.props.sectionArr.length - 1
            : "";
      }
      var selectedSectionId = this.props?.sectionArr[selectedIndex]?.section_id;

    }

    // editing question current value
    if (this.props.imageData !== undefined) {
      var question =
        this.props.imageData.question !== undefined
          ? this.props.imageData.question
          : "";
      var questionId =
        this.props.imageData.question_id !== undefined
          ? this.props.imageData.question_id
          : "";
      var previewUrl =
        this.props.imageData.file_path !== undefined
          ? this.props.imageData.file_path
          : this.props.imageData.file_link;

      var sId =
        this.props.imageData.section_id !== undefined
          ? this.props.imageData.section_id
          : "";
    }

    this.state = {
      queIndex: this.props.index,
      image: [],
      imagePreviewUrl: previewUrl,
      question: question,
      submitted: false,
      fileFieldError: false,
      order: this.props.order,
      selectedSectionIndex: selectedIndex,
      isCheckLowSpace: false,
      lowStorageMessage: "",
      lowStorageStatus: 200,
      showLowStoragePopup: false,
      isChanged: false,
      type: this.props.imageData.file_type,
      sectionId: selectedSectionId || sId,
      questionId: questionId,
    };
  }

  _handleImageChange = async (e) => {
    debugger
    // e.preventDefault();

    let reader = new FileReader();
    let file = e;

    console.log(file);
    const lowStorage = this.checkLowStorage();
    lowStorage
      .then(async (response) => {
        const { status, msg } = response.data;

        if (status == 200) {
          reader.onloadend = () => {
            this.setState({
              image: file,
              imagePreviewUrl: reader.result,
              fileFieldError: false,
            });
            if (file.type.includes("image")) {
              this.setState({ type: 1 })
            } else {
              this.setState({ type: 2 })
            }
          };
          reader.readAsDataURL(file);
          this.props.form.setFieldValue(`field.file_path`, file);
        }
        await this.setState({
          lowStorageStatus: status,
          lowStorageMessage: msg,
          showLowStoragePopup: status == 200 ? false : true,
          isChanged: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _handleLowSpace = async (statusFlag) => {
    await this.setState({ showLowStoragePopup: false });
  };

  _onHideBlock = () => {
    this.props.hideBlock();
  };

  _getSelectedSection = async (e) => {
    let value = e.target.value;
    await this.setState({ selectedSectionIndex: value });
  };

  _addtoPreview = async () => {
    console.log(this.props);
    this.setState({ submitted: true });

    if (this.state.imagePreviewUrl === "") {
      await this.setState({ fileFieldError: true });
      return;
    }

    var sectionTitle;
    if (this.props.section !== undefined) {
      // Setting dynamic section title based on selected section option from dropdown
      if (this.props.sectionArr !== undefined) {
        var index = this.state.selectedSectionIndex;
        sectionTitle =
          index === "question" ? "" : this.props.sectionArr[index].section_name;
      } else {
        sectionTitle =
          this.props.section !== undefined
            ? this.props.section.section_name
            : "";
      }
    } else {
      sectionTitle = "";
    }
    console.log(this.state.type);
    this.props.editBlock({
      type: "notsection",
      question: this.state.question,
      question_type: 2,
      description: "",
      mandatory: 0,
      notes: 0,
      evidence: 0,
      logic: 0,
      response_type: 0,
      old_section:
        this.props.section !== undefined ? this.props.section.title : "",
      section: sectionTitle,
      questionIndex: this.props.questionIndex,
      order: this.state.order,
      file_path: this.state.imagePreviewUrl,
      file_type: this.state.type,
      field_response: [],
      selectedSectionIndex: this.state.selectedSectionIndex,
      isEditing: false,
      old_question_name: this.state.question,
      section_id: this.state.sectionId,
      question_id: this.state.questionId,
    });
  };

  render() {
    const { name, value: formikValue } = this.props.field;
    const { setFieldValue } = this.props.form;

    return (
      <div
        className="section-dynamic"
        tabIndex="0"
        onBlur={this.props.handleBlur}
      >
        <div className="box-dynamic">
          <div className="form-dynamic">
            <div className="form-group">
              <Field
                type="text"
                name={`field.question`}
                value={this.state.question}
                placeholder="Add Image Title"
                className="form-control"
                onChange={(e) => this.setState({ question: e.target.value })}
              />
            </div>

            <div className="file-upload">
              <MyDropzone type="5" uploadFile={(e) => { this._handleImageChange(e) }} />
            </div>
            {this.state.fileFieldError && this.state.submitted && (
              <div className="inputErrorMsg">Image file is required</div>
            )}
            {!this.state.isChanged &&
              <div className="browse-image" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                {this.state.imagePreviewUrl !== "" &&
                  this.state.type == 1 ?
                  <img
                    style={{ height: 'auto' }}
                    src={this.state.imagePreviewUrl}
                    alt="preview"
                  />
                  :
                  <VideoBox url={this.state.imagePreviewUrl} />
                }
              </div>
            }
            {this.props.sectionArr !== undefined &&
              this.props.sectionArr.length > 0 && (
                <Row>
                  <Col sm={4}>
                    <div>
                      <select
                        onChange={this._getSelectedSection}
                        className="form-control"
                        value={this.state.selectedSectionIndex}
                      >
                        <option value="question" key="sectionOption-question">
                          Question
                        </option>
                        {this.props.sectionArr.map((item, index) => (
                          <option value={index} key={`sectionOption-${index}`}>
                            {item.section_name === undefined
                              ? item.title
                              : item.section_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              )}
            <div className="section-btn">
              <Button
                className="icon-btn"
                onClick={() => this.props.onDelete()}
              >
                <Trash className="icon-style" />
              </Button>
              <Button className="icon-btn" onClick={() => this._addtoPreview()}>
                <Save className="icon-style" />
              </Button>
            </div>
          </div>
        </div>
        {this.state.showLowStoragePopup ? (
          <LowSpace
            message={this.state.lowStorageMessage}
            handleLowSpace={this._handleLowSpace}
          />
        ) : null}
      </div>
    );
  }
}
