import React, { Component } from "react";
import { Modal } from 'react-bootstrap';

export default class CompletePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompletePopup: false
    };
    this._closeModal = this._closeModal.bind(this);
  }

  _closeModal() {
    this.props.onCancel();
  }

  _confirm() {
    this.props.onConfirm();
  }

  render() {
    return (
      <div>
        <Modal centered show={this.props.showCompletePopup} className="submit-modal" onHide={this._closeModal}>
          <Modal.Header ></Modal.Header>
          <Modal.Body>
            <img className="submit-img" src={require('assets/images/delete-popup.svg')} />
            <p className="submit-text">Are you sure you want to Complete this goal? </p>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={this._closeModal}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="red-btn"
                onClick={() => this._confirm()}
              >
                YES, Complete
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
