
// const PageThree = (data) => {
//   return `
//     <div style="margin: 20px auto; width: 800px; height: 100vh; border: 3px double #797878; position: relative; box-sizing: border-box; overflow: hidden;">
//       <div style="height: 100%; overflow: auto;">
//         <table cellpadding="15" cellspacing="0" width="100%" style="font-family: arial; font-size: 14px;">
//           <tr>
//             <td valign="top" class="inner-table">
//               <table width="100%">
//                 <tr>
//                   <td valign="middle" colspan="3" style="height:100px; text-align: center;">
//                     <h3 style="text-transform: uppercase; font-size: 18px; font-weight: bold;">NM Investigation Report</h3>
//                   </td>
//                 </tr>
//               </table>
//               <span style="font-size: 16px; margin-bottom: 5px; font-weight: bold;">Activity : </span><span style="font-size: 16px">${data.subject}</span>    
//               <br />
//               <br />
//               <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Activity Info</h4>
//                   <table cellpadding="6" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%">
//                     <tr>
//                       <td style="text-align: center;">1</td>
//                       <td>Service</td>
//                       <td>${data.activity_info?.service || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td style="text-align: center;">2</td>
//                       <td>Project</td>
//                       <td>${data.activity_info?.project || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td style="text-align: center;">3</td>
//                       <td>Department</td>
//                       <td>${data.activity_info?.department || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td style="text-align: center;">4</td>
//                       <td>Contractor</td>
//                       <td>${data.activity_info?.contractor || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td style="text-align: center;">5</td>
//                       <td>Zone</td>
//                       <td>${data.activity_info?.zone || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td style="text-align: center;">6</td>
//                       <td >Location</td>
//                       <td>${data.activity_info?.location || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td style="text-align: center;">7</td>
//                       <td>Incident Date</td>
//                       <td>${data.activity_info?.incident_date || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td style="text-align: center;">8</td>
//                       <td>Incident Time</td>
//                       <td>${data.activity_info?.incident_time || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td style="text-align: center;">9</td>
//                       <td>Target Date</td>
//                       <td>${data.activity_info?.target_date || 'N/A'}</td>
//                     </tr>
//                   </table>
//                   <br />
//                   <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Reporting Info</h4>
//                   <table cellpadding="6" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%">
//                     <tr>
//                       <td style="text-align: center;">1</td>
//                       <td>Reported by</td>
//                       <td>${data.reporting_info?.reported_by || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td style="text-align: center;">2</td>
//                       <td>Reported Date</td>
//                       <td>${data.reporting_info?.reported_date || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td style="text-align: center;">3</td>
//                       <td>Assign to</td>
//                       <td>${data.reporting_info?.assign_to || 'N/A'}</td>
//                     </tr>
//                   </table>
//                   <br />
//               <span style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Observation</span>  <span style="color: #000;font-weight: normal;font-size: 15px">(Incident Description)</span>
//               <hr />
//               <p style="margin-top: 0;line-height: 20px">${data.observation || ''}</p>
//               <span class="page-number" />
//             </td>
//           </tr>
//         </table>
//       </div>
//     </div>
//   `;
// };

// export default PageThree;

const PageThree = (data) => {
  return `
    <div style="margin: 20px auto; width: 800px; height: 98vh; border: 3px double #797878; position: relative; box-sizing: border-box; overflow: hidden;">
      <div style="height: 100%;">
        <table cellpadding="15" cellspacing="0" width="100%" style="font-family: arial; font-size: 14px;">
          <tr>
            <td valign="top" class="inner-table">
              <table width="100%" style="margin-bottom: 10px;">
                <tr>
                  <td valign="middle" colspan="3" style="height: 80px; text-align: center;">
                    <h3 style="text-transform: uppercase; font-size: 18px; font-weight: bold; margin: 0;">NM Investigation Report</h3>
                  </td>
                </tr>
              </table>
              <span style="font-size: 16px; margin-bottom: 5px; font-weight: bold;">Activity: </span><span style="font-size: 16px;">${data.subject || 'N/A'}</span>
              <br />
              <br />
             
              <table cellpadding="6" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%">
                                  <tr>
                                    <td style="text-align: center;">1</td>
                                    <td>Service</td>
                                    <td>${data.activity_info?.service || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: center;">2</td>
                                    <td>Project</td>
                                    <td>${data.activity_info?.project || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: center;">3</td>
                                    <td>Department</td>
                                    <td>${data.activity_info?.department || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: center;">4</td>
                                    <td>Contractor</td>
                                    <td>${data.activity_info?.contractor || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: center;">5</td>
                                    <td>Zone</td>
                                    <td>${data.activity_info?.zone || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: center;">6</td>
                                    <td >Location</td>
                                    <td>${data.activity_info?.location || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: center;">7</td>
                                    <td>Incident Date</td>
                                    <td>${data.activity_info?.incident_date || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: center;">8</td>
                                    <td>Incident Time</td>
                                    <td>${data.activity_info?.incident_time || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: center;">9</td>
                                    <td>Target Date</td>
                                    <td>${data.activity_info?.target_date || 'N/A'}</td>
                                  </tr>
                                </table>
                                <br />
                                <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Reporting Info</h4>
                                <table cellpadding="6" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%">
                                  <tr>
                                    <td style="text-align: center;">1</td>
                                    <td>Reported by</td>
                                    <td>${data.reporting_info?.reported_by || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: center;">2</td>
                                    <td>Reported Date</td>
                                    <td>${data.reporting_info?.reported_date || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: center;">3</td>
                                    <td>Assign to</td>
                                    <td>${data.reporting_info?.assign_to || 'N/A'}</td>
                                  </tr>
                                </table>
                                <br />
                            <span style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Observation</span>  <span style="color: #000;font-weight: normal;font-size: 15px">(Incident Description)</span>
                            <hr />
                            <p style="margin-top: 0;line-height: 20px">${data.observation || ''}</p>
                            <span class="page-number" />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                `;
              };
              
              export default PageThree;
              