import React from "react";
import { integerRegex } from "../constants/regex";
import { OverlayTrigger, Popover, Container } from "react-bootstrap";
import { Popover as SLDPopver } from "@salesforce/design-system-react";
import TooltipIcon from "assets/tooltipIcon.svg";
export function isIntegerNumber(value = 0) {
  if (integerRegex.test(value)) {
    return true;
  } else {
    return false;
  }
}

export function getIntegeOnly(event) {
  const { value } = event?.target;
  let finalString = "";
  if (value === "-") {
    finalString = "";
  }
  if (value === "0" || value === "" || isIntegerNumber(value)) {
    finalString = value;
  }
  return finalString;
}

export function renderToolTip(
  content = "",
  placement = "right",
  toolTipStyle = { width: "15px" },
  type = 1,
  title = "Info"
) {
  if (type === 2) {
    return (
      <SLDPopver
        body={content}
        heading={title}
        align={placement}
        id="popover-heading"
        position="overflowBoundaryElement"
      >
        <img
          className="cursor-pointer ml-1"
          src={TooltipIcon}
          alt="info"
          style={toolTipStyle}
        />
      </SLDPopver>
    );
  }
  if (type === 1) {
    return (
      <OverlayTrigger
        trigger="hover"
        placement={placement}
        rootClose={true}
        overlay={
          <Popover
            id="popover-positioned-bottom"
            style={{ width: "13vw", borderRadius: "10px", minHeight: "1.5vw" }}
          >
            <Container>
              <h2 className="font-weight-500 font-16 my-2 "> {content}</h2>
            </Container>
          </Popover>
        }
      >
        <img
          className="cursor-pointer ml-1"
          src={TooltipIcon}
          alt="info"
          style={toolTipStyle}
        />
      </OverlayTrigger>
    );
  }
}
