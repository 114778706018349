import React, { Component } from "react";
import { Button, Row, Col } from 'react-bootstrap';
import { Trash, Save } from 'react-feather';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export default class AddField extends Component{
  constructor(props) {
    super(props);

    this.state = {
      selectedQueTypeIndex: this.props.selectedQueType || 0,
      textEditorData : null,
    }
  }
  _onHideBlock = () => { 
    this.props.hideBlock();
  }

  handleTextEditorChange = async (event, editor) => {
    const data = editor.getData();
    await this.setState((prevState) => {
      let { textEditorData } = prevState;
      textEditorData = data;
      return { textEditorData };
    });
  };

  _addtoPreview = async () => {

  }


  render() {

      return (
        <div className="box-dynamic" ref={node => this.node = node}>
          <Row>
            <Col sm={11}>
              <div className="dynamic-section">
                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={this.handleTextEditorChange}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }} 
                />
              </div>
            </Col>
            <Col sm={1}>
              <div className="box-dynamic-right"></div>
              <div className="box-dynamic-bottom">
                <Button className="icon-btn" onClick={() => this._onHideBlock()}>
                  <Trash className="icon-style"/>
                </Button>
                <Button s
                  className="icon-btn" 
                  onClick={() => this.props.addText(this.state.textEditorData)}
                >
                  <Save className="icon-style"/>
                </Button>
              </div>
            </Col>
            </Row>
        </div>
      )
  }
}
