//libraries
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";

export const DateTableComponent = ({
  data = [],
  columns = [],
  bodyRendrer,
  scrollProps = {},
  customHeader = false,
  defaultHeight = `calc(100vh - 25vw)`,
  isLoading = false,
  loadingBlockRowLength = 5,
  loadingBlockColumnLength = 8,
  containerClass = ""
  
}) => {
  return (
    <div className="table-responsive">
      <Scrollbars style={{ height: defaultHeight }} {...scrollProps}>
        <table className={containerClass !== "" ? containerClass : "data-table-wrapper table table-striped" }>
          <thead>
            <tr>
              {customHeader !== false
                ? customHeader
                : columns?.map((el, idx) => (
                    <th scope="col" key={idx}>
                      {el}
                    </th>
                  ))}
            </tr>
          </thead>

          {data?.length ? (
            <tbody>
              {data?.map((el, idx, arr) => bodyRendrer(el, idx, arr))}
            </tbody>
          ) : (
            <>
              {!isLoading ? (
                <div className=" my-3 w-100">
                  <span className="text-center my-2 mr-3">No Data Found</span>
                </div>
              ) : null}
            </>
          )}
        </table>
        {isLoading ? (
          <>
            {[...Array(loadingBlockRowLength)]?.map((_) => (
              <div className="d-flex common-flex-gap-1">
                {[...Array(loadingBlockColumnLength)]?.map((_) => (
                  <div className="skelton-wrapper d-flex-center">
                    <Skeleton count={1} height={20} width={150} />{" "}
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : null}
      </Scrollbars>
    </div>
  );
};
