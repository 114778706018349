import React from "react";

const PageNotFound = () => {
  return (
    <div className="page-not-found" style={{ position: "absolute" }}>
      <div className="inner-text">
        {/* <h1>404</h1> */}
        <img className="submit-img img-fluid" src={require('assets/images/404.svg')} />
        <h2>Page Not Found</h2>
      </div>
    </div>
  );
};

export default PageNotFound;
