import React from 'react';
import LoaderImage from "../../../../assets/images/loader1.gif";

const LoadingImage = () => (
    <>

        <img className="loader-img"
            src={LoaderImage}
            alt={`loader`} />
    </>
);

export default LoadingImage;