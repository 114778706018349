//libraires
import React from "react";
//components
import NMDashboardV2 from "./modules/dashboard";
import CapaListNMV2 from "./modules/capa";
import TabsV2 from "app/shared/ui-kit/tabs";
//constants
import { nmContent } from "./constants/nmConstant";
//styles
import "./styles/nmStyles.scss";

class NMModuleV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: nmContent.dasboardTabs[0],
    };
  }

  
  render() {
    const {key = ""} = this.state.current ?? {}
    return (
      <div className="ps-5 pe-5 clide-v2-pe-5 clide-v2-ps-5 clide-v2-colume_title">
        <div className="mb-4">Near Miss</div>
        <div className="mb-3">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-7">
              <TabsV2
                tabs={nmContent.dasboardTabs}
                currentItem={this.state.current}
                handleChange={(key) => this.setState({ current: key })}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <div className="row">
                <div className="col-12 col-md-2">
                  <div className="d-flex">
                    <div className="clide-v2-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={25}
                        height={25}
                        fill="none"
                        viewBox="0 0 25 25"
                      >
                        <path
                          fill="#F56620"
                          d="M3.2 5.6a.8.8 0 01.8-.8h.8a.8.8 0 010 1.6H4a.8.8 0 01-.8-.8zm4 0a.8.8 0 01.8-.8h7.2a.8.8 0 110 1.6H8a.8.8 0 01-.8-.8zm-4 4a.8.8 0 01.8-.8h.8a.8.8 0 010 1.6H4a.8.8 0 01-.8-.8zm4 0a.8.8 0 01.8-.8h7.2a.8.8 0 110 1.6H8a.8.8 0 01-.8-.8zm-4 4a.8.8 0 01.8-.8h.8a.8.8 0 010 1.6H4a.8.8 0 01-.8-.8zm4 0a.8.8 0 01.8-.8h7.2a.8.8 0 110 1.6H8a.8.8 0 01-.8-.8z"
                        />
                      </svg>
                    </div>
                    <div className="clide-v2-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={25}
                        height={25}
                        fill="none"
                        viewBox="0 0 25 25"
                      >
                        <path
                          fill="#F56620"
                          d="M2.287 2.301L12.343.865a.4.4 0 01.457.396v16.678a.4.4 0 01-.456.395L2.286 16.898a.8.8 0 01-.687-.792V3.093a.8.8 0 01.688-.792zM13.6 2.4h3.2a.8.8 0 01.8.8V16a.8.8 0 01-.8.8h-3.2V2.4zM8.16 9.6l2.24-3.2H8.48L7.2 8.229l-1.28-1.83H4l2.24 3.2L4 12.8h1.92l1.28-1.828L8.48 12.8h1.92L8.16 9.6z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {key === "dashboard" && <NMDashboardV2 />
        }
        {key === "capa" && <CapaListNMV2 {...this.props?.capaProps} state={this.props?.state} />
        }
        {key === "list" && <NMDashboardV2 />
        }
        
      </div>
    );
  }
}

export default NMModuleV2;
