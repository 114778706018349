import React, { Component } from "react";
import { Button, Modal } from 'react-bootstrap';
import Url from 'app/shared/constants/Url';
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import { Scrollbars } from 'react-custom-scrollbars';
import CrownIcon from "assets/images/icons/Crown .svg"
const rfdc = require('rfdc')();


export default class AssignUser extends Component{
  constructor(props){
    super(props);
    this.state = {
      showModal: this.props.showModal,
      userList : [],
      teamLeader : [],
      dataLoading : true,
      searchUserName : "",
      searchBtnClicked : false,
      searchUserList : []
    };
  }

  componentDidMount() {
    this._getUserList();
  }

  _getUserList = () => {

    let URL = Url.formType1.assignUserList;
		var ContentType = {
      "Content-Type" : "application/x-www-form-urlencoded"
    };

		import("app/shared/utility/UtilFunction").then( utilFunction => {

			let responseObj = utilFunction.sharedAxiosAPI(URL, ContentType);

			responseObj.then( async(response) => {
				if ( response.data.status === 200 ) {
					await this.setState( prevState => {
						let { userList, teamLeader } = prevState;
            userList = rfdc(response.data.data.assign_user);
            
            // in API for unassigned teamlead object is returning with id only  
            if ( response.data.data.teamLeader && response.data.data.teamLeader.name !== undefined ) {
              teamLeader.push(rfdc(response.data.data.teamLeader));
            }
						return { userList, teamLeader };
					});
        }
        await this.setState( prevState => {
          let {dataLoading} = prevState;
          dataLoading = false;
          return {dataLoading}
        });
			})
			.catch( async(error) => {
				await this.setState( prevState => {
          let {dataLoading} = prevState;
          dataLoading = false;
          return {dataLoading}
        });
			});	
		});
  }

  _handleAssignUser = ( userId ) => {

		var ContentType = {
      "Content-Type" : "application/x-www-form-urlencoded"
    };

    let extraData = {
      selecteduser_id : userId
    };

    import("app/shared/utility/UtilFunction").then( utilFunction => {

			let responseObj = utilFunction.sharedAxiosAPI(Url.formType1.assignUser, ContentType, extraData);

			responseObj.then( async(response) => {
				if ( response.data.status === 200 ) {
          
          await this.setState( prevState => {
            let { teamLeader, userList, searchBtnClicked } = prevState;

            searchBtnClicked = false;
            let userIndex = userList.findIndex( item => item.id === userId );
            let currentTeamLeader = rfdc( teamLeader[0] );
            teamLeader = [];
            teamLeader.push(rfdc(userList[userIndex]));
            userList.splice(userIndex, 1);
            if ( currentTeamLeader !== undefined ) {
              userList.push(currentTeamLeader);
            }
            return { teamLeader, userList, searchBtnClicked }
          });
          
          AlertMessage({message : "Assigned Successfully", type : "success"});
        }
			})
			.catch( async(error) => {
				
			});
		});
  }

  _handleUnAssignUser = () => {

    import("app/shared/utility/UtilFunction").then( utilFunction => {

			let responseObj = utilFunction.sharedAxiosAPI(Url.formType1.unassignUser);

			responseObj.then( async(response) => {

				if ( response.data.status === 200 ) {
          await this.setState( prevState => {
            let { userList, teamLeader, searchBtnClicked } = prevState;
            searchBtnClicked = false;
            userList.push(teamLeader[0]);
            teamLeader = [];
            return { userList, teamLeader, searchBtnClicked };
          });          
          AlertMessage({message : "Unassigned Successfully", type : "success"});
        }

			})
			.catch( async(error) => {
				
			});
		});
  }

  _handleInputChange = async( event ) => {

    const val = event.target.value;

    await this.setState( prevState => {

      let { userList, searchUserName, searchBtnClicked, searchUserList } = prevState;
      searchUserName = val;
      searchBtnClicked = true;
      searchUserList = userList.filter( user => {
        if ( user.name !== undefined ) {
          if ( user.name.toLowerCase().includes( searchUserName.toLowerCase() ) ) {
            return user;
          }
        }
      });
      return { searchUserName, searchBtnClicked, searchUserList  };
    });
  }

  _handleUserSearch = async() => {
    await this.setState( prevState => {
      let { userList, searchUserName, searchBtnClicked, searchUserList } = prevState;
      searchBtnClicked = true;
      searchUserList = userList.filter( user => {
        if ( user.name !== undefined ) {
          if ( user.name.toLowerCase().includes( searchUserName.toLowerCase() ) ) {
            return user;
          }
        }
      });
      return { searchBtnClicked, searchUserList };
    });
  }
 
  render() {
    return (
      <div className="report-section">
        <Modal
          centered
          show={this.state.showModal}
          className="assign-user-modal"
          onHide={this.props.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assign User</Modal.Title>
            <Button variant="outline-primary" className="modal-btn" onClick={this.props.closeModal}>DONE</Button>
          </Modal.Header>

          <Modal.Body>
            <div className="team-lead-box">
              <div className="team-lead-img">
                <img className="team-lead" src={require('assets/sls-icons/Crown/new_opportunity.svg')} alt=""/>
              </div>
              <div className="team-lead-text">
                <p>User Assigned will get following rights for audit</p>
                <p>1 Create, 2 Delete, 3 Assign, 4 Publish, 5 Choose from Marketplace</p>
              </div>
            </div>
            <div className="active-team-box">
              <div className="rd-left-bottom invite-user-section">
                {
                  this.state.teamLeader.length > 0 && 
                    <div className="user-list">
                      <img src={this.state.teamLeader[0].pic} alt=""/>
                      <h6 className="user-name"> {this.state.teamLeader[0].name}</h6>
                      <img className="team-lead" src={require('assets/sls-icons/Crown/new_opportunity.svg')} alt="assign user"/>
                      <Button className="add-btn" onClick={this._handleUnAssignUser}>
                        <i className="fa fa-trash plus-icon" aria-hidden="true"></i>
                      </Button>
                    </div>
                }
              </div>
            </div>            
            <div className="search-section">
              <input type="text" placeholder="Search.." name="search" value={this.state.searchUserName} onChange={(e) => this._handleInputChange(e)}/>

              <button type="button" onClick={this._handleUserSearch}>
                <i className="fa fa-search"></i>
              </button>

            </div>
            <Scrollbars style={{height : `15vw`}}
            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
            >
              <div className="rd-left-bottom invite-user-section">
                {
                  !this.state.searchBtnClicked && this.state.userList.length > 0 && this.state.userList.map( userInfo => (
                    <div className="user-list" key={userInfo.id}>
                      <img src={userInfo.pic} alt=""/>
                      <h6 className="user-name"> {userInfo.name}</h6>
                      <Button className="add-btn" onClick={() => this._handleAssignUser(userInfo.id)}>
                        <img className="team-lead" src={require('assets/images/team-lead.png')} alt=""/>
                      </Button>
                    </div>
                  ))
                }
                {
                  this.state.searchBtnClicked && this.state.searchUserList.length > 0 && this.state.searchUserList.map( userInfo => (
                    <div className="user-list" key={userInfo.id}>
                      <img src={userInfo.pic} alt=""/>
                      <h6 className="user-name"> {userInfo.name}</h6>
                      <Button className="add-btn" onClick={() => this._handleAssignUser(userInfo.id)}>
                        <img className="team-lead" src={require('assets/images/team-lead.png')} alt=""/>
                      </Button>
                    </div>
                  ))
                }
              </div>
            </Scrollbars>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}