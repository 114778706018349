import React, { Component } from "react";
import { Modal, Button, Col } from "react-bootstrap";
import Url from "app/shared/constants/Url";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import { connect } from "react-redux";
import CommonDropdown from "../ReportModal/CommonDropdown";
//import MyDropzone from "./Dropzone";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";
import axios from "axios";
import { urlBuilder } from "app/shared/utility/UtilFunction";
import { SuccessToast } from "app/shared/components/Popups/ToastMessage";
import { requiredMessage } from "app/shared/constants/Messages";

const mapStateToProps = (state) => {
  const data = [...state.permitModule.permittedModuleList];
  return {
    permittedModuleList: data,
  };
};

class TaskDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      typeList: [
        { type_id: 1, document: "Do's & Dont's" },
        { type_id: 2, document: "Training" },
        { type_id: 3, document: "Policy" },
        { type_id: 4, document: "MSDS" },
        { type_id: 7, document: "Standard" },
      ],
      documentName: "",
      selectedFile: "",
      link: "",
      taskId: this.props.taskId,
      module: this.props.module,
      docType: this.props.docType,
      linkError: false,
      selectedFileError: false,
    };
  }

  componentDidMount = async () => {

    console.log(window.location.pathname)
   };

   
  _uploadDocument = async () => {
    await this._formValidation();

    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          formErrorStatus = true;
          return;
        } else {
          formErrorStatus = false;
        }
      }
    }
    //  If form not have error then submit form
    if (!formErrorStatus) {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      await this.setState({ dataLoading: true });

      this._setDataLoading(true);

      let uploadData = new FormData();
      uploadData.append("auth_key", token);
      uploadData.append("user_id", userId);
      var type = this.state.link?.length > 0 ? 1 : 2;
      uploadData.append("type", type);
      uploadData.append("task_id", this.state.taskId || 0);
      uploadData.append("module", this.state.module);

      if (type == 1) {
        uploadData.append("file", urlBuilder(this.state.link ?? ""));
      } else {
        uploadData.append("file", this.state.selectedFile || "");
      }

      var ContentType = {
        "Content-Type": "multipart/form-data",
      };

      let URL = Url.taskManagement.taskAddDocument;

      await axios
        .post(URL, uploadData, ContentType)
        .then(async (response) => {
          if(response?.data?.status == 200) {
            this._setDataLoading(false);
            SuccessToast("Document Uploaded Successfully!");
            this.props.handleClose();
            // this.props.history.push(`${window.location.pathname}/#${this.props.docType}`)
            const isCallBackEnabled =  this.props?.haveCallback ?? false;
            if(isCallBackEnabled){
                this.props.callback();
                return
            }
            window.location.reload();
            return ;
          }
          
        })
        .catch((error) => {
          this._setDataLoading(false);
          SuccessToast("Document can not be Uploaded. Try Again!");

          console.log(error);
        });
    }
  };

  _formValidation = async () => {
    const tempObj = this.state;

    if (tempObj.docType == 1) {
      await this.setState((prevState) => {
        prevState.linkError = tempObj.link ? false : true;

        return prevState;
      });
    } else {
      await this.setState((prevState) => {
        prevState.selectedFileError = tempObj.selectedFile ? false : true;

        return prevState;
      });
    }
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _typeChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedType } = prevState;
      selectedType = id;
      return { selectedType };
    });
  };

  _uploadFile = async (file) => {
    console.log(file);
    await this.setState((prevState) => {
      let { selectedFile } = prevState;
      selectedFile = file;
      return { selectedFile };
    });
  };

  render() {
    return (
      <div>
        <Modal
          show={true}
          onHide={this.props.handleClose}
          className="documentPopup"
          backdrop="static"
          keyboard={false}
        // style={{ width: "20vw" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload a Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Data Loading  */}
            <div
              className="loader"
              style={{ display: this.state.dataLoading ? "block" : "none" }}
            >
              <LoadingImage />
            </div>

            {this.state.docType == 1 ? (
              <div className="form-group">
                <label className="form-label">Link/URL</label>
                <input
                  className="form-control"
                  //type={this.state.selectedType}
                  value={this.state.link}
                  onChange={(e) =>
                    this.setState({
                      link: e.target.value,
                    })
                  }
                  placeholder={"Enter Link"}
                />
                {!!this.state.linkError && (
                  <p className="inputErrorMsg">{requiredMessage}</p>
                )}
              </div>
            ) : null}

            {this.state.docType == 0 ? (
              <>
                <MyDropzone type={8} uploadFile={this._uploadFile} />
                {!!this.state.selectedFileError && (
                  <p className="inputErrorMsg">{requiredMessage}</p>
                )}
              </>
            ) : null}

            <div className="publish-btn" style={{ marginTop: "1vw" }}>
              <Button
                variant="primary"
                onClick={this._uploadDocument}
                className="red-btn"
              >
                UPLOAD
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(TaskDocumentModal);
