const PageFour = (data) => {
  let tempEvidence = "";

  try {
    if (data?.evidence?.length > 0) {
      data.evidence.forEach((item, index) => {
        if (item.evidence_format == "image") {
          if ((index + 1) % 2 === 1) {
            tempEvidence += "<tr>";
          }
          tempEvidence += `
            <td valign="top" style="text-align: center; padding: 5px;">
              <img src="${item.evidence_image}" style="width: 232px; height: 150px; border: 3px solid #fff; box-shadow: 0 0 5px #ddd;">
            </td>`;
          if ((index + 1) % 2 === 0) {
            tempEvidence += "</tr>";
          }
        }
      });
    }

    return `
      <div style="margin: 20px auto; width: 800px; height: 98vh; border: 3px double #797878; position: relative; box-sizing: border-box; overflow: hidden;">
        <div style="height: calc(98vh - 60px);">
          <table cellpadding="15" cellspacing="0" width="100%" style="font-family: Arial; font-size: 12px;">
            <tr>
              <td colspan="2" valign="top" class="inner-table">
                <br />
                <br />
                <h4 style="font-size: 14px; margin-bottom: 5px; font-weight: bold; text-align: center;">Incident Evidences</h4>
                <hr />
                <table width="100%" style="text-align: center;" cellpadding="10">
                  ${tempEvidence}      
                </table>
                <span class="page-number" />
              </td>
            </tr>
          </table>
        </div>
      </div>`;
  } catch (error) {
    console.log(error);
    return "";
  }
};

export default PageFour;
