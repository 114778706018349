const PageEight = (data) => {
  let tempHtml = "";

  try {
    data.incidence_analysis_question.forEach((item, index) => {
      tempHtml += `
        <tr style="border-bottom: 1px solid #ccc;">
          <td style="text-align: center; padding: 8px; border-right: 1px solid #ccc;">${++index}</td>
          <td style="padding: 8px; border-right: 1px solid #ccc;">${item.question || "N/A"}</td>
          <td style="padding: 8px;">${item.response || "N/A"}</td>
        </tr>
      `;
    });
  } catch (error) {}

  return `
    <div style="margin: 20px auto; width: 800px; height: 98vh; border: 7px double #797878; position: relative; box-sizing: border-box; overflow: hidden;">
      <div style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div style=" padding: 10px; width: 100%;">
          <h4 style="font-size: 16px; margin-bottom: 5px; font-weight: bold; text-align: center; color: black;">Incident Analysis Question</h4>
        </div>
        <div style="height: calc(98vh - 106px); overflow: auto; width: 100%;">
          <table cellpadding="10" cellspacing="0" style="border-collapse: collapse; width: 100%; border: 1px solid #ccc;">
            <tr style=" color: white;">
              <th style="text-align: center; padding: 8px; border-right: 1px solid #ccc; color:black;">#</th>
              <th style="text-align: left; padding: 8px; border-right: 1px solid #ccc; color:black; ">Question</th>
              <th style="text-align: left; padding: 8px; color:black;">Answer</th>
            </tr>
            ${tempHtml}
          </table>
        </div>
        <div style="text-align: center; position: absolute; bottom: 10px; width: 100%;">
          <span class="page-number"></span>
        </div>
      </div>
    </div>
  `;
};

export default PageEight;
