/* eslint-disable jsx-a11y/alt-text */
import React, { useState, Component, PureComponent } from "react";
import _ from "lodash";
import {
  Button,
  Row,
  Col,
  Modal,
  Accordion,
  Nav,
  Badge,
} from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { toast } from "react-toastify";
import Url from "app/shared/constants/Url";
import axios from "axios";
import "react-vertical-timeline-component/style.min.css";
import moment from "moment";
import { requiredMessage } from "app/shared/constants/Messages";
import LoadingView from "app/shared/components/Loader/LoadingView";
import DatePicker from "react-datepicker";
import {
  Calendar,
  ChevronDown,
  ChevronRight,
  Send,
  File,
  Eye,
  ThumbsUp,
  CornerUpLeft,
  Trash2,
  Link,
  ChevronLeft,
  FileText,
  Edit,
} from "react-feather";
import CommonDropdown from "app/shared/components/ReportModal/CommonDropdown";
import { Scrollbars } from "react-custom-scrollbars";
import ServiceList from "app/shared/components/ReportModal/ServiceList";
import ProjectList from "app/shared/components/ReportModal/ProjectList";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import CloseModal from "../../shared/components/Goal/CloseModal";
import { Save } from "react-feather";
import { RiFileUploadLine } from "react-icons/ri";
import TaskDocumentModal from "app/shared/components/CreateTask/TaskDocumentModal";
import FsLightbox from "fslightbox-react";
import VideoPlayer from "simple-react-video-thumbnail";
import FullViewModal from "app/shared/utility/FullViewModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { SuccessToast } from "app/shared/components/Popups/ToastMessage";
import { TimeLineComponent } from "app/shared/components/Goal/Goal";
import {
  convertDateFromTimezone,
  convertDateToUTC,
} from "app/shared/utility/tzUtilFunction";

import { VCTimelineComponent } from "app/shared/components/Goal/Goal";

const qs = require("qs");
const tabs = [
  {
    name: "Discuss",
    key: "discuss",
  },
  {
    name: "Links",
    key: "link",
  },
  {
    name: "Doc",
    key: "doc",
  },
];

let debounceFunc = _.debounce(function (callback) {
  callback()
}, 300);
class TaskDetail extends PureComponent {
  constructor(props) {
    super(props);
    var date = new Date();
    this.state = {
      show: false,
      showUser: false,
      taskDetail: [],
      taskId: this.props.match.params.id,
      module: this.props.match.params.module,
      props:this.props,
      dataLoading: true,
      currentTab: "discuss",
      accordionOpen: true,
      lightboxactive: false,
      priorityList: [
        { id: 1, priority: "Normal", priority_color: "#E7C76E" },
        { id: 2, priority: "Important", priority_color: "#93B7F2" },
        { id: 3, priority: "Urgent", priority_color: "#E6EE42" },
        { id: 4, priority: "Critical", priority_color: "#E68895" },
      ],
      isEdit: false,
      selectedStartDate: date,
      maxDatepickerDate: date,
      selectedTargetDate: date,
      userData: JSON.parse(localStorage.getItem("userData")),
      userList: [],
      task: null,
      taskError: false,
      taskDescription: null,
      taskDescriptionError: false,
      selectedPriority: null,
      priorityError: false,
      selectedServiceId: null,
      selectedProjectId: null,
      userInfo: JSON.parse(localStorage.getItem("userData")) || null,
      responsiblePerson: null,
      responsiblePersonError: false,
      selectedCategory: null,
      selectedCategoryError: false,
      categoryList: [],
      selectedServiceId: null,
      selectedProjectId: null,
      projectList: [],
      statusList: [
        // { type_id: 0, type: "Schedule" },
        { type_id: 1, type: "To do" },
        { type_id: 2, type: "Inprogress" },
        { type_id: 3, type: "Complete" },
      ],
      showCloseModal: false,
      closeData: {},
      subcommentlist: [],
      comment: 0,
      commentAdd: "",
      commentlist: [],
      allComments: false,
      task: {},
      taskDocument: [],
      fsToggler: false,
      toggler: false,
      type: null,
      typeList: [
        { type_id: 1, form_type: "Data Collection" },
        { type_id: 2, form_type: "Audit" },
        { type_id: 3, form_type: "Inspection" },
        { type_id: 4, form_type: "Checklist" },
        { type_id: 5, form_type: "Work Permit" },
        { type_id: 6, form_type: "PSSR" },
        { type_id: 7, form_type: "BBS" },
        { type_id: 8, form_type: "Survey" },
        { type_id: 9, form_type: "Mock Drill" },
        { type_id: 10, form_type: "Contractor Assessment" },
        { type_id: 11, form_type: "Feedback" },
        { type_id: 12, form_type: "E-Learning" },
        { type_id: 13, form_type: "Assessment" },
      ],
      imageType: null,
      pdfURL: "",
      showPDFView: false,
      status: "",
      creationDate: new Date(),
      documentList: [],
      docType: 0,
      selectedstatus: "",
      userInfo: JSON.parse(localStorage.getItem("userData")) || null,
      commentError: false,
      replycommentAdd: "",
      overdue: false,
    };
  }

  componentDidMount() {
    this._taskDetail();
    this.CommentList();
    if (
      !(
        this.state.userInfo.designation_flag == 1 ||
        this.state.userInfo.designation_flag == 3
      )
    ) {
      this._fetchUserServiceProject();
    } else {
      this._fetchServiceList();
    }

    this._fetchCategoryList();
  }

  _momentDate = (stringDate) =>
    moment(stringDate, "YYYY-MM-DD HH:mm:ss").toDate();

  async _taskDetail() {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var datailUrl = Url.taskManagement.task;

    await axios
      .post(
        datailUrl,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          task_id: this.state.taskId,
          module: this.state.module,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          let {
            task,
            task_description,
            status,
            target_date,
            category,
            responsible_person,
            start_date,
            priority,
            service_id,
            project_id,
            creation_time,
          } = response.data.data.task;
          let { file } = response.data.data.task.document;
          this.setState({
            taskDetail: response.data.data.task,
            task: task,
            taskDescription: task_description,
            selectedStartDate: this._momentDate(start_date),
            selectedTargetDate: this._momentDate(target_date),
            selectedCategory: category,
            responsiblePerson: responsible_person,
            selectedPriority: priority,
            selectedServiceId: service_id,
            selectedProjectId: project_id,
            taskDocument: response.data.data.task,
            selectedstatus: response.data.data.task.status,
            creationDate: new Date(creation_time),
            documentList: response.data.data.task.document,
          });

          console.log("selectedstatus = " + this.state.selectedstatus);
          this._fetchProjectList(service_id);
          this._fetchUserList(service_id, project_id);
          this.getStatus();
        }

        // console.log(this.state.taskDetail)
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  }
  showModal = () => {
    this.setState({ deletePopup: true });
  };

  hideModal = () => {
    this.setState({ deletePopup: false });
  };

  _showPDF = async (pdfUrl) => {
    this.setState({
      showPDFView: true,
      pdfURL: pdfUrl,
    });
  };

  _hidePDFPopup = async (pdfURL) => {
    this.setState({
      showPDFView: false,
      pdfURL: "",
    });
  };

  checkNullFile = (fileString) => {
    const temp = fileString.split("/");
    const testString = temp[temp.length - 1];
    if (testString == "null" || testString == null || testString == "") {
      return false;
    } else {
      return true;
    }
  };

  _taskDelete = async (id) => {
    this.setState({ dataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.taskManagement.taskDelete,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          task_id: id,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.tasksList();
        }
        this.setState({ dataLoading: false, redirectToReferrer: true });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _fetchCategoryList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      form_type: 17,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .post(Url.formCategoryList, qs.stringify(requestData), {
        headers: ContentType,
      })
      .then((response) => {
        if (response.data.status === 200) {
          var list = response.data.data.map((data) => {
            delete data.description;
            return data;
          });
          this.setState({ categoryList: list });
        }
      })
      .catch((error) => { });
  };

  _handleStatus = async (item = null, status = 0) => {
    await this.setState((prevState) => {
      switch (status) {
        case 0:
          return "Schedule";
        case 1:
          return "Todo";
        case 2:
          return "Inprogress";
        case 3:
          return "Completed";
        default:
          return null;
      }
    });
  };

  _handleChange = async (item = null, type = "start_date") => {
    await this.setState((prevState) => {
      switch (type) {
        case "start_date":
          let { selectedStartDate } = prevState.taskDetail;
          selectedStartDate = item;
          return { selectedStartDate };

        case "target_date":
          let { selectedTargetDate } = prevState.taskDetail;
          selectedTargetDate = item;

          return { selectedTargetDate };

        case "task":
          let { task, taskError } = prevState.taskDetail;
          task = item;
          taskError = item ? false : true;
          return { task, taskError };

        case "priority":
          let { selectedPriority, priorityError } = prevState;
          selectedPriority = item;
          priorityError = item ? false : true;
          return { selectedPriority, priorityError };

        case "task_description":
          let { taskDescription, taskDescriptionError } = prevState;
          taskDescription = item;
          taskDescriptionError = item ? false : true;
          return { taskDescription, taskDescriptionError };

        case "responsible_person":
          let { responsiblePerson, responsiblePersonError } = prevState;
          responsiblePerson = item;
          responsiblePersonError = item ? false : true;
          return { responsiblePerson, responsiblePersonError };

        case "category":
          let { selectedCategory, selectedCategoryError } = prevState;
          selectedCategory = item;
          selectedCategoryError = item ? false : true;
          return { selectedCategory, selectedCategoryError };

        default:
          return prevState;
      }
    });
  };

  _fetchServiceList = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.serviceList);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { serviceList, dataLoading } = prevState;
              serviceList = response.data.data;
              dataLoading = false;
              return { serviceList, dataLoading };
            });
          }
        })
        .catch((error) => {
          this.setState((prevState) => {
            let { dataLoading } = prevState;
            dataLoading = false;
            return { dataLoading };
          });
        });
    });
  };

  _serviceChangeHandle = async (serviceId) => {
    await this.setState((prevState) => {
      let { selectedServiceId, serviceError } = prevState;
      selectedServiceId = serviceId;
      serviceError = serviceId ? false : true;
      return { selectedServiceId, serviceError };
    });
    await this._resetDropdwon();
    this._fetchProjectList(serviceId);
    this._fetchUserList(
      this.state.selectedServiceId,
      this.state.selectedProjectId
    );
  };

  _fetchProjectList = async (serviceId) => {
    let requestData = {
      service_id: serviceId,
      module_name: "task_management",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { projectList } = prevState;
              projectList = response.data.data;
              return { projectList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _projectChangeHandle = async (projectId) => {
    await this._resetDropdwon(false);

    await this.setState((prevState) => {
      let { selectedProjectId, projectError, projectList } = prevState;

      selectedProjectId = projectId;
      projectError = projectId ? false : true;

      return { selectedProjectId, projectError };
    });
    await this._fetchUserList(
      this.state.selectedServiceId,
      this.state.selectedProjectId
    );
  };

  _fetchUserServiceProject = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.userServiceProject);
      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { selectedServiceId, selectedProjectId } = prevState;
              selectedServiceId = response.data.data[0].service_id;
              selectedProjectId = response.data.data[0].project_id;
              return { selectedServiceId, selectedProjectId };
            });
            // this._setDataLoading(false);
          }
        })
        .catch((error) => {
          // this._setDataLoading(false);
        });
    });
  };

  _fetchUserList = async (serviceId, projectId, type) => {
    await this.setState({
      dataLoading: true,
      selectedServiceId: serviceId,
      selectedProjectId: projectId,
    });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var requestData = {
      auth_key: token,
      user_id: userId,
      service_id: serviceId,
      project_id: projectId,
      type: type,
      // form_id: this.state.formId,
    };

    const contentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(Url.UserList, qs.stringify(requestData), contentType)
      .then(async (response) => {
        if (response.data.status == 200) {
          await this.setState({
            dataLoading: false,
            userList: response.data.data,
          });
        }
      })
      .catch(async (error) => {
        await this.setState({
          dataLoading: false,
        });
      });
  };

  _resetDropdwon = async (projectReset = true) => {
    await this.setState((prevState) => {
      let { selectedProjectId, projectList } = prevState;

      if (projectReset) {
        selectedProjectId = null;
        projectList = [];
      }

      return {
        selectedProjectId,
        projectList,
      };
    });
  };

  _taskEdit = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.taskManagement.taskEdit;
    let headerType = "multipart/form-data";

    let data = new FormData();
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("task", this.state.task);
    data.append("task_description", this.state.taskDescription);
    data.append("category", this.state.selectedCategory);
    data.append("priority", this.state.selectedPriority);

    // var startDate = moment(this.state.selectedStartDate).format(
    //   "YYYY-MM-DD HH:mm:ss"
    // );
    // var targetDate = moment(this.state.selectedTargetDate).format(
    //   "YYYY-MM-DD HH:mm:ss"
    // );

    var startDate = convertDateToUTC(this.state.selectedStartDate);
    var targetDate = convertDateToUTC(this.state.selectedTargetDate);

    data.append("start_date", startDate);
    data.append("target_date", targetDate);
    data.append("responsible_person", this.state.responsiblePerson);
    // if (this.props.ownerType) {
    data.append("task_id", this.state.taskId || 0);

    await axios
      .post(URL, data, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          this._taskDetail();
          AlertMessage({ message: "Task edited succesfully" });
        }
        this.setState({ dataLoading: false, isEdit: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
        AlertMessage({
          message: "There is a error while editing task",
          type: "error",
        });
      });
  };

  _changeStatus = async (id, status, module) => {
    this.setState({ dataLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.taskManagement.changeStatus,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          status: status,
          task_id: id,
          module: module,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this._taskDetail();
        }
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };

  _typeChangeHandle = async (id, task_id, module) => {
    await this.setState((prevState) => {
      let { showCloseModal, taskId, closeData } = prevState;
      if (id == 1) {
        this._changeStatus(task_id, 1, module);
      } else if (id == 2) {
        this._changeStatus(task_id, 2, module);
      } else {
        showCloseModal = true;
        closeData = {
          taskId: task_id,
          status: id,
          type: 4,
          module: module,
        };
      }
      return { showCloseModal, taskId, closeData };
    });
    // this.props.handleClose();
  };
  _handleCloseModal = async () => {
    this.setState({
      showCloseModal: false,
      closeData: {},
    });
    this._taskDetail();
  };

  _getModuleType = (name, id) => {
    let val = "";
    let link = "";
    switch (name) {
      case "sm":
        val = "Discussion";
        break;
      case "tm":
        val = "Task";
        break;
      case "ftr":
        val = this.state.typeList[id - 1].form_type;
        break;
      case "goal":
        val = "Goal";
        break;
    }
    return val;
  };

  CommentList = async (limitComment = 3) => {
    this.setState({ isCommentApiLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.taskManagement.taskCommentList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          task_id: this.state.taskId,
          module: this.state.module,
          limit: limitComment,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            commentlist: response.data.data,
            total_number: response.data.total_number,
            comment: 0,
          });
        }
      })
      .catch((error) => { })
      .finally(() => this.setState({ isCommentApiLoading: false }))
  };

  ReplyCommentAdd = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.taskManagement.taskContentAddSubcomment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reply_status: this.state.comment,
          comment_reply_id: 0,
          main_comment_id: this.state.commentId,
          comment: this.state.replycommentAdd,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.subCommentList();
          this.setState({ replycommentAdd: "" });
          AlertMessage({
            message: "Reply Added Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Reply Add Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => { });
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.commentError = tempObj.commentAdd ? false : true;

      return prevState;
    });
  };

  CommentAdd = async () => {
    await this._formValidation();

    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          console.log(this.state[propertyName]);
          formErrorStatus = true;
          console.log("error");
          return;
        } else {
          formErrorStatus = false;
        }
        console.log(formErrorStatus);
      }
    }

    if (!formErrorStatus) {
      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");

      await axios
        .post(
          Url.taskManagement.taskcontentaddcomment,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            id: this.state.taskId,
            module: this.state.module,
            comment: this.state.commentAdd,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.CommentList();
            this.setState({ commentAdd: "" });
            AlertMessage({
              message: "Comment Added Successfully",
              type: "success",
            });
          } else {
            AlertMessage({
              message: "Comment Add Failed. Please try again.",
              type: "error",
            });
          }
        })
        .catch((error) => { });
    }
  };

  subCommentList = async () => {
    this.setState({ isCommentApiLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.taskManagement.taskDetailCommentSubcommentlist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.taskId,
          comment_id: this.state.commentId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ subcommentlist: response.data.data, comment: 1 });
        }
      })
      .catch((error) => { })
      .finally(() => this.setState({ isCommentApiLoading: false }))
  };

  LikeCommentAdd = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.taskManagement.taskContentAddLikeToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Liked Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Like Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => { });
  };

  LikeCommentRemove = async (Cid, Ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.taskManagement.taskcontentcommentremoveunlike,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: Ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Liked Remove Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Like Remove Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => { });
  };

  CommentRemove = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.taskManagement.taskdeletecomment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Deleted Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Deleted Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => { });
  };

  getStatus = async () => {
    // if (this.state.taskDetail.status === 0 && this.state.taskDetail.task_status === 0 || this.state.taskDetail.module === "goal" && this.state.taskDetail.status === 1) {
    if (this.state.taskDetail.task_status === 0) {
      this.setState({
        status: "Schedule",
      });
    } else if (this.state.taskDetail.task_status === 1) {
      this.setState({
        status: "Todo",
      });
    } else if (this.state.taskDetail.task_status === 2) {
      this.setState({
        status: "Inprogress",
      });
    } else {
      this.setState({
        status: "Complete",
      });
    }
  };

  togglecommentId = async (comment_id) => {
    await this.setState({ commentId: comment_id });
    await this.subCommentList();
  };

  backToMainComment = async () => {
    await this.CommentList();
    await this.setState({ comment: 0, accordionOpen: true });
  };

  // _handleDocumentModal = async () => {
  //   await this.setState((prevState) => {
  //     let { showDocumentModal } = prevState;
  //     showDocumentModal = !showDocumentModal;
  //     return { showDocumentModal };
  //   });
  // };

  _handleDocumentModal = async (type = "DOCUMENT") => {
    await this.setState((prevState) => {
      // let { showDocumentModal } = prevState;
      // showDocumentModal = !showDocumentModal;
      // return { showDocumentModal };

      let { showDocumentModal, docType } = prevState;
      switch (type) {
        case "DOCUMENT":
          showDocumentModal = !showDocumentModal;
          docType = 0;
          return { showDocumentModal, docType };

        case "LINK":
          showDocumentModal = !showDocumentModal;
          docType = 1;
          return { showDocumentModal, docType };
      }
    });
  };

  _deleteLink = async (linkId, type) => {
    console.log("link id == " + linkId);
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      id: linkId,
      type: type,
    };

    let URL = Url.taskManagement.deleteUrlLink;

    let contentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(URL, qs.stringify(requestData), contentType)
      .then((response) => {
        let tempUrlList = this.state.documentList.filter(
          (item) => item.document_id !== linkId
        );
        this.setState({
          documentList: tempUrlList,
        });

        if (type === 1) {
          SuccessToast("URL Deleted successfully");
        } else {
          SuccessToast("Document Deleted successfully");
        }
      })
      .catch((error) => {
        if (type === 1) {
          SuccessToast("URL cannot be Deleted. Try again.");
        } else {
          SuccessToast("Document cannot be Deleted. Try again.");
        }
      });
  };

  __renderTimeLine = (data) => {
    const items = [
      {
        title: " Creation Details",
        cardTitle: (
          <div className="pl-3">
            <span className="common-black-1 font-weight-500">Created By</span>
            <div className="d-flex  text-center align-items-center my-2">
              <img src={data.user_pic} className="user-icon" />
              <div>
                <div className="common-black-1 font-weight-bold font-14">
                  {" "}
                  {data.user_name}
                </div>
              </div>
            </div>
            <div className="common-grey-1 font-weight-bold font-14 my-1">
              {" "}
              Creation Date : {convertDateFromTimezone(data.creation_time)}
            </div>
          </div>
        ),
      },
      {
        title: "Assigned Details",
        cardTitle: (
          <div className="pl-3">
            <span className="common-black-1 font-weight-500">
              {data.self === 1 ? "Self" : "Assigned To"}
            </span>
            <div className="d-flex  text-center align-items-center my-2">
              <img
                src={
                  data.responsible_person != 0 ? data.person_pic : data.user_pic
                }
                className="user-icon"
              />
              <div>
                <div className="common-black-1 font-weight-bold font-14">
                  {data.self == 1
                    ? data.user_name
                    : data.responsible_person_name}
                </div>
              </div>
            </div>
            <div className="common-grey-1 font-weight-bold font-14 my-1">
              {" "}
              Due Date : {data?.target_date?.substring(0, 10) || ""}
            </div>
          </div>
        ),
      },
      {
        title: "Start Details",
        cardTitle: (
          <div className="pl-3">
            <div className="d-flex  text-center align-items-center my-2">
              <img
                src={
                  data.responsible_person != 0 ? data.person_pic : data.user_pic
                }
                className="user-icon"
              />
              <div>
                <div className="common-black-1 font-weight-bold font-14">
                  {data.self == 1
                    ? data.user_name
                    : data.responsible_person_name}
                </div>
              </div>
            </div>
            {!(data.module == "tm" || data.module == "goal") ? (
              <div className="common-grey-1 font-weight-bold font-14 ">
                {" "}
                Start Date : {convertDateFromTimezone(data.form_start_date)}
              </div>
            ) : null}
            {!data?.closing_date &&
              !(data.module == "tm" || data.module == "goal") ? (
              <div className="common-grey-1 font-weight-bold font-14 ">
                {" "}
                Close Date : {convertDateFromTimezone(data.form_close_date)}
              </div>
            ) : null}
          </div>
        ),
      },
      {
        title: "Closing Details",
        cardTitle: (
          <div className="pl-3">
            <span className="common-black-1 font-weight-bold">Closed By</span>
            <div className="d-flex  text-center align-items-center my-2">
              <img
                src={
                  data.responsible_person != 0 ? data.person_pic : data.user_pic
                }
                className="user-icon"
              />
              <div>
                <div className="common-black-1 font-weight-bold font-14">
                  {data.self == 1
                    ? data.user_name
                    : data.responsible_person_name}
                </div>
              </div>
            </div>
            <div className="common-grey-1 font-weight-bold font-14 ">
              {" "}
              Close Date :{" "}
              {convertDateFromTimezone(data?.closing_date?.substring(0, 10)) ??
                ""}
            </div>
          </div>
        ),
        skip: !data?.closing_date,
      },
    ];
    return items;
  };
  manageRedirection = () => {
    window.location.href(`${this.state.props?.match?.url}/${this.state.currentTab}`)
  }
  render() {
    var taskData = this.state.taskDocument;
    var sourcesList = [];
    var sourcesType = [];
    var inputMessageFlag = 0;
    console.log("Task Details ==========>", this.props);
    if (this.state.dataLoading == false) {
      var evidencePics =
        this.state.taskDetail && this.state.taskDetail.document
          ? this.state.taskDetail.document.map((item) => {
            return {
              url: item.file,
              type: item.type,
            };
          })
          : [];

      if (this.state.imageType && this.state.taskDocument.document) {
        sourcesList = this.state.taskDocument.document.map((item) => item.file);
      } else {
        // sourcesList = this.state.reportDetail.evidence_resolve.map(
        //   (item) => item.file
        // );
      }

      if (this.state.imageType) {
        sourcesType = this.state.taskDocument.document.map(
          (item) => item.format
        );
      } else {
        // sourcesType = this.state.taskDocument.document.map((item) =>
        //   item.format == "img" ? "image" : item.format
        // );
      }
    }

    if (this.state.redirectToReferrer) {
      return <Redirect to={`/task-management/task`} />;
    }
    var taskDetail = this.state.taskDetail;
    return (
      <div className="mb-5">
        <FullViewModal
          showModal={this.state.showPDFView}
          hideModal={this._hidePDFPopup}
        >
          <embed
            src={`${this.state.pdfURL}#toolbar=0`}
            frameborder="0"
            style={{ width: "100%", height: "80vh" }}
            controls
            controlsList="nodownload"
          />
        </FullViewModal>

        <FsLightbox
          toggler={this.state.fsToggler}
          types={sourcesType}
          sources={sourcesList}
          disableLocalStorage={true}
          key={sourcesList}
        />
        <LoadingView showLoading={this.state.dataLoading} />
        <div className="task-module-container">

          <div className="d-flex justify-content-between mb-3">
            <div>
              <Button className="back-btn" onClick={this.props.history.goBack}>
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </Button>
            </div>
            <div>
              {" "}
              <p className="common-black-1 font-20 ">{taskDetail.task}</p>
            </div>
            <div>
              {taskDetail.status_change_right == 1 &&
                taskDetail.delete_right == 1 &&
                !taskDetail.closing_date &&
                (this.state.module == "tm" ||
                  this.state.module == "goal") &&
                !this.state.isEdit && (
                  <img
                    src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                    className="svg-icons mr-1 cursor-pointer"
                    onClick={() => this.setState({ isEdit: true })}
                  />
                )}
              {taskDetail.status == 0 &&
                taskDetail.delete_right == 1 &&
                taskDetail.status_change_right == 0 &&
                taskDetail.delete_right == 1 &&
                (this.state.module == "tm" ||
                  this.state.module == "goal") ? (
                <div className="rd-btn right-block-gap cursor-pointer ">
                  <img src={require("assets/sls-icons/V2. New Development/delete.svg")}
                    className="cursor-pointer svg-icons" />
                </div>
              ) : null}
            </div>
          </div>

          <Row>
            <Col md={6}>
              <div className=" px-4 py-4 common-card-border-layout-3">
                <Row>
                  <Col md={11}>
                    <div className="d-flex">
                      <label className="common-grey-1 font-20 font-weight-500 ">
                        Task Name :
                      </label>
                      <p className="form-view-text common-black-1 ml-2 font-18 ">
                        {taskDetail.task}
                      </p>
                    </div>
                  </Col>

                </Row>
                {(
                  taskDetail.module == "tm" || taskDetail.module == "goal"
                ) ? <Row>
                  <Col md={12}>
                    <div className="d-flex">
                      <label className="common-grey-1 font-20 font-weight-500">
                        Description :
                      </label>
                      <p className="form-view-text common-black-1 ml-2 font-18 ">
                        {taskDetail.task_description}
                      </p>
                    </div>
                  </Col>
                </Row> : null}
                <div className="seprator-part"></div>
                <Row className="mt-3">
                  <Col md={3}>
                    <div>
                      <label className="common-grey-1 font-20 font-weight-500">
                        {" "}
                        Module
                      </label>
                      <p className="form-view-text common-black-1  font-18 ">
                        {this._getModuleType(
                          taskDetail.module,
                          taskDetail.form_type
                        )}
                      </p>
                    </div>
                  </Col>
                  {!(
                    taskDetail.module == "tm" || taskDetail.module == "goal"
                  ) ? (
                    <>
                      <Col md={3}>
                        <div>
                          <label className="common-grey-1 font-20 font-weight-500">
                            {this._getModuleType(
                              taskDetail.module,
                              taskDetail.form_type
                            )}{" "}
                          </label>
                          <p className="form-view-text common-black-1 font-18 ">
                            {taskDetail.form_name}
                          </p>
                        </div>
                      </Col>
                      {/* <Col md={3} >
                      <div >
                        <label className="common-grey-1 font-20 ">Start Date</label>
                        <p className="form-view-text common-black-1  font-18 font-weight-bold">{`${convertDateFromTimezone(taskDetail.form_start_date)}`}</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div >
                        <label className="common-grey-1 font-20 ">Close Date</label>
                        <p className="form-view-text common-black-1  font-18 font-weight-bold">{`${convertDateFromTimezone(taskDetail.form_close_date)}`}</p>
                      </div>
                    </Col> */}
                    </>
                  ) : (
                    ""
                  )}
                  {taskDetail.module == "goal" ? (
                    <>
                      <Col md={3}>
                        <div>
                          <label className="common-grey-1 font-20 font-weight-500 mt-4">
                            {this._getModuleType(
                              taskDetail.module,
                              taskDetail.form_type
                            )}{" "}
                          </label>
                          <p className="form-view-text common-black-1  font-18 font-weight-500">
                            {taskDetail.form_name}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <label className="common-grey-1 font-20 ">
                            Module Type{" "}
                          </label>
                          <p className="form-view-text common-black-1  font-18 font-weight-500">
                            {taskDetail.category_name}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <label className="common-grey-1 font-20 ">
                            Progressive Metric{" "}
                          </label>
                          <p className="form-view-text common-black-1  font-18 font-weight-500">
                            {taskDetail.progressive_metric}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <label className="common-grey-1 font-20 font-weight-500 ">
                            Created By{" "}
                          </label>
                          <p className="form-view-text common-black-1  font-18 ">
                            {taskDetail.self == 1
                              ? taskDetail.user_name
                              : taskDetail.responsible_person_name}
                          </p>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                  {!this.state.isEdit &&
                    taskDetail.task_status != 3 &&
                    taskDetail.responsible_person ==
                    this.state.userInfo.user_id ? (
                    <Col md={3}>
                      <div>
                        <label className="common-grey-1 font-20 mb-0 font-weight-500">
                          Select
                        </label>
                        {taskDetail.task_status == 3 ? (
                          <p className="form-view-text common-black-1  font-18 font-weight-500">
                            Completed
                          </p>
                        ) : null}
                        {!this.state.isEdit &&
                          taskDetail.task_status != 3 &&
                          taskDetail.responsible_person ==
                          this.state.userInfo.user_id ? (
                          <div
                            className="common-dropdown-layout-2"
                            style={{ marginTop: "-16px", width: "70%" }}
                          >
                            <CommonDropdown
                              isRequired={false}
                              optionList={this.state.statusList}
                              onChangeHandle={(val) =>
                                this._typeChangeHandle(
                                  val,
                                  taskDetail.task_id,
                                  taskDetail.module
                                )
                              }
                              value={taskDetail.status}
                            />
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  ) : null}
                  <Col md={3}>
                    <div>
                      <label className="common-grey-1 font-20 font-weight-500">
                        Status
                      </label>
                      <p className="form-view-text common-black-1  font-18 ">
                        {this.state.status}
                      </p>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <label className="common-grey-1 font-20 font-weight-500">
                        Category
                      </label>
                      <p className="form-view-text common-black-1  font-18 ">
                        {taskDetail.category_name}
                      </p>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <label className="common-grey-1 font-20 font-weight-500 ">
                        Priority
                      </label>
                      <p className="form-view-text common-black-1  font-18 ">
                        <Badge
                          style={{
                            backgroundColor:
                              this.state.priorityList[taskDetail?.priority - 1]
                                ?.priority_color,
                            borderRadius: "1.5vw",
                            padding: "6px 10px",
                          }}
                        >
                          {
                            this.state.priorityList[taskDetail?.priority - 1]
                              ?.priority
                          }
                        </Badge>
                      </p>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <div>
                        <div className="common-grey-1 font-20 font-weight-500 mt-3">
                          {!taskDetail.closing_date ? "Overdue" : "Deviation"}
                        </div>
                        <div className="common-black-1 font-18 mt-2">
                          {" "}
                          {!taskDetail.closing_date
                            ? taskDetail.overdue
                            : taskDetail.deviation}{" "}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className=" px-4 py-4 common-card-border-layout-3 mt-5">
                {/* <TimeLineComponent
                  modules={this.__renderTimeLine(taskDetail)}
                /> */}
                {Object.keys(taskDetail).length > 0 ? (
                  <VCTimelineComponent
                    enableOutline
                    cardHeight={20}
                    items={this.__renderTimeLine(taskDetail)}
                  />
                ) : null}
              </div>
            </Col>
            <Col md={6}>
              <Row>
                {taskDetail.closing_date && (
                  <Col md={12}>
                    <div className=" px-4 py-4 mb-3 common-card-border-layout-3">
                      <Row>
                        <Col md={12}>
                          <div className="d-flex">
                            <label className="common-grey-1 font-20 ">
                              Closing Remark :
                            </label>
                            <p className="form-view-text common-black-1 ml-2 font-18 font-weight-bold">
                              {taskDetail.closing_remark}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="d-flex">
                            <label className="common-grey-1 font-20 ">
                              Closing Reason :
                            </label>
                            <p className="form-view-text common-black-1 ml-2 font-18 font-weight-bold">
                              {taskDetail.closing_reason}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )}
                <Col md={12}>
                  <div className=" px-4 py-4 common-card-border-layout-3">
                    <div className="my-2">
                      <TabComponent
                        tabList={tabs}
                        activeTab={this.state.currentTab}
                        setTab={(selectedKey) =>
                          this.setState({ currentTab: selectedKey })
                        }
                        classes="justify-content-around m-0"
                      />
                      <div
                        className="seprator-part"
                        style={{ marginTop: "-4.5px" }}
                      ></div>
                    </div>
                    <div className="chat-container">
                      <Scrollbars style={{ height: `28vw` }}>
                        <div className="px-4">
                          {this.state.currentTab === "discuss" ? (
                            <div id="discuss">
                              <div>
                                {this.state.comment == 0 ? (
                                  ""
                                ) : (
                                  <div className="d-flex">
                                    <ChevronLeft
                                      className="cursor-pointer common-orange"
                                      onClick={() => this.backToMainComment()}
                                    />{" "}
                                    Comments details
                                  </div>
                                )}
                                {this.state.total_number > 3 &&
                                  this.state.comment == 0 && (
                                    <div>
                                      {this.state.allComments ? (
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.CommentList();
                                            this.setState({
                                              allComments: false,
                                            });
                                          }}
                                          className="cursor-pointer common-orange"
                                        >
                                          Back To Main Comments
                                        </a>
                                      ) : (
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.CommentList(
                                              this.state.total_number
                                            );
                                            this.setState({
                                              allComments: true,
                                            });
                                          }}
                                          className="cursor-pointer common-orange"
                                        >
                                          Show All Comments
                                        </a>
                                      )}
                                    </div>
                                  )}
                              </div>

                              {!this.state.dataLoading &&
                                this.state.comment == 0 &&
                                this.state.commentlist != "" ? (
                                this.state?.commentlist.map((el, idx) => {
                                  const position = el.delete_flag
                                    ? "right"
                                    : "left";
                                  const callbacks = {
                                    delete: this.CommentRemove,
                                    like:
                                      el.like_flag != 1
                                        ? this.LikeCommentAdd
                                        : this.LikeCommentRemove,
                                    reply: this.togglecommentId,
                                  };

                                  if (!el.delete_flag) {
                                    delete callbacks.delete;
                                  }

                                  return (
                                    <div key={idx}>
                                      <ChatMessageBox
                                        message={el.comment}
                                        isLiked={el.like_flag}
                                        likeCount={el.like_number}
                                        hasReplies={el.reply_number}
                                        commentTime={el.comment_time}
                                        repliesCount={el.reply_number}
                                        user_name={el.user_name}
                                        user_pic={el.user_pic}
                                        position={position}
                                        callbacks={callbacks}
                                        callbacksParams={{
                                          delete: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          like: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          reply: {
                                            id: el.comment_id,
                                          },
                                        }}
                                      />
                                      {el.subComment.map((sub, i) => {
                                        const callbacks = {
                                          delete: this.CommentRemove,
                                          like:
                                            sub.like_flag != 1
                                              ? this.LikeCommentAdd
                                              : this.LikeCommentRemove,
                                        };
                                        if (!sub.delete_flag) {
                                          delete callbacks.delete;
                                        }
                                        return (
                                          <div
                                            className={` ${position === "right"
                                              ? "mr-5 pr-5"
                                              : "ml-5 pl-5"
                                              }`}
                                            key={i}
                                          >
                                            <ChatMessageBox
                                              message={sub.comment}
                                              isLiked={sub.like_flag}
                                              likeCount={sub.like_number}
                                              hasReplies={sub.reply_number}
                                              commentTime={sub.comment_time}
                                              repliesCount={sub.reply_number}
                                              user_name={sub.user_name}
                                              position={position}
                                              user_pic={sub.user_pic}
                                              callbacks={callbacks}
                                              callbacksParams={{
                                                delete: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                                like: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="box d-flex inner-box justify-content-center pt-5">
                                  {this.state.comment == 0 &&
                                    this.state.commentlist == "" && (
                                      <div className="box inner-box">
                                        <p>No Comments Available</p>{" "}
                                      </div>
                                    )}
                                </div>
                              )}
                              {!this.state.dataLoading &&
                                this.state.comment == 1 &&
                                this.state.subcommentlist != "" ? (
                                this.state.subcommentlist.map((el, idx) => {
                                  const position = el.delete_flag
                                    ? "right"
                                    : "left";
                                  const callbacks = {
                                    delete: this.CommentRemove,
                                    like:
                                      el.like_flag != 1
                                        ? this.LikeCommentAdd
                                        : this.LikeCommentRemove,
                                    reply: this.togglecommentId,
                                  };

                                  if (!el.delete_flag) {
                                    delete callbacks.delete;
                                  }
                                  return (
                                    <div key={idx}>
                                      <ChatMessageBox
                                        message={el.comment}
                                        isLiked={el.like_flag}
                                        likeCount={el.like_number}
                                        hasReplies={el.reply_number}
                                        commentTime={el.comment_time}
                                        repliesCount={el.reply_number}
                                        user_name={el.user_name}
                                        user_pic={el.user_pic}
                                        position={position}
                                        callbacks={callbacks}
                                        callbacksParams={{
                                          delete: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          like: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          reply: {
                                            id: el.comment_id,
                                          },
                                        }}
                                      />
                                      {el.subComment.map((sub, i) => {
                                        const callbacks = {
                                          delete: this.CommentRemove,
                                          like:
                                            sub.like_flag != 1
                                              ? this.LikeCommentAdd
                                              : this.LikeCommentRemove,
                                        };
                                        if (!sub.delete_flag) {
                                          delete callbacks.delete;
                                        }
                                        return (
                                          <div
                                            className={` ${position === "right"
                                              ? "mr-5 pr-5"
                                              : "ml-5 pl-5"
                                              }`}
                                            key={i}
                                          >
                                            <ChatMessageBox
                                              message={sub.comment}
                                              isLiked={sub.like_flag}
                                              likeCount={sub.like_number}
                                              hasReplies={sub.reply_number}
                                              commentTime={sub.comment_time}
                                              repliesCount={sub.reply_number}
                                              user_name={sub.user_name}
                                              position={position}
                                              user_pic={sub.user_pic}
                                              callbacks={callbacks}
                                              callbacksParams={{
                                                delete: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                                like: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="box inner-box">
                                  {this.state.comment == 1 &&
                                    this.state.subcommentlist == "" && (
                                      <p>No Sub Comments Available</p>
                                    )}
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {this.state.currentTab === "link" ? (
                            <div id="link">
                              {" "}
                              {this.state.documentList ? (
                                this.state.documentList.map((el, idx) => {
                                  const position =
                                    el.delete_right === 1 ? "right" : "left";
                                  const callbacks = {
                                    delete: this._deleteLink,
                                  };
                                  if (
                                    !this.state.selectedstatus != 3 &&
                                    !el.delete_right
                                  ) {
                                    delete callbacks.delete;
                                  }
                                  return (
                                    <>
                                      {" "}
                                      {el.type == 1 ? (
                                        <div key={idx}>
                                          <ChatMessageBox
                                            link={el.file}
                                            commentTime={el?.date + "  " + el?.time}
                                            position={position}
                                            callbacks={callbacks}
                                            callbacksParams={{
                                              delete: {
                                                id: el.document_id,
                                                count: el.type,
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })
                              ) : (
                                <div className="box inner-box">
                                  <p>No Links Available</p>{" "}
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {this.state.currentTab === "doc" ? (
                            <div>
                              {this.state.documentList &&
                                this.state.documentList.map((el, idx) => {
                                  const position =
                                    el.delete_right === 1 ? "right" : "left";
                                  const callbacks = {
                                    delete: this._deleteLink,
                                  };
                                  if (
                                    !this.state.selectedstatus != 3 &&
                                    !el.delete_right
                                  ) {
                                    delete callbacks.delete;
                                  }
                                  return (
                                    <div key={idx}>
                                      {el.type == 2 ? (
                                        <ChatMessageBox
                                          commentTime={el?.date + "  " + el?.time}
                                          message={
                                            <>
                                              {el.type == 2 ? (
                                                <>
                                                  <div
                                                    className="image-box"
                                                    onClick={() => {
                                                      this.setState({
                                                        lightboxactive: true,
                                                        toggler: true,
                                                        fsToggler:
                                                          !this.state.fsToggler,
                                                        imageType: "document",
                                                      });
                                                    }}
                                                  >
                                                    {el.type == 2 &&
                                                      el.format == "image" ? (
                                                      <>
                                                        {" "}
                                                        <div className="browse-image mb-0">
                                                          <img
                                                            style={{
                                                              height: "2.5vw",
                                                            }}
                                                            className="inner-logo"
                                                            src={el.file}
                                                            alt=""
                                                          />
                                                          {el?.file_name}
                                                        </div>
                                                      </>
                                                    ) : null}
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {el.type == 2 &&
                                                el.format == "video" ? (
                                                <>
                                                  <VideoPlayer
                                                    videoUrl={el.format || ""}
                                                    snapshotAt={1}
                                                  />
                                                  {el?.file_name}
                                                </>
                                              ) : null}
                                              <div>
                                                {el.type == 2 &&
                                                  el.format == "application" ? (
                                                    <>
                                                  <FileText
                                                    style={{
                                                      height: "40px",
                                                      width: "40px",
                                                    }}
                                                  />
                                                  {el?.file_name}
                                                  </>
                                                ) : null}
                                              </div>
                                            </>
                                          }
                                          position={position}
                                          callbacks={callbacks}
                                          customIcon={
                                            el.format == "application" ? (
                                              <div>
                                                <button className="icon-btn">
                                                  <img src={require("assets/sls-icons/V2. New Development/View (1).svg")}
                                                    className="cursor-pointer"
                                                    style={{ height: "1.5vw" }} onClick={() =>
                                                      this._showPDF(el.file)
                                                    }
                                                  />
                                                </button>
                                              </div>
                                            ) : null
                                          }
                                          callbacksParams={{
                                            delete: {
                                              id: el.document_id,
                                              count: el.type,
                                            },
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  );
                                })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Scrollbars>
                    </div>
                    {taskDetail.closing_date &&
                      this.state.currentTab === "discuss" ? (
                      <div className="seprator-part mb-4"></div>
                    ) : taskDetail.closing_date &&
                      this.state.currentTab !==
                      "discuss" ? null : !taskDetail.closing_date ? (
                        <div className="seprator-part mb-4"></div>
                      ) : null}
                    <div>
                      {this.state.currentTab === "discuss" ? (
                        <div>
                          {" "}
                          {this.state.comment == 0 ? (
                            <div className="align-items-center comments-input d-flex input-block">
                              <div className="w-100 px-4 border-right">
                                <input
                                  name="safety-thought"
                                  type="text"
                                  placeholder="Write a comment"
                                  className="form-control border-0"
                                  multiple
                                  onChange={(e) =>
                                    this.setState({
                                      commentAdd: e.target.value.trimStart(),
                                    })
                                  }
                                  onKeyDown={(e) => {

                                    if (e.key === 'Enter') {
                                      console.log("checking", inputMessageFlag)
                                      if (inputMessageFlag === 0) {
                                        inputMessageFlag = 1;
                                        console.log("checking", inputMessageFlag)

                                        setTimeout(() => {
                                          this.CommentAdd()
                                          inputMessageFlag = 0;
                                        }, 500)


                                      }
                                    }
                                  }}
                                  value={this.state.commentAdd}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="px-4 send-btn">
                                <Send
                                  aria-disabled="true"
                                  className="send-icon cursor-pointer"
                                  onClick={(e) => {
                                    if (e.detail == 1) {
                                      this.CommentAdd()
                                    }
                                  }}
                                  disabled={this.state.isCommentApiLoading}
                                  style={{
                                    color: "59B0F6",
                                    height: "35px",
                                    width: "35px",
                                  }}
                                />
                              </div>
                              {!!this.state.commentError && (
                                <p className="inputErrorMsg">
                                  {requiredMessage}
                                </p>
                              )}
                            </div>
                          ) : (
                            <div className="align-items-center comments-input d-flex input-block">
                              <div className="w-100 px-4 border-right">
                                <input
                                  name="safety-thought"
                                  type="text"
                                  placeholder="Write a Reply"
                                  className="form-control border-0"
                                  multiple
                                  onChange={(e) =>
                                    this.setState({
                                      replycommentAdd:
                                        e.target.value.trimStart(),
                                    })
                                  }

                                  onKeyDown={(e) => {

                                    if (e.key === 'Enter') {
                                      console.log("checking", inputMessageFlag)
                                      if (inputMessageFlag === 0) {
                                        inputMessageFlag = 1;
                                        console.log("checking", inputMessageFlag)

                                        setTimeout(() => {
                                          this.ReplyCommentAdd()
                                          inputMessageFlag = 0;
                                        }, 500)


                                      }
                                    }
                                  }}
                                  value={this.state.replycommentAdd}
                                />
                              </div>
                              <div className="px-4 send-btn">
                                <Send
                                  aria-disabled="true"
                                  className="send-icon  cursor-pointer"
                                  disabled={this.state.isCommentApiLoading}
                                  onClick={(e) => {
                                    if (this.state?.replycommentAdd != "" && e.detail == 1) {
                                      this.ReplyCommentAdd()
                                    }
                                  }}
                                  style={{
                                    color: "59B0F6",
                                    height: "35px",
                                    width: "35px",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.currentTab !== "discuss" &&
                        !taskDetail.closing_date ? (
                        <div className="d-flex justify-content-center">
                          <div
                            className="chat-message-box"
                            title={` ${this.state.currentTab === "link"
                              ? "Share a url"
                              : "Upload Document"
                              }`}
                          >
                            {this.state.currentTab === "link" ? (
                              <Link
                                className="cursor-pointer"
                                onClick={() =>
                                  this._handleDocumentModal("LINK")
                                }
                              />
                            ) : (
                              ""
                            )}
                            {this.state.currentTab === "doc" ? (
                              <File
                                className="cursor-pointer"
                                onClick={() =>
                                  this._handleDocumentModal("DOCUMENT")
                                }
                              />
                            ) : (
                              " "
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Modal centered show={this.state.deletePopup} className="submit-modal">
          <Modal.Header closeButton onClick={this.hideModal}></Modal.Header>

          <Modal.Body>
            <img
              className="submit-img"
              src={require("assets/images/delete-popup.svg")}
            />
            <p className="submit-text">
              Are you sure you want to delete the task?
            </p>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={this.hideModal}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="red-btn"
                onClick={() => this._taskDelete(this.state.taskId)}
              >
                YES, DELETE
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal centered show={this.state.isEdit}>
          <Modal.Header
            closeButton
            onClick={() => this.setState({ isEdit: false })}
          >
            {" "}
            <Modal.Title>Edit Task </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <>
              <Row className="row-gap" style={{ marginBottom: 0 }}>
                <Col md={12}>
                  <div className="form-group">
                    <label className="form-label common-black-1 font-18 font-weight-500 mb-2 ">
                      Task
                    </label>
                    <input
                      className="d-block p-2 w-100 common-grey-1 "
                      value={this.state.task}
                      onChange={(event) =>
                        this._handleChange(event.target.value, "task")
                      }
                      style={{
                        border: "1px solid #A09494",
                        borderRadius: "5px",
                      }}
                      placeholder={"Enter task name"}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label className="form-label common-black-1 font-18 mb-2 mt-1 font-weight-500">
                      Task Description
                    </label>
                    <input
                      className="d-block p-2 w-100 common-grey-1 "
                      value={this.state.taskDescription}
                      onChange={(event) =>
                        this._handleChange(
                          event.target.value,
                          "task_description"
                        )
                      }
                      placeholder={"Enter task description"}
                      style={{
                        border: "1px solid #A09494",
                        borderRadius: "5px",
                      }}
                    />
                    {!!this.state.taskDescriptionError && (
                      <p className="inputErrorMsg">{requiredMessage}</p>
                    )}
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label className="form-label common-black-1 font-18 mb-2 mt-2">
                      {" "}
                      Creation Date
                    </label>
                    <p className="form-view-text">
                      {`${convertDateFromTimezone(taskDetail.creation_time)}`}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label className="form-label common-black-1 font-18 mb-2 mt-2">
                      Due Date
                    </label>
                    <div className="calender-div">
                      <DatePicker
                        selected={this.state.selectedTargetDate}
                        // minDate={this.state.selectedStartDate}
                        minDate={new Date(taskDetail.creation_time)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="dd/MM/yyyy HH:mm"
                        onChange={(jsDate) =>
                          this._handleChange(jsDate, "target_date")
                        }
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </div>
                </Col>
                {(this.state.userInfo.designation_flag == 1 ||
                  this.state.userInfo.designation_flag == 3) &&
                  !this.props.ownerType && (
                    <>
                      <Col sm={6} className="common-dropdown-layout mt-2">
                        <ServiceList
                          optionList={this.state.serviceList}
                          onChangeHandle={this._serviceChangeHandle}
                          value={this.state.selectedServiceId}
                        />
                        {!!this.state.serviceError && (
                          <p className="inputErrorMsg">{requiredMessage}</p>
                        )}
                      </Col>
                      <Col sm={6} className="common-dropdown-layout mt-2">
                        <ProjectList
                          optionList={this.state.projectList}
                          onChangeHandle={this._projectChangeHandle}
                          value={this.state.selectedProjectId}
                        />
                        {!!this.state.projectError && (
                          <p className="inputErrorMsg">{requiredMessage}</p>
                        )}
                      </Col>
                    </>
                  )}
                <Col sm={6} className="common-dropdown-layout">
                  <CommonDropdown
                    title="Priority"
                    optionList={this.state.priorityList}
                    onChangeHandle={(value) =>
                      this._handleChange(value, "priority")
                    }
                    isRequired={true}
                    value={this.state.selectedPriority}
                  />
                  {!!this.state.priorityError && (
                    <p className="inputErrorMsg">{requiredMessage}</p>
                  )}
                </Col>
                <Col sm={6} className="common-dropdown-layout">
                  <CommonDropdown
                    title="Responsible Person"
                    optionList={this.state.userList}
                    onChangeHandle={(value) =>
                      this._handleChange(value, "responsible_person")
                    }
                    value={this.state.responsiblePerson}
                    isRequired={false}
                  />
                  {!!this.state.responsiblePersonError && (
                    <p className="inputErrorMsg">{requiredMessage}</p>
                  )}
                </Col>
              </Row>
              <div className="modal-footer-btn mt-3">
                <Button
                  variant="primary"
                  onClick={() => {
                    this._taskEdit();
                  }}
                  className="red-btn btn-width"
                >
                  Save
                </Button>
              </div>
            </>
          </Modal.Body>
        </Modal>
        {!!this.state.showCloseModal && (
          <CloseModal
            handleClose={this._handleCloseModal}
            data={this.state.closeData}
          />
        )}
        {!!this.state.showDocumentModal && (
          <TaskDocumentModal
            handleClose={this._handleDocumentModal}
            haveCallback={true}
            callback={() => this._taskDetail()}
            taskId={this.state.taskId}
            module={this.state.module}
            type={this.state.type}
            docType={this.state.docType}
          />
        )}
      </div>
    );
  }
}

export default withRouter(TaskDetail);

const styles = {
  today_div: {
    position: "relative",
    width: "70vw",
  },

  right_pane: {
    position: "fixed",
    width: "18vw",
    height: "100vw",
    minHeight: "calc(100vh - 4.5vw)",
    right: "2vw",
    top: "5vw",
    // "background": "gray",
  },
  row_flex: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
};

export const TabComponent = ({ tabList, activeTab, setTab, classes }) => {
  return (
    <Nav
      activeKey={activeTab}
      onSelect={setTab}
      className={`tabs-module-wrapper ${classes}`}
      variant="tabs"
    >
      {tabList?.map((el, idx) => (
        <Nav.Item>
          <Nav.Link eventKey={el.key} key={idx}>
            {el.name}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export const ChatMessageBox = ({
  position = "left",
  user_name,
  user_pic = null,
  message,
  isLiked,
  likeCount,
  hasReplies,
  repliesCount,
  commentTime,
  callbacks,
  callbacksParams,
  link,
  customIcon = null,
}) => {
  return (
    <div
      className={`chat-item ${position === "right" ? "ml-auto flex-row-reverse " : ""
        }`}
    >
      {user_pic !== null ? (
        <span className="chat-avatar">
          <img src={user_pic} alt={user_name} title={user_name} />
        </span>
      ) : (
        ""
      )}
      <div>
        <div
          className={`chat-content ${position === "right"
            ? "mr-3 flex-row-reverse border-right"
            : "ml-2 border-left"
            }`}
        >
          {message !== "" || null || undefined ? (
            <p className="font-weight-normal">{message}</p>
          ) : (
            ""
          )}
          {link !== "" || null || undefined ? (
            <a href={link} target="_blank">
              {link}
            </a>
          ) : (
            ""
          )}
        </div>
        <div
          className={`chat-footer ${position === "right" ? "mr-3 flex-row-reverse" : ""
            }`}
        >
          <div className="left-part">
            <span>{commentTime}</span>
          </div>
          <div className={`right-part `}>
            {customIcon ? customIcon : ""}
            {callbacks.hasOwnProperty("like") ? (
              <div>
                <button className="icon-btn">
                  <img src={require("assets/sls-icons/V2. New Development/like.svg")}
                    style={{ height: "1.5vw" }}
                    className={`${isLiked ? "like-active" : ""}`}
                    onClick={() => {
                      debounceFunc(() => {
                        callbacks.like(
                          callbacksParams.like.id,
                          callbacksParams.like.count
                        )
                       })
                    }}
                  
                  />
                </button>{" "}
                | <span className="common-black-1">{likeCount}</span>
              </div>
            ) : (
              ""
            )}
            {callbacks.hasOwnProperty("reply") ? (
              <div className="mx-1">
                <button className="icon-btn">
                  <img src={require("assets/sls-icons/V2. New Development/Reply.svg")}
                    style={{ height: "1.5vw" }}
                    className={`${hasReplies ? "reply-active" : ""}`}
                    onClick={() => {
                      debounceFunc(() => {
                        callbacks.reply(callbacksParams.reply.id)
                       })
                    }
                      
                    }
                    // onClick={() => callbacks.reply(callbacksParams.reply.id)}
                  />
                </button>{" "}
                | <span className="common-black-1">{repliesCount}</span>
              </div>
            ) : (
              ""
            )}
            {callbacks.hasOwnProperty("delete") ? (
              <div>
                <button
                  className="icon-btn"
                  onClick={() => {
                    debounceFunc(() => {
                      callbacks.delete(
                        callbacksParams.delete.id,
                        callbacksParams.delete.count
                      )
                     })
                  }
                  }
                  
                >
                  <img src={require("assets/sls-icons/V2. New Development/delete.svg")}
                    style={{ height: "1.5vw" }} />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
