import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Row, Col } from 'react-bootstrap';
import { Scrollbars } from "react-custom-scrollbars";
function MyDropzone({ type, uploadFile, disabledPlaceHolder = false, clearDropZone = false }) {

    const [accept, setAccept] = useState("");
    useEffect(() => {
        if (type == 1) {
            setAccept(".jpg, .jpeg, .png");
        } else if (type == 2) {
            setAccept(".mp4, .mkv, .wav, .omv");
        } else if (type == 3 || type == 4 || type == 7) {
            setAccept(".pdf");
        } else if (type == 5) {
            setAccept(".jpg, .jpeg, .png, .mp4, .mkv, .wav, .omv");
        } else if (type == 6) {
            setAccept(".pdf, .doc, .docx, .xls, .xlsx");
        }
        else if (type == 8) {
            setAccept(".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .mp4, .mkv, .wav, .omv, .jpg, .jpeg, .png, .mp4, .mkv, .wav, .omv ");
        }
        else if (type == 9) {
            setAccept(".jpg, .jpeg, .png, .pdf");
        }
        else if (type == 10) {
            setAccept(".doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .pdf ");
        }
        else if (type == 11) {
            setAccept(".ppt, .pptx ");
        }
    }, [type])

    const [files, setFiles] = useState([]);
    const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
        accept,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

            acceptedFiles.map(file => (
                console.log(file),
                uploadFile(file)
            ))
        }
    });

    const files1 = acceptedFiles.map(file => (
        type == 3 || type == 4 || type == 7 || type == 6 || type == 8 ?
            <li key={file.path}>
                {file.path}
            </li> : null
    ));
    const remove = file => {// make a var for the new array
        acceptedFiles.splice(files1, 1);        // remove the file from the array
    };
    const thumbs = files.map(file => (
        <>
            {file.type.includes("video") &&
                <div style={thumb} key={file.name} >
                    <div style={thumbInner}>
                        <video
                            src={file.preview}
                            style={img}
                        />
                    </div>
                </div>
            }
            {file.type.includes("image") &&
                <div style={thumb} key={file.name} >
                    <div style={thumbInner}>
                        <img
                            src={file.preview}
                            style={img}
                            alt={file.path}
                        />
                    </div>
                </div>
            }
            {!file.type.includes("video") && !file.type.includes("image") && (
            <div style={thumbInner}>
                <p>{file.name}</p>
            </div>
        )}
        </>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);


    useEffect(() => {
        if (files.length && clearDropZone) {
            remove();
            setFiles([]);
        }
    }, [clearDropZone]);

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        errors.map(e => (
            <li key={e.code} className="m-2">{e.message}</li>
        ))
    ));

    return (
        <section className="container-drop" style={{gap:10}}>
            
                <Col>
                    <div {...getRootProps({ className: 'dropzone' })}>

                        <input {...getInputProps()} />
                        <p className='mb-0' style={{ fontSize: ".75rem", lineHeight:1.1 }}>Drag 'n' drop some files here, or click to select files</p>
                        {!disabledPlaceHolder && (<>{type == 1 ? <em>(Only *.jpg, *.jpeg and *.png images will be accepted)</em> : null}
                            {type == 2 ? <em>(Only *.mp4, *.mkv, *.wav and *.omv videos will be accepted)</em> : null}
                            {type == 3 || type == 4 || type == 7 ? <em>(Only *.pdf documents will be accepted)</em> : null}
                            {type == 5 ? <em>(Only *.jpg, *.jpeg, *.png, *.mp4, *.mkv, *.wav and *.omv videos will be accepted)</em> : null}
                            {type == 6 ? <em>(Only *.pdf, *.doc, *.docx, *xls, *xlsx documents will be accepted)</em> : null}
                            {type == 10 ? <em>(Only .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .pdf documents will be accepted)</em> : null}
                            {type == 11 ? <em>(Only .ppt, .pptx  documents will be accepted)</em> : null}
                        </>)}


                    </div>
                </Col>
                {acceptedFiles.length ? <Col>
                    <Scrollbars style={{ height: "100px" }}>
                        {/* <aside>
                            <ul style={{ textAlign: 'start' }}>{files1}</ul>
                            <ul style={{ textAlign: 'start', color: 'red' }}>{fileRejectionItems}</ul>
                        </aside> */}
                        {type != 3 && type != 4 ?
                            <aside style={thumbsContainer}>
                                {thumbs}
                            </aside> : null
                        }
                    </Scrollbars>
                </Col> : null}
            


        </section >
    );
}

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    // width: 100,
    height: 100,
    // width: "80%",
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: '100%',
    height: '100%'
};


export default MyDropzone;