import {
  Button,
  Col,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import {
  Download,
  Eye,
  Info,
  Menu,
  Plus,
  ShoppingCart,
  Trello,
  User,
  Video,
} from "react-feather";
import {
  ErrorToast,
  SuccessToast,
} from "app/shared/components/Popups/ToastMessage";
import { Link, Redirect } from "react-router-dom";
import React, { Component } from "react";

import Backbutton from "app/shared/components/Backbutton";
import Card from "./Card";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import MenuList from "app/shared/components/Menu/MenuList";
import VideoBox from "app/shared/components/ReusableComponents/VideoPlayer/container";
import TimePicker from "react-time-picker";
import Url from "app/shared/constants/Url";
import VideoPlayer from "simple-react-video-thumbnail";
import axios from "axios";
import ConfirmPopupImage from "app/shared/components/Loader/ConfirmPopupImage";
import InfiniteScroll from "react-infinite-scroll-component";

const qs = require("qs");

export default class MarketForm2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoading: true,
      videoPopup: false,
      usePopup: false,
      externalMarketList: [],
      useFormId: "",
      videoURL: "",
      videoTitle: "",
      useFormTitle: "",
      viewPopup: false,
      viewData: {},
      redirectToReferrer: false,

      selectedFormType: 0,
      typeList: [
        { type_id: 1, form_type: "Data Form" },
        { type_id: 2, form_type: "Audit" },
        { type_id: 3, form_type: "Inspect" },
        { type_id: 4, form_type: "Check" },
        { type_id: 5, form_type: "PTW" },
        { type_id: 6, form_type: "PSSR" },
        { type_id: 7, form_type: "BBS" },
        { type_id: 8, form_type: "Survey" },
        { type_id: 9, form_type: "Mock Drill" },
        { type_id: 10, form_type: "C-Asses" },
        { type_id: 11, form_type: "F-Back" },
        { type_id: 12, form_type: "SkillUp" },
        { type_id: 13, form_type: "Assessment" },
      ],

      activeTab: 0,
      marketPlaceFilterList: [],
      assessmentCount: {},
      auditTypeCount: {},
      bbsTypeCount: {},
      checklistTypeCount: {},
      contAssessTypeCount: {},
      datacollectionTypeCount: {},
      elearnTypeCount: {},
      feedbackTypeCount: {},
      inspectionTypeCount: {},
      mockdrillTypeCount: {},
      pssrTypeCount: {},
      surveyTypeCount: {},
      workPermitTypeCount: {},
      searchWord: "",
      formType: 0,
      externalPageNo: 1,
      externalMarketListCount: 0,
      externalFilterPageNo: 1,
      prevFormType: 0,
      formTypeCount: 0,
    };
  }

  componentDidMount() {
    this._getExternalMarketList();
  }


  _getExternalMarketList = async () => {
    var request_data = {
      url: Url.external.externalMarketPlace,
    };

    // axiosAPI function for axios http call
    // let responseObj = this._axiosAPI(request_data);

    let responseObj = this._axiosExternalAPI(request_data);

    // Response processing
    responseObj
      .then(async (response) => {
        this.setState({ dataLoading: false });
        if (response.data.status === 200) {
          await this.setState({
            // externalMarketList: response.data.data.externalMarketlist,
            externalMarketList: (this.state.externalPageNo > 1) ? [...this.state.externalMarketList, ...response.data.data.externalMarketlist] : response.data.data.externalMarketlist,
            assessmentCount: response.data.assessmentTypeCount,
            auditTypeCount: response.data.auditTypeCount,
            bbsTypeCount: response.data.bbsTypeCount,
            checklistTypeCount: response.data.checklistTypeCount,
            contAssessTypeCount: response.data.contAssessTypeCount,
            datacollectionTypeCount: response.data.datacollectionTypeCount,
            elearnTypeCount: response.data.elearnTypeCount,
            feedbackTypeCount: response.data.feedbackTypeCount,
            inspectionTypeCount: response.data.inspectionTypeCount,
            mockdrillTypeCount: response.data.mockdrillTypeCount,
            pssrTypeCount: response.data.pssrTypeCount,
            surveyTypeCount: response.data.surveyTypeCount,
            workPermitTypeCount: response.data.workPermitTypeCount,

            //count for pagination scroll
            externalMarketListCount: response.data.allFormCount,
            externalPageNo: this.state.externalPageNo + 1,
          });
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  };



  _axiosExternalAPI = async (data) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var requestData = {
      auth_key: token,
      user_id: userId,
      page_no: this.state.externalPageNo,
    };

    if (data.form_id !== undefined) {
      requestData.form_id = data.form_id;
    }

    return axios.post(data.url, qs.stringify(requestData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  };



  /* Common Axios function for http call */
  _axiosAPI = async (data) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var requestData = {
      auth_key: token,
      user_id: userId,
    };

    if (data.form_id !== undefined) {
      requestData.form_id = data.form_id;
    }

    return axios.post(data.url, qs.stringify(requestData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  };

  _showHideModal = async (
    type = "video",
    useData = null,
    isShowModal = false
  ) => {
    console.log(useData);

    if (type === "video") {
      await this.setState((prevState) => {
        let { videoPopup, videoURL, videoTitle } = prevState;
        videoURL = useData.video;
        videoTitle = useData.title;
        videoPopup = isShowModal;
        return { videoPopup, videoURL, videoTitle };
      });
    } else if (type === "use_form") {
      await this.setState((prevState) => {
        let { useFormId, usePopup, useFormTitle } = prevState;
        usePopup = isShowModal;
        useFormId = useData.id;
        useFormTitle = useData.title;
        return { useFormId, usePopup, useFormTitle };
      });
    } else if (type === "view") {
      await this.setState((prevState) => {
        let { viewPopup, viewData } = prevState;
        viewPopup = isShowModal;
        viewData = useData;
        return { viewPopup, viewData };
      });
    }
  };

  /* Use form API call */
  _useForm = async () => {
    var request_data = {
      url: Url.formType1.useMarketPlace,
      form_id:
        this.state.useFormId !== undefined || this.state.useFormId !== null
          ? this.state.useFormId
          : "",
    };
    await this.setState({ dataLoading: true });
    // axiosAPI function for axios http call
    let responseObj = this._axiosAPI(request_data);

    // Response processing
    responseObj
      .then(async (response) => {
        if (response.data.status === 200) {
          setTimeout(() => {
            this.setState({
              dataLoading: false,
              redirectToReferrer: true,
            });
          }, 500);
          SuccessToast("Form added to Unpulished Forms");
          this.setState((prevState) => {
            let { usePopup, externalMarketList } = prevState;
            externalMarketList = externalMarketList.filter(
              (item) => item.id !== this.state.useFormId
            );
            usePopup = false;
            return { usePopup, externalMarketList };
          });
        }
      })
      .catch((error) => {
        // this.setState({dataLoading: false})
      });
  };

  _getMarketList = () => {
    if (this.state.externalMarketList.length > 0) {
      return this.state.externalMarketList.map((item, index) => {
        return (
          <tr key={item.id}>
            <td>
              <img className="market-logo" src={item.logo} alt="" />
            </td>
            <td className="left">{item.title}</td>
            <td>{item.type}</td>
            <td width="100">
              <Button
                className="icon-btn"
                onClick={() => this._showHideModal("video", item, true)}
              >
                <Video className="video-icon" />
              </Button>
            </td>
            <td width="100">
              <Button
                className="icon-btn"
                onClick={() => this._showHideModal("view", item, true)}
              >
                <Eye className="eye-icon" />
              </Button>
            </td>
            <td width="120">
              <Button className="icon-btn">
                <Download className="download-icon" />
                <span className="market-download">{item.download}</span>
              </Button>
            </td>
            <td className="last-td" width="180">
              <button
                type="button"
                className="assign-btn btn btn-primary market-use"
                onClick={() => this._showHideModal("use_form", item, true)}
              >
                USE
              </button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="4">
            <p className="list-error">Data not available</p>
          </td>
        </tr>
      );
    }
  };


  _handleInputChange = async (event) => {
    let value = event.target.value;

    this.setState({
      searchWord: value,
    });
    console.log("searchWord == " + this.state.searchWord);
  }

  _getExternalMarketPlaceSearch = async (formTypeId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var requestData = {
      auth_key: token,
      user_id: userId,
      form_type: this.state.formType != 0 ? this.state.formType : 0,
      search_key: this.state.searchWord,
    };

    let URL = Url.external.externalMarketplaceKeySearch;

    const contentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(URL, qs.stringify(requestData), contentType)
      .then((response) => {
        if (response.data.status === 200) {

          this.setState({
            externalMarketList: response.data.data.externalMarketlist,
            dataLoading: false,
            searchWord: "",
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataLoading: false,
        });
      });
  };


  _applyFilter = async (formTypeId) => {

    if (formTypeId != 0) {

      await this.setState({
        formType: formTypeId,
      });

      console.log("formTypeId == " + formTypeId);
      console.log("previous formtype == " + this.state.prevFormType);
      console.log("formtype == " + this.state.formType);

      this._getExternalMarketPlaceFilterData(formTypeId);
    }
  }

  _getExternalMarketPlaceFilterData = async (formTypeId) => {

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let pageNo = ((formTypeId ? formTypeId : this.state.formType) == this.state.prevFormType) ? this.state.externalFilterPageNo : 1;

    var requestData = {
      auth_key: token,
      user_id: userId,
      form_type: formTypeId ? formTypeId : this.state.formType,
      page_no: pageNo,
      // page_no: this.state.externalFilterPageNo,
    };

    let URL = Url.external.externalmarketplacefilter;

    const contentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(URL, qs.stringify(requestData), contentType)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            // externalMarketList: response.data.data.externalMarketlist,
            // externalFilterPageNo: this.state.externalFilterPageNo + 1,
            // formType: formTypeId,

            // externalMarketList: (this.state.externalFilterPageNo > 1) ? [...this.state.externalMarketList, ...response.data.data.externalMarketlist] : response.data.data.externalMarketlist,

            externalMarketList: ((formTypeId ? formTypeId : this.state.formType) == this.state.prevFormType) ? [...this.state.externalMarketList, ...response.data.data.externalMarketlist] : response.data.data.externalMarketlist,
            dataLoading: false,
            externalFilterPageNo: pageNo + 1,
            prevFormType: (formTypeId ? formTypeId : this.state.formType),
            formTypeCount: response.data.formTypeCount,
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataLoading: false,
        });
      });
  };

  render() {
    console.log(this.state.externalMarketList);
    if (this.state.redirectToReferrer) {
      return <Redirect to={`/published-from/unpublished`} />;
    }
    return (
      <div className="profile">
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>

        <div>
          <div className="top-head-search"
            style={{ marginLeft: "60vw", marginTop: "-4vw" }}>
            <input
              type="text"
              placeholder="Enter search word in lowercase"
              name="search"
              value={this.state.searchWord}
              onChange={(e) => this._handleInputChange(e)} />

            <button
              type="submit"
              className="red-btn"
              style={{ margin: '10px' }}
              onClick={() => this._getExternalMarketPlaceSearch()}
            >
              Search
            </button>
          </div>
        </div>

        <div className="ua-uc-section form-sub-menu">
          <Tab.Container
            defaultActiveKey="all"
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link
                  eventKey="all"
                  onClick={async () => {
                    await this.setState({ externalPageNo: 1, formType: 0 });
                    this._getExternalMarketList();
                  }}
                >
                  {` All (${this.state.externalMarketListCount})`}
                </Nav.Link>
              </Nav.Item>
              {/* {this.state.typeList?.map((data) => (
                <Nav.Item>
                  <Nav.Link
                    eventKey={this.state.type_id}
                    onClick={() =>
                      this._getExternalMarketPlaceFilterData(data.type_id)
                    }
                  >
                    {data.form_type}
                  </Nav.Link>
                </Nav.Item>
              ))} */}



              {this.state.typeList?.map((data) => (
                <Nav.Item>
                  <Nav.Link
                    eventKey={this.state.type_id}
                    onClick={() =>
                      // this._getExternalMarketPlaceFilterData(data.type_id)
                      this._applyFilter(data.type_id)
                    }
                  >
                    {data.form_type === "Data Form" ? `${data.form_type} | (${this.state.datacollectionTypeCount})` :
                      data.form_type === "Audit" ? `${data.form_type} | (${this.state.auditTypeCount})` :
                        data.form_type === "Inspect" ? `${data.form_type} | (${this.state.inspectionTypeCount})` :
                          data.form_type === "Check" ? `${data.form_type} | (${this.state.checklistTypeCount})` :
                            data.form_type === "PTW" ? `${data.form_type} | (${this.state.workPermitTypeCount})` :
                              data.form_type === "PSSR" ? `${data.form_type} | (${this.state.pssrTypeCount})` :
                                data.form_type === "BBS" ? `${data.form_type} | (${this.state.bbsTypeCount})` :
                                  data.form_type === "Survey" ? `${data.form_type} | (${this.state.surveyTypeCount})` :
                                    data.form_type === "Mock Drill" ? `${data.form_type} | (${this.state.mockdrillTypeCount})` :
                                      data.form_type === "C-Asses" ? `${data.form_type} | (${this.state.contAssessTypeCount})` :
                                        data.form_type === "F-Back" ? `${data.form_type} | (${this.state.feedbackTypeCount})` :
                                          data.form_type === "SkillUp" ? `${data.form_type} | (${this.state.elearnTypeCount})` :
                                            data.form_type === "Assessment" ? `${data.form_type} | (${this.state.assessmentCount})` : null
                    }
                  </Nav.Link>
                </Nav.Item>
              ))}

            </Nav>
          </Tab.Container>
        </div>


        <InfiniteScroll
          dataLength={this.state.externalMarketList.length}
          next={this.state.formType ? this._getExternalMarketPlaceFilterData : this._getExternalMarketList}
          hasMore={this.state.externalMarketList.length !== (this.state.formType ? this.state.formTypeCount : this.state.externalMarketListCount)}
        >
          <div className="setting-section">
            {/* <div className="profile-top profile-top-whitebg">
            <h2> */}
            {/* <Backbutton /> */}
            {/* Market Place
            </h2>
            <MenuList menuArr={menuArr} />
          </div> */}
            <div className="setting-box market-box">
              <div
                className="published-section published-table"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
              >
                {this.state.externalMarketList.map((item, index) => (
                  <Card item={item} _showHideModal={this._showHideModal} />
                ))}
                {/* <Table bordered striped>
                <thead>
                  <tr>
                    <th>Logo</th>
                    <th className="left">Title</th>
                    <th>Schedule</th>
                    <th className="last-th" colSpan="4">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.externalMarketList && this._getMarketList()}
                </tbody>
              </Table> */}
              </div>
            </div>
          </div>

        </InfiniteScroll>

        <Modal
          centered
          show={this.state.videoPopup}
          onHide={() => this._showHideModal("video", {}, false)}
        >
          <Modal.Header closeButton>
            <Modal.Title> {this.state.videoTitle || `---`}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="video-popup">
              {/* <VideoPlayer videoUrl={this.state.videoURL} snapshotAt={1} /> */}

              <VideoBox url={this.state.videoURL} />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={this.state.usePopup}
          className="submit-modal"
          onHide={() => this._showHideModal("use_form", {}, false)}
          backdrop="static"
        >
          <Modal.Header />
          <Modal.Body>

            <ConfirmPopupImage />
            <p className="submit-text">
              Do you really want to use{" "}
              <b>{`${this.state.useFormTitle} ` || ``}</b>
              Form ?
            </p>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={() => this._showHideModal("use_form", {}, false)}
              >
                NO
              </button>
              <button
                type="submit"
                className="red-btn"
                onClick={() => this._useForm()}
              >
                YES
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={this.state.viewPopup}
          onHide={() => this._showHideModal("view", {}, false)}
          className="view-modal"
        >
          <Modal.Header closeButton>
            <img
              className="view-logo"
              src={this.state.viewData.logo || ""}
              alt=""
            />
            <Modal.Title>
              {this.state.viewData.title || "--"}
              <span className="audit-type">
                ( {this.state.viewData.type || ""})
              </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.state.viewData.view && this.state.viewData.view.view ? (
              <div className="view-body">
                <div className="view-box">
                  <p className="label">Description</p>
                  <p>
                    {/* Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum. */}
                    {this.state.viewData.view.view || `--`}
                  </p>
                </div>
                <Row>
                  {/* <Col xsm={6}>
                  <div className="view-box">
                    <p className="label">Objective</p>
                    <ul>
                      <li>Determine whether safeguards are in place</li>
                      <li>
                        Examine whether the equipment presents any hazards
                      </li>
                      <li>
                        Gather air, water, and other samples to test for
                        hazardous substances
                      </li>
                      <li>Observe work practices to identify unsafe actions</li>
                    </ul>
                  </div>
                </Col> */}
                  {/* <Col xsm={6}>
                  <div className="view-box">
                    <p className="label">Benefit to Organisation</p>
                    <ul>
                      <li>Determine whether safeguards are in place</li>
                      <li>
                        Examine whether the equipment presents any hazards
                      </li>
                      <li>
                        Gather air, water, and other samples to test for
                        hazardous substances
                      </li>
                      <li>Observe work practices to identify unsafe actions</li>
                    </ul>
                  </div>
                </Col> */}
                </Row>
              </div>
            ) : null}
            <div className="section-dynamic"></div>
          </Modal.Body>
        </Modal>
        <link rel="stylesheet" href="https://cdn.plyr.io/3.6.8/plyr.css" />
      </div>
    );
  }
}
