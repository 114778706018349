import React, { Component } from "react";
import TimePicker from 'react-time-picker';

export default class AddTime extends Component{
  state = {
    time: 'Select Time',
  }

  onChange = time => this.setState({ time })

  render() {
    return (
			<div className="form-dynamic">
        <div className="form-group">
          <input type="text" name="" placeholder="Add Hours" className="form-control" disabled />
        </div>
        {/* <div className="form-group">
          <TimePicker
            onChange={this.onChange}
            value={this.state.time}
          />
        </div> */}
			</div>
    )
  }
}