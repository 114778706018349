import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import { Trash, Copy, Edit } from 'react-feather';

export default class AddSectionPreview extends Component {
  constructor(props) {
    super(props);

    var title = this.props.data.title || this.props.data.section_name;
    var description = this.props.data.description || this.props.data.section_description;
    // var totalScore = this.props.data !== undefined ? this.props.data.total_score : this.props.dataSection.total_score;

    this.state = {
      title: title,
      description: description,
      totalScore: this.props.data?.total_score || this.props?.data?.total_score,
      saveSection: 0
    };
  }

  // _onDelete = () => {
  //   this.props.onDelete();
  // }

  render() {
    return (
      <div className="section-view">
        <div className="section-head">
          <h2>{this.state.title}</h2>
        </div>
        <div className="box-dynamic first-box-dynamic">
          <div className="que-display">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5>{this.state.description}</h5>
              {(this.props.data?.total_score > 0 || this.props?.dataSection?.total_score > 0) &&
                <label>Score :
                  <span>{this.props.data?.total_score || this.props?.dataSection?.total_score}</span>
                </label>
              }
            </div>
            {/* <p className="gray-para">{this.state.description}</p> */}
          </div>
          <div className="form-dynamic">
            <div className="section-btn">
              {/* {
                this.props.saveSectionVisible == false ?
                  null
                  :
                  <label className="check-box"> Save to My Section List
                    <input
                      name="saveSection"
                      type="checkbox"
                      checked={this.state.saveSection === 1}
                      value={this.state.saveSection}
                      onChange={async (e) => {

                        var val = this.state.saveSection === 0 ? 1 : 0;
                        //this.props.data.save = val;
                        await this.setState({ saveSection: val });
                        console.log(this.state.saveSection);
                        // handling mySection checkbox value
                        this.props.addMySection(this.state.saveSection);

                      }}
                    />
                    <span className="checkmark1"></span>
                  </label>
              } */}
              {
                this.props.onEdit &&
                <Button className="icon-btn" title="Edit Question" onClick={this.props.onEdit}>
                  <Edit className="icon-style btn-color" />
                </Button>
              }
              {
                this.props.onCopy &&
                <Button className="icon-btn" title="Copy Question" onClick={this.props.onCopy}>
                  <Copy className="icon-style btn-color" />
                </Button>
              }
              {
                this.props.onDelete &&
                <Button className="icon-btn" title="Delete Question" onClick={this.props.onDelete}>
                  <Trash className="icon-style btn-color" />
                </Button>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}