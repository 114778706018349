import "./Card.css";

import { Button } from "react-bootstrap";
import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import MediaPlayer from "./MediaPlayer";
// import Plyr from "plyr-react";
import React from "react";
import { useState } from "react";

function ReadMore({ children = 90 }) {
  const text = children;

  const [isShow, setIsShowLess] = useState(true);

  const results = isShow ? text.slice(0, 90) : text;

  function toggleIsShow() {
    setIsShowLess(!isShow);
  }

  return (
    <p>
      {results}
      <span
        className="btn btn-link"
        onClick={toggleIsShow}
        style={{
          color: "tomato",
          fontSize: "12px",
          textDecoration: "underline",
          marginLeft: '-1vw'
        }}
      >
        {isShow ? "Read More" : "Read Less"}
      </span>
    </p>
  );
}

export default function Card({ item, _showHideModal }) {
  return item.video ? (
    <div className="card1" style={{ lineHeight: '2.5vw' }}>
      <div style={styles.form_card_header}>
        <div style={styles.card_title_group}>
          <h5 style={styles.card_title_group}>{item.title}</h5>
          <div style={styles.card_title_group2}>{`Type | ${item.type}`}</div>
        </div>
      </div>

      <MediaPlayer item={item} />

      <div style={styles.card_text2}>
        <ReadMore>{item.description}</ReadMore>
      </div>
      <div style={styles.card_like_bar}>
        {true ? (
          <span>
            <Button className="icon-btn" title="View">
              <Link to={`/marketplace/${item.id}/preview`}>
                <Eye style={{ color: "black" }} />
              </Link>
            </Button>

            <div style={{ paddingLeft: "12px", display: "inline-block", lineHeight: '1.5vw' }}>
              <div style={styles.number_circle}>{item.download}</div>
            </div>
          </span>
        ) : null}
        {item.isDownloaded === 1 ? (
          <div style={styles.like_text}>
            <b>Downloaded</b>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
}

const styles = {
  card: {
    backgroundColor: "white",
    color: "#272727",
    padding: "20px",
    borderRadius: "10px",
    border: "0px",
    borderColor: "tomato",
    borderStyle: "solid",
    transition: "200ms",
    maxWidth: "380px",
    marginBottom: "2vw",
  },
  fomr_card_header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  profile: {
    width: "2.5rem",
    height: "2.5rem",
    backgroundColor: "tomato",
    borderRadius: "50%",
    margin: "10px",
    fontSize: "20pt",
    textAlign: "center",
    fontWeight: "bold",
    color: "white",
    justifyContent: "center",
  },
  letter: {
    verticalAlign: "middle",
  },
  card_title_group: {
    justifyContent: "center",
  },
  card_title_group2: {
    justifyContent: "center",
    marginBottom: "0.5vw",
  },
  card_title: {
    flex: "0.5",
    fontSize: "large",
    marginBottom: "0",
    marginTop: "0",
  },
  card_date: {
    flex: "0.3",
    fontSize: "small",
    marginTop: "0",
  },
  card_image: {
    borderRadius: "10px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  card_like_icon: {
    height: "25px",
  },
  card_text: {
    width: "350px",
    fontSize: "medium",
  },
  card_text2: {
    width: "350px",
    fontSize: "medium",
    paddingTop: "0.5vw",
  },
  card_like_bar: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  like_text: {
    fontSize: "small",
    marginLeft: "10px",
  },
  number_circle: {
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    background: "#fff",
    border: "2px solid rgb(0 0 0)",
    color: "rgb(0 0 0)",
    textAlign: "center",
    display: "inline-block",
  },
};
