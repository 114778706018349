import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import React, { useState } from "react";
import ConfirmPopupImage from "../Loader/ConfirmPopupImage";



const ConfirmPopup = ({
  showConfirmPopup,
  _handleCancel,
  _handleConfirmAction,
  actionText,
  message,
}) => {
  return (
    <Modal centered show={showConfirmPopup} className="submit-modal">
      <Modal.Body>

        <ConfirmPopupImage />

        <p className="submit-text">{message}</p>

        <div className="modal-footer-btn">
          <button type="submit" className="gray-btn" onClick={_handleCancel}>
            NO
          </button>
          <button
            type="submit"
            className="red-btn"
            onClick={_handleConfirmAction}
          >
            {actionText}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ConfirmPopup.defaultProps = {
  showConfirmPopup: false,
  _handleCancel: () => {},
  cancelSuccess: () => {},
  actionText: "OKAY",
  _handleConfirmAction: () => {},
  message: "",
};

export default ConfirmPopup;
